import { any, identity, propEq, when } from 'ramda'

import type { HasId } from './functions'

// ----------------------------------------------------------

export type Mutable<T extends object> = {
  -readonly [ K in keyof T ]: T[ K ] extends Array<infer A>
    ? Array<MutableField<A>>
    : MutableField<T[ K ]>
}

type MutableField<T> = T extends object
  ? Mutable<T>
  : T

// ----------------------------------------------------------

export const hasById = (
  id: string,
  items: readonly HasId[],
): boolean => any<HasId>(propEq('id', id), items)

export const findById = <T extends HasId>(
  id: string,
  items: readonly T[],
): T | undefined => items.find(item => item.id === id)

export const updateById = <T extends HasId>(
  items: T[],
  id: string,
  evolver: (v: T) => T,
): T[] => items.map(
  item => when<T, T>(propEq('id', id), evolver, item)
)

export const addById = <T extends HasId>(
  items: T[],
  newItem: T,
) => findById(newItem.id, items)
  ? [ ...items ]
  : [ ...items, newItem ]

export const removeById = <T extends HasId>(
  items: T[],
  id: string,
) => items.filter(item => item.id !== id)

// ----------------------------------------------------

export const addIds = (
  ids: string[],
  newIds: string[]
) => [ ...new Set([ ...ids, ...newIds ]) ]

export const removeId = (
  ids: string[],
  id: string
) => ids.filter(i => i !== id)

// ----------------------------------------------------

export const mapOf = <T extends HasId>(
  items: T[]
): Map<string, T> =>
  items.reduce((out, item) => out.set(item.id, item), new Map<string, T>())

export const listOf = <T extends HasId>(
  ids: string[] | undefined,
  items: Map<string, T>,
  sort: (items: readonly T[]) => T[] = items => items as T[]
): T[] => sort(ids?.map(id => items.get(id)) ?? [])

export const mappedListOf = <T, U>(
  srcs: T[] | undefined,
  map: (src: T) => U,
  sort: (items: readonly U[]) => readonly U[] = identity
): U[] => sort(srcs?.map(map) ?? []) as U[]

export const optionOf = <T extends HasId>(
  id: string | undefined,
  items: Map<string, T>
): T | null => items.get(id) ?? null
