<nav mat-tab-nav-bar>

  <a *ngFor="let link of accessibleLinks"
     mat-tab-link
     [disabled]="link.disabled"
     [routerLink]="link.path"
     routerLinkActive
     [routerLinkActiveOptions]="{ 'exact': !link.path || link.path === '.' }"
     #rla="routerLinkActive"
     [active]="rla.isActive"
     [ngClass]="routeAnimationsElements">

    <span [matBadge]="link.indicatorLabel"
          [matBadgeColor]="link.indicatorColour"
          [matBadgeOverlap]="false">{{ link.label }}</span>
  </a>

  <div class="mat-tab-link default-cursor">
    <ng-container *ngIf="commentsToggleContainerTemplate">
      <ng-container *ngTemplateOutlet="commentsToggleContainerTemplate"></ng-container>
    </ng-container>
  </div>

</nav>
