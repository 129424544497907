import { Injectable, type TemplateRef } from '@angular/core'

import type { ComponentType } from '@angular/cdk/portal'
import { MatDialog, type MatDialogRef, type MatDialogConfig } from '@angular/material/dialog'

import { firstValueFrom, type Observable } from 'rxjs'

import type { ConfirmDialogOptions, PromptDialogOptions, SuccessDialogOptions } from './modal-options'

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component'
import { SuccessDialogComponent } from './success-dialog/success-dialog.component'

// ----------------------------------------------------------

const DEFAULT_OPTIONS: MatDialogConfig = {
  autoFocus: false,
  hasBackdrop: true,
  panelClass: 'sl-dialog-panel',
  restoreFocus: true
}

// ----------------------------------------------------------

export interface ShowDialogOptions<D> extends MatDialogConfig<D> {
  obs?: boolean
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public confirm(
    options: ConfirmDialogOptions
  ): Promise<boolean> {
    // _log(`ModalService.confirm(options)`, options, this)

    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          ok: $localize`OK`,
          cancel: $localize`Cancel`,
          ...options
        }
      })

      dialogRef.afterClosed().subscribe(resolve)
    })
  }

  // ----------------------------------------------------

  public prompt(
    options: PromptDialogOptions
  ): Promise<string> {
    // _log(`ModalService.prompt(options)`, options, this)

    return new Promise(resolve => {
      const dialogRef = this.dialogService.open(PromptDialogComponent, {
        data: {
          ok: $localize`OK`,
          cancel: $localize`Cancel`,
          initialValue: '',
          ...options
        },
        minWidth: '400px',
        maxWidth: '80vw'
      })

      dialogRef.afterClosed().subscribe(resolve)
    })
  }

  // ----------------------------------------------------

  public show<C, D, R = unknown>(
    component: ComponentType<C> | TemplateRef<C>,
    data?: D,
    options?: ShowDialogOptions<D>
  ): Promise<R | undefined>

  public show<C, D, R = unknown>(
    component: ComponentType<C> | TemplateRef<C>,
    data: D,
    options: ShowDialogOptions<D> & { obs: true }
  ): Observable<R>

  // Returns `Promise<R | undefined>` but you can return an `Observable<R>` instead
  // by setting `obs` to `true` in the `options` argument.
  public show<C, D, R = unknown>(
    component: ComponentType<C> | TemplateRef<C>,
    data: D = null,
    options?: ShowDialogOptions<D>
  ): Promise<R | undefined> | Observable<R> {
    const allOptions = { ...DEFAULT_OPTIONS, ...options, data }

    const dialogRef: MatDialogRef<C, R> = this.dialogService.open(
      component,
      allOptions
    )

    return allOptions.obs
      ? dialogRef.afterClosed()
      : firstValueFrom(dialogRef.afterClosed())
  }

  // ----------------------------------------------------

  public success(
    options: SuccessDialogOptions
  ): Promise<unknown> {
    return new Promise(resolve => {
      const dialogRef: MatDialogRef<SuccessDialogComponent, unknown> = this.dialogService.open(SuccessDialogComponent, {
        data: {
          ok: $localize`Done`,
          ...options
        },
        minWidth: '400px'
      })

      dialogRef.afterClosed().subscribe(resolve)
    })
  }

  // ----------------------------------------------------

  public closeAllDialogs() {
    this.dialogService.closeAll()
  }

  // ----------------------------------------------------

  constructor(
    private dialogService: MatDialog
  ) {}

}
