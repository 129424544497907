import type { CohortMember } from '@libs/models'

// ----------------------------------------------------

export interface CohortMemberDocumentUpload {
  cohortMember: CohortMember
  file: File
  key: string
}

// ----------------------------------------------------

export interface CohortMemberUploadQuestion {
  key: string
  property: string
  label: string
  hint?: string
}

// -----------------------------------------------------

const questionMap = new Map<string, CohortMemberUploadQuestion>([
  [
    'passport',
    {
      key: 'passport',
      property: 'hasPassport',
      label: $localize`Passport`
    }
  ],
  [
    'proofOfAddress',
    {
      key: 'proofOfAddress',
      property: 'hasUtility',
      label: $localize`Proof of Address`,
      hint: $localize`Upload your Proof of Address (your document has to contain your name in it. Eg: Bank statements, telephone bill, utility bill and tenancy agreement. For tenancy agreement, if you're renting it from a main tenant, you will need to have a sub-tenancy agreement issued in your name and signed)`
    }
  ],
  [
    'utility',
    {
      key: 'utility',
      property: 'hasUtility',
      label: $localize`Utility Bill or a Bank Statement (less than 3 months old)`,
    }
  ],
])

// -----------------------------------------------------

export function getMemberUploadQuestions(
  keys: string[]
): CohortMemberUploadQuestion[] {
  return keys.map(k => questionMap.get(k))
}
