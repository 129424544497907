import { Pipe, type PipeTransform } from '@angular/core'

import { abbreviateNumber } from '@libs/utils'

// ----------------------------------------------------------

@Pipe({
  name: 'abbrNumber'
})
export class AbbrNumberPipe implements PipeTransform {
  transform(amount: number): string {
    if (!Number.isFinite(amount) || amount < 0) {
      return ''
    }

    let res = abbreviateNumber(amount)

    if (res === '') {
      res += amount.toFixed(0)
    }

    return res + ''
  }
}
