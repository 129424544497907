import { Inject, Injectable, type OnDestroy } from '@angular/core'

import { BehaviorSubject, EMPTY, firstValueFrom, type Observable } from 'rxjs'
import { tap, catchError, map, distinctUntilChanged } from 'rxjs/operators'

import { LocalStorageService } from '@libs/storage'
import { Api, BackendService } from '@libs/backend'

import { logger, logHttpError } from '@libs/utils'

// ----------------------------------------------------------

const FLAGS_STORAGE_KEY = 'feature-flags'

const FLAGS_REFRESH_INTERVAL = 5 * 60_000

// ----------------------------------------------------------

export interface FeatureFlagSettings {
  enabled: boolean
  subadmin: boolean
  percentage: number
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService implements OnDestroy {

  private readonly flags$$ = new BehaviorSubject<object>({})

  private timeoutId: ReturnType<typeof setTimeout> | null = null

  // ----------------------------------------------------

  getFeatureFlag(
    flag: string,
  ): Observable<boolean> {
    return this.flags$$.asObservable().pipe(
      map(flags => {
        return flags[ flag ] ?? false
      }),
      distinctUntilChanged(),
      logger(`getFeatureFlag("${flag}")`),
    )
  }

  // ----------------------------------------------------

  private async loadFeatureFlags() {
    await firstValueFrom(this.api
      .all('sys')
      .all('flags')
      .withHeaders({
        'Content-Type': 'application/json',
      })
      .get<object>()
      .pipe(
        tap(newFlags => {
          this.localStorage.setObject(FLAGS_STORAGE_KEY, newFlags)
          this.flags$$.next(newFlags)
        }),
        catchError(err => {
          logHttpError(err)
          return EMPTY
        })
      ))

    this.timeoutId = setTimeout(() => {
      this.loadFeatureFlags()
    }, FLAGS_REFRESH_INTERVAL)
  }

  // ----------------------------------------------------

  async setFeatureFlag(
    flag: string,
    settings: Partial<FeatureFlagSettings>,
  ) {
    await firstValueFrom(this.api
      .all('sys')
      .all('flags')
      .all(flag)
      .patch<object>(settings)
      .pipe(
        tap(newFlags => {
          this.localStorage.setObject(FLAGS_STORAGE_KEY, newFlags)
          this.flags$$.next(newFlags)
        }),
        catchError(err => {
          logHttpError(err)
          return EMPTY
        })
      ))
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  // ----------------------------------------------------

  constructor(
    @Inject(Api) private readonly api: BackendService,
    private readonly localStorage: LocalStorageService,
  ) {
    if (this.localStorage.hasItem(FLAGS_STORAGE_KEY)) {
      this.flags$$.next(this.localStorage.getObject(FLAGS_STORAGE_KEY))
    }

    this.loadFeatureFlags()

    window[ 'ffs' ] = this
  }

}
