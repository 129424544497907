export const ApiConfig = {
  DEV: {
    apiRoot: 'http://local.seedlegals.com:5001/',
    apiGatewayRoot: 'http://local.seedlegals.com:5001/',
    fileTransferRoot: 'http://local.seedlegals.com:5001/',
    notificationsApiRoot: 'http://local.seedlegals.com:5003/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'http://localhost:8080/xero/'
  },
  DEMO1: {
    apiRoot: 'https://api-1.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-1.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-1.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-1.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-1.demo.seedlegals.com/xero/'
  },
  DEMO2: {
    apiRoot: 'https://api-2.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-2.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-2.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-2.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-2.demo.seedlegals.com/xero/'
  },
  DEMO3: {
    apiRoot: 'https://api-3.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-3.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-3.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-3.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-3.demo.seedlegals.com/xero/'
  },
  DEMO4: {
    apiRoot: 'https://api-4.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-4.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-4.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-4.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-4.demo.seedlegals.com/xero/'
  },
  DEMO5: {
    apiRoot: 'https://api-5.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-5.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-5.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-5.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-5.demo.seedlegals.com/xero/'
  },
  DEMO6: {
    apiRoot: 'https://api-6.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-6.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-6.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-6.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-6.demo.seedlegals.com/xero/'
  },
  DEMO7: {
    apiRoot: 'https://api-7.demo.seedlegals.com/main/',
    apiGatewayRoot: 'https://api-7.demo.seedlegals.com/',
    fileTransferRoot: 'https://ft-7.demo.seedlegals.com/main/',
    notificationsApiRoot: 'https://api-7.demo.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.demo.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api-7.demo.seedlegals.com/xero/'
  },
  INT: {
    apiRoot: 'https://api.int.seedlegals.com/main/',
    apiGatewayRoot: 'https://api.int.seedlegals.com/',
    fileTransferRoot: 'https://ft.int.seedlegals.com/main/',
    notificationsApiRoot: 'https://api.int.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://api.seedlegals.com/xero/'
  },
  PROD: {
    apiRoot: 'https://gw2.seedlegals.com/main/',
    apiGatewayRoot: 'https://gw2.seedlegals.com/',
    fileTransferRoot: 'https://ft.seedlegals.com/main/',
    notificationsApiRoot: 'https://gw2.seedlegals.com/wisp/',
    draftableApiRoot: 'https://compare.seedlegals.com/seedlegals/api/v1/',
    xeroApiRoot: 'https://gw2.seedlegals.com/xero/'
  }
}
