import { createReducer, on } from '@ngrx/store'

import { clone, mergeDeepRight, path, pick, sortBy } from 'ramda'

import type { IUserCompany, IUserData } from '@libs/models'
import { AccessRole, AppointmentRole, UserCompanyAccess } from '@libs/models'

import { CompanyUpdated } from '@app/companies/+state/company.actions'
import { DocumentSignedSuccess } from '@app/documents/+state/document.actions'

import {
  AddUserCompany,
  CreateNewUserSuccess,
  LoadCurrentUserComplete,
  LoadCurrentUserSuccess,
  UpdateUserCompaniesUnsignedDocuments,
  UserUpdated,
  UpdateUserCompanyDeadlineStateSuccess,
  UpdateUserCompany
} from './users.actions'

import { updateById } from '@libs/utils'

// ----------------------------------------------------------

const userCompanyChangedPicker = pick([
  'name',
  'jurisdiction',
  'currency',
  'picture',
  'twitter',
  'linkedin',
  'facebook',
  'metadata',
  'hasCaptable',
  'hasCohortPortal'
])

// ----------------------------------------------------------

export const USERS_FEATURE_KEY = 'users'

// ----------------------------------------------------------

export interface UsersState {
  currentUser: IUserData
  currentUserCompanies: IUserCompany[]
  currentUserLoaded: boolean
}

// ----------------------------------------------------------

export const initialState: UsersState = {
  currentUser: null,
  currentUserCompanies: [],
  currentUserLoaded: false
}

// ----------------------------------------------------------

const sortByCompanyName = sortBy(path([ 'company', 'name' ]))

export const usersReducer = createReducer(
  initialState,

  on(CreateNewUserSuccess, (state, { user }): UsersState => {
    return {
      ...state,
      currentUser: clone(user),
      currentUserCompanies: []
    }
  }),

  on(LoadCurrentUserSuccess, (state, { user, companies }): UsersState => {
    const currentUserCompanies: IUserCompany[] = companies.map(data => {
      const company = { ...data.company }

      let roles: AppointmentRole[] = []
      let accesses: AccessRole[] = []

      let access: UserCompanyAccess = UserCompanyAccess.Documents
      let hasFullAccess = false

      // TODO: Sort out the ridiculoius duplication of code relating to
      // initialising and storing user companies and their access rules,
      // along with how they update and how they interact with the UX.
      if (data.appointment) {
        roles = data.appointment.roles

        if (roles.includes(AppointmentRole.Admin)) {
          access = UserCompanyAccess.Admin
          hasFullAccess = true
        } else if (roles.includes(AppointmentRole.Read)) {
          access = UserCompanyAccess.Read
        }
      }

      if (access !== UserCompanyAccess.Admin && data.access) {
        accesses = data.access.accessRoles

        if (accesses.includes(AccessRole.StaffWrite)) {
          access = UserCompanyAccess.StaffWrite
          hasFullAccess = true
        } else if (accesses.includes(AccessRole.StaffRead)) {
          access = UserCompanyAccess.StaffRead
          hasFullAccess = true
        }
      }

      return {
        id: company.id,
        company,
        access,
        hasFullAccess,
        roles,
        subscriptions: data.subscriptions,
        hasOptionAgreement: data.hasOptionAgreement,
        unsignedDocuments: 0,
        pendingDeadlinesCount: data.pendingDeadlinesCount
      }
    })

    return {
      ...state,
      currentUser: clone(user),
      currentUserCompanies: sortByCompanyName(currentUserCompanies)
    }
  }),

  on(LoadCurrentUserComplete, state => ({
    ...state,
    currentUserLoaded: true
  })),

  on(AddUserCompany, (state, { userCompany }): UsersState => {
    return {
      ...state,
      currentUserCompanies: sortByCompanyName([ ...state.currentUserCompanies, userCompany ])
    }
  }),

  on(UpdateUserCompany, (state, { companyId, data }): UsersState => {
    return {
      ...state,
      currentUserCompanies: updateById(
        state.currentUserCompanies,
        companyId,
        (uc: IUserCompany) => ({ ...uc, ...data }),
      )
    }
  }),

  on(UpdateUserCompaniesUnsignedDocuments, (state, { data }): UsersState => ({
    ...state,
    currentUserCompanies: state.currentUserCompanies.map(userCompany => ({
      ...userCompany,
      unsignedDocuments: data[ userCompany.company.id ] || 0
    }))
  })),

  on(UpdateUserCompanyDeadlineStateSuccess, (state, { companyId, pendingDeadlinesCount }): UsersState => ({
    ...state,
    currentUserCompanies: state.currentUserCompanies.map(userCompany => {

      // update the `pendingDeadlinesCount` only for the company for which the deadlines are updated
      return {
        ...userCompany,
        pendingDeadlinesCount: userCompany.company.id === companyId
          ? pendingDeadlinesCount
          : userCompany.pendingDeadlinesCount
      }
    })
  })),

  on(DocumentSignedSuccess, (state, { companyId, allSlotsSigned }): UsersState => {
    if (!allSlotsSigned) {
      return state
    }

    return {
      ...state,
      currentUserCompanies: updateById(
        state.currentUserCompanies,
        companyId,
        userCompany => {
          return {
            ...userCompany,
            unsignedDocuments: Math.max(userCompany.unsignedDocuments - 1, 0)
          }
        }
      )
    }
  }),

  on(UserUpdated, (state, { id, data }): UsersState => {
    if (id !== state.currentUser.id) {
      return state
    }

    return {
      ...state,
      currentUser: mergeDeepRight(state.currentUser, data) as unknown as IUserData
    }
  }),

  on(CompanyUpdated, (state, { companyId, data }): UsersState => ({
    ...state,
    currentUserCompanies: updateById(
      state.currentUserCompanies,
      companyId,
      userCompany => {
        return {
          ...userCompany,
          company: {
            ...userCompany.company,
            ...userCompanyChangedPicker(data)
          }
        }
      }
    )
  }))
)
