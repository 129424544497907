import { path, pathEq, pathOr } from 'ramda'

import type { IMilestoneBasedOptionVesting, IOptionVesting, ITimeBasedOptionVesting } from '../../models'
import { DEFAULT_VESTING_OPTIONS, EventCategory, Jurisdiction, OptionSchemeType, VestingType } from '../../models'

import type { OptionGrantEvent } from './option-grant-event'
import type { EmiValuationEvent } from './emi-valuation-event'
import type { ShareClass } from '../stock'
import type { IEventViewState } from '../events'
import { DocumentEventAdapter } from '../events'


// ------------------------------------------------------------

export class OptionSchemeEvent extends DocumentEventAdapter {
  readonly domain = 'optionSchemes'
  readonly category = EventCategory.OptionScheme

  description: string
  emi: boolean
  hmrcApproval: string

  shareClass: ShareClass

  // ----------------------------------------------------

  constructor({
    description = '',
    emi = false,
    ...data
  }) {
    super({ description, emi, ...data })
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'shareClass',
      'description',
      'emi',
      'hmrcApproval'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'options', this.emi ? 'emi-schemes' : 'schemes', this.id ]
    }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    return {
      state: [
        ...this.getViewState().state,
        'basics'
      ]
    }
  }

  // ----------------------------------------------------

  get type(): OptionSchemeType {
    // TODO: This should be region based instead
    switch (this.company.jurisdiction) {
      case Jurisdiction.France:
        return this.emi
          ? OptionSchemeType.BSPCE
          : OptionSchemeType.ADVISOR
      case Jurisdiction.HongKong:
      case Jurisdiction.Singapore:
        return OptionSchemeType.APAC
      default:
        return this.emi
          ? OptionSchemeType.EMI
          : OptionSchemeType.Unapproved
    }
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.description || $localize`Option scheme`
  }

  // ----------------------------------------------------

  get hasVesting(): boolean {
    return this.type != OptionSchemeType.ADVISOR
  }

  // ----------------------------------------------------

  get vesting(): IOptionVesting {
    return this.answers[ 'vesting' ]?.[ 'type' ] === VestingType.Milestones
      ? {
        milestones: [],
        ...(this.answers[ 'vesting' ] as IMilestoneBasedOptionVesting)
      }
      : {
        ...DEFAULT_VESTING_OPTIONS,
        ...(this.answers[ 'vesting' ] as ITimeBasedOptionVesting)
      }
  }

  // ----------------------------------------------------

  get optionGrants(): OptionGrantEvent[] {
    return this.company.optionGrants.filter(og => og.optionScheme === this)
  }

  // ----------------------------------------------------

  get hasApprovedGrant(): boolean {
    return !!this.optionGrants.find(og => og.approved)
  }

  // ----------------------------------------------------

  get optionHoldersCount(): number {
    return this.optionGrants.length
  }

  // ----------------------------------------------------

  get emiValuations() {
    return this.shareClass.emiValuations
  }

  // ----------------------------------------------------

  get hasInProgressEmiValuation(): boolean {
    return this.emiValuations.some(v => !v.closed)
  }

  // ----------------------------------------------------

  get hasActiveEmiValuation(): boolean {
    return this.emiValuations.some(v => !v.expired)
  }

  // ----------------------------------------------------

  get latestEmiValuation(): EmiValuationEvent | null {
    return this.emiValuations.item(0)
  }

  // ----------------------------------------------------

  get strikePrice(): number {
    if (this.latestEmiValuation) {
      return this.latestEmiValuation.strikePrice ?? this.company.shareNominalValue
    }

    return pathOr(this.company.shareNominalValue, [ 'consent', 'strikePrice' ], this.answers)
  }

  // ----------------------------------------------------

  get lapsingDays(): number | null {
    // Exit Only scheme means you cannot exercise if you leave
    if (pathEq([ 'exercise', 'when' ], 'exitOnly', this.answers)) return -1

    // Exit scheme means you can exercise at any time, up to 5 or 10 years after leaving
    const limitInDays = path([ 'exercise', 'limit' ], this.answers) === 5 ? 1825 : 3650
    if (pathEq([ 'exercise', 'when' ], 'exit', this.answers)) return limitInDays

    // Other schemes mean you have a window to exercise after you leave
    return path([ 'exercise', 'window' ], this.answers)
  }
}
