<div class="banner-container gap-gt-sm-16"
     fxLayout="column"
     fxLayout.gt-sm="row"
     fxLayoutAlign="space-between center">
  <div *ngIf="!button"></div> <!-- Centers the text when no button -->
  <ng-content></ng-content>
  <div fxLayoutAlign="center end">
    <ng-content select="button"></ng-content>
  </div>
</div>
