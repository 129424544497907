import type { CurrencyCode } from '../../models/currency.model'
import type { PricingModel } from './pricing-model'
import type { ProductBundle } from './bundles'

// ----------------------------------------------------------

export type ProductId =
  | 'ADVANCE_ASSURANCE'
  | 'BOOT'
  | 'CLN_FUTUREFUND'
  | 'COHORT_FUNDING'
  | 'CONCIERGE'
  | 'EIS_COMPLIANCE_COMPLETE'
  | 'EMI_VALUATION'
  | 'EXIT'
  | 'FOUNDERS_SHAREHOLDERS_AGREEMENT'
  | 'INSTANT_CONVERSION'
  | 'INSTANT_SHARES'
  | 'INSTANT'
  | 'OPTION_SCHEME_UNAPPROVED'
  | 'OPTION_SCHEME'
  | 'POLICIES'
  | 'PROPOSAL'
  | 'RESEARCH_ASSURANCE'
  | 'RESEARCH_CLAIM'
  | 'SEED'
  | 'SEEDFAST'
  | 'SEEDNOTE'
  | 'SEEDSAFT'
  | 'SHARE_CLASS_REGISTRATION'
  | 'SHARE_TRANSFER'
  | 'STOCK_SPLIT'

  // France
  | 'BSA_ADVISOR'
  | 'BSAAIR'
  | 'BSPCE'

// ------------------------------------------------------------

export enum Products {
  AdvanceAssurance = 'ADVANCE_ASSURANCE',
  BootstrapRound = 'BOOT',
  CLNFutureFund = 'CLN_FUTUREFUND',
  CohortFunding = 'COHORT_FUNDING',
  ConciergeService = 'CONCIERGE',
  ConvertibleNote = 'SEEDFAST',
  EISCompliance = 'EIS_COMPLIANCE_COMPLETE',
  EMIValuation = 'EMI_VALUATION',
  Exit = 'EXIT',
  FOUNDERS_SHAREHOLDERS_AGREEMENT = 'FOUNDERS_SHAREHOLDERS_AGREEMENT',
  InstantConversion = 'INSTANT_CONVERSION',
  InstantInvestment = 'INSTANT',
  InstantShares = 'INSTANT_SHARES',
  OptionScheme = 'OPTION_SCHEME',
  OptionSchemeUnapproved = 'OPTION_SCHEME_UNAPPROVED',
  Policies = 'POLICIES',
  Proposal = 'PROPOSAL',
  ResearchAssurance = 'RESEARCH_ASSURANCE',
  ResearchClaim = 'RESEARCH_CLAIM',
  SeedNOTE = 'SEEDNOTE',
  SeedSAFT = 'SEEDSAFT',
  SeedRound = 'SEED',
  ShareClassRegistration = 'SHARE_CLASS_REGISTRATION',
  ShareTransfer = 'SHARE_TRANSFER',
  StockSplit = 'STOCK_SPLIT',

  // France
  BSAAdvisor = 'BSA_ADVISOR',
  BSAAir = 'BSAAIR',
  BSPCE = 'BSPCE',
}

// ----------------------------------------------------------

export const ProductNames = {
  ADVANCE_ASSURANCE: $localize`Advance Assurance`,
  BOOT: $localize`Bootstrap Round`,
  BSA_ADVISOR: $localize`BSA Advisor`,
  BSPCE: $localize`BSPCE`,
  CONCIERGE: $localize`Concierge Service`,
  EIS_COMPLIANCE_COMPLETE: $localize`S/EIS Compliance`,
  EMI_VALUATION: $localize`EMI Valuation`,
  EXIT: $localize`Exit`,
  FOUNDERS_SHAREHOLDERS_AGREEMENT: $localize`Founder Shareholder Agreement`,
  INSTANT_INVESTMENT_CONSENT: $localize`Instant Investment Consent`,
  INSTANT_SHARES: $localize`Issue Shares`,
  INSTANT: $localize`Instant Investment`,
  OPTION_SCHEME_UNAPPROVED: $localize`Unapproved Scheme`,
  OPTION_SCHEME: $localize`EMI Scheme`,
  SEED: $localize`Seed Round`,
  SEEDFAST: $localize`SeedFAST Agreement`,
  SHARE_TRANSFER: $localize`Share Transfer`,
  STOCK_SPLIT: $localize`Share split`
} as const

// ----------------------------------------------------------

export type PurchaseStage =
  | 'DEPOSIT'
  | 'AGREEMENT'
  | 'BALANCE'

// ------------------------------------------------------------

export enum PurchaseStages {
  Deposit = 'DEPOSIT',
  Agreement = 'AGREEMENT',
  Balance = 'BALANCE'
}

// ----------------------------------------------------------

export interface Product {
  id: ProductId
  standalone: boolean
}

// ----------------------------------------------------------

export interface ProductAndStage {
  product: ProductId
  stage: PurchaseStage
}

// ------------------------------------------------------------

export interface IProductHowMuchData {
  product: ProductId
  stage: PurchaseStage | null
  description: string | null
  currency: CurrencyCode
  amount: number | null
  amountInclVAT: number | null
  discountedAmount: number | null
  discountedAmountInclVAT: number | null
  billedWithVat: boolean
  pricing: {
    [stage in PurchaseStage]?: PricingModel
  }
  bundles: ProductBundle[]
}
