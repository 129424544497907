import { EventCategory } from '../../models/category.model'
import { Event } from './event'
import type { IEventViewState } from './event'
import type { LoanEvent } from './loan-event'
import type { ShareClass } from '../stock/share-class'
import type { ApiFieldSpec } from '../model'
import { LoanCollection } from '../loan/loan'

// ------------------------------------------------------------

export class InstantConversionEvent extends Event implements LoanEvent {
  readonly category: EventCategory = EventCategory.InstantConversion
  readonly domain: string = 'instantConversions'
  readonly domainSingular: string = 'instantConversion'

  shareClass: ShareClass
  loans = new LoanCollection()

  // ----------------------------------------------------

  constructor({
    shareClass = null,
    ...data
  }) {
    super({ shareClass, ...data })

    this.name = $localize`Instant Conversion`
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'shareClass'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'captable', 'debt-table', 'instant-conversions', this.id ]
    }
  }

  // ----------------------------------------------------

  override detach() {
    super.detach()
    this.loans.forEach(loan => {
      loan.instantConversion = null
      loan.share = null
    })
  }

  // ----------------------------------------------------

  override getSharesIssuedFromEvent() {
    return this.closed
      ? this.loans.map(loan => loan.share)
      : []
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.loans.reduce((total, loan) => total + loan.amount, 0)
  }

  // ----------------------------------------------------

  get hasSHA(): boolean {
    const hasSHA = this.answers[ 'adhesion' ]
    return !hasSHA || hasSHA.deed
  }
}
