import { Inject, InjectionToken } from '@angular/core'
import { Router } from '@angular/router'

import { firstValueFrom } from 'rxjs'
import { flatten } from 'ramda'

import { AuthStorageKeys } from '@app/auth/auth.constants'
import { DocumentTypeNames, type User } from '@libs/models'
import type { Message } from './message'
import type { INotificationResponseData, IUserNotificationData } from './notification-api'

import { UserService } from '@app/users/services/user.service'
import { BackendService, NotificationsApi } from '@libs/backend'
import { LocalStorageService } from '@libs/storage'

import { DebugOptions } from '@env/environment'
import { EnvironmentConfig, IEnvironmentConfig } from '@libs/shared/tokens'

import { _log } from '@libs/utils'

// ------------------------------------------------------------

const DEFAULT_PAGE_SIZE = 5

// ----------------------------------------------------------

export const NotificationStore = new InjectionToken<INotificationStore>('NotificationStore')


export interface INotificationStore {
  readonly enabled: boolean

  readonly items: Message[]
  readonly length: number
  readonly unread: number

  loadMoreMessages(): Promise<void>

  markAllMessagesAsRead(): Promise<void>
}

interface INotificationEdge {
  page: number
  numPages: number
  numMessages: number
  userNotifications: IUserNotificationData[]
}

// ----------------------------------------------------------

export class LiveNotificationStore implements INotificationStore {
  private _enabled = false
  private _user = null
  private _token = null


  private readonly _items: Message[] = []

  private readonly _loading: Promise<INotificationEdge>[] = []

  private _numPages: number | undefined
  private _nextPage = 0

  private _numMessages: number | undefined
  private _loadedMessages = 0


  private _totalUnread = 0

  // ----------------------------------------------------

  private reset() {
    this._user = null
    this._token = null

    this._enabled = false

    this._items.splice(0, this._items.length)

    this._loading.splice(0, this._loading.length)

    this._numPages = undefined
    this._nextPage = 0

    this._numMessages = undefined
    this._loadedMessages = 0

    this._totalUnread = 0
  }

  // ----------------------------------------------------

  get enabled() {
    return this._enabled
  }

  // ----------------------------------------------------

  get isEnabled(): boolean {
    return !this.environment.FLAGS.DISABLE_NOTIFICATIONS
  }

  // ----------------------------------------------------

  get length(): number {
    return this._items.length
  }

  // ----------------------------------------------------

  get unread(): number {
    return this._totalUnread
  }

  // ----------------------------------------------------

  get items(): Message[] {
    return this._items
  }

  // ----------------------------------------------------

  get connected(): boolean {
    return false
  }

  // ----------------------------------------------------

  get totalUnread(): number {
    return this._totalUnread
  }

  set totalUnread(value: number) {
    if (value !== this._totalUnread) {
      this._totalUnread = value
    }
  }

  // ----------------------------------------------------

  get loaded(): boolean {
    return this._nextPage > 0 && this._loadedMessages === this._numMessages
  }

  // ----------------------------------------------------

  async initialise(user: User, token: string): Promise<void> {
    if (DebugOptions.logSocketEvents) {
      _log(`NotificationStore.initialise(token, user): this`, token, user, this)
    }

    if (this.connected || !token || !user) {
      return
    }

    this.reset()

    this._user = user
    this._token = token

    // Get the number of unread messages, to be performed once at the start of the app
    if (await this.updateUnreadMessageCount()) {
      this._enabled = true

      // Trigger loading first page of existing notifications so we can populate
      // the _numMessages and _numPages fields based on the number of historical
      // notifications.
      this.loadMoreMessages()

      // Subscribe to new notifications
      // TODO: Re-enable when we do notifications properly.
      // this.connectToServer()
    }
  }

  // ----------------------------------------------------

  close() {
    if (DebugOptions.logSocketEvents) {
      _log(`NotificationStore.close(): this`, this)
    }

    // if (this._client) {
    //   this._client.disconnect()
    // }

    this.reset()
  }

  // ----------------------------------------------------

  async markAllMessagesAsRead(): Promise<void> {
    try {
      await firstValueFrom(this.notificationsApi
        .one('users')
        .all('notifications')
        .all('markAllAsRead')
        .post())

      this.totalUnread = 0
    } catch (ex) {
      _log(`markAllMessagesAsRead(): error`, ex)
    }
  }

  // ----------------------------------------------------

  /**
   * Connect to the notification server so we can get notifications/mark them as
   * read in real-time.
   */
  // private connectToServer() {
  //   if (this.isEnabled && !this._client) {
  //     this._client = this.stompService.connect({
  //       endpoint: this.buildNotificationsEndpoint(),
  //       user: this._user,
  //       token: this._token,
  //       onConnect: (client: StompClient) => {
  //         client.subscribe(
  //           NOTIFICATION_TOPIC,
  //           (data: IUserNotificationData) => this.handleNewMessage(data)
  //         )
  //       },
  //     })
  //   }
  // }

  // private buildNotificationsEndpoint(): string {
  //   return this.environment.apiRoot + `reddit`
  // }

  // ----------------------------------------------------

  private async fetchData<T>(endpoint: string, params = {}): Promise<T> {
    return firstValueFrom(this.notificationsApi
      .one('users')
      .get(endpoint, params))
  }

  // ----------------------------------------------------

  private async processRawNotification(userNotification: IUserNotificationData): Promise<Message> {
    const notification = userNotification.notification

    const originator = await this.userService.findUserByEmail(notification.originator.email)

    const payload = notification.payload

    const makeEntity = (domain: 'companies' | 'users', picture: string, name: string, id: string) => ({
      id,
      domain,
      name,
      picture
    })

    const message = {
      id: userNotification.id,
      type: notification.type,
      originator: { name: originator.name, picture: originator.picture, email: originator.email },
      payload,
      inserted: notification.inserted,
      read: userNotification.read || false
    }

    let documentTypeName: string
    let text: string

    switch (notification.type) {
      case 'DOCUMENT_CREATED':
        documentTypeName = DocumentTypeNames[ notification.payload.type ]

        text = notification.payload.employee
          ? $localize`${originator.name} created a new ${documentTypeName} between ${notification.payload.company.name} and ${notification.payload.employee.name}.`
          : $localize`${originator.name} created a new ${documentTypeName} for ${notification.payload.company.name}.`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          documentTypeName: DocumentTypeNames[ notification.payload.type ],
          onClick: () => {
            this.router.navigate([ '/companies', notification.payload.company.id, 'documents', notification.payload.id ])
          }
        }

      case 'DOCUMENT_SHARED_TO_ADMINS': {
        const { document } = notification.payload

        documentTypeName = DocumentTypeNames[ document.type ]
        text = $localize`${originator.name} shared the ${documentTypeName}.`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          documentTypeName,
          onClick: () => {
            this.router.navigate([ '/documents', document.id ])
          }
        }
      }

      case 'DOCUMENT_SIGNED': {
        // "user" is present in the payload, e.g. when somebody marks somebody else as "signed offline"
        // The notification does not show that piece of information for now

        const { company, document } = notification.payload

        documentTypeName = DocumentTypeNames[ document.type ]

        text = company
          ? $localize`${originator.name} signed the ${documentTypeName} on behalf of ${company.name}.`
          : $localize`${originator.name} signed the ${documentTypeName}.`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          documentTypeName,
          onClick: () => {
            this.router.navigate([ '/documents', document.id ])
          }
        }
      }

      case 'DOCUMENT_FULLY_SIGNED_TO_COMPANY_ADMINS':
      case 'DOCUMENT_FULLY_SIGNED_TO_SIGNATORIES':
        documentTypeName = DocumentTypeNames[ notification.payload.type ]
        text = $localize`${documentTypeName} is now fully signed.`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          documentTypeName,
          onClick: () => {
            this.router.navigate([ '/companies', notification.payload.company.id, 'documents', notification.payload.id ])
          }
        }

      case 'DOCUMENT_SIGNED_BY_WITNESS': {
        const document = notification.payload.witnessOf.document

        documentTypeName = DocumentTypeNames[ document.type ]
        text = $localize`A witness of ${originator.name} signed the ${documentTypeName}.`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          documentTypeName,
          onClick: () => {
            this.router.navigate([ '/documents', document.id ])
          }
        }
      }

      case 'COMPANY_ACCESS_REQUEST': {
        text = $localize`${originator.name} (${originator.email}) has requested to be added as a user on ${notification.payload.company.name}`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          onClick: () => {
            this.router.navigate([ '/companies', notification.payload.company.id, 'settings', 'users' ])
          }
        }
      }

      case 'CONVERSATION_ITEM_CREATED': {
        text = $localize`${originator.name} (${originator.email}) has added a new comment in ${notification.payload.company.name}'s round terms`

        return {
          ...message,
          entity: makeEntity('users', originator.picture, originator.name, originator.id),
          text,
          onClick: () => {
            this.router.navigate([ '/conversations', notification.payload.conversationId ])
          }
        }
      }
    }
  }

  // ----------------------------------------------------

  /**
   * Make a one-off call to the server to get the total number of unread
   * messages for the user - we need to do this to get a value for all
   * historical notifications without having loaded any of them.
   */
  private async updateUnreadMessageCount(): Promise<boolean> {
    if (!this.isEnabled) {
      return false
    }

    try {
      const response = await this.fetchData<number>('notifications/unreadCount')

      this.totalUnread = response ?? 0

      return true
    } catch {
      return false
    }
  }

  // ----------------------------------------------------

  /**
   * Return true if we've not yet loaded a page, or if we haven't loaded all
   * pages of results yet, based on the value of {@link _numPages}.
   */
  private canLoadNextPage(): boolean {
    return this._numPages === undefined || this._nextPage < this._numPages
  }

  // ----------------------------------------------------

  /**
   * Calls {@link doLoadPage} on the next page to load and append the returned
   * promise to the {@link _loading} array. To actually process the returned
   * data you should call {@link processLoadedPages}.
   */
  private loadNextPage() {
    const promise = this.doLoadPage(this._nextPage++)

    if (!this._loading) {
      this._loading.splice(0, this._loading.length)
    }

    this._loading.push(promise)
  }

  // ----------------------------------------------------

  async loadMoreMessages() {
    if (this.canLoadNextPage()) {
      this.loadNextPage()
      await this.processLoadedPages()
    }
  }

  // ----------------------------------------------------

  /**
   * If {@link _loading} contains one or more promises that were added by
   * {@link loadNextPage} wait until all of them resolve and then combine the
   * array of resolved data so that we can process the notifications and add
   * them to our list of items.
   */
  private async processLoadedPages(): Promise<void> {
    if (!this._loading.length) {
      return
    }

    // Wait for all page loads to finish.
    const results = await Promise.all(this._loading)

    this._loading.splice(0, this._loading.length)

    const { numMessages, numPages } = results[ results.length - 1 ]

    const allNotifications = flatten(results.map(r => r.userNotifications))

    // Update total number of historical messages
    this._loadedMessages += allNotifications.length

    this._numMessages = numMessages
    this._numPages = numPages

    // Process the raw notifications to get consistent data, which is added to
    // the end of the list of items.
    for (const n of allNotifications) {
      this.insertMessage(await this.processRawNotification(n), true)
    }
  }


  // ----------------------------------------------------

  private async doLoadPage(page: number): Promise<INotificationEdge> {
    try {
      const response = await this.fetchData<INotificationResponseData>('notifications', {
        size: DEFAULT_PAGE_SIZE,
        page
      })

      if (response) {
        return {
          page,
          numPages: response.totalPages,
          numMessages: response.totalElements,
          userNotifications: response.content
        }
      }
    } catch (ex) {
      _log(`NotificationStore._doLoadPage(page: ${page}) error!`, ex)
    }
  }

  // ----------------------------------------------------

  // private async handleNewMessage(payload: IUserNotificationData) {
  //   const message = await this.processRawNotification(payload)

  //   this.insertMessage(message)

  //   await webNotification.showNotification(
  //     'SeedLegals',
  //     {
  //       body: message.text,
  //       icon: '/assets/images/logos/android-chrome-192x192.png',
  //       autoClose: DURATION,
  //       onClick: message.onClick
  //     }
  //   )
  // }

  // ----------------------------------------------------

  private insertMessage(message: Message, oldMessage = false) {
    if (oldMessage) {
      // Add old messages to end of list
      this._items.push(message)
    } else {
      // Insert new messages at start of list
      this._items.unshift(message)

      // Increase no. of unread messages by 1 for new messages
      this._totalUnread++
    }
  }

  // ----------------------------------------------------

  constructor(
    @Inject(EnvironmentConfig) private readonly environment: IEnvironmentConfig,
    @Inject(NotificationsApi) private readonly notificationsApi: BackendService,
    // private readonly layout: LayoutFacade,
    private readonly localStorage: LocalStorageService,
    private readonly router: Router,
    // private readonly stompService: StompService,
    private readonly userService: UserService,
  ) {
    const idToken = this.localStorage.getItem(AuthStorageKeys.ID_TOKEN)
    this.userService.currentUser$.subscribe(user => this.initialise(user, idToken))
  }
}

// ------------------------------------------------------------

export class NoopNotificationStore implements INotificationStore {
  readonly enabled = false
  readonly items: Message[] = []
  readonly length = 0
  readonly unread = 0
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async loadMoreMessages() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async markAllMessagesAsRead() {}
}
