import { ErrorHandler, NgModule, type ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { firstValueFrom } from 'rxjs'

import { EntityServices } from '@ngrx/data'
import { StoreModule } from '@ngrx/store'

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to'
import { CookieService } from 'ngx-cookie-service'

import {
  Api,
  ApiFactory,
  BackendProvider,
  type BackendService,
  FileTransferApi,
  FileTransferApiFactory,
  RestApi,
  RestApiFactory,
  NotificationsApi,
  NotificationsApiFactory
} from '@libs/backend'

import { AnalyticsModule } from '@libs/analytics'
import { AuthModule } from '@app/auth/auth.module'
import { PaymentsModule } from '@app/payments/payments.module'
import { EntitiesComponentsModule } from '@app/entities/entities-components.module'

import { ModalsModule } from '@libs/modals'
import { EntityService } from '@app/entities/services/entity.service'
import { AppEntityService } from './services/app-entity.service'
import { type IConfigurationData, Configuration } from './services/configuration.service'
import { SentryErrorHandler } from './services/sentry/sentry-error-handler.service'

import { CORE_FEATURE_KEY, coreReducer } from './+state/core.reducer'
import { LABELLERS } from './labellers'

// ----------------------------------------------------------

export function configurationFactory(
  api: BackendService,
  configuration: Configuration
) {
  return async () => {
    const configData = await firstValueFrom(api
      .one('configuration')
      .withoutAuthorization()
      .get<IConfigurationData>())
    configuration.initialise(configData)
  }
}

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    ModalsModule,
    EntitiesComponentsModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: RootCoreModule,
      providers: [
        BackendProvider,
        {
          provide: Api,
          useFactory: ApiFactory,
          deps: [
            BackendProvider
          ]
        },
        {
          provide: FileTransferApi,
          useFactory: FileTransferApiFactory,
          deps: [
            BackendProvider
          ]
        },
        {
          provide: RestApi,
          useFactory: RestApiFactory,
          deps: [
            BackendProvider
          ]
        },
        {
          provide: NotificationsApi,
          useFactory: NotificationsApiFactory,
          deps: [
            BackendProvider
          ]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: configurationFactory,
          deps: [ Api, Configuration ],
          multi: true
        },
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler
        },
        {
          provide: EntityServices,
          useExisting: AppEntityService
        },
        EntityService,
        CookieService,
        ...LABELLERS
      ]
    }
  }

  // ----------------------------------------------------

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }
}

// ----------------------------------------------------------

@NgModule({
  imports: [
    CoreModule,

    HttpClientModule,

    ScrollToModule.forRoot(),

    StoreModule.forFeature(CORE_FEATURE_KEY, coreReducer),

    AnalyticsModule,

    AuthModule,

    PaymentsModule
  ]
})
export class RootCoreModule {}
