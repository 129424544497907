import { Injectable } from '@angular/core'
import type { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Router } from '@angular/router'

import { type Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AuthFacade } from '../+state/auth.facade'
import { SessionExpired } from '../+state/auth.actions'

// ----------------------------------------------------------

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  // ----------------------------------------------------

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        // Automatically logout if 401 response returned from API
        if (!request.url.includes('xero') && err.status === 401) {
          this.authFacade.dispatch(SessionExpired({ redirectUri: this.router.url }))
        }

        return throwError(() => err)
      })
    )
  }

}
