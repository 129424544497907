import { type Fragment, Fragments, isFragment } from './graphql-fragments'

// ----------------------------------------------------------

export function addFragmentsToQuery(
  query: string,
): string {
  const fragmentKeys = [ ...extractFragments(query) ].sort()

  return [
    query,
    ...fragmentKeys.map(k => Fragments[ k ]),
    ''
  ].join('\n')
}

// ----------------------------------------------------------

const fragmentRegex = /\.{3}(?!on )([a-z][a-zA-Z]+)/g


function* extractFragments(
  query: string,
  seen = new Set<Fragment>(),
): Generator<Fragment> {
  for (const [ , f ] of query.matchAll(fragmentRegex)) {
    const fragmentKey = f[ 0 ].toUpperCase() + f.substring(1)

    if (!isFragment(fragmentKey) || seen.has(fragmentKey)) {
      continue
    }

    seen.add(fragmentKey)

    yield fragmentKey
    yield* extractFragments(Fragments[ fragmentKey ], seen)
  }
}

// ----------------------------------------------------------

export interface QueryOptions {
  extraFields?: string
  extraFragments?: Fragment[]
}

// ----------------------------------------------------------

export interface EventQueryOptions extends QueryOptions {
  eventName: string
}

// ----------------------------------------------------------

export function buildEventFilesQuery({
  eventName,
  extraFields = '',
}: EventQueryOptions): string {
  return addFragmentsToQuery(`
    query getEventFiles(
      $eventId: ID!
    ) {
      ${eventName}(id: $eventId) {
        ...eventFields
        ${extraFields}
      }
    }
  `)
}

// ----------------------------------------------------------

export function buildEventSummaryQuery(
  eventName: string
): string {
  return addFragmentsToQuery(`
    query getEventSummary(
      $eventId: ID!
    ) {
      ${eventName}(id: $eventId) {
        ...eventFileFields
      }
    }
  `)
}

// ----------------------------------------------------------

export function buildDocumentQuery({
  extraFields = '',
}: QueryOptions = {}): string {
  return addFragmentsToQuery(`
    query getDocumentMetadata(
      $documentId: ID!
    ) {
      document(id: $documentId) {
        ...documentFields
        ${extraFields}
      }
    }
  `)
}

// ----------------------------------------------------------

export const documentSignatoriesQuery = addFragmentsToQuery(`
  query getDocumentSignatories(
    $documentId: ID!
  ) {
    document(id: $documentId) {
      id
      ...signatoriesFields
      ...signatoriesFields
    }
  }
`)

// ----------------------------------------------------------
export const entityGroupQuery = addFragmentsToQuery(`
  query getEntityGroup(
    $groupId: ID!
  ) {
    entityGroup(id: $groupId) {
      ...entityGroupFields
    }
  }
`)
