<h3 class="sub-heading">
  <ng-content></ng-content>
</h3>

<div *ngFor="let requirement of requirements"
     fxLayoutAlign="start center"
     class="gap-16">
  <mat-icon fontIcon="icon-check" color="primary"></mat-icon>
  <span [innerHtml]="requirement"></span>
</div>
