import { ChangeDetectionStrategy, Component, HostBinding, Input, type OnInit } from '@angular/core'

// ---------------------------------------------------

@Component({
  selector: 'sl-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: [ './circular-progress.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CircularProgressComponent implements OnInit {
  @Input() size = 24
  @Input() completed = 0
  @Input() total = 0

  @HostBinding('style.height') height: string
  @HostBinding('style.width') width: string

  // -------------------------------------------------

  ngOnInit(): void {
    this.height = `${this.size}px`
    this.width = `${this.size}px`
  }

  get progress() {
    // input sanity check, completed value cannot exceed total
    const completed = Number(this.completed) > Number(this.total) ? this.total : this.completed
    return [ completed, this.total ]
  }
}
