import { Injectable } from '@angular/core'

import { select, Store } from '@ngrx/store'

import type { IUserCompany, User } from '@libs/models'

import { UpdateUserCompany, UserUpdated } from './users.actions'
import {
  selectUserLoaded,
  selectUser,
  selectUserId,
  selectUserCompanies,
  selectDefaultUserCompany,
  selectLeftNavInfo,
  selectHasUnsignedDocuments,
  selectUnsignedDocumentCount,
  selectCurrentRegion,
} from './users.selectors'
import { selectIsPlatformAdmin } from '@app/auth/+state/auth.selectors'

// ----------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class UsersFacade {
  userLoaded$ = this.store.pipe(select(selectUserLoaded))

  user$ = this.store.pipe(select(selectUser))
  userId$ = this.store.pipe(select(selectUserId))

  userCompanies$ = this.store.pipe(select(selectUserCompanies))
  defaultUserCompany$ = this.store.pipe(select(selectDefaultUserCompany))
  isPlatformAdmin$ = this.store.pipe(select(selectIsPlatformAdmin))

  leftNavInfo$ = this.store.pipe(select(selectLeftNavInfo))
  currentRegion$ = this.store.pipe(select(selectCurrentRegion))

  unsignedDocumentCount$ = this.store.pipe(select(selectUnsignedDocumentCount))

  hasUnsignedDocuments$ = this.store.pipe(select(selectHasUnsignedDocuments))

  // ----------------------------------------------------

  userUpdated(id: string, data: Partial<User>) {
    this.store.dispatch(UserUpdated({ id, data }))
  }

  // ----------------------------------------------------

  updateUserCompany(companyId: string, data: Partial<IUserCompany>) {
    this.store.dispatch(UpdateUserCompany({ companyId, data }))
  }

  // ----------------------------------------------------

  constructor(
    private store: Store
  ) {}
}
