import type { CountryCode } from './country.model'
import {
  austria,
  belgium,
  bulgaria,
  type Country,
  croatia,
  cyprus,
  czechRepublic,
  denmark,
  estonia,
  finland,
  france,
  germany,
  greece,
  hungary,
  ireland,
  italy,
  latvia,
  lithuania,
  luxembourg,
  malta,
  netherlands,
  norway,
  poland,
  portugal,
  romania,
  serbia,
  slovakiaRepublic,
  slovenia,
  spain,
  sweden,
  unitedKingdom
} from 'jsvat'
import { RegionalPostcodePrefixes } from './country.model'

// ----------------------------------------------------------

/**
 * Enum of all jurisdictions available on SeedLegals Platform
 */
export enum Jurisdiction {
  Austria = 'AUT',
  Belgium = 'BEL',
  BosniaAndHerzegovina = 'BIH',
  Bulgaria = 'BGR',
  Cayman = 'CYM',
  Croatia = 'HRV',
  Cyprus = 'CYP',
  CzechRepublic = 'CZE',
  Denmark = 'DNK',
  EnglandWales = 'EAW',
  Estonia = 'EST',
  Finland = 'FIN',
  France = 'FRA',
  Georgia = 'GEO',
  Germany = 'DEU',
  Greece = 'GRC',
  HongKong = 'HKG',
  Hungary = 'HUN',
  Iceland = 'ISL',
  Ireland = 'IRL',
  Israel = 'ISR',
  Italy = 'ITA',
  Latvia = 'LVA',
  Lithuania = 'LTU',
  Luxembourg = 'LUX',
  Malta = 'MLT',
  Moldova = 'MDA',
  Montenegro = 'MNE',
  Netherlands = 'NLD',
  NorthernIreland = 'NIR',
  NorthMacedonia = 'MKD',
  Norway = 'NOR',
  Poland = 'POL',
  Portugal = 'PRT',
  Romania = 'ROU',
  Scotland = 'SCT',
  Serbia = 'SRB',
  Singapore = 'SGP',
  Slovakia = 'SVK',
  Slovenia = 'SVN',
  Spain = 'ESP',
  Sweden = 'SWE',
  Turkey = 'TUR',
  UnitedStatesDelaware = 'USD',
  VirginIslands = 'BVI'
}

export const Jurisdictions: Jurisdiction[] = Object.values(Jurisdiction)

/**
 * List of 'SeedLegals Complete' jurisdictions available on SeedLegals Platform.
 * These jurisdictions have some products/support for them.
 */
export const SeedLegalsCompleteJurisdictions: Jurisdiction[] = [
  Jurisdiction.EnglandWales,
  Jurisdiction.France,
  Jurisdiction.HongKong,
  Jurisdiction.Ireland,
  Jurisdiction.NorthernIreland,
  Jurisdiction.Scotland,
  Jurisdiction.Singapore
]

/**
 * Used for sl-jurisdiction-select to individually disable specific jurisdictions based on Region / Purchase
 */
export type SelectableJurisdiction = {
  code: Jurisdiction,
  disabled: boolean
}

// ----------------------------------------------------------

/**
 * Maps the min & max length of the companies house number for Jurisdictions.
 * Validation defaults to using EnglandWales.
 * TODO: Explicitly set remaining juridictions values and remove the partial type.
 */
export const JurisdictionRegistrarLength: Partial<Record<Jurisdiction, [number, number]>> = {
  [ Jurisdiction.EnglandWales ]: [ 8, 8 ],
  [ Jurisdiction.France ]: [ 8, 8 ],
  [ Jurisdiction.Germany ]: [ 7, 12 ],
  [ Jurisdiction.HongKong ]: [ 7, 7 ],
  [ Jurisdiction.Ireland ]: [ 6, 6 ],
  [ Jurisdiction.Portugal ]: [ 8, 11 ],
  [ Jurisdiction.Singapore ]: [ 10, 10 ],
  [ Jurisdiction.Sweden ]: [ 11, 11 ],
  [ Jurisdiction.Malta ]: [ 7, 12 ],
}

export const JurisdictionUTRLength: Partial<Record<Jurisdiction, number>> = {
  [ Jurisdiction.EnglandWales ]: 10,
  [ Jurisdiction.HongKong ]: 8,
}

export const JurisdictionToVatValidator: Record<Jurisdiction, Country | RegExp | null> = {
  [ Jurisdiction.Austria ]: austria,
  [ Jurisdiction.Belgium ]: belgium,
  [ Jurisdiction.BosniaAndHerzegovina ]: null,
  [ Jurisdiction.Bulgaria ]: bulgaria,
  [ Jurisdiction.Cayman ]: null,
  [ Jurisdiction.Croatia ]: croatia,
  [ Jurisdiction.Cyprus ]: cyprus,
  [ Jurisdiction.CzechRepublic ]: czechRepublic,
  [ Jurisdiction.Denmark ]: denmark,
  [ Jurisdiction.EnglandWales ]: unitedKingdom,
  [ Jurisdiction.Estonia ]: estonia,
  [ Jurisdiction.France ]: france,
  [ Jurisdiction.Finland ]: finland,
  [ Jurisdiction.Georgia ]: null,
  [ Jurisdiction.Germany ]: germany,
  [ Jurisdiction.Greece ]: greece,
  [ Jurisdiction.HongKong ]: null,
  [ Jurisdiction.Hungary ]: hungary,
  [ Jurisdiction.Iceland ]: null,
  [ Jurisdiction.Ireland ]: ireland,
  [ Jurisdiction.Israel ]: null,
  [ Jurisdiction.Italy ]: italy,
  [ Jurisdiction.Latvia ]: latvia,
  [ Jurisdiction.Lithuania ]: lithuania,
  [ Jurisdiction.Luxembourg ]: luxembourg,
  [ Jurisdiction.Malta ]: malta,
  [ Jurisdiction.Moldova ]: null,
  [ Jurisdiction.Montenegro ]: null,
  [ Jurisdiction.Netherlands ]: netherlands,
  [ Jurisdiction.NorthMacedonia ]: null,
  [ Jurisdiction.Norway ]: norway,
  [ Jurisdiction.Poland ]: poland,
  [ Jurisdiction.Portugal ]: portugal,
  [ Jurisdiction.Romania ]: romania,
  // TODO: Validate with UK once this issue is resolved
  // https://github.com/se-panfilov/jsvat/issues/118
  [ Jurisdiction.NorthernIreland ]: null,
  [ Jurisdiction.Scotland ]: unitedKingdom,
  [ Jurisdiction.Serbia ]: serbia,
  [ Jurisdiction.Singapore ]: /^[a-z0-9]{10}$/i,
  [ Jurisdiction.Slovakia ]: slovakiaRepublic,
  [ Jurisdiction.Slovenia ]: slovenia,
  [ Jurisdiction.Spain ]: spain,
  [ Jurisdiction.Sweden ]: sweden,
  [ Jurisdiction.Turkey ]: null,
  [ Jurisdiction.UnitedStatesDelaware ]: null,
  [ Jurisdiction.VirginIslands ]: null,
}

// ----------------------------------------------------------

export const JurisdictionName: Record<Jurisdiction, string> = {
  [ 'AUT' ]: $localize`Austria`,
  [ 'BEL' ]: $localize`Belgium`,
  [ 'BIH' ]: $localize`Bosnia and Herzegovina`,
  [ 'BGR' ]: $localize`Bulgaria`,
  [ 'HRV' ]: $localize`Croatia`,
  [ 'CYP' ]: $localize`Cyprus`,
  [ 'CZE' ]: $localize`Czech Republic`,
  [ 'EAW' ]: $localize`England and Wales`,
  [ 'NIR' ]: $localize`Northern Ireland`,
  [ 'IRL' ]: $localize`Republic of Ireland`,
  [ 'SCT' ]: $localize`Scotland`,
  [ 'CYM' ]: $localize`Cayman Islands`,
  [ 'DNK' ]: $localize`Denmark`,
  [ 'EST' ]: $localize`Estonia`,
  [ 'FRA' ]: $localize`France`,
  [ 'FIN' ]: $localize`Finland`,
  [ 'GEO' ]: $localize`Georgia`,
  [ 'DEU' ]: $localize`Germany`,
  [ 'GRC' ]: $localize`Greece`,
  [ 'HKG' ]: $localize`Hong Kong`,
  [ 'HUN' ]: $localize`Hungary`,
  [ 'ISL' ]: $localize`Iceland`,
  [ 'ISR' ]: $localize`Israel`,
  [ 'ITA' ]: $localize`Italy`,
  [ 'LVA' ]: $localize`Latvia`,
  [ 'LTU' ]: $localize`Lithuania`,
  [ 'LUX' ]: $localize`Luxembourg`,
  [ 'MLT' ]: $localize`Malta`,
  [ 'MDA' ]: $localize`Moldova`,
  [ 'MNE' ]: $localize`Montenegro`,
  [ 'NLD' ]: $localize`Netherlands`,
  [ 'MKD' ]: $localize`North Macedonia`,
  [ 'NOR' ]: $localize`Norway`,
  [ 'POL' ]: $localize`Poland`,
  [ 'PRT' ]: $localize`Portugal`,
  [ 'ROU' ]: $localize`Romania`,
  [ 'SRB' ]: $localize`Serbia`,
  [ 'SGP' ]: $localize`Singapore`,
  [ 'SVK' ]: $localize`Slovakia`,
  [ 'SVN' ]: $localize`Slovenia`,
  [ 'ESP' ]: $localize`Spain`,
  [ 'SWE' ]: $localize`Sweden`,
  [ 'TUR' ]: $localize`Turkey`,
  [ 'USD' ]: $localize`United States`,
  [ 'BVI' ]: $localize`Virgin Islands`
}

// ----------------------------------------------------------

export const JurisdictionDefaultCountries: Record<Jurisdiction, CountryCode> = {
  [ 'EAW' ]: 'GB',
  [ 'NIR' ]: 'GB',
  [ 'IRL' ]: 'IE',
  [ 'SCT' ]: 'GB-SCT',
  [ 'BVI' ]: 'VG',
  [ 'CYM' ]: 'KY',
  [ 'HKG' ]: 'HK',
  [ 'SGP' ]: 'SG',
  [ 'FRA' ]: 'FR',
  [ 'USD' ]: 'US',
  [ 'DNK' ]: 'DK',
  [ 'DEU' ]: 'DE',
  [ 'NLD' ]: 'NL',
  [ 'SWE' ]: 'SE',
  [ 'GRC' ]: 'GR',
  [ 'GEO' ]: 'GE',
  [ 'AUT' ]: 'AT',
  [ 'BEL' ]: 'BE',
  [ 'BGR' ]: 'BG',
  [ 'HRV' ]: 'HR',
  [ 'CYP' ]: 'CY',
  [ 'CZE' ]: 'CZ',
  [ 'EST' ]: 'EE',
  [ 'FIN' ]: 'FI',
  [ 'HUN' ]: 'HU',
  [ 'ITA' ]: 'IT',
  [ 'LVA' ]: 'LV',
  [ 'LTU' ]: 'LT',
  [ 'LUX' ]: 'LU',
  [ 'MLT' ]: 'MT',
  [ 'POL' ]: 'PL',
  [ 'PRT' ]: 'PT',
  [ 'ROU' ]: 'RO',
  [ 'SVK' ]: 'SK',
  [ 'SVN' ]: 'SI',
  [ 'ESP' ]: 'ES',
  [ 'BIH' ]: 'BA',
  [ 'ISL' ]: 'IS',
  [ 'ISR' ]: 'IL',
  [ 'MDA' ]: 'MD',
  [ 'MKD' ]: 'MK',
  [ 'MNE' ]: 'MN',
  [ 'NOR' ]: 'NO',
  [ 'SRB' ]: 'RS',
  [ 'TUR' ]: 'TR',
}

// ----------------------------------------------------------

export function getCountryForJurisdiction(jurisdiction: Jurisdiction) {
  return JurisdictionDefaultCountries[ jurisdiction ]
}

// ----------------------------------------------------------

export interface ICompanyRegistrationAgency {
  shortName: string
  logo: string
}

// ----------------------------------------------------------

export const CompanyRegistrationAgencies = new Map<Jurisdiction, ICompanyRegistrationAgency>([
  [
    Jurisdiction.EnglandWales,
    {
      shortName: 'Companies House',
      logo: '/assets/images/app/logos/uk-registrar.png'
    }
  ],
  [
    Jurisdiction.France,
    {
      shortName: 'RNCS/Sirene',
      logo: '/assets/images/app/logos/fr-registrar.png'
    }
  ],
  [
    Jurisdiction.Ireland,
    {
      shortName: 'Companies Registration Office',
      logo: '/assets/images/app/logos/ie-registrar.png'
    }
  ]
])

// ----------------------------------------------------------

/**
 *  The list of jurisdictions that exclude the VAT form field.
 */
export const VAT_DISABLED_JURISDICTIONS = [
  Jurisdiction.HongKong,
]

/**
 *  The list of jurisdictions that exclude the HMRC form field.
 */
export const HMRC_DISABLED_JURISDICTIONS = [
  Jurisdiction.Germany,
  Jurisdiction.HongKong,
  Jurisdiction.Singapore,
]

/**
 * The list of jurisdictions that exclude the tax reference form field.
 */
export const TAX_REF_DISABLED_JURISDICTIONS = [
  Jurisdiction.Germany,
  Jurisdiction.Singapore,
]

/**
 * Jurisdictions that include the Amtsgericht form field.
 */
export const AMTSGERICHT_ENABLED_JURISDICTIONS = [
  Jurisdiction.Germany,
]

/**
 * Jurisdictions that include a secondary name field for a translated Company Name.
 */
export const REGIONAL_NAME_ENABLED_JURISDICTIONS = [
  Jurisdiction.HongKong,
]

// ----------------------------------------------------------

/**
 * @deprecated No longer used, ensure the user can select all UK based sub-jurisdictions directly instead.
 */
function getJurisdictionFromPostCode(postCode: string) {
  const [ , prefix ] = postCode.match(/^([A-Z]{1,2})/)
  if (RegionalPostcodePrefixes.has(prefix)) {
    return RegionalPostcodePrefixes.get(prefix)
  }
}
