import { Component, type OnInit, Inject, HostBinding, HostListener } from '@angular/core'
import { AnimationEvent } from '@angular/animations'

import { OverlayRef } from '@angular/cdk/overlay'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import type { Company, Investor, InvestmentEvent, User } from '@libs/models'
import { InvestorPopupData } from '../../models/investor-popup-data'

import { ModalService, popupFadeAndScaleAnimations, PopupState } from '@libs/modals'
import { EventInvestorService } from '../../services/event-investor.service'

// ----------------------------------------------------------

@Component({
  selector: 'sl-investor-info-popup',
  templateUrl: './investor-info-popup.component.html',
  styleUrls: [ './investor-info-popup.component.scss' ],
  animations: [
    popupFadeAndScaleAnimations
  ]
})
export class InvestorInfoPopupComponent implements OnInit {
  event: InvestmentEvent
  investor: Investor

  isConversion = false
  canEdit = false
  user?: User
  company?: Company

  @HostBinding('@popupState') popupState: PopupState = 'enter'

  // ----------------------------------------------------

  constructor(
    public dialogRef: OverlayRef,
    @Inject(MAT_DIALOG_DATA) public data: InvestorPopupData<any, any, any>,
    private modalService: ModalService,
    private eventInvestorService: EventInvestorService
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.event = this.data.event

    this.investor = this.data.summary.investor

    if (this.investor.type === 'user') {
      this.user = this.investor.entity as User
    } else if (this.investor.type === 'company') {
      this.company = this.investor.entity as Company
    }

    if (this.data.sidenavComponent) {
      this.canEdit = true
    }

    this.dialogRef.backdropClick().subscribe(() => this.close())

    // _log(
    //   `InvestorInfoPopupComponent.ngOnInit(popupState = "${this.popupState}"): data, this`,
    //   this.data,
    //   this
    // )
  }

  // ----------------------------------------------------

  async onEditClicked() {
    this.close()

    const result = await this.eventInvestorService.openSidenav(
      this.data.sidenavComponent,
      this.event,
      this.data.summary
    )

    if (result && typeof this.data.onEventChanged === 'function') {
      this.data.onEventChanged()
    }

    // TODO: Should emit message via NgRx
    // if (this.user) {
    //   this.entityService.showEntityEditDialog<User>(this.user)
    // }
  }

  // ----------------------------------------------------

  async onDeleteClicked() {
    const confirm = await this.modalService.confirm({
      title: `Remove ${this.investor.name} from the investors?`,
      ok: 'Remove'
    })
    if (confirm) {
      this.close()
      await this.data.onDelete()
    }
  }

  // ----------------------------------------------------

  close() {
    // _log(
    //   `InvestorInfoPopupComponent.close(popupState = "${this.popupState}"): this`,
    //   this
    // )

    this.popupState = 'leave'
    this.dialogRef.detach()
  }

  // ----------------------------------------------------

  @HostListener('@popupState.done', [ '$event' ])
  onAnimationDone(event: AnimationEvent) {
    // _log(
    //   `InvestorInfoPopupComponent.onAnimationDone(popupState = "${this.popupState}", event): this`,
    //   event,
    //   this
    // )

    if (this.popupState === 'leave') {
      this.dialogRef.dispose()
    }
  }
}
