import { Component, ViewChild, type AfterViewInit } from '@angular/core'
import { ActivatedRoute, Router, type RouterOutlet, NavigationEnd } from '@angular/router'

import { CdkPortalOutlet } from '@angular/cdk/portal'
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav'

import { combineLatest, type Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import type { User } from '@libs/models'
import { UiService } from '@libs/services'

import { routeAnimations } from '../../route-animation'

import { UsersFacade } from '@app/users/+state/users.facade'
import { CompanyFacade } from '@app/companies/+state/company.facade'
import { LayoutFacade } from '../../+state/layout.facade'
import { SidenavService } from '../../services/sidenav.service'

import { pascalCase } from '@libs/utils'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [ './main-layout.component.scss' ],
  animations: [
    routeAnimations
  ]
})
export class MainLayoutComponent implements AfterViewInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer
  @ViewChild('sideNav') rightSidenav: MatSidenav
  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet

  currentUser$: Observable<User> = this.route.data.pipe(map(data => data?.currentUser))
  currentCompany$ = this.layout.currentCompany$
  leftNavInfo$ = this.userFacade.leftNavInfo$

  isLoading$ = combineLatest([
    this.companyFacade.companiesLoading$,
    this.layout.isNavigating$
  ]).pipe(
    map(([ companyLoading, isNavigating ]) => companyLoading || isNavigating)
  )

  // ----------------------------------------------------

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public layout: LayoutFacade,
    private sidenavService: SidenavService,
    private uiService: UiService,
    private companyFacade: CompanyFacade,
    private userFacade: UsersFacade,
  ) {}

  // --------------------------------------------------

  ngAfterViewInit() {
    this.router.events
      .pipe(
        // logger(`MainLayoutComponent.router.events$`),
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.uiService.scrollToTop()
      })

    // _log(
    //   `MainLayoutComponent.ngAfterViewInit(): rightSideNavComponent, portalOutletElement`,
    //   this.rightSidenav,
    //   this.portalOutlet
    // )

    this.sidenavService.initialise(
      this.portalOutlet,
      this.rightSidenav
    )

    this.uiService.scrollToTop()
  }

  // ----------------------------------------------------

  prepareRoute(outlet: RouterOutlet) {
    const currentUrl = this.router.url.split('/')[ 3 ]
    if (outlet.activatedRouteData[ 'animation' ] === undefined && currentUrl) {
      return `slAnim${pascalCase(currentUrl)}`
    }

    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[ 'animation' ]
  }
}
