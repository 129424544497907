<div class="container gap-8"
     [ngClass]="level"
     fxLayout="row"
     fxLayoutAlign="start center">

  <mat-icon *ngIf="icon"
            [class]="iconSize"
            fontIcon="icon-{{ icon }}"></mat-icon>
  <div fxFlex>
    <h4 *ngIf="headerTitle"
        class="header-title">{{ headerTitle }}</h4>
    <ng-content></ng-content>
  </div>
</div>
