import { Inject, Directive } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

// ----------------------------------------------------------

@Directive()
export abstract class ModalComponent<C, D, R = unknown> {
  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<C, R>,
    @Inject(MAT_DIALOG_DATA) public data: D
  ) {}

  // ----------------------------------------------------

  close(result?: R): void {
    this.dialogRef.close(result)
  }
}
