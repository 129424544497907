import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'stripUrlScheme'
})
export class StripUrlSchemePipe implements PipeTransform {

  transform(url: string): string {
    if (url) {
      return String(url).replace(/^https?:\/\//i, '')
    }
  }

}
