import { Directive, ElementRef } from '@angular/core'

// ----------------------------------------------------------

@Directive({
  selector: 'img'
})
export class LazyImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype

    if (supports && !nativeElement.hasAttribute('loading')) {
      nativeElement.setAttribute('loading', 'lazy')
    }
  }
}
