<div *ngIf="notification"
     class="item-wrapper"
     fxLayout="column"
     fxLayoutAlign="space-between stretch"
     [class.unread]="!notification.read"
     (click)="onNotificationClicked(notification)">
  <div fxLayout="row" class="gap-8">
    <sl-avatar [entity]="$any(notification.entity)" [size]="24"></sl-avatar>

    <p [ngSwitch]="notification.type">
      <ng-container *ngSwitchCase="'DOCUMENT_CREATED'">
        <ng-container *ngTemplateOutlet="documentCreated">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'DOCUMENT_SHARED_TO_ADMINS'">
        <ng-container *ngTemplateOutlet="documentShared">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'DOCUMENT_SIGNED'">
        <ng-container *ngTemplateOutlet="documentSigned">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'DOCUMENT_FULLY_SIGNED_TO_COMPANY_ADMINS'">
        <ng-container *ngTemplateOutlet="documentFullySigned">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'DOCUMENT_FULLY_SIGNED_TO_SIGNATORIES'">
        <ng-container *ngTemplateOutlet="documentFullySigned">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'DOCUMENT_SIGNED_BY_WITNESS'">
        <ng-container *ngTemplateOutlet="witnessSigned">
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'COMPANY_ACCESS_REQUEST'">
        <ng-container *ngTemplateOutlet="companyAccessRequest">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CONVERSATION_ITEM_CREATED'">
        <ng-container *ngTemplateOutlet="conversationItemCreated">
        </ng-container>
      </ng-container>
    </p>
  </div>

  <time [attr.datetime]="notification.inserted">
    {{ notification.inserted | fromNow | sentencecase }}
  </time>
</div>

<ng-template #companyUpdated i18n>
  <span class="text-semibold">{{ notification.originator.name }}</span>
  updated details for
  <span class="text-semibold">{{ notification.payload?.name }}</span>.
</ng-template>

<ng-template #documentCreated>
  <ng-container *ngIf="notification.payload?.employee; else documentCreatedCompany" i18n>
    <span class="text-semibold">{{ notification.originator.name }}</span>
    created a new <span class="text-semibold">{{ notification.documentTypeName }}</span>
    between <span class="text-semibold">{{ notification.payload?.company?.name }}</span>
    and <span class="text-semibold">{{ notification.payload?.employee.name }}</span>.
  </ng-container>

  <ng-template #documentCreatedCompany i18n>
    <span class="text-semibold">{{ notification.originator.name }}</span>
    created a new <span class="text-semibold">{{ notification.documentTypeName }}</span>
    for <span class="text-semibold">{{ notification.payload?.company?.name }}</span>.
  </ng-template>
</ng-template>

<ng-template #documentShared i18n>
  <span class="text-semibold">{{ notification.originator.name }}</span> shared
  the <span class="text-semibold">{{ notification.documentTypeName }}</span>.
</ng-template>

<ng-template #documentSigned>
  <ng-container *ngIf="!notification.company; else documentSignedCompany" i18n>
    <span class="text-semibold">{{ notification.originator.name }}</span> signed
    the <span class="text-semibold">{{ notification.documentTypeName }}</span>.
  </ng-container>

  <ng-template #documentSignedCompany i18n>
    <span class="text-semibold">{{ notification.originator.name }}</span>
    signed the <span class="text-semibold">{{ notification.documentTypeName }}</span>
    on behalf of <span class="text-semibold">{{ notification.company?.name }}</span>.
  </ng-template>
</ng-template>

<ng-template #documentFullySigned i18n>
  <span class="text-semibold">{{ notification.documentTypeName }}</span> fully
  signed.
</ng-template>

<ng-template #witnessSigned i18n>
  A witness of <span class="text-semibold">{{ notification.originator.name }}</span>
  signed the <span class="text-semibold">{{ notification.documentTypeName }}</span>.
</ng-template>

<ng-template #companyAccessRequest i18n>
  {{ notification.originator.name }}
  <span class="text-semibold">({{ notification.originator.email }})</span> has requested to be added as a user on
  <span class="text-semibold">{{ notification.payload.company.name }}</span>.
</ng-template>

<ng-template #conversationItemCreated i18n>
  {{ notification.originator.name }}
  <span class="text-semibold">({{ notification.originator.email }})</span> has left a comment on
  <span class="text-semibold">{{ notification.payload.company.name }}'s round terms</span>.
</ng-template>
