import { Inject, Injectable, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core'
import { formatDate } from '@angular/common'

// ----------------------------------------------------------

@Injectable()
@Pipe({
  name: 'slDate'
})
export class SlDatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {}

  // ----------------------------------------------------

  transform(value: Date | string | number, format = 'mediumDate'): string | null {
    if (value == null || value === '' || value !== value) {
      return null
    }

    try {
      return formatDate(value, format, this.locale)
    } catch (error) {
      throw Error(`InvalidPipeArgument: '${value}' for pipe SlDatePipe`)
    }
  }
}
