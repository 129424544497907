import { Pipe, type PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'else'
})
export class ElsePipe implements PipeTransform {

  transform(
    value: unknown,
    elseValue: unknown,
  ): unknown {
    return value ?? elseValue
  }

}
