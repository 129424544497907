<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="title">
  <div style="width: 40px"></div>
  <div i18n>Payment</div>
  <button mat-icon-button
          (click)="dialogRef.close()">
    <mat-icon fontIcon="icon-close"></mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div fxLayout="column"
     class="content gap-24">

  <div>
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="product-billing">
      <span class="text-light-grey">{{ data.paymentData.description }}</span>
      <span class="text-semibold">{{ data.price | slCurrency: data.paymentData.currency }}</span>
    </div>
    <mat-divider></mat-divider>
  </div>

  <sl-loading-wrapper [loading]="cardsLoading$ | async">
    <div fxLayout="column"
         fxLayoutAlign="start center"
         class="p-16 gap-32">

      <sl-stripe-cards [cards]="cards$ | async"
                       [selectable]="true"
                       (cardSelected)="onCardSelected($event)">
        <div class="text-center p-16">
          <button (click)="addNewCardClicked()"
                  mat-button
                  color="primary"
                  i18n>
            + Add new card
          </button>
        </div>
      </sl-stripe-cards>

      <div fxLayout="row"
           fxLayoutAlign="center center">
        <button (click)="onPayClicked()"
                [disabled]="!selected"
                mat-raised-button
                class="pay-button"
                color="primary"
                i18n>
          Pay
        </button>
      </div>

    </div>
  </sl-loading-wrapper>

</div>

<!-- LinkedIn Conversion Tracking -->
<img style="height: 1px; width: 1px; display:none"
     alt=""
     src="https://px.ads.linkedin.com/collect/?pid=2110249&conversionId=3441529&fmt=gif"
     loading="eager">
