<ng-container *ngIf="entities.length == 1; else avatarList">
    <sl-avatar-with-name [entity]="entities[ 0 ]"
                         [popup]="false"
                         [size]="24"></sl-avatar-with-name>
  </ng-container>
  
  <ng-template #avatarList>
    <sl-avatar-list [entities]="entities"
                    [size]="24"
                    overlapAmount="0.8">
      <span class="avatar-name" i18n>{{ shares.length }} investors</span>
    </sl-avatar-list>
  </ng-template>
