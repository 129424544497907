import { pluck } from 'ramda'

import { EventCategory } from '../../models/category.model'
import { DocumentEventAdapter } from './document-event-adapters'
import { EventCollectionBase } from './event'
import type { DirectorshipEvent, DirectorshipTerminationEvent } from './director-events'
import { SaveMode } from '../model'

// ----------------------------------------------------------

const links = pluck('link')

// ------------------------------------------------------------

export interface IBoardMeetingAnswers {
  details: {
    time: string
    location: string
  }
  agenda: {
    items: IBoardMeetingAgendaItem[]
  }
  boardAttendance: {
    chairman: string
    directorsAbsent: string[]
    discussion: string
  }
  actions: {
    followUp: string
  }
}

// ------------------------------------------------------------

export interface IBoardMeetingAgendaItem {
  description: string
  documents: string[]
  result?: 'yes' | 'no' | 'notDiscussed' | 'notNeeded'
  notes?: string
}

// ------------------------------------------------------------

export class BoardMeetingEvent extends DocumentEventAdapter<IBoardMeetingAnswers> {
  readonly domain = 'boardMeetings'
  readonly category = EventCategory.BoardMeeting

  // ----------------------------------------------------

  directorships = new EventCollectionBase<DirectorshipEvent>('director.name')
  directorshipTerminations = new EventCollectionBase<DirectorshipTerminationEvent>('director.name')

  // ----------------------------------------------------

  protected override customisePayload(payload: object, _: SaveMode): object {
    return {
      ...payload,
      directorships: links(this.directorships.items()),
      directorshipTerminations: links(this.directorshipTerminations.items())
    }
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'board', 'meetings', this.id ]
    }
  }

  // ----------------------------------------------------

  override detach() {
    super.detach()
    this.directorships.forEach(app => app.boardMeeting = null)
    this.directorshipTerminations.forEach(app => app.boardMeeting = null)
  }
}
