<div [formGroup]="controls"
     fxLayout="row"
     fxLayoutAlign="start center">
  <input matInput
         #input
         formControlName="date"
         [matDatepicker]="picker"
         [min]="minDate"
         [max]="maxDate"
         readonly>

  <mat-datepicker-toggle matSuffix
                         [for]="picker"></mat-datepicker-toggle>
</div>

<mat-datepicker #picker [startView]="startView"></mat-datepicker>
