import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'

import { AngularMaterialModule } from '@libs/material'
import { SharedDirectivesModule } from '../../../shared/src/lib/directives.module'

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { FullscreenDialogComponent } from './fullscreen-dialog/fullscreen-dialog.component'
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component'
import { SimpleDialogWrapperComponent } from './simple-dialog-wrapper/simple-dialog-wrapper.component'
import { SuccessDialogComponent } from './success-dialog/success-dialog.component'
import { SuccessTickComponent } from './success-tick/success-tick.component'

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AngularMaterialModule,
    SharedDirectivesModule
  ],
  declarations: [
    ConfirmDialogComponent,
    FullscreenDialogComponent,
    PromptDialogComponent,
    SimpleDialogWrapperComponent,
    SuccessDialogComponent,
    SuccessTickComponent,
  ],
  exports: [
    FullscreenDialogComponent,
    SimpleDialogWrapperComponent,
    SuccessTickComponent,
  ]
})
export class ModalsModule {}
