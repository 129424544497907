
export interface Currency {
  code: CurrencyCode
  label: string
  symbol: string
  prefix: boolean
  space: boolean
  icon: string
  flag: string
}

// ----------------------------------------------------------

export interface Price {
  amount: number
  currency: CurrencyCode
}

// ----------------------------------------------------------

export interface Fee extends Price {
  billedWithVat: boolean
}

// ----------------------------------------------------------

export type CurrencyCode =
  | 'GBP'
  | 'EUR'
  | 'USD'
  | 'AUD'
  | 'CAD'
  | 'HKD'
  | 'SGD'
  | 'JPY'
  | 'DKK'
  | 'SEK'
  | 'NOK'
  | 'PLN'
  | 'MXN'

// ----------------------------------------------------------

export const Currencies: Currency[] = [
  {
    code: 'GBP',
    label: $localize`Pound Sterling`,
    symbol: '£',
    prefix: true,
    space: false,
    icon: 'currency-gbp',
    flag: 'GB'
  },
  {
    code: 'EUR',
    label: $localize`Euro`,
    symbol: '€',
    prefix: false,
    space: false,
    icon: 'currency-eur',
    flag: 'EUropeanunion'
  },
  {
    code: 'USD',
    label: $localize`United States Dollar`,
    symbol: '$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'US'
  },
  {
    code: 'AUD',
    label: $localize`Australian Dollar`,
    symbol: '$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'AU'
  },
  {
    code: 'CAD',
    label: $localize`Canadian Dollar`,
    symbol: '$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'CA'
  },
  {
    code: 'SGD',
    label: $localize`Singapore Dollar`,
    symbol: 'S$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'SG'
  },
  {
    code: 'HKD',
    label: $localize`Hong Kong Dollar`,
    symbol: 'HK$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'HK'
  },
  {
    code: 'JPY',
    label: $localize`Japanese Yen`,
    symbol: '¥',
    prefix: true,
    space: false,
    icon: 'currency-jpy',
    flag: 'JP'
  },
  {
    code: 'DKK',
    label: $localize`Danish krone`,
    symbol: 'kr',
    prefix: false,
    space: true,
    icon: 'currency-sign',
    flag: 'DK'
  },
  {
    code: 'SEK',
    label: $localize`Swedish Krona`,
    symbol: 'kr',
    prefix: false,
    space: true,
    icon: 'currency-sign',
    flag: 'SE'
  },
  {
    code: 'NOK',
    label: $localize`Norwegian Krone`,
    symbol: 'kr',
    prefix: false,
    space: true,
    icon: 'currency-sign',
    flag: 'NO'
  },
  {
    code: 'PLN',
    label: $localize`Polish Złoty`,
    symbol: 'zł',
    prefix: false,
    space: false,
    icon: 'currency-sign',
    flag: 'PL'
  },
  {
    code: 'MXN',
    label: $localize`Mexican Peso`,
    symbol: '$',
    prefix: true,
    space: false,
    icon: 'currency-usd',
    flag: 'MX'
  }
]

// ----------------------------------------------------------

export type CurrencyMap = {
  [ code in CurrencyCode ]: Currency
}

export const CurrenciesByCode: CurrencyMap = Currencies.reduce((o, c) => {
  o[ c.code ] = c
  return o
}, {} as CurrencyMap)

// ----------------------------------------------------------

export function getCurrencyByCode (code: CurrencyCode): Currency {
  return CurrenciesByCode[ code ]
}

// ----------------------------------------------------------

export function isValidCurrencyCode(code: string): boolean {
  return !!CurrenciesByCode[ code ]
}
