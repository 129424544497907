import { clone } from 'ramda'
import { addMonths, isAfter } from 'date-fns'

import { asDate, formatYearMonth, isoFormat, isSameOrAfter, isSameOrBefore, today } from '@libs/utils'

import { EventCategories, EventCategory } from '../../models/category.model'
import type { DocumentTypeId } from '../../models/document-types.model'
import type { Region } from '../../models/region.model'
import { EmploymentType, getEmploymentTypeForDocument } from '../../models/employment.model'
import type { Department } from '../department'
import type { Document } from '../documents'
import type { ApiFieldSpec } from '../model'
import type { User } from '../user'
import { EventCollectionBase, type IEventViewState, Event, eventComparator } from './event'

// ------------------------------------------------------------

export enum BonusTargetPeriods {
  QUARTERLY = 3,
  BIANNUALLY = 6,
  ANNUALLY = 12
}

// ------------------------------------------------------------

export abstract class BaseEmploymentEvent<A extends object = object> extends Event<A> {
  readonly category = EventCategory.Team

  abstract employee: User

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'team', 'profile', this.employee.id ]
    }
  }

  // ----------------------------------------------------

  override getQuestionState(
    sectionKey: string,
  ): IEventViewState {
    const { state } = this.getViewState()

    state.push(this.id, 'edit')

    const params = {
      expandedPanel: sectionKey
    }

    return { state, params }
  }

  // ----------------------------------------------------

  // Override to allow editing agreements on approved
  // employment events imported from Quick Agreements.
  override get canEditDocuments() {
    return true
  }
}

// ------------------------------------------------------------

export abstract class EmploymentChangeEvent<A extends object = object> extends BaseEmploymentEvent<A> {
  employment: EmploymentStartEvent

  // ----------------------------------------------------

  constructor({
    employment,
    ...data
  }) {
    super(data)

    this.employment = employment
  }

  // ----------------------------------------------------

  override get safeName() {
    return EventCategories[ this.category ]
  }

  // ----------------------------------------------------

  get employee(): User {
    return this.employment.employee
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'employment', include: 'create' }
    ]
  }
}

// ------------------------------------------------------------

export class EmploymentEventCollection<E extends BaseEmploymentEvent = BaseEmploymentEvent>
  extends EventCollectionBase<E> {

  getByEmployee(
    employee: User
  ): E[] {
    return this.filter(e => e.employee === employee)
  }

}

// ------------------------------------------------------------

export interface IEmploymentTermsAnswers {
  job: {
    description: string
    reportName: string
  }
  times: {
    fixedTerm: boolean
    endDate?: string
  }
  office: {
    location: string
    fullTime: boolean
    partTimeDays?: string
    workDay: boolean
    workStartTime?: string
    workFinishTime?: string
    lunchbreak: number
    workFlextime: string
  }
  salary: {
    review: number
    frequency: 'month' | 'fortnight' | 'week'
    salaryDate: string
  }
  bonus: {
    frequency: number
    amount: number
    freeform: string
  }
  equity: {
    type: 'false' | 'options'
    optionsGrantDateEvent: 'thisAgreement' | 'incorporation' | 'completion' | 'specificDate'
    optionsGrantDate: string
    optionsClass: string
    optionsNumberOrPercent: 'fixed' | 'percent' | 'performance'
    performanceOptionsEvent: 'As needed' | 'Every 6 months' | 'Once a year'
    performanceOptionsAnnualMonth: 'January' | 'April' | 'July' | 'October'
    performanceOptionsBiannualMonth: 'January and July' | 'April and October'
    performanceOptionsEmployment: number
    performanceOptionsMethod: 'Gold/Silver/Bronze' | 'A % of salary based on performance'
    performanceOptionsG: number
    performanceOptionsS: number
    performanceOptionsB: number
    performanceOptionsCustomHi: number
    performanceOptionsCustomLo: number
    performanceOptionsVestingPeriod: number
    optionsNumber: number
    optionsPercent: number
    optionsPricePerShare: number
    optionsVestingEnabled: 'no' | 'wizard' | 'milestones' | 'custom'
    optionsMilestones: {
      deliverable: string
      amount: number
    }[]
    customOptionVestingSchedule: string
    optionsVestingStartEvent: 'thisAgreement' | 'incorporation' | 'completion' | 'specificDate'
    optionsVestingStartDate: string
    optionsVestingPeriod: number
    optionsAlreadyVested: number
    optionsCliff: number
    optionsFrequency: 'month' | 'quarter' | 'year'
    BadLeaverOptions: 'vested' | 'none'
    BadLeaverOptionsPeriod: 'vesting' | 'anytime'
    GoodLeaverOptions: 'vested' | 'all'
    ForcedLeaverOptions: 'vested' | 'all' | 'exclude'
    VoluntaryLeaverOptions: 'vested' | 'exclude'
    optionsAcceleratedVesting: boolean
    optionsExercise: number
    optionsPlanType: 'emi' | 'unapproved'
  }
  holiday: {
    excludesPublic: boolean
    amountDays: number
    amountDaysExcludingPublic: number
    birthday: boolean
    yearStartDate: string
    carriedOverHolidays: number
    paidSickLeave: boolean
    paidSickDays: number
  }
  hr: {
    probation: boolean
    probationPeriod: number
    hrContact: string
    noticePeriod: number
    gardenLeave: boolean
  }
  nonCompete: {
    period: number
  }
  offerExpiry: {
    timeoutDate: string
  }
  extras: {
    additionalItems: string
  }
}

export interface IConsultancyTermsAnswers {
  consultancy: {
    type: 'fixed' | 'ongoing'
    effectiveDate: string
    endDate?: string
    noticePeriod: string
    competing: 'yes' | 'no'
  }
  assignor: {
    type: 'individual' | 'company'
    companyName?: string
    address?: string
  }
  project: Record<string, unknown>
  payment: Record<string, unknown>
  equity: Record<string, unknown>
  nonCompete: Record<string, unknown>
  other: Record<string, unknown>
}

// ------------------------------------------------------------

export type EmploymentEventWithTerms =
  | EmploymentStartEvent
  | EmploymentVariationEvent

export type EmploymentEvent =
  | EmploymentStartEvent
  | EmploymentVariationEvent
  | EmploymentBonusPaymentEvent
  | EmploymentBonusTargetEvent
  | EmploymentTerminationEvent
  | EmploymentExpiredEvent

export type EmploymentEventWithSalary =
  | EmploymentStartEvent
  | EmploymentVariationEvent

export type EmploymentEnd =
  | EmploymentTerminationEvent
  | EmploymentExpiredEvent

// ------------------------------------------------------------

export type BonusTargetPeriod = 'QUARTERLY' | 'BIANNUALLY' | 'ANNUALLY'

// ------------------------------------------------------------

export enum PayPeriod {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Fixed = 'FIXED',
  Task = 'PER_TASK',
  Custom = 'CUSTOM'
}

// ------------------------------------------------------------

export interface EmploymentKeyTerms {
  startDate: string
  endDate: string | null
  jobTitle: string
  salary: number | null
  currentSalary: number | null
  payPeriod: PayPeriod | null
  currentPayPeriod: PayPeriod | null
  type: EmploymentType
  companyName: string | null
}

// ------------------------------------------------------------

/** The changes that can be set for an employment variation updating pay. */
interface EmploymentPayChanges {
  salary: number | null
  payPeriod: PayPeriod | null
  effectiveDate: string
  type: EmploymentType
}

// ------------------------------------------------------------

export class Employee {

  id: string
  region: Region
  employee: User
  employments = new EventCollectionBase<EmploymentStartEvent>()

  // ----------------------------------------------------

  constructor(
    id: string,
    region: Region,
    employee: User,
    employments: EmploymentStartEvent[]
  ) {
    this.id = id
    this.employee = employee
    this.region = region
    this.employments.addAll(employments)
  }

  // ----------------------------------------------------

  get name(): string {
    return this.employee.name
  }

  // ----------------------------------------------------

  get latestEmployment(): EmploymentStartEvent {
    return this.employments.items()[ 0 ]
  }

  // ----------------------------------------------------

  get keyTerms(): EmploymentKeyTerms {
    return this.latestEmployment.keyTerms
  }

  // ----------------------------------------------------

  get department(): Department | null {
    return this.latestEmployment.department
  }

  // ----------------------------------------------------

  get events(): EmploymentEvent[] {
    let events = []
    for (const employment of this.employments.items()) {
      events = [ ...events, ...employment.events ]
    }
    return events
  }

  // ----------------------------------------------------

  get isExpired(): boolean {
    return !this.latestEmployment.isActive && !this.latestEmployment.termination
  }

  // ----------------------------------------------------

  get isTerminated(): boolean {
    return !this.latestEmployment.isActive && !!this.latestEmployment.termination
  }

  // ----------------------------------------------------

  rehire(): EmploymentStartEvent {
    const keyTerms = this.latestEmployment.keyTerms

    return new EmploymentStartEvent({
      company: this.latestEmployment.company,
      employee: this.employee,
      jobTitle: keyTerms.jobTitle,
      salary: keyTerms.salary,
      payPeriod: keyTerms.payPeriod,
      effectiveDate: today(),
      department: this.department,
      type: keyTerms.type,
    })
  }

  // ----------------------------------------------------

  public previousEmployment(employment: EmploymentStartEvent): EmploymentStartEvent {
    return this.employments.findMostRecentEventBeforeEvent(employment)
  }

}

// ------------------------------------------------------------

export class EmploymentStartEvent extends BaseEmploymentEvent<IEmploymentTermsAnswers | IConsultancyTermsAnswers> {
  readonly domain = 'employments'

  readonly newEmployee = true
  readonly type: EmploymentType

  readonly employee: User
  department: Department
  readonly jobTitle: string
  readonly salary: number | null
  readonly payPeriod: PayPeriod | null
  readonly endDate: string | null

  readonly variations = new EventCollectionBase<EmploymentVariationEvent>()
  readonly bonusEvents = new EventCollectionBase<EmploymentBonusPaymentEvent>()
  readonly targetEvents = new EventCollectionBase<EmploymentBonusTargetEvent>()
  termination: EmploymentTerminationEvent | null = null

  // TODO: fix hack
  newTermsVariation: EmploymentVariationEvent

  // ----------------------------------------------------

  constructor({
    employee,
    jobTitle,
    salary = null,
    department = null,
    payPeriod = null,
    type = EmploymentType.Employee,
    endDate = null,
    ...data
  }) {
    super(data)

    this.employee = employee
    this.department = department
    this.jobTitle = jobTitle
    this.salary = salary
    this.payPeriod = payPeriod
    this.type = type
    this.endDate = endDate
  }

  // ----------------------------------------------------

  // TODO: Sort out all of these properties for events, events
  // with documents, latest values from events, etc. etc.
  get events(): EmploymentEvent[] {
    const e: EmploymentEvent[] = [ this, ...this.variations.items(), ...this.targetEvents.items(), ...this.bonusEvents.items() ]
    if (this.endEvent) {
      e.push(this.endEvent)
    }
    return e
  }

  // ----------------------------------------------------

  public get isActive(): boolean {
    if (!this.endEvent?.effectiveDate) {
      return true
    }
    return !isSameOrBefore(asDate(this.endEvent.effectiveDate), today())
  }

  // ----------------------------------------------------

  public get latestVariation(): EmploymentVariationEvent {
    return this.variations.items()[ 0 ]
  }

  // ----------------------------------------------------

  private get documentVariations(): EmploymentVariationEvent[] {
    return this.variations.items().filter(v => v.document)
  }

  // ----------------------------------------------------

  public get latestVariationWithDocument(): EmploymentVariationEvent {
    return this.documentVariations[ 0 ]
  }

  // ----------------------------------------------------

  public firstVariationForDocType(docType: DocumentTypeId): EmploymentVariationEvent {
    return this.documentVariations.filter(v => v.docType === docType)[ 0 ]
  }

  // ----------------------------------------------------

  public get latestEvent(): EmploymentEvent {
    return this.events.sort(eventComparator)[ 0 ]
  }

  // ----------------------------------------------------

  public latestJobTitle(): string {
    return this.variations.items()
      .sort(eventComparator)
      .find(variation => variation.jobTitle)?.jobTitle ?? this.jobTitle
  }

  // ----------------------------------------------------

  public latestSalary(): number | null {
    return this.variations.items()
      .sort(eventComparator)
      .find(variation => variation?.salary !== null)?.salary ?? this.salary
  }

  // ----------------------------------------------------

  // The latest salary that is not in the future.
  public currentSalary(): number | null {
    return [ ...this.variations.items(), this ]
      .filter(e => isSameOrBefore(asDate(e.effectiveDate), today()))
      .sort(eventComparator)
      .find(variation => variation?.salary !== null)?.salary ?? this.salary
  }

  // ----------------------------------------------------

  public latestPayPeriod(): PayPeriod | null {
    return [ this, ...this.variations.items() ]
      .sort(eventComparator)
      .find(variation => variation?.payPeriod !== null)?.payPeriod
  }

  // ----------------------------------------------------

  /** The latest pay period that is not in the future. */
  public currentPayPeriod(): PayPeriod | null {
    return [ this, ...this.variations.items() ]
      .filter(e => isSameOrBefore(asDate(e.effectiveDate), today()))
      .sort(eventComparator)
      .find(variation => variation?.payPeriod !== null)?.payPeriod
  }

  // ----------------------------------------------------

  private currentEmploymentType(): EmploymentType {
    return this.variations.items()
      .filter(e => isSameOrBefore(asDate(e.effectiveDate), today()))
      .sort(eventComparator)
      .find(variation => variation?.type !== null)?.type ?? this.type
  }

  // ----------------------------------------------------

  public get companyName(): string | null {
    const variation = [ ...this.variations.items(), this ]
      .sort(eventComparator)
      .find(variation => 'assignor' in variation.answers && variation.answers.assignor.companyName !== null)

    return (variation?.answers as IConsultancyTermsAnswers)?.assignor.companyName
  }

  // ----------------------------------------------------

  public get latestBonusPayment(): EmploymentBonusPaymentEvent {
    return this.bonusEvents.items()[ 0 ]
  }

  // ----------------------------------------------------

  // Checks whether this employment has a bonus target which is currently active (The current day
  // is between the effective date and the end (effective date + performancePeriod).
  public get hasCurrentlyActiveBonusTarget(): boolean {
    return this.targetEvents.items()
      .some(te => isSameOrAfter(today(), asDate(te.effectiveDate))
        && isSameOrBefore(today(), addMonths(asDate(te.effectiveDate), BonusTargetPeriods[ te.performancePeriod ]))
      )
  }

  // ----------------------------------------------------

  get endEvent(): EmploymentEnd | null {
    if (this.termination) {
      return this.termination
    }

    if (this.latestVariationWithDocument) {
      return this.latestVariationWithDocument.endDate
        ? new EmploymentExpiredEvent({
          employment: this,
          effectiveDate: this.latestVariationWithDocument.endDate,
        })
        : null
    }

    if (this.endDate) {
      return new EmploymentExpiredEvent({
        employment: this,
        effectiveDate: this.endDate,
      })
    }

    return null
  }

  // ----------------------------------------------------

  public get keyTerms(): EmploymentKeyTerms {
    return {
      startDate: this.effectiveDate,
      endDate: this.endEvent?.effectiveDate || this.endDate,
      jobTitle: this.latestJobTitle(),
      salary: this.latestSalary(),
      currentSalary: this.currentSalary(),
      payPeriod: this.latestPayPeriod(),
      currentPayPeriod: this.currentPayPeriod(),
      type: this.currentEmploymentType(),
      companyName: this.companyName,
    }
  }

  // ----------------------------------------------------

  public updatePay(changes: EmploymentPayChanges): EmploymentVariationEvent {
    return new EmploymentVariationEvent({
      company: this.company,
      employment: this,
      jobTitle: null,
      answers: {},
      ...changes,
    })
  }

  // ----------------------------------------------------

  public updateJobTitle(
    effectiveDate: string,
    newJobTitle: string,
  ): EmploymentVariationEvent {
    const type = this.latestVariation?.type ?? this.type

    return new EmploymentVariationEvent({
      company: this.company,
      employment: this,
      effectiveDate,
      jobTitle: newJobTitle,
      salary: null,
      payPeriod: null,
      answers: {},
      type
    })
  }

  // ----------------------------------------------------

  public awardBonus(
    effectiveDate: string,
    amount: number,
    comment: string,
    startMonth: string,
    endMonth: string,
    employmentBonusTarget?: EmploymentBonusTargetEvent,
  ): EmploymentBonusPaymentEvent {
    return new EmploymentBonusPaymentEvent({
      company: this.company,
      employment: this,
      effectiveDate,
      amount,
      comment,
      startMonth,
      endMonth,
      employmentBonusTarget
    })
  }

  // ----------------------------------------------------

  public setTarget(
    effectiveDate: string,
    amount: number,
    comment: string,
    performancePeriod: BonusTargetPeriod,
    paymentFrequency: BonusTargetPeriod,
  ): EmploymentBonusTargetEvent {
    return new EmploymentBonusTargetEvent({
      company: this.company,
      employment: this,
      effectiveDate,
      amount,
      performancePeriod,
      paymentFrequency,
      comment
    })
  }

  // ----------------------------------------------------

  public varyTerms(
    docType: DocumentTypeId,
    defaultAnswers?: IEmploymentTermsAnswers | IConsultancyTermsAnswers,
  ): EmploymentVariationEvent {
    const latestEventDate = asDate(this.latestVariation?.effectiveDate || this.effectiveDate)
    const effectiveDate = isAfter(latestEventDate, today()) ? latestEventDate : today()

    const latestVariationForDoc = this.variations.items()
      .sort((a, b) => b.effectiveDate > a.effectiveDate ? 1 : -1)
      .find(variation => variation.document?.documentType === docType)

    const answers = defaultAnswers || latestVariationForDoc?.answers || this.answers

    return new EmploymentVariationEvent({
      company: this.company,
      employment: this,
      type: getEmploymentTypeForDocument(docType) ?? EmploymentType.Employee,
      docType,
      effectiveDate: isoFormat(effectiveDate),
      jobTitle: this.latestJobTitle(),
      salary: this.latestSalary(),
      answers: this.cloneTerms(answers),
      payPeriod: this.latestPayPeriod(),
    })
  }

  // ----------------------------------------------------

  public terminate(effectiveDate: string): EmploymentTerminationEvent {
    return new EmploymentTerminationEvent({
      company: this.company,
      employment: this,
      effectiveDate
    })
  }

  // ----------------------------------------------------

  private cloneTerms(oldAnswers: IEmploymentTermsAnswers | IConsultancyTermsAnswers) {
    if (!oldAnswers) {
      return {}
    }

    const answers = clone(oldAnswers)
    // remove endDate when copying over
    if ('times' in answers) {
      delete answers[ 'times' ][ 'endDate' ]
    }

    // remove offer expiry when copying over
    if ('offerExpiry' in answers) {
      delete answers[ 'offerExpiry' ]
    }

    return answers
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.employee.name
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'employee', include: 'create' },
      'jobTitle',
      'salary',
      'payPeriod',
      'answers',
      'department',
      'endDate',
      'type',
    ]
  }
}

// ------------------------------------------------------------

export class EmploymentVariationEvent extends EmploymentChangeEvent<IEmploymentTermsAnswers | IConsultancyTermsAnswers> {
  readonly domain = 'employmentVariations'
  readonly newEmployee = false

  type: EmploymentType
  jobTitle: string | null
  // Can be 0 (if founders pledge) or null (if not updating)
  salary: number | null
  payPeriod: PayPeriod | null
  comment: string
  endDate: string

  private _docType: DocumentTypeId

  // ----------------------------------------------------

  constructor({
    employment,
    type = EmploymentType.Employee,
    jobTitle = null,
    salary = null,
    payPeriod = null,
    endDate = null,
    docType = null,
    ...data
  }) {
    super({
      employment,
      ...data
    })

    this.type = type
    this.jobTitle = jobTitle
    this.salary = salary
    this.payPeriod = payPeriod
    this.endDate = endDate
    this.docType = docType
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.employee.name
  }

  // ----------------------------------------------------

  public get isStartAgreement(): boolean {
    const priorAgreement = this.employment.latestVariationWithDocument

    if (priorAgreement?.salary === 0 && priorAgreement?.docType === this.docType) { // prior agreement is a pledge, new one is an founder service agreement
      return false
    } else if (priorAgreement?.salary > 0) { // prior agreement is not a pledge
      return false
    } else {
      return true
    }
  }

  // ----------------------------------------------------

  // TODO: Remove this property.
  get docType(): DocumentTypeId | null {
    return this.document?.documentType || this._docType
  }

  set docType(docType: DocumentTypeId) {
    this._docType = docType
  }

  // ----------------------------------------------------

  createAgreement(): Document {
    return null
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'type', include: 'create' },
      'jobTitle',
      'salary',
      'type',
      'payPeriod',
      'endDate',
      'comment'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.employment.variations.add(this)
  }

  override detach() {
    super.detach()
    this.employment.variations.remove(this)
  }

  // ----------------------------------------------------

  get document(): Document {
    return this.documents.length > 0
      ? this.documents.items()[ 0 ]
      : null
  }
}

// ------------------------------------------------------------

export class EmploymentBonusPaymentEvent extends EmploymentChangeEvent {
  readonly domain = 'employmentBonusPayments'

  amount: number
  comment: string
  startMonth: string | null
  endMonth: string | null

  employmentBonusTarget: EmploymentBonusTargetEvent | null // can be null if it's a discretionary payment or targetId if it's part of a target

  // ----------------------------------------------------

  constructor({
    employment,
    amount = 0,
    comment = '',
    startMonth = null,
    endMonth = null,
    employmentBonusTarget = null,
    ...data
  }) {
    super({
      employment,
      ...data
    })

    this.amount = amount
    this.comment = comment
    this.startMonth = startMonth
    this.endMonth = endMonth
    this.employmentBonusTarget = employmentBonusTarget
  }

  // ----------------------------------------------------

  override get safeName() {
    return $localize`Bonus Payment`
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'employmentBonusTarget', include: 'create' },
      'amount',
      'comment',
      'startMonth',
      'endMonth'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.employment.bonusEvents.add(this)
  }

  override detach() {
    super.detach()
    this.employment.bonusEvents.remove(this)
  }

  get numberOfPayments(): number | null {
    return this.employmentBonusTarget?.numberOfPayments
  }

  get paymentIndex(): number | null {
    return this.employmentBonusTarget?.payments.indexOf(this) + 1
  }

}

// ----------------------------------------------------------

export class EmploymentBonusTargetEvent extends EmploymentChangeEvent {
  readonly domain = 'employmentBonusTargets'

  amount: number
  performancePeriod: BonusTargetPeriod
  paymentFrequency: BonusTargetPeriod
  comment: string

  // ----------------------------------------------------

  constructor({
    employment,
    amount = 0,
    performancePeriod = null,
    paymentFrequency = null,
    comment = '',
    ...data
  }) {
    super({
      employment,
      ...data
    })

    this.amount = amount
    this.performancePeriod = performancePeriod
    this.paymentFrequency = paymentFrequency
    this.comment = comment
  }

  // ----------------------------------------------------

  override get safeName() {
    return $localize`Bonus Target`
  }

  // ----------------------------------------------------

  public get payments(): EmploymentBonusPaymentEvent[] {
    return this.employment.bonusEvents
      .filter(e => e.employmentBonusTarget?.id === this.id)
      .sort((a, b) => b.effectiveDate < a.effectiveDate ? 1 : -1)
  }

  // ----------------------------------------------------

  private get lastPayment(): EmploymentBonusPaymentEvent | undefined {
    return this.payments[ this.payments.length - 1 ]
  }

  // ----------------------------------------------------

  get numberOfPayments(): number {
    return BonusTargetPeriods[ this.performancePeriod ] / BonusTargetPeriods[ this.paymentFrequency]
  }

  // ----------------------------------------------------

  get numberOfPaymentsPaid(): number {
    return this.payments.length
  }

  // ----------------------------------------------------

  get alreadyPaid(): number {
    return this.payments.reduce((acc, el) => acc + el.amount, 0)
  }

  // ----------------------------------------------------

  get nextPaymentPeriod(): { startMonth: string, endMonth: string } {
    const startMonth = this.lastPayment ? addMonths(asDate(this.lastPayment.endMonth), 1) : asDate(this.effectiveDate)
    return { startMonth: formatYearMonth(startMonth), endMonth: formatYearMonth(addMonths(startMonth, BonusTargetPeriods[ this.paymentFrequency ] - 1)) }
  }

  // ----------------------------------------------------

  get isFinished(): boolean {
    return this.numberOfPayments === this.numberOfPaymentsPaid
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'amount',
      'performancePeriod',
      'paymentFrequency',
      'comment'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.employment.targetEvents.add(this)
  }

  override detach() {
    super.detach()
    this.employment.targetEvents.remove(this)
  }
}

// ------------------------------------------------------------

export class EmploymentTerminationEvent extends EmploymentChangeEvent {
  readonly domain = 'employmentTerminations'

  // ----------------------------------------------------

  override get safeName() {
    return $localize`Termination`
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.employment.termination = this
  }

  override detach() {
    super.detach()
    this.employment.termination = null
  }
}

// ------------------------------------------------------------

// TODO: Not a real Event, should probably not extend EmploymentChangeEvent - could use Termination?
export class EmploymentExpiredEvent extends EmploymentChangeEvent {
  readonly domain: string
}
