import { Directive, ElementRef, HostListener, Injector, Input, HostBinding, OnInit, OnDestroy } from '@angular/core'

import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription } from 'rxjs'

import { Company, Entity, User } from '@libs/models'

import { UserService } from '@app/users/services/user.service'
import { CompanyService } from '@app/companies/services/company/company.service'
import { LayoutFacade } from '@app/layout/+state/layout.facade'

import { InfoPopUpPanelComponent } from '../components/info-pop-up-dialog/info-pop-up-panel.component'

// ----------------------------------------------------------

export interface IPopupData {
  user?: User
  company?: Company
}

// ----------------------------------------------------------

@Directive({
  selector: '[slEntityInfoPopup]'
})
export class EntityInfoPopupDirective implements OnInit, OnDestroy {
  @Input('slEntityInfoPopup') entity: Entity

  @Input() disabled: boolean

  // ----------------------------------------------------

  // The backdrop currently blocks scroll events so
  // this strategy acts like the block strategy. Fixed
  // in the following PR but still no release in sight...
  //
  // https://github.com/angular/components/pull/7779
  scrollStrategy = this.overlay.scrollStrategies
    .reposition({ autoClose: true })

  // ----------------------------------------------------

  hasAdminAccess = false

  sub: Subscription

  // ----------------------------------------------------

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private elementRef: ElementRef,
    private layoutFacade: LayoutFacade,
    private userService: UserService,
    private companyService: CompanyService
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.sub = this.layoutFacade.currentCompanyFullAccess$.subscribe(isAdmin => {
      this.hasAdminAccess = isAdmin
    })
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.sub?.unsubscribe()
  }

  // ----------------------------------------------------

  @HostBinding('class.sl-entity-info-link')
  get enabled() {
    return this.hasAdminAccess && !this.disabled
  }

  // ----------------------------------------------------

  @HostListener('click', [ '$event' ])
  async openEntityInfoPopup(event: MouseEvent) {
    if (!this.enabled) {
      return
    }

    event.stopPropagation()

    const data = await this.getPopupData()

    const overlayRef = this.getOverlay()
    const popupInjector = this.getInjector(overlayRef, data)

    const portal = new ComponentPortal(InfoPopUpPanelComponent, null, popupInjector)

    overlayRef.attach(portal)
  }

  // ----------------------------------------------------

  private async getPopupData(): Promise<IPopupData> {
    if (this.entity.domain === 'users') {
      const user = await this.userService.getUser(this.entity.id)

      return { user }
    } else {
      const company = await this.companyService.getCompanyExcerpt(this.entity.id)

      return { company }
    }
  }

  // ----------------------------------------------------

  private getOverlay(): OverlayRef {
    return this.overlay.create({
      minWidth: '320px',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.scrollStrategy,
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          }
        ])
        .withTransformOriginOn('sl-info-pop-up-panel')
        .withViewportMargin(8)
    })
  }

  // ----------------------------------------------------

  private getInjector(
    overlayRef: OverlayRef,
    popupData: IPopupData
  ): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MAT_DIALOG_DATA,
          useValue: popupData
        },
        {
          provide: OverlayRef,
          useValue: overlayRef
        }
      ]
    })
  }
}
