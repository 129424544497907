<div *ngIf="headerTitle"
     class="mat-dialog-header"
     fxLayout="row"
     fxLayoutAlign="space-between center">
  <div class="mat-title mat-primary-fg m-0">{{ headerTitle }}</div>

  <ng-container *ngIf="cancelButton">
    <span fxFlex></span>

    <button mat-icon-button
            aria-label="Close"
            i18n-aria-label
            [matDialogClose]="undefined">
      <mat-icon fontIcon="icon-close"></mat-icon>
    </button>
  </ng-container>
</div>

<mat-dialog-content fxLayout="column"
                    [ngClass]="contentClass">
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions cdkTrapFocus
                    [attr.align]="buttonAlign">
  <div *ngIf="errorMessage"
       class="mat-warn-fg mat-body-2 mr-20">{{ errorMessage }}</div>

  <ng-content select=".buttons"></ng-content>

  <ng-container *ngIf="showDefaultButtons">
    <ng-container *ngIf="cancelButton">

      <button *ngIf="closeOnClick"
              mat-button
              (click)="cancelClicked.emit()"
              [matDialogClose]="undefined"
              [attr.aria-label]="cancelAriaLabel || cancelLabel">
        {{ cancelLabel }}
      </button>

      <button *ngIf="!closeOnClick"
              mat-button
              (click)="cancelClicked.emit()"
              [attr.aria-label]="cancelAriaLabel || cancelLabel">
        {{ cancelLabel }}
      </button>

      <span style="width: 8px"></span>
    </ng-container>

    <ng-container *ngIf="okButton">
      <button mat-flat-button
              cdkFocusInitial
              color="primary"
              [slLoading]="loading"
              (click)="onOkClicked($event)"
              [disabled]="form.invalid"
              [attr.aria-label]="okLabel">
        {{ okLabel }}
      </button>
    </ng-container>
  </ng-container>
</mat-dialog-actions>
