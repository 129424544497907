<mat-select [(ngModel)]="value"
            [disabled]="disabled"
            (openedChange)="selectOpened = true"
            (ngModelChange)="onChanged($event)">

  <mat-select-trigger>
    <ng-container *ngTemplateOutlet="countryItem; context: { $implicit: selectedCountry }"></ng-container>
  </mat-select-trigger>

  <ng-container *ngIf="selectOpened; else dummyOption">
    <mat-option *ngFor="let item of countries"
                [value]="item.code">
      <ng-container *ngTemplateOutlet="countryItem; context: { $implicit: item }"></ng-container>
    </mat-option>
  </ng-container>

  <ng-template #dummyOption>
    <mat-option [value]="selectedCountry.code">
      <ng-container *ngTemplateOutlet="countryItem; context: { $implicit: selectedCountry }"></ng-container>
    </mat-option>

    <mat-option disabled>
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-spinner [diameter]="16"></mat-spinner>
      </div>
    </mat-option>
  </ng-template>

</mat-select>

<ng-template #countryItem let-country>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       class="gap-8">
    <div class="flag"
         [ngClass]="country.code"></div>
    <div>{{ country.name }}</div>
  </div>
</ng-template>
