import { Optional, Pipe, PipeTransform } from '@angular/core'
import { formatNumber } from '@angular/common'

import { LocaleService } from '@libs/services'

// ------------------------------------------------------

@Pipe({
  name: 'slNumber'
})
export class SlNumberPipe implements PipeTransform {

  constructor(@Optional() private localeService?: LocaleService) {}

  // ----------------------------------------------------

  transform(value: number, digitsInfo = '1.0-2'): string {
    const locale: string = this.localeService
      ? this.localeService.locale
      : 'en'

    return formatNumber(value, locale, digitsInfo)
  }

}
