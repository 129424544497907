export function formatEmptyStrings<T extends object>(
  data: T,
  keys: string[]
): T {
  const result = { ...data }

  for (const key of keys) {
    if (result[ key ] === '') {
      result[ key ] = null
    }
  }

  return result
}
