import { Pipe, type PipeTransform } from '@angular/core'

import { clone, map, prop } from 'ramda'

import { listJoin } from '@libs/utils'

// ----------------------------------------------------------

export interface AddressPipeOptions {
  field?: string
  keepEmpty?: boolean
}

// ----------------------------------------------------------

@Pipe({
  name: 'list'
})
export class ListPipe implements PipeTransform {

  transform(
    value: unknown[],
    args: AddressPipeOptions | string = {}
  ): string {
    value = value ?? []

    if (typeof args === 'string') {
      args = { field: args }
    }

    let items: unknown[] = args.field
      ? map(prop(args.field), value)
      : clone(value)

    if (!args.keepEmpty) {
      items = items.filter(i => !!i)
    }

    return listJoin(items)
  }

}
