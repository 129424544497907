import { pick } from 'ramda'

import type { AppointmentRole, ExtendedRole } from '../things/appointment-roles'
import type { IPlanData, SubscriptionStatus } from '../things/money'
import type { Company } from '../things/company'
import type { CurrencyCode } from './currency.model'
import type { Jurisdiction } from './jurisdiction.model'
import type { BillingPeriod } from './plan.model'

// ------------------------------------------------------------

export enum UserCompanyAccess {
  Admin = 'admin',
  StaffWrite = 'staffWrite',
  StaffRead = 'staffRead',
  Read = 'read',
  Documents = 'documents'
}

// ------------------------------------------------------------

export interface IUserCompany {
  id: string
  company: {
    id: string
    name: string
    jurisdiction: Jurisdiction
    currency: CurrencyCode
    picture: string | null
    twitter: string | null
    linkedin: string | null
    facebook: string | null
    metadata: object
    hasCaptable: boolean
    hasCohortPortal: boolean
  }
  subscriptions: {
    plan: IPlanData
    status: SubscriptionStatus
    expires: string
    billingPeriod: BillingPeriod
  }[]
  roles: AppointmentRole[]
  access: UserCompanyAccess
  hasFullAccess: boolean
  unsignedDocuments: number
  pendingDeadlinesCount: number
  hasOptionAgreement: boolean
}

// ------------------------------------------------------------

export interface IUserCompanyWithRoleInfo extends IUserCompany {
  extendedRoles: Set<ExtendedRole>
  roleInfo: string
}

// ------------------------------------------------------------

export interface LeftNavInfo {
  currentCompany: IUserCompanyWithRoleInfo | undefined
  otherCompanies: IUserCompanyWithRoleInfo[]
  moreDocumentsToSign: number
}

// ------------------------------------------------------------

export const userCompanyPicker: (company: Company) => IUserCompany[ 'company' ] = pick([
  'id',
  'name',
  'jurisdiction',
  'currency',
  'picture',
  'twitter',
  'linkedin',
  'facebook',
  'metadata',
  'hasCaptable',
  'hasCohortPortal'
])
