import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import * as Sentry from '@sentry/browser'

import { AppModule } from './app/app.module'

import { environment } from '@env/environment'
import { Profile } from '@env/models'

// ----------------------------------------------------------

Sentry.init(environment.sentryOptions)

// ----------------------------------------------------------

if (environment.profile === Profile.Production || environment.profile === Profile.Int) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err))
