<div *ngIf="address && address.isComplete()"
     fxLayout="row"
     fxLayoutAlign="start start"
     class="gap-8">
  <mat-icon fontIcon="icon-map-marker"
            class="s20"></mat-icon>

  <div>
    <div *ngIf="address.line1">{{ address.line1 }}</div>
    <div *ngIf="address.line2">{{ address.line2 }}</div>
    <div *ngIf="address.city">{{ address.city}}</div>
    <div *ngIf="address.postcode">{{ address.postcode }}</div>
    <div *ngIf="address.country">{{ country }}</div>
  </div>
</div>
