import { Inject, Injectable, type OnDestroy, RendererFactory2, type Renderer2 } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { NavigationEnd, Router } from '@angular/router'

import { Api, BackendService } from '@libs/backend'

import type { Observable, Subscription } from 'rxjs'
import { catchError, filter, of, take } from 'rxjs'

import { environment } from '@env/environment'
import type { User } from '@libs/models'

// ----------------------------------------------------

declare global {
  interface Window {
    _hsq: string[][]
    HubSpotConversations: {
      widget: {
        load?: () => void
        open?: () => void
      }
    }
    hsConversationsOnReady: [
      () => void
    ]
    hsConversationsSettings: {
      loadImmediately?: boolean
      identificationToken?: string
      identificationEmail?: string
    }
  }
}

// ----------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class HubspotService implements OnDestroy {
  private renderer: Renderer2
  private subscriptions: Subscription[] = []

  private firstNavigationEndEvent = true
  private isLoaded = false

  // ----------------------------------------------------

  public boot(user: User) {
    if (!environment.HUBSPOT_CLIENT_ID || this.isLoaded) {
      return
    }

    // set initial variables for hubspot
    window._hsq = window._hsq || []
    window.hsConversationsSettings = { loadImmediately: false }
    window.hsConversationsOnReady = [
      () => {
        if (!this.isLoaded) { // somehow this gets called twice even though their API docs says it is only run once - better be safe than sorry
          this.isLoaded = true
          window.HubSpotConversations.widget.load()
        }
      }
    ]

    // subscribes to router events to track page views
    this.subscriptions.push(this.getPageTrackingSubscripion())

    // get identity token from backend and initialise widget
    const sub = this.getHubspotIdentityToken().subscribe(({ token }) => {
      if (token) {
        window.hsConversationsSettings.identificationToken = token
        window.hsConversationsSettings.identificationEmail = user.email
      }

      this.injectScript()
    })

    this.subscriptions.push(sub)
  }

  // ----------------------------------------------------

  private getPageTrackingSubscripion(): Subscription {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.firstNavigationEndEvent) {
        window._hsq.push([ 'setPath', event.urlAfterRedirects ])

        this.firstNavigationEndEvent = false
      } else {
        window._hsq.push([ 'setPath', event.urlAfterRedirects ])
        window._hsq.push([ 'trackPageView' ])
      }
    })
  }

  // ----------------------------------------------------

  private getHubspotIdentityToken(): Observable<{ token: string | null }> {
    return this.api
      .one('hubspot', 'token')
      .get<{ token: string }>()
      .pipe(
        take(1),
        catchError(() => of({ token: null }))
      )
  }

  // ----------------------------------------------------

  private injectScript() {
    const script = this.renderer.createElement('script')
    script.type = 'module'
    script.src = `//js.hs-scripts.com/${environment.HUBSPOT_CLIENT_ID}.js`
    script.id = 'hs-script-loader'
    this.renderer.appendChild(this.document.body, script)
  }

  // ----------------------------------------------------

  openChat() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open()
    }
  }

  // ----------------------------------------------------

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe()
    }
  }

  // ----------------------------------------------------

  constructor (
    private readonly router: Router,
    private readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(Api) private api: BackendService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }
}
