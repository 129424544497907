<div [class.highlighted]="highlighted" fxLayout="row" fxLayoutAlign="start start" class="gap-16">

  <sl-avatar [entity]="entity" [size]="size" [sizeXs]="sizeXs"></sl-avatar>

  <div fxLayout="column" class="company-details gap-2">
    <div fxLayout="row" fxLayoutAlign="start center" class="gap-4">
      <span class="company-name">{{ displayData.name }}</span>
      <span class="text-dark-grey">{{ displayData.type }}</span>
    </div>
    <div fxLayout="row">
      <span *ngIf="displayData.registrarNumber" class="text-dark-grey text-bold" >{{ displayData.registrarNumber }}</span>
      <span *ngIf="displayData.registrarNumber && displayData.incorporated" class="text-dark-grey">&nbsp;•&nbsp;</span>
      <span *ngIf="displayData.incorporated" class="text-dark-grey" i18n>Incorporated {{ displayData.incorporated | slDate }}</span>
    </div>
    <div *ngIf="displayData.address" class="text-dark-grey">{{ displayData.address | address }}</div>
    <div *ngIf="displayData.isNew && !displayData.jurisdiction" class="text-dark-grey" i18n>... is available to create</div>
    <div *ngIf="displayData.isNew && displayData.jurisdiction" class="text-dark-grey">{{ displayData.jurisdiction | slJurisdiction }}</div>
  </div>
</div>
