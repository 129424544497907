import { Component, Input } from '@angular/core'

import { InfoCardData } from '../../models/info-card-data.model'

// ----------------------------------------------------------

@Component({
  selector: 'sl-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: [ './info-card.component.scss' ]
})
export class InfoCardComponent {
  @Input() cardData: InfoCardData
}

//TODO: Will need to eventually handle currency vs number vs other input? (To be reconciled when comp-table merged)
