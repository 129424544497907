import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core'

import { StripeService } from '../../services/stripe.service'
import { _log } from '@libs/utils'

// ----------------------------------------------------------

@Component({
  selector: 'sl-stripe-card',
  template: `
    <label *ngIf="label" [attr.for]="uid">{{label}}</label>
    <div [attr.id]="uid" #target></div>
  `,
  styleUrls: [ './stripe-card.component.scss' ]
})
export class StripeCardComponent implements OnInit, OnDestroy {
  @Input() label?: string

  @Output() validityChanged = new EventEmitter<boolean>()

  @ViewChild('target') target: ElementRef

  cardElement: stripe.elements.Element

  readonly uid = `card_${Date.now()}`

  private _isValid = false
  public get isValid() {
    return this._isValid
  }
  public set isValid(value: boolean) {
    if (this._isValid !== value) {
      this._isValid = value
      this.validityChanged.emit(value)
    }
  }

  // ----------------------------------------------------

  constructor(
    private stripeService: StripeService
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    setTimeout(async () => {
      this.cardElement = await this.stripeService.createAndMountElement(this.target.nativeElement, 'card')

      this.cardElement.on('change', (event: stripe.elements.ElementChangeResponse) => {
        _log(`StripeCardComponent.cardElement.change(event): cardElement`, event, this.cardElement, this)

        this.isValid = event.complete && !event.error
      })
    }, 0)
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    // _log(`StripeCardComponent.ngOnDestroy(uid = ${this.uid})`, this.cardElement, this)

    if (this.cardElement) {
      this.cardElement.destroy()
      this.cardElement = null
    }
  }
}
