import { Component, Inject } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { UNSUBSCRIBE_FEEDBACK_OPTIONS } from '@libs/models'
import { MatRadioChange } from '@angular/material/radio'

// ----------------------------------------------------------

export interface IUnsubscribeDialogResult {
  reason: string
  comment: string
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-unsubscribe-dialog',
  templateUrl: './unsubscribe-dialog.component.html',
  styleUrls: [ './unsubscribe-dialog.component.scss' ]
})
export class UnsubscribeDialogComponent {
  options = UNSUBSCRIBE_FEEDBACK_OPTIONS
  selected: string

  commentForm = this.fb.control('')

  // ----------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: void,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UnsubscribeDialogComponent, IUnsubscribeDialogResult | null>,
  ) {}

  // ----------------------------------------------------

  onSelectOption(event: MatRadioChange) {
    this.selected = event.value
  }

  // ----------------------------------------------------

  close() {
    this.dialogRef.close(null)
  }

  // ----------------------------------------------------

  async onUnsubscribeClicked() {
    this.dialogRef.close({ reason: this.selected, comment: this.commentForm.value })
  }
}
