export { ModalsModule } from './lib/modals.module'

export {
  ConfirmDialogOptions,
  PromptDialogOptions
} from './lib/modal-options'

export { ModalService } from './lib/modal.service'

export { SimpleDialogWrapperComponent } from './lib/simple-dialog-wrapper/simple-dialog-wrapper.component'

export {
  PopupState,
  popupFadeAndScaleAnimations
} from './lib/modal-animations'
