import { Inject, Injectable } from '@angular/core'

import type { Observable } from 'rxjs'
import { distinctUntilChanged, startWith } from 'rxjs/operators'

import type { IProductHowMuchData, Fee, PricingModel, ProductId } from '@libs/models'

import { StoreService } from '@libs/services'
import { Api, BackendService } from '@libs/backend'
import { PriceCalculatorService } from './price-calculator.service'

// ----------------------------------------------------------

export interface PriceInfoData extends IProductHowMuchData {
  value?: number
}

// ----------------------------------------------------------

export interface PriceInfoParams {
  companyId: string
  productId: ProductId
  value?: number
}

// ----------------------------------------------------------

@Injectable()
export class PriceInfoStoreService extends StoreService<PriceInfoData, PriceInfoParams> {

  readonly currency$ = this.select(this.data$, data => data.currency)
  readonly billedWithVat$ = this.select(this.data$, data => data.billedWithVat)
  readonly stages$ = this.select(this.data$, data => data.pricing)
  readonly discountedAmount$ = this.select(this.data$, data => data.discountedAmount)

  readonly value$ = this.select(this.data$, data => data.value).pipe(
    startWith(0),
    distinctUntilChanged(),
    // logger(`value$`),
  )

  // ----------------------------------------------------

  readonly pricingModel$ = this.select(this.stages$, stages => stages.BALANCE)
  readonly depositPricingModel$ = this.select(this.stages$, stages => stages.DEPOSIT)
  readonly agreementPricingModel$ = this.select(this.stages$, stages => stages.AGREEMENT)

  // ----------------------------------------------------

  /**
   * Represents the actual estimated amount the user will pay, factoring any promotions or membership related discounts.
   */
  readonly discountedFee$: Observable<Fee> = this.select(
    this.currency$,
    this.billedWithVat$,
    this.discountedAmount$,
    (currency, billedWithVat, amount) => ({
      amount,
      currency,
      billedWithVat,
    })

  )

  /**
   * Represents the original price, prior to any discounts having been applied.
   */
  readonly totalFee$: Observable<Fee> = this.select(
    this.currency$,
    this.billedWithVat$,
    this.pricingModel$,
    this.value$,
    (currency, billedWithVat, pricingModel, value) => ({
      amount: this.getPrice(pricingModel, value),
      currency,
      billedWithVat,
    })
  )

  readonly depositFee$: Observable<Fee | null> = this.select(
    this.currency$,
    this.billedWithVat$,
    this.depositPricingModel$,
    this.value$,
    (currency, billedWithVat, pricingModel, value) => {
      if (!pricingModel) {
        return null
      }

      return {
        amount: this.getPrice(pricingModel, value),
        currency,
        billedWithVat,
      }
    }
  ) // .pipe(logger(`depositFee$`))

  readonly agreementFee$: Observable<Fee | null> = this.select(
    this.currency$,
    this.billedWithVat$,
    this.agreementPricingModel$,
    this.value$,
    (currency, billedWithVat, pricingModel, value) => {
      if (!pricingModel) {
        return null
      }

      return {
        amount: this.getPrice(pricingModel, value),
        currency,
        billedWithVat,
      }
    }
  )

  // ----------------------------------------------------

  readonly setValue = this.updater((state, value: number) => ({
    ...state,
    data: { ...state.data, value }
  }))

  // ----------------------------------------------------

  protected override loadData(
    { companyId, productId, value }: PriceInfoParams,
  ): Observable<PriceInfoData> {
    return this.api
      .one('companies', companyId)
      .all(productId)
      .withParams(value ? { chargeableAmount: value.toString() } : {})
      .get<IProductHowMuchData>('howMuch')
  }

  // ----------------------------------------------------

  getPrice(
    pricingModel: PricingModel | undefined,
    value: number,
  ): number | null {
    return pricingModel
      ? this.priceCalculator.getPrice(pricingModel, value)
      : null
  }

  // ----------------------------------------------------

  constructor(
    @Inject(Api) private api: BackendService,
    private priceCalculator: PriceCalculatorService,
  ) {
    super()
  }

}
