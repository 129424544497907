<a [href]="href"
   [target]="openInNewTab ? '_blank' : ''"
   mat-list-item>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       class="gap-16">
    <img src="/assets/images/app/left-nav/{{ image }}.svg"
         alt="External link"
         class="p-8"
         i18n-alt>
    <div>{{ label }}</div>
  </div>
</a>
