// ------------------------------------------------------------

export class ColourGenerator {
  idx = 0

  // ----------------------------------------------------

  constructor(private colours: string[]) {}

  // ----------------------------------------------------

  next() {
    const colour = this.colours[ this.idx++ % this.colours.length ]
    // console.info(`ColourGenerator.next(idx = ${this.idx - 1}, colour = %c  %c ${colour})`, `background-color: ${colour}`, '')
    return colour
  }

  // ----------------------------------------------------

  fromId(id: string) {
    const colour = this.colours[ id.charCodeAt(id.length - 1) % this.colours.length ]
    // console.info(`ColourGenerator.fromId(id = ${id}, colour = %c  %c ${colour})`, `background-color: ${colour}`, '')
    return colour
  }
}

// ----------------------------------------------------------

export function hexToRgba(hex: string, alpha: number = 1) {
  let cols: number[]

  if (hex.length === 6 || hex.length === 7) {
    const m = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (m) {
      cols = m.slice(1, 4).map(v => parseInt(v, 16))
    }
  } else if (hex.length === 3 || hex.length === 4) {
    const m = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex)

    if (m) {
      cols = m.slice(1, 4).map(v => parseInt(v + v, 16))
    }
  }

  return cols
    ? `rgba(${[ ...cols, alpha ].join(', ')})`
    : null
}
