import { EventCategory } from '../../models/category.model'
import type { CohortTeam } from '../cohort-manager/cohort'
import type { ApiFieldSpec } from '../model'
import { Products } from '../money/product'
import { InvestmentCollection } from '../stock/investment'

import type { IEventViewState } from './event'
import { InvestmentEvent } from './investment-event'
import type { LoanEvent } from './loan-event'
import { LoanCollection } from '../loan/loan'
import { ShareClass } from '../stock'

// --------------------------------------------------------

export class CohortFundingEvent extends InvestmentEvent implements LoanEvent {
  override readonly domain = 'cohortFundings'

  override readonly category = EventCategory.CohortFunding
  readonly productId = Products.CohortFunding

  readonly cohortTeam: CohortTeam

  shareClass: ShareClass
  optionPoolPercentage: number | null
  optionCount: number | null

  loans = new LoanCollection()
  override investments = new InvestmentCollection()

  // ------------------------------------------------------

  constructor({
    cohortTeam,
    optionPoolPercentage = null,
    optionCount = null,
    shareClass = null,
    ...data
  }) {
    super({
      cohortTeam,
      optionPoolPercentage,
      optionCount,
      shareClass,
      ...data
    })
  }

  // ----------------------------------------------------

  override get safeName(): string {
    // TODO: Add condition when other cohort owners are introduced
    return $localize`Entrepreneur First Funding`
    // return $localize`Cohort funding`
  }

  // ----------------------------------------------------

  override get estimatedInvestment(): number {
    return this.loans.reduce((total, loan) => total + loan.amount, 0)
  }

  // ------------------------------------------------------

  get estimatedOptionCount(): number {
    if (this.optionCount) {
      return this.optionCount
    }

    // Pre-money, taken from Round.estimatedOptionCount
    const poolSize = this.optionPoolPercentage / 100

    return Math.max(0, Math.floor(
      this.company.outstandingShareCount * poolSize / (1 - poolSize) - this.company.optionCount
    ))
  }

  // ------------------------------------------------------

  get estimatedPostMoneyShareCount(): number {
    if (this.shareCount) {
      return this.shareCount
    } else if (this.investments.length === 0) {
      return 0
    }

    // intendedOwnership is post-money, need to add new options to share count to calculate
    const intendedOwnership = this.investments.item(0).intendedOwnership / 100
    const estimatedPostMoneyDilutedShareCount = this.company.outstandingShareCount + this.estimatedOptionCount

    return Math.ceil(
      estimatedPostMoneyDilutedShareCount * intendedOwnership / (1 - intendedOwnership)
    )
  }

  // ------------------------------------------------------

  get pricePerShare(): number {
    return this.company.shareNominalValue
  }

  // ------------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'shareClass',
      { key: 'cohortTeam', include: 'create' },
      { key: 'optionPoolPercentage', include: 'create' }
    ]
  }

  // ------------------------------------------------------

  override getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'raise', 'cohort-fundings', this.id ]
    }
  }
}
