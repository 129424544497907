import { Pipe, PipeTransform } from '@angular/core'

import { getCountryNameByCode, CountryCode } from '@libs/models'

// ----------------------------------------------------------

const ADDRESS_FIELDS: string[] = [
  'line1',
  'line2',
  'city',
  'postcode',
  'country'
]

// ----------------------------------------------------------

export interface AddressDisplayOptions {
  countryLabel?: 'none' | 'code' | 'name'
  countryDefault?: CountryCode
  prefix?: string
  suffix?: string
  fieldsToDisplay?: string[]
}

// ----------------------------------------------------------

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: unknown, options: AddressDisplayOptions = {}): string | null {
    if (value == null || typeof value !== 'object') {
      return null
    }

    options = {
      countryLabel: 'name',
      countryDefault: 'GB',
      prefix: '',
      suffix: '',
      ...options
    }

    // console.info(`value: %o, options: %o`, value, options)

    const addressFields = options.fieldsToDisplay ?? ADDRESS_FIELDS

    const items = addressFields
      .reduce((out, fieldName) => {
        if (fieldName in value && value[ fieldName ]) {
          let fieldValue = value[ fieldName ]

          // console.log(`${fieldName}: %c"${fieldValue}"`, 'color: #07f')

          if (fieldName === 'country') {
            if (options.countryLabel === 'none' || options.countryDefault && fieldValue === options.countryDefault) {
              return out
            }

            if (options.countryLabel === 'name') {
              fieldValue = getCountryNameByCode(fieldValue)
            }
          }

          // console.log(`  ... pushed %c"${fieldValue}"`, 'color: #07f')
          out.push(fieldValue)
        }

        return out
      }, [])

    // console.dir(items)

    if (items.length === 0) {
      return null
    }

    return options.prefix + items.join(', ') + options.suffix
  }

}
