import { Component, Input, HostBinding } from '@angular/core'

// ------------------------------------------------------------

@Component({
  selector: 'sl-sticky-bar',
  templateUrl: './sticky-bar.component.html',
  styleUrls: [ './sticky-bar.component.scss' ]
})
export class StickyBarComponent {
  @Input() align: 'start' | 'center' | 'space-between' | 'end' = 'center'
  @Input() gap = 8

  @Input() disableAnimation = false

  @HostBinding('style.top') @Input() top?: string
  @HostBinding('style.bottom') @Input() bottom?: string
  @HostBinding('class.animated') get isAnimated() { return !this.disableAnimation }
}
