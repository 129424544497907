import { Inject, Injectable } from '@angular/core'

import { type Observable, of } from 'rxjs'
import { catchError, map, switchMap, toArray } from 'rxjs/operators'

import type { Jurisdiction, Region, IAddressDataWithoutId, CompanyType, CurrencyCode } from '@libs/models'
import { type IIrelandRegisteredCompanyData } from '@app/companies/models/company-data'

import { Api, BackendService } from '@libs/backend'

// ----------------------------------------------------------

export interface IIrelandRegisteredCompanyResponse {
  alreadyCreated: boolean
  registrarNumber: string
  region: Region.Commonwealth
  jurisdiction: Jurisdiction.Ireland
  currency: CurrencyCode
  name: string
  rawName: string
  type: CompanyType
  incorporated: string
  addressSnippet?: string
  address?: IAddressDataWithoutId
  postCode?: string
  companyIndicator: string
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class RegistrarService {

  constructor(
    @Inject(Api) private api: BackendService
  ) {}

  // ----------------------------------------------------

  getCompany(
    data: IIrelandRegisteredCompanyData
  ): Observable<IIrelandRegisteredCompanyData> {
    return this.api.one('registrar/company')
      .get<IIrelandRegisteredCompanyData>({
        registrarNumber: data.registrarNumber,
        companyIndicator: data.companyIndicator
      })
      .pipe(
        map(i => ({
          field: 'companiesHouseNumber',
          ...i
        } as IIrelandRegisteredCompanyData))
      )
  }

  // ----------------------------------------------------

  searchCompaniesByName(
    q: string,
    limit = 5
  ): Observable<IIrelandRegisteredCompanyData[]> {
    return this.api.all('registrar/search')
      .get<IIrelandRegisteredCompanyResponse[]>({ name: q })
      .pipe(
        switchMap(items => items.slice(0, limit)),
        map(item => ({
          field: 'companiesHouseNumber',
          ...item
        } as IIrelandRegisteredCompanyData)),
        toArray(),
        catchError(() => of([]))
      )
  }
}
