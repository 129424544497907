import { Component, Inject } from '@angular/core'

import { Investor } from '@libs/models'
import { SideNavData, SideNavRef } from '@libs/utils'

import { IInvestorSidenavData, IInvestorEntity } from '../../models/investor-sidenav'

// ----------------------------------------------------------

@Component({
  selector: 'sl-investor-sidenav',
  templateUrl: './investor-sidenav.component.html'
})
export class InvestorSidenavComponent {
  constructor(
    @Inject(SideNavData) public data: IInvestorSidenavData,
    private sidenavRef: SideNavRef<InvestorSidenavComponent, Investor>
  ) {}

  // ----------------------------------------------------

  onEntitySelected(entity: IInvestorEntity) {
    this.sidenavRef.close(entity.investor)
  }
}
