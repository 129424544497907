<div *ngIf="showContent; then contentBlock else ghostBlock"
     @showHideGhost></div>

<ng-template #ghostBlock>
  <div class="ghost-grid"
       [ngStyle]="styles"
       [ngClass]="classes"
       fxLayout="row"
       fxLayoutAlign="space-between start">
    <div *ngFor="let gridItem of _itemChildren; let i = index"
         [style.width.%]="100 * (gridItem._colSpan || cols) / cols"
         [style.marginBottom.px]="rowGap"
         [ngSwitch]="gridItem.slGhostType">
      <div [ngStyle]="gridItem.slGhostStyles">
        <ng-container *ngSwitchCase="'document'">
          <div class="ghost-document">
            <div class="line right bold"></div>
            <div class="line right"></div>
            <div class="line right"></div>
            <br>
            <br>
            <br>
            <br>
            <div class="line large center bold"></div>
            <div class="line large center"></div>
            <br>
            <br>
            <br>
            <div class="line bold"></div>
            <div class="line"></div>
            <br>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'line'">
          <div class="line bold"></div>
          <div class="line"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'text'">
          <div class="line"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'heading'">
          <div class="line heading"></div>
        </ng-container>

        <ng-container *ngSwitchCase="'card'">
          <div class="ghost-card" [style.minHeight.px]="gridItem.slRowHeight">
            <div class="line bold"></div>
            <br>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'table'">
          <table mat-table
                 [dataSource]="rowsBuilder(gridItem._rows)">
            <ng-container matColumnDef="c">
              <th mat-header-cell
                  *matHeaderCellDef>
                <div></div>
              </th>
              <td mat-cell
                  *matCellDef="let model">
                <div></div>
              </td>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="colsBuilder(gridItem._cols)"></tr>
            <tr mat-row
                *matRowDef="let row; columns: colsBuilder(gridItem._cols)"></tr>
          </table>
        </ng-container>

        <ng-container *ngSwitchCase="'avatar'">
          <div class="ghost-avatar">
            <div class="circle"></div>
            <div class="line bold"></div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'list'">
          <mat-grid-list [cols]="gridItem._cols"
                         [rowHeight]="gridItem.slRowHeight"
                         gutterSize="20px">
            <mat-grid-tile *ngFor="let d of colsBuilder(gridItem._cols)"
                           class="mat-elevation-z8">
              <div class="grid-content"
                   fxLayout="column"
                   fxLayoutAlign="start start">
                <div class="line"></div>
                <div class="line"></div>
              </div>
              <mat-grid-tile-footer>
                <div class="line"></div>
              </mat-grid-tile-footer>
            </mat-grid-tile>
          </mat-grid-list>
        </ng-container>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentBlock>
  <div @showContent>
    <ng-template [cdkPortalOutlet]="contentPortal"></ng-template>

    <ng-content></ng-content>
  </div>
</ng-template>
