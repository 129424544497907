import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { PromptDialogOptions } from '../modal-options'

// ----------------------------------------------------------

@Component({
  selector: 'sl-prompt-dialog',
  templateUrl: './prompt-dialog.component.html'
})
export class PromptDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogOptions
  ) {}
}
