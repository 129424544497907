export type CardBrand =
  | 'amex'
  | 'mastercard'
  | 'visa'

// ----------------------------------------------------------

export interface Card {
  id: string
  label: string
  companyId: string
  default: boolean
  brand: CardBrand
  expMonth: number
  expYear: number
  last4: string
}

// ----------------------------------------------------------

export interface CardWithExpiry extends Card {
  expiry: Date
}

// ----------------------------------------------------------

export const CardBrands: Record<CardBrand, { brand: string, image: string }> = {
  visa: {
    brand: `Visa`,
    image: '/assets/images/app/cards/visa.svg'
  },
  mastercard: {
    brand: `Mastercard`,
    image: '/assets/images/app/cards/mastercard.svg'
  },
  amex: {
    brand: `American Express`,
    image: '/assets/images/app/cards/amex.svg'
  }
}
