/* eslint-disable no-multi-spaces */

import type { Params } from '@angular/router'

import { createSelector } from '@ngrx/store'
import { createRouterSelector, getRouterSelectors } from '@ngrx/router-store'

// ------------------------------------------------------------

export const selectRouterState = createRouterSelector()

// ------------------------------------------------------------

export const {
  selectCurrentRoute,   // select the current route
  selectUrl,            // select the current url
  selectFragment,       // select the current route fragment
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteData,      // select the current route data
} = getRouterSelectors()

// ------------------------------------------------------------

/**
 * The default `selectRouteParams` selector returned by the
 * `getRouterSelectors()` function only gets the params on
 * the leaf child route segment, so we create our own version
 * that collects parameters from all route segments along the
 * current route.
 *
 * @see https://ngrx.io/guide/router-store/selectors#extracting-all-params-in-the-current-route
 */
export const selectRouteParams = createSelector(
  selectRouterState,
  routerState => {
    let currentRoute = routerState?.state?.root
    let params: Params = {}

    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild

      params = {
        ...params,
        ...currentRoute.params,
      }
    }

    return params
  }
)

// ------------------------------------------------------------

export const selectRouteParam = <T = string>(key: string) => createSelector(
  selectRouteParams,
  params => params?.[ key ] as T
)
