import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UntypedFormBuilder } from '@angular/forms'

import { ModalComponent } from '@libs/utils'

import { CompanyMatch } from '@app/entities/models/company-match'

// ----------------------------------------------------

@Component({
  templateUrl: './request-company-access-dialog.component.html',
})
export class RequestCompanyAccessDialogComponent extends ModalComponent<RequestCompanyAccessDialogComponent, CompanyMatch>{

  constructor(
    fb: UntypedFormBuilder,
    dialogRef: MatDialogRef<RequestCompanyAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: CompanyMatch
  ) {
    super(fb, dialogRef, data)
  }
}
