import { Injectable } from '@angular/core'

import type { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { omit } from 'ramda'
import { Query, gql } from 'apollo-angular'

import type { IUserData, UserAdditionalInfo } from '@libs/models'

// ----------------------------------------------------------

export interface IUserQueryUserData extends Omit<IUserData, 'additionalInfo'> {
  additionalInfo: UserAdditionalInfo
  __typename: 'UserExcerpt'
}

export interface IUsersQueryData {
  users: IUserQueryUserData[]
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class UsersQuery extends Query<IUsersQueryData, { ids: string[] }> {
  override readonly document = gql`
    query getUsers($ids: [ ID! ]!) {
      users(ids: $ids) {
        id
        picture
        email
        phone
        firstName
        lastName
        address {
          id
          line1
          line2
          city
          postcode
          country
        }
        sex
        connected
        description
        facebook
        twitter
        linkedin
        additionalInfo
        signature
        locale
      }
    }
  `

  // ----------------------------------------------------

  getUsers(ids: string[]): Observable<IUserData[]> {
    return this.watch({ ids }, {
      fetchPolicy: 'no-cache'
    })
      .valueChanges
      .pipe(
        // logger(`UsersQuery.getUsers$: result`),
        filter(result => !!result.data),
        map(result => result.data.users.map(userData => omit([ '__typename' ], { ...userData }))),
      )
  }
}
