import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { YouTubePlayerModule } from '@angular/youtube-player'

import { LetModule } from '@ngrx/component'

// ----------------------------------------------------------

import { AngularMaterialModule } from '@libs/material'
import { ModalsModule } from '@libs/modals'

import { COMPONENTS, EXPORT_COMPONENTS } from './components'
import { PIPES } from './pipes'
import { DebugConfig, EnvironmentConfig, GlobalObject } from './tokens'

import { DebugOptions, environment } from '@env/environment'
import { SharedDirectivesModule } from './directives.module'
import { EntityComponentsModule } from './modules/entities/entity-components.module'

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    YouTubePlayerModule,
    LetModule,
    ModalsModule,
    SharedDirectivesModule,
    EntityComponentsModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...EXPORT_COMPONENTS,
    ...PIPES
  ],
  providers: [
    {
      provide: EnvironmentConfig,
      useValue: environment
    },
    {
      provide: DebugConfig,
      useValue: DebugOptions
    },
    {
      provide: GlobalObject,
      useValue: window
    },
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    LetModule,
    ModalsModule,
    SharedDirectivesModule,
    EntityComponentsModule,
    ...EXPORT_COMPONENTS,
    ...PIPES
  ]
})
export class SharedModule {}
