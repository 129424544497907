import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { percentage } from '@libs/utils'

// --------------------------------------------------------

@Component({
  selector: 'sl-progress-bar',
  template: `
    <mat-progress-bar [value]="barValue"
                      mode="determinate"
                      color="accent"></mat-progress-bar>
  `,
  styleUrls: [ './progress-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input()
  set percentage(pct: number) {
    this.barValue = 100 * Math.max(0, Math.min(1, pct))
  }

  _value = 0
  _total = 0

  @Input()
  set value(value: number) {
    this._value = value
    this.setBarValue()
  }

  @Input()
  set total(total: number) {
    this._total = total
    this.setBarValue()
  }

  barValue = 0

  // ----------------------------------------------------

  setBarValue() {
    const p = percentage(this._value, this._total)

    this.barValue = 100 * Math.max(0, Math.min(1, p))
  }
}
