
export enum AuthStorageKeys {
  ID_TOKEN = 'idToken',
  ACCESS_TOKEN = 'accessToken',
  EXPIRES_AT = 'expiresAt',
  PROFILE = 'profile',
  REDIRECT_URL = 'redirectUrl',
  EMAIL_TOKEN = 'token',
  WHO_AM_I = 'whoAmI',
  USER_ID = 'userId'
}
