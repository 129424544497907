import { Injectable } from '@angular/core'

import { Angulartics2 } from 'angulartics2'

import { User } from '@libs/models'
import { AnalyticsKeys } from '../analytics.constants'

// ----------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private angulartics2: Angulartics2
  ) {}

  // ----------------------------------------------------

  disableForPlatformAdmins(user: User): void {
    if (user.isPlatformAdmin) {
      // Disables Angulartics2
      this.angulartics2.settings.developerMode = true
    }
  }

  // ----------------------------------------------------

  trackEvent(action: AnalyticsKeys = AnalyticsKeys.NEW, label?: string) {
    this.angulartics2.eventTrack.next({
      action,
      properties: {
        category: AnalyticsKeys.COMMANDS,
        label,
        value: 1
      }
    })
  }
}
