import { Pipe, PipeTransform } from '@angular/core'

/**
 * Calculates the Return On Investment (ROI) based on the investment and payout
 * @param investment {number}: The amount of money invested by a shareClass or shareholder
 * @param payout {number}: The amount of money paid to that shareClass or shareholder after liquidation
 * @returns {?number}: Return on investment
 */
@Pipe({
  name: 'roi'
})
export class RoiPipe implements PipeTransform {

  transform (investment: number, payout: number): number {

    // Validate params
    if (!Number.isFinite(investment) || !Number.isFinite(payout)) {
      return undefined
    }

    return investment > 0
      ? Math.round(100 * (payout - investment) / investment) / 100
      : 0
  }
}
