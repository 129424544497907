import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ConfirmDialogOptions } from '../modal-options'

// ----------------------------------------------------------

@Component({
  selector: 'sl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogOptions
  ) {}
}
