<div class="wrapper" cdkScrollable>
  <div class="item-container">
    <sl-notification-item *ngFor="let notification of messageStore.items"
                          [notification]="notification">
    </sl-notification-item>

    <div *ngIf="!messageStore.items.length" class="placeholder">
      <span class="text-italic" i18n>
        Notifications appear here, stay tuned!
      </span>
      <img src="/assets/images/emoji/smirk.svg" width="24" height="24" alt="Smirk emoji" i18n-alt>
    </div>
  </div>
</div>
