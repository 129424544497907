import { Component, ElementRef, HostBinding, Optional, Self, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'

import { FocusMonitor } from '@angular/cdk/a11y'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatSelect } from '@angular/material/select'

import { Subject } from 'rxjs'

import { Countries, CountryCode, Country, CountriesByCode } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-country-select',
  templateUrl: './country-select.component.html',
  providers: [
    { provide: MatFormFieldControl, useExisting: CountrySelectComponent },
  ]
})
export class CountrySelectComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<CountryCode> {
  static nextId = 0

  countries = Countries

  selectOpened = false

  // ----------------------------------------------------

  @Output() changed = new EventEmitter<CountryCode>()

  // ----------------------------------------------------

  value: CountryCode = 'GB'

  // ----------------------------------------------------

  disabled = false

  // ----------------------------------------------------

  @HostBinding() id = `sl-country-select-${CountrySelectComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''
  @ViewChild(MatSelect, { static: true }) input: MatSelect

  // eslint-disable-next-line rxjs/finnish
  stateChanges = new Subject<void>()

  controlType = 'sl-country-select'

  focused = false

  // ----------------------------------------------------

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next()
  }
  _placeholder: string

  // ----------------------------------------------------

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: boolean) {
    this._required = value

    this.stateChanges.next()
  }
  _required = false

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: CountryCode) => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {}

  // ----------------------------------------------------

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin
      this.stateChanges.next()
    })
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement)
  }

  // ----------------------------------------------------

  get selectedCountry(): Country {
    // _log(`CountrySelectComponent.selectedCountry: value, selectedCountry`, this.value, CountriesByCode[ this.value ])
    return CountriesByCode[ this.value ]
  }

  // ----------------------------------------------------

  onChanged(
    value: CountryCode
  ) {
    this.onChange(value)
    this.stateChanges.next()
    this.changed.emit(value)
  }

  // ----------------------------------------------------

  writeValue(
    modelValue: CountryCode
  ): void {
    if (modelValue) {
      this.value = modelValue
    }
  }

  // ----------------------------------------------------

  registerOnChange(
    fn: (newValue: CountryCode) => void
  ): void {
    this.onChange = fn
  }

  // ----------------------------------------------------

  registerOnTouched(
    fn: () => void
  ): void {
    this.onTouch = fn
  }

  // ----------------------------------------------------

  setDisabledState(
    isDisabled: boolean
  ): void {
    this.disabled = isDisabled
  }

  // ----------------------------------------------------

  setDescribedByIds(
    ids: string[]
  ): void {
    this.describedBy = ids.join(' ')
  }

  // ----------------------------------------------------

  onContainerClick(): void {
    this.input.open()
  }

  get empty(): boolean {
    return false
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  get errorState(): boolean {
    return this.input.errorState
  }

  get autofilled(): boolean {
    return false
  }

}
