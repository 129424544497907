export type UserNotificationType =
 | 'DocumentCreated'
 | 'DocumentSharedToAdmins'
 | 'ReceivedSignedByAll'
 | 'SentSignedByAll'
 | 'SignatorySigned'
 | 'WitnessSigned'

// use the same keys as in the BE
export enum UserNotificationKeys {
  DocumentCreated = 'DOCUMENT_CREATED',
  DocumentSharedToAdmins = 'DOCUMENT_SHARED_TO_ADMINS',
  ReceivedSignedByAll = 'DOCUMENT_FULLY_SIGNED_TO_SIGNATORIES',
  SentSignedByAll = 'DOCUMENT_FULLY_SIGNED_TO_COMPANY_ADMINS',
  SignatorySigned = 'DOCUMENT_SIGNED',
  WitnessSigned = 'DOCUMENT_SIGNED_BY_WITNESS'
}

// ------------------------------------------------------------

export type UserNotifications = {
  [ key in UserNotificationKeys ]: boolean
}

// ------------------------------------------------------------

export const NOTIFICATION_KEYS = Object.values(UserNotificationKeys)

// ------------------------------------------------------------


export const NOTIFICATIONS_GROUPS: Array<{
  label: string
  items: UserNotificationKeys[]
}> = [
  {
    label: $localize`As an admin, notify me when...`,
    items: [
      UserNotificationKeys.DocumentCreated,
      UserNotificationKeys.SignatorySigned,
      UserNotificationKeys.WitnessSigned,
      UserNotificationKeys.SentSignedByAll
    ]
  },
  {
    label: $localize`As a recipient, notify me when...`,
    items: [
      UserNotificationKeys.DocumentSharedToAdmins,
      UserNotificationKeys.ReceivedSignedByAll
    ]
  }
]

// ------------------------------------------------------------

export const NOTIFICATION_LABELS: {
  [ key in UserNotificationKeys ]: string
} = {
  [ UserNotificationKeys.DocumentCreated ]: $localize`A new document is created`,
  [ UserNotificationKeys.SignatorySigned ]: $localize`A signatory signs a document`,
  [ UserNotificationKeys.WitnessSigned ]: $localize`A witness signs a document`,
  [ UserNotificationKeys.SentSignedByAll ]: $localize`A document is signed by all parties`,
  [ UserNotificationKeys.DocumentSharedToAdmins ]: $localize`My company signatory receives a document to sign`,
  [ UserNotificationKeys.ReceivedSignedByAll ]: $localize`A document is signed by all signatories`
}
