
export enum DocumentCategory {
  Policies = 'CompanyPolicies',
  InvestorAgreement = 'InvestorAgreement',
  QuickAgreement = 'QuickAgreement',
  CommercialAgreement = 'CommercialAgreement',
  Certificate = 'Certificate'
}

export const DocumentCategories: Record<DocumentCategory, string> = {
  [ DocumentCategory.Policies ]: $localize`Company Policies`,
  [ DocumentCategory.InvestorAgreement ]: $localize`Investor Agreement`,
  [ DocumentCategory.QuickAgreement ]: $localize`Quick Agreement`,
  [ DocumentCategory.CommercialAgreement ]: $localize`Commercial Agreement`,
  [ DocumentCategory.Certificate ]: $localize`Certificate`
}

// ------------------------------------------------------------

export enum EventCategory {
  AdvanceAssurance = 'AdvanceAssurance',
  Board = 'Board',
  BoardMeeting = 'BoardMeeting',
  BSAAIR= 'BSAAIR',
  CLNFutureFund = 'FutureFund',
  CohortFunding = 'CohortFunding',
  ConfirmationStatement = 'ConfirmationStatement',
  ConsultancyAgreement = 'ConsultancyAgreement',
  DirectorAppointment = 'DirectorAppointment',
  DirectorDetailUpdate = 'DirectorDetailUpdate',
  DirectorTermination = 'DirectorTermination',
  EmiValuation = 'EMIValuation',
  Exit = 'Exit',
  FounderLoan ='FounderLoan',
  FundingRound = 'FundingRound',
  HistoricalASA = 'HistoricalASA',
  HistoricalBSAAIR = 'HistoricalBSAAIR',
  HistoricalCLN = 'HistoricalCLN',
  HistoricalFounderLoan = 'HistoricalFounderLoan',
  HistoricalRound = 'HistoricalRound',
  InstantConversion = 'InstantConversion',
  InstantInvestment = 'InstantInvestment',
  InstantShares = 'IssueShares',
  OptionExercise = 'OptionExercise',
  OptionGrant = 'OptionGrant',
  OptionReturn = 'OptionReturn',
  OptionPool = 'OptionPool',
  OptionScheme = 'OptionScheme',
  Proposal = 'Proposal',
  RegularReport = 'RegularReport',
  Repayment = 'Repayment',
  ResearchAssurance = 'ResearchAssurance',
  ResearchClaim = 'ResearchClaim',
  SeedFAST = 'SeedFAST',
  SeedNOTE = 'SeedNOTE',
  SeedSAFE = 'SeedSAFE',
  SeedSAFT = 'SeedSAFT',
  SEISCompliance = 'SEISCompliance',
  ShareClassRegistration = 'ShareClassRegistration',
  ShareTransfer = 'ShareTransfer',
  StockSplit = 'ShareSplit',
  StopVesting = 'StopVesting',
  Team = 'Team',
}

export const EventCategories: Record<EventCategory, string> = {
  [ EventCategory.AdvanceAssurance ]: $localize`Advance Assurance`,
  [ EventCategory.Board ]: $localize`Board`,
  [ EventCategory.BoardMeeting ]: $localize`Board Meeting`,
  [ EventCategory.BSAAIR ]: $localize`BSA AIR`,
  [ EventCategory.CLNFutureFund ]: $localize`Future Fund`,
  [ EventCategory.CohortFunding ] : $localize`Cohort Funding`,
  [ EventCategory.CohortFunding ]: $localize`Cohort Funding`,
  [ EventCategory.ConfirmationStatement ]: $localize`Confirmation Statement`,
  [ EventCategory.ConsultancyAgreement ]: $localize`Consultancy Agreement`,
  [ EventCategory.DirectorAppointment ]: $localize`Director Appointment`,
  [ EventCategory.DirectorDetailUpdate ]: $localize`Change of Director's Details`,
  [ EventCategory.DirectorTermination ] : $localize`Director Termination`,
  [ EventCategory.EmiValuation ]: $localize`EMI Valuation`,
  [ EventCategory.Exit ]: $localize`Exit`,
  [ EventCategory.FounderLoan ]: $localize`Founder Loan`,
  [ EventCategory.FundingRound ]: $localize`Funding Round`,
  [ EventCategory.HistoricalASA ] : $localize`Historical ASA`,
  [ EventCategory.HistoricalBSAAIR ]: $localize`Historical BSA AIR`,
  [ EventCategory.HistoricalCLN ] : $localize`Historical CLN`,
  [ EventCategory.HistoricalFounderLoan ]: $localize`Historical Founder Loan`,
  [ EventCategory.HistoricalRound ]: $localize`Historical Round`,
  [ EventCategory.InstantConversion ]: $localize`Instant Conversion`,
  [ EventCategory.InstantInvestment ]: $localize`Instant Investment`,
  [ EventCategory.InstantShares ]: $localize`Issue Shares`,
  [ EventCategory.OptionExercise ]: $localize`Option Exercise`,
  [ EventCategory.OptionGrant ]: $localize`Option Grant`,
  [ EventCategory.OptionReturn ]: $localize`Option Return`,
  [ EventCategory.OptionPool ]: $localize`Option Pool`,
  [ EventCategory.OptionScheme ]: $localize`Option Scheme`,
  [ EventCategory.Proposal ] : $localize`Deal`,
  [ EventCategory.RegularReport ]: $localize`Regular Report`,
  [ EventCategory.Repayment ] : $localize`Repayment`,
  [ EventCategory.ResearchAssurance ] : $localize`R&D Assurance`,
  [ EventCategory.ResearchClaim ] : $localize`R&D Claim`,
  [ EventCategory.SeedFAST ]: $localize`SeedFAST`,
  [ EventCategory.SeedNOTE ]: $localize`SeedNOTE`,
  [ EventCategory.SeedSAFE ]: $localize`SeedSAFE`,
  [ EventCategory.SeedSAFT ]: $localize`SeedSAFT`,
  [ EventCategory.SEISCompliance ]: $localize`S/EIS Compliance`,
  [ EventCategory.ShareClassRegistration ]: $localize`Share Class Registration`,
  [ EventCategory.ShareTransfer ]: $localize`Share Transfer`,
  [ EventCategory.StockSplit ]: $localize`Share Split`,
  [ EventCategory.StopVesting ]: $localize`Stop Vesting`,
  [ EventCategory.Team ]: $localize`Team`,
}

// ------------------------------------------------------------

export type Category =
  | EventCategory
  | DocumentCategory

// ------------------------------------------------------------

export const CategoryLabels: Record<Category, string> = {
  ...DocumentCategories,
  ...EventCategories
}
