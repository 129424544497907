import { createAction, props, union } from '@ngrx/store'

import type { IConfigurationData } from '../services/configuration.service'

// ----------------------------------------------------------

export const ConfigurationLoaded = createAction(
  '[Core] Configuration Loaded',
  props<{ data: IConfigurationData }>()
)

// ----------------------------------------------------------

const actions = union({
  ConfigurationLoaded
})

export type CoreAction = typeof actions
