export enum AnalyticsKeys {
  COMMANDS = 'Commands',
  NEW = 'new',
  NEW_ADVANCE_ASSURANCE = 'New Advance Assurance',
  NEW_CONCIERGE_SERVICE = 'New Concierge Service',
  NEW_INSTANT_INVESTMENT = 'New Instant Investment',
  NEW_SEED_FAST = 'New SeedFAST',
  START_FREE_TRIAL = 'start_free_trial',
  ADD_CARD = 'add_card'
}
