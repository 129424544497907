import { Component, Inject } from '@angular/core'

import { Company } from '@libs/models'
import { SideNavData, SideNavRef } from '@libs/utils'

// ------------------------------------------------------

@Component({
  selector: 'sl-add-company-contact-sidenav',
  templateUrl: './add-company-contact-sidenav.component.html'
})
export class AddCompanyContactSidenavComponent {

  constructor(
    @Inject(SideNavData) public company: Company,
    public sidenavRef: SideNavRef<AddCompanyContactSidenavComponent, boolean>
  ) { }

}
