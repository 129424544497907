import { AbstractControl, UntypedFormArray, ValidationErrors, ValidatorFn } from '@angular/forms'

import { isAfter, isBefore } from 'date-fns'

import { getDecimalPlaces } from '../numbers'
import { asDate } from '../dates'

// ----------------------------------------------------------

export function atLeastOneSelectedValidator(validIfNoItems = true): ValidatorFn {
  return (control: UntypedFormArray): ValidationErrors | null => {
    if (control.length > 0) {
      if (control.value.some(v => v)) {
        return null
      }
    } else if (validIfNoItems) {
      return null
    }

    return {
      atLeastOneSelected: {
        value: control.value
      }
    }
  }
}

// ----------------------------------------------------------

export function decimalPlaceValidator(decimalPlaces: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (getDecimalPlaces(control.value) > decimalPlaces) {
        return { pattern: { valid: false } }
      }
    }

    return null
  }
}

// ----------------------------------------------------------

export function minimumDateValidator(minDate: Date | string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && minDate && isBefore(asDate(control.value), asDate(minDate))) {
      return {
        minDateError: {
          'date-minimum': minDate,
          'date-actual': control.value
        }
      }
    }
    return null
  }
}

// ----------------------------------------------------------

export function maximumDateValidator(maxDate: Date | string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && maxDate && isAfter(asDate(control.value), asDate(maxDate))) {
      return {
        maxDateError: {
          'date-maximum': maxDate,
          'date-actual': control.value
        }
      }
    }
    return null
  }
}
