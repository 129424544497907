import { CohortManager, ICohortConfig } from './cohort-config.model'

// ------------------------------------------------------

export const CKConfig: ICohortConfig = {
  name: 'Climate-KIC',
  description: 'Climate-KIC',
  cohortLogo: '/assets/images/app/logos/climate-kic.png',
  toolbarBackground: '#6db545',
  offerLetterDocType: 'CKCAG',
  FSRVDocType: 'FSRV',
  fundingDocTypes: [ 'CKSAFE' ],
  cohortManager: CohortManager.ClimateKIC,
  cohortMemberUploads: [],
  cohortCountry: { code: 'GB', name: $localize`United Kingdom` },
  immigrationQuestionText: '',
  immigrationTypes: [],
  cohortMembersQuestions: [],
  cities: [],
}
