<div>
  <ng-container *ngFor="let swapp of swappData">
    <ng-container *ngIf="isCurrentApp(swapp); else elseBlock">
      <a ngClass="selected">
        <img [src]="swapp.imgSrc"
             [alt]="swapp.altText">
        <span>{{ swapp.text }}</span>
      </a>
    </ng-container>
    <ng-template #elseBlock>
      <a [href]="swapp.href"
         target="_blank">
        <img [src]="swapp.imgSrc"
             [alt]="swapp.altText">
        <div class="gap-4">
          <span>{{ swapp.text }}</span>
          <mat-icon fontIcon="icon-open-in-new" inline></mat-icon>
        </div>
      </a>
    </ng-template>
  </ng-container>
</div>
