import { type OverlayRef } from '@angular/cdk/overlay'

// ----------------------------------------------------------

export class SwappPanelOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose()
  }
}
