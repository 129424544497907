import { createAction, union, props } from '@ngrx/store'

import type { IEventViewState, ISnapshotData, IPatchDocumentResponse } from '@libs/models'
import type { Signature } from '@libs/file-upload'
import type { IDownload } from '@libs/backend'
import type { ISignatoriesSidenavData } from '../models/panels'
import type { DocumentPermissionsSidenavData } from 'libs/file-upload/src/lib/components/document-permissions-sidenav/document-permissions-sidenav.component'

// ----------------------------------------------------

export const PatchDocument = createAction(
  '[Document] Patch Document',
  props<{ id: string, payload: object }>()
)

export const PatchDocumentSuccess = createAction(
  '[Document] Patch Document Success',
  props<{ id: string, response: IPatchDocumentResponse }>()
)

export const PatchDocumentError = createAction(
  '[Document] Patch Document Error',
  props<{ id: string, error: unknown }>()
)

// ----------------------------------------------------

export const DownloadDocumentPdf = createAction(
  '[Document] Download Document PDF',
  props<{ id: string }>()
)

// ----------------------------------------------------

export const DownloadAllCompanyDocumentsZip = createAction(
  '[Document] Download All Company Documents Zip',
  props<{ companyId: string }>()
)

export const DownloadAllCompanyDocumentsZipSuccess = createAction(
  '[Document] Download All Company Documents Zip Success',
  props<{ download: IDownload }>()
)

export const DownloadAllCompanyDocumentsZipError = createAction(
  '[Document] Download All Company Documents Zip Error'
)

// ----------------------------------------------------

export const DownloadCohortFundingDocumentsZip = createAction(
  '[Document] Download All Cohort Funding Documents ZIP',
  props<{ cohortTeamId: string }>()
)

// ----------------------------------------------------

export const ArchiveDocument = createAction(
  '[Document] Archive Document',
  props<{ id: string }>()
)

export const ArchiveDocumentSuccess = createAction(
  '[Document] Archive Document Success',
  props<{ id: string, snapshot: ISnapshotData }>()
)

export const ArchiveDocumentError = createAction(
  '[Document] Archive Document Error',
  props<{ id: string, error: unknown }>()
)

// ----------------------------------------------------

export const ArchiveDocuments = createAction(
  '[Document] Archive Documents',
  props<{ ids: string[], noun: string }>()
)

export const ArchiveDocumentsSuccess = createAction(
  '[Document] Archive Documents Success',
  props<{ ids: string[], noun: string }>()
)

export const ArchiveDocumentsError = createAction(
  '[Document] Archive Documents Error',
  props<{ ids: string[], error: unknown }>()
)

// ----------------------------------------------------

export const UnarchiveDocument = createAction(
  '[Document] Unarchive Document',
  props<{ id: string }>()
)

export const UnarchiveDocumentSuccess = createAction(
  '[Document] Unarchive Document Success',
  props<{ id: string }>()
)

export const UnarchiveDocumentError = createAction(
  '[Document] Unarchive Document Error',
  props<{ id: string, error: unknown }>()
)

// ----------------------------------------------------

export const UnarchiveDocuments = createAction(
  '[Document] Unarchive Documents',
  props<{ ids: string[], noun: string }>()
)

export const UnarchiveDocumentsSuccess = createAction(
  '[Document] Unarchive Documents Success',
  props<{ ids: string[], noun: string }>()
)

export const UnarchiveDocumentsError = createAction(
  '[Document] Unarchive Documents Error',
  props<{ ids: string[], error: unknown }>()
)

// ----------------------------------------------------

export const GoToDocument = createAction(
  '[Document] Go To Document',
  props<{ id: string, companyId: string }>()
)

export const GoToDocumentEdit = createAction(
  '[Document] Go To Document Edit',
  props<{ id: string, companyId: string, sectionKey?: string }>()
)

export const GoToDocumentQuestions = createAction(
  '[Document] Go To Document Questions',
  props<IEventViewState>()
)

// ----------------------------------------------------

export const OpenDocumentSharingPanel = createAction(
  '[Document] Open Document Sharing Panel',
  props<DocumentPermissionsSidenavData>()
)

export const OpenDocumentSigningPanel = createAction(
  '[Document] Open Document Signing Panel',
  props<ISignatoriesSidenavData>()
)

// ----------------------------------------------------

export const DocumentSigned = createAction(
  '[Document] Document Signed',
  props<{ documentId: string, signature: Signature }>()
)

export const DocumentSignedSuccess = createAction(
  '[Document] Document Signed Success',
  props<{ documentId: string, companyId: string, allSlotsSigned: boolean }>()
)

export const DocumentSignatureRemoved = createAction(
  '[Document] Document Signature Removed',
  props<{ documentId: string, signatureId: string }>()
)

// ----------------------------------------------------

const actions = union({
  PatchDocument,
  PatchDocumentSuccess,
  PatchDocumentError,

  DownloadAllCompanyDocumentsZip,
  DownloadAllCompanyDocumentsZipSuccess,
  DownloadAllCompanyDocumentsZipError,

  DownloadDocumentPdf,
  DownloadCohortFundingDocumentsZip,

  ArchiveDocument,
  ArchiveDocumentSuccess,
  ArchiveDocumentError,

  ArchiveDocuments,
  ArchiveDocumentsSuccess,
  ArchiveDocumentsError,

  UnarchiveDocument,
  UnarchiveDocumentSuccess,
  UnarchiveDocumentError,

  UnarchiveDocuments,
  UnarchiveDocumentsSuccess,
  UnarchiveDocumentsError,

  GoToDocument,
  GoToDocumentEdit,
  GoToDocumentQuestions,

  OpenDocumentSharingPanel,
  OpenDocumentSigningPanel,

  DocumentSigned,
  DocumentSignedSuccess,
  DocumentSignatureRemoved,
})

export type DocumentAction = typeof actions
