import { Pipe, PipeTransform } from '@angular/core'

import { IOptionVesting, VestingType } from '@libs/models'

// ----------------------------------------------------------

const PERIODS = [
  {
    value: 3,
    label: $localize`3 months`,
  },
  {
    value: 6,
    label: $localize`6 months`,
  },
  {
    value: 12,
    label: $localize`1 year`,
  },
  {
    value: 18,
    label: $localize`18 months`,
  },
  {
    value: 24,
    label: $localize`2 years`,
  },
  {
    value: 36,
    label: $localize`3 years`,
  },
  {
    value: 48,
    label: $localize`4 years`,
  },
  {
    value: 60,
    label: $localize`5 years`,
  },
  {
    value: 72,
    label: $localize`6 years`,
  },
  {
    value: 84,
    label: $localize`7 years`,
  },
  {
    value: 96,
    label: $localize`8 years`,
  }
]

const VESTING_PERIODS = [
  {
    value: 0,
    label: $localize`The options all vest immediately`,
  },
  ...PERIODS
]

const VESTING_FREQUENCY = [
  {
    value: 1,
    label: $localize`Every month`,
  },
  {
    value: 3,
    label: $localize`Every quarter`,
  },
  {
    value: 6,
    label: $localize`Every half-year`,
  },
  {
    value: 12,
    label: $localize`Every year`,
  }
]

const VESTING_CLIFF = [
  {
    value: 0,
    label: $localize`None`,
  },
  ...PERIODS
]

// ------------------------------------------------------

@Pipe({
  name: 'slVesting'
})
export class VestingPipe implements PipeTransform {

  transform(
    value: IOptionVesting,
    type: 'period' | 'frequency' | 'cliff' | 'accelerationPeriod'
  ): string {
    if (value.type === VestingType.Milestones) {
      return $localize`Milestone-based`
    }

    const labels = type === 'period'
      ? VESTING_PERIODS
      : type === 'frequency'
        ? VESTING_FREQUENCY
        : VESTING_CLIFF

    return labels.find(l => l.value === value[ type ])?.label ?? '—'
  }

}
