import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { SuccessDialogOptions } from '../modal-options'

// ----------------------------------------------------------

@Component({
  templateUrl: './success-dialog.component.html',
  styleUrls: [ './success-dialog.component.scss' ]
})
export class SuccessDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SuccessDialogOptions
  ) {}
}
