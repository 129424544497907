import { Component, ContentChild } from '@angular/core'
import { MatButton } from '@angular/material/button'

// --------------------------------------------------------------------

@Component({
  selector: 'sl-action-banner',
  templateUrl: './action-banner.component.html',
  styleUrls: [ './action-banner.component.scss' ]
})
export class ActionBannerComponent {
  @ContentChild(MatButton) button: MatButton
}
