import type {
  DocumentAccess,
  DocumentRelationship,
  IEntityGroupData,
  IPublishedUploadedFile
} from '../../models/document.model'
import { DocumentAccessibility, DocumentStatus } from '../../models/document.model'

import { Collection } from '../collection'
import type { ApiFieldSpec } from '../model'
import { OnCompanyModel } from '../model'
import type { User } from '../user'

import type { DocumentTypeId } from '../../models/document-types.model'
import { DocumentTypeNames } from '../../models/document-types.model'
import { AccessRole } from '../../models/access.model'

import { CompanyRelationship } from './document-relationships'
import type { DocumentSnapshot } from './document-snapshot'
import type { IDocumentPermissionInfo } from './permission-set'
import { PermissionSet } from './permission-set'
import type { Signatory } from './signatory'

import type { Investor } from '../stock/investor'
import type { Share } from '../stock/share'

import type { Event } from '../events/event'
import type { Statement } from '../events/statement'

import type { ISigningStatus } from './signature'
import { SigningStrategy } from './signature'
import type { Currency } from '../../models'

import { uniqById } from '@libs/utils'

// ------------------------------------------------------------

const documentComparator = (a: Document, b: Document): number => {
  return new Date(b.modified).getTime() - new Date(a.modified).getTime()
}

// ------------------------------------------------------------

export class Document<E extends Event = Event> extends OnCompanyModel {
  documentType: DocumentTypeId

  status: DocumentStatus = DocumentStatus.Ready
  accessibility: DocumentAccess
  answers: object
  publishedSnapshot: DocumentSnapshot | null
  publishedUploadedFile: IPublishedUploadedFile | null

  submitter: User | null = null
  employee: User | null = null

  investor: Investor | null = null
  share: Share | null = null

  event: E | null = null
  statement: Statement | null = null

  permissions = new PermissionSet(this)
  entityGroupPermissions: IEntityGroupData[] = []
  signatories: Signatory[] = []

  sharees: DocumentRelationship[] = []
  signatoryRelationships: DocumentRelationship[] = []

  signingStatus: ISigningStatus = { strategy: SigningStrategy.None }

  user: User | null = null

  // ----------------------------------------------------

  constructor({
    company,
    type = null,
    documentType = null,
    status = DocumentStatus.Ready,
    publishedSnapshot = null,
    publishedUploadedFile = null,
    answers = {},
    ...data
  }) {
    super({
      company,
      type,
      documentType,
      status,
      publishedSnapshot,
      publishedUploadedFile,
      answers,
      ...data
    })
  }

  // ----------------------------------------------------

  get domain() {
    return `documents`
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    if (this.investor) {
      this.investor.documents.add(this)
    }

    if (this.event) {
      this.event.documents.add(this)
    }

    if (this.share) {
      this.share.document = this
    }

    if (this.statement) {
      this.statement.documents.add(this)
    }
  }

  override detach() {
    super.detach()

    if (this.investor) {
      this.investor.documents.remove(this)
    }

    if (this.event) {
      this.event.documents.remove(this)
    }

    if (this.share) {
      this.share.document = null
    }

    if (this.statement) {
      this.statement.documents.remove(this)
    }
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'user', include: 'create' },
      { key: 'submitter', include: 'create' },
      { key: 'documentType', include: 'create' },
      { key: 'publishedSnapshot', include: 'update' },
      'status',
      'answers'
    ]
  }

  // ----------------------------------------------------

  override customisePayload(payload, mode) {
    payload = super.customisePayload(payload, mode)

    if (mode === 'create') {

      if (this.employee) {
        payload.employee = this.employee
      }

      if (this.investor) {
        payload.investor = this.investor
      }

      if (this.statement) {
        payload.statement = this.statement
      }

      if (this.event) {
        payload[ this.event.fieldSingular ] = this.event
      }
    }

    return payload
  }

  // ----------------------------------------------------

  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    this.accessibility = responseData.accessibility
  }

  // ----------------------------------------------------

  override async afterUpdate(api, responseData) {
    await super.afterUpdate(api, responseData)

    if (responseData.accessibility) {
      this.accessibility = responseData.accessibility
    }
  }

  // ----------------------------------------------------

  /** @deprecated */
  getPermission(entityId: string): IDocumentPermissionInfo {
    return this.permissions.getPermission(entityId)
  }

  // ----------------------------------------------------

  /** @deprecated */
  isAdmin(user: User): boolean {
    return this.getPermission(user.id).admin
  }

  // ----------------------------------------------------

  hasRequiredSignatures(): boolean {
    return this.signatories.every(sig => sig.isCompleted)
  }

  // ----------------------------------------------------

  canEdit(user: User): boolean {
    return this.isAdmin(user) || this.company.hasAccess(user, AccessRole.StaffWrite)
  }

  // ----------------------------------------------------

  get pendingWitnesses(): Signatory[] {
    return this.signatories.filter(sig => sig.needsWitness)
  }

  // ----------------------------------------------------

  get name(): string {
    let name = ''

    // Using domain as a hack-y way to get around importing individual event
    // types and creating a circular reference via Event's documents field.
    // if (this.event instanceof Round || this.event instanceof StockSplitEvent) {
    if ([ 'rounds', 'stockSplits' ].includes(this.event?.domain)) {
      name += this.event.name + ' - '
    }

    return name + DocumentTypeNames[ this.documentType ]
  }

  // ----------------------------------------------------

  get archived(): Date | null {
    return this.publishedSnapshot
      ? this.publishedSnapshot.inserted
      : null
  }

  // ----------------------------------------------------

  /** @deprecated */
  get suggestedSharees(): DocumentRelationship[] {
    return uniqById([
      ...this.sharees,
      ...this.signatoryRelationships
    ])
      .filter(dr => !(dr instanceof CompanyRelationship && dr.isDocumentCompany))
  }

  // ----------------------------------------------------

  /** @deprecated */
  get totalSuggestedSharees(): number {
    return this.suggestedSharees.length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get totalSharedSuggestedSharees(): number {
    return this.suggestedSharees
      .filter(ss => ss.canView)
      .length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get finished(): boolean {
    return this.signatories.every(s => s.isSigned)
  }

  // ----------------------------------------------------

  /** @deprecated */
  get totalSignatories(): number {
    return this.signatories.length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get currentSignatures(): number {
    return this.signatories.filter(s => s.isSigned).length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get totalSharedSignatories(): number {
    return this.sharedSignatories + this.currentSignatures
  }

  // ----------------------------------------------------

  /** @deprecated */
  get sharedSignatories(): number {
    return this.signatoryRelationships
      .filter(ss => !ss.hasSigned)
      .filter(ss => ss.canView)
      .length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get totalWitnessedSignatures(): number {
    return this.signatories.filter(s => s.requiresWitness).length
  }

  // ----------------------------------------------------

  /** @deprecated */
  get witnessedSignatures(): number {
    return this.signatories.filter(s => s.requiresWitness && s.isWitnessed).length
  }

  // ----------------------------------------------------

  get usesNewWorkflow(): boolean {
    return !!this.event
  }

  // ----------------------------------------------------

  get requiresFeature(): boolean {
    return this.accessibility == DocumentAccessibility.MissingFeature || this.accessibility == DocumentAccessibility.MissingBoth
  }

  // ----------------------------------------------------

  get requiresPurchase(): boolean {
    return this.accessibility == DocumentAccessibility.MissingProduct || this.accessibility == DocumentAccessibility.MissingBoth
  }

  // ----------------------------------------------------

  get requiresPayment(): boolean {
    return this.requiresFeature || this.requiresPurchase
  }

  // ----------------------------------------------------

  get locale(): Currency {
    return this.event.locale
  }

  // -----------------------------------------------------

  override toString() {
    return `${this.name} (id: #${this.id || '?'}, type: Document)`
  }
}

// Order documents by last modified date, descending order
// Document.compare = documentComparator

// ------------------------------------------------------------

export class DocumentCollection extends Collection<Document> {
  constructor() {
    super(documentComparator)
  }

  // ----------------------------------------------------

  /**
   * public getByType(documentTypeId: DocumentTypeId): Document?
   *
   * @param {DocumentTypeId} documentTypeId
   * @returns Document?
   */
  getByType(documentTypeId: DocumentTypeId): Document {
    return this.find(d => d.documentType === documentTypeId)
  }

  // ----------------------------------------------------

  /**
   * public getAllByType(documentTypeId: DocumentTypeId): Document[]
   *
   * @param {DocumentTypeId} documentTypeId
   * @returns Document[]
   */
  getAllByType(documentTypeId: DocumentTypeId): Document[] {
    return this.filter(d => d.documentType === documentTypeId)
  }
}
