import { EventCategory } from '../../models/category.model'
import type { ApiFieldSpec } from '../model'
import type { IEventViewState } from '../events/event'
import { Event } from '../events/event'
import type { Option } from './option'
import { OptionReturnType } from '../events/option-return-type'

// ------------------------------------------------------------

export class OptionReturnEvent extends Event {
  readonly category: EventCategory = EventCategory.OptionReturn
  readonly domain: string = 'optionReturns'

  option: Option
  count: number

  type: OptionReturnType

  remainderOption: Option | null = null

  // ----------------------------------------------------

  constructor({
    count = 0,
    type = OptionReturnType.SIMPLE,
    ...data
  }) {
    super({ count, type, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'option', include: 'create' },
      'count',
    ]
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.option && this.optionGrant.investor && typeof this.optionGrant.investor.name === 'string'
      ? $localize`Options returned - ${this.optionGrant.investor.name}`
      : $localize`Options returned`
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return { state: [ '/companies', this.company.id, 'options', 'terminations', this.id ] }
  }

  // ----------------------------------------------------

  get optionGrant() {
    return this.option.optionGrant
  }

  // ----------------------------------------------------

  get isTermination() {
    return this.type === OptionReturnType.TERMINATION
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    if (this.option) {
      this.option.optionReturn = this
    }
  }

  // -----------------------------------------------------

  override detach() {
    super.detach()

    if (this.option) {
      this.option.optionReturn = null
    }
  }
}
