import { InjectionToken } from '@angular/core'

import { Observable } from 'rxjs'

// ----------------------------------------------------------

export interface StoreQueryService<Data, QueryVars extends object = object> {
  load(variables: QueryVars): Observable<Data>
}

// ----------------------------------------------------------

export const StoreQuery = new InjectionToken<StoreQueryService<unknown, object>>('StoreQuery')

export const StoreInitialState = new InjectionToken<unknown>('StoreInitialState')
