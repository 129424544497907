import type { Collection, IModelData, Model } from '@libs/models'

// ------------------------------------------------------------

export function getExistingEntitiesForData<T extends Model, D extends IModelData>(
  sourceCollection: Collection<T> | Map<string, T>,
  jsonData: D[]
): T[] {
  return jsonData.map(v => sourceCollection.get(v.id))
}
