import { Component, Input, OnInit } from '@angular/core'

import { Appointment, AppointmentRole, ExtendedRole, RolesData } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-role-bullet',
  templateUrl: './role-bullet.component.html',
  styleUrls: [ './role-bullet.component.scss' ]
})
export class RoleBulletComponent implements OnInit {
  // TODO: Fix this so it can be have type AppointmentRole correctly - uppercase all inputs
  @Input() role: ExtendedRole
  @Input() appointment?: Appointment

  hasRole = true
  text: string

  // ----------------------------------------------------

  ngOnInit() {
    if (this.role !== ExtendedRole.Shareholder && this.role !== ExtendedRole.Investor && this.appointment) {
      this.hasRole = this.appointment
        .hasRole((this.role as unknown) as AppointmentRole)
    }

    this.text = RolesData.find(r => r.id === this.role).name
  }
}
