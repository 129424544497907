<div fxLayout="column"
     class="p-16 gap-8">
  <div fxLayout="row"
       class="gap-16">
    <a href="https://seedlegals.com/contact-us"
       target="_blank" i18n>Contact Us</a>
    <a href="https://seedlegals.com/about-us"
       target="_blank" i18n>About SeedLegals</a>
  </div>

  <div fxLayout="row"
       class="gap-16">
    <a href="https://seedlegals.com/pricing"
       target="_blank" i18n>Pricing</a>
    <a href="https://seedlegals.com/terms-of-service/"
       target="_blank" i18n>Terms of Service</a>
  </div>

  <div fxLayout="row"
       class="gap-16">
    <a href="https://seedlegals.com/privacy-policy/"
       target="_blank" i18n>Privacy Policy</a>
  </div>

  <div i18n>&copy; {{ copyrightYear | date: 'yyyy' }} SeedLegals. All rights reserved.</div>
</div>
