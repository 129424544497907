import { Model, ApiFieldSpec } from '../model'
import { Address } from '../address'
import { Signature } from './signature'

import { BackendService } from '@libs/backend'

// ------------------------------------------------------------

export class Witness extends Model {
  readonly domain = 'witnesses'

  name: string | null = null
  occupation: string | null = null
  address: Address | null = null
  signature: string | null = null
  signed: string | null = null

  token?: string

  // ----------------------------------------------------

  constructor(
    public witnessOf: Signature,
    {
      name = null,
      occupation = null,
      address = new Address({}),
      signature = null,
      signed = null,
      ...data
    }
  ) {
    super(data)

    this.name = name
    this.occupation = occupation
    this.address = address
    this.signature = signature
    this.signed = signed
  }

  // ----------------------------------------------------

  updateAddress(addressData) {
    Object.assign(this.address, addressData)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'witnessOf', include: 'create' },
      { key: 'name', include: 'update' },
      { key: 'occupation', include: 'update' },
      { key: 'address', include: 'update' },
      { key: 'signature', include: 'update' },
      { key: 'signed', include: 'update' }
    ]
  }

  // ----------------------------------------------------

  override async afterUpdate(api: BackendService, responseData: any) {
    await super.afterUpdate(api, responseData)

    if (responseData.address && !this.address.saved) {
      this.address.setID(responseData.address.id)
    }
  }

  // ----------------------------------------------------

  override attach() {
    this.witnessOf.witness = this
  }

  override detach() {
    this.witnessOf.witness = null
  }

  // ----------------------------------------------------

  get document() {
    return this.witnessOf.document
  }

  get isSigned() {
    return !!this.signature
  }

  // ----------------------------------------------------

  get hasDetails() {
    return !!this.name
  }
}
