export type DisclosuresSectionKeys =
| 'ip'
| 'shareCapital'
| 'companyInfo'
| 'businessPlan'
| 'mgmtAccounts'
| 'commitments'
| 'taxation'
| 'litigation'
| 'dataProtection'
| 'assetsDebts'
| 'connectedPersons'
| 'arrangements'
| 'insurance'
| 'statutory'
| 'bribery'
| 'documentBundle'
| 'founderLoan'

const disclosuresSectionKeys = [
  'ip',
  'shareCapital',
  'companyInfo',
  'businessPlan',
  'mgmtAccounts',
  'commitments',
  'taxation',
  'litigation',
  'dataProtection',
  'assetsDebts',
  'connectedPersons',
  'arrangements',
  'insurance',
  'statutory',
  'bribery',
  'documentBundle',
  'founderLoan'
] as const

// ----------------------------------------------------------

type DisclosuresStructure = {
  [key in DisclosuresSectionKeys]: {
    [key: string]: unknown
  }
}

// ----------------------------------------------------------

export function getDisclosuresFromAnswers(answers: object = {}) {
  const disclosures = {}
  for (const sectionKey of disclosuresSectionKeys) {
    disclosures[ sectionKey ] = answers[ sectionKey ] ?? {}
  }

  return <DisclosuresStructure>disclosures
}
