import { NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { SharedModule } from '@libs/shared'
import { AuthStateModule } from './auth-state.module'
import { AuthRoutingModule } from './auth-routing.module'

import { ErrorInterceptor } from './services/error-interceptor.service'

import { PAGES } from './pages'

// ----------------------------------------------------------

@NgModule({
  imports: [
    SharedModule,
    AuthStateModule,
    AuthRoutingModule
  ],
  declarations: [
    ...PAGES
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
