import { Injectable } from '@angular/core'

import { Store, select } from '@ngrx/store'

import { selectLoggedIn, selectEmailToken, selectWhoAmI, selectIsPlatformAdmin, selectIsUserConnected } from './auth.selectors'
import { AuthAction } from './auth.actions'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {

  isLoggedIn$ = this.store.pipe(select(selectLoggedIn))
  emailToken$ = this.store.pipe(select(selectEmailToken))

  whoAmI$ = this.store.pipe(select(selectWhoAmI))
  isPlatformAdmin$ = this.store.pipe(select(selectIsPlatformAdmin))
  isUserConnected$ = this.store.pipe(select(selectIsUserConnected))

  // ----------------------------------------------------

  dispatch(
    action: AuthAction
  ) {
    this.store.dispatch(action)
  }

  // ----------------------------------------------------

  constructor(
    private store: Store,
  ) {}

}
