import { Inject, Injectable, InjectionToken, LOCALE_ID, Optional, Provider, Type } from '@angular/core'

import { Currency } from '@libs/models'

import { LocaleService } from '../locale/locale.service'

// ----------------------------------------------------------

export type LabelFunction<T = any> = (
  thing: T,
  locale: string,
  currency: Currency,
  options?: any,
) => string

// ----------------------------------------------------------

export type LabelTarget = Type<any> | string

// ----------------------------------------------------------

export interface LabellerDefinition {
  target: LabelTarget
  kind?: string
  label: LabelFunction
}

// ----------------------------------------------------------

export const Labeller = new InjectionToken<LabellerDefinition[]>('Labeller')

// ----------------------------------------------------------

export function labelProvider<T>(target: string, label: LabelFunction<T>): Provider
export function labelProvider<T>(target: string, kind: string, label: LabelFunction<T>): Provider
export function labelProvider<T>(target: Type<T>, label: LabelFunction<T>): Provider
export function labelProvider<T>(target: Type<T>, kind: string, label: LabelFunction<T>): Provider

export function labelProvider<T>(
  target: string | Type<T>,
  kindOrLabel: string | LabelFunction<T>,
  label?: LabelFunction<T>
): Provider {
  return {
    provide: Labeller,
    multi: true,
    useValue: typeof kindOrLabel === 'string'
      ? { target, kind: kindOrLabel, label }
      : { target, label: kindOrLabel }
  }
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  private labellers = new Map<LabelTarget, Map<string, LabelFunction>>()

  // ----------------------------------------------------

  getLabel(
    target: any,
    kind = 'default',
    fallbackToDefault = true,
  ): string | undefined {
    const lf = this.getLabeller(target, kind, fallbackToDefault)

    return this.callLabeller(lf, target)
  }

  // ----------------------------------------------------

  callLabeller(
    labeller: LabelFunction | undefined,
    target: any,
  ): string | undefined {
    return labeller?.(target, this.locale, this.localeService.currency)
  }

  // ----------------------------------------------------

  getLabeller(
    target: any,
    kind = 'default',
    fallbackToDefault = true,
  ): LabelFunction | undefined {
    const m = this.labellers.get(target.constructor) ?? this.labellers.get(target)

    if (m) {
      if (m.has(kind)) {
        return m.get(kind)
      } else if (fallbackToDefault) {
        return m.get('default')
      }
    }

    return undefined
  }

  // ----------------------------------------------------

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private localeService: LocaleService,
    @Optional() @Inject(Labeller) definitions: LabellerDefinition[],
  ) {
    if (definitions) {
      for (const { target, kind = 'default', label } of definitions) {
        if (!this.labellers.has(target)) {
          this.labellers.set(target, new Map())
        }

        this.labellers.get(target).set(kind, label)
      }
    }

    // _log(`LabelService(definitions): labellers`, definitions, this.labellers, this)
  }

}
