import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core'
import { ActionWithSearchBarComponent } from '@libs/shared'
import { IEntity } from '@app/entities/models/entity-sidenav'
import { path, sortBy } from 'ramda'

export const entitySorter = sortBy(path([ 'entity', 'name' ]))

// ----------------------------------------------------------

@Component({
  selector: 'sl-grouped-entity-selector',
  templateUrl: './grouped-entity-selector.component.html',
  styleUrls: [ './grouped-entity-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedEntitySelectorComponent<E extends IEntity = IEntity> implements OnChanges {

  @Input() entityGroups: Map<string, E[]> = new Map()
  @Input() searchLabel = $localize`Search...`
  @Input() addNewLabel = $localize`Add new...`
  @Input() canAddNew = false

  @Output() entitySelected = new EventEmitter<IEntity>()
  @Output() addNewClicked = new EventEmitter<void>()

  @ViewChild(ActionWithSearchBarComponent) actionBar: ActionWithSearchBarComponent

  sortedEntityGroups: Map<string, E[]> = new Map()
  filteredEntityGroups: Map<string, E[]> = new Map()

  // ----------------------------------------------------

  ngOnChanges(changes) {
    // _log(`EntitySelectorComponent.ngOnChanges(changes): this`, changes, this)

    if (changes.entityGroups) {
      for (const [ key, value ] of this.entityGroups.entries()) {
        this.sortedEntityGroups.set(key, entitySorter(value))
      }

      if (this.actionBar) {
        this.actionBar.clearSearch()
      } else {
        this.onFilterChanged()
      }
    }
  }

  // ----------------------------------------------------

  onEntitySelected(entity: IEntity) {
    this.entitySelected.emit(entity)
  }

  // ----------------------------------------------------

  onFilterChanged(filterText = '') {
    if (filterText) {
      this.filteredEntityGroups = new Map()
      const matchRegex = new RegExp(filterText, 'i')

      for (const [ key, value ] of this.sortedEntityGroups.entries()) {
        this.filteredEntityGroups.set(key, value.filter(e => matchRegex.test(e.entity.name)))
      }
    } else {
      for (const [ key, value ] of this.sortedEntityGroups.entries()) {
        this.filteredEntityGroups.set(key, value)
      }
    }

  }

}
