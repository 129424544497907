<div class="info-body gap-10"
     fxLayout="column"
     fxFlex>
  <sl-avatar-with-name [entity]="user"
                       [popup]="false"></sl-avatar-with-name>

  <div *ngIf="user.email"
       fxLayout="row"
       fxLayoutAlign="start center"
       class="gap-8">
    <mat-icon fontIcon="icon-email"
              class="s20"></mat-icon>

    <span class="mat-body-2">{{ user.email }}</span>

    <mat-icon *ngIf="user.connected"
              fontIcon="icon-check-circle"
              matTooltip="Email verified"
              i18n-matTooltip
              class="s14"></mat-icon>
  </div>


</div>
