import { EntityMetadataMap } from '@ngrx/data'

import { compareDesc, parseISO } from 'date-fns'

// ----------------------------------------------------------

export function nameComparer(
  a: { name: string },
  b: { name: string }
): number {
  return a.name.localeCompare(b.name)
}

export function effectiveDateComparer(
  a: { effectiveDate: string },
  b: { effectiveDate: string }
): number {
  return compareDesc(parseISO(a.effectiveDate), parseISO(b.effectiveDate))
}

export function cohortDateComparer(
  a: { formStartDate: string },
  b: { formStartDate: string }
): number {
  return compareDesc(parseISO(a.formStartDate), parseISO(b.formStartDate))
}

// ----------------------------------------------------------

export const appEntityMetadata: EntityMetadataMap = {
  DocumentExcerpt: {},
  UserExcerpt: {
    sortComparer: nameComparer
  },
  Deal: {
    sortComparer: effectiveDateComparer
  },
  Card: {},
  Cohort: {
    sortComparer: cohortDateComparer
  },
  CohortMember: {
    sortComparer: nameComparer
  },
  CohortTeam: {
    sortComparer: nameComparer
  }
}
