import type { OnInit } from '@angular/core'
import { Component, Inject } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

import { Configuration } from '@app/core/services/configuration.service'
import { PlanService } from '../../services/plan.service'
import { ToastService } from '@libs/services'

import type {
  Company,
  Feature,
  IRegionData,
  Plan,
  User
} from '@libs/models'
import { AppointmentRole } from '@libs/models'
import { ModalComponent } from '@libs/utils'

// ----------------------------------------------------------

export interface PaywallDialogData {
  user: User
  company: Company
  missingFeature?: Feature
}

export interface PaywallDialogDataResults {
  hasFeature: boolean
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-paywall-dialog',
  templateUrl: './paywall-dialog.component.html'
})
export class PaywallDialogComponent extends ModalComponent<PaywallDialogComponent, PaywallDialogData, PaywallDialogDataResults> implements OnInit {

  company: Company
  user: User
  missingPlan: Plan = null
  canUseFreeTrial: boolean
  isCompanyAdmin: boolean
  region: IRegionData
  loading: boolean

  // ----------------------------------------------------------

  constructor(
    fb: UntypedFormBuilder,
    dialogRef: MatDialogRef<PaywallDialogComponent>,
    @Inject(MAT_DIALOG_DATA) override readonly data: PaywallDialogData,
    private planService: PlanService,
    private toastService: ToastService,
    private router: Router,
    private configuration: Configuration
  ) {
    super(fb, dialogRef, data)
  }

  // ----------------------------------------------------------

  ngOnInit() {
    this.company = this.data.company
    this.user = this.data.user

    this.isCompanyAdmin = this.company.hasRole(this.user, AppointmentRole.Admin) || this.planService.isUserWithStaffAccess(this.company, this.user)
    this.missingPlan = this.configuration.getPlanByFeatureAndJurisdiction(this.data.missingFeature, this.company.jurisdiction)
    this.canUseFreeTrial = this.missingPlan ? this.company.canUseFreeTrial() : false
    this.region = this.configuration.getRegionByJurisdiction(this.company.jurisdiction)
  }

  // ----------------------------------------------------------

  async activateFreeTrial() {
    this.loading = true
    try {
      await this.planService.activateFreeTrial(this.company, this.user, this.missingPlan)
      this.toastService.success($localize`Free trial started`)
    } catch (e) {
      this.toastService.error($localize`Error starting free trial`)
    }
    this.loading = false
    this.closeDialog()
  }

  // -----------------------------------------------------------

  goToSubscriptionPage() {
    this.router.navigate([ 'companies', this.company.id, 'settings', 'membership' ])
    this.closeDialog()
  }

  // -----------------------------------------------------------

  closeDialog() {
    const hasFeature = this.company.hasFeature(this.data.missingFeature)
    this.close({ hasFeature })
  }
}
