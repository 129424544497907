import { EventCategory } from '../../models/category.model'

import { Collection } from '../collection'
import { OnCompanyEventModel, type ApiFieldSpec } from '../model'
import type { Investor } from '../stock/investor'
import { DocumentEventAdapter } from './document-event-adapters'

// ------------------------------------------------------------

export class AdvanceAssuranceDocumentEvent extends DocumentEventAdapter {
  readonly domain = 'advanceAssurances'
  readonly documentTypeId = 'EIAS'
  readonly category = EventCategory.AdvanceAssurance

  readonly minInvestmentPercentage = 0.25

  advanceAssuranceIntents = new Collection<AdvanceAssuranceIntent>()

  // ----------------------------------------------------

  constructor(data) {
    super(data)
  }

  // ----------------------------------------------------

  override get safeName() {
    return $localize`SEIS/EIS Advance Assurance`
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'raise', 'advance-assurances', this.id ]
    }
  }

  // ------------------------------------------------------

  get totalIntent(): number {
    return this.advanceAssuranceIntents.reduce((accum, cur) => accum + cur.amount, 0)
  }

  // ------------------------------------------------------

  /**
   * Whether the application will request investors to be provided at all. Requested if the user has not previously
   * raised investment, or raised investment but only deminimis aid.
   */
  get requiresInvestors(): boolean {
    const priorInvestmentAnswers = this.answers[ 'priorInvestments' ]
    const nonRequiredInvestmentTypes = [ 'seis', 'eis', 'state' ]
    return !priorInvestmentAnswers?.[ 'anyPriorInvestment' ]
      || priorInvestmentAnswers?.[ 'anyPriorInvestment' ]
         && !priorInvestmentAnswers?.[ 'priorInvestmentTypes' ]?.some(a => nonRequiredInvestmentTypes.includes(a))
  }

  // ------------------------------------------------------

  /**
  * Whether the application requires the user to list individual investors, or provide a letter from a platform.
  */
  get investorsRequireListing(): boolean {
    const upcomingRoundAnswers = this.answers[ 'upcomingRound' ]
    return !upcomingRoundAnswers || [ 'direct', 'directfund' ].includes(upcomingRoundAnswers.investorType)
  }

  // ------------------------------------------------------

  get hasRequiredInvestors(): boolean {
    return !this.investorsRequireListing || this.advanceAssuranceIntents.length > 0 && this.advanceAssuranceIntents.items()
      .every(i => i.investor?.name && i.investor?.entity.address?.isComplete()) && this.investmentAmountValid
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.totalIntent
  }

  // ------------------------------------------------------

  get investmentAmountValid(): boolean {
    return this.advanceAssuranceIntents.length > 0
      && this.totalIntent >= this.minInvestmentPercentage * (this.answers[ 'upcomingRound' ]?.[ 'target' ] ?? 0)
  }
}

// -----------------------------------------------------

export class AdvanceAssuranceIntent extends OnCompanyEventModel<AdvanceAssuranceDocumentEvent> {
  amount: number
  investor: Investor

  readonly domain: string = 'advanceAssuranceIntents'

  // ----------------------------------------------------

  constructor({
    event,
    investor = null,
    amount = null,
    ...data
  }) {
    super({ ...data, event, investor, amount })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'investor', include: 'create' },
      'amount',
    ]
  }
}
