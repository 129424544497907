import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { StatusDialogData } from '../../models'

// ----------------------------------------------------

@Component({
  selector: 'sl-loading-dialog',
  template: `
    <div class="p-32" fxLayout="row" fxLayoutAlign="space-around center">
      <mat-spinner color="primary"></mat-spinner>
      <h3 fxFlex="60">{{ data.title }}</h3>
    </div>
  `
})
export class LoadingDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: StatusDialogData) {}
}
