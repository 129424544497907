import { Component } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-learn-more-link',
  template: `
    <a href="https://seedlegals.com/pricing"
       target="_blank"
       i18n>Learn More</a>
  `,
  styles: [ 'a { color: #6062ff; white-space: nowrap; }' ]
})
export class LearnMoreLinkComponent {}
