export interface IProductPriceDisplayInfo {
  title?: string
  image?: string
  description?: string
  additionalInformation?: string
  payLaterDescription?: string
  okLabel?: string
}

// ------------------------------------------------------------

export interface ProductPriceDisplayInfo {
  all?: IProductPriceDisplayInfo
  DEPOSIT?: IProductPriceDisplayInfo
  AGREEMENT?: IProductPriceDisplayInfo
  BALANCE?: IProductPriceDisplayInfo
}

// ------------------------------------------------------------

export const ITEMS_BY_STAGE: ProductPriceDisplayInfo = {
  DEPOSIT: {
    title: $localize`Start your round`,
    image: `/assets/images/app/plan-unlock.svg`,
    description: $localize`Congratulations, you're on your way towards raising your round!`,
    okLabel: $localize`Continue`
  },
  AGREEMENT: {
    title: $localize`Docs now, pay later!`,
    image: `/assets/images/app/plan-unlock.svg`,
    description: $localize`As a startup, we know that cash is tight ahead of your new funding round. So we’ll only bill you the balance of the plan when your round is closed, defined as when your Shareholders or Investment Agreement is signed and you’re set to get funding in from your investors.`,
    payLaterDescription: $localize`By unlocking your Shareholders Agreement, Investment Agreement or Articles, you’re agreeing to be billed on completion of this round for the SeedLegals fees based on the amount raised. If this investment round never closes, no problem, nothing further is due.`,
    okLabel: $localize`Accept`
  },
  BALANCE: {
    title: $localize`Well done!`,
    image: `/assets/images/app/plan-close.svg`,
    description: $localize`Amazing job on your funding round!\nNow that you’re on the home stretch, the time has come…`,
    okLabel: $localize`Continue`
  }
}

// ------------------------------------------------------------

export const ITEMS_BY_PRODUCT_AND_STAGE = {
  INSTANT: {
    all: {
      title: $localize`Instant Investment Agreement`,
      image: `/assets/images/app/features/diploma2.svg`
    },
    DEPOSIT: {
      description: $localize`Unlock this Instant Investment Agreement to sign it and share with your investor for them to sign.`
    },
    BALANCE: {
      description: $localize`Complete your Instant Investment Agreement.`
    }
  },
  INSTANT_SHARES: {
    all: {
      title: $localize`Issue Shares`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`Complete your Share Issue.`
    }
  },
  ADVANCE_ASSURANCE: {
    all: {
      title: $localize`SEIS/EIS Advance Assurance package`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`The forms, cover letter, tutorials and expert help to submit your Advance Assurance application to HMRC.`
    }
  },
  POLICIES: {
    all: {
      title: $localize`Company Policies`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`The Policies for your company: Staff Handbook, Employee and Website Privacy Policies.`
    }
  },
  SEEDFAST: {
    all: {
      title: $localize`SeedFAST Agreement`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`SeedFAST Agreement`
    }
  },
  BSAAIR: {
    all: {
      title: $localize`BSA Air`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`BSA Air`
    }
  },
  SEEDNOTE: {
    all: {
      title: $localize`SeedNOTE Agreement`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`SeedNOTE Agreement`
    },
    DEPOSIT: {
      title: $localize`Unlock your SeedNOTE Term Sheet`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`Congrats! You’re all set to send your Term Sheet to your investors.`,
      okLabel: $localize`Continue`
    },
    AGREEMENT: {
      title: $localize`Docs now, pay later!`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`As a startup, we know that cash is tight ahead of your new funding round. So we’ll only bill you the balance of the plan when your SeedNOTE is closed, defined as when your SeedNOTE Agreement is fully signed and you’re set to get the funds in from your investors.`,
      payLaterDescription: $localize`By unlocking your SeedNOTE Agreement, you’re agreeing to be billed on completion of this loan for the SeedLegals fees based on the amount raised. If this SeedNOTE never ends up closing, no problem, nothing further is due.`,
      okLabel: $localize`Accept`
    },
    BALANCE: {
      title: $localize`Well done!`,
      image: `/assets/images/app/plan-close.svg`,
      description: $localize`Nice job closing this SeedNOTE!\nTime to close it off and issue those Loan Notes…`,
      okLabel: $localize`Continue`
    }
  },
  CLN_FUTUREFUND: {
    all: {
      title: $localize`Future Fund Application`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize`Future Fund Application`
    },
    DEPOSIT: {
      title: $localize`Unlock your Future Fund Term Sheet`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`Congrats! You’re all set to send your Term Sheet to your investors.`,
      okLabel: $localize`Continue`
    },
    AGREEMENT: {
      title: $localize`Docs now, pay later!`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`As a startup, we know that cash is tight ahead of your new funding round. So we’ll only bill you the balance of the plan when your Future Fund is closed, defined as when your Future Fund's resolutions are fully signed and you’re set to get the funds in from your investors.`,
      payLaterDescription: $localize`By unlocking your Future Fund Application, you’re agreeing to be billed on completion of this loan for the SeedLegals fees based on the amount raised. If this Future Fund never ends up closing, no problem, nothing further is due.`,
      okLabel: $localize`Accept`
    },
    BALANCE: {
      title: $localize`Well done!`,
      image: `/assets/images/app/plan-close.svg`,
      description: $localize`Nice job closing this Future Fund!\nTime to close it off and issue those Loan Notes…`,
      okLabel: $localize`Continue`
    }
  },
  SHARE_TRANSFER: {
    all: {
      title: $localize`Share Transfer`,
      image: `/assets/images/app/features/share-sign.svg`,
      description: $localize`The forms, letter and deed necessary for a shareholder to transfer their shares to another party`
    }
  },
  STOCK_SPLIT: {
    all: {
      title: $localize`Share split`,
      image: `/assets/images/app/features/share-sign.svg`,
      description: $localize`The documents necessary to split your shares`
    }
  },
  OPTION_SCHEME: {
    all: {
      title: $localize`EMI Option Scheme`,
      image: `/assets/images/app/features/pie-chart.svg`,
      description: $localize`Set up your EMI share option scheme and manage your employee options.`
    }
  },
  OPTION_SCHEME_UNAPPROVED: {
    all: {
      title: $localize`Unapproved Option Scheme`,
      image: `/assets/images/app/features/pie-chart.svg`,
      description: $localize`Set up your unapproved share option scheme and manage your options.`
    }
  },
  BSPCE: {
    all: {
      title: $localize`BSPCE`,
      image: `/assets/images/app/features/pie-chart.svg`,
      description: $localize`Set up your BSPCE share option scheme and manage your employee options.`
    }
  },
  BSA_ADVISOR: {
    all: {
      title: $localize`BSA Advisor`,
      image: `/assets/images/app/features/pie-chart.svg`,
      description: $localize`Set up your BSA Advisor option scheme and manage your employee options.`
    }
  },
  EMI_VALUATION: {
    all: {
      title: $localize`Valuation for EMI`,
      image: `/assets/images/app/features/contract.svg`,
      description: $localize`Receive an Actual Market Value and an Initial Unrestricted Market Value to come up with the right exercise price for your EMI share option scheme.`
    }
  },
  EIS_COMPLIANCE_COMPLETE: {
    all : {
      title: $localize`SEIS/EIS Compliance`,
      image: `/assets/images/app/plan-close.svg`,
      description: $localize`After your round, give your investors the certificates they need to claim their SEIS and EIS tax deductions.`
    }
  },
  FOUNDERS_SHAREHOLDERS_AGREEMENT: {
    all: {
      title: $localize`Founder Shareholders Agreement`,
      image: `/assets/images/app/features/diploma2.svg`,
      description: $localize``
    }
  },
  RESEARCH_ASSURANCE: {
    all: {
      title: $localize`R&D Advance Assurance`,
      image: `/assets/images/app/plan-close.svg`,
      description: $localize`The forms, cover letter, tutorials and expert help to submit your R&D Advance Assurance application to HMRC.`,
    }
  },
  RESEARCH_CLAIM: {
    DEPOSIT: {
      title: $localize`Start your claim`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`Pay now to create and submit your application.\n\nOn successful credit: Pay 5% of the total amount of tax benefit (or £1,490 minimum whichever is greater).`,
      additionalInformation: `and the balance once HMRC approve the claim (or after 90 days, whichever is sooner).\nPayment will be taken automatically via Stripe.`,
      okLabel: $localize`Continue`
    },
    AGREEMENT: {
      title: $localize`Congratulations, you're on your way towards claiming R&D Tax credits!`,
      image: `/assets/images/app/plan-unlock.svg`,
      description: $localize`Get your application reviewed and ready to send to HMRC.`,
      payLaterDescription: $localize`Get your application reviewed and ready to send to HMRC.`,
      okLabel: $localize`Accept`
    },
  }
}
