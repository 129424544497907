import { DocumentEventAdapter } from '../events/document-event-adapters'

import { Share } from '../stock/share'
import { Option } from './option'
import { ApiFieldSpec } from '../model'
import { ShareClass } from '../stock/share-class'
import { Investor } from '../stock/investor'
import { IEventViewState } from '../events/event'
import { EventCategory } from '../../models'

// ------------------------------------------------------------

export class OptionExerciseEvent extends DocumentEventAdapter {
  readonly domain = 'optionExercises'
  readonly documentTypeId = 'OPEX'
  readonly category = EventCategory.OptionExercise

  count: number
  strikePrice: number

  /**
   * Option certificate from the investor is exercising some or all options.
   * Will be annulled once this event is closed.
   */
  option: Option

  /**
   * Once the exercise is closed, if all options weren't exercised this will
   * be a new option certificate for the remaining options.
   */
  remainderOption: Option | null = null

  /**
   * Once the exercise is closed, the new certificate for the shares that
   * the investor purchased using their options.
   */
  share: Share | null = null

  investor: Investor
  shareClass: ShareClass

  // ----------------------------------------------------

  constructor({
    count = 1,
    ...data
  }) {
    super({ count, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'option', include: 'create' },
      'count',
      'strikePrice'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'options', 'exercises', this.id ]
    }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    return {
      state: [
        ...this.getViewState().state,
        'basics'
      ]
    }
  }

  // ----------------------------------------------------

  get optionGrant() {
    return this.option.optionGrant
  }

  // ----------------------------------------------------

  get optionScheme() {
    return this.optionGrant.optionScheme
  }

  // ----------------------------------------------------

  override getSharesIssuedFromEvent() {
    return this.closed && this.share
      ? [ this.share ]
      : []
  }

  // ----------------------------------------------------

  override get safeName() {
    if (this.investor) {
      return $localize`Option Exercise` + ` - ${this.investor.name}`
    } else {
      return $localize`Option Exercise`
    }
  }

  // -----------------------------------------------------

  override async afterRemoval(): Promise<void> {
    this.option.optionExercise = null
    this.option.active = true
  }

  // -----------------------------------------------------

  override get timelineAmount(): number {
    return this.count * this.strikePrice
  }
}
