/* eslint-disable no-restricted-syntax */

import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store'
import * as fromRouter from '@ngrx/router-store'

import { DebugOptions, debugStyles as _ds } from '@env/environment'

// ----------------------------------------------------------

export interface AppState {
  router: fromRouter.RouterReducerState
}

// ----------------------------------------------------------

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
}

// ----------------------------------------------------------

const actionRegex = /^(?:\[([\w ]+)\] |(@ngrx\/(?:store|store-devtools|router-store|effects|data))\/)(.+)$/

// ----------------------------------------------------------

function logAction(
  actionType: string,
  extraInfo = '',
  nameStyle = _ds.actionName
): void {
  const p = extraInfo.length > 0
    ? ` (${extraInfo})`
    : ''

  const m = actionType.match(actionRegex)

  if (m) {
    console.groupCollapsed(`%c[${m[ 1 ] || m[ 2 ]}] %c${m[ 3 ]}%c${p}`, _ds.actionSource, nameStyle, _ds.actionInfo)
  } else {
    console.groupCollapsed(`%c${actionType}%c${p}`, nameStyle, _ds.actionInfo)
  }
}

// ----------------------------------------------------------

// console.log all actions
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (state: AppState, action: any): AppState {
    let logged = true

    switch (action.type) {
      case '@ngrx/router-store/request':
        logAction(action.type, `navigationTrigger: ${action.payload.event.navigationTrigger}`)
        break

      case '@ngrx/router-store/navigation':
      case '@ngrx/router-store/cancel':
        logAction(action.type, `url: ${action.payload.event.url}`)
        break

      case '@ngrx/router-store/navigated':
        logAction(action.type, `urlAfterRedirects: ${action.payload.event.url}`)
        break

      case '@ngrx/router-store/error':
        logAction(action.type, `url: ${action.payload.event.url}`, _ds.actionErrorName)
        console.info('error')
        console.log(action.payload.event.error)
        break

      case '@ngrx/store/update-reducers':
      case '@ngrx/store-devtools/recompute':
        logged = false
        break

      default:
        logAction(action.type)
        break
    }

    if (logged) {
      console.info('action')
      console.dir({ ...action })
      console.info('state')
      console.dir({ ...state })

      console.groupEnd()
    }

    return reducer(state, action)
  }
}

// ----------------------------------------------------------

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppState>[] = DebugOptions.logActions
  ? [ logger ]
  : []
