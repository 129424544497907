import { formatDate } from '@angular/common'

import {
  format,
  parse,
  startOfMonth
} from 'date-fns'

// ------------------------------------------------------------
// Functions converting YearMonth strings and Date instances

export const parseYearMonth = (ym: string) => parse(ym, 'yyyy-MM', startOfMonth(new Date()))

export const formatYearMonth = (d: string | Date): string => {
  return typeof d === 'string'
    ? d
    : format(d, 'yyyy-MM')
}

export const displayYearMonth = (d: string | Date): string => {
  if (typeof d === 'string') {
    d = parseYearMonth(d)
  }

  return format(d, 'MMM yy')
}

export const displayFullYearMonth = (d: string | Date): string => {
  if (typeof d === 'string') {
    d = parseYearMonth(d)
  }

  return format(d, 'MMM yyyy')
}

// ------------------------------------------------------------
// Simple YearMonth class

export class YearMonth {

  static parse(
    ym: string
  ): YearMonth | null {
    const date = parseYearMonth(ym)
    if (!isFinite(date.valueOf())) {
      return null
    }
    return YearMonth.fromDate(date)
  }

  // ----------------------------------------------------

  static fromDate(
    d: Date
  ): YearMonth {
    return new YearMonth(d.getFullYear(), d.getMonth() + 1)
  }

  // ----------------------------------------------------

  constructor(
    public year: number,
    public month: number
  ) {}

  // ----------------------------------------------------

  equals(
    other: YearMonth
  ): boolean {
    return this.year === other.year && this.month === other.month
  }

  // ----------------------------------------------------

  previous(): YearMonth {
    return this.month === 1
      ? new YearMonth(this.year - 1, 12)
      : new YearMonth(this.year, this.month - 1)
  }

  // ----------------------------------------------------

  next(): YearMonth {
    return this.month === 12
      ? new YearMonth(this.year + 1, 1)
      : new YearMonth(this.year, this.month + 1)
  }

  // ----------------------------------------------------

  asDate(): Date {
    return new Date(this.year, this.month - 1, 1)
  }

  // ----------------------------------------------------

  format(
    locale: string
  ): string {
    return formatDate(this.asDate(), 'MMM y', locale)
  }

  // ----------------------------------------------------

  toString() {
    return this.year.toString().padStart(4, '0') + '-' + this.month.toString().padStart(2, '0')
  }

}

// ------------------------------------------------------------
// YearMonth iterator

export function* yearMonthIterator(
  from: string,
  to?: string
) {
  let cur = YearMonth.parse(from)
  const end = YearMonth.parse(to || from)

  yield cur

  while (!cur.equals(end)) {
    cur = cur.next()

    yield cur
  }
}

export function* yearMonthStringIterator(
  from: string,
  to?: string
) {
  for (const ym of yearMonthIterator(from, to)) {
    yield ym.toString()
  }
}

export function yearMonthRange(
  from: string,
  to?: string
): string[] {
  return Array.from(yearMonthStringIterator(from, to))
}
