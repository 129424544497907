import { AppointmentRole, Region } from '@libs/models'

import type { LeftNavLinksData } from './left-nav-links-data'

// -------------------------------------------------------

export const LeftNavLinks: LeftNavLinksData[][] = [
  [
    {
      target: 'my-options',
      label: $localize`My Options`,
      image: 'options',
      allowedRegions: [ Region.Commonwealth, Region.France, Region.Ireland, Region.HongKong, Region.Singapore ],
      condition: company => company.hasOptionAgreement
    }
  ],
  [
    {
      target: 'pitch',
      accessRole: AppointmentRole.Admin,
      label: $localize`Pitch`,
      image: 'pitch',
      allowedRegions: [
        Region.Commonwealth,
        Region.Europe,
        Region.France,
        Region.Germany,
        Region.Ireland,
      ]
    },
    {
      target: 'raise',
      accessRole: AppointmentRole.Admin,
      label: $localize`Raise`,
      image: 'raise',
      allowedRegions: [
        Region.Commonwealth,
        Region.France,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore
      ],
    },
    {
      target: 'captable',
      accessRole: AppointmentRole.Read,
      label: $localize`Shares`,
      image: 'captable',
      allowedRegions: [
        Region.Commonwealth,
        Region.Europe,
        Region.France,
        Region.Germany,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore,
      ],
    },
    {
      target: 'board',
      accessRole: AppointmentRole.Admin,
      label: $localize`Board`,
      image: 'board',
      allowedRegions: [ Region.Commonwealth ]
    }
  ],
  [
    {
      target: 'team',
      accessRole: AppointmentRole.Admin,
      label: $localize`Team`,
      image: 'team',
      allowedRegions: [
        Region.Commonwealth,
        Region.France,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore,
      ],
    },
    {
      target: 'agreements',
      accessRole: AppointmentRole.Admin,
      label: $localize`Agreements`,
      image: 'quick',
      allowedRegions: [
        Region.Commonwealth,
        Region.France,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore,
      ],
    },
    {
      target: 'options',
      accessRole: AppointmentRole.Admin,
      label: $localize`Share Options`,
      image: 'options',
      allowedRegions: [
        Region.Commonwealth,
        Region.France,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore
      ]
    },
    {
      target: 'research',
      accessRole: AppointmentRole.Admin,
      label: $localize`R&D Tax Credits`,
      image: 'research',
      allowedRegions: [ Region.Commonwealth ]
    },
    {
      target: 'policies',
      accessRole: AppointmentRole.Admin,
      label: $localize`Policies`,
      image: 'policies',
      allowedRegions: [ Region.Commonwealth, Region.Ireland ]
    }
  ],
  [
    {
      target: 'calendar',
      accessRole: AppointmentRole.Admin,
      label: $localize`Calendar`,
      image: 'calendar',
      allowedRegions: [
        Region.Commonwealth,
        Region.Europe,
        Region.France,
        Region.Germany,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore,
      ],
    }
  ],
  [
    {
      target: 'settings',
      accessRole: AppointmentRole.Admin,
      label: $localize`Settings`,
      image: 'details',
      allowedRegions: [
        Region.Commonwealth,
        Region.Europe,
        Region.France,
        Region.Germany,
        Region.HongKong,
        Region.Ireland,
        Region.Singapore,
      ],
    }
  ]
]
