export const LocationConfig = {
  DEV: {
    GROW_APP_LOCATION: 'http://local.seedlegals.com:3000/',
    INVEST_APP_LOCATION: 'http://local.seedlegals.com:3000/',
    PITCH_LOCATION: 'http://local.seedlegals.com:3000/pitch',
    WITNESS_LOCATION: 'http://local.seedlegals.com:3000/witness',
    assetsLocation: 'https://s3-eu-west-2.amazonaws.com/seedlegals-development-assets/'
  },
  DEMO1: {
    GROW_APP_LOCATION: 'https://1.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-1.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://1.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://1.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-1.demo.seedlegals.com/'
  },
  DEMO2: {
    GROW_APP_LOCATION: 'https://2.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-2.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://2.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://2.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-2.demo.seedlegals.com/',
  },
  DEMO3: {
    GROW_APP_LOCATION: 'https://3.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-3.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://3.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://3.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-3.demo.seedlegals.com/',
  },
  DEMO4: {
    GROW_APP_LOCATION: 'https://4.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-4.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://4.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://4.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-4.demo.seedlegals.com/',
  },
  DEMO5: {
    GROW_APP_LOCATION: 'https://5.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-5.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://5.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://5.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-5.demo.seedlegals.com/',
  },
  DEMO6: {
    GROW_APP_LOCATION: 'https://6.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-6.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://6.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://6.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-6.demo.seedlegals.com/',
  },
  DEMO7: {
    GROW_APP_LOCATION: 'https://7.demo.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest-7.demo.seedlegals.com/',
    PITCH_LOCATION: 'https://7.demo.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://7.demo.seedlegals.com/witness',
    assetsLocation: 'https://assets-7.demo.seedlegals.com/',
  },
  INT: {
    GROW_APP_LOCATION: 'https://legal.int.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest.int.seedlegals.com/',
    PITCH_LOCATION: 'https://legal.int.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://legal.int.seedlegals.com/witness',
    assetsLocation: 'https://assets.int.seedlegals.com/'
  },
  PROD: {
    GROW_APP_LOCATION: 'https://app.seedlegals.com/',
    INVEST_APP_LOCATION: 'https://invest.seedlegals.com/',
    PITCH_LOCATION: 'https://app.seedlegals.com/pitch',
    WITNESS_LOCATION: 'https://app.seedlegals.com/witness',
    assetsLocation: 'https://assets.seedlegals.com/'
  }
}
