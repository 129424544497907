import { NgModule } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { HttpClientModule, HttpHeaders } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import localeFrance from '@angular/common/locales/fr'
import localeGB from '@angular/common/locales/en-GB'

import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EntityDataModule } from '@ngrx/data'

import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { InMemoryCache, concat, ApolloLink } from '@apollo/client/core'

import { metaReducers, reducers } from './+state/app.reducer'
import { appEntityMetadata } from './app-entity-metadata'
import { AuthStorageKeys } from '@app/auth/auth.constants'

import { CoreModule } from '@app/core/core.module'
import { AppRoutingModule } from './app-routing.module'
import { UsersStateModule } from './users/users-state.module'

import { AppComponent } from './app.component'

import { DebugOptions, environment, debugStyles as _ds } from '@env/environment'
import { Profile } from '@env/models'

// ----------------------------------------------------------

// declare const require
registerLocaleData(localeFrance, 'fr')
registerLocaleData(localeGB, 'en')

// ----------------------------------------------------------

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApolloModule,
    HttpClientModule,
    // NgrxCacheModule,

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
        // strictActionSerializability: true,
        // strictStateSerializability: true
      }
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      // serializer: MinimalRouterStateSerializer
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'SeedLegals',
      logOnly: environment.profile === Profile.Production || environment.profile === Profile.Int
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([]),

    EntityDataModule.forRoot({
      entityMetadata: appEntityMetadata
    }),

    UsersStateModule,

    CoreModule.forRoot(),

    AppRoutingModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({
          uri: `${environment.apiRoot}graphql`
        })

        const authMiddleware = new ApolloLink((operation, forward) => {
          // add the authorization to the headers
          operation.setContext({
            headers: new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem(AuthStorageKeys.ID_TOKEN)}`)
          })

          if (DebugOptions.logConsoleOutput) {
            console.groupCollapsed(`%cREQUEST%c  GRAPHQL %c${operation.operationName}`, _ds.requestRequest, '', _ds.requestUrl)
            console.dir(operation.variables)
            // eslint-disable-next-line no-restricted-syntax
            console.trace()
            console.groupEnd()
          }

          const start = Date.now()

          return forward(operation).map(data => {
            if (DebugOptions.logConsoleOutput) {
              const taken = (Date.now() - start) / 1000

              if (data.errors) {
                console.groupCollapsed(`%cERROR%c    GRAPHQL %c${operation.operationName}%c (%fs)`, _ds.requestError, '', _ds.requestUrl, '', taken)
                console.dir(data.errors)
                console.groupEnd()
              } else {
                console.groupCollapsed(`%cRESPONSE%c GRAPHQL %c${operation.operationName}%c (%fs)`, _ds.requestResponse, '', _ds.requestUrl, '', taken)
                console.dir(data.data)
                console.groupEnd()
              }
            }

            return data
          })
        })

        return {
          cache: new InMemoryCache({
            possibleTypes: {
              Event: [
                'Employment',
                'EmploymentVariation',
                'EmploymentBonusPayment',
                'EmploymentSalaryReview',
                'EmploymentTermination'
              ]
            }
          }),
          link: concat(authMiddleware, http)
        }
      },
      deps: [
        HttpLink
      ]
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({
          uri: `${environment.apiGatewayRoot}public-graphql`
        })

        return {
          public: {
            name: 'public',
            cache: new InMemoryCache(),
            link: http
          }
        }
      },
      deps: [
        HttpLink
      ]
    }
    // {
    //   provide: TRANSLATIONS,
    //   useFactory: (locale: string) => {
    //     locale = locale || 'en'
    //     return require(`raw-loader!../../locale/messages.${locale}.xlf`).default
    //   },
    //   deps: [ LOCALE_ID ]
    // }
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
