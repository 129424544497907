import { createFeatureSelector, createSelector } from '@ngrx/store'

import { partition } from 'ramda'

import { findById } from '@libs/utils'
import { IUserData, IUserCompany, UserCompanyAccess, ExtendedRole, RolesData, IUserCompanyWithRoleInfo, LeftNavInfo } from '@libs/models'

import { selectRegions } from '@app/core/+state/core.selectors'
import { getCurrentCompanyId } from '@app/layout/+state/layout.selectors'

import { USERS_FEATURE_KEY, UsersState } from './users.reducer'

// ----------------------------------------------------------

export const selectUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY)

export const selectUserLoaded = createSelector(
  selectUsersState,
  (state: UsersState) => state.currentUserLoaded
)

// ----------------------------------------------------------

export const selectUser = createSelector(
  selectUsersState,
  (state: UsersState) => state.currentUser
)

export const selectUserId = createSelector(
  selectUser,
  (user: IUserData) => user.id
)

// ----------------------------------------------------------

export const selectUserCompanies = createSelector(
  selectUsersState,
  (state: UsersState) => state.currentUserCompanies
)

// ----------------------------------------------------------

export const selectDefaultUserCompany = createSelector(
  selectUserCompanies,
  (userCompanies: IUserCompany[]) => {
    return userCompanies.find(({ access }) => access !== UserCompanyAccess.Documents)
      ?? userCompanies[ 0 ]
  }
)

// ----------------------------------------------------------

export const selectCurrentCompany = createSelector(
  selectUserCompanies,
  getCurrentCompanyId,
  (companies, companyId) => findById(companyId, companies)
)

// ----------------------------------------------------------

export const selectCurrentRegion = createSelector(
  selectCurrentCompany,
  selectRegions,
  (userCompany, regions) => userCompany && regions
   ? regions.find(r => r.jurisdictions.includes(userCompany.company.jurisdiction)).id
   : null
)

// ----------------------------------------------------------

export const selectLeftNavInfo = createSelector(
  selectUserCompanies,
  getCurrentCompanyId,
  (userCompanies, currentCompanyId): LeftNavInfo => {
    const companiesWithRoleInfo: IUserCompanyWithRoleInfo[] = userCompanies.map(userCompany => {
      const extendedRoles = new Set<ExtendedRole>(userCompany.roles as unknown as ExtendedRole[])

      if (userCompany.access === UserCompanyAccess.StaffWrite) {
        extendedRoles.add(ExtendedRole.StaffWrite)
      } else if (userCompany.access === UserCompanyAccess.StaffRead) {
        extendedRoles.add(ExtendedRole.StaffRead)
      }

      return {
        ...userCompany,
        extendedRoles,
        roleInfo: RolesData.find(rd => extendedRoles.has(rd.id))?.name
      }
    })

    const [ currentCompanies, otherCompanies ] = partition(c => c.id === currentCompanyId, companiesWithRoleInfo)

    const currentCompany = currentCompanies?.[ 0 ]
    const moreDocumentsToSign = otherCompanies.reduce((tot, uc) => tot + uc.unsignedDocuments, 0)

    return {
      currentCompany,
      otherCompanies,
      moreDocumentsToSign,
    }
  }
)

// ----------------------------------------------------------

export const selectUnsignedDocumentCount = createSelector(
  selectUserCompanies,
  userCompanies => userCompanies.reduce(
    (total, userCompany) => total + userCompany.unsignedDocuments,
    0
  )
)

export const selectHasUnsignedDocuments = createSelector(
  selectUserCompanies,
  userCompanies => userCompanies.some(userCompany => userCompany.unsignedDocuments)
)
