import type { CohortTeam, InterestType, RoundType, ShareClass } from '../things'

import type { IModelData, INamedEntity, ITimestamped } from './model.model'
import type { ICompanyExcerptData } from './company.model'
import type { IUserExcerptData } from './user.model'
import type { IDocumentExcerptData, IDocumentWithSignaturesData } from './document.model'
import type { SeedNoteType } from './seed-note-type'
import type { ICompanyCohortTeamData } from './cohort.model'
import type { EventSupportingDocument, SupportingDocumentType } from './supporting-documents.model'
import type { RoundingMode } from '../things/events/rounding-mode'
import type { OptionReturnType } from '../things/events/option-return-type'

// ----------------------------------------------------------

export enum InvestmentType {
  Subscription = 'SUBSCRIPTION',
  Conversion = 'CONVERSION'
}

// ----------------------------------------------------------

export const InvestmentTypeLabels: Array<{ key: string, label: string }> = [
  { key: InvestmentType.Subscription, label: 'Cash' },
  { key: InvestmentType.Conversion, label: 'Conversion' }
]

// ----------------------------------------------------------

export const enum AntiDilution {
  None = 'NONE',
  Narrow = 'WEAV',
  Broad = 'BWAV',
  Full = 'FURA'
}

export const AntiDilutionLabels = {
  [ AntiDilution.None ]: $localize`None`,
  [ AntiDilution.Narrow ]: $localize`Narrow-based Weighted Average`,
  [ AntiDilution.Broad ]: $localize`Broad-based Weighted Average`,
  [ AntiDilution.Full ]: $localize`Full Ratchet`
}

// ----------------------------------------------------------

export const enum InvestmentScheme {
  Standard = 'STANDARD',
  EIS = 'EIS',
  SEIS = 'SEIS',
  VCT = 'VCT'
}

// ----------------------------------------------------------

export const InvestmentSchemeNames: Map<InvestmentScheme, string> = new Map([
  [ InvestmentScheme.SEIS, $localize`SEIS` ],
  [ InvestmentScheme.EIS, $localize`EIS` ],
  [ InvestmentScheme.VCT, $localize`VCT` ],
  [ InvestmentScheme.Standard, $localize`Standard` ]
])

// ----------------------------------------------------------

export type OptionType =
  | 'NONE'
  | 'PREM'
  | 'POPR'
  | 'POST'

export enum OptionTypes {
  None = 'NONE',
  PreMoney = 'PREM',
  PostMoneyPreValuation = 'POPR',
  PostMoney = 'POST'
}

// ----------------------------------------------------------

export interface IRoundSeisInfo {
  hasSeis: boolean
  seisAvailable?: number
  seisExceeded?: boolean
  legacySeisExceeded?: boolean
}

// ----------------------------------------------------------

export interface IInvestorData extends IModelData {
  investingCompany: ICompanyExcerptData | null
  investingUser: IUserExcerptData | null
}

// ----------------------------------------------------------

export interface IInvestorWithInvestmentsData extends IInvestorData {
  investments: IInvestmentWithShareClassData[]
}

// ----------------------------------------------------------

export interface IInvestmentData extends IModelData {
  amount: number
  intendedOwnership: number
  intendedCount: number
  discount: number
  type: InvestmentType
  scheme: InvestmentScheme
  received: string
}

// ----------------------------------------------------------

export interface IInvestmentWithShareClassData extends IInvestmentData {
  shareClass: IShareClassData
}

// ----------------------------------------------------------

export interface IInvestmentWithRoundData extends IInvestmentData {
  round: IRoundData
}

// ----------------------------------------------------------

export interface IInvestmentWithInvestorShareAndShareClassData extends IInvestmentWithShareClassData {
  investor: IInvestorData
  share: IShareData | null
  loan: ILoanData | null
}

// ----------------------------------------------------------

export interface IShareBaseData extends IModelData {
  count: number
  issued: string
  certno: string | null
  annulled: boolean
}

// ----------------------------------------------------------

export interface IShareData extends IShareBaseData {
  investmentScheme: InvestmentScheme
}

// ----------------------------------------------------------

export interface IShareWithShareClassAndHolderData extends IShareData {
  investment: IInvestmentData
  investor: IInvestorData
  shareClass: IShareClassData
}

// ----------------------------------------------------------

export interface IShareClassData extends IModelData {
  name: string
  seniority: number
  preferred: boolean
  voting: number
  participating: boolean
  dividends: boolean
  transferable: boolean
  antiDilution: AntiDilution
  liquidation: boolean
  multiplier: number
}

// ----------------------------------------------------------

export interface IOptionExcerptData extends IModelData {
  count: number
  active: boolean
  certno: string | null
  optionExercise: IOptionExerciseData
}

// ----------------------------------------------------------

export interface IOptionWithCertificateData extends IModelData {
  count: number
  grantedDate: string | null
  active: boolean
  certno: string | null
  investor: IInvestorData
  optionScheme: IOptionSchemeExcerptData
  optionGrant: IOptionGrantData
  documents: IDocumentWithSignaturesData[]
}

// ----------------------------------------------------------

export interface IEventData extends IModelData {
  answers: object
  effectiveDate: string
  inserted: string | null
  approved: string | null
}

// ----------------------------------------------------------

export interface IEventWithSupportingDocumentsData extends IEventData {
  supportingDocumentTypes: SupportingDocumentType[]
  supportingDocuments: EventSupportingDocument[]
}

// ----------------------------------------------------------

// tslint:disable-next-line
export type IAdvanceAssuranceExcerptData = IEventData

// ----------------------------------------------------------

export interface IAdvanceAssuranceData extends IAdvanceAssuranceExcerptData {
  advanceAssuranceIntents: IAdvanceAssuranceIntentData[]
}

// ----------------------------------------------------------

export interface IAdvanceAssuranceIntentData extends IModelData {
  amount: number
  investor: IInvestorData
}

// ----------------------------------------------------------

export interface IBoardMeetingEventData extends IEventData {
  directorships: IDirectorshipEventData[]
  directorshipTerminations: IDirectorshipEventData[]
}

// ----------------------------------------------------------

export interface IComplianceData extends IEventWithSupportingDocumentsData {
  signingDirector: IUserExcerptData
  statements: IStatementData[]
  startDate: string
  endDate: string
}

// ----------------------------------------------------------

export interface IStatementData extends IModelData {
  effectiveDate: string
  uir: string
  scheme: InvestmentScheme
  shareClass: IShareClassData
}

// ----------------------------------------------------------

export interface IConvertibleNoteExcerptData extends IEventData {
  investmentScheme: InvestmentScheme
  discount: number
  cap: number
  lowValuation: number
  floorValuation: number
  longstopDate: string
  maturityDate: string
  historical: boolean
  cohortTeam: CohortTeam
}

// ----------------------------------------------------------

export interface IConvertibleNoteData extends IConvertibleNoteExcerptData {
  shareClass: IShareClassData
  loan: ILoanData
}

// ----------------------------------------------------------

export interface IInstantConversionData extends IEventData {
  shareClass: IShareClassData
  loans: ILoanWithShareData[]
}

// ----------------------------------------------------------

export interface IDirectorshipEventData extends IEventData {
  director: IUserExcerptData
}

export interface IDirectorshipVariationEventData extends IEventData {
  directorship: IDirectorshipEventData
}

export interface IDirectorshipTerminationEventData extends IEventData {
  directorship: IDirectorshipEventData
}

// ----------------------------------------------------------

export interface ICohortFundingData extends IEventData {
  name: string
  optionCount: number
  optionPoolPercentage: number
  cohortTeam: ICompanyCohortTeamData
  shareClass: IShareClassData
  loans: ILoanData[]
  investments: IInvestmentWithInvestorShareAndShareClassData[]
}

// ----------------------------------------------------------

export interface ISeedNoteData extends IEventData {
  name: string
  type: SeedNoteType
  target: number
  maturityDate: string
  interestRate: number
  discount: number
  interestType: InterestType
  shareClass: ShareClass
  cap: number
  lowValuation: number
  loans: ILoanData[]
  historical: boolean
  interestStartDate: string
}

// -----------------------------------------------------------

export interface ISeedSaftData extends IEventData {
  amount: number
  investor: IInvestorData
}

// -----------------------------------------------------------

export interface ILoanData extends IModelData {
  amount: number
  conversionPrice: number | null
  received: string
  investor: IInvestorData
  loanCertificate: ILoanCertificateData
}

// -----------------------------------------------------------

export interface ILoanWithShareData extends ILoanData {
  share: IShareWithShareClassAndHolderData
}

// ------------------------------------------------------------

export interface ILoanCertificateData extends IModelData {
  certno: string
  issued: string
  loan: ILoanData
}

// ----------------------------------------------------------

export interface IMilestone {
  description: string
  optionCount: number
}

export enum VestingType {
  Time = 'time',
  Milestones = 'milestones'
}

// ----------------------------------------------------------

export interface IEmiValuationExcerptData extends IEventData {
  strikePrice: number
  amv: number
  iumv: number
  hmrcApprovalDate: string
  purchased: boolean
  officialAmv: number | null
  officialIumv: number | null
  historical: boolean
}

// ----------------------------------------------------------

export interface IEmiValuationData extends IEmiValuationExcerptData {
  shareClass: IShareClassData
  signingDirector: IUserExcerptData
  supportingDocuments: EventSupportingDocument[]
}

// ----------------------------------------------------------

export interface IExitData extends IEventData {
  investor: IInvestorData
  valuation: number
  exitPayouts: IExitPayoutData[]
}

export interface IExitPayoutData extends IModelData {
  sellingInvestor: IInvestorData
  shareClass: IShareClassData
  scheme: InvestmentScheme
  count: number
  amount: number
  share: IShareData | null
}

// ----------------------------------------------------------

export interface IOptionExerciseExcerptData extends IEventData {
  count: number
  strikePrice: number
  scheme: InvestmentScheme
}

// ----------------------------------------------------------

export interface IOptionExerciseData extends IOptionExerciseExcerptData {
  share: IShareData | null
  remainderOption: IOptionExcerptData | null
  shareClass: IShareClassData
  investor: IInvestorData
}

// ----------------------------------------------------------

export interface IOptionGrantExcerptData extends IEventData {
  count: number
  strikePrice: number
  vesting: IOptionGrantVesting
  optionScheme: IOptionSchemeExcerptData
}

// ----------------------------------------------------------

export interface IOptionAcceleration {
  accelerated: boolean
  accelerationPortion: number
  accelerationPeriod: number
  accelerateOnIPO: boolean
}

// ----------------------------------------------------------

export interface ITimeBasedOptionVesting extends IOptionAcceleration {
  type: VestingType.Time
  period: number
  frequency: number
  vestedAtStart: number
  cliff: number
}

// ----------------------------------------------------------

export interface IMilestoneBasedOptionVesting extends IOptionAcceleration {
  type: VestingType.Milestones
  milestones: IMilestone[]
}

// ----------------------------------------------------------

export const DEFAULT_VESTING_OPTIONS: ITimeBasedOptionVesting = {
  type: VestingType.Time,
  period: 36,
  frequency: 1,
  vestedAtStart: 0,
  cliff: 0,
  accelerated: true,
  accelerationPortion: 100,
  accelerationPeriod: 12,
  accelerateOnIPO: false
}

export const DEFAULT_MILESTONE_VESTING_OPTIONS: IMilestoneBasedOptionVesting = {
  type: VestingType.Milestones,
  milestones: [],
  accelerated: true,
  accelerationPortion: 100,
  accelerationPeriod: 12,
  accelerateOnIPO: false
}

// ----------------------------------------------------------

export type IOptionVesting = ITimeBasedOptionVesting | IMilestoneBasedOptionVesting

// ----------------------------------------------------------

export type ITimeBasedOptionGrantVesting = ITimeBasedOptionVesting & { startDate: string }
export type IMilestoneBasedOptionGrantVesting = IMilestoneBasedOptionVesting & { startDate: string }

export type IOptionGrantVesting = ITimeBasedOptionGrantVesting | IMilestoneBasedOptionGrantVesting

// ----------------------------------------------------------

export interface IOptionGrantData extends IOptionGrantExcerptData {
  investor: IInvestorData
  optionScheme: IOptionSchemeData
  options: IOptionExcerptData[]
}

// ----------------------------------------------------------

export interface IOptionPoolData extends IEventData {
  count: number
  historical: boolean
}

// ----------------------------------------------------------

export interface IOptionReturnData extends IEventData {
  count: number
  type: OptionReturnType
}

// ----------------------------------------------------------

export type LeaverStatus = 'GOOD' | 'BAD'

export enum LeaverStatuses {
  Good = 'GOOD',
  Bad = 'BAD'
}

// ----------------------------------------------------------

export interface IStopVestingData extends IEventData {
  leaverStatus: LeaverStatus
  optionGrant: IOptionGrantData
}

// ----------------------------------------------------------

export interface IOptionSchemeExcerptData extends IEventData, ITimestamped {
  description: string
  emi: boolean
  hmrcApproval: string
}

// ----------------------------------------------------------

export interface IOptionSchemeData extends IOptionSchemeExcerptData {
  shareClass: IShareClassData
}

// ----------------------------------------------------------

export interface IProposalData extends IEventData, INamedEntity {
  company: ICompanyExcerptData
  founderCompany: ICompanyExcerptData
  investmentAmount: number
  investmentScheme: InvestmentScheme
  valuation: number
  optionOwnership: number
  optionType: OptionType
  target: number
  roundCompletionDate: string
  modified: string | null
  round: IRoundExcerptData
  documents: IDocumentExcerptData[]
  user: IUserExcerptData
}

// ----------------------------------------------------------

export interface IResearchAssuranceData extends IEventWithSupportingDocumentsData {
  signingDirector: IUserExcerptData
}

// ----------------------------------------------------------

export interface IResearchClaimData extends IEventWithSupportingDocumentsData {
  startDate: string
  taxableBalanceYear1: number
  taxableBalanceYear2: number
}

// ----------------------------------------------------------

export interface IShareClassRegistrationData extends IEventData {
  shareClass: IShareClassData
}

// ----------------------------------------------------------

export interface IInstantInvestmentConsentData extends IEventData {
  target: number
  pricePerShare: number
}

export interface IShareAllotmentReturnData extends IEventData {
  startDate: string
  endDate: string
}

// ----------------------------------------------------------

export interface IRoundExcerptData extends IEventData, INamedEntity {
  type: RoundType
  pricePerShare: number
  valuation: number
  target: number
  seisAvailable: number
  shareCountRounding: RoundingMode
  allocatedOptionCount: number
  optionCount: number
  optionOwnership: number
  optionType: OptionType
  conversationEnabled: boolean
}

// ----------------------------------------------------------

export interface IRoundData extends IRoundExcerptData {
  shareClasses: IShareClassData[]
  leaders: IInvestorData[]
  investments: IInvestmentWithInvestorShareAndShareClassData[]
  instantInvestmentConsent?: IInstantInvestmentConsentData
}

// ----------------------------------------------------------

export interface IRepaymentData extends IEventData {
  loans: ILoanData[]
}

// ----------------------------------------------------------

export interface IShareTransferData extends IEventData {
  count: number
  pricePerShare: number
  investor: IInvestorData
  share: IShareWithShareClassAndHolderData
  sourceShare: IShareWithShareClassAndHolderData
  remainderShare: IShareWithShareClassAndHolderData
}

// ----------------------------------------------------------

export interface IStockSplitData extends IEventData {
  before: number
  after: number
  historical: boolean
}

// ----------------------------------------------------------

export type EventDataFormats =
  | IAdvanceAssuranceData
  | IComplianceData
  | IConvertibleNoteData
  | IEmiValuationData
  | IOptionExerciseData
  | IOptionGrantData
  | IOptionSchemeData
  | IRoundData
  | IShareTransferData
  | IStockSplitData


// ----------------------------------------------------------

export type InvestmentEventFormats =
  | IConvertibleNoteData
  | IRoundData
