
export enum AppointmentRole {
  Admin = 'ADMIN',
  Director = 'DIRECTOR',
  FoundingEmployee = 'FND_EMPLOYEE',
  FoundingFamily = 'FND_FAMILY',
  Founder = 'FOUNDER',
  Read = 'READ',
  Signatory = 'SIGNATORY'
}

// ----------------------------------------------------------

export enum ExtendedRole {
  Admin = 'ADMIN',
  Director = 'DIRECTOR',
  FoundingEmployee = 'FND_EMPLOYEE',
  FoundingFamily = 'FND_FAMILY',
  Founder = 'FOUNDER',
  Read = 'READ',
  Signatory = 'SIGNATORY',
  Shareholder = 'SHAREHOLDER',
  Investor = 'INVESTOR',
  Employee = 'EMPLOYEE',
  StaffRead = 'STAFF_READ',
  StaffWrite = 'STAFF_WRITE',
}

// ------------------------------------------------------------

export interface IRoleData {
  id: ExtendedRole
  name: string
  property: string
}

// ------------------------------------------------------------

export const RolesData: IRoleData[] = [
  {
    id: ExtendedRole.Admin,
    name: $localize`Admin`,
    property: 'admin',
  },
  {
    id: ExtendedRole.StaffWrite,
    name: $localize`Support (View/Edit)`,
    property: 'admin',
  },
  {
    id: ExtendedRole.StaffRead,
    name: $localize`Support (View)`,
    property: 'admin',
  },
  {
    id: ExtendedRole.Director,
    name: $localize`Director`,
    property: 'director',
  },
  {
    id: ExtendedRole.Signatory,
    name: $localize`Signatory`,
    property: 'signatory',
  },
  {
    id: ExtendedRole.Founder,
    name: $localize`Founder`,
    property: 'founder',
  },
  {
    id: ExtendedRole.FoundingEmployee,
    name: $localize`Founding Employee`,
    property: 'foundingEmployee',
  },
  {
    id: ExtendedRole.FoundingFamily,
    name: $localize`Founder’s Family / Trust`,
    property: 'familyFounder',
  },
  {
    id: ExtendedRole.Read,
    name: $localize`Cap table access`,
    property: 'read',
  },
  {
    id: ExtendedRole.Shareholder,
    name: $localize`Shareholder`,
    property: 'shareholder',
  },
  {
    id: ExtendedRole.Investor,
    name: $localize`Investor`,
    property: 'investor',
  },
  {
    id: ExtendedRole.Employee,
    name: $localize`Employee`,
    property: 'employee',
  },
]

// ------------------------------------------------------------

export const FOUNDER_ROLES: AppointmentRole[] = [
  AppointmentRole.Admin,
  AppointmentRole.Founder,
  AppointmentRole.Director,
  AppointmentRole.Signatory,
  AppointmentRole.Read
]

// ------------------------------------------------------------

export const FOUNDER_ROLES_FRANCE: AppointmentRole[] = [
  AppointmentRole.Admin,
  AppointmentRole.Founder,
  AppointmentRole.Signatory,
  AppointmentRole.Read
]
