import { Pipe, type PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'slDash'
})
export class DashPipe implements PipeTransform {

  transform(
    value: unknown,
    allowFalseyValues = false
  ): unknown {
    return allowFalseyValues
      ? value ?? '—'
      : value || '—'
  }

}
