import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { BackendServiceConfiguration } from '../models/backend-service-config.model'

import { LocalStorageService } from '@libs/storage'
import { BackendService } from './backend.service'

// ----------------------------------------------------------

@Injectable()
export class BackendProvider {
  getBackendService(options: Partial<BackendServiceConfiguration> = {}): BackendService {
    const config = {
      baseUrl: '',
      logging: false,
      ...options
    }

    return new BackendService(config, this.http, this.storage)
  }

  // ----------------------------------------------------

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService
  ) {}
}
