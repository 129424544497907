<div class="filter-container">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>

    <mat-select multiple
                [formControl]="listControl"
                (selectionChange)="emitSelectionChanged()">
      <mat-select-trigger>
        {{ listControl.value[0]?.name || '' }}
        <span *ngIf="isAdditionalSelectionShown" class="additional-selection-label">
          (+{{ listControl.value.length - 1 }} {{ listControl.value.length === 2 ? 'other' : 'others' }})
        </span>
      </mat-select-trigger>
      <mat-option *ngFor="let item of options" [value]="item">{{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-chip-list *ngIf="listControl.value.length"
                 [selectable]="false"
                 aria-label="Filter"
                 i18n-aria-label>
    <mat-chip *ngFor="let item of listControl.value"
              [removable]="true"
              (removed)="removeFilters(item)">
      <span>{{ item.name }}</span>
      <mat-icon matChipRemove fontIcon="icon-close-circle" class="mb-8"></mat-icon>
    </mat-chip>

    <button mat-button
            color="primary"
            (click)="removeFilters()"
            i18n>Clear all filters</button>
  </mat-chip-list>
</div>
