export enum MimeTypes {
  CSV = 'text/csv',
  HTML = 'text/html',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  PDF = 'application/pdf',
  UNKNOWN = 'application/octet-stream',
  ZIP = 'application/zip'
}

// ----------------------------------------------------

export interface IDownload<T = Blob | ArrayBuffer> {
  fileName: string
  content: T
  mimeType: string
}
