
export type Feature =
  | 'AGREEMENTS'
  | 'AGREEMENTS_LITE'
  | 'BOARD'
  | 'CAPTABLE'
  | 'DATAROOM'
  | 'CALENDAR'
  | 'OPTIONS'
  | 'PITCH'
  | 'PITCH_DATA_ROOM'
  | 'POLICIES'
  | 'TEAM'

// ----------------------------------------------------------

export enum Features {
  Agreements = 'AGREEMENTS',
  AgreementsLite = 'AGREEMENTS_LITE',
  Board = 'BOARD',
  CapTable = 'CAPTABLE',
  Dataroom = 'DATAROOM',
  Options = 'OPTIONS',
  Pitch = 'PITCH',
  PitchDataRoom = 'PITCH_DATA_ROOM',
  Policies = 'POLICIES',
  Calendar = 'CALENDAR',
  Team = 'TEAM'
}
