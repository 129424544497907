import { Component, EventEmitter, Input, type OnChanges, Output } from '@angular/core'

import { equals, partition, propEq } from 'ramda'

import { Jurisdiction, type ICompanyRegistrationAgency, CompanyRegistrationAgencies } from '@libs/models'
import type { CompanyMatch, ILocalCompanyMatch, IRegisteredCompanyMatch } from '../../models/company-match'

// ----------------------------------------------------------

const sourcePartitioner = partition<CompanyMatch>(propEq('source', 'local'))

// ----------------------------------------------------------

@Component({
  selector: 'sl-registrar-company-selector',
  templateUrl: './registrar-company-selector.component.html',
  styleUrls: [ './registrar-company-selector.component.scss' ]
})
export class RegistrarCompanySelectorComponent implements OnChanges {
  @Input() companies: CompanyMatch[] = []
  @Input() jurisdiction?: Jurisdiction
  @Input() allowNewCompany = false
  @Input() preventExistingCompanies = false
  @Input() companyName = ''

  @Output() readonly companySelected = new EventEmitter<CompanyMatch>()
  @Output() readonly notifyAdminCompanyDuplicate = new EventEmitter<ILocalCompanyMatch>()

  readonly equals = equals

  _selectedCompany: CompanyMatch | null = null
  get selectedCompany(): CompanyMatch | null {
    return this._selectedCompany
  }
  set selectedCompany(value: CompanyMatch | null) {
    this._selectedCompany = value
  }

  localCompanies: ILocalCompanyMatch[] = []
  remoteCompanies: IRegisteredCompanyMatch[] = []

  displayRemoteCompanies: boolean

  agencyInfo?: ICompanyRegistrationAgency

  // ----------------------------------------------------

  ngOnChanges(changes): void {
    if (changes.companies) {
      const [ localCompanies, remoteCompanies ] = sourcePartitioner(changes.companies.currentValue)
      this.localCompanies = localCompanies as ILocalCompanyMatch[]
      this.remoteCompanies = remoteCompanies as IRegisteredCompanyMatch[]
    }

    if (changes.jurisdiction) {
      this.agencyInfo = changes.jurisdiction.currentValue
        ? CompanyRegistrationAgencies.get(changes.jurisdiction.currentValue)
        : undefined
    }

    this.displayRemoteCompanies = this.agencyInfo && !!this.remoteCompanies.length
    this.selectedCompany = null
  }

  // ----------------------------------------------------

  isSelectable(match: CompanyMatch): boolean {
    return !(this.preventExistingCompanies && match.source === 'local')
  }

  // ------------------------------------------------------

  get newCompanyMatch(): CompanyMatch {
    return { source: 'new', name: this.companyName } as CompanyMatch
  }

  // ----------------------------------------------------

  onCompanySelected(match: CompanyMatch) {
    if (!this.isSelectable(match)) {
      return
    }
    this.selectedCompany = match
    this.companySelected.emit(match)
  }

}
