import { InjectionToken } from '@angular/core'

import { MatSidenav } from '@angular/material/sidenav'

import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

// ----------------------------------------------------------

export const SideNavData = new InjectionToken<any>('SideNavData')

// ----------------------------------------------------------

export class SideNavRef<C, R = any> {

  private _afterClosed$ = new Subject<R>()

  private closing = false
  // eslint-disable-next-line rxjs/finnish
  public afterClosed = this._afterClosed$.asObservable()

  // ----------------------------------------------------

  constructor(
    public sidenav: MatSidenav,
  ) {
    sidenav._closedStream
      .pipe(
        // logger('sidenav._closedStream'),
        take(1)
      )
      .subscribe(() => {
        if (!this.closing) {
          this.closed()
        }
      })
  }

  // ----------------------------------------------------

  private closed(result?: R) {
    // console.info(`SideNavRef.closed`, result)

    this._afterClosed$.next(result)
    this._afterClosed$.complete()
  }

  // ----------------------------------------------------

  async close(result?: R) {
    // console.info(`SideNavRef.close`, result)

    this.closing = true
    this.closed(result)

    await this.sidenav.close()
  }
}
