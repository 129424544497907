import type { IAddressData, IAddressDataWithoutId } from './address.model'
import type { IAppointmentPublicExcerptData, IAppointmentWithUserData } from './appointment.model'
import type { CurrencyCode } from './currency.model'
import type { IHasPicture, IModelData, INamedEntity, ITimestamped } from './model.model'

import type {
  IAdvanceAssuranceData,
  IBoardMeetingEventData,
  ICohortFundingData,
  IComplianceData,
  IConvertibleNoteData,
  IDirectorshipEventData,
  IDirectorshipTerminationEventData,
  IDirectorshipVariationEventData,
  IEmiValuationData,
  IEventData,
  IExitData,
  IInstantConversionData,
  IInstantInvestmentConsentData,
  IInvestorData,
  IShareAllotmentReturnData,
  IOptionExerciseData,
  IOptionGrantData,
  IOptionPoolData,
  IOptionSchemeData,
  IProposalData,
  IRepaymentData,
  IResearchAssuranceData,
  IResearchClaimData,
  IRoundData,
  ISeedNoteData,
  ISeedSaftData,
  IShareClassData,
  IShareClassRegistrationData,
  IShareTransferData,
  IStockSplitData,
  IStopVestingData,
} from './investment-event.model'
import type { Jurisdiction } from './jurisdiction.model'
import type { ProductId, PurchaseStage } from '../things/money/product'
import type { ITransactionData } from '../things/money/transaction'
import type { SubscriptionMap } from '../things/money/subscription'
import type { IBankAccountData } from './bank-account.model'
import type { ICompanyCohortTeamData } from './cohort.model'
import type { IUserExcerptData } from './user.model'
import type { IAccessWithUserData } from './access.model'

import type { PartialWithoutId } from '@libs/utils'

// ----------------------------------------------------------

type UkCompanyType =
  | 'LTD'
  | 'LIMITED'
  | 'LLP'
  | 'LP'
  | 'PLC'
  | 'LBG'
  | 'PUC'
  | 'CIC'
  | 'OTHER'

type DeCompanyType =
  | 'AG'
  | 'GMBH'
  | 'KG'
  | 'KGAA'
  | 'MBH'
  | 'OHG'
  | 'UG'
  | 'OTHER'

export type FrCompanyType =
  | 'SAS'
  | 'SA'
  | 'SASU'
  | 'SCA'
  | 'SARL'
  | 'EURL'
  | 'SCS'
  | 'SNC'
  | 'SC'
  | 'OTHER'

type HkCompanyType =
  | 'COM_CN'
  | 'LTD_COM_CN'
  | 'UNL_CN'
  | UkCompanyType

type SgpCompanyType =
  | 'PTE_LTD'
  | 'PTE_LIMITED'
  | 'OTHER'

export type CompanyType =
  | DeCompanyType
  | FrCompanyType
  | HkCompanyType
  | SgpCompanyType
  | UkCompanyType

export interface CompanyTypeSuffixAndLabel {
  suffix?: string
  regionalSuffix?: string
  label: string
}
// ----------------------------------------------------------

export const CompanyTypeNames: Record<CompanyType, CompanyTypeSuffixAndLabel> = {
  //Commonwealth
  [ 'LTD' ]: { suffix: 'LTD', label: $localize`Private company limited by shares` },
  [ 'LIMITED' ]: { suffix: 'LIMITED', label: $localize`Private company limited by shares` },
  [ 'LLP' ]: { suffix: 'LLP', label: $localize`Limited liability partnership` },
  [ 'LP' ]: { suffix: 'LP', label: $localize`Limited partnership` },
  [ 'PLC' ]: { suffix: 'PLC', label: $localize`Public limited company` },
  [ 'LBG' ]: { suffix: 'LBG', label: $localize`Private company limited by guarantee` },
  [ 'PUC' ]: { suffix: 'PUC', label: $localize`Private unlimited company` },
  [ 'CIC' ]: { suffix: 'CIC', label: $localize`Community interest company` },

  // France
  [ 'SAS' ]: { suffix: 'SAS', label: $localize`Limited liability company` },
  [ 'SA' ]: { suffix: 'SA', label: $localize`Public limited company` },
  [ 'SASU' ]: { suffix: 'SASU', label: $localize`Limited liability company, sole shareholder` },
  [ 'SCA' ]: { suffix: 'SCA', label: $localize`Publicly traded partnership` },
  [ 'SARL' ]: { suffix: 'SARL', label: $localize`Private limited company` },
  [ 'EURL' ]: { suffix: 'EURL', label: $localize`Single shareholder limited company` },
  [ 'SCS' ]: { suffix: 'SCS', label: $localize`Limited partnership` },
  [ 'SNC' ]: { suffix: 'SNC', label: $localize`General partnership` },
  [ 'SC' ]: { suffix: 'SC', label: $localize`Property company` },

  // Hong Kong
  [ 'COM_CN' ]: { suffix: '', regionalSuffix: '公司', label: '公司' },
  [ 'LTD_COM_CN' ]: { suffix: 'Limited', regionalSuffix: '有限公司', label: '有限公司' },
  [ 'UNL_CN' ]: { suffix: 'Unlimited', regionalSuffix: '無限公司', label: '無限公司' },

  // Singapore
  [ 'PTE_LTD' ]: { suffix: 'Pte. Ltd.', label: $localize`Private company limited by shares` },
  [ 'PTE_LIMITED' ]: { suffix: 'Pte. Limited.', label: $localize`Private company limited by shares` },

  // Germany
  [ 'AG' ]: { suffix: 'AG', label: $localize` Public limited company` },
  [ 'GMBH' ]: { suffix: 'GmbH', label: $localize`Company with limited liability` },
  [ 'KG' ]: { suffix: 'KG', label: $localize`Limited partnership` },
  [ 'KGAA' ]: { suffix: 'KgaA', label: $localize`Partnership limited by shares` },
  [ 'MBH' ]: { suffix: 'mbH', label: $localize`Company with limited liability` },
  [ 'OHG' ]: { suffix: 'OHG', label: $localize`Open business company` },
  [ 'UG' ]: { suffix: 'UG', label: $localize`Entrepreneurship company (with limited liability)` },

  // Other
  [ 'OTHER' ]: { label: $localize`Other` }
}

// ----------------------------------------------------------

export interface IBaseCompanyData extends IModelData, INamedEntity, IHasPicture {}

// ----------------------------------------------------------
export interface ICompanyData extends IBaseCompanyData {
  companiesHouseNumber: string | null
  sirenCode: string | null
  hmrcReferenceNumber: string | null
  taxReferenceNumber: string | null

  payeReferenceNumber: string | null
  vatNumber: string | null
  address: IAddressData | null
  regionalName: string | null
  greffe: string | null
  amtsgericht: string | null
  currency: CurrencyCode
  type: CompanyType
  jurisdiction: Jurisdiction
  description: string | null
  twitter: string | null
  linkedin: string | null
  facebook: string | null
  website: string | null
  signatureText: string | null
  shareCapital: number | null
  shareNominalValue: number | null
  showHeaderLogo: boolean
  enableStaffWriteAccess: boolean
  claimed: boolean
  hasCohortPortal: boolean
  hasConsented: boolean
  incorporated: string | null
  metadata: object
  subscriptions: SubscriptionMap
  transactions: ITransactionData[]
  accesses: IAccessWithUserData[]
  appointments: IAppointmentWithUserData[]
  bankAccount: IBankAccountData
  companyProducts: CompanyProduct[]
  investors: IInvestorData[]
  shareClasses: IShareClassData[]

  advanceAssurances: IAdvanceAssuranceData[]
  boardMeetings: IBoardMeetingEventData[]
  cohortFundings: ICohortFundingData[]
  cohortTeams: ICompanyCohortTeamData[]
  compliances: IComplianceData[]
  confirmationStatements: IEventData[]
  convertibleNotes: IConvertibleNoteData[]
  directorships: IDirectorshipEventData[]
  directorshipVariations: IDirectorshipVariationEventData[]
  directorshipTerminations: IDirectorshipTerminationEventData[]
  emiValuations: IEmiValuationData[]
  exits: IExitData[]
  instantConversions: IInstantConversionData[]
  optionExercises: IOptionExerciseData[]
  optionGrants: IOptionGrantData[]
  optionSchemes: IOptionSchemeData[]
  optionPools: IOptionPoolData[]
  proposals: IProposalData[]
  investorProposals: IProposalData[]
  stopVestings: IStopVestingData[]
  regularReports: IEventData[]
  repayments: IRepaymentData[]
  researchAssurances: IResearchAssuranceData[]
  researchClaims: IResearchClaimData[]
  rounds: IRoundData[]
  seedNotes: ISeedNoteData[]
  seedSafts: ISeedSaftData[]
  shareClassRegistrations: IShareClassRegistrationData[]
  shareTransfers: IShareTransferData[]
  stockSplits: IStockSplitData[]
  instantInvestmentConsents: IInstantInvestmentConsentData[]
  shareAllotmentReturns: IShareAllotmentReturnData[]
}

export type ICreateCompanyData = PartialWithoutId<Omit<ICompanyData, 'address'> & { address: IAddressDataWithoutId }>

// ----------------------------------------------------------

export interface ICompanyExcerptData extends IBaseCompanyData {
  address: IAddressData | null
  appointments: IAppointmentWithUserData[]
  metadata: object
  currency: CurrencyCode
  jurisdiction: Jurisdiction
  hasConsented: boolean
  claimed: boolean
}

// ----------------------------------------------------------

export interface ICompanyAdminExcerptData extends ICompanyExcerptData {
  companiesHouseNumber: string | null
  sirenCode: string | null
  hubspotId: number | null
  subscriptions: SubscriptionMap
  transactions: ITransactionData[]
  accesses: IAccessWithUserData[]
  enableStaffWriteAccess: boolean
  claimed: boolean
}

// ----------------------------------------------------------

export interface ICompanyExcerptWithSubscriptionsData extends IBaseCompanyData {
  subscriptions: SubscriptionMap
}

// ----------------------------------------------------------

export interface ICompanyPublicExcerptData extends IBaseCompanyData {
  companiesHouseNumber: string | null
  sirenCode: string | null
  address: IAddressData | null
  type: CompanyType
  jurisdiction: Jurisdiction
  currency: CurrencyCode
  description: string | null
  website: string | null
  incorporated: string | null
  claimed: boolean
  appointments: IAppointmentPublicExcerptData[]
}

// ----------------------------------------------------------

export type CompanyDataFormats =
  | ICompanyData
  | ICompanyExcerptData
  | ICompanyExcerptWithSubscriptionsData
  | ICompanyAdminExcerptData
  | ICompanyPublicExcerptData


// ----------------------------------------------------------

interface IFrCompanyTypeMappings {
  mappings: Array<{
    code: string
    type: FrCompanyType
  }>
  default: FrCompanyType
}

const frCompanyTypeMappings: IFrCompanyTypeMappings = {
  mappings: [
    {
      code: '52',
      type: 'SNC'
    },
    {
      code: '53',
      type: 'SCS'
    },
    {
      code: '5498',
      type: 'EURL'
    },
    {
      code: '5499',
      type: 'SARL'
    },
    {
      code: '55',
      type: 'SA'
    },
    {
      code: '65',
      type: 'SC'
    }
  ],
  default: 'SAS'
}

export function getFrCompanyTypeFromCode(code: string): FrCompanyType {
  for (const x of frCompanyTypeMappings.mappings) {
    if (code.startsWith(x.code)) {
      return x.type
    }
  }
  return frCompanyTypeMappings.default
}

// ----------------------------------------------------------

/**
 * Type guards
 */

export function isCompanyData(data: CompanyDataFormats): data is ICompanyData {
  return (<ICompanyData> data).shareClasses !== undefined
}

export function isCompanyPublicExcerptData(data: CompanyDataFormats): data is ICompanyPublicExcerptData {
  return !isCompanyData(data)
    && (<ICompanyPublicExcerptData> data).incorporated !== undefined
}

function isCompanyAdminExcerptData(data: CompanyDataFormats): data is ICompanyAdminExcerptData {
  return !isCompanyData(data)
    && (<ICompanyAdminExcerptData> data).transactions !== undefined
}

export function isCompanyExcerptData(data: CompanyDataFormats): data is ICompanyExcerptData {
  return !isCompanyData(data)
    && !isCompanyAdminExcerptData(data)
    && !isCompanyPublicExcerptData(data)
    && (<ICompanyExcerptData> data).appointments !== undefined
}

export function isCompanyExcerptWithSubscriptionsData(data: CompanyDataFormats): data is ICompanyExcerptWithSubscriptionsData {
  return !isCompanyData(data)
    && !isCompanyAdminExcerptData(data)
    && (<ICompanyExcerptWithSubscriptionsData> data).subscriptions !== undefined
}

// ----------------------------------------------------------

export type WelcomePageKey =
  | 'raise'
  | 'shares'
  | 'board'
  | 'team'
  | 'agreements'
  | 'options'
  | 'pitch'
  | 'policies'
  | 'research'
  | 'researchClaims'
  | 'my-options'
  | 'calendar'

// ----------------------------------------------------------

export interface ICompanyMetadata {
  foundersAndDirectorsSetup?: boolean
  legacyId?: number
  seenWelcomePages?: WelcomePageKey[]
}

// ----------------------------------------------------------

export interface CompanyProduct extends IModelData, ITimestamped {
  product: ProductId
  stage: PurchaseStage
  entity?: string
  transaction: ITransactionData
  user: IUserExcerptData
  used?: Date
}
