import { createReducer, on } from '@ngrx/store'

import { IBreadcrumbItem } from '../models/breadcrumbs'

import {
  BackdropClicked,
  SetNavigationData,
  SetBreadcrumbs,
  MainNavOpen,
  MainNavClose,
  MainNavToggle,
  SideNavOpen,
  SideNavClose,
  SideNavToggle,
  NavigationStarted,
  NavigationFinished,
  ChangeCurrentCompany,
  SetCurrentCompanyId,
  UpdateCurrentUserCompanies,
  CloseLeftNavPanel
} from './layout.actions'
import { TabLink } from '@libs/models'

// ----------------------------------------------------------

export const LAYOUT_FEATURE_KEY = 'layout'

// ----------------------------------------------------------

export interface LayoutState {
  // The state of the main navigation sidebar for mobile
  mainNavOpen: boolean

  // Whether the main nav can be opened/collapsed
  collapsibleMainNav: boolean

  // The state of the right-hand sidebar
  sideNavOpen: boolean

  // The state of the left nav panel
  lefNavPanelOpen: boolean

  // True while navigating between states
  navigating: boolean

  // Contains the current company id
  currentCompanyId: string | null

  // Array of breadcrumb definition items for page header
  breadcrumbs: IBreadcrumbItem[]

  // Array of links for the subnav
  tabLinks: TabLink[]
}

// ----------------------------------------------------------

export interface LayoutPartialState {
  readonly [ LAYOUT_FEATURE_KEY ]: LayoutState
}

// ----------------------------------------------------------

export const initialState: LayoutState = {
  mainNavOpen: false,
  collapsibleMainNav: false,
  sideNavOpen: false,
  lefNavPanelOpen: false,
  navigating: false,
  currentCompanyId: null,
  breadcrumbs: [],
  tabLinks: []
}

// ----------------------------------------------------------

const reducer = createReducer(
  initialState,

  on(SetNavigationData, (state: LayoutState, { breadcrumbs, tabLinks, collapsibleMainNav }) => ({
    ...state,
    breadcrumbs: [ ...breadcrumbs ],
    tabLinks: [ ...tabLinks ],
    collapsibleMainNav
  })),

  on(SetBreadcrumbs, (state: LayoutState, { breadcrumbs }) => ({
    ...state,
    breadcrumbs: [ ...breadcrumbs ]
  })),

  on(BackdropClicked, MainNavClose, (state: LayoutState) => ({
    ...state,
    mainNavOpen: false
  })),

  on(MainNavOpen, (state: LayoutState) => ({
    ...state,
    mainNavOpen: true
  })),
  on(MainNavToggle, (state: LayoutState) => ({
    ...state,
    mainNavOpen: !state.mainNavOpen
  })),

  on(SideNavOpen, (state: LayoutState) => ({
    ...state,
    sideNavOpen: true
  })),
  on(SideNavClose, (state: LayoutState) => ({
    ...state,
    sideNavOpen: false
  })),
  on(SideNavToggle, (state: LayoutState) => ({
    ...state,
    sidenavOpen: !state.sideNavOpen
  })),
  on(CloseLeftNavPanel, ChangeCurrentCompany, (state: LayoutState) => ({
    ...state,
    lefNavPanelOpen: false
  })),

  on(NavigationStarted, (state: LayoutState) => ({
    ...state,
    navigating: true
  })),
  on(NavigationFinished, (state: LayoutState) => ({
    ...state,
    navigating: false
  })),

  on(ChangeCurrentCompany, SetCurrentCompanyId, (state: LayoutState, { companyId }) => ({
    ...state,
    currentCompanyId: companyId
  })),

  on(UpdateCurrentUserCompanies, (state: LayoutState, { companies: userCompanies }) => ({
    ...state,
    userCompanies
  }))

)

export function layoutReducer(state, action) {
  return reducer(state, action)
}
