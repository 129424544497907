import { isoFormat, today } from '@libs/utils'

import { Event } from './event'
import { EventCategory } from '../../models'

// ------------------------------------------------------------

export class RegularReportEvent extends Event {
  readonly domain = 'regularReports'
  readonly category = EventCategory.RegularReport

  // ----------------------------------------------------

  constructor({
    ...data
  }) {
    super({ ...data })

    this.name = 'Regular report'

    this.effectiveDate = this.effectiveDate || isoFormat(today())
  }

  // ----------------------------------------------------

  override get scopeField() {
    return 'regularReport'
  }

  // ----------------------------------------------------

  override get safeName() {
    return `Regular report`
  }

  // ----------------------------------------------------

  getViewState() {
    // TODO: Currently not applicable. Create a dedicated event page one day
    return {
      state: [ '/companies', this.company.id, 'timeline' ]
    }
  }
}
