import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'

import { BackendProvider } from './services/backend.provider'
import { RestApiService } from './services/rest-api.service'

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    BackendProvider,
    RestApiService,
  ]
})
export class BackendModule {}
