import { Injectable } from '@angular/core'

import { type Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { Query } from 'apollo-angular'

import { parseISO } from 'date-fns'

import { mapDeep } from '../objects'

// ----------------------------------------------------------

@Injectable()
export abstract class BaseQuery<T, V = Record<string, unknown>> extends Query<T, V> {
  getData(
    variables?: V
  ): Observable<T> {
    return this.watch(variables, {
      fetchPolicy: 'no-cache'
    })
      .valueChanges
      .pipe(
        filter(result => !!result.data),
        map(result => result.data)
      )
  }

  // ----------------------------------------------------

  fetchData(
    variables?: V
  ): Observable<T> {
    return this.fetch(variables, {
      fetchPolicy: 'no-cache'
    }).pipe(
      map(result => result.data)
    )
  }
}

// ----------------------------------------------------------

export function fixIds<R>(
  src: unknown,
  modelKeys: string[] = []
): R {
  const keySet = new Set(modelKeys)

  return mapDeep((value, key) => {
    if (key === 'id' && typeof value === 'string') {
      return value
    }

    if (key === '__typename' && typeof value === 'string') {
      return undefined
    }

    if (keySet.has(key) && value && typeof value.id === 'string') {
      return value.id
    }

    return value
  }, src) as R
}

// ----------------------------------------------------------

const DEFAULT_DATE_FIELDS = [
  'inserted',
  'modified',
  'updated',
  'archived'
]

// ----------------------------------------------------------

export function fixDates<R, T = unknown>(
  src: T[],
  modelKeys: string[] = DEFAULT_DATE_FIELDS
): R[] {
  const keySet = new Set(modelKeys)

  return mapDeep((value: unknown, key: string) => {
    if (keySet.has(key) && value && typeof value === 'string') {
      return parseISO(value)
    }

    return value
  }, src)
}
