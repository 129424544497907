
export const Colours = [
  '#ffa200',    // orange
  '#01d5ce',    // teal
  '#4d8eff',    // blue
  '#e7e251',    // yellow
  '#f0649d',    // pink
  '#00ffa2',    // green
  '#e874ec'     // purple
]

// ----------------------------------------------------------

export const AvatarColours = [
  '#da4453',    // grapefruit
  '#e9573f',    // bittersweet
  '#f6bb42',    // sunflower
  '#8cc152',    // grass
  '#37bc9b',    // mint
  '#3bafda',    // aqua
  '#4a89dc',    // bluejeans
  '#987adc',    // lavender
  '#d770ad'     // pinkrose
]

export const getAvatarColour = (id: string | undefined) =>
  AvatarColours[
    id
      ? id.charCodeAt(id.length - 1) % AvatarColours.length
      : Math.floor(Math.random() * AvatarColours.length)
  ]
