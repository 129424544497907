import { parseISO } from 'date-fns'

import { ISnapshotData } from '../../models'

// ----------------------------------------------------------

export class DocumentSnapshot {
  id = ''
  version  = 1
  inserted: Date

  // ----------------------------------------------------

  constructor(data: string | ISnapshotData) {
    if (typeof data === 'string') {
      this.inserted = parseISO(data)
    } else {
      this.id = data.id
      this.inserted = parseISO(data.inserted)
      this.version = data.version
    }
  }

  // ----------------------------------------------------

  get link(): string {
    return `/snapshots/${this.id}`
  }
}
