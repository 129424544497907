
export interface IVestingScheduleItem {
  date: Date
  count: number
  total: number

  clone(): IVestingScheduleItem
  combineWith(other: IVestingScheduleItem): this
  initialiseTotal(previous?: IVestingScheduleItem): this
}

// ------------------------------------------------------------

export class VestingScheduleItem implements IVestingScheduleItem {

  constructor(
    public readonly date: Date,
    public count = 0,
    public total = 0,
  ) {}

  // ----------------------------------------------------

  clone(): VestingScheduleItem {
    return new VestingScheduleItem(this.date, this.count, this.total)
  }

  // ----------------------------------------------------

  combineWith(other: VestingScheduleItem): this {
    this.count += other.count
    this.total += other.count
    return this
  }

  // ----------------------------------------------------

  initialiseTotal(previous?: VestingScheduleItem): this {
    this.total = this.count + (previous ? previous.total : 0)
    return this
  }
}
