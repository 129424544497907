<div class="avatar-list"
     fxLayout="row wrap"
     fxLayoutAlign="start center">

  <div *ngFor="let entity of slicedEntities; let last = last;"
       class="avatar-list-wrapper"
       (click)="entityClick.emit(entity)"
       [ngStyle]="{ 'width.px': last ? size : overlapWidth }"
       matTooltip="{{ !last || entitiesRemaining === 0 ? entity.name : '' }}"
       [matTooltipDisabled]="showName">
    <sl-avatar *ngIf="!last || entitiesRemaining === 0"
               [entity]="entity.entity || entity"
               [size]="size">
    </sl-avatar>

    <div *ngIf="last && entitiesRemaining > 0"
          class="remaining-entities"
          [style.width.px]="size"
          [style.lineHeight.px]="size"
          [style.fontSize.px]="size / 3">
        + {{ entities.length - max }}
    </div>
  </div>

  <div *ngIf="showAdd"
       class="avatar-list-wrapper"
       [attr.aria-label]="addLabel">

    <button [routerLink]="addRouterLink"
            mat-stroked-button>
      <mat-icon fontIcon="icon-plus"></mat-icon>
      {{ addLabel }}
    </button>
  </div>

  <ng-content></ng-content>

</div>
