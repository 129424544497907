<mat-expansion-panel class="fee-panel mat-elevation-z0"
                     [expanded]="true"
                     [hideToggle]="true">
  <mat-expansion-panel-header collapsedHeight="48px"
                              expandedHeight="48px">
    <div fxFlex
         fxLayout="row"
         fxLayoutAlign="space-between center">
      <span i18n>Cost Breakdown</span>
      <div fxFlexAlign="end">
        <mat-icon [fontIcon]="expansionPanel.expanded ? 'icon-chevron-down' : 'icon-chevron-up'"></mat-icon>
      </div>
    </div>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <div class="value-list mb-16">
      <ng-container *ngFor="let product of bundle.products">
        <span>{{ ProductNames[ product.product ] }}</span>
        <span>{{ product.price | slPrice }}</span>
      </ng-container>

      <ng-container *ngIf="bundle.savings?.amount > 0">
        <span i18n>Savings</span>
        <span class="savings">-{{ bundle.savings | slPrice | slDash }}</span>
      </ng-container>

      <span i18n>Total</span>
      <sl-fee [fee]="bundle.price"></sl-fee>
    </div>
  </ng-template>
</mat-expansion-panel>
