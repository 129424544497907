import type { Document } from '../documents/document'
import { Event } from './event'
import { EventCategories } from '../../models/category.model'

// ------------------------------------------------------------

export abstract class DocumentEventAdapter<A extends object = object> extends Event<A> {

  override get safeName() {
    return EventCategories[ this.category ]
  }

  // ----------------------------------------------------

  get document(): Document {
    return this.documents.length > 0
      ? this.documents[ 0 ]
      : null
  }
}
