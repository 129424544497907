import { uniqBy } from 'ramda'

import { Collection } from './collection'
import { ApiFieldSpec, INamed, OnCompanyModel } from './model'

import { User } from './user'
import { Company } from './company'
import { AccessRole } from '../models/access.model'

// ------------------------------------------------------------

export class Access extends OnCompanyModel implements INamed {
  readonly domain = 'accesses'

  user: User
  accessRoles: AccessRole[]

  // ----------------------------------------------------

  constructor({
    user = null,
    accessRoles = [],
    ...data
  }) {
    super({ user, accessRoles, ...data })
  }

  // ----------------------------------------------------

  get name() {
    return this.user.name
  }

  // ----------------------------------------------------

  hasAccessRole(accessRole: AccessRole): boolean {
    return this.accessRoles.includes(accessRole)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'accessRoles'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.user.accesses.add(this)
  }

  override detach() {
    super.detach()
    this.user.accesses.remove(this)
  }
}

// ------------------------------------------------------------

export class AccessCollection extends Collection<Access> {
  // Get a collection of accesses in company name order
  static getAccessCollectionForUser(): AccessCollection {
    return new AccessCollection('company.name user.name')
  }

  // ----------------------------------------------------

  // Get a collection of accesses in user name order
  static getAccessCollectionForCompany() {
    return new AccessCollection('user.name company.name')
  }

  // ----------------------------------------------------

  constructor(sortBy = 'user.name') {
    super(sortBy)
  }

  // ----------------------------------------------------

  getUsers(): User[] {
    return uniqBy(u => u.id, this.map(a => a.user))
  }

  // ----------------------------------------------------

  getCompanies(): Company[] {
    return uniqBy(u => u.id, this.map(a => a.company))
  }

  // ----------------------------------------------------

  getAccessForCompany({ id }): Access[] {
    return this.filter(access => access.company.id === id)
  }

  // ----------------------------------------------------

  findAccessForUser({ id }): Access | undefined {
    return this.find(access => access.user.id === id)
  }

  // ----------------------------------------------------

  findAccessForCompany({ id }): Access | undefined {
    return this.find(access => access.company.id === id)
  }
}
