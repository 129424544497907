<mat-card class="mat-elevation-z0">

  <!-- User: Avatar, Name, Email -->
  <!-- Company: Avatar, Website -->
  <mat-card-header fxLayout="row"
                   fxLayoutAlign="start center">
    <sl-avatar [entity]="entity"
               mat-card-avatar></sl-avatar>

    <mat-card-title>{{ entity.name }}</mat-card-title>

    <div fxFlex></div>

    <button (click)="close()"
            mat-icon-button>
      <mat-icon fontIcon="icon-close"></mat-icon>
    </button>

    <!-- User : Email -->
    <mat-card-subtitle *ngIf="user && user.email"
                       fxLayout="row"
                       class="gap-2">
      <span>{{ user.email }}</span>

      <mat-icon *ngIf="user.connected"
                fontIcon="icon-check-circle"
                matTooltip="Email verified"
                i18n-matTooltip
                inline></mat-icon>
    </mat-card-subtitle>

    <!-- Company: Website -->
    <mat-card-subtitle *ngIf="company && company.website">
      <a [href]="company.website | addUrlScheme"
         target="_blank">{{ company.website | stripUrlScheme }}</a>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <!-- User / Company: Address snippet -->
    <div *ngIf="address && address.toFormattedString() as addressString">
      <div class="header" i18n>Address</div>

      <div class="detail">
        {{ addressString }}
      </div>
    </div>

    <!-- User / Company: Social -->
    <div *ngIf="entity.facebook || entity.linkedin || entity.twitter">
      <div class="header" i18n>Social</div>

      <div class="detail social">
        <span *ngIf="entity.twitter">
          <a [href]="'https://twitter.com/' + entity.twitter"
             target="_blank"
             i18n>Twitter</a>
        </span>

        <span *ngIf="entity.linkedin">
          <a [href]="entity.linkedin"
             target="_blank"
             i18n>LinkedIn</a>
        </span>

        <span *ngIf="entity.facebook">
          <a [href]="entity.facebook"
             target="_blank"
             i18n>Facebook</a>
        </span>
      </div>
    </div>

    <!-- Company: Lists of directors -->
    <div *ngIf="company">
      <div class="header" i18n>Directors</div>

      <div class="detail">
        <sl-role-list [appointments]="appointments"
                      role="DIRECTOR"></sl-role-list>
      </div>
    </div>

    <!-- Company: List of signatories -->
    <div *ngIf="company">
      <div class="header" i18n>Signatories</div>

      <div class="detail">
        <sl-role-list [appointments]="appointments"
                      role="SIGNATORY"></sl-role-list>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions *ngIf="canEdit">
    <button (click)="onEditClicked()"
            mat-stroked-button
            color="primary"
            i18n>Edit</button>
  </mat-card-actions>

</mat-card>
