import { Pipe, PipeTransform } from '@angular/core'

// ----------------------------------------------------------

/**
 * Transforms text to sentence case: first letter uppercase, lowercase the rest.
 */
@Pipe({
  name: 'sentencecase'
})
export class SentenceCasePipe implements PipeTransform {
  /**
   * @param value The string to transform to sentence case.
   */
  transform(value: string): string {
    if (!value || typeof value !== 'string') return value

    return value[ 0 ].toUpperCase() + value.substring(1).toLowerCase()
  }
}
