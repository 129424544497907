import { merge } from 'rxjs'
import type { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs'
import { map, filter, sample, take, share, skipUntil } from 'rxjs/operators'

import { delayUntil } from 'rxjs-etc/operators'

import { select } from '@ngrx/store'

import type { HasId } from './functions'

// ----------------------------------------------------

export function matchesId<T extends HasId>(id: string): OperatorFunction<T[], boolean> {
  return (source: Observable<T[]>) => source.pipe(
    map(c => c.some(c2 => c2.id === id))
  )
}

// ----------------------------------------------------

export function withId<T extends HasId>(id: string): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.pipe(
    filter(m => m.id === id)
  )
}

// ----------------------------------------------------

export function skipUntilTruthy<T>(
  notifier: Observable<unknown>
): MonoTypeOperatorFunction<T> {
  return source => source.pipe(
    skipUntil(
      notifier.pipe(
        filter(v => !!v)
      )
    )
  )
}

// ----------------------------------------------------

export function delayUntilTruthy<T>(
  notifier: Observable<unknown>
): MonoTypeOperatorFunction<T> {
  return source => source.pipe(
    delayUntil(
      notifier.pipe(
        filter(v => !!v)
      )
    )
  )
}

// ----------------------------------------------------

export type Truthy<T> =
  false extends T ? never :
  0 extends T ? never :
  '' extends T ? never :
  null extends T ? never :
  undefined extends T ? never :
  T

function isTruthy<T>(
  value: T,
): value is Truthy<T> {
  return !!value
}

export function truthy<T>(): OperatorFunction<T, Truthy<T>> {
  return source => source.pipe(
    filter(isTruthy)
  )
}

// ----------------------------------------------------

export function delayUntilTruthy2<T>(
  notifier$: Observable<unknown>
): MonoTypeOperatorFunction<T> {
  const trueNotifier$ = notifier$.pipe(
    truthy(),
    share(),
  )

  return source => merge(
    source.pipe(
      sample(trueNotifier$),
      take(1),
    ),
    source.pipe(
      skipUntil(trueNotifier$),
    )
  )
}

// ----------------------------------------------------

export function selectDefined<T, K>(
  selector: (state: T) => K,
): OperatorFunction<T, K> {
  return source => source.pipe(
    select(selector),
    filter(value => value !== null),
  )
}
