import { EventCategory } from '../../models/category.model'
import { type OptionType, OptionTypes } from '../../models/investment-event.model'
import { SupportingDocumentType } from '../../models/supporting-documents.model'
import { EventCollectionBase, type IEventViewState } from './event'
import { InvestmentEvent } from './investment-event'
import type { InstantInvestmentConsentEvent } from './instant-investment-consent-event'
import type { ProposalEvent } from './proposal-event'
import { PlatformRoundTypes, RoundType } from './round-type'
import { RoundMetadata } from './round-metadata'
import type { RoundingMode } from './rounding-mode'
import { type Investor, InvestorCollection } from '../stock/investor'
import { type ProductId, Products } from '../money/product'

import { percentage, roundPps, uniqById } from '@libs/utils'

// ------------------------------------------------------------

export class Round extends InvestmentEvent {
  readonly domain = 'rounds'

  type: RoundType
  pricePerShare: number | null
  valuation: number
  target: number
  seisAvailable: number
  allocatedOptionCount: number
  optionCount: number | null
  optionOwnership: number | null
  optionType: OptionType
  shareCountRounding: RoundingMode
  proposals = new EventCollectionBase<ProposalEvent>()

  instantInvestmentConsent?: InstantInvestmentConsentEvent

  proposal: ProposalEvent | null
  leaders = new InvestorCollection()
  conversationEnabled: boolean

  // ----------------------------------------------------

  constructor({
    name = '',
    type = RoundType.Historical,
    pricePerShare = null,
    target = 0,
    valuation = 0,
    optionType = OptionTypes.None as OptionType,
    optionCount = null,
    optionOwnership = null,
    allocatedOptionCount = 0,
    seisAvailable = 250000,
    ...data
  }) {
    super({
      name,
      type,
      target,
      pricePerShare,
      valuation,
      optionType,
      optionCount,
      optionOwnership,
      allocatedOptionCount,
      seisAvailable,
      ...data
    })
  }

  // ----------------------------------------------------

  static fromProposal(proposal: ProposalEvent, overrides: Partial<Round> = {}): Round {
    return new Round({
      company: proposal.founderCompany,
      type: RoundType.Seed,
      target: proposal.target,
      valuation: proposal.valuation,
      optionType: proposal.optionType,
      optionOwnership: Math.max(proposal.optionOwnership, proposal.founderCompany.optionOwnership * 100), // optionOwnership should not be set below existing company.optionOwnership
      effectiveDate: proposal.roundCompletionDate,
      ...overrides
    })
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    if (this.instantInvestmentConsent) {
      this.instantInvestmentConsent.instantInvestments.add(this)
    }
  }

  override detach() {
    super.detach()

    if (this.instantInvestmentConsent) {
      this.instantInvestmentConsent.instantInvestments.remove(this)
    }
  }

  // ----------------------------------------------------

  get category(): EventCategory {
    if (this.historical) {
      return EventCategory.HistoricalRound
    }
    if (this.isInstant) {
      return EventCategory.InstantInvestment
    }
    if (this.isInstantShares) {
      return EventCategory.InstantShares
    }
    return EventCategory.FundingRound
  }

  // ----------------------------------------------------

  get historical(): boolean {
    return this.type === RoundType.Historical
  }

  // ----------------------------------------------------

  get isBootstrap(): boolean {
    return this.type === RoundType.Bootstrap
  }

  // ----------------------------------------------------

  get isSeed(): boolean {
    return this.product === Products.SeedRound as ProductId
  }

  // ----------------------------------------------------

  get isInstant(): boolean {
    return this.type === RoundType.Instant
  }

  // ----------------------------------------------------

  get isInstantShares(): boolean {
    return this.type === RoundType.InstantShares
  }

  // ----------------------------------------------------


  get isFundingRound(): boolean {
    return this.isBootstrap || this.isSeed
  }

  // ----------------------------------------------------


  get isMajorFundingRound(): boolean {
    return this.isSeed
  }

  // ----------------------------------------------------

  get description() {
    return RoundMetadata[ this.type ].description
  }

  // ----------------------------------------------------

  override get scopeField() {
    return 'round'
  }

  // ----------------------------------------------------

  override get safeName() {
    if (this.name) {
      return this.name
    }

    if (this.isInstant) {
      return $localize`${this.investor?.name} investment`
    }

    return this.description
  }

  // ----------------------------------------------------

  get product(): ProductId | null {
    return RoundMetadata[ this.type ].product
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'name',
      'type',
      'pricePerShare',
      'target',
      'valuation',
      'optionType',
      'optionCount',
      'optionOwnership',
      'allocatedOptionCount',
      'seisAvailable',
      'instantInvestmentConsent'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    const state = [ '/companies', this.company.id ]

    if (this.historical) {
      state.push('captable')
    } else if (this.isInstantShares) {
      state.push('captable', 'instant-shares', this.id)
    } else {
      state.push('raise')

      if (this.isInstant) {
        state.push('instant-investments')

        if (this.instantInvestmentConsent) {
          state.push(this.instantInvestmentConsent.id)
          return { state, params: { scroll: this.id } }
        }
      } else {
        state.push('rounds')
      }

      state.push(this.id)
    }

    return { state }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    const viewState = this.getViewState()
    return this.isInstant ? viewState : {
      state: [ ...viewState.state, 'overview' ],
      params: viewState.params
    }
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return Math.max(this.estimatedTarget, this.estimatedInvestment)
  }

  // ----------------------------------------------------

  get estimatedPreMoneyDilutedShareCount(): number {
    let c = this.company.dilutedShareCount

    if (this.optionType === OptionTypes.PreMoney || this.optionType === OptionTypes.PostMoneyPreValuation) {
      c += this.estimatedOptionCount
    }

    return c
  }

  // ----------------------------------------------------

  get estimatedPricePerShare(): number {
    if (Number.isFinite(this.pricePerShare)) {
      return this.pricePerShare
    }

    if (this.valuation > 0 && this.estimatedPreMoneyDilutedShareCount > 0) {
      return roundPps(this.valuation / this.estimatedPreMoneyDilutedShareCount)
    }

    return 0
  }

  // ----------------------------------------------------

  get estimatedValuation(): number {
    if (Number.isFinite(this.valuation)) {
      return this.valuation
    }

    if (this.pricePerShare > 0 && this.estimatedPreMoneyDilutedShareCount > 0) {
      return Math.floor(this.pricePerShare * this.estimatedPreMoneyDilutedShareCount)
    }

    return 0
  }

  // ----------------------------------------------------

  get estimatedOptionCount(): number {
    if (typeof this.optionCount === 'number') {
      return this.optionCount
    }

    if (this.optionType !== OptionTypes.None && this.optionOwnership > 0) {
      if (this.optionType === OptionTypes.PreMoney) {

        return Math.max(0, Math.floor(
          this.company.outstandingShareCount * this.optionOwnership / (100 - this.optionOwnership) - this.company.optionCount
        ))

      } else if (this.optionType === OptionTypes.PostMoneyPreValuation) {

        // TODO: Cannot be solved with our current Object-oriented solution.
        // estimatedCount looks for estimatedPricePerShare, which needs estimatedPreMoneyDilutedShareCount, which needs estimatedOptionCount, which looks for estimatedCount.
        // Instead of count, the calculation should involve investments and pre-money valuation only, to get the estimatedPricePerShare, and infer the estimatedCount and estimatedOptionCount from there.

      } else if (this.optionType === OptionTypes.PostMoney) {

        const estimatedPostMoneyShareCount = this.company.outstandingShareCount + this.estimatedCount

        return Math.max(0, Math.floor(
          estimatedPostMoneyShareCount * this.optionOwnership / (100 - this.optionOwnership) - this.company.optionCount
        ))

      }
    }

    return 0
  }

  // ----------------------------------------------------

  get raisedPercent(): number {
    return percentage(this.investment || this.estimatedInvestment, this.estimatedTarget)
  }

  // ----------------------------------------------------

  get estimatedTarget(): number {
    return this.isInstant ? this.estimatedInvestment : this.target
  }

  // ----------------------------------------------------

  get hasSEIS(): boolean {
    return this.investments.some(i => i.scheme === 'SEIS')
  }

  // ----------------------------------------------------

  get hasEIS(): boolean {
    return this.investments.some(i => i.scheme === 'EIS')
  }

  // ----------------------------------------------------

  get hasSEISOrEIS(): boolean {
    return this.hasSEIS || this.hasEIS
  }

  // ----------------------------------------------------

  getSEISOrEISInvestors(): Investor[] {
    return uniqById(this.investments
      .filter(i => [ 'SEIS', 'EIS' ].includes(i.scheme))
      .map(i => i.investor))
  }

  // ----------------------------------------------------

  override get hasLeadInvestor(): boolean {
    return this.leaders.length > 0
  }

  // ----------------------------------------------------

  get investor(): Investor | null {
    return this.isInstant && this.hasLeadInvestor
      ? this.leaders.item(0)
      : null
  }

  // ----------------------------------------------------

  getMostRecentRound(): Round | undefined {
    const rounds = this.company.closedRounds.filter(round => PlatformRoundTypes.has(round.type))

    return rounds.length
      ? rounds[ 0 ]
      : null
  }

  // ----------------------------------------------------

  getPreviousRoundPricePerShare(): number {
    return this.getMostRecentRound()?.pricePerShare ?? this.company.shareNominalValue
  }

  // ----------------------------------------------------

  override isLeadInvestor(investor: Investor): boolean {
    if (!this.hasLeadInvestor) {
      return false
    }

    return this.leaders.has(investor)
  }

  // -----------------------------------------------------

  getSupportingDocument(type: SupportingDocumentType) {
    const docs = suppDocs[ this.type ]
    if (docs == null) {
      return null
    }
    return this.documents.getAllByType(docs[ type ])[ 0 ]
  }

  // -----------------------------------------------------

  override toString() {
    return `${this.name}(approved: ${this.approved}, effectiveDate: ${this.effectiveDate})`
  }
}

// ------------------------------------------------------------

export class RoundCollection extends EventCollectionBase<Round> {
  getByType(type: RoundType): Round[] {
    return this.filter(r => r.type === type)
  }

  // ----------------------------------------------------

  findCurrentFundingRound(): Round | undefined {
    return this.find(r => r.isFundingRound && !r.closed)
  }

  // ----------------------------------------------------

  findCurrentMajorFundingRound(): Round | undefined {
    return this.find(r => r.isMajorFundingRound && !r.closed)
  }

  // ----------------------------------------------------

  findCurrentSeedRound(): Round | undefined {
    return this.find(r => r.isSeed && !r.closed)
  }

  // ----------------------------------------------------

  getCurrentRoundsByType(type: RoundType): Round[] {
    return this.filter(r => r.type === type && !r.closed)
  }

  // ----------------------------------------------------

  getCompletedRoundsByType(type: RoundType): Round[] {
    return this.filter(r => r.type === type && r.closed)
  }
}

const suppDocs = {
  [ RoundType.Bootstrap ]: {
    [ SupportingDocumentType.InvestmentAgreement ]: 'BSSA',
    [ SupportingDocumentType.Articles ]: 'BSAR'
  },
  [ RoundType.Seed ]: {
    [ SupportingDocumentType.InvestmentAgreement ]: 'SHAG',
    [ SupportingDocumentType.Articles ]: 'ARAS'
  }
}
