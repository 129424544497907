import type { CohortCity, ICohortConfig } from './cohort-config.model'
import { EFConfigByCity } from './ef-config'
import { C13Config } from './c13-config'

import { environment } from '@env/environment'
import { CKConfig } from './ck-config'

// ----------------------------------------------------------

export const EF_USER_COMPANY_ID = environment.CohortManager.EntrepreneurFirstOperationsId // Entrepreneur First Operations
export const EF_LOAN_COMPANY_ID = 'c_d8bQa1CP3o' // Entrepreneur First Investment (Ii)

export const C13_USER_COMPANY_ID = environment.CohortManager.Carbon13Id // Carbon 13
export const C13_LOAN_COMPANY_ID = 'c_lNCvj0h0zz' // KCP Nominees

export const CK_USER_COMPANY_ID = environment.CohortManager.ClimateKicId // Climate-KIC Holdings

export const CohortCompanyIds = [ EF_USER_COMPANY_ID, C13_USER_COMPANY_ID, CK_USER_COMPANY_ID ]

// ----------------------------------------------------------

// TODO: ask Michael can this be moved to question model config
export const C13DefaultAnswers = {
  note: {
    multiple: false
  },
  capAndDiscount: {
    fixedValuation: false,
    capEnabled: false
  },
  nextRound: {
    includeSeedFasts: false,
    amountExclusive: 100_000,
    conversionShares: 'best'
  },
  longstop: {
    months: 6
  },
  investorRights: {
    specificInvestors: 'C13',
    warranties: true,
    allowDownRound: false,
    majorInvestor: true,
    mfn: true,
    selfCertify: false,
    USinvestorDisclaimer: false,
  },
  coverPage: {
    display: false
  }
}

// ------------------------------------------------------

export function getCohortConfig(
  cohortCompanyId: string,
  city?: CohortCity
): ICohortConfig {

  switch (cohortCompanyId) {
    case EF_USER_COMPANY_ID:
      return EFConfigByCity[ city ]

    case C13_USER_COMPANY_ID:
      return C13Config

    case CK_USER_COMPANY_ID:
      return CKConfig
  }

}
