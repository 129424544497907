<mat-toolbar color="primary"
             [style.backgroundColor]="background">

  <mat-toolbar-row class="topnav gap-4 gap-gt-md-16"
                   fxLayoutAlign="start center">
    <button *ngIf="layout.isMainNavCollapsible$ | async"
            (click)="toggleSidenav()"
            mat-icon-button>
      <mat-icon fontIcon="icon-menu"
                matBadge="&#8288;"
                [matBadgeHidden]="(hasUnsignedDocuments$ | async) !== true"
                [matBadgeOverlap]="true"
                matBadgePosition="above before"
                matBadgeSize="small"
                matBadgeColor="accent"></mat-icon>
    </button>

    <button *ngIf="(shouldShowBackButton$ | async) && lastClickableCrumb"
            [routerLink]="lastClickableCrumb.route"
            mat-icon-button>
      <mat-icon fontIcon="icon-chevron-left"></mat-icon>
    </button>

    <sl-breadcrumbs [breadcrumbs]="breadcrumbs"
                    [isAdmin]="layout.currentCompanyFullAccess$ | async"
                    [showAllCrumbs]="(shouldShowBackButton$ | async) === false"></sl-breadcrumbs>

    <span fxFlex="auto"></span>

    <sl-search-field *ngIf="isAppWideSearchEnabled$ | async"
                     fxFlex
                     fxLayoutAlign="end"
                     [companyId]="layout.currentCompanyId$ | async"></sl-search-field>

    <sl-platform-admin-toggle *slDevOnly></sl-platform-admin-toggle>

    <sl-notification-icon></sl-notification-icon>

    <span class="separator"></span>

    <sl-swapp-icon [appType]="appType"></sl-swapp-icon>
  </mat-toolbar-row>

  <mat-toolbar-row *ngIf="links.length > 1"
                   class="subnav">
    <sl-subnav-bar [links]="links"
                   [isAdmin]="layout.currentCompanyFullAccess$ | async"
                   [commentsToggleContainerTemplate]="commentsToggleContainerTemplate"
                   [region]="currentRegion$ | async"></sl-subnav-bar>
  </mat-toolbar-row>

</mat-toolbar>

<div class="page-content-wrapper"
     [class.subnav]="links.length > 1"
     [ngClass]="routeAnimationsElements">

  <ng-container *ngIf="contentContainerTemplate; else outletTemplate">
    <ng-container *ngTemplateOutlet="contentContainerTemplate"></ng-container>
  </ng-container>

  <ng-template #outletTemplate>
    <router-outlet></router-outlet>
  </ng-template>
</div>
