import { addYears } from 'date-fns'

import { isoFormat, today } from '@libs/utils'

import type { ApiFieldSpec } from '../model'
import { EventCategory } from '../../models/category.model'
import { InvestmentScheme, SeedNoteType } from '../../models'
import type { IEventViewState } from './event'
import { InterestType } from '../loan/interest-type'
import { InvestmentEvent } from './investment-event'
import type { LoanSourceEvent } from './loan-event'
import type { ShareClass } from '../stock'
import { Products, ProductId } from '../money'
import { LoanCollection } from '../loan/loan'

// ----------------------------------------------------------

export class SeedNoteEvent extends InvestmentEvent implements LoanSourceEvent {

  readonly domain = 'seedNotes'
  readonly investmentScheme = InvestmentScheme.Standard

  readonly fixedValuation = null
  readonly downRoundAllowed = false
  interestStartDate = null

  type: SeedNoteType
  target: number // raising
  discount: number
  interestRate: number
  interestType: InterestType
  maturityDate: string
  historical: boolean
  cap: number
  lowValuation: number
  shareClass: ShareClass

  loans = new LoanCollection()

  // ----------------------------------------------------

  constructor({
    type = SeedNoteType.SeedNOTE,
    target = 1,
    discount = 0,
    interestRate = 0,
    interestType = InterestType.Simple,
    maturityDate = isoFormat(addYears(today(), 3)),
    historical = false,
    cap = 0,
    lowValuation = 0,
    shareClass = null,
    interestStartDate = null,
    ...data
  }) {
    super({
      type,
      target,
      discount,
      interestRate,
      interestType,
      maturityDate,
      historical,
      cap,
      lowValuation,
      shareClass,
      interestStartDate,
      ...data
    })
  }

  // ----------------------------------------------------

  get product(): ProductId {
    return this.type === SeedNoteType.FutureFund ?
      Products.CLNFutureFund :
      Products.SeedNOTE
  }

  // ----------------------------------------------------

  get category(): EventCategory {
    return this.historical ?
      EventCategory.HistoricalCLN :
      this.type === SeedNoteType.FutureFund ?
        EventCategory.CLNFutureFund :
        EventCategory.SeedNOTE
  }

  // ----------------------------------------------------

  override get safeName() {
    if (this.name) {
      return this.name
    }

    return 'SeedNOTE'
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.target
  }

  // ----------------------------------------------------

  override get estimatedInvestment(): number {
    return this.loans.reduce((total, loan) => total + loan.amount, 0)
  }

  // ----------------------------------------------------

  get raisedPercent(): number {
    return this.estimatedInvestment / this.target
  }

  // ----------------------------------------------------

  get valuationFloor(): number {
    return this.lowValuation
  }

  // ----------------------------------------------------

  get valuationCap(): number | null {
    return this.cap > 0
      ? this.cap
      : null
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'historical', include: 'create' },
      'name',
      'type',
      'target',
      'discount',
      'interestRate',
      'interestType',
      'maturityDate',
      'cap',
      'lowValuation',
      'shareClass'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    const viewState = this.historical
      ? [ 'captable', 'debt-table' ]
      : [ 'raise', 'seed-notes', this.id ]

    return {
      state: [ '/companies', this.company.id, ...viewState ]
    }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    const viewState = this.getViewState()
    return {
      state: [ ...viewState.state, 'basics' ],
      params: viewState.params
    }
  }
}
