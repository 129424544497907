import { Event, IEventViewState } from './event'
import { EventCategory, SupportingDocumentType } from '../../models'
import { User } from '../user'
import { ApiFieldSpec } from '../model'

// ------------------------------------------------------------

export class ResearchAssuranceEvent extends Event {

  readonly category: EventCategory = EventCategory.ResearchAssurance
  readonly domain: string = 'researchAssurances'

  signingDirector: User

  supportingDocuments: SupportingDocumentType[] = []

  // ---------------------------------------------------

  constructor({ ...data }) {

    super({ ...data })
    this.name = $localize`Advance Assurance`

  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'signingDirector', include: 'update' }
    ]
  }

  // ---------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'research', 'assurances', this.id ]
    }
  }

  // ---------------------------------------------------

  hasDocument(type: SupportingDocumentType): boolean {
    return this.supportingDocuments.includes(type)
  }

}
