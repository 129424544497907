import type { Type } from '@angular/core'
import { Inject, Injectable } from '@angular/core'

import { clone, isEmpty, mergeDeepRight, omit, pick } from 'ramda'

import {
  Access,
  AccessRole,
  Address,
  AdvanceAssuranceDocumentEvent,
  AdvanceAssuranceIntent,
  Appointment,
  AppointmentRole,
  BoardMeetingEvent,
  Cohort,
  CohortFundingEvent,
  CohortMember,
  CohortTeam,
  Company,
  CompanyBankAccount,
  ComplianceEvent,
  ConfirmationStatementEvent,
  ConvertibleNoteEvent,
  DirectorshipEvent,
  DirectorshipTerminationEvent,
  DirectorshipVariationEvent,
  Document,
  DocumentSnapshot,
  EmiValuationEvent,
  EmploymentBonusPaymentEvent,
  EmploymentStartEvent,
  EmploymentTerminationEvent,
  EmploymentVariationEvent,
  ExitEvent,
  ExitPayout,
  FounderShareholderEvent,
  InstantConversionEvent,
  InstantInvestmentConsentEvent,
  Investment,
  Investor,
  isCompanyData,
  isCompanyExcerptData,
  isCompanyExcerptWithSubscriptionsData,
  isCompanyPublicExcerptData,
  Loan,
  LoanCertificate,
  Option,
  OptionExerciseEvent,
  OptionGrantEvent,
  OptionPoolEvent,
  OptionReturnEvent,
  OptionSchemeEvent,
  ProposalEvent,
  RegularReportEvent,
  RepaymentEvent,
  ResearchAssuranceEvent,
  ResearchClaimEvent,
  Round,
  SeedNoteEvent,
  SeedSaftEvent,
  Share,
  ShareAllotmentReturnEvent,
  ShareClass,
  ShareClassRegistration,
  ShareTransferEvent,
  Signatory,
  Signature,
  SignedAsRole,
  Statement,
  StatementCollection,
  StockSplitEvent,
  StopVestingEvent,
  Subscription,
  User,
  Witness
} from '@libs/models'

import type {
  AppointmentDataFormats,
  CompanyDataFormats,
  EmploymentChangeEvent,
  Event,
  IAccessData,
  IAccessWithUserData,
  IAddressData,
  IAdvanceAssuranceData,
  IAdvanceAssuranceExcerptData,
  IAppointmentPublicExcerptData,
  IAppointmentWithUserData,
  IBankAccountData,
  IBoardMeetingEventData,
  ICohortFundingData,
  ICompanyCohortTeamData,
  ICompanyData,
  ICompanyExcerptData,
  ICompanyExcerptWithSubscriptionsData,
  ICompanyPublicExcerptData,
  ICompanySignatoryData,
  IComplianceData,
  IConvertibleNoteData,
  IConvertibleNoteExcerptData,
  IDirectorshipEventData,
  IDirectorshipTerminationEventData,
  IDirectorshipVariationEventData,
  IDocumentData,
  IDocumentExcerptData,
  IEmiValuationData,
  IEmiValuationExcerptData,
  IEmploymentEventData,
  IEmploymentStartEventData,
  IEventData,
  IExitData,
  IInstantConversionData,
  IInstantInvestmentConsentData,
  IInvestmentWithInvestorShareAndShareClassData,
  IInvestorData,
  ILoanCertificateData,
  ILoanData,
  InvestmentEvent,
  IOptionExerciseData,
  IOptionExerciseExcerptData,
  IOptionGrantData,
  IOptionGrantExcerptData,
  IOptionPoolData,
  IOptionReturnData,
  IOptionSchemeData,
  IOptionSchemeExcerptData,
  IProposalData,
  IRepaymentData,
  IResearchAssuranceData,
  IResearchClaimData,
  IRoundData,
  IRoundExcerptData,
  ISeedNoteData,
  ISeedSaftData,
  IShareAllotmentReturnData,
  IShareClassData,
  IShareClassRegistrationData,
  IShareData,
  IShareTransferData,
  ISignatureData,
  IStockSplitData,
  IStopVestingData,
  ISubscriptionData,
  IUserCompany,
  IUserData,
  IUserExcerptData,
  IUserSignatoryData,
  LoanEvent,
  PlanId,
  SignatoryData,
  SubscriptionMap,
  UserDataFormats
} from '@libs/models'

import { Configuration } from '../configuration.service'
import { DebugConfig, IDebugOptions } from '@libs/shared'

import { getExistingEntitiesForData } from './globals-utils'
import { _log, _logc } from '@libs/utils'

// ------------------------------------------------------------

const signatureDataPicker = pick([
  'id',
  'name',
  'signature',
  'signed',
  'signedAs'
])

const witnessDataPicker = pick([
  'id',
  'name',
  'occupation',
  'signature',
  'signed'
])

const omitHasCaptable = omit([ 'hasCaptable' ])

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  addresses = new Map<string, Address>()
  companies = new Map<string, Company>()
  users = new Map<string, User>()
  accesses = new Map<string, Access>()
  appointments = new Map<string, Appointment>()
  documents = new Map<string, Document>()
  investments = new Map<string, Investment>()
  shares = new Map<string, Share>()
  loans = new Map<string, Loan>()
  loanCertificates = new Map<string, LoanCertificate>()

  // ------------------------------------------------------------

  buildAddress(addressData: IAddressData): Address {
    if (this.debug.logGlobalsService) {
      console.groupCollapsed(`%cbuildAddress(id: ${addressData.id || 'null'})`, 'color: red; font-size: 1.1em;')
      _log(`addressData`, addressData)
    }

    let address = this.addresses.get(addressData.id)

    if (!address) {
      address = new Address()
      address.setID(addressData.id)
      this.addresses.set(addressData.id, address)
    }

    address.line1 = addressData.line1
    address.line2 = addressData.line2
    address.city = addressData.city
    address.postcode = addressData.postcode
    address.country = addressData.country

    if (this.debug.logGlobalsService) {
      _log(`address`, address)
      console.groupEnd()
    }

    return address
  }

  // ----------------------------------------------------

  /**
   * Given a JSON object containing data representing a company -
   * e.g. as found in the JSON response to API requests to the
   * `/companies` end-point - parse the response and return an
   * instance of `Company`, creating all required objects linked
   * to the company - appointments, users, rounds, share classes,
   * shares, investors and documents.
   */

  buildCompany(companyData: CompanyDataFormats) {
    let company: Company = this.companies.get(companyData.id)

    if (!company) {
      company = new Company({ id: companyData.id })
      this.companies.set(company.id, company)
    }

    company.name = companyData.name
    company.picture = companyData.picture

    if (isCompanyData(companyData)) {
      company.isFullModel = true

      this.initialiseCompanyData(company, companyData as ICompanyData)
    } else if (isCompanyExcerptData(companyData)) {
      this.buildCompanyExcerpt(company, companyData as ICompanyExcerptData)
    } else if (isCompanyExcerptWithSubscriptionsData(companyData)) {
      this.initialiseCompanySubscriptions(company, (companyData as ICompanyExcerptWithSubscriptionsData).subscriptions)
    } else if (isCompanyPublicExcerptData(companyData)) {
      this.initialiseCompanyPublicExcerptData(company, companyData as ICompanyPublicExcerptData)
    }

    if ('address' in companyData) {
      company.address = companyData.address
        ? this.buildAddress(companyData.address)
        : null
    }

    if ('metadata' in companyData) {
      company.metadata = companyData.metadata
    }

    company.nameChanged()
    company.pictureChanged()

    return company
  }

  // ----------------------------------------------------

  private initialiseCompanyData(
    company: Company,
    data: ICompanyData
  ): void {
    this.buildBankAccounts(company, [ data.bankAccount ])

    this.buildCompanyAccesses(company, data.accesses)

    this.buildCompanyAppointments(company, data.appointments)

    this.buildInvestors(company, data.investors)

    this.buildShareClasses(company, data.shareClasses)

    this.buildRegularReports(company, data.regularReports)

    this.buildInstantInvestmentConsents(company, data.instantInvestmentConsents)

    this.buildShareAllotmentReturns(company, data.shareAllotmentReturns)

    this.buildRounds(company, data.rounds)

    this.buildConvertibleNotes(company, data.convertibleNotes)

    this.buildAdvanceAssurances(company, data.advanceAssurances)

    this.buildDirectorships(company, data.directorships)

    this.buildDirectorshipVariations(company, data.directorshipVariations)

    this.buildDirectorshipTerminations(company, data.directorshipTerminations)

    this.buildBoardMeetings(company, data.boardMeetings)

    this.buildCompanyCohortTeams(company, data.cohortTeams)

    this.buildCohortFundings(company, data.cohortFundings)

    this.buildCompliances(company, data.compliances)

    this.buildConfirmationStatements(company, data.confirmationStatements)

    this.buildStockSplits(company, data.stockSplits)

    this.buildOptionSchemes(company, data.optionSchemes)

    this.buildEmiValuations(company, data.emiValuations)

    this.buildExits(company, data.exits)

    this.buildOptionGrantsAndExercises(company, data.optionGrants, data.optionExercises)

    this.buildOptionPoolEvents(company, data.optionPools)

    this.buildSeedNotes(company, data.seedNotes)

    this.buildSeedSafts(company, data.seedSafts)

    this.buildStopVestings(company, data.stopVestings)

    this.buildProposals(company, data.proposals)

    this.buildProposals(company, data.investorProposals)

    this.buildRepayments(company, data.repayments)

    this.buildResearchAssurances(company, data.researchAssurances)

    this.buildResearchClaims(company, data.researchClaims)

    this.buildInstantConversions(company, data.instantConversions)

    this.buildShareClassRegistrations(company, data.shareClassRegistrations)

    this.buildShareTransfers(company, data.shareTransfers)

    this.attachLoansAndInvestments(company, data)

    company.companiesHouseNumber = data.companiesHouseNumber
    company.sirenCode = data.sirenCode
    company.hmrcReferenceNumber = data.hmrcReferenceNumber
    company.taxReferenceNumber = data.taxReferenceNumber
    company.payeReferenceNumber = data.payeReferenceNumber
    company.vatNumber = data.vatNumber
    company.currency = data.currency
    company.type = data.type
    company.jurisdiction = data.jurisdiction
    company.regionalName = data.regionalName
    company.greffe = data.greffe
    company.amtsgericht = data.amtsgericht
    company.incorporated = data.incorporated
    company.shareNominalValue = data.shareNominalValue
    company.shareCapital = data.shareCapital
    company.description = data.description
    company.twitter = data.twitter
    company.linkedin = data.linkedin
    company.facebook = data.facebook
    company.website = data.website
    company.signatureText = data.signatureText
    company.showHeaderLogo = data.showHeaderLogo
    company.enableStaffWriteAccess = data.enableStaffWriteAccess
    company.claimed = data.claimed
    company.hasCohortPortal = data.hasCohortPortal
    company.hasConsented = data.hasConsented

    // Removed broken sorter that wasn't needed, had attempted
    // to sort on companyProduct.transaction.inserted (I think).
    company.companyProducts = data.companyProducts

    this.initialiseCompanySubscriptions(company, data.subscriptions)
  }

  // ----------------------------------------------------

  private initialiseCompanyPublicExcerptData(
    company: Company,
    data: ICompanyPublicExcerptData
  ): void {
    company.companiesHouseNumber = data.companiesHouseNumber
    company.sirenCode = data.sirenCode
    company.type = data.type
    company.currency = data.currency
    company.jurisdiction = data.jurisdiction
    company.incorporated = data.incorporated
    company.description = data.description
    company.website = data.website
    company.claimed = data.claimed

    this.buildCompanyAppointments(company, data.appointments, true)
  }

  // ----------------------------------------------------

  private initialiseCompanySubscriptions(
    company: Company,
    subscriptionsData: SubscriptionMap
  ): void {
    for (const planId of Object.keys(subscriptionsData)) {
      const subscriptionData = subscriptionsData[ planId ]

      let subscription = company.getSubscription(planId as PlanId)

      if (!subscription) {
        subscription = this.buildSubscription(company, planId as PlanId, subscriptionData)
      } else {
        subscription.status = subscriptionData.status
        subscription.expires = subscriptionData.expires ? new Date(subscriptionData.expires) : null
        subscription.billingPeriod = subscriptionData.billingPeriod
      }
    }
  }

  // ----------------------------------------------------

  buildSubscription(
    company: Company,
    planId: PlanId,
    data: ISubscriptionData
  ): Subscription {
    const plan = this.configuration.getPlanById(planId)

    const subscription = new Subscription(
      company,
      plan,
      data.status,
      data.expires ? new Date(data.expires) : null,
      data.billingPeriod
    )
    company.subscriptions.set(plan.id, subscription)

    return subscription
  }

  // ----------------------------------------------------

  buildUser(userData: UserDataFormats): User {
    let user = this.users.get(userData.id)

    if (!user) {
      user = new User({ id: userData.id })
      this.users.set(user.id, user)
    }

    user.connected = userData.connected
    user.email = userData.email
    user.phone = userData.phone
    user.firstName = userData.firstName
    user.lastName = userData.lastName
    user.sex = userData.sex
    user.picture = userData.picture

    if (userData.address) {
      user.address = userData.address
        ? this.buildAddress(userData.address)
        : null
    }

    if ('signature' in userData) {
      user.isFullModel = true

      this.initialiseUserData(user, userData as IUserData)
    } else {
      user.invited = (userData as IUserExcerptData).invited
    }

    user.nameChanged()
    user.pictureChanged()

    return user
  }

  // ----------------------------------------------------

  initialiseUserData(user: User, data: IUserData) {
    user.description = data.description
    user.facebook = data.facebook
    user.linkedin = data.linkedin
    user.twitter = data.twitter
    user.signature = data.signature
    user.locale = data.locale
    user.emailToken = data.emailToken

    user.additionalInfo = clone(data.additionalInfo)
  }

  // ----------------------------------------------------

  buildUserCompany(
    userCompanyData: IUserCompany
  ): Company {
    let company = this.companies.get(userCompanyData.company.id)

    if (!company) {
      company = new Company(omitHasCaptable(userCompanyData.company))
      this.companies.set(company.id, company)

      company.nameChanged()
      company.pictureChanged()
    }

    company.hasCohortPortal = userCompanyData.company.hasCohortPortal

    if (userCompanyData.subscriptions) {
      const subscriptions = userCompanyData.subscriptions.reduce((out, sub) => {
        out[ sub.plan.id ] = {
          plan: sub.plan,
          expires: sub.expires,
          status: sub.status,
          billingPeriod: sub.billingPeriod
        }

        return out
      }, {})

      this.initialiseCompanySubscriptions(company, subscriptions)
    }

    return company
  }

  // ----------------------------------------------------

  buildCompanyExcerpt(
    company: Company,
    companyData: ICompanyExcerptData
  ) {
    this.buildCompanyAppointments(company, companyData.appointments, true)
    company.jurisdiction = companyData.jurisdiction
    company.currency = companyData.currency
  }

  // ----------------------------------------------------

  buildCompanyAccesses(
    company: Company,
    accessesData: IAccessWithUserData[]
  ): void {
    for (const accessData of accessesData) {
      const user = this.buildUser(accessData.user)

      this.buildAccess(company, user, accessData)
    }
  }

  // ----------------------------------------------------

  buildAccess(
    company: Company,
    user: User,
    { id: accessId, accessRoles }: IAccessData,
  ): Access {
    let access = this.accesses.get(accessId)

    if (!access) {
      access = new Access({
        id: accessId,
        company,
        user
      })

      this.accesses.set(accessId, access)

      access.attach()
    }

    // HACK: Having STAFF_WRITE should always mean having
    // STAFF_READ, this is there to ensure this is true even when
    // some other issue has left a user with only STAFF_WRITE.
    if (accessRoles.includes(AccessRole.StaffWrite) && !accessRoles.includes(AccessRole.StaffRead)) {
      accessRoles.push(AccessRole.StaffRead)
    }

    access.accessRoles = accessRoles

    return access
  }

  // ----------------------------------------------------

  buildCompanyAppointments(
    company: Company,
    appointmentsData: IAppointmentWithUserData[] | IAppointmentPublicExcerptData[],
    isExcerpt = false
  ): void {
    for (const appointmentData of appointmentsData) {
      const user = this.buildUser(appointmentData.user)

      this.buildAppointment(company, user, appointmentData, isExcerpt)
    }
  }

  // ----------------------------------------------------

  buildAppointment(
    company: Company,
    user: User,
    appointmentData: AppointmentDataFormats,
    isExcerpt = false
  ): Appointment {
    let appointment = this.appointments.get(appointmentData.id)

    if (!appointment) {
      appointment = new Appointment({
        id: appointmentData.id,
        company,
        user
      })

      this.appointments.set(appointment.id, appointment)

      appointment.attach()
    }

    appointment.position = appointmentData.position

    if (isExcerpt) {
      // This is an appointment on a company's public data which means that only director
      // and signatory roles are returned by the API, so we don't overwrite all existing roles...
      appointment.setRole(AppointmentRole.Admin, appointmentData.roles.includes(AppointmentRole.Admin))
      appointment.setRole(AppointmentRole.Director, appointmentData.roles.includes(AppointmentRole.Director))
      appointment.setRole(AppointmentRole.Signatory, appointmentData.roles.includes(AppointmentRole.Signatory))
    } else {
      appointment.roles = appointmentData.roles
    }

    if (this.debug.logGlobalsService) {
      _log(`appointment`, appointment)
    }

    return appointment
  }

  // ----------------------------------------------------

  private initialiseEventData(event: Event, eventData: IEventData) {
    event.effectiveDate = eventData.effectiveDate
    event.approved = eventData.approved
    event.inserted = new Date(eventData.inserted)

    // TODO: Check if we've kept a reference to the old
    // answers object anywhere - possibly question classes
    event.answers = mergeDeepRight(event.answers, eventData.answers)
  }

  // ----------------------------------------------------

  buildAdvanceAssurances(
    company: Company,
    assurancesData: IAdvanceAssuranceData[]
  ) {
    for (const assuranceData of assurancesData) {
      this.buildAdvanceAssurance(company, assuranceData)
    }
  }

  // ----------------------------------------------------

  buildAdvanceAssurance(
    company: Company,
    assuranceData: IAdvanceAssuranceData
  ): AdvanceAssuranceDocumentEvent {
    const assurance = this.buildAdvanceAssuranceExcerpt(company, assuranceData)

    for (const advanceAssuranceIntentData of assuranceData.advanceAssuranceIntents || []) {
      let advanceAssuranceIntent = assurance.advanceAssuranceIntents.get(advanceAssuranceIntentData.id)

      if (!advanceAssuranceIntent) {
        const investor = company.investors.get(advanceAssuranceIntentData.investor.id)

        advanceAssuranceIntent = new AdvanceAssuranceIntent({
          id: advanceAssuranceIntentData.id,
          company,
          event: assurance,
          investor,
          amount: advanceAssuranceIntentData.amount
        })
        advanceAssuranceIntent.attach()
      }

      advanceAssuranceIntent.amount = advanceAssuranceIntentData.amount
    }

    return assurance
  }

  // ----------------------------------------------------

  buildAdvanceAssuranceExcerpt(
    company: Company,
    assuranceData: IAdvanceAssuranceExcerptData
  ): AdvanceAssuranceDocumentEvent {
    let assurance = company.advanceAssurances.get(assuranceData.id)

    if (!assurance) {
      assurance = new AdvanceAssuranceDocumentEvent({ id: assuranceData.id, company })
      assurance.attach()
    }

    this.initialiseEventData(assurance, assuranceData)

    return assurance
  }

  // ----------------------------------------------------

  buildBankAccounts(company: Company, bankAccountsData: IBankAccountData[]) {
    if (isEmpty(bankAccountsData) || bankAccountsData[ 0 ] === null) {
      return
    }

    for (const bankAccountData of bankAccountsData) {
      let bankAccount = company.bankAccounts.get(bankAccountData.id)

      if (!bankAccount) {
        const id = bankAccountData.id
        bankAccount = new CompanyBankAccount({ id, company })
        bankAccount.attach()
      }

      bankAccount.name = bankAccountData.name
      bankAccount.sortCode = bankAccountData.sortCode
      bankAccount.accountNumber = bankAccountData.accountNumber
      bankAccount.swiftCode = bankAccountData.swiftCode
      bankAccount.iban = bankAccountData.iban
      bankAccount.bankCode = bankAccountData.bankCode
      bankAccount.holder = bankAccountData.holder
      bankAccount.country = bankAccountData.country
    }
  }

  // ----------------------------------------------------

  buildBoardMeetings(
    company: Company,
    boardMeetingsData: IBoardMeetingEventData[]
  ) {
    for (const boardMeetingData of boardMeetingsData) {
      this.buildBoardMeeting(company, boardMeetingData)
    }
  }

  // ----------------------------------------------------

  buildBoardMeeting(
    company: Company,
    boardMeetingData: IBoardMeetingEventData
  ): BoardMeetingEvent {
    let boardMeeting = company.boardMeetings.get(boardMeetingData.id)

    if (!boardMeeting) {
      boardMeeting = new BoardMeetingEvent({ id: boardMeetingData.id, company })
      boardMeeting.attach()
    }

    this.initialiseEventData(boardMeeting, boardMeetingData)

    if (Array.isArray(boardMeetingData.directorships)) {
      const directorships = getExistingEntitiesForData(company.directorships, boardMeetingData.directorships)

      boardMeeting.directorships
        .clear()
        .addAll(directorships)

      directorships.forEach(e => {
        e.boardMeeting = boardMeeting
      })
    }

    if (Array.isArray(boardMeetingData.directorshipTerminations)) {
      const directorshipTerminations = getExistingEntitiesForData(company.directorshipTerminations, boardMeetingData.directorshipTerminations)

      boardMeeting.directorshipTerminations
        .clear()
        .addAll(directorshipTerminations)

      directorshipTerminations.forEach(e => {
        e.boardMeeting = boardMeeting
      })
    }

    return boardMeeting
  }

  // ----------------------------------------------------

  buildCompanyCohortTeams(
    company: Company,
    cohortTeams: ICompanyCohortTeamData[]
  ) {
    for (const team of cohortTeams) {
      this.buildCompanyCohortTeam(company, team)
    }
  }

  // ----------------------------------------------------

  buildCompanyCohortTeam(
    company: Company,
    teamData: ICompanyCohortTeamData
  ): CohortTeam {

    let cohortTeam = company.cohortTeams.get(teamData.id)

    if (!cohortTeam) {
      const { cohort: { company: cohortCompanyData, ...cohortData }, cohortMembers, ...cohortTeamData } = teamData
      const cohort = new Cohort({ company: this.buildCompany(cohortCompanyData), ...cohortData })

      const additionalInfo = cohortTeamData.additionalInfo

      cohortTeam = new CohortTeam({ company, cohort, ...cohortTeamData, additionalInfo })

      cohortTeam.cohortMembers.addAll(cohortMembers.map(
        member => new CohortMember({ ...member, user: this.buildUser(member.user), cohort })
      ))

      cohortTeam.attach()
    }

    return cohortTeam
  }

  // ----------------------------------------------------

  buildCompliances(
    company: Company,
    compliancesData: IComplianceData[]
  ) {
    for (const complianceData of compliancesData) {
      this.buildCompliance(company, complianceData)
    }
  }

  // ----------------------------------------------------

  buildCompliance(
    company: Company,
    complianceData: IComplianceData
  ): ComplianceEvent {
    let compliance = company.compliances.get(complianceData.id)

    if (!compliance) {
      compliance = new ComplianceEvent({ id: complianceData.id, company })
      compliance.attach()
    }

    if (complianceData.signingDirector) {
      compliance.signingDirector = this.buildUser(complianceData.signingDirector)
    }
    compliance.startDate = complianceData.startDate
    compliance.endDate = complianceData.endDate
    if (complianceData.supportingDocuments) {
      compliance.supportingDocuments = complianceData.supportingDocuments
    }
    this.initialiseEventData(compliance, complianceData)

    const oldStatements = compliance.statements
    compliance.statements = new StatementCollection()
    for (const statementData of complianceData.statements || []) {
      let statement = oldStatements.get(statementData.id)

      if (!statement) {

        const shareClass = company.shareClasses.get(statementData.shareClass.id)

        statement = new Statement({
          id: statementData.id,
          company,
          event: compliance,
          shareClass
        })

        statement.scheme = statementData.scheme
        statement.uir = statementData.uir
        statement.effectiveDate = statementData.effectiveDate
      }

      statement.attach()
    }

    return compliance
  }

  // ----------------------------------------------------

  buildConfirmationStatements(
    company: Company,
    confirmationStatementsData: IEventData[]
  ) {
    for (const confirmationStatementData of confirmationStatementsData) {
      this.buildConfirmationStatement(company, confirmationStatementData)
    }
  }

  // ----------------------------------------------------

  buildConfirmationStatement(
    company: Company,
    confirmationStatementData: IEventData
  ): ConfirmationStatementEvent {
    let confirmationStatement = company.confirmationStatements.get(confirmationStatementData.id)

    if (!confirmationStatement) {
      confirmationStatement = new ConfirmationStatementEvent({ id: confirmationStatementData.id, company })
      confirmationStatement.attach()
    }

    this.initialiseEventData(confirmationStatement, confirmationStatementData)

    return confirmationStatement
  }

  // ----------------------------------------------------

  buildConvertibleNotes(
    company: Company,
    notesData: IConvertibleNoteData[]
  ) {
    for (const noteData of notesData) {
      this.buildConvertibleNote(company, noteData)
    }
  }

  // ----------------------------------------------------

  buildConvertibleNote(
    company: Company,
    noteData: IConvertibleNoteData
  ): ConvertibleNoteEvent {
    if (!noteData.loan) {
      return
    }

    const note = this.buildConvertibleNoteExcerpt(company, noteData)

    this.buildLoan(company, note, noteData.loan)

    note.shareClass = company.shareClasses.get(noteData.shareClass.id)

    return note
  }

  // ----------------------------------------------------

  buildConvertibleNoteExcerpt(
    company: Company,
    noteData: IConvertibleNoteExcerptData
  ): ConvertibleNoteEvent {
    let note = company.convertibleNotes.get(noteData.id)

    if (!note) {
      note = new ConvertibleNoteEvent({ id: noteData.id, company })
      note.attach()
    }

    this.initialiseEventData(note, noteData)

    // TODO: Check
    note.investmentScheme = noteData.investmentScheme
    note.discount = noteData.discount
    note.historical = noteData.historical
    note.cap = noteData.cap
    note.lowValuation = noteData.lowValuation
    note.floorValuation = noteData.floorValuation
    note.longstopDate = noteData.longstopDate
    note.maturityDate = noteData.maturityDate
    note.cohortTeam = noteData.cohortTeam

    return note
  }

  // ----------------------------------------------------

  buildDirectorships(
    company: Company,
    directorshipsData: IDirectorshipEventData[]
  ) {
    for (const data of directorshipsData) {
      this.buildDirectorship(company, data)
    }
  }

  buildDirectorship(
    company: Company,
    directorshipData: IDirectorshipEventData
  ) {
    let directorshipEvent = company.directorships.get(directorshipData.id)

    if (!directorshipEvent) {
      const director = this.buildUser(directorshipData.director)

      directorshipEvent = new DirectorshipEvent({ id: directorshipData.id, company, director })
      directorshipEvent.attach()
    }

    this.initialiseEventData(directorshipEvent, directorshipData)

    return directorshipEvent
  }

  buildDirectorshipVariations(
    company: Company,
    variationsData: IDirectorshipVariationEventData[]
  ) {
    for (const data of variationsData) {
      this.buildDirectorshipVariation(company, data)
    }
  }

  buildDirectorshipVariation(
    company: Company,
    variationData: IDirectorshipVariationEventData
  ) {
    let directorshipVariation = company.directorshipVariations.get(variationData.id)

    if (!directorshipVariation) {
      const directorship = this.buildDirectorship(company, variationData.directorship)

      directorshipVariation = new DirectorshipVariationEvent({ id: variationData.id, company, directorship })
      directorshipVariation.attach()
    }

    this.initialiseEventData(directorshipVariation, variationData)

    return directorshipVariation
  }

  buildDirectorshipTerminations(
    company: Company,
    terminationsData: IDirectorshipTerminationEventData[]
  ) {
    for (const data of terminationsData) {
      this.buildDirectorshipTermination(company, data)
    }
  }

  buildDirectorshipTermination(
    company: Company,
    terminationsData: IDirectorshipTerminationEventData
  ): DirectorshipTerminationEvent {
    let directorshipTermination = company.directorshipTerminations.get(terminationsData.id)

    if (!directorshipTermination) {
      const directorship = this.buildDirectorship(company, terminationsData.directorship)

      directorshipTermination = new DirectorshipTerminationEvent({ id: terminationsData.id, company, directorship })
      directorshipTermination.attach()
    }

    this.initialiseEventData(directorshipTermination, terminationsData)

    return directorshipTermination
  }

  // ----------------------------------------------------

  buildEmiValuations(
    company: Company,
    valuationsData: IEmiValuationData[]
  ) {
    for (const valuationData of valuationsData) {
      this.buildEmiValuation(company, valuationData)
    }
  }

  // ----------------------------------------------------

  buildEmiValuation(
    company: Company,
    valuationData: IEmiValuationData
  ): EmiValuationEvent {
    let valuation = company.emiValuations.get(valuationData.id)

    if (!valuation) {
      const shareClass = company.shareClasses.get(valuationData.shareClass.id)

      valuation = new EmiValuationEvent({
        id: valuationData.id,
        company,
        hmrcApprovalDate: valuationData.hmrcApprovalDate,
        shareClass,
        historical: valuationData.historical
      })
      valuation.attach()
    }

    this.initialiseEventData(valuation, valuationData)

    valuation.strikePrice = valuationData.strikePrice
    valuation.supportingDocuments = valuationData.supportingDocuments
    valuation.amv = valuationData.amv
    valuation.iumv = valuationData.iumv
    valuation.officialAmv = valuationData.officialAmv
    valuation.officialIumv = valuationData.officialIumv

    if (valuationData.signingDirector) {
      valuation.signingDirector = this.buildUser(valuationData.signingDirector)
    }

    return valuation
  }

  // ----------------------------------------------------

  buildEmiValuationExcerpt(
    company: Company,
    valuationData: IEmiValuationExcerptData
  ): EmiValuationEvent {
    let valuation = company.emiValuations.get(valuationData.id)

    if (!valuation) {
      valuation = new EmiValuationEvent({
        id: valuationData.id,
        company,
        hmrcApprovalDate: valuationData.hmrcApprovalDate,
        amv: valuationData.amv,
        iumv: valuationData.iumv,
        historical: valuationData.historical
      })
      valuation.attach()
    }

    this.initialiseEventData(valuation, valuationData)

    valuation.strikePrice = valuationData.strikePrice

    return valuation
  }

  // ----------------------------------------------------

  buildEmploymentStartEvent(
    company: Company,
    data: IEmploymentStartEventData
  ): EmploymentStartEvent {
    let employmentStartEvent = company.events.get(data.id) as EmploymentStartEvent

    if (!employmentStartEvent) {
      const employee = this.buildUser(data.employee)

      employmentStartEvent = new EmploymentStartEvent({
        ...data,
        company,
        employee
      })

      employmentStartEvent.attach()
    }

    this.initialiseEventData(employmentStartEvent, data)

    return employmentStartEvent
  }

  // ----------------------------------------------------

  buildEmploymentEvent<T extends EmploymentChangeEvent, D extends IEmploymentEventData>(
    company: Company,
    type: Type<T>,
    data: D
  ): T {
    let employmentEvent = company.events.get(data.id) as T

    if (!employmentEvent) {
      // const employment = company.employments.get(data.employment.id)
      const employment = this.buildEmploymentStartEvent(company, data.employment)

      employmentEvent = new type({
        ...data,
        company,
        employment
      })

      employmentEvent.attach()
    }

    this.initialiseEventData(employmentEvent, data)

    return employmentEvent
  }

  // ----------------------------------------------------

  buildExits(
    company: Company,
    exitsData: IExitData[]
  ) {
    for (const exitData of exitsData) {
      this.buildExit(company, exitData)
    }
  }

  // ----------------------------------------------------

  buildExit(
    company: Company,
    exitData: IExitData,
  ): ExitEvent {
    let exit = company.exits.get(exitData.id)

    if (!exit) {
      const investor = company.investors.get(exitData.investor.id)

      exit = new ExitEvent({
        id: exitData.id,
        company,
        investor,
        valuation: exitData.valuation,
      })
      exit.attach()
    }

    this.initialiseEventData(exit, exitData)

    exit.valuation = exitData.valuation

    exit.exitPayouts.clear()

    for (const payoutData of exitData.exitPayouts) {
      const shareClass = company.shareClasses.get(payoutData.shareClass.id)
      const sellingInvestor = company.investors.get(payoutData.sellingInvestor.id)

      const payout = new ExitPayout({
        id: payoutData.id,
        event: exit,
        shareClass,
        scheme: payoutData.scheme,
        count: payoutData.count,
        amount: payoutData.amount,
        sellingInvestor,
        share: null
      })

      exit.exitPayouts.add(payout)
    }

    return exit
  }

  // ----------------------------------------------------

  buildFoundersShareholdersAgreement(
    company: Company,
    foundersShareholdersAgreementData: IEventData
  ): FounderShareholderEvent {
    let foundersShareholdersAgreement = company.foundersShareholdersAgreements.get(foundersShareholdersAgreementData.id)

    if (!foundersShareholdersAgreement) {
      foundersShareholdersAgreement = new FounderShareholderEvent({
        id: foundersShareholdersAgreementData.id,
        company
      })
      foundersShareholdersAgreement.attach()
    }

    this.initialiseEventData(foundersShareholdersAgreement, foundersShareholdersAgreementData)

    return foundersShareholdersAgreement
  }

  // ----------------------------------------------------

  buildInstantConversions(
    company: Company,
    instantConversions: IInstantConversionData[]
  ) {
    for (const instantConversion of instantConversions) {
      this.buildInstantConversion(company, instantConversion)
    }
  }

  // ----------------------------------------------------

  buildInstantConversion(
    company: Company,
    instantConversionData: IInstantConversionData
  ) {
    let instantConversion = company.instantConversions.get(instantConversionData.id)

    if (!instantConversion) {
      const shareClass = instantConversionData.shareClass
        ? company.shareClasses.get(instantConversionData.shareClass.id)
        : null

      instantConversion = new InstantConversionEvent({
        id: instantConversionData.id,
        company,
        shareClass
      })

      instantConversion.attach()
    }

    instantConversion.loans.clear()

    for (const loanData of instantConversionData.loans) {
      if (!this.loans.get(loanData.id)) {
        this.buildLoan(company, instantConversion, loanData)
      }
      const loan = this.loans.get(loanData.id)

      loan.instantConversion = instantConversion
      instantConversion.loans.add(loan)

      if (loanData.share) {
        let share = loan.share

        if (!share) {
          const shareClass = company.shareClasses.get(loanData.share.shareClass.id)

          share = this.buildShare(
            loanData.share.id,
            instantConversion,
            shareClass,
            loan.investor
          )

          loan.share = share
        }

        this.initialiseShareData(share, loanData.share)
      }
    }

    this.initialiseEventData(instantConversion, instantConversionData)

    return instantConversion
  }

  // ----------------------------------------------------

  buildOptionGrantsAndExercises(
    company: Company,
    grantsData: IOptionGrantData[],
    exercisesData: IOptionExerciseData[]
  ) {
    for (const exerciseData of exercisesData) {
      this.buildOptionExercise(company, exerciseData)
    }

    for (const grantData of grantsData) {
      this.buildOptionGrant(company, grantData)
    }

    for (const exerciseData of exercisesData) {
      if (exerciseData.remainderOption) {
        const exercise = company.optionExercises.get(exerciseData.id)
        const remainderOption = company.options.get(exerciseData.remainderOption.id)

        if (remainderOption != null) {
          exercise.remainderOption = remainderOption
          remainderOption.originatingEvent = exercise
        }
      }
    }
  }

  // ----------------------------------------------------

  buildOptionExercise(
    company: Company,
    exerciseData: IOptionExerciseData
  ): OptionExerciseEvent {
    let exercise = company.optionExercises.get(exerciseData.id)

    const investor = exercise?.investor ?? company.investors.get(exerciseData.investor.id)
    const shareClass = exercise?.shareClass ?? company.shareClasses.get(exerciseData.shareClass.id)

    if (!exercise) {
      exercise = new OptionExerciseEvent({
        id: exerciseData.id,
        company,
        investor,
        shareClass
      })
      exercise.attach()
    }

    this.initialiseEventData(exercise, exerciseData)

    exercise.count = exerciseData.count
    exercise.strikePrice = exerciseData.strikePrice

    if (exerciseData.share) {
      if (!exercise.share) {
        exercise.share = this.buildShare(exerciseData.share.id, exercise, shareClass, investor)
      }

      this.initialiseShareData(exercise.share, exerciseData.share)
    }

    return exercise
  }

  // ----------------------------------------------------

  buildOptionExerciseExcerpt(
    company: Company,
    exerciseData: IOptionExerciseExcerptData
  ): OptionExerciseEvent {
    let exercise = company.optionExercises.get(exerciseData.id)

    if (!exercise) {
      exercise = new OptionExerciseEvent({ id: exerciseData.id, company })
      exercise.attach()
    }

    this.initialiseEventData(exercise, exerciseData)

    exercise.count = exerciseData.count

    return exercise
  }

  // ----------------------------------------------------

  buildOptionGrant(
    company: Company,
    grantData: IOptionGrantData
  ): OptionGrantEvent {
    let grant = company.optionGrants.get(grantData.id)

    const investor = company.investors.get(grantData.investor.id)
    const optionScheme = company.optionSchemes.get(grantData.optionScheme.id)

    if (!grant) {
      grant = new OptionGrantEvent({
        id: grantData.id,
        company,
        investor,
        optionScheme
      })
      grant.attach()
    }

    this.initialiseEventData(grant, grantData)

    grant.count = grantData.count
    grant.strikePrice = grantData.strikePrice
    grant.vesting = grantData.vesting

    for (const optionData of grantData.options) {
      let option = grant.options.get(optionData.id)

      if (!option) {
        let optionExercise = null

        if (optionData.optionExercise) {
          optionExercise = company.optionExercises.get(optionData.optionExercise.id)

          if (!optionExercise) {
            optionExercise = this.buildOptionExercise(company, optionData.optionExercise)
          }
        }

        option = new Option({
          id: optionData.id,
          company,
          investor,
          originatingEvent: grant,
          optionGrant: grant,
          optionExercise
        })

        option.attach()
        grant.options.add(option)

        if (optionExercise) {
          optionExercise.option = option
        }
      }

      option.count = optionData.count
      option.certno = optionData.certno
      option.active = optionData.active
    }

    return grant
  }

  // ----------------------------------------------------

  buildOptionGrantExcerpt(
    company: Company,
    grantData: IOptionGrantExcerptData
  ): OptionGrantEvent {
    let grant = company.optionGrants.get(grantData.id)

    if (!grant) {
      grant = new OptionGrantEvent({ id: grantData.id, company })
      grant.attach()
    }

    this.initialiseEventData(grant, grantData)

    if (grantData.optionScheme) { // French option grants require option scheme
      grant.optionScheme = this.buildOptionSchemeExcerpt(company, grantData.optionScheme)
    }

    grant.count = grantData.count
    grant.strikePrice = grantData.strikePrice
    grant.vesting = grantData.vesting

    return grant
  }

  // ----------------------------------------------------

  buildOptionSchemes(
    company: Company,
    schemesData: IOptionSchemeData[]
  ) {
    for (const schemeData of schemesData) {
      this.buildOptionScheme(company, schemeData)
    }
  }


  // ----------------------------------------------------

  buildOptionScheme(
    company: Company,
    schemeData: IOptionSchemeData
  ): OptionSchemeEvent {
    let scheme = company.optionSchemes.get(schemeData.id)

    if (!scheme) {
      const shareClass = company.shareClasses.get(schemeData.shareClass.id)

      scheme = new OptionSchemeEvent({
        id: schemeData.id,
        company,
        shareClass
      })
      scheme.attach()
    }

    this.initialiseEventData(scheme, schemeData)

    scheme.emi = schemeData.emi
    scheme.description = schemeData.description
    scheme.hmrcApproval = schemeData.hmrcApproval

    return scheme
  }

  // ----------------------------------------------------

  buildOptionPoolEvents(
    company: Company,
    optionPoolsData: IOptionPoolData[]
  ) {
    for (const optionPoolData of optionPoolsData) {
      this.buildOptionPoolEvent(company, optionPoolData)
    }
  }

  // ----------------------------------------------------

  buildOptionPoolEvent(
    company: Company,
    optionPoolData: IOptionPoolData
  ): OptionPoolEvent {
    let poolEvent = company.optionPools.get(optionPoolData.id)

    if (!poolEvent) {
      poolEvent = new OptionPoolEvent({
        id: optionPoolData.id,
        count: optionPoolData.count,
        company
      })
      poolEvent.attach()
    }

    this.initialiseEventData(poolEvent, optionPoolData)
    poolEvent.count = optionPoolData.count
    poolEvent.historical = optionPoolData.historical

    return poolEvent
  }


  // ----------------------------------------------------

  buildOptionSchemeExcerpt(
    company: Company,
    schemeData: IOptionSchemeExcerptData
  ): OptionSchemeEvent {
    let scheme = company.optionSchemes.get(schemeData.id)

    if (!scheme) {
      scheme = new OptionSchemeEvent({ id: schemeData.id, company })
      scheme.attach()
    }

    this.initialiseEventData(scheme, schemeData)

    scheme.emi = schemeData.emi
    scheme.description = schemeData.description

    return scheme
  }

  // ----------------------------------------------------

  buildOptionReturnEvent(
    company: Company,
    optionReturnData: IOptionReturnData,
    options?: { id: string, optionGrant: string, count: number, certno: string, active: boolean }[]
  ): OptionReturnEvent {
    let returnEvent = company.optionReturns.get(optionReturnData.id)

    if (!returnEvent) {
      returnEvent = new OptionReturnEvent({
        id: optionReturnData.id,
        count: optionReturnData.count,
        type: optionReturnData.type,
        company
      })
      returnEvent.attach()
    }

    this.initialiseEventData(returnEvent, optionReturnData)
    returnEvent.count = optionReturnData.count

    if (options) {
      for (const optionData of options) {
        const grant = company.optionGrants.get(optionData.optionGrant)
        const option = new Option({
          id: optionData.id,
          company,
          investor: grant.investor,
          originatingEvent: returnEvent,
          optionGrant: grant,
          optionReturn: returnEvent
        })

        option.count = optionData.count
        option.certno = optionData.certno
        option.active = optionData.active

        option.attach()
        grant.options.add(option)

        returnEvent.option = option
      }
    }

    return returnEvent
  }

  // ----------------------------------------------------

  buildProposals(
    company: Company,
    proposalDatas: IProposalData[]
  ) {
    for (const proposalData of proposalDatas) {
      this.buildProposal(company, proposalData)
    }
  }

  // ----------------------------------------------------

  buildProposal(
    company: Company,
    proposalData: IProposalData
  ): ProposalEvent {
    let proposal
    if (company) proposal = company.proposals.get(proposalData.id)

    const founderCompany = this.buildCompany(proposalData.founderCompany)

    let round = founderCompany.rounds.get(proposalData.round?.id)
    if (proposalData.round) {
      round = this.buildRoundExcerpt(founderCompany, proposalData.round)
    }

    if (!proposal) {
      proposal = new ProposalEvent({ id: proposalData.id })
      if (proposalData.company) proposal.company = this.buildCompany(proposalData.company)
      else proposal.user = this.buildUser(proposalData.user)
      proposal.founderCompany = founderCompany
      proposal.round = round
      proposal.attach()

      for (const document of proposalData.documents) {
        proposal.documents.add(this.buildDocument(proposal.company, document))
      }
    }

    proposal.name = proposalData.name
    proposal.investmentAmount = proposalData.investmentAmount
    proposal.investmentScheme = proposalData.investmentScheme
    proposal.valuation = proposalData.valuation
    proposal.optionType = proposalData.optionType
    proposal.optionOwnership = proposalData.optionOwnership
    proposal.target = proposalData.target
    proposal.roundCompletionDate = proposalData.roundCompletionDate
    proposal.updated = new Date(proposalData.modified)

    this.initialiseEventData(proposal, proposalData)

    return proposal
  }

  // ----------------------------------------------------

  buildRegularReports(
    company: Company,
    regularReportsData: IEventData[]
  ) {
    for (const regularReportData of regularReportsData) {
      this.buildRegularReport(company, regularReportData)
    }
  }

  // ----------------------------------------------------

  buildRegularReport(
    company: Company,
    regularReportData: IEventData
  ): RegularReportEvent {
    let regularReport = company.regularReports.get(regularReportData.id)

    if (!regularReport) {
      regularReport = new RegularReportEvent({ id: regularReportData.id, company })
      regularReport.attach()
    }

    this.initialiseEventData(regularReport, regularReportData)

    return regularReport
  }

  // ----------------------------------------------------

  buildRepayments(
    company: Company,
    repaymentData: IRepaymentData[]
  ) {
    for (const repayment of repaymentData) {
      this.buildRepayment(company, repayment)
    }
  }

  // ------------------------------------------------------------

  buildRepayment(
    company: Company,
    repaymentData: IRepaymentData
  ): RepaymentEvent {
    let repayment = company.repayments.get(repaymentData.id)

    if (!repayment) {
      repayment = new RepaymentEvent({
        id: repaymentData.id,
        company,
      })
      repayment.attach()
    }

    repayment.loans
      .clear()
      .addAll(getExistingEntitiesForData(this.loans, repaymentData.loans))

    repayment.loans.forEach(loan => loan.repayment = repayment)

    this.initialiseEventData(repayment, repaymentData)

    return repayment
  }

  // ----------------------------------------------------

  buildResearchAssurances(
    company: Company,
    researchAssuranceDatas: IResearchAssuranceData[]
  ) {
    for (const researchAssuranceData of researchAssuranceDatas) {
      this.buildResearchAssurance(company, researchAssuranceData)
    }
  }

  // ----------------------------------------------------

  buildResearchAssurance(
    company: Company,
    researchAssuranceData: IResearchAssuranceData
  ) {
    let researchAssurance = company.researchAssurances.get(researchAssuranceData.id)

    if (!researchAssurance) {
      researchAssurance = new ResearchAssuranceEvent({
        id: researchAssuranceData.id,
        company,
      })
      researchAssurance.attach()
    }
    if (researchAssuranceData.signingDirector) {
      researchAssurance.signingDirector = this.buildUser(researchAssuranceData.signingDirector)
    }
    researchAssurance.supportingDocuments = researchAssuranceData.supportingDocumentTypes
    this.initialiseEventData(researchAssurance, researchAssuranceData)

    return researchAssurance
  }

  // ----------------------------------------------------

  buildResearchClaims(
    company: Company,
    researchClaimDatas: IResearchClaimData[]
  ) {
    for (const researchClaimData of researchClaimDatas) {
      this.buildResearchClaim(company, researchClaimData)
    }
  }

  // ----------------------------------------------------

  buildResearchClaim(
    company: Company,
    researchClaimData: IResearchClaimData
  ) {
    let researchClaim = company.researchClaims.get(researchClaimData.id)

    if (!researchClaim) {
      researchClaim = new ResearchClaimEvent({
        id: researchClaimData.id,
        startDate: researchClaimData.startDate,
        taxableBalanceYear1: researchClaimData.taxableBalanceYear1,
        taxableBalanceYear2: researchClaimData.taxableBalanceYear2,
        company,
      })
      researchClaim.attach()
    }

    researchClaim.supportingDocuments = researchClaimData.supportingDocuments
    researchClaim.startDate = researchClaimData.startDate
    researchClaim.taxableBalanceYear1 = researchClaimData.taxableBalanceYear1
    researchClaim.taxableBalanceYear2 = researchClaimData.taxableBalanceYear2

    this.initialiseEventData(researchClaim, researchClaimData)

    return researchClaim
  }

  // ----------------------------------------------------

  buildInstantInvestmentConsents(
    company: Company,
    data: IInstantInvestmentConsentData[]
  ): void {
    for (const consent of data) {
      this.buildInstantInvestmentConsent(company, consent)
    }
  }

  // ----------------------------------------------------

  buildInstantInvestmentConsent(
    company: Company,
    consent: IInstantInvestmentConsentData
  ): InstantInvestmentConsentEvent {
    let instantInvestmentConsent = company.instantInvestmentConsents.get(consent.id)

    if (!instantInvestmentConsent) {
      instantInvestmentConsent = new InstantInvestmentConsentEvent({ id: consent.id, company })
      instantInvestmentConsent.attach()
    }

    this.initialiseEventData(instantInvestmentConsent, consent)

    instantInvestmentConsent.target = consent.target
    instantInvestmentConsent.pricePerShare = consent.pricePerShare

    return instantInvestmentConsent
  }

  // ----------------------------------------------------

  buildShareAllotmentReturns(
    company: Company,
    data: IShareAllotmentReturnData[]
  ): void {
    for (const shareAllotment of data) {
      this.buildShareAllotmentReturn(company, shareAllotment)
    }
  }

  // ----------------------------------------------------

  buildShareAllotmentReturn(
    company: Company,
    data: IShareAllotmentReturnData
  ): ShareAllotmentReturnEvent {
    let shareAllotmentReturn = company.shareAllotmentReturns.get(data.id)

    if (!shareAllotmentReturn) {
      shareAllotmentReturn = new ShareAllotmentReturnEvent({ id: data.id, company })
      shareAllotmentReturn.attach()
    }

    this.initialiseEventData(shareAllotmentReturn, data)

    shareAllotmentReturn.startDate = data.startDate
    shareAllotmentReturn.endDate = data.endDate

    return shareAllotmentReturn
  }

  // ----------------------------------------------------

  buildRounds(
    company: Company,
    roundsData: IRoundData[]
  ) {
    for (const roundData of roundsData) {
      this.buildRound(company, roundData)
    }
  }

  // ----------------------------------------------------

  buildRound(
    company: Company,
    roundData: IRoundData
  ): Round {
    let round = company.rounds.get(roundData.id)

    if (!round) {
      round = new Round({ id: roundData.id, company })
    }

    if (roundData.instantInvestmentConsent) {
      round.instantInvestmentConsent = this.buildInstantInvestmentConsent(company, roundData.instantInvestmentConsent)
    }

    round.attach()

    round.leaders
      .clear()
      .addAll(getExistingEntitiesForData(company.investors, roundData.leaders))

    round.shareClasses
      .clear()
      .addAll(getExistingEntitiesForData(company.shareClasses, roundData.shareClasses))

    this.buildInvestments(round, roundData.investments)

    this.initialiseEventData(round, roundData)

    round.name = roundData.name
    round.type = roundData.type
    round.pricePerShare = roundData.pricePerShare
    round.target = roundData.target
    round.valuation = roundData.valuation
    round.optionType = roundData.optionType
    round.optionCount = roundData.optionCount
    round.optionOwnership = roundData.optionOwnership
    round.allocatedOptionCount = roundData.allocatedOptionCount
    round.seisAvailable = roundData.seisAvailable
    round.shareCountRounding = roundData.shareCountRounding
    round.conversationEnabled = roundData.conversationEnabled
    return round
  }

  // ----------------------------------------------------

  buildRoundExcerpt(
    company: Company,
    roundData: IRoundExcerptData
  ): Round {
    let round = company.rounds.get(roundData.id)

    if (!round) {
      round = new Round({ id: roundData.id, company })
      round.attach()
    }

    this.initialiseEventData(round, roundData)

    round.name = roundData.name
    round.type = roundData.type
    round.pricePerShare = roundData.pricePerShare
    round.target = roundData.target
    round.valuation = roundData.valuation
    round.optionType = roundData.optionType
    round.optionCount = roundData.optionCount
    round.optionOwnership = roundData.optionOwnership
    round.allocatedOptionCount = roundData.allocatedOptionCount
    round.seisAvailable = roundData.seisAvailable
    round.conversationEnabled = roundData.conversationEnabled
    return round
  }

  // ----------------------------------------------------

  buildCohortFundings(
    company: Company,
    cohortFundingsData: ICohortFundingData[]
  ) {
    for (const cohortFundingData of cohortFundingsData) {
      this.buildCohortFunding(company, cohortFundingData)
    }
  }

  // ----------------------------------------------------

  buildCohortFunding(
    company: Company,
    cohortFundingData: ICohortFundingData
  ): CohortFundingEvent {
    let cohortFunding = company.cohortFundings.get(cohortFundingData.id)

    if (!cohortFunding) {
      const cohortTeam = company.cohortTeams.get(cohortFundingData.cohortTeam.id)
      const shareClass = cohortFundingData.shareClass
        ? company.shareClasses.get(cohortFundingData.shareClass.id)
        : null

      cohortFunding = new CohortFundingEvent({
        id: cohortFundingData.id,
        company,
        cohortTeam,
        shareClass
      })

      cohortFunding.attach()
    }

    this.initialiseEventData(cohortFunding, cohortFundingData)

    for (const loanData of cohortFundingData.loans || []) {
      this.buildLoan(company, cohortFunding, loanData)
    }

    this.buildInvestments(cohortFunding, cohortFundingData.investments || [])

    cohortFunding.optionCount = cohortFundingData.optionCount
    cohortFunding.optionPoolPercentage = cohortFundingData.optionPoolPercentage

    return cohortFunding
  }

  // ----------------------------------------------------

  buildSeedNotes(
    company: Company,
    seedNotesData: ISeedNoteData[]
  ) {
    for (const seedNoteData of seedNotesData) {
      this.buildSeedNote(company, seedNoteData)
    }
  }

  // ----------------------------------------------------

  buildSeedNote(
    company: Company,
    seedNoteData: ISeedNoteData
  ): SeedNoteEvent {
    let seedNote = company.seedNotes.get(seedNoteData.id)

    if (!seedNote) {
      const shareClass = seedNoteData.shareClass
        ? company.shareClasses.get(seedNoteData.shareClass.id)
        : null

      seedNote = new SeedNoteEvent({
        id: seedNoteData.id,
        company,
        shareClass
      })
      seedNote.attach()
    }

    this.initialiseEventData(seedNote, seedNoteData)

    seedNote.type = seedNoteData.type
    seedNote.interestRate = seedNoteData.interestRate
    seedNote.target = seedNoteData.target
    seedNote.maturityDate = seedNoteData.maturityDate
    seedNote.discount = seedNoteData.discount
    seedNote.name = seedNoteData.name
    seedNote.interestType = seedNoteData.interestType
    seedNote.cap = seedNoteData.cap
    seedNote.lowValuation = seedNoteData.lowValuation
    seedNote.historical = seedNoteData.historical
    seedNote.interestStartDate = seedNoteData.interestStartDate

    for (const loanData of seedNoteData.loans) {
      this.buildLoan(company, seedNote, loanData)
    }

    return seedNote
  }

  // ----------------------------------------------------

  buildSeedSafts(
    company: Company,
    seedSaftsData: ISeedSaftData[]
  ) {
    for (const seedSaftData of seedSaftsData) {
      this.buildSeedSaft(company, seedSaftData)
    }
  }

  // ----------------------------------------------------

  buildSeedSaft(
    company: Company,
    seedSaftData: ISeedSaftData
  ): SeedSaftEvent {
    let seedSaft = company.seedSafts.get(seedSaftData.id)
    let investor: Investor = null

    if (seedSaftData.investor) {
      this.buildInvestors(company, [ seedSaftData.investor ])
      investor = company.investors.get(seedSaftData.investor.id)
    }

    if (!seedSaft) {
      seedSaft = new SeedSaftEvent({
        id: seedSaftData.id,
        investor,
        amount: seedSaftData.amount,
        company
      })
      seedSaft.attach()
    }

    this.initialiseEventData(seedSaft, seedSaftData)
    return seedSaft
  }

  // ----------------------------------------------------

  buildShareTransfers(
    company: Company,
    transfersData: IShareTransferData[]
  ) {
    // First we go through and create the transfer events, along
    // with any transferred and remainder shares on each event.
    for (const transferData of transfersData) {
      this.buildShareTransfer(company, transferData)
    }

    // Now go through and set the source shares for each event
    // as we know they have been created.
    for (const transferData of transfersData) {
      const transfer = company.shareTransfers.get(transferData.id)

      transfer.sourceShare = this.shares.get(transferData.sourceShare.id)

      this.initialiseShareData(transfer.sourceShare, transferData.sourceShare)
    }
  }

  // ----------------------------------------------------

  buildShareTransfer(
    company: Company,
    transferData: IShareTransferData
  ): ShareTransferEvent {
    let transfer = company.shareTransfers.get(transferData.id)

    if (!transfer) {
      const investor = company.investors.get(transferData.investor.id)

      transfer = new ShareTransferEvent({
        id: transferData.id,
        company,
        investor
      })
      transfer.attach()
    }

    if (transferData.share) {
      if (!transfer.share) {
        const targetShareInvestor = company.investors.get(transferData.share.investor.id)
        const shareClass = company.shareClasses.get(transferData.share.shareClass.id)

        transfer.share = this.buildShare(transferData.share.id, transfer, shareClass, targetShareInvestor)
      }

      this.initialiseShareData(transfer.share, transferData.share)
    }

    if (transferData.remainderShare) {
      if (!transfer.remainderShare) {
        const targetShareInvestor = company.investors.get(transferData.remainderShare.investor.id)
        const shareClass = company.shareClasses.get(transferData.remainderShare.shareClass.id)

        transfer.remainderShare = this.buildShare(transferData.remainderShare.id, transfer, shareClass, targetShareInvestor)
      }

      this.initialiseShareData(transfer.remainderShare, transferData.remainderShare)
    }

    this.initialiseEventData(transfer, transferData)

    transfer.count = transferData.count
    transfer.pricePerShare = transferData.pricePerShare

    return transfer
  }

  // ----------------------------------------------------

  buildStockSplits(
    company: Company,
    splitsData: IStockSplitData[]
  ) {
    for (const splitData of splitsData) {
      this.buildStockSplit(company, splitData)
    }
  }

  // ----------------------------------------------------

  buildStockSplit(
    company: Company,
    splitData: IStockSplitData
  ): StockSplitEvent {
    let split = company.stockSplits.get(splitData.id)

    if (!split) {
      split = new StockSplitEvent({ id: splitData.id, company })
      split.attach()
    }

    this.initialiseEventData(split, splitData)

    split.before = splitData.before
    split.after = splitData.after
    split.historical = splitData.historical

    return split
  }

  // ----------------------------------------------------

  buildStopVestings(
    company: Company,
    stopVestingData: IStopVestingData[]
  ) {
    for (const stopVesting of stopVestingData) {
      this.buildStopVesting(company, stopVesting)
    }
  }

  // ----------------------------------------------------

  buildStopVesting(
    company: Company,
    stopVestingData: IStopVestingData
  ): StopVestingEvent {
    let stopVesting = company.stopVestings.get(stopVestingData.id)

    const optionGrant = company.optionGrants.get(stopVestingData.optionGrant.id)

    if (!stopVesting) {
      stopVesting = new StopVestingEvent({
        id: stopVestingData.id,
        company,
        optionGrant
      })
      stopVesting.attach()
    }

    this.initialiseEventData(stopVesting, stopVestingData)

    stopVesting.leaverStatus = stopVestingData.leaverStatus

    return stopVesting
  }

  // ----------------------------------------------------

  buildShareClasses(
    company: Company,
    shareClassesData: IShareClassData[]
  ) {
    for (const shareClassData of shareClassesData) {
      let shareClass = company.shareClasses.get(shareClassData.id)

      if (!shareClass) {
        const id = shareClassData.id
        shareClass = new ShareClass({ id, company })
        shareClass.attach()
      }

      shareClass.name = shareClassData.name
      shareClass.seniority = shareClassData.seniority
      shareClass.preferred = shareClassData.preferred
      shareClass.voting = shareClassData.voting
      shareClass.participating = shareClassData.participating
      shareClass.dividends = shareClassData.dividends
      shareClass.transferable = shareClassData.transferable
      shareClass.antiDilution = shareClassData.antiDilution
      shareClass.liquidation = shareClassData.liquidation
      shareClass.multiplier = shareClassData.multiplier
    }
  }

  // ----------------------------------------------------

  private buildShareClassRegistrations(
    company: Company,
    shareClassRegistrationsData: IShareClassRegistrationData[]
  ) {
    for (const shareClassRegistration of shareClassRegistrationsData) {
      this.buildShareClassRegistration(company, shareClassRegistration)
    }
  }

  // ----------------------------------------------------

  private buildShareClassRegistration(
    company: Company,
    data: IShareClassRegistrationData
  ): ShareClassRegistration {
    let shareClassRegistrationEvent = company.events.get(data.id) as ShareClassRegistration

    if (!shareClassRegistrationEvent) {
      shareClassRegistrationEvent = new ShareClassRegistration({
        ...data,
        company,
        shareClass: data.shareClass
      })

      shareClassRegistrationEvent.attach()
    }

    this.initialiseEventData(shareClassRegistrationEvent, data)

    return shareClassRegistrationEvent
  }

  // ----------------------------------------------------

  buildInvestors(
    company: Company,
    investorsData: IInvestorData[]
  ) {
    for (const investorData of investorsData) {
      let investor = company.investors.get(investorData.id)

      if (!investor) {
        const id = investorData.id

        const entity = investorData.investingCompany
          ? this.buildCompany(investorData.investingCompany)
          : this.buildUser(investorData.investingUser)

        investor = new Investor({ id, company, entity })
        investor.attach()
      }
    }
  }

  // ----------------------------------------------------

  buildInvestments(
    event: InvestmentEvent,
    investmentsData: IInvestmentWithInvestorShareAndShareClassData[]
  ) {
    const company = event.company

    for (const investmentData of investmentsData) {
      let investment = event.investments.get(investmentData.id)

      const investor = company.investors.get(investmentData.investor.id)
      const shareClass = company.shareClasses.get(investmentData.shareClass.id)

      if (!investment) {
        const id = investmentData.id

        investment = new Investment({ id, company, event, investor, shareClass })
        investment.attach()

        this.investments.set(id, investment)
      }

      investment.scheme = investmentData.scheme
      investment.amount = investmentData.amount
      investment.intendedOwnership = investmentData.intendedOwnership
      investment.intendedCount = investmentData.intendedCount
      investment.discount = investmentData.discount
      investment.type = investmentData.type
      investment.received = investmentData.received

      if (investmentData.share) {
        if (!investment._share) {
          investment._share = this.buildShare(
            investmentData.share.id,
            event,
            shareClass,
            investor,
            investment
          )
        }

        this.initialiseShareData(investment._share, investmentData.share)
      }
    }
  }

  // ----------------------------------------------------

  private attachLoansAndInvestments(
    company: Company,
    data: ICompanyData
  ) {
    for (const roundData of data.rounds) {
      for (const investmentData of roundData.investments) {
        if (investmentData.loan) {
          const investment = this.investments.get(investmentData.id)

          if (!investment) {
            throw new Error(`Cannot match loan and investment due to missing investment id ${investmentData.id}`)
          }

          const loan = this.loans.get(investmentData.loan.id)

          if (!loan) {
            throw new Error(`Cannot match loan and investment due to missing loan id ${investmentData.loan.id}`)
          }

          investment.loan = loan
          loan.investment = investment
        }
      }
    }
  }

  // ----------------------------------------------------

  buildLoan(
    company: Company,
    event: LoanEvent,
    loanData: ILoanData
  ) {
    let loan: Loan = event.loans.get(loanData.id) ?? this.loans.get(loanData.id)

    const investor = company.investors.get(loanData.investor.id)

    if (!loan) {
      loan = new Loan({
        id: loanData.id,
        event
      })

      loan.attach()

      this.loans.set(loanData.id, loan)
    }

    if (loanData.loanCertificate) {
      if (!loan.loanCertificate) {
        loan.loanCertificate = this.buildLoanCertificate(loanData.loanCertificate, loan, investor)
      }
    }

    loan.amount = loanData.amount
    loan.conversionPrice = loanData.conversionPrice
    loan.received = loanData.received
    loan.investor = investor
  }

  // ----------------------------------------------------

  buildLoanCertificate(
    loanCertificateData: ILoanCertificateData,
    loan: Loan,
    investor: Investor,
  ): LoanCertificate {
    if (this.loanCertificates.has(loanCertificateData.id)) {
      return this.loanCertificates.get(loanCertificateData.id)
    }

    const loanCertificate = new LoanCertificate({
      id: loanCertificateData.id,
      certno: loanCertificateData.certno,
      issued: loanCertificateData.issued,
      loan,
      investor
    })

    this.loanCertificates.set(loanCertificateData.id, loanCertificate)

    return loanCertificate
  }

  // ----------------------------------------------------

  private buildShare(
    id: string,
    event: Event | null,
    shareClass: ShareClass,
    investor: Investor,
    investment?: Investment
  ): Share {
    if (this.shares.has(id)) {
      return this.shares.get(id)
    }

    const share = new Share({
      id,
      company: event.company,
      event,
      shareClass,
      investor,
      investment: investment || null
    })

    this.shares.set(id, share)

    return share
  }

  // ----------------------------------------------------

  private initialiseShareData(share: Share, shareData: IShareData): void {
    share.count = shareData.count
    share.certno = shareData.certno
    share.issued = shareData.issued
    share.annulled = shareData.annulled
    share.investmentScheme = shareData.investmentScheme ?? null
  }

  // ----------------------------------------------------

  buildDocument(
    company: Company | null,
    documentData: IDocumentData | IDocumentExcerptData
  ): Document {
    const id = documentData.id
    const isFullModel = 'type' in documentData
    let user: User

    if (!company && documentData.company) {
      company = this.buildCompany(documentData.company)
    } else if(documentData.user) {
      user = this.buildUser(documentData.user)
    }

    let document: Document = this.documents.get(documentData.id)

    if (!document) {
      let investor: Investor = null

      if (documentData.investor) {
        this.buildInvestors(company, [ documentData.investor ])
        investor = company.investors.get(documentData.investor.id)
      }

      document = new Document({
        id: documentData.id,
        documentType: documentData.type,
        company,
        investor
      })

      if (investor) {
        document.investor = investor
        investor.documents.add(document)
      }

      if (user) document.user = user

      this.documents.set(id, document)
    }

    if (isFullModel) {
      this.initialiseFullDocumentData(document, documentData as IDocumentData)
    } else {
      this.initialiseDocumentExcerptData(document, documentData as IDocumentExcerptData)
    }

    document.inserted = new Date(documentData.inserted)

    if (this.debug.logGlobalsService) {
      _log(`document`, document)
    }

    if (company) document.attach()

    return document
  }

  // ------------------------------------------------------------

  initialiseDocumentExcerptData(
    document: Document,
    data: IDocumentExcerptData
  ): void {
    document.updated = data.modified
      ? new Date(data.modified)
      : null

    document.publishedSnapshot = data.archived
      ? new DocumentSnapshot(data.archived)
      : null

    document.employee = data.employee
      ? this.buildUser(data.employee)
      : null
  }

  // ------------------------------------------------------------

  initialiseFullDocumentData(document: Document, data: IDocumentData) {
    const company = document.company

    if (data.advanceAssurance) {
      document.event = this.buildAdvanceAssuranceExcerpt(company, data.advanceAssurance)
    } else if (data.boardMeeting) {
      document.event = this.buildBoardMeeting(company, data.boardMeeting)
    } else if (data.compliance) {
      document.event = this.buildCompliance(company, data.compliance)

      if (data.statement) {
        document.statement = (document.event as ComplianceEvent).statements.get(data.statement.id)
      }
    } else if (data.confirmationStatement) {
      document.event = this.buildConfirmationStatement(company, data.confirmationStatement)
    } else if (data.convertibleNote) {
      document.event = this.buildConvertibleNoteExcerpt(company, data.convertibleNote)
    } else if (data.directorship) {
      document.event = this.buildDirectorship(company, data.directorship)
    } else if (data.directorshipVariation) {
      document.event = this.buildDirectorshipVariation(company, data.directorshipVariation)
    } else if (data.directorshipTermination) {
      document.event = this.buildDirectorshipTermination(company, data.directorshipTermination)
    } else if (data.emiValuation) {
      document.event = this.buildEmiValuationExcerpt(company, data.emiValuation)
    } else if (data.exit) {
      document.event = this.buildExit(company, data.exit)
    } else if (data.foundersShareholdersAgreement) {
      document.event = this.buildFoundersShareholdersAgreement(company, data.foundersShareholdersAgreement)
    } else if (data.optionPool) {
      document.event = this.buildOptionPoolEvent(company, data.optionPool)
    } else if (data.instantConversion) {
      document.event = this.buildInstantConversion(company, data.instantConversion)
    } else if (data.optionExercise) {
      document.event = this.buildOptionExerciseExcerpt(company, data.optionExercise)
    } else if (data.optionGrant) {
      document.event = this.buildOptionGrantExcerpt(company, data.optionGrant)
    } else if (data.optionScheme) {
      document.event = this.buildOptionSchemeExcerpt(company, data.optionScheme)
    } else if (data.optionReturn) {
      document.event = this.buildOptionReturnEvent(company, data.optionReturn)
    } else if (data.proposal) {
      document.event = this.buildProposal(company, data.proposal)
    } else if (data.regularReport) {
      document.event = this.buildRegularReport(company, data.regularReport)
    } else if (data.researchAssurance) {
      document.event = this.buildResearchAssurance(company, data.researchAssurance)
    } else if (data.researchClaim) {
      document.event = this.buildResearchClaim(company, data.researchClaim)
    } else if (data.round) {
      document.event = this.buildRoundExcerpt(company, data.round)
    } else if (data.shareClassRegistration) {
      document.event = this.buildShareClassRegistration(company, data.shareClassRegistration)
    } else if (data.shareTransfer) {
      document.event = this.buildShareTransfer(company, data.shareTransfer)
    } else if (data.stockSplit) {
      document.event = this.buildStockSplit(company, data.stockSplit)
    } else if (data.seedNote) {
      document.event = this.buildSeedNote(company, data.seedNote)
    } else if (data.seedSaft) {
      document.event = this.buildSeedSaft(company, data.seedSaft)
    } else if (data.cohortFunding) {
      document.event = this.buildCohortFunding(company, data.cohortFunding)
    } else if (data.employment) {
      document.event = this.buildEmploymentStartEvent(company, data.employment)
    } else if (data.employmentTermination) {
      document.event = this.buildEmploymentEvent(company, EmploymentTerminationEvent, data.employmentTermination)
    } else if (data.employmentVariation) {
      document.event = this.buildEmploymentEvent(company, EmploymentVariationEvent, data.employmentVariation)
    } else if (data.employmentBonusPayment) {
      document.event = this.buildEmploymentEvent(company, EmploymentBonusPaymentEvent, data.employmentBonusPayment)
    } else if (data.stopVesting) {
      document.event = this.buildStopVesting(company, data.stopVesting)
    } else if (data.instantInvestmentConsent) {
      document.event = this.buildInstantInvestmentConsent(company, data.instantInvestmentConsent)
    } else if (data.shareAllotmentReturn) {
      document.event = this.buildShareAllotmentReturn(company, data.shareAllotmentReturn)
    }

    document.accessibility = data.accessibility
    document.answers = data.answers
    document.status = data.status
    document.publishedUploadedFile = data.publishedUploadedFile

    document.publishedSnapshot = data.publishedSnapshot
      ? new DocumentSnapshot(data.publishedSnapshot)
      : null

    document.updated = data.updated
      ? new Date(data.updated)
      : null

    document.employee = data.employee
      ? this.buildUser(data.employee)
      : null
  }

  // ----------------------------------------------------

  buildSignatories(
    document: Document,
    signatoriesData: SignatoryData[]
  ) {
    return signatoriesData.map(signatoryData => this.buildSignatory(document, signatoryData))
  }

  // ------------------------------------------------------------

  buildSignatory(
    document: Document,
    signatoryData: SignatoryData
  ): Signatory {
    let signatory
    if ('company' in signatoryData && signatoryData.company) {
      signatory = this.buildCompanySignatory(document, signatoryData as ICompanySignatoryData)
    } else {
      signatory = this.buildUserSignatory(document, signatoryData as IUserSignatoryData)
    }

    if (this.debug.logGlobalsService) {
      _log(`signatory`, signatory)
    }

    return signatory
  }

  // ------------------------------------------------------------

  buildCompanySignatory(
    document: Document,
    signatoryData: ICompanySignatoryData
  ): Signatory {
    const signatory = Signatory.getCompanySignatory(
      document,
      this.buildCompany(signatoryData.company),
      signatoryData.companyRole || SignedAsRole.Signatory,
      signatoryData.requiresWitness || false
    )

    if (signatoryData.signature) {
      signatory.signature = this.buildSignature(signatory, signatoryData.signature)
    }

    return signatory
  }

  // ------------------------------------------------------------

  buildUserSignatory(
    document: Document,
    signatoryData: IUserSignatoryData
  ): Signatory {
    const signatory = Signatory.getUserSignatory(
      document,
      this.buildUser(signatoryData.user),
      signatoryData.requiresWitness
    )

    if (signatoryData.signature) {
      signatory.signature = this.buildSignature(signatory, signatoryData.signature)
    }

    return signatory
  }

  // ------------------------------------------------------------

  buildSignature(
    signatory: Signatory,
    signatureData: ISignatureData
  ): Signature {
    const signature = new Signature(
      signatory,
      this.buildUser(signatureData.user),
      signatureDataPicker(signatureData)
    )

    if (signatory.requiresWitness && signatureData.witness) {
      signature.witness = new Witness(
        signature,
        witnessDataPicker(signatureData.witness)
      )

      if (signatureData.witness.address) {
        signature.witness.address = this.buildAddress(signatureData.witness.address)
      }
    }

    return signature
  }

  // ------------------------------------------------------------

  constructor(
    @Inject(DebugConfig) private debug: IDebugOptions,
    private configuration: Configuration,
  ) {
    if (this.debug.logGlobalsService) {
      _logc(`GlobalsService(config)`, configuration, this)
    }
  }
}
