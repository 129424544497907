import { Collection } from '../collection'
import { DocumentCollection, Document } from '../documents'
import { INamed, Model, OnCompanyModel, ApiFieldSpec } from '../model'
import { CohortFundingEvent, ConvertibleNoteEvent } from '../events'
import { CohortMemberBankAccount } from '../money'
import { User } from '../user'
import { Company } from '../company'

import { CohortManager, CohortCity, CohortCityNames, getCohortConfig, ICohortConfig } from '@libs/cohort'

// ------------------------------------------------------------

export class Cohort extends Model implements INamed {
  readonly domain = 'cohorts'

  name: string

  /** This is the 'host' company i.e. EF or C13 */
  company: Company
  city?: CohortCity

  formStartDate?: string
  formEndDate?: string
  launchStartDate?: string
  launchEndDate?: string

  // ----------------------------------------------------

  constructor({
    name = '',
    city = CohortCityNames.LONDON,
    ...data
  }) {
    super({ name, city, ...data })
  }

  // ----------------------------------------------------

  get config(): ICohortConfig {
    return getCohortConfig(this.company.id, this.city)
  }

  // ----------------------------------------------------

  get logo(): string {
    return this.config.cohortLogo
  }

  // ----------------------------------------------------

  get colour(): string {
    return this.config.toolbarBackground
  }

  // ----------------------------------------------------

  get cohortManager(): CohortManager {
    return this.config.cohortManager
  }

  // ----------------------------------------------------

  get isClimateKIC(): boolean {
    return this.config.cohortManager === CohortManager.ClimateKIC
  }

  get isEF(): boolean {
    return this.config.cohortManager === CohortManager.EntrepreneurFirst
  }

  get offerLetterTitle(): string {
    if (this.isClimateKIC) {
      return $localize`Community Agreement`
    } else {
      return $localize`Offer Letter`
    }
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'company', include: 'create' },
      'name',
      'city',
      'formStartDate',
      'formEndDate',
      'launchStartDate',
      'launchEndDate'
    ]
  }
}

// ------------------------------------------------------------

export interface CohortTeamAdditionalInfo {
  haxInvestment?: boolean
}

// ------------------------------------------------------------

export class CohortTeam extends OnCompanyModel {
  readonly domain = 'cohortTeams'

  /** This is the new company formed for the team */
  override company: Company

  cohort: Cohort

  externalId: string
  completed: string
  additionalInfo: CohortTeamAdditionalInfo

  cohortMembers = new CohortMemberCollection()
  documents = new DocumentCollection()

  cohortFunding: CohortFundingEvent

  // ----------------------------------------------------

  constructor({
    company,
    cohort,
    externalId = '',
    completed = null,
    additionalInfo = {},
    ...data
  }) {
    super({ company, cohort, externalId, completed, additionalInfo, ...data })
  }

  // ----------------------------------------------------

  get name() {
    return this.company.name
  }

  // ----------------------------------------------------

  get fundingEvent() {
    return this.company.events.find(e =>
      (e instanceof ConvertibleNoteEvent || e instanceof CohortFundingEvent)
      && e.cohortTeam?.id === this._id)
  }

  // ----------------------------------------------------

  get fundingDocuments(): (Document | undefined)[] {
    return this.cohort.config.fundingDocTypes.map(type => this.documents.getByType(type))
  }

  // ----------------------------------------------------

  get completedAnyFundingDocument(): boolean {
    return this.fundingDocuments.some(d => d?.finished)
  }

  // ----------------------------------------------------

  get founderServiceAgreements(): Document[] {
    return this.documents.getAllByType(this.cohort.config.FSRVDocType)
  }

  // ----------------------------------------------------

  get hasFounderAgreements(): boolean {
    return this.founderServiceAgreements.length === this.cohortMembers.length
  }

  // ----------------------------------------------------

  get signedFounderAgreements(): boolean {
    return this.hasFounderAgreements && this.founderServiceAgreements.every(doc => doc.finished)
  }

  // ----------------------------------------------------

  get progress(): string {
    return this.fundingDocuments.reduce((a, d) => a + (d?.finished ? 1 : 0), 0) + '/' + this.fundingDocuments.length
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'company', include: 'create' },
      { key: 'cohort', include: 'create' },
      'externalId',
      'completed',
      'additionalInfo',
    ]
  }
}

// ------------------------------------------------------------

export interface CohortMemberCompany {
  id: string
  name: string
  picture: string
  companiesHouseNumber: string
  claimed: boolean
  hasCaptable: boolean
}

// ------------------------------------------------------------

export interface CohortMemberAdditionalInfo {
  salary?: number
  companyName?: string
  legalStatus?: string
  registeredOfficeAddress?: string
}

// ------------------------------------------------------------

export class CohortMember extends Model implements INamed {
  readonly domain = 'cohortMembers'

  user: User
  cohort: Cohort
  bankAccount: CohortMemberBankAccount
  hasPassport: boolean
  hasUtility: boolean
  hasSignedSAFE: boolean
  passed: boolean
  memberCompany: CohortMemberCompany

  additionalInfo: CohortMemberAdditionalInfo

  documents = new DocumentCollection()

  // ----------------------------------------------------

  constructor({
    user,
    memberCompany = null,
    cohort,
    bankAccount = null,
    hasPassport = false,
    hasUtility = false,
    hasSignedSAFE = false,
    additionalInfo = {},
    ...data
  }) {
    super({ user, memberCompany, cohort, bankAccount, hasPassport, hasUtility, hasSignedSAFE, additionalInfo, ...data })
  }

  // ----------------------------------------------------

  get name() {
    return this.user.name
  }

  // ----------------------------------------------------

  get avatarUrl() {
    return this.user.avatarUrl
  }

  // ----------------------------------------------------

  get hasAvatar() {
    return this.user.hasAvatar
  }

  // ----------------------------------------------------

  get initials() {
    return this.user.initials
  }

  // ----------------------------------------------------

  get offerLetter(): Document | undefined {
    return this.documents.getByType(this.cohort.config.offerLetterDocType)
  }

  // ----------------------------------------------------

  get signedOfferLetter(): boolean {
    return this.offerLetter?.hasRequiredSignatures()
  }

  // ----------------------------------------------------

  get hasAdditionalInfo(): boolean {
    for (const q of this.cohort.config.cohortMembersQuestions) {
      if (q.enabled) {
        if (!q.enabled(this.additionalInfo)) {
          continue
        }
      }

      if (q.required) {
        if (!(q.key in this.additionalInfo)) {
          return false
        }
      }
    }

    return true
  }

  // ----------------------------------------------------

  get progress(): string {

    const progressSteps = [
      this.signedOfferLetter,
      this.bankAccount.isComplete,
      this.hasPassport,
      this.hasUtility,
      this.hasAdditionalInfo,
    ]

    return progressSteps.filter(Boolean).length + '/' + progressSteps.length

  }

  // ----------------------------------------------------

  get approved(): boolean {
    return this.passed || this.cohort.isClimateKIC
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'user', include: 'create' },
      { key: 'memberCompany', include: 'update' },
      { key: 'cohort', include: 'create' },
      'passed',
      'additionalInfo'
    ]
  }
}

// ------------------------------------------------------------

export class CohortMemberCollection extends Collection<CohortMember> {
  constructor() {
    super('name')
  }

  // ----------------------------------------------------

  getCohortMemberForUser(user: User): CohortMember | undefined {
    return this.find(member => member.user.link === user.link)
  }

  // ----------------------------------------------------

  get hasCohortMember(): boolean {
    return this.length > 0
  }
}
