import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AuthFacade } from '../+state/auth.facade'

// ----------------------------------------------------------

@Injectable()
export class AdminRoleGuard implements CanActivate {

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.isPlatformAdmin$.pipe(
      map(isPlatformAdmin => {
        return isPlatformAdmin || this.router.createUrlTree([ '/' ])
      })
    )
  }

  // ----------------------------------------------------

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
  ) {}

}
