<div class="overview-card"
     fxLayout="column"
     fxLayoutAlign="start stretch">

  <div class="card-color" [ngClass]="cardData.css"></div>

  <div class="overview-card-content"
       fxLayout="column"
       fxLayoutAlign="start stretch">
    <span class="title">{{ cardData.title }}</span>
    <span class="large">{{ cardData.value | slNumber }}</span>
    <span class="small">{{ cardData.change }}</span>
  </div>
</div>
