export const COMPANY_CREATION_DISCLAIMER = $localize`
  <h3>It's your data</h3>
  <p>All data you upload into the SeedLegals platform is 100% yours. We’ll never share or make your data or information available to anyone without your explicit permission.</p>
  <h3>No qualified legal, tax, or financial advice</h3>
  <p>We're a platform that helps you grow your company and close funding rounds. We will assist you with our own advice as you use our services and we have professional indemnity insurance in place in the event of a claim. But we are not giving qualified advice at any stage and ultimately, you should make your own judgement on the suitability of any documents to your circumstances and obtain your own qualified advice as you see fit.</p>
  <p>Full details in our <a href="https://seedlegals.com/terms-of-service/" target="_blank">Terms of Service</a></p>
`

export const FR_COMPANY_CREATION_DISCLAIMER = $localize`
  <h3>It's your data</h3>
  <p>All data you upload into the SeedLegals platform is 100% yours. We’ll never share or make your data or information available to anyone without your explicit permission.</p>
  <h3>No qualified legal, tax, or financial advice</h3>
  <p>We're a platform that helps you grow your company and close funding rounds. We will assist you with our own advice as you use our services and we have professional indemnity insurance in place in the event of a claim. But we are not giving qualified advice at any stage and ultimately, you should make your own judgement on the suitability of any documents to your circumstances and obtain your own qualified advice as you see fit.</p>
  <h3>Seek legal advice if needed</h3>
  <p>SeedLegals is a platform that uses our proprietary technology to help you grow your company and close funding rounds. SeedLegals is not a law firm and is not qualified to give legal advice under French Law (71-1130 dated 31 December 1971 reforming certain legal and judicial professions, as amended). We are a platform that helps you draft legal documents on your own, without external legal advice. If you need any legal advice, please contact our partner law firms (our customer support team will help you with this) or seek qualified legal support on your own.</p>
  <p>Full details in our <a href="https://seedlegals.com/fr/terms-of-subscription/" target="_blank">Terms of Service</a></p>
`

export const IRL_COMPANY_CREATION_DISCLAIMER = $localize`
  <h3>Your data is secure</h3>
  <p>All personal data or information you upload into the SeedLegals platform is secure. We’ll never share or make your personal data or information available to anyone without your explicit permission, and in accordance with our Privacy Policy.</p>
  <p>Communications between you and us are protected by our Privacy Policy but not by attorney-client privilege.</p>
  <h3>No legal, tax, or financial advice</h3>
  <p>We're a platform that uses our proprietary technology to help you grow your company and close funding rounds. We are not a law firm. We are not licensed to provide legal advice at any stage and are not a substitute for a practising advocate, solicitor, attorney, or law firm. Ultimately, you should make your own judgement on the suitability of any documents to your circumstances and obtain your own qualified advice as you see fit.</p>
  <p>Your access to our platform is subject to our full <a href="https://seedlegals.com/ie/terms-of-service/" target="_blank">Terms of Service</a></p>
`

export const SGP_COMPANY_CREATION_DISCLAIMER = $localize`
  <h3>Your data is secure</h3>
  <p>All personal data or information you upload into the SeedLegals platform is secure. We’ll never share or make your personal data or information available to anyone without your explicit permission, and in accordance with our Privacy Policy.</p>
  <p>Communications between you and us are protected by our Privacy Policy but not by attorney-client privilege.</p>
  <h3>No legal, tax, or financial advice</h3>
  <p>We're a platform that uses our proprietary technology to help you grow your company and close funding rounds. We are not a law firm. We are not licensed to provide legal advice at any stage and are not a substitute for a practising advocate, solicitor, attorney, or law firm. Ultimately, you should make your own judgement on the suitability of any documents to your circumstances and obtain your own qualified advice as you see fit.</p>
  <p>Your access to our platform is subject to our full <a href="https://seedlegals.com/sg/terms-of-service/" target="_blank">Terms of Service</a></p>
`

export const HKG_COMPANY_CREATION_DISCLAIMER = $localize`
  <h3>Your data is secure</h3>
  <p>All personal data or information you upload into the SeedLegals platform is secure. We’ll never share or make your personal data or information available to anyone without your explicit permission, and in accordance with our Privacy Policy.</p>
  <p>Communications between you and us are protected by our Privacy Policy but not by attorney-client privilege.</p>
  <h3>No legal, tax, or financial advice</h3>
  <p>We're a platform that uses our proprietary technology to help you grow your company and close funding rounds. We are not a law firm. We are not licensed to provide legal advice at any stage and are not a substitute for a practising advocate, solicitor, attorney, or law firm. Ultimately, you should make your own judgement on the suitability of any documents to your circumstances and obtain your own qualified advice as you see fit.</p>
  <p>Your access to our platform is subject to our full <a href="https://seedlegals.com/hk/terms-of-service/" target="_blank">Terms of Service</a></p>
`
