<div fxLayout="column">
  <div class="bb sticky">
    <sl-action-with-search-bar [debounceInterval]="800"
                               (searchChanged$)="onSearch($event)"
                               searchLabel="Search"
                               i18n-searchLabel
                               fxFlex
                               class="search-bar">
    </sl-action-with-search-bar>
  </div>

  <div style="height: 4px">
    <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
  </div>

  <ng-container *ngrxLet="results$ as results">
    <mat-tab-group mat-align-tabs="start center"
                   dynamicHeight>
        <mat-tab labelClass="small">
          <ng-template mat-tab-label>
            <mat-icon fontIcon="icon-filter-variant" class="s20 mr-8"></mat-icon>
            <span i18n>All ({{ results.documents.length + results.employees.length }})</span>
          </ng-template>
          <mat-action-list>
            <ng-container *ngIf="loaded$ | async; else noInput">
              <p *ngIf="results.employees.length > 0"
                 mat-subheader
                 i18n>Team ({{ results.employees.length }})</p>
              <ng-container *ngTemplateOutlet="employeeList; context: { $implicit: results.employees }"></ng-container>

              <p *ngIf="results.documents.length > 0"
                 mat-subheader
                 i18n>Documents ({{ results.documents.length }})</p>
              <ng-container *ngTemplateOutlet="documentList; context: { $implicit: results.documents }"></ng-container>

              <p *ngIf="results.documents.length + results.employees.length === 0"
                 mat-subheader
                 i18n>No results found</p>
            </ng-container>
          </mat-action-list>
        </mat-tab>
        <mat-tab [disabled]="results.employees.length === 0"
                 labelClass="small">
          <ng-template mat-tab-label>
            <mat-icon fontIcon="icon-account-group" class="s20 mr-8"></mat-icon>
            <span i18n>Team ({{ results.employees.length }})</span>
          </ng-template>
          <mat-action-list>
            <ng-container *ngTemplateOutlet="employeeList; context: { $implicit: results.employees }"></ng-container>
          </mat-action-list>
        </mat-tab>
        <mat-tab [disabled]="results.documents.length === 0"
                 labelClass="small">
          <ng-template mat-tab-label>
            <mat-icon fontIcon="icon-file-document" class="s20 mr-8"></mat-icon>
            <span i18n>Documents ({{ results.documents.length }})</span>
          </ng-template>
          <mat-action-list>
            <ng-container *ngTemplateOutlet="documentList; context: { $implicit: results.documents }"></ng-container>
          </mat-action-list>
        </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>

<ng-template #documentList let-documents>
  <mat-list-item *ngFor="let document of documents"
                 [routerLink]="[ 'companies', companyId, 'documents', document.id ]"
                 mat-dialog-close>
    <div gdColumns="5fr 4fr 3fr 3fr"
         gdColumns.lt-sm="1fr 1fr"
         gdAlignColumns=" center"
         gdGap="8px"
         class="w-100">
      <div fxLayoutAlign=" center" class="gap-8 text-truncate">
        <mat-icon fontIcon="icon-file-document" class="dark"></mat-icon>
        <p class="text-truncate">{{ documentTypeNames[ document.type ] }}</p>
      </div>

      <p class="text-truncate text-light-grey">
        {{ document.otherPartyUser?.name ?? document.otherPartyCompany?.name | slDash }}
      </p>

      <p class="text-light-grey hide-lt-sm">{{ document.modified | slDate }}</p>

      <sl-category-bullet class="hide-lt-sm" [category]="documentTypeCategories.get(document.type)"></sl-category-bullet>
    </div>
  </mat-list-item>
</ng-template>

<ng-template #employeeList let-employees>
  <mat-list-item *ngFor="let employee of employees"
                 [routerLink]="[ 'companies', companyId, 'team', 'profile', employee.user.id ]"
                 mat-dialog-close>
    <div gdColumns="5fr 4fr 3fr 3fr"
         gdColumns.lt-sm="1fr 1fr"
         gdAlignColumns=" center"
         class="w-100 gap-8">
      <div fxLayoutAlign=" center" class="gap-8 text-truncate">
        <sl-entity [entity]="employee.user"
                   [noTooltip]="true"
                   [size]="24">
        </sl-entity>
        <p class="text-truncate">{{ employee.user.name | slDash }}</p>
      </div>

      <p class="text-truncate text-light-grey">{{ employee.keyTerms.jobTitle }}</p>

      <p class="text-truncate text-light-grey hide-lt-sm">{{ employee.keyTerms.department?.name | slDash }}</p>

      <sl-chip [colour]="employmentTypeTheme[ employee.keyTerms.employmentType ]"
               [customStyle]="{ height: '25px', fontSize: '10px', fontWeight:'500' }"
               class="hide-lt-sm">
        {{ employmentTypeLabels[ employee.keyTerms.employmentType ] }}
      </sl-chip>
    </div>
  </mat-list-item>
</ng-template>

<ng-template #noInput>
  <p mat-subheader i18n>Suggestions</p>
  <mat-list-item mat-dialog-close [routerLink]="[ 'companies', companyId, 'documents' ]">
    <img matListIcon src="/assets/images/app/left-nav/documents.svg" alt="documents image" i18n-alt>
    <span i18n>Go to my documents</span>
  </mat-list-item>
  <mat-list-item mat-dialog-close [routerLink]="[ 'companies', companyId, 'raise' ]">
    <img matListIcon src="/assets/images/app/left-nav/raise.svg" alt="raise image" i18n-alt>
    <span i18n>Raise investment</span>
  </mat-list-item>
  <mat-list-item mat-dialog-close [routerLink]="[ 'companies', companyId, 'captable' ]">
    <img matListIcon src="/assets/images/app/left-nav/captable.svg" alt="captable image" i18n-alt>
    <span i18n>Check my captable</span>
  </mat-list-item>
  <mat-list-item mat-dialog-close [routerLink]="[ 'companies', companyId, 'team' ]">
    <img matListIcon src="/assets/images/app/left-nav/team.svg" alt="team image" i18n-alt>
    <span i18n>Manage my team</span>
  </mat-list-item>
</ng-template>
