import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import type { CurrencyCode, Tier } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-price-info-tier-list',
  templateUrl: './price-info-tier-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceInfoTierListComponent {

  @Input() currency: CurrencyCode
  @Input() tiers: Tier[]
  @Input() minimumPrice: number
  @Input() depositFee: number
  @Input() hideCalculations: boolean
}
