import { UrlTree } from '@angular/router'

import { isObservable, type MonoTypeOperatorFunction, type Observable, throwError } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'

import { DebugOptions } from '@env/environment'

// --------------------------------------------------------------------------

const isPrimitive = v => v === null
  || [ 'undefined', 'boolean', 'string', 'number' ].includes(typeof v)

// --------------------------------------------------------------------------

const asArray = (v: string | string[]): string[] => Array.isArray(v) ? v : [ v ]

// --------------------------------------------------------------------------

export const STYLES = {
  BOLD:  [ 'font-weight: bold;', '' ],
  LINK:  [ 'text-decoration: underline; color: #07f;', '' ],
  NUM:   [ 'font-weight: bold; color: hotpink;', '' ],
  NAME:  [ 'color: #8a2be2;', '' ],
  ERROR: [ 'font-weight: bold; color: #e52b50;', '' ],
  VALUE: [ 'color: #536872;', '' ],
  VAL2:  [ 'font-weight: bold; color: #b0f;', '' ],
  VAL3:  [ 'font-weight: bold; color: #88f;', '' ]
}

// --------------------------------------------------------------------------

interface GroupOutputItem {
  kind: 'group'
  group: ConsoleGroup
}

interface LogOutputItem {
  kind: 'log'
  items: unknown[]
}

interface ArrayOutputItem {
  kind: 'array'
  array: unknown[]
}

interface ObjectOutputItem {
  kind: 'object'
  object: unknown
}

interface ErrorOutputItem {
  kind: 'error'
  error: Error
}

interface UrlTreeOutputItem {
  kind: 'urlTree'
  urlTree: UrlTree
}

type OutputItem =
  | GroupOutputItem
  | LogOutputItem
  | ArrayOutputItem
  | ObjectOutputItem
  | ErrorOutputItem
  | UrlTreeOutputItem

// --------------------------------------------------------------------------

export class ConsoleGroup {
  isCollapsed = false
  showTrace = false
  _title: string[] = [ '' ]
  outputItems: OutputItem[] = []

  // ----------------------------------------------------

  collapse(collapsed = true): this {
    this.isCollapsed = collapsed
    return this
  }

  // ----------------------------------------------------

  trace(trace = true): this {
    this.showTrace = trace
    return this
  }

  // ----------------------------------------------------

  title(...title: string[]): this {
    this._title = title
    return this
  }

  // ----------------------------------------------------

  items(items: unknown[]): this {
    const newItems: OutputItem[] = items.map(item => {
      if (item instanceof ConsoleGroup) {
        return {
          kind: 'group',
          group: item
        }
      } else if (isPrimitive(item)) {
        return {
          kind: 'log',
          items: [ item ]
        }
      } else if (Array.isArray(item)) {
        return {
          kind: 'array',
          array: item
        }
      } else if (typeof item === 'function') {
        return {
          kind: 'log',
          items: [ item.toString() ]
        }
      } else if (item instanceof Error) {
        return {
          kind: 'error',
          error: item
        }
      } else if (item instanceof UrlTree) {
        return {
          kind: 'urlTree',
          urlTree: item
        }
      } else {
        return {
          kind: 'object',
          object: item
        }
      }
    })

    this.outputItems.push(...newItems)

    return this
  }

  // ----------------------------------------------------

  openConsoleGroup(): void {
    if (this.isCollapsed) {
      console.groupCollapsed(...this._title)
    } else {
      console.group(...this._title)
    }
  }

  // ----------------------------------------------------

  writeItemToConsole(item: OutputItem): void {
    switch (item.kind) {
      case 'group':
        item.group.display()
        break

      case 'log':
        console.log(...item.items)
        break

      case 'array':
        console.groupCollapsed(`%c${item.array.length}%c items`, ...STYLES.VALUE)

        try {
          for (const i of item.array) {
            console.dir(i)
          }
        } finally {
          console.groupEnd()
        }

        break

      case 'error':
        console.error(item.error)
        break

      case 'urlTree':
        console.log(item.urlTree.toString(), item.urlTree)
        break

      case 'object':
        console.dir(item.object)
        break
    }
  }

  // ----------------------------------------------------

  closeConsoleGroup() {
    console.groupEnd()
  }

  // ----------------------------------------------------

  display() {
    this.openConsoleGroup()

    try {
      if (this.showTrace) {
        console.groupCollapsed(`Trace`)
        // eslint-disable-next-line no-restricted-syntax
        console.trace()
        console.groupEnd()
      }

      for (const v of this.outputItems) {
        this.writeItemToConsole(v)
      }
    } finally {
      this.closeConsoleGroup()
    }
  }
}

// --------------------------------------------------------------------------

export function _log(title: string | string[], ...args: unknown[]) {
  if (DebugOptions.logConsoleOutput) {
    new ConsoleGroup()
      .title(...asArray(title))
      .items(args)
      .display()
  }
}

export function _logt(title: string | string[], ...args: unknown[]) {
  if (DebugOptions.logConsoleOutput) {
    new ConsoleGroup()
      .trace()
      .title(...asArray(title))
      .items(args)
      .display()
  }
}

export function _logc(title: string | string[], ...args: unknown[]) {
  if (DebugOptions.logConsoleOutput) {
    new ConsoleGroup()
      .collapse()
      .title(...asArray(title))
      .items(args)
      .display()
  }
}

export function _logtc(title: string | string[], ...args: unknown[]) {
  if (DebugOptions.logConsoleOutput) {
    new ConsoleGroup()
      .trace()
      .collapse()
      .title(...asArray(title))
      .items(args)
      .display()
  }
}

// --------------------------------------------------------------------------

type ILogFunc = (title: string | string[], ...args: unknown[]) => void

const processArgs = (prefix: string) => (func: ILogFunc) => {
  return (title: string | string[], ...args: unknown[]) => {
    if (typeof title === 'string') {
      title = [ title ]
    }

    title[ 0 ] = prefix + ': ' + title

    func(title, ...args)
  }
}

export class Logger {
  processor = processArgs(this.name)

  log = this.processor(_log)
  logc = this.processor(_logc)
  logt = this.processor(_logt)
  logtc = this.processor(_logtc)

  constructor(private name: string) {}
}

// --------------------------------------------------------------------------

export type LoggerOptions<T> = {
  name?: string
  nameStyles?: string[]
  items?: string[]
  collapsed?: boolean
  project?: (x: T) => unknown
}

export function _logger<T>(
  {
    name = 'Observable',
    nameStyles = [],
    collapsed = false,
    items = [],
    project = (x: T) => x,
  }: LoggerOptions<T> = {}
): MonoTypeOperatorFunction<T> {
  if (items.length) {
    name += ': ' + items.join(', ')
  }

  const lf = collapsed ? _logc : _log
  const lop: MonoTypeOperatorFunction<T> = tap(v => {
    lf([ name, ...nameStyles ], project(v))
  })

  return function(source: Observable<T>) {
    return source.pipe(
      lop,
      catchError(error => {
        lf([ '%c' + name, 'color: red' ], error)
        return throwError(() => error)
      })
    )
  }
}

export function logger<T>(
  nameOrOptions?: string | LoggerOptions<T>,
  project?: (x: T) => unknown,
) {
  if (typeof nameOrOptions === 'string') {
    nameOrOptions = {
      name: nameOrOptions,
      project,
    }
  }

  return _logger<T>(nameOrOptions)
}

// --------------------------------------------------------------------------

const SIMPLE_TYPES = new Set([ 'string', 'number', 'boolean', 'undefined', 'symbol' ])

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * If console logging is enabled wraps the decorated method so that each call
 * will be logged with its arguments along with either the return value or any
 * exception thrown. If the return value is an observable instead emitted values
 * will each be logged similarly to if using the `logger()` operator above.
 *
 * @param prefix Optional prefix to add before each message
 * @returns      Method descriptor for wrapped method
 */
export function LogMethod(
  prefix?: string,
) {
  if (!DebugOptions.logConsoleOutput) {
    return (target, key, descriptor) => descriptor
  }

  return function<
    Method extends (...args: any) => any,
    This = ThisParameterType<Method>,
    Return = ReturnType<Method>,
  >(
    target: Object,
    key: string | symbol,
    descriptor: TypedPropertyDescriptor<Method>,
  ): TypedPropertyDescriptor<Method> {
    const method = descriptor.value

    const label = prefix
      ? `%c[${prefix}] %c${target.constructor.name}%c.%c${method.name}%c()`
      : `%c${target.constructor.name}%c.%c${method.name}%c()`
    const labelStyles = prefix
      ? [ 'color: #f0f;', 'color: #388a34; font-weight: bold;', '', 'color: #388a34; font-style: italic;', '' ]
      : [ 'color: #388a34; font-weight: bold;', '', 'color: #388a34; font-style: italic;', '' ]

    console.log(`Logging method: ${label}`, ...labelStyles)

    /**
     * The type of `...args` is `unknown[]` here because setting it to `Parameters<Method>` (the
     * type of the decorated function's parameters as a tuple e.g. `[ prefix?: string ]) gives
     * an error that `Parameters<Method>` must be an array type, which is due to a TypeScript bug
     * where tuples aren't recognised by a spread parameter such as `...args`.
     *
     * @see https://github.com/microsoft/TypeScript/issues/45371
     */
    descriptor.value = function(this: This, ...args: unknown[]): Return {
      console.groupCollapsed(label, ...labelStyles)
      console.log(`this = %o`, this)
      console.dir(args)

      try {
        const result = method.apply(this, args) as Return

        if (isObservable(result)) {
          const nextLabel = [ label + ': %cNEXT    %c', ...labelStyles, 'color: #388a34;', '' ]
          const errorLabel = [ label + ': %cERROR   %c', ...labelStyles, 'color: red; font-weight: bold;', '' ]
          const completeLabel = [ label + ': %cCOMPLETE%c', ...labelStyles, 'color: #05f; font-weight: bold;', '' ]

          return result.pipe(
            tap({
              next: value => {
                if (value instanceof UrlTree) {
                  value = `UrlTree(url: ${value})`
                }

                if (SIMPLE_TYPES.has(typeof value)) {
                  console.log(...nextLabel, value)
                } else {
                  console.group(...nextLabel)
                  console.dir(value)
                  console.groupEnd()
                }
              },
              error: err => {
                console.error(...errorLabel, err)
              },
              complete: () => {
                console.log(...completeLabel)
              },
            }),
          ) as unknown as Return
        } else {
          console.log(`result = %o`, result)
        }

        return result
      } finally {
        console.groupEnd()
      }
    } as Method

    return descriptor
  }
}

/* eslint-enable @typescript-eslint/ban-types */
/* eslint-enable @typescript-eslint/no-explicit-any */
