import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core'

import { path, sortBy } from 'ramda'

import { IEntity } from '../../models/entity-sidenav'

import { ActionWithSearchBarComponent } from '@libs/shared'

// ----------------------------------------------------------

export const entitySorter = sortBy(path([ 'entity', 'name' ]))

// ----------------------------------------------------------

@Component({
  selector: 'sl-entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: [ './entity-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySelectorComponent<E extends IEntity = IEntity> implements OnChanges {
  @Input() entities: E[] = []
  @Input() searchLabel = $localize`Search...`
  @Input() addNewLabel = $localize`Add new...`
  @Input() canAddNew = false

  @Output() entitySelected = new EventEmitter<IEntity>()
  @Output() addNewClicked = new EventEmitter<void>()

  @ViewChild(ActionWithSearchBarComponent) actionBar: ActionWithSearchBarComponent

  sortedEntities: E[] = []
  filteredEntities: E[] = []

  // ----------------------------------------------------

  ngOnChanges(changes) {
    // _log(`EntitySelectorComponent.ngOnChanges(changes): this`, changes, this)

    if (changes.entities) {
      this.sortedEntities = entitySorter(this.entities)

      if (this.actionBar) {
        this.actionBar.clearSearch()
      } else {
        this.onFilterChanged()
      }
    }
  }

  // ----------------------------------------------------

  onEntitySelected(entity: IEntity) {
    this.entitySelected.emit(entity)
  }

  // ----------------------------------------------------

  onFilterChanged(filterText = '') {
    if (filterText) {
      const matchRegex = new RegExp(filterText, 'i')

      this.filteredEntities = this.sortedEntities.filter(e => matchRegex.test(e.entity.name))
    } else {
      this.filteredEntities = [ ...this.sortedEntities ]
    }
  }
}
