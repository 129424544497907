import { type AfterContentInit, Component, ContentChild, ContentChildren, Input, QueryList, TemplateRef, ViewContainerRef } from '@angular/core'
import { trigger, transition, style, animate } from '@angular/animations'

import { TemplatePortal } from '@angular/cdk/portal'

import { GhostGridItemDirective } from './ghost-grid-item.directive'
import { GhostGridContentDirective } from './ghost-grid-content.directive'

// ----------------------------------------------------------

/**
 * Ghost grid component
 *
 * Customizable grid-style container for building layouts with ghost components.
 * Should contain at least one child component with the `slGhostGridItem` directive
 * in order to render a `ghost component` while `loading` input is `true`.
 *
 * Simple usage with directives attached to content components:
 *
 * ```html
 * <sl-ghost-grid [loading]="loading">
 *   <h3 slGhostGridItem slGhostType="heading">
 *     // Some page heading
 *   </h3>
 *   <p slGhostGridItem slGhostType="line">
 *     // Paragraph text to be loaded.
 *     // Will be replaced by ghost lines
 *   </p>
 *   <table mat-table slGhostGridItem slGhostType="table" [slGhostRows]="4" [slGhostCols]="3">
 *     // Fancy table to be loaded
 *   </table>
 * </sl-ghost-grid>
 * ```
 *
 * More complex grid type loading page with ghost layout built separately.
 *
 * ```html
 * <sl-ghost-grid [loading]="loading" [cols]="6" [rowGap]="40">
 *   <sl-custom-component>
 *     // Some complex layout wrapped in this component
 *   </sl-custom-component>
 *   <ng-template slGhostGridItem slGhostType="heading"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="card" [slGhostColSpan]="2" [slGhostColSpanXs]="6"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="card" [slGhostColSpan]="2" [slGhostColSpanXs]="6"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="card" [slGhostColSpan]="2" [slGhostColSpanXs]="6"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="avatar" [slGhostColSpan]="2"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="list" [slGhostColSpan]="4" [slGhostCols]="2" [slGhostColsXs]="1"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="table" [slGhostRows]="3"></ng-template>
 * </sl-ghost-grid>
 * ```
 *
 * Wrapping content in a template with the slGhostGridContent directive
 * so that it will be lazily-loaded when ready:
 *
 * ```html
 * <sl-ghost-grid [ready]="ready$ | async">
 *   <ng-template slGhostGridContent>
 *     <sl-custom-component [data]="data | async$">
 *       // Component showing data loaded asynchronously
 *     </sl-custom-component>
 *   </ng-template>
 *
 *   <ng-template slGhostGridItem slGhostType="heading"></ng-template>
 *   <ng-template slGhostGridItem slGhostType="card"></ng-template>
 * </sl-ghost-grid>
 * ```
 */
@Component({
  selector: 'sl-ghost-grid',
  templateUrl: './ghost-grid.component.html',
  styleUrls: [ './ghost-grid.component.scss' ],
  animations: [
    trigger('showHideGhost', [
      transition(':leave', [
        animate('0.5s', style({ opacity: 0 }))
      ])
    ]),
    trigger('showContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ])
    ]),
  ]
})
export class GhostGridComponent implements AfterContentInit {

  /** Whether to show the `ghost components` or the actual content of the component.
   * Will trigger a Fade animation on Enter and Leave of the loading components.
   */
  @Input() loading?: boolean

  /** Whether to show the actual content of the component or the `ghost components`.
   * Will trigger a Fade animation on Enter and Leave of the loading components.
   */
  @Input() ready?: boolean

  /** Styles passed to the ghost-grid outer container. */
  @Input() styles: { [ klass: string ]: string } = {}

  /** Classes applied to the ghost-grid outer container. */
  @Input() classes: string | string[] = []

  /** Amount of columns that the grid will be composed of. Default = 1 */
  @Input() cols = 1

  /** Gap between each row in `px`. Default = 16 */
  @Input() rowGap = 16

  // ----------------------------------------------------------

  /** List of {@link GhostGridItemDirective} in template */
  @ContentChildren(GhostGridItemDirective)
  _itemChildren: QueryList<GhostGridItemDirective>

  /** Reference to template with {@link GhostGridContentDirective} if present */
  @ContentChild(GhostGridContentDirective, { read: TemplateRef, static: true })
  contentTemplate: TemplateRef<unknown>

  contentPortal?: TemplatePortal

  // ----------------------------------------------------------

  constructor(
    private viewContainerRef: ViewContainerRef
  ) {}

  // ----------------------------------------------------------

  ngAfterContentInit() {
    if (this.contentTemplate) {
      this.contentPortal = new TemplatePortal(
        this.contentTemplate,
        this.viewContainerRef
      )
    }
  }

  // ----------------------------------------------------------

  get showContent() {
    return this.loading === false || this.ready === true
  }

  // ----------------------------------------------------------

  colsBuilder(len: number) {
    return Array(len).fill('c')
  }

  rowsBuilder(len: number) {
    return Array(len).fill({ c: '' })
  }
}
