import { Inject, Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'

import type { Observable } from 'rxjs'
import { of, firstValueFrom } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { omit } from 'ramda'

import { Store } from '@ngrx/store'

import { Api, BackendService, FileTransferApi, RestApi } from '@libs/backend'
import { LocaleService, ToastService } from '@libs/services'
import { GlobalsService } from '@app/core/services/globals/globals.service'
import { UserService } from '@app/users/services/user.service'
import { RegisteredCompanySearchService } from '../registered-company-search/registered-company-search.service'

import { AddUserCompany } from '@app/users/+state/users.actions'
import { CompanyCreated, LoadCompany, LoadCompanyError, LoadCompanySuccess } from '../../+state/company.actions'
import { CompanyFacade } from '../../+state/company.facade'

import type {
  BankAccount,
  Company,
  CurrencyCode,
  Entity,
  ICompanyData,
  ICompanyPublicExcerptData,
  ICreateCompanyData,
  IModelData,
  IUploadEntityPictureResponse,
  Jurisdiction,
  User,
  WelcomePageKey
} from '@libs/models'
import {
  AccessRole,
  Address,
  AppointmentRole,
  CompanyBankAccount,
  Investor,
  isValidCurrencyCode,
  JurisdictionDefaultCountries,
  UserCompanyAccess,
  userCompanyPicker
} from '@libs/models'

import type { RegisteredCompanyData } from '../../models/company-data'
import type { CompanyMatch } from '@app/entities/models/company-match'
import { Configuration } from '@core/services/configuration.service'

// ----------------------------------------------------------

const addressOmitter = omit([ 'address' ])

const registeredCompanyOmitter = omit([
  'field',
  'registrarNumber',
  'region',
  'rawName',
  'addressSnippet'
])

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companyLoadingMap = new Map<string, Promise<Company>>()

  // ----------------------------------------------------

  public changeLocale(company: Company): string {
    let currencyCode: CurrencyCode = 'GBP'

    if (company.currency && isValidCurrencyCode(company.currency)) {
      currencyCode = company.currency
    }

    this.localeService.setCurrency(currencyCode)

    return this.localeService.locale
  }

  // ----------------------------------------------------

  /**
   * Create a new company with any initial values specified in data - which
   * can be as little as just the name of the new company.
   */
  private async doCreateCompany(
    user: User,
    companyData: ICreateCompanyData,
    extendRoles = false
  ): Promise<Company> {
    const { id } = await firstValueFrom(this.restApi
      .all('companies')
      .post<IModelData>(companyData))

    const company = await this.getCompany(id, true)

    const bankAccount = await this.doCreateBankAccount(company)
    bankAccount.attach()

    this.changeLocale(company)
    let roles: AppointmentRole[] = []
    const isUserAdmin = company.getRelation(user)?.roles.includes(AppointmentRole.Admin)

    // do not add appointments for seedlegals employees
    if (!user.email.endsWith('@seedlegals.com')) {
      roles = [ AppointmentRole.Read, AppointmentRole.Admin ]

      if (extendRoles) {
        const appointment = company.getRelation(user)

        if (!appointment) {
          throw new Error(`Appointment missing on newly created company ${company.id}`)
        }

        appointment.director = true
        appointment.founder = true
        appointment.signatory = true

        await appointment.save(this.restApi)

        roles.push(
          AppointmentRole.Director,
          AppointmentRole.Founder,
          AppointmentRole.Signatory
        )
      }
    } else {
      // add read access to seedlegals employees
      await firstValueFrom(this.api
        .one('companies', company.id)
        .one('accessRoles')
        .patch({
          roles: [ AccessRole.StaffRead ]
        }))

      /* For companies created by Gods,Demi Gods and Seedlegals internal users.
          Gods are assigned ADMIN roles
          Demi Gods and all other  Seedlegals Users are assigned CX Read access roles.
      */

      if (isUserAdmin) {
        roles.push(AppointmentRole.Admin)
      }
    }

    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(CompanyCreated({ company, user }))

    // TODO: Change to request user company details from BE

    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(AddUserCompany({
      userCompany: {
        id: company.id,
        company: userCompanyPicker(company),
        subscriptions: [],
        access: isUserAdmin ? UserCompanyAccess.Admin : UserCompanyAccess.StaffRead,
        hasFullAccess: true,
        roles,
        hasOptionAgreement: false,
        unsignedDocuments: 0,
        pendingDeadlinesCount: 0,
      }
    }))

    return company
  }

  // ----------------------------------------------------

  private async doCreateBankAccount(company: Company): Promise<BankAccount> {
    const bankAccount = new CompanyBankAccount({
      company
    })

    await bankAccount.save(this.restApi)
    return bankAccount
  }

  // ----------------------------------------------------

  /**
   * Check if a user has 'admin' access to the company, which means that
   * at least one of these conditions is true:
   *
   *   a) the user is defined as a god (platform admin)
   *   b) the user has an appointment for the company with the ADMIN role set
   *   c) the user has an access for the company with the role STAFF_READ set
   *   d) the user has an access for the company with the role STAFF_WRITE set
   */
  isUserAdmin(
    company?: Company,
    user?: User
  ): boolean {
    if (!company || !user) {
      return false
    }

    if (user.isPlatformAdmin || company.hasRole(user, AppointmentRole.Admin)) {
      return true
    }

    const access = user.accesses.findAccessForCompany({ id: company.id })

    if (!access) {
      return false
    }

    return access.accessRoles.includes(AccessRole.StaffRead) || access.accessRoles.includes(AccessRole.StaffWrite)
  }

  // ----------------------------------------------------

  /**
   * Check if this user has an appointment with read access for a company,
   * or are marked as a "SeedLegals user" and automatically have read
   * access on any company.
   */
  hasReadAccessForCompany(
    user: User,
    companyId: string
  ): boolean {
    if (user.isPlatformAdmin) {
      return true
    }

    const appointment = user.appointments.find(appt => appt.company.id === companyId)

    if (appointment?.read) {
      return true
    }

    return !!user.accesses.findAccessForCompany({ id: companyId })
  }

  // ----------------------------------------------------

  /**
   * Create a new company based on different possible CompanyMatch sources
   */
  async createCompanyFromCompanyMatch(
    match: CompanyMatch,
    jurisdiction: Jurisdiction,
    extendRoles: boolean
  ): Promise<Company> {

    const region = this.configuration.getRegionByJurisdiction(jurisdiction)

    const currentUser = this.userService.currentUser
    let company: Company

    switch (match.source) {
      case 'local':
        company = await match.company
        break

      case 'remote':
        try {
          company = await this.createRegisteredCompany(
            match.companyData,
            currentUser,
            extendRoles
          )
        } catch (error) {
          this.toastService.error($localize`Cannot create company. Please contact us.`)
          return
        }
        break

      case 'new': {
        company = await this.createNewCompany(
          {
            name: match.name,
            address: new Address({ country: JurisdictionDefaultCountries[ jurisdiction ].slice(0, 2) }),
            jurisdiction,
            currency: region.currency,
            type: 'OTHER'
          },
          currentUser
        )
        break
      }
    }
    return company
  }

  // ----------------------------------------------------

  /**
   * Create a new company with just a name that the user entered on
   * the new company page. It may well represent a business that
   * isn't even yet incorporated and registered at Companies House yet.
   */
  createNewCompany(
    companyData: ICreateCompanyData,
    user: User,
    extendRoles = false
  ): Promise<Company> {
    return this.doCreateCompany(user, companyData, extendRoles)
  }

  // ----------------------------------------------------

  /**
   * Create a new company with data from Companies House based on the
   * Companies House number they supplied - so it's a valid company,
   * just maybe not one they own nor have any involvement at all in!
   */
  async createRegisteredCompany(
    companyData: RegisteredCompanyData,
    user: User,
    extendRoles = false
  ): Promise<Company> {
    companyData = await firstValueFrom(this.registeredCompanySearchService
      .getCompany(companyData))

    companyData[ companyData.field ] = companyData.registrarNumber

    return this.doCreateCompany(user, registeredCompanyOmitter(companyData), extendRoles)
  }

  // ----------------------------------------------------

  async patchCompany(
    company: Company,
    data: Partial<Company>
  ): Promise<HttpErrorResponse | Error | undefined> {
    try {
      await firstValueFrom(this.restApi
        .one('companies', company.id)
        .patch(data))

      Object.assign(company, addressOmitter(data))

      if ('name' in data) {
        company.nameChanged()
      }

      if ('picture' in data) {
        company.pictureChanged()
      }

      if ('address' in data) {
        company.updateAddress(data.address)
      }

      if ('currency' in data) {
        this.changeLocale(company)
      }

      this.companyFacade.companyUpdated(company, data)
    } catch (ex) {
      if (ex instanceof HttpErrorResponse) {
        return ex
      } else {
        return new Error('Failed to update company')
      }
    }
  }

  // ----------------------------------------------------

  async updateCompanyLogo(company: Company, dataURI: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.fileTransferApi
        .one('companies', company.id)
        .upload<IUploadEntityPictureResponse>('logo.png', dataURI, 'uploadLogo'))

      company.picture = response.picture

      company.pictureChanged()

      this.companyFacade.companyUpdated(company, { picture: company.picture })

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  async addInvestorForEntity(company: Company, entity: Entity): Promise<Investor> {
    let investor: Investor = company.investors.getInvestorForEntity(entity)

    if (!investor) {
      investor = new Investor({ company, entity })

      await investor.save(this.restApi)
    }

    return investor
  }

  // ----------------------------------------------------

  getCompaniesMatchingNameFragment(name: string): Observable<Company[]> {
    return this.api
      .one('companies')
      .one('search')
      .get<ICompanyPublicExcerptData[]>('findByNameIgnoreCaseContaining', { q: name, limit: 10 })
      .pipe(
        map(companiesData => companiesData.map(companyData => this.globalsService.buildCompany(companyData))),
        catchError(() => of([]))
      )
  }

  // ----------------------------------------------------

  private async loadCompany(
    companyId: string
  ): Promise<Company> {
    // console.info(`CompanyService.loadCompany(id = %d): loading from API...`, companyId)

    // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(LoadCompany({ companyId }))

    try {
      const companyData = await firstValueFrom(this.restApi
        .one('companies', companyId)
        .get<ICompanyData>({ projection: 'inlineAppointmentsAndShareholders' }))

      // console.info(`CompanyService.loadCompany(id = %d): loaded from API, data = %o`, companyId, companyData)

      // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store.dispatch(LoadCompanySuccess({ companyId, response: companyData }))

      return this.globalsService.buildCompany(companyData)
    } catch (ex) {
      console.error(ex)
      if (ex instanceof HttpErrorResponse || ex instanceof Error) {
        this.store.dispatch(LoadCompanyError({ companyId, error: ex.message }))
      }

      return null
    } finally {
      this.companyLoadingMap.delete(companyId)
    }
  }

  // ----------------------------------------------------

  /**
   * Get data for a company matching the given ID, including all
   * of the appointments and shares information.
   */
  async getCompany(
    companyId: string,
    forceReload = false
  ): Promise<Company> {
    const currentUser: User = this.userService.currentUser

    if (!forceReload && !this.hasReadAccessForCompany(currentUser, companyId)) {
      return this.getCompanyExcerpt(companyId, false)
    }

    if (!forceReload) {
      const company = this.globalsService.companies.get(companyId)

      if (company && company.isFullModel) {
        return company
      }
    }

    let loadingPromise: Promise<Company> = this.companyLoadingMap.get(companyId)

    if (!loadingPromise) {
      loadingPromise = this.loadCompany(companyId)

      this.companyLoadingMap.set(companyId, loadingPromise)
    }

    return loadingPromise
  }

  // ----------------------------------------------------

  /**
   * Get data for a company matching the given ID, including all
   * of the appointments and shares information.
   */
  async getCompanyExcerpt(
    companyId: string,
    forceReload = false
  ): Promise<Company> {
    if (!forceReload) {
      const company = this.globalsService.companies.get(companyId)

      if (company) {
        return company
      }
    }

    try {
      const companyResponseData = await firstValueFrom(this.api
        .one('companies', companyId)
        .get<ICompanyPublicExcerptData>('public'))

      return this.globalsService.buildCompany(companyResponseData)
    } catch {
      return null
    }
  }

  // ----------------------------------------------------

  hasSeenWelcomePage(
    company: Company,
    key: WelcomePageKey
  ): boolean {
    return company.metadata.seenWelcomePages?.includes(key) ?? false
  }

  // ----------------------------------------------------

  async setHasSeenWelcomePage(
    company: Company,
    key: WelcomePageKey
  ): Promise<boolean> {
    company.metadata.seenWelcomePages = Array.isArray(company.metadata.seenWelcomePages)
      ? [ ...company.metadata.seenWelcomePages, key ]
      : [ key ]

    await firstValueFrom(this.restApi
      .one('companies', company.id)
      .patch({
        metadata: company.metadata
      }))

    return true
  }

  // ----------------------------------------------------

  async giveConsentToCompany(
    company: Company
  ): Promise<void> {
    try {
      await firstValueFrom(this.api
        .one('companies', company.id)
        .one('consent')
        .post())

      company.hasConsented = true
    } catch {}
  }

  // ----------------------------------------------------

  constructor(
    @Inject(RestApi) private restApi: BackendService,
    @Inject(Api) private api: BackendService,
    @Inject(FileTransferApi) private fileTransferApi: BackendService,
    private readonly configuration: Configuration,
    private readonly localeService: LocaleService,
    private readonly registeredCompanySearchService: RegisteredCompanySearchService,
    private readonly globalsService: GlobalsService,
    private readonly userService: UserService,
    private readonly companyFacade: CompanyFacade,
    private readonly store: Store,
    private readonly toastService: ToastService
  ) {}
}
