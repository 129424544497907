import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { dedentText } from '@libs/utils'

// ----------------------------------------------------------

@Pipe({
  name: 'slBlurb'
})
export class BlurbPipe implements PipeTransform {

  transform(
    blurb: string
  ): SafeHtml {
    const normalisedBlurb = dedentText(blurb)
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '')

    return this.sanitizer.bypassSecurityTrustHtml(normalisedBlurb)
  }

  // ------------------------------------------------

  constructor(
    private sanitizer: DomSanitizer
  ) {}

}
