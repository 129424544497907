<div fxLayout="column" class="gap-32">

  <!-- New Company -->
  <div *ngIf="allowNewCompany && companyName.length >= 3">
    <ng-container *ngTemplateOutlet="selectableCompany;
                    context: { $implicit: newCompanyMatch, selected: equals(newCompanyMatch, selectedCompany) }"></ng-container>
  </div>

  <!--Local Company-->
  <div *ngIf="localCompanies.length" fxLayout="column" class="gap-16">
    <div class="fadein" fxLayout="row gap-16" fxLayoutAlign="start center">
      <img src="assets/images/app/logo-24x24.png" alt="SeedLegals logo" i18n-alt>
      <span class="section-label" i18n>Already on SeedLegals</span>
    </div>

    <ng-container *ngFor="let c of localCompanies; let last = last">
      <ng-container *ngTemplateOutlet="preventExistingCompanies? localUnselectableCompany: selectableCompany;
                      context: { $implicit: c, last: last, selected: equals(c, selectedCompany) }">
      </ng-container>
    </ng-container>
  </div>

  <!--Remote Company-->
  <div *ngIf="displayRemoteCompanies" fxLayout="column" class="gap-16">
    <div class="fadein gap-16" fxLayout="row" fxLayoutAlign="start center">
      <img [src]="agencyInfo.logo" [alt]="agencyInfo.shortName + ' logo'" i18n-alt>
      <span class="section-label" i18n>On {{ agencyInfo.shortName }} ({{ remoteCompanies.length }})</span>
    </div>

    <ng-container *ngFor="let c of remoteCompanies; let last = last">
      <ng-container *ngTemplateOutlet="selectableCompany; context: { $implicit: c, selected: equals(c, selectedCompany) }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #selectableCompany let-company let-selected="selected">
  <button mat-button [ngClass]="selected? 'selected':''" (click)="onCompanySelected(company)">
    <div class="pv-16 fadein" fxLayout="row" fxLayoutAlign="space-between center">
      <sl-avatar-with-company-details [companyMatch]="company"></sl-avatar-with-company-details>
      <img *ngIf="selected" src="/assets/images/app/home/company-selected.svg" alt="Selected Company">
    </div>
  </button>
</ng-template>

<ng-template #localUnselectableCompany let-company let-last="last">
  <div class="fadein" fxLayout="row" fxLayoutAlign="space-between center">
    <sl-avatar-with-company-details [companyMatch]="company"></sl-avatar-with-company-details>

    <div *ngIf="company.canNotify; else alreadyNotified" fxLayout="column" class="gap-8" fxLayoutAlign="center center">
      <span class="font-size-12 text-light-grey" i18n>Is this your company?</span>
      <button (click)="notifyAdminCompanyDuplicate.emit(company)" mat-button class="chip-button light" color="primary">
        <mat-icon fontIcon="icon-email-send-outline"></mat-icon>
        <span i18n>Request access</span>
      </button>
    </div>

    <ng-template #alreadyNotified>
      <div fxLayout="row" fxLayoutAlign="center center" class="gap-8">
        <mat-icon fontIcon="icon-check"></mat-icon>
        <span i18n>We've notified the company admins</span>
      </div>
    </ng-template>
  </div>
  <mat-divider *ngIf="!last || displayRemoteCompanies"></mat-divider>
</ng-template>
