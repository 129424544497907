import { createReducer, on } from '@ngrx/store'
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity'

import { ICompanyData } from '@libs/models'
import { IInvoice } from '../models/invoice'

import {
  LoadCompany,
  LoadCompanySuccess,
  LoadCompanyError,
  LoadCompanyInvoicesSuccess,
  LoadCompanyInvoicesError,
  LoadCompanyInvoices,
  UpdateCompanyBankAccountSuccess,
  UpdateCompanyBankAccountError,
  UpdateCompanyBankAccount
} from './company.actions'

// ----------------------------------------------------------

export const COMPANIES_FEATURE_KEY = 'companies'

// ----------------------------------------------------

export interface CompaniesState extends EntityState<ICompanyData> {
  loaded: boolean
  loading: boolean
  error?: unknown
  invoices: IInvoice[]
}

// ----------------------------------------------------------

export const companiesAdapter: EntityAdapter<ICompanyData> = createEntityAdapter<ICompanyData>()

// ----------------------------------------------------------

export const companiesInitialState: CompaniesState = companiesAdapter.getInitialState({
  loading: false,
  loaded: false,
  invoices: [],
})

// ----------------------------------------------------

export const companiesReducer = createReducer(
  companiesInitialState,

  on(LoadCompany, LoadCompanyInvoices, UpdateCompanyBankAccount, (state): CompaniesState => {
    return { ...state, loading: true, loaded: false, error: undefined }
  }),

  on(LoadCompanySuccess, (state, { response }): CompaniesState => {
    return companiesAdapter.addOne(response,
      { ...state, loading: false, loaded: true }
    )
  }),

  on(LoadCompanyInvoicesSuccess, (state, { invoices }): CompaniesState => {
    return { ...state, loading: false, loaded: true, invoices }
  }),

  on(UpdateCompanyBankAccountSuccess, (state): CompaniesState => {
    return { ...state, loading: false, loaded: true }
  }),

  on(LoadCompanyError, LoadCompanyInvoicesError, UpdateCompanyBankAccountError, (state, { error }): CompaniesState => {
    return { ...state, loading: false, error }
  }),

)
