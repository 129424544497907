<mat-select [(ngModel)]="value"
            [disabled]="disabled"
            (openedChange)="selectOpened = true"
            [placeholder]="allowOther ? 'Other' : null"
            (ngModelChange)="onChanged($event)"
            panelClass="tall">

  <mat-select-trigger>
    <ng-container *ngTemplateOutlet="jurisdictionItem; context: { $implicit: selectedJurisdiction }"></ng-container>
  </mat-select-trigger>

  <ng-container *ngIf="selectOpened; else dummyOption">
    <mat-optgroup *ngIf="completeJurisdictions.length > 0" label="SeedLegals Complete" i18n-label>
      <ng-container *ngFor="let item of completeJurisdictions; let last = last">
        <mat-option [value]="item.code" [disabled]="item.disabled">
          <ng-container *ngTemplateOutlet="jurisdictionItem; context: { $implicit: item.code, $disabled: item.disabled }"></ng-container>
        </mat-option>
        <mat-divider *ngIf="last"></mat-divider>
      </ng-container>
    </mat-optgroup>

    <mat-optgroup *ngIf="globalJurisdictions.length > 0" label="SeedLegals Global" i18n-label>
      <ng-container *ngFor="let item of globalJurisdictions">
        <mat-option [value]="item.code" [disabled]="item.disabled">
          <ng-container *ngTemplateOutlet="jurisdictionItem; context: { $implicit: item.code, $disabled: item.disabled }"></ng-container>
        </mat-option>
      </ng-container>
      <mat-option *ngIf="allowOther">
        <ng-container *ngTemplateOutlet="jurisdictionItem; context: { $implicit: undefined, $disabled: false }"></ng-container>
      </mat-option>
    </mat-optgroup>
  </ng-container>

  <ng-template #dummyOption>
    <mat-option [value]="selectedJurisdiction">
      <ng-container *ngTemplateOutlet="jurisdictionItem; context: { $implicit: selectedJurisdiction }"></ng-container>
    </mat-option>

    <mat-option disabled>
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-spinner [diameter]="16"></mat-spinner>
      </div>
    </mat-option>
  </ng-template>
</mat-select>

<ng-template #jurisdictionItem let-jurisdiction let-disabled="disabled">
  <div fxLayout="row" fxLayoutAlign="start center" class="gap-8">
    <div *ngIf="jurisdiction" class="flag" [class.disabled]="disabled" [ngClass]="countryCode(jurisdiction)"></div>
    <span>{{ jurisdiction | slJurisdiction }}</span>
  </div>
</ng-template>
