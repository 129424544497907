<form [formGroup]="contactForm"
      #formDirective="ngForm">
  <ng-container *ngIf="user?.connected">
    <sl-user-info [user]="user"></sl-user-info>
  </ng-container>

  <div *ngIf="!user?.connected"
       fxLayout="column"
       class="gap-16">

    <mat-form-field>
      <mat-label i18n>Email</mat-label>

      <input (focusout)="onFocusOut()"
             [disabled]="user"
             matInput
             type="email"
             formControlName="email">
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label i18n>First name</mat-label>

      <input matInput
             formControlName="firstName"
             required>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label i18n>Last name</mat-label>

      <input matInput
             formControlName="lastName"
             required>
      <mat-hint i18n>Enter their full legal name, no nicknames or shortened versions</mat-hint>
    </mat-form-field>

  </div>

  <div fxLayout="row" fxLayoutAlign="center">
    <button mat-stroked-button
            class="round-button"
            color="primary"
            (click)="onAddClicked()"
            [disabled]="contactForm.invalid"
            i18n>
      Add
    </button>
  </div>
</form>
