import { EventCategories, EventCategory } from '../../models/category.model'
import { DocumentEventAdapter } from './document-event-adapters'
import { Products } from '../money'
import type { Investor } from '../stock'
import type { ApiFieldSpec } from '../model'

// ------------------------------------------------------------

export class SeedSaftEvent extends DocumentEventAdapter {
  readonly domain = 'seedSafts'

  readonly category = EventCategory.SeedSAFT
  readonly product = Products.SeedSAFT

  investor: Investor
  amount: number

  constructor({
    amount = null,
    investor = null,
    ...data
  }) {
    super({
      amount,
      investor,
      ...data
    })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'amount',
      'investor'
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'raise', 'seed-safts', this.id ]
    }
  }

  // ----------------------------------------------------

  override get safeName() {
    const eventName = EventCategories[ this.category ]
    if (this.investor) {
      return `${eventName} - ${this.investor.name}`
    }

    return eventName
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.amount
  }
}
