import { ValidatorFn, Validators } from '@angular/forms'

import { CountryCode } from '@libs/models'
import { mergeDeep } from '@libs/utils'

// ----------------------------------------------------------

export enum BankAccountType {
  IBAN,
  UK,
  USA,
  Canada,
  Singapore,
  HongKong,
  India,
}

// ----------------------------------------------------------

export const BankAccountTypesByCountry = new Map<BankAccountType, Set<CountryCode>>([
  [
    BankAccountType.UK,
    new Set([ 'GB' ]),
  ],
  [
    BankAccountType.USA,
    new Set([ 'US' ]),
  ],
  [
    BankAccountType.Canada,
    new Set([ 'CA' ]),
  ],
  [
    BankAccountType.Singapore,
    new Set([ 'SG' ]),
  ],
  [
    BankAccountType.HongKong,
    new Set([ 'HK' ]),
  ],
  [
    BankAccountType.India,
    new Set([ 'IN' ]),
  ],
])

// ----------------------------------------------------------

export function getBankAccountTypeForCountry(
  country: CountryCode,
): BankAccountType {

  for (const [ accountType, countries ] of BankAccountTypesByCountry.entries()) {
    if (countries.has(country)) {
      return accountType
    }
  }

  return BankAccountType.IBAN
}

// ----------------------------------------------------------

export interface BankAccountField {
  field: string
  label: string
  hint?: string
  companyHint?: string
  required?: boolean
  validators?: ValidatorFn[]
}

// ----------------------------------------------------------

const swiftCode = {
  field: 'swiftCode',
  label: $localize`SWIFT Code`,
  companyHint: $localize`If you have international investors, enter your account SWIFT code to help them transfer their funds to you. Leave blank if not needed.`,
  validators: [ Validators.minLength(8), Validators.maxLength(11) ],
}

const iban = {
  field: 'iban',
  label: $localize`Bank Account IBAN`,
  companyHint: $localize`If you have international investors, enter your account IBAN to help them transfer their funds to you. Leave blank if not needed.`,
  validators: [ Validators.maxLength(34) ],
}

// ----------------------------------------------------------

const BankAccountFieldsByAccountType = new Map<BankAccountType, BankAccountField[]>([
  [
    BankAccountType.IBAN,
    [
      {
        field: 'holder',
        label: $localize`Account Holder`,
        validators: [ Validators.maxLength(100) ],
      },
      {
        field: 'address',
        label: $localize`Bank Address`,
        validators: [ Validators.maxLength(150) ],
      },
      mergeDeep(swiftCode, { required: true, validators: [ Validators.required ] }),
      mergeDeep(iban, { required: true, validators: [ Validators.required ] }),
    ],
  ],
  [
    BankAccountType.UK,
    [
      {
        field: 'sortCode',
        label: $localize`Sort Code`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(8) ],
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(8) ],
      },
      swiftCode,
      iban,
    ],
  ],
  [
    BankAccountType.USA,
    [
      {
        field: 'address',
        label: $localize`Bank Name`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(150) ],
      },
      {
        field: 'sortCode',
        label: $localize`Routing Number`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(9) ],
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(16) ],
      },
      swiftCode,
      iban,
    ],
  ],
  [
    BankAccountType.Canada,
    [
      {
        field: 'address',
        label: $localize`Bank Name`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(150) ],
      },
      {
        field: 'bankCode',
        label: $localize`Institution Number`,
        required: true,
        validators: [ Validators.required, Validators.pattern(`\\d{3}`) ],
      },
      {
        field: 'sortCode',
        label: $localize`Transit Number`,
        required: true,
        validators: [ Validators.required, Validators.pattern(`\\d{5}`) ],
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.pattern(`\\d{5,12}`) ],
      },
      swiftCode,
      iban,
    ],
  ],
  [
    BankAccountType.Singapore,
    [
      {
        field: 'address',
        label: $localize`Bank Name`,
        validators: [ Validators.maxLength(150) ],
      },
      {
        field: 'bankCode',
        label: $localize`Bank Code`,
        required: true,
        validators: [ Validators.required, Validators.pattern(`\\d{4}`) ],
        hint: '4 digits'
      },
      {
        field: 'sortCode',
        label: $localize`Branch Code`,
        validators: [ Validators.pattern(`\\d{3}`) ],
        hint: $localize`3 digits`
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.minLength(9), Validators.maxLength(12) ],
      },
      swiftCode,
      iban,
    ],
  ],
  [
    BankAccountType.HongKong,
    [
      {
        field: 'address',
        label: $localize`Bank Name`,
        validators: [ Validators.maxLength(150) ],
      },
      {
        field: 'bankCode',
        label: $localize`Bank Code`,
        required: true,
        validators: [ Validators.required, Validators.pattern(`\\d{4}`) ],
        hint: '4 digits'
      },
      {
        field: 'sortCode',
        label: $localize`Branch Code`,
        required: true,
        validators: [ Validators.required, Validators.pattern('\\d{3}') ],
        hint: $localize`Usually 3 digits`
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.minLength(6), Validators.maxLength(9) ],
        hint: $localize`Usually 6 or 9 digits`
      },
      swiftCode,
      iban,
    ],
  ],
  [
    BankAccountType.India,
    [
      {
        field: 'address',
        label: $localize`Bank Name`,
        validators: [ Validators.maxLength(150) ],
      },
      {
        field: 'sortCode',
        label: $localize`MICR`,
        required: true,
        validators: [ Validators.required, Validators.maxLength(9) ],
      },
      {
        field: 'accountNumber',
        label: $localize`Account Number`,
        required: true,
        validators: [ Validators.required, Validators.minLength(9), Validators.maxLength(18) ],
      },
      swiftCode,
      iban,
    ],
  ],
])

// ----------------------------------------------------------

export function getBankAccountFields(
  country: CountryCode,
): BankAccountField[] {
  return BankAccountFieldsByAccountType.get(getBankAccountTypeForCountry(country))
}
