import { Injectable } from '@angular/core'

import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data'

import { Card } from '../models/cards'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CardService extends EntityCollectionServiceBase<Card> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Card', serviceElementsFactory)
  }
}
