import { Component, Output, EventEmitter, OnChanges, Input } from '@angular/core'
import { UntypedFormBuilder, Validators, UntypedFormGroup, Validator } from '@angular/forms'

import { pick } from 'ramda'

import { CountryCode, IAddressData } from '@libs/models'

import { FormControlComponent, getValueProvider, getValidatorProvider } from '@libs/utils'

// ----------------------------------------------------------

const DEFAULT_COUNTRY = 'GB'

// ----------------------------------------------------------

const addressPicker = pick([
  'line1',
  'line2',
  'city',
  'postcode',
  'country'
])

// ----------------------------------------------------------

@Component({
  selector: 'sl-address-editor',
  templateUrl: './address-editor.component.html',
  providers: [
    getValueProvider(AddressEditorComponent),
    getValidatorProvider(AddressEditorComponent)
  ]
})
export class AddressEditorComponent extends FormControlComponent<IAddressData | null> implements OnChanges, Validator {
  @Input() required = false
  @Input() abridged = false
  @Input() inSidenav = false
  @Input() defaultCountry?: CountryCode


  @Output() changed = new EventEmitter<IAddressData | null>()

  // ----------------------------------------------------------

  constructor(fb: UntypedFormBuilder) {
    super(fb)
  }

  // ----------------------------------------------------------

  ngOnChanges(changes) {
    if (changes.required) {
      for (const k of Object.keys(this.controls.controls)) {
        const ctrl = this.controls.get(k)

        const validators = this.required && k !== 'line2'
          ? [ Validators.required ]
          : []

        if (k === 'postcode') {
          validators.push(Validators.maxLength(8))
        }

        ctrl.setValidators(validators)
      }

      this.controls.updateValueAndValidity()
    }
  }

  // ----------------------------------------------------------

  getControlGroup(): UntypedFormGroup {
    return this.fb.group({
      line1: [ '' ],
      line2: [ '' ],
      city: [ '' ],
      postcode: [
        '',
        [
          Validators.maxLength(8)
        ]
      ],
      country: [
        DEFAULT_COUNTRY,
        Validators.required
      ]
    })
  }

  // ----------------------------------------------------

  validate() {
    if (this.required && this.controls.status === 'INVALID') {
      return {
        required: true
      }
    }

    return null
  }

  // ----------------------------------------------------

  override changeValue(modelValue: IAddressData | null): void {
    super.changeValue(modelValue)
    this.changed.emit(modelValue)
  }

  // ----------------------------------------------------

  override convertFormDataToModelData(formValue: unknown): IAddressData {
    const data = super.convertFormDataToModelData(formValue)

    return {
      ...data,
      line2: data.line2 || null
    }
  }

  // ----------------------------------------------------

  override convertModelDataToFormData(value: IAddressData | null): unknown {
    if (value) {
      const formData = addressPicker(value)

      return {
        ...formData,
        line2: formData.line2 || ''
      }
    } else {
      return {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
        country: this.defaultCountry || DEFAULT_COUNTRY,
      }
    }
  }

  // ----------------------------------------------------

  get postcodeCtrl() { return this.controls.get('postcode') }
}
