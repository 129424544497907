import { MatDateFormats } from '@angular/material/core'

export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: null,
  },
  display: {
    dateInput: 'dd/MM/y',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'd MMMM yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  }
}
