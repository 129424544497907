import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: [ './icon-button.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {

  @Input() icon: string
  @Input() disabled = false
  @Input() completed = false

  @Output() clicked = new EventEmitter<MouseEvent>()

  // ----------------------------------------------------

  get fontIcon() {
    return `icon-${this.icon}`
  }

  // ----------------------------------------------------

  onClicked($event: MouseEvent) {
    this.clicked.emit($event)
  }

}
