import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import type { Company, Investor, User } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-avatar-list',
  templateUrl: './avatar-list.component.html',
  styleUrls: [ './avatar-list.component.scss' ]
})
export class AvatarListComponent implements OnInit {

  @Input() entities: Array<Company | User | Investor>
  @Input() max = 15 // Display maximum 15 at a time by default
  @Input() size = 40
  @Input() showName = false
  @Input() showAdd = false
  @Input() addRouterLink?: string
  @Input() addLabel?: string
  @Input() overlapAmount = 0

  @Output() entityClick = new EventEmitter<Company | User | Investor>()

  // --------------------------------------------------

  slicedEntities: Array<Company | User | Investor>
  entitiesRemaining: number
  overlapWidth: number

  // --------------------------------------------------

  ngOnInit() {
    this.slicedEntities = this.entities.slice(0, this.max)
    this.entitiesRemaining = this.entities.length - this.slicedEntities.length
    this.overlapWidth = this.size * (1 - this.overlapAmount)
  }

}
