import { Component, ChangeDetectionStrategy, Input } from '@angular/core'

import { Message } from '../../services/notification/message'
import { NotificationPanelOverlayRef } from '../notification-panel/notification-panel-overlay-ref'

// ----------------------------------------------------------

@Component({
  selector: 'sl-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: [ './notification-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemComponent {
  @Input() notification: Message

  constructor(private readonly panelRef: NotificationPanelOverlayRef) {}

  onNotificationClicked(note: Message) {
    note.onClick()

    this.panelRef.close()
  }
}
