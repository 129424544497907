import { Inject, Injectable, Optional } from '@angular/core'

import { Observable, of } from 'rxjs'
import { filter, skipUntil, switchMap, tap } from 'rxjs/operators'

import { ComponentStore, tapResponse } from '@ngrx/component-store'

import { StoreInitialState, StoreQuery, StoreQueryService } from './store-query'

// ----------------------------------------------------------

export interface StoreState<Data> {
  ready: boolean
  loading: boolean
  loaded: boolean
  error?: unknown
  data?: Data
}

// ----------------------------------------------------------

@Injectable()
export class StoreService<Data, QueryVars extends object = object> extends ComponentStore<StoreState<Data>> {

  readonly ready$ = this.select(state => state.ready)
  readonly loaded$ = this.select(state => state.loaded)
  readonly loading$ = this.select(state => state.loading)
  readonly error$ = this.select(state => state.error)

  readonly data$ = this.select(state => state.data).pipe(
    skipUntil(this.ready$.pipe(filter(r => r)))
  )

  // ----------------------------------------------------

  readonly reset = this.updater((): StoreState<Data> => ({
    ready: false,
    loading: false,
    loaded: false
  }))

  // ----------------------------------------------------

  readonly loadFromQuery = this.effect((props$: Observable<QueryVars>) => props$.pipe(
    tap(() => {
      this.patchState({
        loading: true,
        loaded: false,
        error: undefined
      })

      // _log(`${this.constructor.name}.loadFromQuery(variables): this.get()`, variables, clone(this.get()), this)
    }),
    switchMap(variables => this.loadData(variables).pipe(
      tapResponse(
        data => {
          // _log(`${this.constructor.name}.loadFromQuery(variables): data, this.get()`, variables, data, clone(this.get()))

          this.patchState({
            ready: true,
            loading: false,
            loaded: true,
            data
          })

          // _log(`${this.constructor.name}.loadFromQuery: this.get()`, clone(this.get()))
        },
        error => {
          this.patchState({
            loading: false,
            error
          })
        }
      )
    ))
  ))

  // ----------------------------------------------------

  readonly updateFromQuery = this.effect((props$: Observable<QueryVars>) => props$.pipe(
    tap(() => {
      this.patchState({
        loading: true,
        loaded: false,
        error: undefined
      })

      // _log(`${this.constructor.name}.updateFromQuery(variables): this.get()`, variables, clone(this.get()), this)
    }),
    switchMap(variables => this.loadData(variables).pipe(
      tapResponse(
        data => {
          // _log(`${this.constructor.name}.updateFromQuery(variables): data, this.get()`, variables, data, clone(this.get()))

          this.patchState(state => ({
            ready: true,
            loading: false,
            loaded: true,
            data: {
              ...state.data,
              ...data
            },
          }))

          // _log(`${this.constructor.name}.updateFromQuery: this.get()`, clone(this.get()))
        },
        error => {
          this.patchState({
            loading: false,
            error
          })
        }
      )
    ))
  ))

  // ----------------------------------------------------

  protected loadData(
    variables: QueryVars
  ): Observable<Data> {
    return this.queryService?.load(variables) ?? of({} as Data)
  }

  // ----------------------------------------------------

  constructor(
    @Optional() @Inject(StoreQuery) protected queryService?: StoreQueryService<Data, QueryVars>,
    @Optional() @Inject(StoreInitialState) data?: Data,
  ) {
    super({
      ready: false,
      loading: false,
      loaded: false,
      data,
    })
  }

}
