import { Component, Input, EventEmitter, OnChanges, Output } from '@angular/core'
import { PitchInterest } from '@app/pitch/models/pitch.model'

import { Company, Entity, User } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-entity-info-card',
  templateUrl: './entity-info-card.component.html',
  styleUrls: [ './entity-info-card.component.scss' ]
})
export class EntityInfoCardComponent implements OnChanges {
  @Input() entity?: Entity
  @Input() pitchInterest?: PitchInterest
  @Input() actionIcon: string
  @Input() actionDisabled: boolean
  @Input() showAddress = true

  @Output() actionClicked = new EventEmitter<Entity>()

  entityReference: string
  isConnected = false

  // ------------------------------------------------

  ngOnChanges(): void {
    if (this.entity instanceof User) {
      this.entityReference = this.entity.email
      this.isConnected = this.entity.connected
    } else if (this.entity instanceof Company) {
      this.entityReference = this.entity.companiesHouseNumber || this.entity.sirenCode
    } else {
      this.entityReference = ''
    }

    if (this.pitchInterest) {
      this.entity = this.pitchInterest as any as Entity
      this.entityReference = this.pitchInterest.companyName
        ? `${ this.pitchInterest.firstName } ${ this.pitchInterest.lastName } • ${ this.pitchInterest.email }`
        : `${ this.pitchInterest.email }`
    }
  }
}
