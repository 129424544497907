import { Pipe, PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'addUrlScheme'
})
export class AddUrlSchemePipe implements PipeTransform {

  transform(url: string): string {
    if (url) {
      return url.startsWith('http://') || url.startsWith('https://')
        ? url
        : 'https://' + url
    }
  }

}
