import { Event, type IEventViewState } from './event'
import { type Round, RoundCollection } from './round'
import { RoundType } from './round-type'
import type { Share } from '../stock/share'
import { EventCategory } from '../../models'
import { asDate, isSameOrBefore } from '@libs/utils'
import type { ShareAllotmentReturnEvent } from './share-allotment-return-event'

// ----------------------------------------------------

export class InstantInvestmentConsentEvent extends Event {
  readonly domain = 'instantInvestmentConsents'
  readonly domainSingular: string = 'instantInvestmentConsent'
  readonly category: EventCategory = EventCategory.InstantInvestment
  readonly workflowKey = 'instant-investment-consent'

  target: number
  pricePerShare: number

  instantInvestments = new RoundCollection()

  // ----------------------------------------------------

  constructor({
    company,
    name = $localize`Instant Investment Group`,
    pricePerShare = null,
    target = 0,
    ...data
  }) {
    super({
      company,
      name,
      target,
      pricePerShare,
      ...data
    })
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.target
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'effectiveDate',
      'pricePerShare',
      'target',
      'revision'
    ]
  }

  // ----------------------------------------------------

  getSharesIssued(): Share[] {
    return this.instantInvestments.items().reduce((out: Share[], ii) => {
      return [ ...out, ...ii.getSharesIssuedFromEvent() ]
    }, [])
  }

  // ----------------------------------------------------

  getShareAllotmentReturns(): ShareAllotmentReturnEvent[] {
    const allDates = this.getSharesIssued().map(s => s.issued)
    const shareAllotmentReturns = this.company.shareAllotmentReturns
    return shareAllotmentReturns.getByMultipleDates(allDates)
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return { state: [ '/companies', this.company.id, 'raise', 'instant-investments', this.id ] }
  }

  // ----------------------------------------------------

  get previousRoundWithDisclosures(): Round | null {
    const rounds = this.company.closedRounds
      .filter(round => {
        return round.type === RoundType.Seed && round.effectiveDate < this.effectiveDate
      })

    return rounds.length
      ? rounds[ 0 ]
      : null
  }

  // ----------------------------------------------------

  get hasDisclosureForNoMgmtAccounts(): boolean {
    const reasonNot = this.previousRoundWithDisclosures?.answers['mgmtAccounts']?.['reasonNot']
    return reasonNot && reasonNot !== ''
  }

  // ----------------------------------------------------

  get hasInvestmentProposals(): boolean {
    return !!this.answers['proposal']?.['display']
  }

  // ----------------------------------------------------

  hasEISBeforeOrOn(date: string, instantInvestment?: Round): boolean {
    return this.instantInvestments.filter(ii => {
      return ii.id !== instantInvestment?.id && isSameOrBefore(asDate(ii.effectiveDate), asDate(date))
    }).some(ii => ii.hasEIS)
  }
}
