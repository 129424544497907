import { IAddressData } from '../../models/address.model'
import { EventCategory, EventCategories } from '../../models/category.model'
import { DocumentEventAdapter } from './document-event-adapters'
import { User } from '../user'
import { BoardMeetingEvent } from './board-meeting-event'
import { ApiFieldSpec } from '../model'

// ------------------------------------------------------------

export abstract class BaseDirectorshipEvent<A extends object = object> extends DocumentEventAdapter<A> {
  abstract director: User

  // ----------------------------------------------------

  override get safeName() {
    return EventCategories[ this.category ] + ' — ' + this.director.name
  }
}

// ------------------------------------------------------------

export interface IDirectorshipAnswers {
  director: {
    birthDate: string
    formerNames: string
    nationality: string
    occupation: string
  }
  appointmentDetails: {
    fundingRoundAppointment: boolean
    appointmentPermission?: 'shareholders' | 'board' | 'founders' | 'investorMajority' | 'specificInvestor'
  }
}

// ------------------------------------------------------------

export class DirectorshipEvent extends BaseDirectorshipEvent<IDirectorshipAnswers> {
  readonly domain = 'directorships'
  readonly category = EventCategory.DirectorAppointment

  director: User
  boardMeeting: BoardMeetingEvent | null = null

  // ----------------------------------------------------

  constructor({
    director,
    boardMeeting = null,
    ...data
  }) {
    super(data)

    this.director = director
    this.boardMeeting = boardMeeting
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'director', include: 'create' },
      'boardMeeting'
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'board', 'director-appointments', this.id ]
    }
  }

  // ----------------------------------------------------

  getFundingRoundAppointment(): boolean {
    return this.answers.appointmentDetails?.fundingRoundAppointment ?? true
  }

  // ----------------------------------------------------

  getAppointmentPermission(): string {
    return this.answers.appointmentDetails?.appointmentPermission ?? 'none'
  }
}

// ------------------------------------------------------------

export interface IDirectorshipVariationAnswers {
  director: {
    address?: IAddressData
    name?: {
      firstName: string
      lastName: string
    }
    nationality?: string
    occupation?: string
  }
}

// ------------------------------------------------------------

export class DirectorshipVariationEvent extends BaseDirectorshipEvent<IDirectorshipVariationAnswers> {
  readonly domain = 'directorshipVariations'
  readonly category = EventCategory.DirectorDetailUpdate

  directorship: DirectorshipEvent

  // ----------------------------------------------------

  constructor({
    directorship,
    ...data
  }) {
    super(data)

    this.directorship = directorship
  }

  // ----------------------------------------------------

  public get director() {
    return this.directorship.director
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'directorship', include: 'create' }
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'board', 'director-detail-updates', this.id ]
    }
  }
}

// ------------------------------------------------------------

export interface IDirectorshipTerminationAnswers {
  terminationDetails: {
    modelArticles: boolean
    terminationPermission: 'shareholders' | 'director' | 'founders' | 'investorMajority' | 'specificInvestor'
  }
}

// ------------------------------------------------------------

export class DirectorshipTerminationEvent extends BaseDirectorshipEvent<IDirectorshipTerminationAnswers> {
  readonly domain = 'directorshipTerminations'
  readonly category = EventCategory.DirectorTermination

  directorship: DirectorshipEvent
  boardMeeting: BoardMeetingEvent | null = null

  // ----------------------------------------------------

  constructor({
    directorship,
    boardMeeting = null,
    ...data
  }) {
    super(data)

    this.directorship = directorship
    this.boardMeeting = boardMeeting
  }

  // ----------------------------------------------------

  public get director() {
    return this.directorship.director
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'directorship', include: 'create' },
      'boardMeeting'
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'board', 'director-terminations', this.id ]
    }
  }

  // ----------------------------------------------------

  getModelArticles(): boolean {
    return this.answers.terminationDetails?.modelArticles ?? false
  }

  // ----------------------------------------------------

  getTerminationPermission(): string {
    return this.answers.terminationDetails?.terminationPermission ?? 'none'
  }
}
