import {
  addDays,
  differenceInDays,
  formatDistance,
  isBefore
} from 'date-fns'

import { today } from '@libs/utils'

import { Company } from '../company'
import { BillingPeriod, PlanId } from '../../models/plan.model'
import { Plan } from './plan'
import { ProductId } from './product'

// ------------------------------------------------------------

export type SubscriptionStatus =
  | 'FREE_TRIAL'
  | 'SUBSCRIBED'
  | 'CANCELLED'
  | 'ADMIN_FREEBIE'

export enum SubscriptionStatuses {
  FreeTrial = 'FREE_TRIAL',
  Subscribed = 'SUBSCRIBED',
  Cancelled = 'CANCELLED',
  AdminFreebie = 'ADMIN_FREEBIE'
}

// ----------------------------------------------------------

export type SubscriptionMap = {
  [ key in PlanId ]?: ISubscriptionData
}

export interface ISubscriptionData {
  expires: string
  status: SubscriptionStatus
  billingPeriod: BillingPeriod
}

// ------------------------------------------------------------

export const UNSUBSCRIBE_FEEDBACK_OPTIONS = [
  { key: 'lowUsage', label:  $localize`Not used often enough to justify costs` },
  { key: 'techIssue', label:  $localize`Technical issues` },
  { key: 'expensive', label:  $localize`Too expensive` },
  { key: 'switching', label: $localize`Switching to another company` },
  { key: 'shutDown', label:  $localize`Shutting the business down` },
  { key: 'onlyForRound', label:  $localize`Only needed to support my funding round` },
  { key: 'platformUsability', label:  $localize`Not sure how to use the platform` },
  { key: 'missingFeature', label:  $localize`Missing a feature I need` },
  { key: 'other', label:  $localize`Something else` }
]

// ------------------------------------------------------------

export class Subscription {
  constructor(
    public company: Company,
    public plan: Plan,
    public status: SubscriptionStatus,
    public expires: Date | null = null,
    public billingPeriod: BillingPeriod = null
  ) {
    if (this.status !== SubscriptionStatuses.AdminFreebie && this.status !== SubscriptionStatuses.Subscribed) {
      if (!this.expires) {
        throw new Error(`Subscription of type ${this.status} cannot be created without an expiry date`)
      }
    }
  }

  // ----------------------------------------------------

  setAsSubscribed() {
    this.status = SubscriptionStatuses.Subscribed
    this.expires = null
  }

  // ----------------------------------------------------

  setAsAdminFreebie() {
    this.status = SubscriptionStatuses.AdminFreebie
    this.expires = null
  }

  // ----------------------------------------------------

  setAsCancelled(expiry?: string) {
    this.status = SubscriptionStatuses.Cancelled
    this.expires = expiry ? new Date(expiry) : new Date()
  }

  // ----------------------------------------------------

  setBillingPeriod(billingPeriod: BillingPeriod) {
    this.billingPeriod = billingPeriod
  }

  // ----------------------------------------------------

  extendExpiry(days: number) {
    if (!this.expires) {
      throw new Error(`Cannot extend a subscription without an expiry date`)
    }

    this.expires = addDays(this.expires, days)
  }

  // ----------------------------------------------------

  // True if currently subscribed
  get isPaid(): boolean {
    return this.status === SubscriptionStatuses.Subscribed || this.status === SubscriptionStatuses.AdminFreebie
  }

  // ----------------------------------------------------

  /**
   * True if Seedlegals Admin subscribed to a plan for free
   */
   get isAdminFreebie(): boolean {
    return this.status === SubscriptionStatuses.AdminFreebie
  }

  // ----------------------------------------------------

  /**
   * True if paid for and then cancelled - may still be active
   * due to remainder of final month's subscription
   */
  get isCancelled(): boolean {
    return this.status === SubscriptionStatuses.Cancelled
  }

  // ----------------------------------------------------

  /**
   * True if paid for and then cancelled, but still active
   * due to remainder of final month's subscription
   */
  get isInCancellationPeriod(): boolean {
    return this.status === SubscriptionStatuses.Cancelled && isBefore(new Date(), this.expires)
  }

  // ----------------------------------------------------

  /**
   * A subscription is active if any of these are true
   *
   *   a) a free trial has been activated and not yet expired
   *   b) there is an active subscription
   *   c) a subscription has been cancelled but they are
   *      within the remainder of the last month purchased
   */
  get isActive(): boolean {
    if (this.isPaid) {
      return true
    }

    return isBefore(new Date(), this.expires)
  }

  // ----------------------------------------------------

  // True if no active or cancelled subscription, and this plan has a free trial and we're not past the trial's expiry date
  get isFreeTrial(): boolean {
    return this.status === SubscriptionStatuses.FreeTrial && isBefore(new Date(), this.expires)
  }

  // ----------------------------------------------------

  get isExpiredTrial(): boolean {
    return this.status === SubscriptionStatuses.FreeTrial && !isBefore(new Date(), this.expires)
  }

  // ----------------------------------------------------

  get daysRemaining(): number {
    if (!this.expires) {
      return NaN
    } else if (!isBefore(new Date(), this.expires)) {
      return 0
    }

    return differenceInDays(this.expires, today())
  }

  // ----------------------------------------------------

  get fromNow(): string | null {
    return this.expires
      ? formatDistance(this.expires, today(), { addSuffix: true })
      : null
  }

  // ----------------------------------------------------

  get statusLabel(): string {
    if (this.status === SubscriptionStatuses.FreeTrial) {
      return $localize`Free trial`
    } else if (this.status === SubscriptionStatuses.Cancelled) {
      return $localize`Cancelled`
    } else if (this.status === SubscriptionStatuses.Subscribed) {
      return $localize`Subscribed`
    } else if (this.status === SubscriptionStatuses.AdminFreebie) {
      return $localize`Admin freebie`
    }
  }
}

// ------------------------------------------------------------

export const enum CouponResponse {
  Ok,
  InvalidCode,
  AlreadyApplied,
  NoFreeTrial,
  OtherCodeApplied,
  Unauthorized
}

export interface Coupon {
  id: string
  type: 'product_discount' | 'subscription_discount' | 'free_trial'
  freeTrialExpiries?: Record<PlanId, string>
  months?: number
  discounts?: Discount[]
}

export interface Discount {
  type?: 'flat' | 'percentage'
  currency?: string
  amount?: number
  discountPercent?: number
  plans?: string[]
  products?: ProductId[]
  billingPeriod?: BillingPeriod
  numPeriods?: number
}
