import type { IEventViewState } from './event'
import { Event } from './event'
import type { EntityDomain } from '../../models'
import { EventCategory } from '../../models'
import type { ApiFieldSpec } from '../model'

// ---------------------------------------------------

export class OptionPoolEvent extends Event {

  readonly category: EventCategory = EventCategory.OptionPool
  readonly domain: EntityDomain = 'optionPools'

  count: number
  historical: boolean

  // ---------------------------------------------------

  override get safeName(): string {
    return this.isCreationEvent()
      ? $localize`Option pool creation`
      : $localize`Option pool update`
  }

  // ---------------------------------------------------

  get estimatedOptionCount(): number {
    return this.count
  }

  // ---------------------------------------------------

  constructor({ name = '',
    count = 1,
    historical = false,
    ...data
  }) {
    super({ name, count, historical, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'count',
      'historical'
    ]
  }

  // ---------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'captable', 'option-pool', this.id ]
    }
  }

  // ---------------------------------------------------

  // Determines whether an optionPoolEvent is creating a new pool, or updating an existing pool by determining if
  // a previous event has affected the option count.
  isCreationEvent(): boolean {
    // Only the first optionPoolEvent in the list is considered a creation event
    const optionPoolEvents = this.company.previousOptionPoolEvents
    return this === optionPoolEvents[ 0 ] || optionPoolEvents.length === 0
  }

}
