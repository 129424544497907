import { Injectable, Injector, type TemplateRef, type ViewContainerRef, ElementRef } from '@angular/core'

import { ComponentPortal, type ComponentType, type Portal, type PortalOutlet, TemplatePortal } from '@angular/cdk/portal'
import type { MatSidenav } from '@angular/material/sidenav'

import { SideNavData, SideNavRef } from '@libs/utils'

import { LayoutFacade } from '../+state/layout.facade'

// ----------------------------------------------------------

export function isTemplateRef(
  obj: ComponentType<unknown> | TemplateRef<unknown>
): obj is TemplateRef<unknown> {
  return obj[ 'elementRef' ] instanceof ElementRef &&
    obj[ 'createEmbeddedView' ] instanceof Function
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private portalOutlet: PortalOutlet
  private sidenav: MatSidenav

  // ----------------------------------------------------

  public initialise(
    portalOutlet: PortalOutlet,
    sidenav: MatSidenav
  ) {
    // _log(`SidenavService.initialise(portalOutlet, sidenav)`, portalOutlet, sidenav)

    this.portalOutlet = portalOutlet
    this.sidenav = sidenav
  }

  // ----------------------------------------------------

  public detatch() {
    if (this.portalOutlet?.hasAttached()) {
      this.portalOutlet.detach()
    }
  }

  // ----------------------------------------------------

  public open<C, D = any, R = any>(
    component: ComponentType<C>,
    data?: D
  ): SideNavRef<C, R>

  public open<C, D = any, R = any>(
    component: ComponentType<C>,
    injector: Injector,
    data?: D
  ): SideNavRef<C, R>

  public open<C, D = any, R = any>(
    component: ComponentType<C>,
    injector?: Injector | D,
    data?: D
  ): SideNavRef<C, R> {
    data = injector instanceof Injector
      ? data
      : injector
    injector = injector instanceof Injector
      ? injector
      : this.injector

    // _log(
    //   `SidenavService.open(component = ${component.name}, data): component, injector`,
    //   data,
    //   component,
    //   this.injector,
    //   this
    // )

    if (!this.sidenav) {
      throw new Error(`SidenavService not initialised`)
    }

    const sideNavRef: SideNavRef<C, R> = new SideNavRef(this.sidenav)

    const portalInjector = this.getPortalInjector(sideNavRef, data)

    const portal: ComponentPortal<C> = new ComponentPortal(
      component,
      null,
      portalInjector,
      null
    )

    // _log(
    //   `SidenavService.open(${component.name}): sideNavRef, portalInjector, portal`,
    //   sideNavRef,
    //   portalInjector,
    //   portal,
    //   this
    // )

    this.attachPortal(portal)

    return sideNavRef
  }

  // ----------------------------------------------------

  public openTemplate<D = unknown, R = any>(
    templateRef: TemplateRef<D>,
    viewContainerRef: ViewContainerRef,
    data?: D
  ): SideNavRef<unknown, R> {
    // _log(
    //   `SidenavService.openTemplate(data): templateRef, viewContainerRef, injector`,
    //   data,
    //   templateRef,
    //   viewContainerRef,
    //   this.injector,
    //   this
    // )

    const sideNavRef: SideNavRef<unknown, R> = new SideNavRef(this.sidenav)

    const portal: TemplatePortal<D> = new TemplatePortal(
      templateRef,
      viewContainerRef,
      data
    )

    // _log(
    //   `SidenavService.openTemplate(): sideNavRef, portalInjector, portal`,
    //   sideNavRef,
    //   portalInjector,
    //   portal,
    //   this
    // )

    this.attachPortal(portal)

    return sideNavRef
  }

  // ----------------------------------------------------

  public async openSync<C, D = unknown, R = boolean>(
    component: ComponentType<C>,
    data?: D
  ): Promise<R>

  public async openSync<C, D = unknown, R = boolean>(
    component: ComponentType<C>,
    injector: Injector,
    data?: D
  ): Promise<R>

  public async openSync<C, D = unknown, R = boolean>(
    component: ComponentType<C>,
    injector?: Injector | D,
    data?: D
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      const openCall = injector instanceof Injector
        ? this.open<C, D, R>(component, injector, data)
        : this.open<C, D, R>(component, injector)

      openCall.afterClosed.subscribe({ next: resolve, error: reject })
    })
  }

  // ----------------------------------------------------

  public async openTemplateSync<D = unknown, R = boolean>(
    templateRef: TemplateRef<D>,
    viewContainerRef: ViewContainerRef,
    data?: D
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      this.openTemplate<D, R>(templateRef, viewContainerRef, data)
        .afterClosed
        .subscribe({ next: resolve, error: reject })
    })
  }

  // ----------------------------------------------------

  private attachPortal(
    portal: Portal<unknown>
  ) {
    if (!this.portalOutlet) {
      throw new Error(`SidenavService not initialised`)
    }

    if (this.portalOutlet.hasAttached()) {
      this.portalOutlet.detach()
    }

    // Ensure that asynchronous code to detach any existing portal has executed
    setTimeout(() => {
      this.portalOutlet.attach(portal)
      this.facade.sideNavOpen()
    })
  }

  // ----------------------------------------------------

  private getPortalInjector(
    sideNavRef: SideNavRef<unknown, unknown>,
    data?: unknown
  ) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: SideNavData,
          useValue: data
        },
        {
          provide: SideNavRef,
          useValue: sideNavRef
        }
      ]
    })
  }

  // ----------------------------------------------------

  constructor(
    private injector: Injector,
    private facade: LayoutFacade
  ) {
    // _log(`SidenavService(injector)`, injector, componentFactoryResolver, this)
  }

}
