import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { FilterOption } from '@libs/shared/models/list-filter.model'
import { FormControl } from '@ngneat/reactive-forms'

@Component({
  selector: 'sl-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: [ 'list-filter.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListFilterComponent {
  @Input() label: string
  @Input() options: FilterOption[] = []
  @Output() selectionChanged = new EventEmitter<FilterOption[]>()

  listControl: FormControl<FilterOption[]> = new FormControl([])

  get isAdditionalSelectionShown() {
    return (this.listControl.value?.length || 0) > 1
  }

  removeFilters(itemToRemove?: FilterOption) {
    this.listControl.setValue(
      itemToRemove ? this.listControl.value.filter(item => item.id !== itemToRemove.id) : []
    )

    this.emitSelectionChanged()
  }

  emitSelectionChanged() {
    this.selectionChanged.emit(this.listControl.value)
  }
}
