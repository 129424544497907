import { Component, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core'
import { CdkScrollable } from '@angular/cdk/overlay'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { map, filter, distinctUntilChanged, debounceTime } from 'rxjs/operators'

import { Message } from '../../services/notification/message'
import { NotificationStore, INotificationStore } from '../../services/notification/notification-store.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: [ './notification-panel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
  @ViewChild(CdkScrollable, { static: true })
  private readonly scrollable: CdkScrollable

  private readonly loading$ = new BehaviorSubject(false)

  private readonly seenMessages = new Set<Message>()

  // ----------------------------------------------------

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(NotificationStore) public readonly messageStore: INotificationStore
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    combineLatest([
      this.scrollable.elementScrolled().pipe(
        debounceTime(500)
      ),
      this.loading$
    ])
      .pipe(
        filter(([ event, loading ]) => !loading),
        map(() => this.scrollable.measureScrollOffset('top')),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.checkVisibility()
        this.checkIfLoadMessagesRequired()
      })

    this.checkVisibility()
    this.checkIfLoadMessagesRequired()
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    if (this.seenMessages.size > 0) {
      this.messageStore.markAllMessagesAsRead()
    }
  }

  // ----------------------------------------------------

  private checkVisibility() {
    const topOffset = this.scrollable.measureScrollOffset('top')

    const topIndex = Math.floor(topOffset / 80)
    const bottomIndex = Math.min(topIndex + 5, this.messageStore.length)

    for (let i = topIndex; i < bottomIndex; i++) {
      const message = this.messageStore.items[ i ]

      if (!message.read) {
        this.seenMessages.add(message)
      }
    }
  }

  // ----------------------------------------------------

  private async checkIfLoadMessagesRequired() {
    const bottomOffset = this.scrollable.measureScrollOffset('bottom')

    if (bottomOffset < 80) {
      this.loading$.next(true)

      await this.messageStore.loadMoreMessages()

      this.cdr.detectChanges()

      this.loading$.next(false)
    }
  }
}
