import { NgModule } from '@angular/core'

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'

import { USERS_FEATURE_KEY, usersReducer } from './+state/users.reducer'
import { UsersEffects } from './+state/users.effects'

// ----------------------------------------------------------

@NgModule({
  imports: [
    StoreModule.forFeature(USERS_FEATURE_KEY, usersReducer),
    EffectsModule.forFeature([ UsersEffects ])
  ]
})
export class UsersStateModule {}
