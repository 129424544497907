import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'
import { formatCurrency, formatNumber } from '@angular/common'

import { getCurrencyByCode, Price } from '@libs/models'

// ----------------------------------------------------------

const ZEROES_REGEX = /\.0+$/

// ----------------------------------------------------------

@Pipe({
  name: 'slPrice'
})
export class PricePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  // ----------------------------------------------------

  transform(
    price: Price | undefined,
  ): string {
    if (!price || !Number.isFinite(price.amount)) {
      return ''
    }

    const { symbol } = getCurrencyByCode(price.currency)

    let digitsInfo = '1.2'

    // Check if value ends in all zero digits and change
    // digitsInfo to prevent any decimal digits being shown
    const simpleResult = formatNumber(price.amount, 'en', digitsInfo)

    if (simpleResult.match(ZEROES_REGEX)) {
      digitsInfo = '1.0-0'
    }

    // Use formatCurrency from @angular/common
    const result = formatCurrency(price.amount, this.locale, symbol, price.currency, digitsInfo)

    // _logc(
    //   `PricePipe.transform(amount = ${price.amount}, currency = "${price.currency}"): result, simpleResult, currency, localeNumberFormat: Decimal, localeNumberFormat: Currency, getNumberOfCurrencyDigits()`,
    //   result,
    //   simpleResult,
    //   getCurrencyByCode(price.currency),
    //   getLocaleNumberFormat(locale, NumberFormatStyle.Decimal),
    //   getLocaleNumberFormat(locale, NumberFormatStyle.Currency),
    //   getNumberOfCurrencyDigits(currency)
    // )

    return result
  }

}
