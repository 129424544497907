import { addDays, isAfter, isBefore } from 'date-fns'
import { asDate, isSameOrBefore, today } from '@libs/utils'

import type { ApiFieldSpec } from '../model'
import type { User } from '../user'
import type { ShareClass } from '../stock/share-class'

import { DocumentEventAdapter } from '../events/document-event-adapters'
import type { IEventViewState } from '../events/event'
import { EventCategory } from '../../models/category.model'
import type { EventSupportingDocument, SupportingDocumentType } from '../../models/supporting-documents.model'

// ------------------------------------------------------------

const HMRC_APPROVAL_DATE = asDate('2022-12-01')

function getEmiValuationValidDays(hmrcApprovalDate: Date): number {
  return isBefore(hmrcApprovalDate, HMRC_APPROVAL_DATE) ? 119 : 89
}

// ------------------------------------------------------------

export interface EmiValuationAnswers {
  valuation: {
    iumv: number
    amv: number
    approach: 'normal' | 'aggressive'
    tradeStarted: boolean
    tradeStartDate: string
    taxReturnFiled: boolean
    pnls: {
      startDate: string
      endDate: string
      turnover: number
      grossProfit: number
      ebitda: number
    }[]
    latestAccountAssets: number
    latestAccountDate: string
    grossProfitMultiple: number
    grossProfitMultipleReason: string
    EBITDAMultiple: number
    EBITDAMultipleReason: string
  }
}

// ------------------------------------------------------------

export class EmiValuationEvent extends DocumentEventAdapter<EmiValuationAnswers> {
  readonly domain = 'emiValuations'
  readonly documentTypeId = 'VAL231'
  readonly category = EventCategory.EmiValuation

  strikePrice: number
  amv: number
  iumv: number
  officialAmv: number | null
  officialIumv: number | null
  hmrcApprovalDate: string | null

  shareClass: ShareClass
  signingDirector: User
  supportingDocuments: EventSupportingDocument[] = []

  historical: boolean

  // ----------------------------------------------------

  constructor({
    company,
    strikePrice = null,
    hmrcApprovalDate = null,
    amv = null,
    iumv = null,
    historical = false,
    ...data
  }) {
    super({
      company,
      strikePrice,
      amv,
      iumv,
      historical,
      ...data
    })
    this.hmrcApprovalDate = hmrcApprovalDate
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    if (this.shareClass) {
      this.shareClass.emiValuations.add(this)
    }
  }

  override detach() {
    super.detach()

    if (this.shareClass) {
      this.shareClass.emiValuations.remove(this)
    }
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'shareClass',
      'amv',
      'iumv',
      'strikePrice',
      'hmrcApprovalDate',
      'historical'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'options', 'emi-valuations', this.id ]
    }
  }

  // ----------------------------------------------------

  get expiryDate(): Date | null {
    if (this.hmrcApprovalDate === null) {
      return null
    }
    const approvedDate: Date = asDate(this.hmrcApprovalDate)
    const expiredDate: Date = addDays(approvedDate, getEmiValuationValidDays(approvedDate))

    return asDate(expiredDate)
  }

  // ----------------------------------------------------

  get expired(): boolean {
    if (this.expiryDate === null) {
      return false
    }

    const now: Date = today()
    return !isSameOrBefore(now, this.expiryDate)
  }

  // ----------------------------------------------------

  get active(): boolean {
    return !this.expired && this.closed
  }

  // ----------------------------------------------------

  get purchased(): boolean {
    return this.hasPurchasedStage()
  }

  // ----------------------------------------------------

  isOutOfDate(grantEffectiveDate: Date): boolean {
    const hmrcApprovalDate = asDate(this.hmrcApprovalDate)

    return !hmrcApprovalDate
           || isBefore(grantEffectiveDate, hmrcApprovalDate)
           || isAfter(grantEffectiveDate, addDays(hmrcApprovalDate, getEmiValuationValidDays(hmrcApprovalDate)))
  }

  // ---------------------------------------------------

  hasDocument(type: SupportingDocumentType, typeSpecific?: string): boolean {
    return !!this.supportingDocuments.find(d => d.type === type && d.typeSpecific === typeSpecific)
  }

  // ---------------------------------------------------

  get comparables() {
    return this.answers[ 'comparables' ] ?? []
  }
}
