import { ascend, descend, path, prop, sortWith } from 'ramda'


import { Event, IInvestorsByShareClass } from './event'

import { InvestmentCollection } from '../stock/investment'
import { Investor, InvestorCollection } from '../stock/investor'
import { ShareClassCollection, ShareClass } from '../stock/share-class'
import { Share } from '../stock/share'

// ------------------------------------------------------------

const sorter = sortWith<IInvestorsByShareClass>([
  descend(prop('isLeadInvestor')),
  ascend(path([ 'investor', 'name' ])),
])

// ------------------------------------------------------------

export abstract class InvestmentEvent<A extends object = object> extends Event<A> {

  investors = new InvestorCollection()
  investments = new InvestmentCollection()
  shareClasses = new ShareClassCollection()

  // ----------------------------------------------------

  constructor(data) {
    super(data)
  }

  // ----------------------------------------------------

  get investment() {
    return this.investments.reduce((total, i) => total + i.investment, 0)
  }

  // ----------------------------------------------------

  get estimatedInvestment() {
    return this.investments.estimatedInvestment
  }

  // -----------------------------------------------------

  get receivedInvestments(): InvestmentCollection {
    const col = new InvestmentCollection()
    col.addAll(this.investments.filter(i => i.type === 'SUBSCRIPTION').filter(i => !!i.received))
    return col
  }

  // ----------------------------------------------------

  get estimatedReceivedInvestment() {
    return this.receivedInvestments.estimatedInvestment
  }

  // ----------------------------------------------------

  get count() {
    return this.getSharesIssuedFromEvent()
      .reduce((total, i) => total + i.count, 0)
  }

  // ----------------------------------------------------

  get shareCount() {
    return this.count
  }

  // ----------------------------------------------------

  get estimatedCount() {
    return this.investments.reduce((total, i) => total + i.count, 0)
  }

  // ----------------------------------------------------

  addInvestor(investor) {
    if (this.investors.has(investor)) {
      return false
    }

    this.investors.add(investor)

    return true
  }

  // ----------------------------------------------------

  get hasDiscount() {
    return this.investments.some(i => i.hasDiscount)
  }

  // ----------------------------------------------------

  get hasLeadInvestor() {
    return false
  }

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLeadInvestor(investor: Investor) {
    return false
  }

  // ----------------------------------------------------

  override getSharesIssuedFromEvent(): Share[] {
    return this.closed
      ? this.investments.map(investment => investment.share).filter(s => !!s)
      : []
  }

  // ----------------------------------------------------

  override getInvestorsForShareClass(shareClass: ShareClass): IInvestorsByShareClass[] {
    const investments = this.investments
      .getByShareClass(shareClass)
      .reduce((out, investment) => {
        const investor = investment.investor

        if (out[ investor.id ]) {
          out[ investor.id ].investments.push(investment)
        } else {
          out[ investor.id ] = {
            investor,
            isLeadInvestor: this.isLeadInvestor(investor),
            investments: [ investment ]
          }
        }

        return out
      }, {})

    return sorter(Object.values(investments))
  }
}
