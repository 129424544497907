<div mat-dialog-content class="text-center">

  <button mat-icon-button
          class="close-button"
          mat-dialog-close
          aria-label="Close"
          i18n-aria-label>
    <mat-icon fontIcon="icon-close"></mat-icon>
  </button>

  <sl-success-tick></sl-success-tick>

  <h1>{{ data.title }}</h1>

  <p *ngIf="data.text || data.link">
    {{ data.text }}
    <a *ngIf="data.link"
       [routerLink]="data.link.value"
       mat-dialog-close>
      {{ data.link.label }}
    </a>
  </p>

  <div *ngIf="data.html"
       [innerHTML]="data.html"></div>
</div>

<div mat-dialog-actions
     align="center">

  <button mat-flat-button
          mat-dialog-close
          color="primary"
          cdkFocusInitial>
    {{data.ok}}
  </button>
</div>
