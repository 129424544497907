import { createFeatureSelector, createSelector } from '@ngrx/store'

import type { IConfigurationData } from '../services/configuration.service'

import { CORE_FEATURE_KEY, CoreState } from './core.reducer'

// ----------------------------------------------------------

export const selectCoreState = createFeatureSelector<CoreState>(CORE_FEATURE_KEY)

export const selectConfigurationLoaded = createSelector(
  selectCoreState,
  (state: CoreState) => state.configurationLoaded
)

// ----------------------------------------------------------

export const selectConfiguration = createSelector(
  selectCoreState,
  (state: CoreState) => state.configuration
)

export const selectDocumentTypes = createSelector(
  selectConfiguration,
  (configuration: IConfigurationData) => configuration.documentTypes
)

export const selectPlans = createSelector(
  selectConfiguration,
  (configuration: IConfigurationData) => configuration.plans
)

export const selectProducts = createSelector(
  selectConfiguration,
  (configuration: IConfigurationData) => configuration.products
)

export const selectRegions = createSelector(
  selectConfiguration,
  (configuration: IConfigurationData) => configuration.regions
)
