import { type ErrorHandler, Injectable } from '@angular/core'

import * as Sentry from '@sentry/browser'

import { environment } from '@env/environment'

// ----------------------------------------------------------

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    const { sentryOptions: { logErrorsInConsole = false } } = environment

    if (logErrorsInConsole) {
      console.error(error)
    }

    Sentry.captureException(error.originalError || error)
  }
}
