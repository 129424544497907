<!--
<div *ngIf="summaries$ | async as summaries">
  <sl-investor-summary-list [summaries]="summaries"
                            (investorClicked)="onInvestorClicked($event)"
                            (addNewClicked)="onAddInvestorClicked()"></sl-investor-summary-list>
</div>
-->

<div class="list-container"
     fxLayout="row wrap"
     fxLayoutGap="8px grid">

  <ng-container *ngIf="summaries$ | async as summaries">
    <sl-investor-summary-chip *ngFor="let summary of summaries; index as idx"
                              [summary]="summary"
                              [contentTemplate]="valueTemplateRef"
                              [class.clickable]="!!popupTemplateRef"
                              (clicked)="onInvestorClicked(summary, idx)">
    </sl-investor-summary-chip>
  </ng-container>

  <sl-chip *ngIf="showAddNew"
           [customStyle]="{
             backgroundColor: 'white',
             borderColor: '#04dca2'
           }"
           class="clickable"
           (click)="onAddInvestorClicked()">
    <mat-icon fontIcon="icon-plus"
              class="s24"></mat-icon>

    <div class="mh-8" i18n>
      Add new
    </div>
  </sl-chip>

</div>
