import { Component, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import type { UntypedFormGroup, Validator } from '@angular/forms'

// ------------------------------------------------------

import { Currencies, getCurrencyByCode } from '@libs/models'
import type { Currency, CurrencyCode } from '@libs/models'
import { FormControlComponent, getValidatorProvider, getValueProvider } from '@libs/utils'

// ------------------------------------------------------

@Component({
  selector: 'sl-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: [ './currency-select.component.scss' ],
  providers: [
    getValueProvider(CurrencySelectComponent),
    getValidatorProvider(CurrencySelectComponent)
  ]
})
export class CurrencySelectComponent extends FormControlComponent<CurrencyCode | null> implements Validator {
  @Input() required = false

  @Output() changed = new EventEmitter<CurrencyCode | null>()

  readonly currencies = Currencies
  selectedValue: CurrencyCode

  // ----------------------------------------------------------

  constructor(fb: UntypedFormBuilder) {
    super(fb)
  }

  // ------------------------------------------------------

  get selectedCurrency(): Currency {
    return getCurrencyByCode(this.selectedValue)
  }

  // ----------------------------------------------------------

  getControlGroup(): UntypedFormGroup {
    return this.fb.group({})
  }

  // ------------------------------------------------------

  override changeValue(modelValue: CurrencyCode): void {
    super.changeValue(modelValue)
    this.changed.emit(modelValue)
  }

  // ------------------------------------------------------

  validate() {
    if (this.required && this.controls.status === 'INVALID') {
      return {
        required: true
      }
    }

    return null
  }

  // ------------------------------------------------------

  override writeValue(modelValue: CurrencyCode | undefined): void {
    this.selectedValue = modelValue
  }
}
