import { Component, EventEmitter, Inject, Input, type OnInit, Output } from '@angular/core'

import { EMPTY, from, of } from 'rxjs'
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators'

import { FormBuilder, type FormControl } from '@ngneat/reactive-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { Company, Document, DocumentStatus, type DocumentTypeId } from '@libs/models'
import type { DocumentShareeModel } from '@libs/file-upload'

import { BackendService, RestApi } from '@libs/backend'
import { type ConfirmDialogOptions, ModalService } from '@libs/modals'
import { ToastService } from '@libs/services'

import { getDocumentStatusChecker } from './document-status-checkers'

// ------------------------------------------------------------

interface DropdownStatus {
  title: string
  subTitle: string
  color: string
  value: DocumentStatus
}

const dialogOptions: ConfirmDialogOptions = {
  title: $localize`This document has signatures`,
  text: $localize`Are you sure you want to set it to "Review only"?`,
  ok: $localize`Yes`
}

// ------------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-document-status-chip',
  templateUrl: './document-status-chip.component.html',
  styleUrls: [ './document-status-chip.component.scss' ]
})
export class DocumentStatusChipComponent implements OnInit {
  @Input() document: Document | DocumentShareeModel
  @Input() showWarning = true
  // Required if document is of type DocumentShareeModel
  // to check event answers
  @Input() company?: Company

  // This is required to make sure change detection picks up
  // status change made in a different place on the platform
  // E.g document view -> Share document panel
  @Input() set status (receivedInput: DocumentStatus) {
    if (!this.statusCtrl) {
      this.statusCtrl = this.fb.control(receivedInput)
    } else {
      this.statusCtrl.setValue(receivedInput, { emitEvent: false })
    }

    this.selectedStatus = this.statuses.find(status => status.value === receivedInput)
  }
  @Output() updated = new EventEmitter<DocumentStatus>()

  statusCtrl: FormControl<DocumentStatus>
  readonly statuses: DropdownStatus[] = [
    {
      title: $localize`Review only`,
      subTitle: $localize`Document cannot be signed`,
      color: '#9b9b9b',
      value: DocumentStatus.Draft
    },
    {
      title: $localize`Ready to sign`,
      subTitle: $localize`Document can be signed`,
      color: '#00c099',
      value: DocumentStatus.Ready
    }
  ]

  selectedStatus?: DropdownStatus
  toggleDisabled?: boolean
  tooltipText = ''

  // ----------------------------------------------------

  constructor(
    @Inject(RestApi) private restApi: BackendService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private toast: ToastService,
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.toggleDisabled = this.isHideDisplayOptions()

    this.statusCtrl.valueChanges.pipe(
      untilDestroyed(this),
      // if the document has any signature we need to ask for confirmation
      exhaustMap(status => this.hasAnySignatures && status === DocumentStatus.Draft && this.showWarning
        ? from(this.modalService.confirm(dialogOptions)).pipe(map(update => update && status ))
        : of(status)),
      concatMap((shouldUpdate?: DocumentStatus) => {
        if (shouldUpdate) {
          return this.updateStatus(shouldUpdate)
        }
        this.statusCtrl.setValue(this.document.status, { emitEvent: false })

        return EMPTY
      }),
    ).subscribe()
  }

  // ----------------------------------------------------

  updateStatus = (status: DocumentStatus) => this.restApi.one('documents', this.document.id).patch({ status }).pipe(
    map(result => {
      Object.assign(this.document, { status: this.statusCtrl.value })
      this.updated.emit(this.document.status)
      return result
    }),
    catchError(_ => {
      this.statusCtrl.setValue(this.document.status, { emitEvent: false })
      this.toast.error($localize`Error updating status.`)
      return EMPTY
    })
  )

  // ----------------------------------------------------

  get documentType(): DocumentTypeId {
    return this.document instanceof Document ? this.document.documentType : this.document.type
  }

  // ----------------------------------------------------

  get hasAnySignatures(): boolean {
    return this.document instanceof Document
      ? this.document.currentSignatures > 0
      : this.document.signaturesCount + this.document.signatoriesWithSignature.filter(s => s.requiresWitness).length > 0
  }

  // ------------------------------------------------------------

  /**
   * *** HACK ***to accommodate Document Integrity requirement. Since DocumentShareeModel does not hold
   * a reference to the document's event, there is a need to extract it from the company object
   * @returns boolean indicating whether status dropdown should be disabled based on a document type
   * specific callback (e.g. disable dropdown if user decided to hide investors and a cap table)
   */
  private isHideDisplayOptions(): boolean {
    const documentStatusChecker = getDocumentStatusChecker(this.documentType)

    if (!documentStatusChecker) {
      return false
    }

    const answers = this.document instanceof Document
      ? this.document.event.answers
      : this.company?.documents.get(this.document.id)?.event?.answers

    if (!answers) {
      return false
    }

    this.tooltipText = documentStatusChecker.tooltipText
    const callback = documentStatusChecker.checker

    return callback(answers)
  }
}
