import { Component, Inject, type OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { OverlayRef } from '@angular/cdk/overlay'
import { animate, type AnimationEvent, style, transition, trigger } from '@angular/animations'

import type { Company, User, Appointment, Address, Entity } from '@libs/models'

import { EntityService, InfoPopupData } from '../../services/entity.service'
import { CompanyService } from '@app/companies/services/company/company.service'

// ----------------------------------------------------

@Component({
  selector: 'sl-info-pop-up-panel',
  templateUrl: './info-pop-up-panel.component.html',
  styleUrls: [ './info-pop-up-panel.component.scss' ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[@dialogContainer]': 'state',
    '(@dialogContainer.done)': 'onAnimationDone($event)',
  },
  animations: [
    trigger('dialogContainer', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0)' }),
        animate('.2s ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('.2s ease-out', style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class InfoPopUpPanelComponent implements OnInit {
  user: User | null = null

  company: Company | null = null
  appointments: Appointment[]
  canEdit: boolean
  address: Address

  state: 'enter' | 'leave' = 'enter'

  // ----------------------------------------------------

  constructor(
    public dialogRef: OverlayRef,
    @Inject(MAT_DIALOG_DATA) public data: InfoPopupData,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {}

  // ----------------------------------------------------

  async ngOnInit() {
    const currentUser: User = this.route.snapshot.data.currentUser
    const currentCompany: Company = this.route.snapshot.data.company

    if (this.data.user) {
      this.user = this.data.user
      if (!this.user.connected) {
        this.address = this.user.address
      }
      this.canEdit = this.companyService.isUserAdmin(currentCompany, currentUser)
    } else if (this.data.company) {
      this.company = this.data.company
      this.address = this.company.address
      this.appointments = this.company.appointments.items()
    }

    this.dialogRef.backdropClick().subscribe(() => this.close())
  }

  // ----------------------------------------------------

  get entity(): Entity {
    return this.user || this.company
  }

  // ----------------------------------------------------

  onEditClicked(): void {
    this.dialogRef.dispose()
    // TODO: Should emit message via NgRx
    if (this.user) {
      this.entityService.showEntityEditDialog<User>(this.user)
    }
  }

  // ----------------------------------------------------

  close() {
    this.state = 'leave'
    this.dialogRef.detach()
  }

  // ----------------------------------------------------

  onAnimationDone(event: AnimationEvent) {
    if (this.state === 'leave') {
      this.dialogRef.dispose()
    }
  }
}
