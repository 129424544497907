import { Component, Inject, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'

import { FormControl } from '@ngneat/reactive-forms'
import { sum } from 'ramda'

import {
  Product,
  ProductNames,
  PurchaseStages,
  TransactionRequestData
} from '@libs/models'
import { SideNavData, SideNavRef } from '@libs/utils'
import {
  BundleProductMarketingFeatures,
  IBundleSidenavData,
  IBundleWithProductsData,
  IProductBundleData,
  IProductOnlyData,
  productBundlesMetadata,
} from '../../models/bundles'

import { Configuration } from '@core/services/configuration.service'

// ----------------------------------------------------------

@Component({
  selector: 'sl-product-bundles-sidenav',
  templateUrl: './product-bundles-sidenav.component.html',
  styleUrls: [ './product-bundles-sidenav.component.scss' ]
})
export class ProductBundlesSidenavComponent implements OnInit {

  howMuch = this.data.howMuch
  event = this.data.event

  bundles: IProductBundleData[] = []
  bundle = new FormControl<IProductBundleData>()

  product: Product
  marketingFeatures: string[]
  label: string
  description: string

  // ----------------------------------------------------

  constructor(
    private configuration: Configuration,
    private sideNavRef: SideNavRef<ProductBundlesSidenavComponent, TransactionRequestData>,
    @Inject(SideNavData) public data: IBundleSidenavData,
  ) {}

  // ----------------------------------------------------

  ngOnInit(): void {
    this.product = this.configuration.getProductById(this.howMuch.product)
    this.marketingFeatures = BundleProductMarketingFeatures[ this.howMuch.product ]

    this.bundles = this.howMuch.bundles.map(b => {
      if (productBundlesMetadata[ b.id ]) {
        [ this.label, this.description ] = [ $localize`Save on your Schemes`, $localize`Choose more than one Scheme and we’ll automatically apply a discount.` ]

        const fullPrice = sum(b.products.map(p => p.price.amount))
        const currency = b.price.currency

        return {
          id: b.id,
          name: productBundlesMetadata[ b.id ].name,
          currency: b.price.currency,
          fullPrice: {
            amount: fullPrice,
            currency,
          },
          price: {
            amount: b.price.amount,
            currency,
            billedWithVat: this.howMuch.billedWithVat,
          },
          savings: {
            amount: fullPrice - b.price.amount,
            currency,
          },
          products: b.products,
          benefitDetails: productBundlesMetadata[ b.id ].benefitDetails,
          isBundle: true
        }
      }
    })

    this.bundles.unshift({
      id: this.howMuch.product,
      name: `${ProductNames[ this.howMuch.product ]} only`,
      currency: this.howMuch.currency,
      price: {
        amount: this.howMuch.amount,
        currency: this.howMuch.currency,
        billedWithVat: this.howMuch.billedWithVat,
      },
      products: [ {
        product: this.howMuch.product,
        stage: this.howMuch.stage,
        price: {
          amount: this.howMuch.amount,
          currency: this.howMuch.currency,
        }
      } ],
      isBundle: false
    })

    this.bundle.patchValue(this.bundles[ 0 ])
    this.bundle.setValidators(Validators.required)
  }

  // ----------------------------------------------------

  get selectedBundle(): IProductBundleData {
    const bundle = this.bundle.value
    return bundle.isBundle
      ? bundle as IBundleWithProductsData
      : bundle as IProductOnlyData
  }

  // ----------------------------------------------------

  // onBundleSelected(event: MatRadioChange) {
  //   this.bundle.patchValue(event.value)
  // }

  // ----------------------------------------------------

  confirm() {
    const bundle = this.bundle.value
    const isStandalone = this.product.standalone
    let payload: TransactionRequestData

    if (bundle.isBundle) {
      payload = {
        kind: 'bundle',
        bundle: bundle.id,
        entity: isStandalone ? null : this.event.link,
        price: bundle.price.amount,
        stage: PurchaseStages.Balance,
        product: isStandalone ? this.howMuch.product : null,
        description: productBundlesMetadata[ bundle.id ].name
      }
    } else {
      payload = {
        kind: isStandalone ? 'product' : 'entity',
        entity: isStandalone ? null : this.event.link,
        product: isStandalone ? this.howMuch.product : null,
        stage: this.howMuch.stage
      }
    }

    return this.sideNavRef.close(payload)
  }
}
