import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core'
import { UntypedFormBuilder, FormGroupDirective, Validators } from '@angular/forms'

import { Company, User, FOUNDER_ROLES } from '@libs/models'
import { ICompanyContact } from '../../models/company-contacts'

import { UserService } from '@app/users/services/user.service'
import { AppointmentService } from '@app/companies/services/appointment/appointment.service'
import { ToastService } from '@libs/services'

// ----------------------------------------------------------

@Component({
  selector: 'sl-add-company-contact',
  templateUrl: './add-company-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddCompanyContactComponent {
  @Input() company: Company

  @Output() contactAdded = new EventEmitter<ICompanyContact>()

  @ViewChild('formDirective') private formDirective: FormGroupDirective

  contactForm = this.fb.group({
    email: [],
    firstName: [ '', Validators.required ],
    lastName: [ '', Validators.required ]
  })

  user: User | null = null

  // ----------------------------------------------------

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private appointmentService: AppointmentService,
    private toastService: ToastService
  ) {}

  // ----------------------------------------------------

  async onFocusOut() {
    const emailCtrl = this.contactForm.get('email')

    if (emailCtrl.valid) {
      const email = emailCtrl.value

      this.user = await this.userService.findUserByEmail(email)

      if (this.user) {
        this.contactForm.patchValue({
          firstName: this.user.firstName,
          lastName: this.user.lastName
        })
      }
    }
  }

  // ----------------------------------------------------

  async onAddClicked() {
    let user = this.user

    const { email, firstName, lastName }: { email: string, firstName: string, lastName: string } = this.contactForm.value

    if (user && !user.connected) {
      await this.userService.patchUser(user, { firstName, lastName })
    } else if (!user) {
      user = await this.userService.createUser({ email, firstName, lastName })
    }

    let appointment = this.company.getRelation(user)

    if (appointment) {
      await this.appointmentService.patchAppointment(appointment, { roles: FOUNDER_ROLES })
    } else {
      appointment = await this.appointmentService.createAppointment(this.company, user, { roles: FOUNDER_ROLES })
    }

    this.toastService.success($localize`${user.name} added to ${this.company.name}`)

    // https://stackoverflow.com/questions/48216330/angular-5-formgroup-reset-doesnt-reset-validators/48217303#48217303
    this.formDirective.resetForm()
    this.contactForm.reset()

    this.contactAdded.emit({ user, appointment })
  }
}
