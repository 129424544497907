<sl-action-with-search-bar (searchChanged$)="onFilterChanged($event)"
                           [searchLabel]="searchLabel">
  <button *ngIf="canAddNew"
          (click)="addNewClicked.emit()"
          class="action-button"
          mat-flat-button>
    <mat-icon fontIcon="icon-plus"
              color="primary"></mat-icon>
    <span>{{ addNewLabel }}</span>
  </button>
</sl-action-with-search-bar>

<mat-divider></mat-divider>

<div class="entity-list"
     fxLayout="column"
     fxLayoutAlign="start stretch">

  <sl-entity-selector-entity-list [entities]="filteredEntities"
                                  (entitySelected)="onEntitySelected($event)"></sl-entity-selector-entity-list>
</div>
