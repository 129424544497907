import { NavigationEnd } from '@angular/router'
import type {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params,
  Router,
  UrlMatchResult,
  UrlSegment
} from '@angular/router'

import { combineLatest, distinctUntilKeyChanged, filter, map, startWith } from 'rxjs'

import type { HasId } from './functions'

// ----------------------------------------------------------

export function getEventIdMatcher(
  segments: UrlSegment[]
): UrlMatchResult {
  return segments.length > 0
    ? {
      consumed: [
        segments[ 0 ]
      ],
      posParams: {
        eventId: segments[ 0 ]
      }
    }
    : null
}

// ----------------------------------------------------------

export function getParamsFromSnapshot(
  snapshot: ActivatedRouteSnapshot
): Params {
  return { ...snapshot.params }
}

// ----------------------------------------------------------

export function getMergedDataAndParamsFromSnapshot<T extends Record<string, unknown>>(
  snapshot: ActivatedRouteSnapshot
): T {
  return {
    ...snapshot.data,
    ...getParamsFromSnapshot(snapshot)
  } as T
}

// ----------------------------------------------------------

export function getLastRouteSnapshot(
  rootSnapshot: ActivatedRouteSnapshot
): ActivatedRouteSnapshot {
  let cur = rootSnapshot

  while (cur.firstChild) {
    cur = cur.firstChild
  }

  return cur
}

// ----------------------------------------------------------

export function getAllRouteSnapshots(
  rootSnapshot: ActivatedRouteSnapshot
): ActivatedRouteSnapshot[] {
  const snapshotIterator = new RouteSnapshotIterator(rootSnapshot)

  return Array.from(snapshotIterator)
}

// ----------------------------------------------------------

export class RouteSnapshotIterator implements Iterable<ActivatedRouteSnapshot> {
  constructor(
    private root: ActivatedRouteSnapshot
  ) {}

  [ Symbol.iterator ]() {
    let cur = this.root

    return {
      next(): IteratorResult<ActivatedRouteSnapshot> {
        if (!cur) {
          return {
            done: true,
            value: null
          }
        }

        const value = cur

        cur = cur.firstChild

        return {
          done: false,
          value
        }
      }
    }
  }
}

// ----------------------------------------------------------

export function eventIdChanged<E extends HasId = HasId>(
  router: Router,
  route: ActivatedRoute,
) {
  return combineLatest([
    route.data,
    router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
      startWith(null)
    )
  ]).pipe(
    map(([ { event }, _ ]): E => event),
    distinctUntilKeyChanged('id'),
  )
}
