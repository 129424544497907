import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'

import { type Observable, of, throwError } from 'rxjs'
import { tap, catchError } from 'rxjs/operators'

import { logHttpError } from '@libs/utils'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private contentCache = new Map<string, unknown>()

  // ----------------------------------------------------

  getUrlForPath(path: string): string {
    return this.location.prepareExternalUrl(path)
  }

  // ----------------------------------------------------

  loadFile(path: string): Observable<string> {
    if (this.contentCache.has(path)) {
      return of(this.contentCache.get(path) as string)
    }

    const url = this.getUrlForPath(path)

    return this.httpClient
      .get(url, { responseType: 'text' })
      .pipe(
        tap(data => {
          this.contentCache.set(path, data)
        }),
        catchError(err => {
          logHttpError(err)

          return of('')
        })
      )

  }

  // ----------------------------------------------------

  loadJson<T>(path: string): Observable<T> {
    if (this.contentCache.has(path)) {
      return of(this.contentCache.get(path) as T)
    }

    const url = this.getUrlForPath(path)

    return this.httpClient
      .get<T>(url)
      .pipe(
        tap(data => {
          this.contentCache.set(path, data)
        }),
        catchError(err => {
          logHttpError(err)

          return throwError(() => err)
        })
      )
  }

  // ----------------------------------------------------

  constructor(
    private httpClient: HttpClient,
    private location: Location
  ) {}
}
