import { InjectionToken } from '@angular/core'

import type { BackendProvider, BackendService } from '@libs/backend'

import { environment } from '@env/environment'

// ----------------------------------------------------------

export const Api = new InjectionToken<BackendService>('Api')
export const FileTransferApi = new InjectionToken<BackendService>('FileTransferApi')
export const RestApi = new InjectionToken<BackendService>('RestApi')
export const NotificationsApi = new InjectionToken<BackendService>('NotificationsApi')

// ----------------------------------------------------------

const apiBaseUrl = environment.apiRoot
const fileTransferApiBaseUrl = environment.fileTransferRoot
const restApiBaseUrl = environment.apiRoot.replace(/\/$/, '') + '/api/v1'
const notificationsApiBaseUrl = environment.notificationsApiRoot

export const ApiFactory = (backendProvider: BackendProvider) => backendProvider.getBackendService({ baseUrl: apiBaseUrl })
export const FileTransferApiFactory = (backendProvider: BackendProvider) => backendProvider.getBackendService({ baseUrl: fileTransferApiBaseUrl })
export const RestApiFactory = (backendProvider: BackendProvider) => backendProvider.getBackendService({ baseUrl: restApiBaseUrl })
export const NotificationsApiFactory = (backendProvider: BackendProvider) => backendProvider.getBackendService({ baseUrl: notificationsApiBaseUrl })
