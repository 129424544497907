import { Collection, type Document } from '..'
import { type DocumentTypeId, EventCategory, Jurisdiction } from '../../models'
import { Event, type IEventViewState } from './event'

import { asDate, isoFormat, isSameOrAfter, isSameOrBefore, today, uniqById } from '@libs/utils'

// ----------------------------------------------------

export class ShareAllotmentReturnEvent extends Event {
  readonly domain = 'shareAllotmentReturns'
  readonly category = EventCategory.FundingRound
  readonly workflowKey = 'share-allotment-return'

  startDate: string
  endDate: string

  // ----------------------------------------------------

  constructor({
    company,
    name = $localize`Share Allotment Return`,
    startDate = isoFormat(today()),
    endDate = isoFormat(today()),
    ...data
  }) {
    super({
      company,
      name,
      startDate,
      endDate,
      ...data
    })
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return null
  }

  // ----------------------------------------------------

  get shareAllotmentReturnDocument(): Document | undefined {
    let docType: DocumentTypeId
    switch (this.company.jurisdiction) {
      case Jurisdiction.Ireland:
        docType = 'B5'
        break
      case Jurisdiction.HongKong:
        docType = 'NSC1'
        break
      default:
        docType = 'SH01'
    }
    return this.documents.getByType(docType)
  }

}

// ----------------------------------------------------

export class ShareAllotmentReturnCollection extends Collection<ShareAllotmentReturnEvent> {

  getByDate(date: string | Date): ShareAllotmentReturnEvent {
    const _date = asDate(date)
    return this.find(s => isSameOrAfter(_date, asDate(s.startDate)) && isSameOrBefore(_date, asDate(s.endDate)))
  }

  // ----------------------------------------------------

  getByMultipleDates(dates: Array<string | Date>): ShareAllotmentReturnEvent[] {
    return uniqById(dates.map(d => this.getByDate(d)))
  }
}
