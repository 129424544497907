<ng-template>
  <sl-sidenav-stepper #subStepper
                      [parentStepper]="parentStepper"
                      (selectionChange)="onSelectionChanged($event)">

    <sl-sidenav-step *ngIf="!skipEntitySelector"
                     [showButtons]="false"
                     [label]="title">
      <div fxLayout="column"
            fxLayoutAlign="start stretch"
            class="gap-16">
        <sl-entity-selector [entities]="entities"
                            [searchLabel]="searchLabel"
                            [addNewLabel]="addNewLabel"
                            [canAddNew]="canAddNew"
                            (entitySelected)="onEntitySelected($event)"
                            (addNewClicked)="subStepper.next()"></sl-entity-selector>
      </div>
    </sl-sidenav-step>

    <sl-sidenav-step [showButtons]="false"
                     [label]="addNewLabel">
      <div fxLayout="column"
           fxLayoutAlign="start stretch"
           class="p-24 gap-16">
        <button mat-stroked-button
                class="round-button"
                color="primary"
                (click)="onEntityTypeClicked(subStepper, 'user')"
                i18n>
          Individual
        </button>

        <button mat-stroked-button
                class="round-button"
                color="primary"
                (click)="onEntityTypeClicked(subStepper, 'company')"
                i18n>
          Company
        </button>
      </div>
    </sl-sidenav-step>

    <sl-sidenav-step *ngIf="entityType === 'user'"
                     label="Enter details"
                     i18n-label
                     [stepControl]="userCtrl"
                     [async]="true"
                     (stepDone)="userForm.getUser()">

      <sl-user-form [formControl]="userCtrl"
                    [required]="true"
                    [defaultUserData]="defaultDetails"
                    #userForm
                    class="m-16"
                    [defaultCountry]="company.address?.country"
                    (userSaved)="onEntitySaved($event)"></sl-user-form>

    </sl-sidenav-step>

    <sl-sidenav-step *ngIf="entityType === 'company'"
                     label="Add new company"
                     i18n-label
                     [stepControl]="companyCtrl"
                     [async]="true"
                     (stepDone)="companySelector.getCompany()">

      <sl-company-selector #companySelector
                           class="m-16"
                           [currentCompany]="company"
                           [extendRoles]="false"
                           [defaultName]="defaultDetails?.companyName"
                           (companySelected)="onCompanySelected($event)"
                           (companySaved)="onCompanySaved(subStepper, $event)"></sl-company-selector>

    </sl-sidenav-step>

    <sl-sidenav-step [stepControl]="contactsCtrl"
                     (stepDone)="onEntitySaved(newCompany)"
                     label="Add contacts"
                     i18n-label>
      <sl-add-company-contact [company]="newCompany"
                              (contactAdded)="onContactAdded()"
                              class="m-16"></sl-add-company-contact>
    </sl-sidenav-step>

  </sl-sidenav-stepper>
</ng-template>
