import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { StatusDialogData } from '../../models'

// ---------------------------------------------------------------

@Component({
  selector: 'sl-success-dialog',
  template: `
    <div class="p-32 gap-10" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <img
          src="/assets/images/app/onboarding/rocket.svg"
          width="50"
          height="50"
        />
        <h3 fxFlex="60">{{ data.title }}</h3>
      </div>
      <div *ngIf="data.text" class="ph-32" fxLayout="row" fxLayoutAlign="space-around center">
        {{data.text}}
      </div>
    </div>
  `
})
export class SuccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: StatusDialogData) {}
}
