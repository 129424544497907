import { Component, Input } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-subheading',
  templateUrl: './subheading.component.html',
  styleUrls: [ './subheading.component.scss' ]
})
export class SubheadingComponent {
  @Input() marginTop = 40
  @Input() marginBottom = 24
  @Input() gap = 16
  @Input() emoji?: string
  @Input() icon?: string
}
