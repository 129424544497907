<div [class.company]="!isUser"
     [ngStyle]="sizeStyle"
     [ngStyle.xs]="sizeStyleXs">
  <img *ngIf="hasAvatar"
       [src]="avatarUrl"
       alt="Avatar"
       i18n-alt>

  <div *ngIf="!hasAvatar"
       class="initials"
       [ngStyle]="initialsStyle"
       [ngStyle.xs]="initialsStyleXs">{{ initials }}</div>
</div>
