import { Component, Inject } from '@angular/core'

import { environment } from '@env/environment'
import { AppType } from '@libs/shared/models/swapp-data.model'

// ----------------------------------------------------------

interface AppData {
  text: string
  imgSrc: string
  altText: string
  href: string
  appType: AppType
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-swapp-panel',
  templateUrl: './swapp-panel.component.html',
  styleUrls: [ './swapp-panel.component.scss' ],
})
export class SwappPanelComponent {
  constructor(@Inject('appType') readonly appType: AppType) {}

  swappData: AppData[] = [
    {
      text: $localize`For investors`,
      imgSrc: 'assets/images/app/invest-logo-24x24.png',
      altText: 'SeedLegals Invest',
      href: this.investAppLocation,
      appType: AppType.INVEST
    },
    {
      text: $localize`For founders`,
      imgSrc: 'assets/images/app/logo-24x24.png',
      altText: 'SeedLegals Grow',
      href: this.growAppLocation,
      appType: AppType.GROW
    }
  ]

  isCurrentApp(swapp: AppData): boolean {
    return this.appType === swapp.appType
  }

  get growAppLocation(): string {
    return environment.GROW_APP_LOCATION
  }

  get investAppLocation(): string {
    return environment.INVEST_APP_LOCATION
  }
}
