import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to'

import type { IDownload } from '@libs/backend'

// ------------------------------------------------------------

const SCROLL_ON_LOAD_DURATION = 200

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class UiService {

  scrollToTop() {
    this.scrollToElement('.page-content-wrapper > :first-child')
  }

  // ----------------------------------------------------

  scrollToElement(
    selectorOrElement: string | HTMLElement,
    duration = SCROLL_ON_LOAD_DURATION,
    offset = 0
  ) {
    const target: HTMLElement = typeof selectorOrElement === 'string'
      ? this._document.querySelector(selectorOrElement)
      : selectorOrElement

    // _log(`UiService.scrollToElement(selectorOrElement, duration): targetElem`, selectorOrElement, duration, target)

    if (target) {
      requestAnimationFrame( () => {
        this.scrollToService.scrollTo({
          target,
          duration,
          easing: 'easeInOutCubic',
          offset
        })
      })
    }
  }

  // ----------------------------------------------------

  openInNewTab(url: string, target: string = '_blank') {
    window.open(url, target)
  }

  // ----------------------------------------------------

  downloadFile({ fileName, content, mimeType }: IDownload): void {
    const blob = content instanceof ArrayBuffer
      ? new Blob([ content ], { type: mimeType })
      : content

    const fileUrl = URL.createObjectURL(blob)

    try {
      this.downloadUrl(fileUrl, fileName)
    } finally {
      setTimeout(() => {
        URL.revokeObjectURL(fileUrl)
      }, 10000)
    }
  }

  // ----------------------------------------------------

  downloadUrl(fileUrl: string, fileName: string): void {
    const a = document.createElement('a')
    a.href = fileUrl
    a.download = fileName
    a.style.display = 'none'

    try {
      document.body.appendChild(a)
      a.click()
    } finally {
      a.remove()
    }
  }

  // ----------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private scrollToService: ScrollToService
  ) {}
}
