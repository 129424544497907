import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-requirements-list',
  templateUrl: './requirements-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementsListComponent {
  @Input() requirements: string[]
}
