<ng-template>
  <sl-sidenav-stepper #subStepper
                      [parentStepper]="parentStepper">

    <sl-sidenav-step *ngIf="showSelectStep"
                     [showButtons]="false"
                     [label]="title">

      <div fxLayout="column"
            fxLayoutAlign="start stretch"
            class="gap-16">
        <sl-entity-selector [entities]="entities"
                            [searchLabel]="searchLabel"
                            [addNewLabel]="addNewLabel"
                            [canAddNew]="canAddNew"
                            (entitySelected)="onUserSelected($event, false)"
                            (addNewClicked)="subStepper.next()"></sl-entity-selector>
      </div>

    </sl-sidenav-step>

    <sl-sidenav-step [label]="detailsLabel"
                     [stepControl]="userCtrl"
                     [async]="true"
                     (stepDone)="userForm.getUser()">

      <sl-user-form [formControl]="userCtrl"
                    (changed)="onUserChanged($event)"
                    #userForm
                    class="m-16"
                    [required]="true"
                    [showAddress]="showAddress"
                    [requireAddress]="requireAddress"
                    [requestLegalName]="requestLegalName"
                    (userSaved)="onUserSaved($event)"
                    [defaultCountry]="defaultCountry"></sl-user-form>

    </sl-sidenav-step>

  </sl-sidenav-stepper>
</ng-template>
