
export interface IModelData {
  id: string | null

  // modified: string | null
  // inserted: string | null
  // updated: string | null
}

// ----------------------------------------------------------

export interface INamedEntity {
  name: string
}

// ----------------------------------------------------------

export interface IHasPicture {
  picture: string | null
}

// ----------------------------------------------------------

export interface ITimestamped {
  inserted: string
  updated: string | null
}

// ----------------------------------------------------------

export type EntityType = 'user' | 'company'

export enum EntityTypes {
  User = 'user',
  Company = 'company'
}
