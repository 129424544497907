import { parseISO, isBefore } from 'date-fns'

import { type ApiFieldSpec, OnCompanyEventModel } from '../model'
import { SeedNoteEvent } from '../events/seed-note-event'
import type { LoanCertificate } from './loan-certificate'
import type { Investor } from '../stock/investor'
import type { Investment } from '../stock/investment'
import type { Share } from '../stock/share'
import type { InstantConversionEvent } from '../events/instant-conversion-event'
import type { RepaymentEvent } from '../events/repayment-event'
import type { DebtIssueEvent } from '../events/debt-types'

import { calculateInterest, Comparator } from '@libs/utils'
import { DebtStatus } from '@libs/captable'
import { Collection } from '../collection'

// ------------------------------------------------------------

export const loanDateComparator: Comparator<Loan> = (a: Loan, b: Loan) => a.event.effectiveDate && b.event.effectiveDate
  ? new Date(b.event.effectiveDate).getTime() - new Date(a.event.effectiveDate).getTime()
  : 0

// ------------------------------------------------------------

export class Loan extends OnCompanyEventModel<DebtIssueEvent> {

  readonly domain: string = 'loans'

  amount: number
  conversionPrice: number | null
  received: string

  investor: Investor

  loanCertificate: LoanCertificate

  repayment: RepaymentEvent | null
  instantConversion: InstantConversionEvent | null

  investment: Investment | null
  share: Share | null

  // -----------------------------------------------------------

  constructor({
    event,
    amount = 0,
    conversionPrice = null,
    received = null,
    investment = null,
    share = null,
    loanCertificate = null,
    repayment = null,
    instantConversion = null,
    ...data
  }) {
    super({ event, company: event.company, amount, conversionPrice, received, investment, share, loanCertificate, repayment, instantConversion, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'investor', include: 'create' },
      'amount',
      'received',
      'instantConversion',
      'repayment'
    ]
  }

  // ----------------------------------------------------

  get closingEvent() {
    return this.repayment ??
      this.instantConversion ??
      this.investment?.event
  }

  // ----------------------------------------------------

  amountWithInterest(d: string): number {
    if (this.event instanceof SeedNoteEvent) {
      return this.amount + calculateInterest({
        principal: this.amount,
        interestType: this.event.interestType,
        interestRate: this.event.interestRate,
        startDate: parseISO(this.event.interestStartDate),
        endDate: parseISO(d)
      })
    } else {
      return this.amount
    }
  }

  // ----------------------------------------------------

  amountAtMaturity(): number {
    return this.event instanceof SeedNoteEvent
      ? this.amountWithInterest(this.event.maturityDate)
      : this.amount
  }

  // ----------------------------------------------------

  get valuationForLoan(): number {
    if (this.event.fixedValuation != null) {
      return this.event.fixedValuation
    }

    const previousRound = this.company.rounds.findMostRecentEventBeforeEvent(this.event)
    const previousValuation = previousRound?.valuation ?? 0

    return Math.max(previousValuation, this.event.valuationFloor)
  }

  // ----------------------------------------------------

  get estimatedShareCount(): number {
    if (this.investment) {
      return this.investment.count
    }

    if (this.share) {
      return this.share.count
    }

    const estimatedPricePerShare = this.estimatedPricePerShare

    if (estimatedPricePerShare > 0) {
      const amountWithInterest = this.amountWithInterest(this.instantConversion.effectiveDate)

      return Math.floor(amountWithInterest / estimatedPricePerShare)
    } else {
      return 0
    }
  }

  // ----------------------------------------------------

  get estimatedPricePerShare(): number {
    if (!this.instantConversion) {
      throw Error('Cannot calculate estimatedPricePerShare for loan without conversion event')
    }

    if (this.conversionPrice !== null) {
      return this.conversionPrice
    }

    const effectiveDate = parseISO(this.instantConversion.effectiveDate)

    const shareCountBefore = this.company.getActiveShares()
      .filter(share => isBefore(parseISO(share.issued), effectiveDate))
      .reduce((tot, share) => tot + share.count, 0)

    // TODO: This is not "before", but this is too broken to fix at this point.
    const optionCount = this.company.optionCount

    const dilutedShareCountBefore = shareCountBefore + optionCount

    return dilutedShareCountBefore > 0
      ? this.valuationForLoan / dilutedShareCountBefore
      : 0
  }

  // ----------------------------------------------------

  getStatus(): DebtStatus {
    if (this.investment?.event.closed) {
      return DebtStatus.Converted
    } else if (this.investment) {
      return DebtStatus.Converting
    } else if (this.instantConversion?.closed) {
      return DebtStatus.Converted
    } else if (this.instantConversion) {
      return DebtStatus.Converting
    } else if (this.repayment?.closed) {
      return DebtStatus.Repaid
    } else {
      return DebtStatus.Open
    }
  }

  // ----------------------------------------------------

  override async afterRemoval() {
    if (this.loanCertificate) {
      this.loanCertificate.loan = null
      this.loanCertificate = null
    }
  }

}

// ------------------------------------------------------------

export class LoanCollection extends Collection<Loan> {
  constructor() {
    super(loanDateComparator)
  }

  // ----------------------------------------------------------

  getLatestLoanEventDate(): string {
    return this.items()[ 0 ]?.event?.effectiveDate
  }
}
