import { Products, ProductId } from '../money/product'
import { InvestmentScheme } from '../../models/investment-event.model'
import { RoundType } from './round-type'
import { Region } from '../../models'

// -----------------------------------------------------------

const bootstrapInvestmentSchemes: InvestmentScheme[] = [
  InvestmentScheme.Standard,
  InvestmentScheme.SEIS
]

const standardInvestmentSchemes: InvestmentScheme[] = [
  ...bootstrapInvestmentSchemes,
  InvestmentScheme.EIS,
  InvestmentScheme.VCT
]

// ------------------------------------------------------------

export interface IRoundMetadata {
  description: string
  product: ProductId | null
  investmentSchemes: InvestmentScheme[]
  viewState?: string
  editState?: string
  marketingFeatures?: string[]
}

// ------------------------------------------------------------

export const frMarketingFeatures = [
  $localize`Term Sheet`,
  $localize`Previous Investor Consent`,
  $localize`Shareholders Agreement`,
  $localize`Board Report`,
  $localize`Shareholders Written Consultation`,
  $localize`Previous Investor Notice`,
  $localize`Individual Preemption Waiver`,
  $localize`Subscription Forms`,
  $localize`Funds Tracker`,
  $localize`Board Resolution`,
  $localize`Power for Formalities`
]

export const hkMarketingFeatures = [
  $localize`Term Sheet`,
  $localize`Previous Investor Consent`,
  $localize`Preemption Notice`,
  $localize`Shareholders Agreement`,
  $localize`Articles of Association`,
  $localize`Disclosure Letter`,
  $localize`Board Resolution`,
  $localize`Shareholders Resolution`,
  $localize`NSC1 Form`
]

export const gbMarketingFeatures = [
  $localize`Term Sheet`,
  $localize`Previous Investor Consent`,
  $localize`Preemption Notice`,
  $localize`Shareholders Agreement`,
  $localize`Articles of Association`,
  $localize`Disclosure Letter`,
  $localize`Board Resolution`,
  $localize`Shareholders Resolution`,
  $localize`SH01 Form`
]

export const sgMarketingFeatures = [
  $localize`Term Sheet`,
  $localize`Previous Investor Consent`,
  $localize`Preemption Notice`,
  $localize`Shareholders Agreement`,
  $localize`Constitution`,
  $localize`Disclosure Letter`,
  $localize`Board Resolution`,
  $localize`Shareholders Resolution`
]

export const instantMarketingFeatures = [
  $localize`Instant Investment Proposal`,
  $localize`Instant Investment Agreement`,
]

// ------------------------------------------------------------

// Update Ireland when merged in
export const SeedMarketingFeatures: Record<Region, string[]> = {
  [ Region.Commonwealth ]: gbMarketingFeatures,
  [ Region.Europe ]: gbMarketingFeatures,
  [ Region.France ]: frMarketingFeatures,
  [ Region.HongKong ]: hkMarketingFeatures,
  [ Region.Germany ]: gbMarketingFeatures,
  [ Region.Singapore ]: sgMarketingFeatures,
  [ Region.Ireland ]: gbMarketingFeatures
}

export const ConsentMarketingFeatures = [
  $localize`Previous Investor Consent`,
  $localize`Preemption Notice`,
  $localize`Board Resolution`,
  $localize`Shareholders Resolution`,
]

export const InstantInvestmentMarketingFeatures: Record<Region, string[]> = {
  [ Region.Commonwealth ]: [
    ...instantMarketingFeatures,
    $localize`SH01 Form`
  ],
  [ Region.Europe ]: instantMarketingFeatures,
  [ Region.France ]: instantMarketingFeatures,
  [ Region.HongKong ]: [
    ...instantMarketingFeatures,
    $localize`NSC1 Form`
  ],
  [ Region.Germany ]: instantMarketingFeatures,
  [ Region.Singapore ]: instantMarketingFeatures,
  [ Region.Ireland ]: [
    ...instantMarketingFeatures,
    $localize`CRO Forms`
  ]
}

// ------------------------------------------------------------

export const RoundMetadata: Record<RoundType, IRoundMetadata> = {
  [ RoundType.Historical ]: {
    description: $localize`Historical round`,
    product: null,
    investmentSchemes: standardInvestmentSchemes
  },
  [ RoundType.Bootstrap ]: {
    description: $localize`Bootstrap round`,
    product: Products.BootstrapRound,
    viewState: 'app.company.run.rounds.one',
    editState: 'app.company.run.rounds.one.questions',
    investmentSchemes: bootstrapInvestmentSchemes,
    marketingFeatures: [
      $localize`Investment Proposal`,
      $localize`Investment Agreement`,
      $localize`Articles of Association`,
      $localize`Board Resolution`,
      $localize`Shareholders Resolution`,
      $localize`SH01 and share certificates`
    ]
  },
  [ RoundType.Seed ]: {
    description: $localize`Seed round`,
    product: Products.SeedRound,
    viewState: 'app.company.run.rounds.one',
    editState: 'app.company.run.rounds.one.questions',
    investmentSchemes: standardInvestmentSchemes
  },
  [ RoundType.Instant ]: {
    description: $localize`Instant investment`,
    product: Products.InstantInvestment,
    viewState: 'app.company.run.instantInvestments.one',
    editState: 'app.company.run.instantInvestments.one.questions',
    investmentSchemes: standardInvestmentSchemes,
    marketingFeatures: [
      $localize`Instant Investment Proposal`,
      $localize`Instant Investment Agreement`,
      $localize`Previous Investor Consent`,
      $localize`Preemption Notice`,
      $localize`Board and Shareholders Resolutions`,
      $localize`SH01 and share certificates`
    ]
  },
  [ RoundType.InstantShares ]: {
    description: $localize`Issue Shares`,
    product: Products.InstantShares,
    viewState: 'app.company.run.instantShares.one',
    editState: 'app.company.run.instantShares.one.questions',
    investmentSchemes: standardInvestmentSchemes,
    marketingFeatures: [
      $localize`Add any number of people`,
      $localize`Specify % or number of shares`,
      $localize`We'll do the dilution calculations`,
      $localize`Previous Investor Consent`,
      $localize`Board and Shareholders Resolutions`,
      $localize`SH01 and share certificates`
    ]
  }
}
