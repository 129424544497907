import { Injectable } from '@angular/core'

import { type Observable, forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'

import { flatten } from 'ramda'

import { Jurisdiction } from '@libs/models'
import type { RegisteredCompanyData } from '../../models/company-data'
import type { IRegisteredCompanyMatch } from '@app/entities/models/company-match'

import { CompaniesHouseService } from '../companies-house/companies-house.service'
import { RegistrarService } from '../registrar/registrar.service'
import { RncsService } from '../rncs/rncs.service'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class RegisteredCompanySearchService {
  getCompany(
    data: RegisteredCompanyData
  ): Observable<RegisteredCompanyData> {
    switch (data.jurisdiction) {
      case Jurisdiction.EnglandWales:
      case Jurisdiction.NorthernIreland:
      case Jurisdiction.Scotland:
        return this.companiesHouseService.getCompany(data)

      case Jurisdiction.Ireland:
        return this.registrarService.getCompany(data)

      case Jurisdiction.France:
        return this.rncsService.getCompany(data)
    }
  }

  // ----------------------------------------------------

  searchCompanies(
    scope: Jurisdiction | 'All',
    q: string,
    limit = 5
  ): Observable<RegisteredCompanyData[]> {
    const sources: Observable<RegisteredCompanyData[]>[] = []

    if (scope === 'All' || scope === Jurisdiction.EnglandWales) {
      sources.push(this.companiesHouseService.searchCompanies(q, limit))
    }

    if (scope === 'All' || scope === Jurisdiction.Ireland) {
      sources.push(this.registrarService.searchCompaniesByName(q, limit))
    }

    if (scope === 'All' || scope === Jurisdiction.France) {
      sources.push(this.rncsService.searchCompanies(q, limit))
    }

    return forkJoin(sources).pipe(
      map(results => flatten(results))
    )
  }

  // ----------------------------------------------------

  getCompanyMatch(
    companyData: RegisteredCompanyData
  ): IRegisteredCompanyMatch {
    return {
      source: 'remote',
      name: companyData.rawName,
      region: companyData.region,
      registrarNumber: companyData.registrarNumber,
      incorporated: companyData.incorporated,
      address: companyData.addressSnippet || null,
      companyData
    }
  }

  // ----------------------------------------------------

  constructor(
    private companiesHouseService: CompaniesHouseService,
    private rncsService: RncsService,
    private registrarService: RegistrarService
  ) {}
}
