import type { Jurisdiction } from './jurisdiction.model'
import type { CompanyType } from './company.model'
import type { ProductId } from '../things/money/product'
import type { PlanId } from '../models/plan.model'
import type { CurrencyCode } from './currency.model'
import {
  HKG_COMPANY_CREATION_DISCLAIMER,
  COMPANY_CREATION_DISCLAIMER,
  FR_COMPANY_CREATION_DISCLAIMER,
  SGP_COMPANY_CREATION_DISCLAIMER,
  IRL_COMPANY_CREATION_DISCLAIMER
} from './region-disclaimers.model'
import type { OptionReturnStrategy } from "../things"

// ----------------------------------------------------------

export enum Region {
  Commonwealth = 'COMMONWEALTH',
  Europe = 'EUROPE',
  France = 'FRANCE',
  Germany = 'GERMANY',
  HongKong = 'HONG_KONG',
  Ireland = 'IRELAND',
  Singapore = 'SINGAPORE',
}

// ----------------------------------------------------------

export const REGIONS_WITH_SEIS = [
  Region.Commonwealth,
  Region.Ireland
]

// ----------------------------------------------------------

export interface IRegionData {
  id: Region
  jurisdictions: Jurisdiction[]
  products: ProductId[]
  companyTypes: CompanyType[]
  plans: PlanId[]
  currency: CurrencyCode
  freeTrialDuration: number
  optionReturnStrategy: OptionReturnStrategy
}

// ----------------------------------------------------------

export const COMPANY_REGISTRY_BY_REGION: Map<Region, string> = new Map([
  [ Region.Commonwealth, 'Companies House' ],
  [ Region.HongKong, 'The Companies Registry' ],
  [ Region.Ireland, 'Companies Registration Office' ]
])

// ----------------------------------------------------------

export const REGION_COMPANY_DISCLAIMERS: Record<Region, string> = {
  [Region.Commonwealth]: COMPANY_CREATION_DISCLAIMER,
  [Region.Europe]: COMPANY_CREATION_DISCLAIMER,
  [Region.Germany]: COMPANY_CREATION_DISCLAIMER,
  [Region.France]: FR_COMPANY_CREATION_DISCLAIMER,
  [Region.HongKong]: HKG_COMPANY_CREATION_DISCLAIMER,
  [Region.Ireland]: IRL_COMPANY_CREATION_DISCLAIMER,
  [Region.Singapore]: SGP_COMPANY_CREATION_DISCLAIMER
}

export function isApacRegion(region: Region): boolean {
  // helper function to determine whether a region is APAC
  return [
    Region.HongKong,
    Region.Singapore
  ].includes(region)
}
