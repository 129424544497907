import { Injectable, Optional, Pipe, type PipeTransform } from '@angular/core'

import type { CurrencyCode } from '@libs/models'

import { LocaleService } from '@libs/services'

import { abbreviateNumber } from '@libs/utils'
import { getCurrency } from './currency-utils'

// -----------------------------------------------------------

@Injectable()
@Pipe({
  name: 'abbrCurrency'
})
export class AbbrCurrencyPipe implements PipeTransform {

  constructor(
    @Optional() private localeService: LocaleService
  ) {}

  // -----------------------------------------------------------

  transform(
    amount: number,
    currencyCode?: CurrencyCode,
  ): string {
    if (!Number.isFinite(amount) || amount < 0) {
      return ''
    }

    const { symbol, prefix, space } = getCurrency(this.localeService, currencyCode)

    let res = abbreviateNumber(amount)

    if (res === '') {
      res = amount.toFixed(2)
    }

    // Warning: that is a non-breaking space below!
    const sp = space ? ' ' : ''

    return prefix
      ? symbol + sp + res
      : res + sp + symbol
  }

}
