import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

import type { Fee } from '@libs/models'

// -----------------------------------------------------------

@Component({
  selector: 'sl-fee',
  template: `
    <span i18n>
      { fee.amount === 0, select,
          true {Free}
          false {{{ fee | slPrice }}{ fee.billedWithVat, select, true {&ngsp;+ VAT}}}
      }
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeeComponent {
  @Input() fee: Fee
}
