import { createFeatureSelector, createSelector } from '@ngrx/store'

import { AccountRole } from '@libs/models'
import { AUTH_FEATURE_KEY, type AuthState } from './auth.reducer'

// ----------------------------------------------------------

// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY)

export const selectLoggedIn = createSelector(
  selectAuthState,
  (state: AuthState) => state.loggedIn
)

export const selectEmailToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.emailToken
)

export const selectIdToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.idToken
)

// ----------------------------------------------------------

export const selectWhoAmI = createSelector(
  selectAuthState,
  (state: AuthState) => state.whoAmI
)

export const selectWhoAmIPending = createSelector(
  selectAuthState,
  (state: AuthState) => state.whoAmIPending
)

export const selectWhoAmIRedirectUrl = createSelector(
  selectAuthState,
  (state: AuthState) => state.whoAmIRedirectUrl
)

export const selectWhoAmIError = createSelector(
  selectAuthState,
  (state: AuthState) => state.whoAmIError
)

// ----------------------------------------------------------

export const selectAccountRole = createSelector(
  selectWhoAmI,
  whoAmI => whoAmI?.role ?? AccountRole.User
)

export const selectAccountLocale = createSelector(
  selectWhoAmI,
  whoAmiI => whoAmiI?.locale
)

export const selectIsUserConnected = createSelector(
  selectWhoAmI,
  whoAmiI => whoAmiI?.connected
)

export const selectIsPlatformAdmin = createSelector(
  selectAccountRole,
  role => role === AccountRole.Subadmin || role === AccountRole.Admin
)
