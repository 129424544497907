import type { Region } from '../../models/region.model'

export enum RoundType {
  Historical = 'HIST',
  Bootstrap = 'BOOT',
  Seed = 'SEED',
  Instant = 'INSTANT',
  InstantShares = 'INSTANT_SHARES'
}

// ------------------------------------------------------------

export const AllRoundTypes: RoundType[] = [
  RoundType.Historical,
  RoundType.Bootstrap,
  RoundType.Seed,
  RoundType.Instant,
  RoundType.InstantShares
]

// ------------------------------------------------------------

export const PlatformRoundTypes = new Set<RoundType>([
  RoundType.Bootstrap,
  RoundType.Seed
])

// ------------------------------------------------------------

export const AvailablePlatformRoundTypes: Record<Region, RoundType[]> = {
  COMMONWEALTH: [
    RoundType.Bootstrap,
    RoundType.Seed,
  ],
  EUROPE: [],
  FRANCE: [
    RoundType.Seed,
  ],
  GERMANY: [
    RoundType.Seed,
  ],
  SINGAPORE: [
    RoundType.Seed,
  ],
  HONG_KONG: [
    RoundType.Seed,
  ],
  IRELAND: [
    RoundType.Seed,
  ],
}
