<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="title">
  <div style="width: 40px"></div>
  <div i18n>Add new card</div>
  <button mat-icon-button
          (click)="dialogRef.close(false)">
    <mat-icon fontIcon="icon-close"></mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div class="content">
  <div fxLayout="column"
       class="gap-8"
       fxLayoutAlign="start center">

    <mat-form-field>
      <mat-label i18n>Name on card</mat-label>
      <input matInput
             required
             [formControl]="cardLabelCtrl">
    </mat-form-field>

    <sl-stripe-card #stripeCard></sl-stripe-card>

    <button (click)="onAddClicked(stripeCard)"
            [disabled]="!stripeCard.isValid || !cardLabelCtrl.valid || processing"
            mat-raised-button
            class="add-button"
            color="primary">
      <span i18n>Save</span>
    </button>
  </div>
</div>
