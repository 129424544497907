import type { IAddressData, IAddressDataWithoutId } from './address.model'
import type { Jurisdiction } from './jurisdiction.model'
import type { Locale } from './locale.model'
import type { IHasPicture, IModelData } from './model.model'

// ----------------------------------------------------------

export type NotificationType =
  | 'DOCUMENT_CREATED'
  | 'DOCUMENT_SIGNED'

// ----------------------------------------------------------

export type UserDataFormats =
  | IUserData
  | IUserExcerptData

// ----------------------------------------------------------

export interface UtmParams {
  utmMedium: string
  utmSource: string
  utmCampaign: string
}

// ------------------------------------------------------------

export enum AccountRole {
  User = 'USER',
  Subadmin = 'SUBADMIN',
  Admin = 'ADMIN'
}

// ----------------------------------------------------------

export enum Sex {
  Male = 1,
  Female = 2,
  NotKnown = 0
}

// ----------------------------------------------------------

export const Sexes: Record<Sex, string> = {
  [ Sex.Male ]: $localize`Male`,
  [ Sex.Female ]: $localize`Female`,
  [ Sex.NotKnown ]: $localize`Not Specified`
}

// ------------------------------------------------------------

export interface UserAdditionalInfo {
  // Onboarding fields
  role: string | null
  onboardingJurisdiction: Jurisdiction | 'other' | 'notIncorporated' | null
  goals: string[]
  roundTimeline?: string | null
  lastAccounts?: string | null
  firstUtmMedium?: string
  firstUtmSource?: string
  firstUtmCampaign?: string
  utmMedium?: string
  utmSource?: string
  utmCampaign?: string
  startedOnboarding?: boolean
  // Old onboarding fields
  sectors?: string[]

  // Other fields
  seenVideos?: string[]
  closedStartNegotiating?: boolean

  [ prop: string ]: unknown
}

// ----------------------------------------------------------

export interface IUserFormData extends IModelData {
  connected: boolean
  email: string
  firstName: string
  lastName: string
  address: IAddressData | null
}

// ----------------------------------------------------------

export interface IBaseUserExcerptData extends IModelData, IHasPicture {
  email: string
  phone: string
  firstName: string
  lastName: string
  address: IAddressData | null
  sex: Sex
  connected: boolean
}

// ----------------------------------------------------------

export interface IUserExcerptData extends IBaseUserExcerptData {
  invited: string
}

// ----------------------------------------------------------

export interface IUserData extends IBaseUserExcerptData {
  emailToken: string | null
  description: string | null
  facebook: string | null
  twitter: string | null
  linkedin: string | null
  additionalInfo: UserAdditionalInfo
  signature: string | null
  locale: Locale
}

// ----------------------------------------------------------

export interface IUserCreateData {
  email: string
  firstName: string
  lastName: string
  address: IAddressDataWithoutId | null
  phone: string
  picture?: string
  sex: Sex
  connected: false
}

// ----------------------------------------------------------

export type IUserPatchData = Partial<Omit<IUserData, 'address'> & { address: Partial<IAddressData | IAddressDataWithoutId> }>

// ----------------------------------------------------------

export interface IUserNotificationData extends IModelData {
  read: boolean
  notification: INotificationData
}

// ----------------------------------------------------------

export interface INotificationData {
  type: NotificationType
  originator: IUserExcerptData
  inserted: string
  payload: string
}
