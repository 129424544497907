import { createAction, props, union } from '@ngrx/store'

import type { IUserCompany, User, IUserData } from '@libs/models'

import type { UnsignedDocumentsQueryResult } from '@app/documents/services/graphql/unsigned-documents-query.service'
import type { ICurrentUserQueryCompanyData } from '../services/graphql/current-user-query'

// ----------------------------------------------------------

export const CreateNewUser = createAction(
  '[Users] Create New User'
)

export const GetWhoAmIForNewUser = createAction(
  '[Users] Get Who Am I For New User'
)

export const CreateNewUserSuccess = createAction(
  '[Users] Create New User Success',
  props<{ user: IUserData }>()
)

export const CreateNewUserError = createAction(
  '[Users] Create New User Error',
  props<{ error: unknown }>()
)

// ----------------------------------------------------------

export const LoadCurrentUser = createAction(
  '[Users] Load Current User'
)

export const LoadCurrentUserSuccess = createAction(
  '[Users] Load Current User Success',
  props<{ user: IUserData, companies: ICurrentUserQueryCompanyData[] }>()
)

export const LoadCurrentUserError = createAction(
  '[Users] Load Current User Error',
  props<{ error: unknown }>()
)

export const LoadCurrentUserComplete = createAction(
  '[Users] Load Current User Complete',
  props<{ currentUser: User }>()
)

// ----------------------------------------------------------

export const UpdateUserCompanies = createAction(
  '[Users] Update User Companies',
  props<{ companies: IUserCompany[] }>()
)

export const UpdateUserCompany = createAction(
  '[Users] Update User Company',
  props<{ companyId: string, data: Partial<IUserCompany> }>()
)

export const AddUserCompany = createAction(
  '[Users] Add User Company',
  props<{ userCompany: IUserCompany }>()
)

// ----------------------------------------------------------

export const UpdateUserCompaniesUnsignedDocuments = createAction(
  '[Users] Update User Companies Unsigned Document Counts',
  props<{ data: UnsignedDocumentsQueryResult }>()
)

// ----------------------------------------------------------

export const UpdateUserCompanyDeadlineStateSuccess = createAction(
  '[Users] User Companies Deadline State Update Success',
  props< { companyId: string, pendingDeadlinesCount: number } >()
)

// ----------------------------------------------------------

export const UserUpdated = createAction(
  '[User] User Updated',
  props<{ id: string, data: Partial<User> }>()
)

// ----------------------------------------------------------

const actions = union({
  CreateNewUser,
  GetWhoAmIForNewUser,
  CreateNewUserSuccess,
  CreateNewUserError,
  LoadCurrentUser,
  LoadCurrentUserSuccess,
  LoadCurrentUserError,
  UpdateUserCompanies,
  UpdateUserCompany,
  AddUserCompany,
  UpdateUserCompaniesUnsignedDocuments,
  UpdateUserCompanyDeadlineStateSuccess,
  UserUpdated
})

export type UsersAction = typeof actions
