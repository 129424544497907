<div class="action-row bb"
     fxLayout="row"
     fxLayoutAlign="start center">
  <ng-content></ng-content>
</div>

<div class="action-row gap-8"
     fxLayout="row"
     fxLayoutAlign="space-between center">

  <mat-icon fontIcon="icon-magnify"
            class="ml-8"></mat-icon>

  <mat-form-field fxFlex
                  appearance="none">
    <input [formControl]="searchCtrl"
           [placeholder]="searchLabel"
           matInput
           autocomplete="off">
  </mat-form-field>

  <button *ngIf="searchCtrl.value.length > 0"
          (click)="clearSearch()"
          mat-icon-button
          aria-label="Cancel"
          i18n-aria-label>
    <mat-icon fontIcon="icon-close-circle-outline"></mat-icon>
  </button>
</div>
