export { BackendModule } from './lib/backend.module'

export { BackendProvider } from './lib/services/backend.provider'
export { BackendService } from './lib/services/backend.service'
export { RequestBuilder } from './lib/services/request-builder'

export { RestApiService } from './lib/services/rest-api.service'

export {
  Api, ApiFactory,
  FileTransferApi, FileTransferApiFactory,
  RestApi, RestApiFactory,
  NotificationsApi, NotificationsApiFactory
} from './lib/config/backend.config'

export type { IDownload } from './lib/models/download.model'
export { MimeTypes } from './lib/models/download.model'
