<div class="main-layout"
     [class.is-mobile]="layout.isMainNavCollapsible$ | async"
     [class.is-fullscreen-sidenav]="layout.isXSmall$ | async">

  <mat-sidenav-container [class.left-opened]="layout.mainNavOpen$ | async"
                         [class.right-opened]="layout.sideNavOpen$ | async"
                         (backdropClick)="layout.backdropClicked()">

    <mat-sidenav #mainNav
                 [opened]="layout.mainNavOpen$ | async"
                 [mode]="layout.mainNavMode$ | async">
      <sl-left-nav [company]="currentCompany$ | async"
                   [user]="currentUser$ | async"
                   [leftNavInfo]="leftNavInfo$ | async"
                   [isLoading]="isLoading$ | async"></sl-left-nav>
    </mat-sidenav>

    <mat-sidenav #sideNav
                 [opened]="layout.sideNavOpen$ | async"
                 mode="over"
                 position="end"
                 [autoFocus]="false"
                 (closedStart)="layout.sideNavClose()">
      <ng-template cdkPortalOutlet></ng-template>
    </mat-sidenav>

    <mat-sidenav-content>
      <div [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet=outlet></router-outlet>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
