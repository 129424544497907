import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-event-status-bullet',
  templateUrl: './event-status-bullet.component.html',
  styleUrls: [ './event-status-bullet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventStatusBulletComponent {
  @Input() closed: boolean
  @Input() effectiveDate: string
}
