import { EventCategory } from '../../models/category.model'
import type { InvestmentScheme } from '../../models/investment-event.model'

import { Collection } from '../collection'
import { type ApiFieldSpec, OnCompanyEventModel } from '../model'
import type { Investor } from '../stock/investor'
import type { Share } from '../stock/share'
import type { ShareClass } from '../stock/share-class'
import type { IEventViewState } from './event'
import { DocumentEventAdapter } from './document-event-adapters'

// ------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExitAnswers {}

// ------------------------------------------------------------

export class ExitEvent extends DocumentEventAdapter<ExitAnswers> {
  readonly domain = 'exits'
  readonly category = EventCategory.Exit

  valuation: number

  investor: Investor

  exitPayouts = new Collection<ExitPayout>()

  // ----------------------------------------------------

  constructor({
    company,
    investor,
    valuation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    documents = null,
    ...data
  }) {
    super({
      company,
      investor,
      valuation,
      ...data
    })

    this.name = $localize`Exit`
  }

  // ----------------------------------------------------

  override get timelineAmount() {
    return this.valuation
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'investor', include: 'create' },
      'valuation',
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'raise', 'exits', this.id ]
    }
  }

}

// ------------------------------------------------------------

export class ExitPayout extends OnCompanyEventModel<ExitEvent> {
  readonly domain = 'exitPayouts'

  shareClass: ShareClass

  scheme: InvestmentScheme

  count: number

  amount: number

  sellingInvestor: Investor

  /**
   * Once the transfer is closed, the new share certificate for the transferred
   * shares owned by the target investor.
   */
  share: Share | null = null

  // ----------------------------------------------------

  constructor({
    event,
    shareClass,
    scheme,
    count,
    amount,
    sellingInvestor,
    share,
    ...data
  }) {
    super({ ...data, event, shareClass, scheme, count, amount, sellingInvestor, share })
  }

}
