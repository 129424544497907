<div [class.company]="entity?.domain ==='companies'"
     [class.grow]="grow"
     class="entity-chip">
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       class="entity-chip-content gap-8">
    <sl-avatar *ngIf="entity"
               [entity]="entity"
               [size]="24"></sl-avatar>

    <span><ng-content></ng-content></span>
  </div>
</div>
