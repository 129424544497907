import { NgModule } from '@angular/core'

import { SharedModule } from '@libs/shared'
import { ModalsModule } from '@libs/modals'

import { SearchFieldComponent } from './search-field/search-field.component'
import { SearchDialogComponent } from './search-dialog/search-dialog.component'

// ----------------------------------------------------------

@NgModule({
  imports: [
    SharedModule,
    ModalsModule
  ],
  declarations: [
    SearchFieldComponent,
    SearchDialogComponent
  ],
  exports: [
    SearchFieldComponent
  ]
})
export class SearchModule {}
