<sl-action-with-search-bar (searchChanged$)="onFilterChanged($event)"
                           [searchLabel]="searchLabel">
  <button *ngIf="canAddNew"
          (click)="addNewClicked.emit()"
          class="action-button"
          mat-flat-button>
    <mat-icon fontIcon="icon-plus"
              color="primary"></mat-icon>
    <span>{{ addNewLabel }}</span>
  </button>
</sl-action-with-search-bar>

<mat-divider></mat-divider>

<div class="entity-list"
     fxLayout="column"
     fxLayoutAlign="start stretch">

  <div *ngFor="let entry of filteredEntityGroups.entries()">

    <ng-container *ngIf="entry[1].length > 0">
      <div class="group-name">{{ entry[0] }}</div>

      <sl-entity-selector-entity-list [entities]="entry[1]"
                                      (entitySelected)="onEntitySelected($event)"></sl-entity-selector-entity-list>
    </ng-container>

  </div>
</div>
