import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core'

import { map } from 'rxjs/operators'

import { Actions, ofType } from '@ngrx/effects'

import type { Entity, User } from '@libs/models'
import { UserUpdated } from '@app/users/+state/users.actions'
import { CompanyUpdated } from '@app/companies/+state/company.actions'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-avatar-with-name',
  templateUrl: './avatar-with-name.component.html',
  styleUrls: [ './avatar-with-name.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarWithNameComponent implements OnInit, OnChanges {
  @Input() entity: Entity
  @Input() size = 40
  @Input() sizeXs?: number
  @Input() emailVerified = false
  @Input() popup = true

  isUser = false
  user: User | null = null

  showEmailVerified: boolean

  // ----------------------------------------------------

  constructor(
    private actions$: Actions,
    private changeDetector: ChangeDetectorRef
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.actions$
      .pipe(
        ofType(UserUpdated, CompanyUpdated),
        map(action => {
          return action.type === UserUpdated.type
            ? action.id
            : action.companyId
        }),
        untilDestroyed(this)
      )
      .subscribe(id => {
        if (this.entity.id === id) {
          this.changeDetector.detectChanges()
        }
      })
  }

  // ----------------------------------------------------

  ngOnChanges() {
    if (this.entity.domain === 'users') {
      this.isUser = true
      this.user = this.entity as User
    } else {
      this.isUser = false
    }

    this.showEmailVerified = this.isUser
      ? this.emailVerified && this.user.connected
      : false
  }
}
