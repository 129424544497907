import { DocumentEventAdapter } from './document-event-adapters'
import { EventCategory } from '../../models/category.model'

// ----------------------------------------------------------

export class ConfirmationStatementEvent extends DocumentEventAdapter {
  readonly domain = 'confirmationStatements'
  readonly category = EventCategory.ConfirmationStatement

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'board', 'confirmation-statements', this.id ]
    }
  }
}
