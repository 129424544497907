<div [formGroup]="controls"
     fxLayout="column">

  <mat-form-field *ngIf="!abridged">
    <mat-label i18n>Line 1</mat-label>

    <input formControlName="line1"
           matInput
           [required]="required">
  </mat-form-field>

  <mat-form-field *ngIf="!abridged">
    <mat-label i18n>Line 2</mat-label>

    <input formControlName="line2"
           matInput>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n>City</mat-label>

    <input formControlName="city"
           matInput
           [required]="required">
  </mat-form-field>

  <div fxLayout.xs="column"
       [fxLayout.gt-xs]="inSidenav?'column':'row'"
       [class.gap-gt-xs-16]="!inSidenav">
    <mat-form-field *ngIf="!abridged">
      <mat-label i18n>Postcode</mat-label>

      <input formControlName="postcode"
             matInput
             maxlength="10"
             [required]="required">

      <mat-error *ngIf="postcodeCtrl.errors"
                 i18n>A valid post code is required</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n>Country</mat-label>

      <sl-country-select formControlName="country"></sl-country-select>
    </mat-form-field>
  </div>

</div>
