import { NgModule } from '@angular/core'

import { SharedModule } from '@libs/shared'

import { COMPONENTS, EXPORT_COMPONENTS } from './components'
import { DIRECTIVES } from './directives'

// ----------------------------------------------------------

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ...COMPONENTS,
    ...EXPORT_COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    ...EXPORT_COMPONENTS,
    ...DIRECTIVES
  ]
})
export class EntitiesComponentsModule {}
