import { Component, OnInit, Input } from '@angular/core'

import type { Appointment, AppointmentRole, User } from '@libs/models'

// ----------------------------------------------------

@Component({
  selector: 'sl-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: [ './role-list.component.scss' ]
})
export class RoleListComponent implements OnInit {

  @Input() appointments: Appointment[]
  @Input() role: AppointmentRole

  users: User[]

  // ----------------------------------------------------

  ngOnInit() {
    this.users = this.appointments ?
      this.appointments.filter(a => a.roles.includes(this.role))
        .map(a => a.user) : []
  }
}
