import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core'
import { SearchDialogComponent } from '../search-dialog/search-dialog.component'
import { ModalService } from '@libs/modals'

// ----------------------------------------------------------

@Component({
  selector: 'sl-search-field',
  templateUrl: 'search-field.component.html',
  styleUrls: [ './search-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFieldComponent {
  @Input() companyId: string

  modalService = inject(ModalService)

  modalOpen = false

  // ------------------------------------------------------

  @HostListener('window:keydown.meta.k', [ '$event' ])
  async openSearchMac(event: KeyboardEvent) {
    if (this.isMac()) {
      event.preventDefault()
      await this.onSearch()
    }
  }

  @HostListener('window:keydown.control.k', [ '$event' ])
  async openSearchOther(event: KeyboardEvent) {
    if (!this.isMac()) {
      event.preventDefault()
      await this.onSearch()
    }
  }

  // ------------------------------------------------------

  isMac() {
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      return true
    }
  }

  // ------------------------------------------------------

  async onSearch() {
    if (!this.modalOpen) {
      this.modalOpen = true
      await this.modalService.show<SearchDialogComponent, { companyId: string }>(
        SearchDialogComponent,
        { companyId: this.companyId },
        {
          position: { top: '16px' },
          width: '100%',
          maxWidth: '840px',
          autoFocus: 'first-tabbable'
        }
      )
      this.modalOpen = false
    }
  }
}
