import { Component } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-unauthenticated-layout',
  template: `
    <div class="main-layout">
      <mat-toolbar class="old-toolbar" flexLayout="row" flexLayoutAlign="space-between center">
        <img src="https://app.seedlegals.com/assets/images/app/logo-176x25.png">
      </mat-toolbar>
      <mat-sidenav-container>
        <mat-sidenav-content>
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  `,
  styles: [ `
    .old-toolbar {
      height: 55px;
      background-image: url("https://assets.seedlegals.com/bg/fibre-glass.jpg") !important;
    }
  ` ]
})
export class UnauthenticatedLayoutComponent {}
