import { Component, Input, TemplateRef, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, HostListener } from '@angular/core'

import { Investor, InvestorSummary, User, Company } from '@libs/models'

// -----------------------------------------------------------

@Component({
  selector: 'sl-investor-summary-chip',
  templateUrl: './investor-summary-chip.component.html',
  styleUrls: [ './investor-summary-chip.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorSummaryChipComponent implements OnChanges {
  @Input() contentTemplate?: TemplateRef<any>
  @Input() summary: InvestorSummary

  @Output() clicked = new EventEmitter<Investor>()

  // ----------------------------------------------------

  entity: User | Company

  // ----------------------------------------------------

  ngOnChanges(changes) {
    if (changes.summary) {
      this.entity = this.summary.investor.entity as User | Company
    }
  }

  // ----------------------------------------------------

  @HostListener('click')
  onClicked() {
    this.clicked.emit(this.summary.investor)
  }
}
