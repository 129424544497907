<form [formGroup]="form"
      fxLayout="column">

  <mat-form-field>
    <mat-label i18n>Company jurisdiction</mat-label>
    <sl-jurisdiction-select formControlName="jurisdiction"
                            [selectableJurisdictions]="selectableJurisdictions"
                            [allowOther]="true"></sl-jurisdiction-select>
  </mat-form-field>

  <sl-company-form [jurisdiction]="selectedJurisdiction"
                   (companySelected)="onCompanySelected($event)"></sl-company-form>
</form>
