import { Inject, Injectable } from '@angular/core'
import type { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Router } from '@angular/router'

import type { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { AuthService } from './auth.service'
import { AuthFacade } from '../+state/auth.facade'

import { EnvironmentConfig, IEnvironmentConfig } from '@libs/shared/tokens'

// ----------------------------------------------------------

@Injectable()
export class AuthGuard implements CanActivate {

  /**
   *  This isn't checked for lazily-loaded modules, but see this answer
   *
   *  https://stackoverflow.com/questions/43857783/angular-2-how-do-i-get-route-parameters-from-canload-implementation#43859249
   *
   * for how to use an empty parent route with a guard to get around that.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    // _log(`AuthGuard.canActivate(${state.url}): route, state`, route, state)

    return this.authFacade.isLoggedIn$.pipe(
      map(authed => {
        this.authService.storeUrlParams(
          route.queryParamMap, 
          this.environment.tokens.email, 
          this.environment.tokens.utm.medium,
          this.environment.tokens.utm.source,
          this.environment.tokens.utm.campaign
        )

        if (authed) {
          // _log(`AuthGuard.checkLogin(${url}): Authenticated in authStore or API`, this.router)
          return true
        }

        const redirectUrl = state.url.replace(/\?[^#]*/, '')
        // _log(`AuthGuard.checkLogin(${url}): Not authenticated! Storing redirectUrl and params`, redirectUrl, params)

        this.authService.storeRedirectUrl(redirectUrl)
        return this.router.createUrlTree([ '/login' ])
      }),
      first(),
    )
  }

  // ----------------------------------------------------

  constructor(
    private router: Router,
    @Inject(EnvironmentConfig) private environment: IEnvironmentConfig,
    private authService: AuthService,
    private authFacade: AuthFacade,
  ) {}

}
