import { HttpClient } from '@angular/common/http'

import { AuthStorageKeys } from '@app/auth/auth.constants'

import { BackendServiceConfiguration } from '../models/backend-service-config.model'
import { RequestOptions } from '../models/request-options.model'

import { LocalStorageService } from '@libs/storage'
import { Builder } from './builder'
import { RequestBuilder } from './request-builder'

// ----------------------------------------------------------

export class BackendService implements Builder {
  readonly isCollection = false

  readonly segments: string[] = []

  // ----------------------------------------------------

  all(entity: string): RequestBuilder {
    return new RequestBuilder(this).all(entity)
  }

  // ----------------------------------------------------

  one(entity: string, id?: string): RequestBuilder {
    return new RequestBuilder(this).one(entity, id)
  }

  // ----------------------------------------------------

  get httpClient(): HttpClient {
    return this.client
  }

  // ----------------------------------------------------

  get requestOptions(): RequestOptions {
    const options: RequestOptions = {}

    const token = this.storage.getItem(AuthStorageKeys.ID_TOKEN)

    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`
      }
    }

    return options
  }

  // ----------------------------------------------------

  get baseUrl(): string {
    return this.config.baseUrl.replace(/\/$/, '')
  }

  // ----------------------------------------------------

  constructor(
    private config: BackendServiceConfiguration,
    private client: HttpClient,
    private storage: LocalStorageService
  ) {}
}
