import { HttpErrorResponse } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { firstValueFrom, from, interval } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'

import type {
  BillingPeriod,
  Company,
  CompanyProduct,
  Coupon,
  Document,
  Event,
  Feature,
  IPlanHowMuchData,
  IProductHowMuchData,
  ISubscriptionData,
  Plan,
  PlanId,
  Product,
  ProductId,
  PurchaseStage,
  Transaction,
  TransactionRequestData,
  User
} from '@libs/models'
import {
  AccessRole,
  CouponResponse,
  DocumentAccessibility,
  PurchaseStages,
  Subscription,
  SubscriptionStatuses
} from '@libs/models'
import type { CardWithExpiry } from '../models/cards'
import type { FreeTrialReward } from '../services/payments.service'

import { Api, BackendService, RestApi } from '@libs/backend'
import { Configuration } from '@app/core/services/configuration.service'
import { PaymentsService } from './payments.service'
import { StripeService } from './stripe.service'
import { PlanDialogService } from './plan-dialog.service'
import { UsersFacade } from '@app/users/+state/users.facade'

import { AnalyticsKeys, AnalyticsService } from '@libs/analytics'

// ------------------------------------------------------------

export interface IDocumentPaymentRequirements {
  hasRequirements: boolean
  featuresRequired: boolean
  hasRequiredFeatures: boolean
  requiredFeatures: Feature[]
  purchaseRequired: boolean
  requiredPurchase?: IProductHowMuchData
}

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  async addCouponCode(company: Company, couponCode: string): Promise<CouponResponse> {
    try {
      const response = await firstValueFrom(this.api
        .one('companies', company.id)
        .all('promoCodes')
        .post<Coupon>({
          code: couponCode
        }))

      switch (response.type) {
        case 'free_trial':
          for (const [ planId, expiry ] of Object.entries(response.freeTrialExpiries)) {
            const subscription = company.subscriptions.get(planId as PlanId)

            if (subscription) {
              subscription.expires = new Date(expiry)
            }
          }
          break

        case 'subscription_discount':
          if (await this.planDialogService.showCouponsAppliedDialog(response.discounts ?? [])) {
            this.router.navigate([ 'companies', company.id, 'settings', 'membership' ])
          }
          break
      }

      return CouponResponse.Ok

    } catch (ex) {
      if (ex instanceof HttpErrorResponse && ex.status === 403) {
        return ex.error.reason as CouponResponse
      }

      throw ex
    }
  }

  // ----------------------------------------------------

  async activateFreeTrial(company: Company, user: User, plan: Plan): Promise<void> {
    const subscriptionData: ISubscriptionData = await firstValueFrom(this.api
      .one('companies', company.id)
      .one('activateFreeTrial')
      .one(plan.id)
      .post())

    if (subscriptionData) {
      this.analyticsService.trackEvent(AnalyticsKeys.START_FREE_TRIAL)
      const subscription = new Subscription(company, this.configuration.getPlanById(plan.id), subscriptionData.status, new Date(subscriptionData.expires))
      company.subscriptions.set(plan.id, subscription)

      this.updateUserCompanySubscriptions(company)
    }
  }

  // ----------------------------------------------------

  async checkDocumentPaymentRequirements(
    document: Document
  ): Promise<IDocumentPaymentRequirements> {
    const { id, documentType } = document

    const { features } = this.configuration.documentTypeInfo.get(documentType)

    const result: IDocumentPaymentRequirements = {
      hasRequirements: true,
      featuresRequired: features.length > 0,
      hasRequiredFeatures: !document.requiresFeature,
      requiredFeatures: [],
      purchaseRequired: document.requiresPurchase,
    }

    if (result.featuresRequired) {
      result.requiredFeatures = [ ...features ]
    }

    if (result.purchaseRequired) {
      result.requiredPurchase = await this.getDocumentProductRequirement(document.company.id, id)
    }

    result.hasRequirements = result.hasRequiredFeatures && !result.purchaseRequired

    return result
  }

  // ----------------------------------------------------

  getDocumentProductRequirement(
    companyId: string,
    documentId: string,
  ): Promise<IProductHowMuchData> {
    return firstValueFrom(this.api
      .one('companies', companyId)
      .one('documents', documentId)
      .get<IProductHowMuchData>('howMuch'))
  }

  // ----------------------------------------------------

  getProductPricing(
    companyId: string,
    productId: ProductId,
  ): Promise<IProductHowMuchData> {
    return firstValueFrom(this.api
      .one('companies', companyId)
      .all(productId)
      .get<IProductHowMuchData>('howMuch'))
  }

  // ----------------------------------------------------

  getPlansPrices(
    company: Company
  ): Promise<IPlanHowMuchData[]> {
    return firstValueFrom(this.api
      .one('companies', company.id)
      .get('howMuch'))
  }

  // ----------------------------------------------------

  getHowMuchPlan(
    company: Company,
    plan: Plan,
    billingPeriod: BillingPeriod
  ): Promise<IPlanHowMuchData> {
    return firstValueFrom(this.api
      .one('companies', company.id)
      .withParams({ plan: plan.id, billingPeriod })
      .get('howMuch'))
  }

  // ----------------------------------------------------

  async addNewCard(
    companyId: string,
    label: string,
    card: stripe.elements.Element
  ) {
    const cardSetupIntent = await this.paymentService.addNewCard(companyId, label)

    try {
      await this.stripeService.confirmCardSetup(cardSetupIntent.clientSecret, {
        payment_method: { card }
      })
    } catch (e) {
      this.planDialogService.showPaymentMethodAuthenticationFailedDialog()
    }
  }

  // ----------------------------------------------------

  async deleteCard(
    companyId: string,
    card: CardWithExpiry
  ): Promise<boolean> {
    const confirm = await this.planDialogService.showRemoveCardConfirmDialog(card)

    if (confirm) {
      const success = await this.paymentService.removeCard(companyId, card.id)
      return success
    }
    return false
  }

  // ----------------------------------------------------

  async subscribeToPlan(
    company: Company,
    plan: Plan,
    billingPeriod: BillingPeriod,
    user: User,
    showToast: boolean
  ): Promise<Subscription> {
    let planPurchased = false

    const currentSubscription = company.getActiveSubscription()

    // Hack for SeedLegals employees to switch plans without paying
    if (this.isUserWithStaffAccess(company, user)) {
      planPurchased = !!await this.paymentService.doPlanSubscription(company, plan, plan.adminFreebieBillingPeriod)
    } else {
      const priceInfo = await this.getHowMuchPlan(company, plan, billingPeriod)

      const hasDefaultCard = await this.planDialogService.showSubscriptionDialog(company, priceInfo)
      if (!hasDefaultCard) {
        return
      }

      this.planDialogService.showWaitDialog()

      const subscriptionResponse = await this.paymentService.doPlanSubscription(
        company,
        plan,
        billingPeriod
      )

      if (subscriptionResponse && subscriptionResponse.status === 'requires_action') {
        const paymentIntent = await this.stripeService.confirmCardPayment(subscriptionResponse.stripeClientSecret)
        planPurchased = !!paymentIntent
      } else if (subscriptionResponse && subscriptionResponse.status === 'success') {
        planPurchased = true
      }

      this.planDialogService.hideWaitDialog()
      if (showToast) {
        this.planDialogService.showSuccessOrErrorToast(planPurchased)
      } else {
        this.planDialogService.showSuccessOrErrorDialog(planPurchased)
      }
    }

    if (!planPurchased) {
      return null
    }

    // clear old active subsctiption
    if (currentSubscription) {
      currentSubscription.setAsCancelled()
    }

    let subscription: Subscription = company.getSubscription(plan.id)

    if (this.isUserWithStaffAccess(company, user)) {
      if (!subscription) {
        subscription = new Subscription(company, plan, SubscriptionStatuses.AdminFreebie, null, plan.adminFreebieBillingPeriod)
        company.subscriptions.set(plan.id, subscription)
      } else {
        subscription.setAsAdminFreebie()
        subscription.setBillingPeriod(plan.adminFreebieBillingPeriod)
      }
    } else {
      if (!subscription) {
        subscription = new Subscription(company, plan, SubscriptionStatuses.Subscribed, null, billingPeriod)
        company.subscriptions.set(plan.id, subscription)
      } else {
        subscription.setAsSubscribed()
        subscription.setBillingPeriod(billingPeriod)
      }
    }

    // Update accessibility of loaded documents based on feature set after new subscription
    const companyFeatures = company.getAllFeatures()

    for (const document of company.documents.items()) {
      if (document.requiresFeature) {
        const { features } = this.configuration.documentTypeInfo.get(document.documentType)

        if (features.every(feature => companyFeatures.has(feature))) {
          document.accessibility = document.accessibility === DocumentAccessibility.MissingFeature
            ? DocumentAccessibility.Granted
            : DocumentAccessibility.MissingProduct
        }
      }
    }

    this.updateUserCompanySubscriptions(company)

    return subscription
  }

  // ----------------------------------------------------

  async unsubscribeFromPlan(
    company: Company,
    plan: Plan,
    user: User
  ): Promise<void> {

    const answers = await this.planDialogService.showUnsubscribeDialog()

    if (answers) {
      this.planDialogService.showWaitDialog()

      await this._removeSubscription(company, plan, user, { ...answers, user_id: user.id })

      this.planDialogService.hideWaitDialog()
    }
  }

  // ----------------------------------------------------

  isUserWithStaffAccess(
    company: Company,
    user: User,
  ): boolean {
    if (user.isPlatformAdmin) {
      return true
    }

    const access = user.accesses.findAccessForCompany(company)

    // Hack for SeedLegals employees to switch plans without paying
    return access
      ? access.hasAccessRole(AccessRole.StaffRead) || access.hasAccessRole(AccessRole.StaffWrite)
      : false
  }

  // ----------------------------------------------------

  private async _removeSubscription(
    company: Company,
    plan: Plan,
    user: User,
    answers: object = {}
  ): Promise<void> {
    const _subscription = await firstValueFrom(this.api
      .one('companies', company.id)
      .one('plans')
      .one(plan.id)
      .remove<ISubscriptionData>(
        {},
        {
          body: {
            answers
          }
        },
      ))

    company.getSubscription(plan.id).setAsCancelled(_subscription.expires)

    this.updateUserCompanySubscriptions(company)
  }

  // ----------------------------------------------------

  private updateUserCompanySubscriptions(company: Company) {
    const subscriptions = [ ...company.subscriptions.values() ].map(s => {
      return {
        plan: {
          id: s.plan.id,
          features: [ ...s.plan.features ],
          includes: [ ...s.plan.includes ]
        },
        status: s.status,
        expires: s.expires?.toISOString() ?? null,
        billingPeriod: s.billingPeriod
      }
    })

    this.usersFacade.updateUserCompany(company.id, { subscriptions })
  }

  // ----------------------------------------------------

  async purchaseEvent(
    user: User,
    event: Event,
    productId: ProductId,
    stage: PurchaseStage = PurchaseStages.Balance,
  ): Promise<boolean> {
    const product = this.configuration.getProductById(productId)

    const howMuch = await this.getHowMuch(event, stage, event.company, product)

    let payload: TransactionRequestData

    if (howMuch.bundles.length > 0) {
      payload = await this.planDialogService.showPriceInfoSidenav(howMuch, event)
    } else if (product.standalone) {
      payload = {
        kind: 'product',
        product: productId,
        stage,
      }
    } else {
      payload = {
        kind: 'entity',
        entity: event.link,
        stage,
      }
    }

    if (!payload) {
      return false
    }

    return this.doProductPurchase(
      event.company,
      user,
      payload,
      howMuch,
      event,
      product
    )
  }

  // ----------------------------------------------------

  purchaseProduct(
    user: User,
    event: Event,
    stage: PurchaseStage = PurchaseStages.Balance,
    priceInfo: IProductHowMuchData,
  ): Promise<boolean> {
    return this.doProductPurchase(
      event.company,
      user,
      {
        kind: 'entity',
        entity: event.link,
        stage
      },
      priceInfo
    )
  }

  // ----------------------------------------------------

  purchaseStandaloneProduct(
    user: User,
    company: Company,
    product: ProductId,
    priceInfo: IProductHowMuchData,
  ): Promise<boolean> {
    return this.doProductPurchase(
      company,
      user,
      {
        kind: 'product',
        product,
        stage: PurchaseStages.Balance
      },
      priceInfo
    )
  }

  // ----------------------------------------------------

  purchaseBundle(
    user: User,
    company: Company,
    payload: TransactionRequestData,
    howMuch: IProductHowMuchData,
  ): Promise<boolean> {
    return this.doProductPurchase(
      company,
      user,
      payload,
      howMuch
    )
  }

  // ----------------------------------------------------

  private async getPaymentMethodId(
    company: Company,
    user: User,
    priceInfo: IProductHowMuchData,
    payload: TransactionRequestData
  ): Promise<string> {
    const paymentDetails = {
      description: priceInfo.description,
      amount: priceInfo.billedWithVat ? priceInfo.discountedAmountInclVAT : priceInfo.discountedAmount,
      paymentId: '',
      currency: priceInfo.currency
    }

    if (this.isUserWithStaffAccess(company, user) || paymentDetails.amount === 0 && payload.kind !== 'bundle') {
      paymentDetails.paymentId = ''
    } else {
      if (payload.kind === 'bundle') {
        paymentDetails.description = payload.description
        paymentDetails.amount = payload.price
      }
      paymentDetails.paymentId = await this.planDialogService.showCardSelectionDialog(
        company,
        { description: paymentDetails.description, currency: paymentDetails.currency },
        paymentDetails.amount,
        priceInfo.product
      )
    }

    return paymentDetails.paymentId
  }

  // ----------------------------------------------------

  private async makePayment(
    company: Company,
    payload: TransactionRequestData,
    paymentMethodId: string
  ): Promise<{ companyProducts: CompanyProduct[], rewards: FreeTrialReward[] } | null> {

    const purchaseResponse = await this.paymentService.purchase(company, {
      ...payload,
      paymentMethodId
    })

    if (!purchaseResponse) {
      return null
    }

    let companyProducts: CompanyProduct[]

    if (purchaseResponse.stripeClientSecret) {
      const paymentIntent = await this.stripeService.confirmCardPayment(purchaseResponse.stripeClientSecret)

      if (!paymentIntent) {
        return null
      }

      const transaction = await this.pollTransaction(purchaseResponse.invoiceId)
      companyProducts = await this.paymentService.getCompanyProductByTransactionId(company, transaction.id)
    } else {
      companyProducts = await this.paymentService.getCompanyProductByTransactionId(company, purchaseResponse.id)
    }

    return {
      companyProducts,
      rewards: purchaseResponse.rewards
    }
  }

  // ----------------------------------------------------

  private async pollTransaction(
    invoiceId: string
  ): Promise<Transaction> {
    return firstValueFrom(interval(2000).pipe(
      take(3),
      switchMap(() => from(this.paymentService.getTransactionByInvoiceId(invoiceId))),
      filter(t => !!t),
      take(1)
    ))
  }

  // ----------------------------------------------------

  private async doProductPurchase(
    company: Company,
    user: User,
    payload: TransactionRequestData,
    priceInfo: IProductHowMuchData,
    event?: Event,
    product?: Product
  ): Promise<boolean> {
    let hasSubscription = !!company.getActiveNonTrialSubscription()

    if (!hasSubscription) {
      hasSubscription = await this.planDialogService.showCompanySubscriptionDialog(company, user)
      if (product && event) {
        priceInfo = await this.getHowMuch(event, priceInfo.stage, company, product)
      }
    }

    // You cannot purchase products without an active non-trial subscription
    if (hasSubscription) {
      if (!this.isUserWithStaffAccess(company, user) && payload.kind !== 'bundle') {
        const res = await this.planDialogService.showPriceInfoDialog(priceInfo)
        if (!res) {
          return false
        }
      }

      const paymentMethodId: string = await this.getPaymentMethodId(company, user, priceInfo, payload)

      if (paymentMethodId === undefined) {
        return false
      }

      this.planDialogService.showWaitDialog()

      const result = await this.makePayment(company, payload, paymentMethodId)

      this.planDialogService.hideWaitDialog()

      if (!result) {
        this.planDialogService.showErrorDialog()
        return false
      }

      if (priceInfo.amount > 0) {
        this.planDialogService.showSuccessDialog()
      }

      return true
    }
    return false
  }

  // ----------------------------------------------------

  // May come back in order to extend subscriptions instead
  private extendFreeTrial(company: Company, rewards: FreeTrialReward[], priceInfo: IProductHowMuchData) {
    if (rewards.length > 0) {
      let days = 0

      // Apply free trial extension reward for certain purchases
      for (const reward of rewards) {
        const rewardedSubscription = company.getSubscription(reward.plan)

        if (rewardedSubscription && rewardedSubscription.isFreeTrial) {
          rewardedSubscription.extendExpiry(reward.days)
          days = Math.max(days, reward.days)
        }
      }

      // Show success modal
      this.planDialogService.showSuccessDialog(days)
    } else {
      // No need to show anything if it's a free product unlock - there's already a toast
      if (priceInfo.amount > 0) {
        this.planDialogService.showSuccessDialog()
      }
    }
  }

  // ----------------------------------------------------

  private async getHowMuch(
    event: Event,
    stage: PurchaseStage = PurchaseStages.Balance,
    company: Company,
    product: Product
  ): Promise<IProductHowMuchData> {
    return product.standalone
      ? await this.getHowMuchForAStandalonePurchase(company, product.id)
      : await this.getHowMuchForAPurchase(event, stage)
  }

  // ----------------------------------------------------

  getHowMuchForAPurchase(
    event: Event,
    stage: PurchaseStage = PurchaseStages.Balance,
  ): Promise<IProductHowMuchData> {
    return firstValueFrom(this.api
      .one('companies', event.company.id)
      .one(event.domain, event.id)
      .all(stage)
      .get<IProductHowMuchData>('howMuch'))
  }

  // ----------------------------------------------------

  getHowMuchForAStandalonePurchase(
    company: Company,
    productId: string
  ): Promise<IProductHowMuchData> {
    return firstValueFrom(this.api
      .one('companies', company.id)
      .all(productId)
      .get<IProductHowMuchData>('howMuch'))
  }

  // ----------------------------------------------------

  useBundleSlot(
    companyProductId: string,
    entity: string,
    entityName: string,
  ): Promise<CompanyProduct> {
    return firstValueFrom(this.restApi
      .one('companyProducts', companyProductId)
      .patch({
        [ entityName ]: entity,
        used: new Date()
      }))
  }

  // ----------------------------------------------------

  constructor(
    @Inject(RestApi) private restApi: BackendService,
    @Inject(Api) private api: BackendService,
    private configuration: Configuration,
    private paymentService: PaymentsService,
    private stripeService: StripeService,
    @Inject(Router) private router: Router,
    private planDialogService: PlanDialogService,
    private usersFacade: UsersFacade,
    private analyticsService: AnalyticsService
  ) {}

}
