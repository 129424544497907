import { flatten } from 'ramda'

import { Collection } from '../collection'
import { OnCompanyEventModel, ApiFieldSpec } from '../model'

import { Document, DocumentCollection } from '../documents/document'
import { ComplianceEvent } from './compliance-event'
import { InvestmentScheme } from '../../models'
import { ShareClass } from '../stock/share-class'
import { isoFormat, today } from '@libs/utils'

// ------------------------------------------------------------

export class Statement extends OnCompanyEventModel<ComplianceEvent> {
  readonly domain = 'statements'
  readonly icon = 'note-multiple-outline'

  effectiveDate: string
  uir: string | null

  scheme: InvestmentScheme
  shareClass: ShareClass

  documents = new DocumentCollection()

  // ----------------------------------------------------

  constructor({
    event,
    uir = null,
    effectiveDate = isoFormat(today()),
    ...data
  }) {
    super({ ...data, event, uir, effectiveDate })
  }

  // ----------------------------------------------------

  get scopeField() {
    return 'statement'
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [ 'uir' ]
  }

  // ----------------------------------------------------

  getShares() {
    return this.event.company.getActiveShares()
      .filter(s => s.scheme === this.scheme && s.shareClass.id === this.shareClass.id && s.acquired === this.effectiveDate)
  }

  // ----------------------------------------------------

  getEisClaims() {
    return this.getShares()
      .filter(share => share.eisClaim)
      .map(share => share.eisClaim)
  }
}

// ------------------------------------------------------------

export class StatementCollection extends Collection<Statement> {
  getAllDocuments(): Document[] {
    return flatten(this.items().map(statement => statement.documents.items()))
  }

  getAllEisClaims(): Document[] {
    return flatten(this.items().map(statement => statement.getEisClaims()))
  }
}
