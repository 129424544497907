import { Injectable } from '@angular/core'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  hasItem(key: string): boolean {
    return localStorage.getItem(key) !== null
  }

  // ----------------------------------------------------

  keys(): string[] {
    return Object.keys(localStorage)
  }

  // ----------------------------------------------------

  getItem(key: string, defaultValue?: string): string | undefined {
    return localStorage.getItem(key) || defaultValue
  }

  // ----------------------------------------------------

  getObject<T>(key: string): T | undefined
  getObject<T>(key: string, defaultValue: T): T

  getObject<T>(key: string, defaultValue?: T): T | undefined {
    const stringValue = localStorage.getItem(key)

    return stringValue
      ? JSON.parse(stringValue)
      : defaultValue
  }

  // ----------------------------------------------------

  setItem(key: string, value: string): string {
    localStorage.setItem(key, value)

    return value
  }

  // ----------------------------------------------------

  setObject<T>(key: string, value: T): T {
    const stringValue = JSON.stringify(value)

    localStorage.setItem(key, stringValue)

    return value
  }

  // ----------------------------------------------------

  removeItem(
    key: string
  ) {
    localStorage.removeItem(key)
  }

  // ----------------------------------------------------

  removeItems(
    ...keys: string[]
  ) {
    for (const k of keys) {
      this.removeItem(k)
    }
  }

}
