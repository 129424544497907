import { formatDate } from '@angular/common'

import { evolve } from 'ramda'

import {
  addDays,
  addYears,
  differenceInDays,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
  formatISO,
  isAfter,
  isBefore,
  isValid,
  isWithinInterval,
  max,
  min,
  parseISO,
  startOfDay,
  subDays
} from 'date-fns'

// ------------------------------------------------------------
// Functions returning Date instances

export const today = () => startOfDay(new Date())

export const isoParse = (v: string): Date => parseISO(v.split('T')[ 0 ])
export const isoFormat = (v: number | Date): string => formatISO(v, { representation: 'date' })

export const asDate = (v: string | Date): Date => {
  return typeof v === 'string'
    ? isoParse(v)
    : v
}

export const asIsoString = (v: string | Date | null): string | null => {
  if (v instanceof Date) {
    return isValid(v) ? isoFormat(v) : null
  } else if (typeof v === 'string') {
    return isValid(isoParse(v)) ? v : null
  } else {
    return null
  }
}

// ------------------------------------------------------------
// Functions comparing dates

export const isSameOrAfter = (a: Date, b: Date) => !isBefore(a, b)
export const isSameOrBefore = (a: Date, b: Date) => !isAfter(a, b)

// ------------------------------------------------------------
// Functions providing single max/min date from multiple dates

export const getLatest = (dates: Array<string | Date | null>): string | null => {
  const latest = max(dates.flatMap(d => d ? [ asDate(d) ] : []))
  return isNaN(latest.getDate()) ? null : asIsoString(latest)

}
export const getEarliest = (dates: Array<string | Date | null>): string | null => {
  const earliest = min(dates.flatMap(d => d ? [ asDate(d) ] : []))
  return isNaN(earliest.getDate()) ? null : asIsoString(earliest)
}

// ------------------------------------------------------------
// Functions acting on ISO date strings

export const getIsoDayBefore = (d: string | Date) => isoFormat(subDays(asDate(d), 1))
export const getIsoDayAfter = (d: string | Date) => isoFormat(addDays(asDate(d), 1))

// ------------------------------------------------------------
// Functions formatting dates

export const isoToday = () => isoFormat(today())

export const hrEnGbFormat = (d: string | Date) => format(asDate(d), 'd MMM y')

export function fromNow(
  date: string | Date,
  addSuffix = true
): string {
  date = asDate(date)

  if (isNaN(date.getDate())) {
    throw new Error('Invalid date')
  }

  if (Math.abs(differenceInDays(date, today())) === 0) {
    return $localize`today`
  }

  return formatDistanceToNowStrict(date, {
    addSuffix,
    roundingMethod: isAfter(date, today()) ? 'ceil' : 'floor'
  })
}

// ----------------------------------------------------------

export const formatDateRange = (
  startDate: string | Date,
  endDate: string | Date,
  locale = 'en'
) => formatDate(startDate, 'd MMM yyyy', locale) + ' — ' + formatDate(endDate, 'd MMM yyyy', locale)

// ------------------------------------------------------------

export function daysUntil(
  date: string | Date,
): number {
  date = asDate(date)

  if (isNaN(date.getDate())) {
    throw new Error('Invalid date')
  }

  return Math.max(differenceInDays(date, today()), 0)
}

// ------------------------------------------------------------

export function taxYearsForInterval(start: string, end: string): string[] {
  const startDate = asDate(start)
  const endDate = asDate(end)

  const years = differenceInYears(endDate, startDate)

  if (years === 1) {
    return [ startDate.getFullYear().toString(), (startDate.getFullYear() + 1).toString() ]
  }

  return [ startDate.getFullYear().toString() ]
}

// ------------------------------------------------------------

export function taxYearRangesForInterval(
  start: string,
  end: string
): { from: string, to: string }[] {
  const startDate = asDate(start)
  const endDate = asDate(end)

  const years = differenceInYears(endDate, startDate)

  if (years === 1) {
    const start2 = addYears(startDate, 1)

    return [
      { from: start, to: isoFormat(subDays(start2, 1)) },
      { from: isoFormat(start2), to: end },
    ]
  } else {
    return [
      { from: start, to: end },
    ]
  }
}

// ------------------------------------------------------------

export function isCurrentTaxYearUK(date: string): boolean {
  // the month is 0-indexed
  const taxYear = { month: 3, day: 6 }
  const currentDate = new Date(),
  currentYear = currentDate.getFullYear(),
  currentMonth = currentDate.getMonth(),
  currentDay = currentDate.getDate()

  const [taxYearStart, taxYearEnd] = 
    currentMonth < taxYear.month || (currentMonth === taxYear.month && currentDay < taxYear.day) ?
    [currentYear - 1, currentYear] : [currentYear, currentYear + 1]
  
  return isWithinInterval(new Date(date), {
    start: new Date(taxYearStart, taxYear.month, taxYear.day),
    end: new Date(taxYearEnd, taxYear.month, taxYear.day)
  })
}

// ------------------------------------------------------------
// Functions parsing dates

export const parseInsertedDate = evolve({
  inserted: parseISO
})

export const dateEvolver = evolve({
  inserted: parseISO,
  modified: parseISO,
  updated: parseISO,
  issued: parseISO,
  approved: parseISO,
  effectiveDate: parseISO,
  expires: parseISO,
  incorporated: parseISO,
  invited: parseISO,
  signed: parseISO
})
