import { createAction, props, union } from '@ngrx/store'

import { Card, CardWithExpiry } from '../models/cards'

// ----------------------------------------------------------

export const LoadCards = createAction(
  '[Payments] Load Cards',
  props<{ companyId: string }>()
)

export const LoadCardsSuccess = createAction(
  '[Payments] Load Cards Success',
  props<{ cards: Card[] }>()
)

export const LoadCardsError = createAction(
  '[Payments] Load Cards Error',
  props<{ error: any }>()
)

// ----------------------------------------------------------

export const AddNewCard = createAction(
  '[Payments] Add New Card',
  props<{ companyId: string, label: string, cardElement: stripe.elements.Element }>()
)

export const AddNewCardSuccess = createAction(
  '[Payments] Add New Card Success',
  props<{ companyId: string }>()
)

export const AddNewCardError = createAction(
  '[Payments] Add New Card Error',
  props<{ error: any }>()
)

// ----------------------------------------------------------

export const SetDefaultCard = createAction(
  '[Payments] Set Default Card',
  props<{ companyId: string, cardId: string }>()
)

export const SetDefaultCardSuccess = createAction(
  '[Payments] Set Default Card Success',
  props<{ companyId: string, cardId: string }>()
)

export const SetDefaultCardError = createAction(
  '[Payments] Set Default Card Error',
  props<{ error: any }>()
)

// ----------------------------------------------------------

export const DeleteCard = createAction(
  '[Payments] Delete Card',
  props<{ companyId: string, card: CardWithExpiry }>()
)

export const DeleteCardSuccess = createAction(
  '[Payments] Delete Card Success',
  props<{ companyId: string, cardId: string }>()
)

export const DeleteCardError = createAction(
  '[Payments] Delete Card Error',
  props<{ error: any }>()
)

// ----------------------------------------------------------

const actions = union({
  LoadCards,
  LoadCardsSuccess,
  LoadCardsError,
  AddNewCard,
  AddNewCardSuccess,
  AddNewCardError,
  SetDefaultCard,
  SetDefaultCardSuccess,
  SetDefaultCardError,
  DeleteCard,
  DeleteCardSuccess,
  DeleteCardError
})

export type PaymentsAction = typeof actions
