import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'

// ------------------------------------------------

@Directive({
  selector: '[slLoading]'
})
export class LoadingDirective {
  @Input() set slLoading(showSpinner: boolean) {
    if (showSpinner) {
      this.renderer.addClass(this.el.nativeElement, 'loading-button')
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'loading-button')
    }
  }

  // ------------------------------------------------

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }
}
