import { Component } from '@angular/core'

// ------------------------------------------------------------

@Component({
  selector: 'sl-sidenav-stepper-prequisite-failure',
  templateUrl: './sidenav-stepper-prequisite-failure.component.html',
  styleUrls: [ './sidenav-stepper-prequisite-failure.component.scss' ]
})
export class SidenavStepperPrequisiteFailureComponent {}
