import { Directive, HostListener } from '@angular/core'

import { LayoutFacade } from '@app/layout/+state/layout.facade'

// ----------------------------------------------------------

@Directive({
  selector: '[slSidenavClose]'
})
export class SidenavCloseDirective {
  @HostListener('click') onClick() {
    this.layout.sideNavClose()
  }

  // ----------------------------------------------------

  constructor(
    private layout: LayoutFacade
  ) {}
}
