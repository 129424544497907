import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'

import { PaymentsEffects } from './+state/payments.effects'

// ----------------------------------------------------------

@NgModule({
  imports: [
    EffectsModule.forFeature([ PaymentsEffects ])
  ]
})
export class PaymentsStateModule {}
