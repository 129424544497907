import { AfterContentInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validator } from '@angular/forms'

import { equals, pick } from 'ramda'

import { CountryCode, IUserFormData, User } from '@libs/models'

import { UserService } from '@app/users/services/user.service'

import { FormControlComponent, getValueProvider, getValidatorProvider, updateFormControlRequired } from '@libs/utils'

// ----------------------------------------------------------

const userComparePicker = pick([ 'firstName', 'lastName', 'address' ])

const userCreatePicker = pick([ 'email', 'firstName', 'lastName', 'address' ])

// ----------------------------------------------------------

@Component({
  selector: 'sl-user-form',
  templateUrl: './user-form.component.html',
  providers: [
    getValueProvider(UserFormComponent),
    getValidatorProvider(UserFormComponent)
  ]
})
export class UserFormComponent extends FormControlComponent<IUserFormData>
  implements AfterContentInit, OnChanges, Validator {

  @Input() user: User | null = null
  @Input() required = false
  @Input() showAddress = true
  @Input() requireAddress = false
  @Input() requestLegalName = true

  /** Default values that are used to populate form fields. */
  @Input() defaultUserData?: Partial<IUserFormData>
  @Input() defaultCountry?: CountryCode

  @Output() changed = new EventEmitter<IUserFormData>()
  @Output() userSaved = new EventEmitter<User>()

  email: string | null = null

  // ----------------------------------------------------

  constructor(
    fb: UntypedFormBuilder,
    private userService: UserService
  ) {
    super(fb)
  }

  // ----------------------------------------------------------

  ngOnChanges() {
    updateFormControlRequired(this.controls, 'firstName', this.required)
    updateFormControlRequired(this.controls, 'lastName', this.required)
    updateFormControlRequired(this.controls, 'address', this.required && this.requireAddress)

    this.controls.updateValueAndValidity()
  }

  // ----------------------------------------------------------

  ngAfterContentInit() {
    if (this.user) {
      this.controls.patchValue({ id: this.user.id })

      this.emailCtrl.disable({ emitEvent: false })
    }

    if (this.defaultUserData) {
      this.controls.patchValue(this.defaultUserData)

      if (this.defaultUserData.email) {
        this.searchForUser()
      }
    }
  }

  // ----------------------------------------------------------

  getControlGroup(): UntypedFormGroup {
    return this.fb.group({
      id: [ null ],
      connected: [ false ],
      email: [ null ],
      firstName: [ null ],
      lastName: [ null ],
      address: [ null ]
    })
  }

  // ----------------------------------------------------

  get emailCtrl() { return this.controls.get('email') }

  // ----------------------------------------------------

  validate() {
    if (this.required && this.controls.status === 'INVALID') {
      return {
        required: true
      }
    }

    return null
  }

  // ----------------------------------------------------

  override changeValue(modelValue: IUserFormData): void {
    super.changeValue(modelValue)

    // _log(`UserFormComponent.changeValue(modelValue): status, controls`, modelValue, this.controls.status, this.controls, this)

    this.changed.emit(modelValue)
  }

  // ----------------------------------------------------

  async searchForUser() {
    const emailCtrl = this.emailCtrl

    if (emailCtrl.invalid || emailCtrl.value === this.email) {
      return
    }

    this.email = emailCtrl.value
    this.user = await this.userService.findUserByEmail(this.email)

    if (this.user) {
      updateFormControlRequired(this.controls, 'address', false)

      this.controls.patchValue({
        id: this.user.id,
        connected: this.user.connected,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        address: this.user.address
      })

      emailCtrl.disable({ emitEvent: false })
    } else {
      this.controls.patchValue({
        id: null,
        connected: false
      })
    }
  }

  // ----------------------------------------------------

  async getUser() {
    if (!this.controls.valid) {
      return null
    }

    const userData = this.controls.value

    if (this.user) {
      if (!this.user.connected) {
        const patchData = userComparePicker(userData)

        if (!equals(patchData, userComparePicker(this.user))) {
          await this.userService.patchUser(this.user, patchData)
        }
      }
    } else {
      this.user = await this.userService.createUser(userCreatePicker(userData))

      this.controls.patchValue({
        id: this.user.id
      })

      this.emailCtrl.disable({ emitEvent: false })
    }

    // _log(`UserForm.getUser(): originalUser, this.user, userData`, originalUser, this.user, userData)

    this.userSaved.emit(this.user)
  }
}
