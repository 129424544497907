import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core'

@Component({
  selector: 'sl-action-item',
  templateUrl: './action-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionItemComponent implements OnInit {
  @Output() actionClick = new EventEmitter()
  @Input() disabled = false
  @Input() warn = false
  @Input() icon: string

  ariaId: string

  ngOnInit() {
    this.ariaId = `mit-${Math.random().toString().slice(2)}`
  }
}
