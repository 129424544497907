import { type EntityType, EntityTypes } from '../models/model.model'
import { Locale } from '../models/locale.model'
import { AccountRole, Sex, type UserAdditionalInfo, type UtmParams } from '../models/user.model'
import type { IUserCompany } from '../models/user-company.model'

import { AccessCollection } from './access'
import { AppointmentCollection, type Appointment } from './appointment'
import type { Company } from './company'
import { Entity } from './entity'

import { environment } from '@env/environment'

// ------------------------------------------------------------

export class User extends Entity {
  readonly entityType: EntityType = EntityTypes.User

  name = ''
  connected = false
  email: string | null
  phone: string | null
  emailToken: string | null
  firstName: string
  lastName: string
  sex: Sex
  override facebook: string | null
  override twitter: string | null
  override linkedin: string | null
  additionalInfo: UserAdditionalInfo
  signature: string | null
  invited: string | null

  accesses = AccessCollection.getAccessCollectionForUser()
  appointments = AppointmentCollection.getAppointmentCollectionForUser()
  userCompanies: IUserCompany[] = []
  utmParams: UtmParams
  role: AccountRole
  locale: Locale

  // ----------------------------------------------------

  constructor({
    firstName = '',
    lastName = '',
    sex = Sex.NotKnown,
    email = null,
    phone = null,
    emailToken = null,
    facebook = null,
    linkedin = null,
    twitter = null,
    additionalInfo = {},
    signature = null,
    isFullModel = false,
    locale = Locale.English,
    ...data
  }) {
    super({
      firstName,
      lastName,
      sex,
      email,
      phone,
      emailToken,
      facebook,
      linkedin,
      twitter,
      additionalInfo,
      signature,
      isFullModel,
      locale,
      ...data
    })
  }

  // ----------------------------------------------------

  override nameChanged() {
    this.name = this.firstName != null && this.lastName != null
      ? this.firstName + ' ' + this.lastName
      : ''

    super.nameChanged()
  }

  // ----------------------------------------------------

  get domain() {
    return `users`
  }

  // ----------------------------------------------------

  get defaultAvatar() {
    return 'user-nopic-180.png'
  }

  // ----------------------------------------------------

  get signatureUrl(): string | null {
    return this.signature
      ? environment.assetsLocation + this.signature
      : null
  }

  // ----------------------------------------------------

  get isPlatformAdmin(): boolean {
    return this.role === AccountRole.Admin
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'email',
      'phone',
      'firstName',
      'lastName',
      'sex',
      'connected',
      'twitter',
      'linkedin',
      'facebook',
      'additionalInfo'
    ]
  }

  // ----------------------------------------------------

  override customisePayload(payload, mode) {
    payload = super.customisePayload(payload, mode)

    if (this.email === '') {
      payload.email = null
    }

    return payload
  }

  // ----------------------------------------------------

  /**
   * Get a list of the companies for which we have an appointment with
   * read access i.e. has one/both of the READ and ADMIN roles.
   *
   * @deprecated
   * @returns {Company[]} Companies for which we have read access.
   */
  getCompaniesWithReadAccess(): Company[] {
    return this.appointments.transform((out, appointment) => {
      if (appointment.read) {
        out.push(appointment.company)
      }
    }, [])
  }

  // ----------------------------------------------------

  getAppointmentForCompany(company: Company | string): Appointment | undefined {
    const id = typeof company === 'string' ? company : company.id

    return this.appointments.findAppointmentForCompany({ id })
  }

  // ----------------------------------------------------

  override get isMissingEmail(): boolean {
    return !this.email
  }

  // ----------------------------------------------------

  /**
   * Check if this user has seen a given video.
   *
   * @param {string} videoId ID of the video
   * @returns {boolean}
   */
  hasSeenVideo(videoId: string): boolean {
    return (this.additionalInfo.seenVideos || []).includes(videoId)
  }

  // ----------------------------------------------------

  override toString() {
    return `${this.name} (${this.link})`
  }
}
