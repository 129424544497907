import { NgModule } from '@angular/core'

import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'

import { authReducer, AUTH_FEATURE_KEY } from './+state/auth.reducer'
import { AuthEffects } from './+state/auth.effects'

// ----------------------------------------------------------

@NgModule({
  imports: [
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
    EffectsModule.forFeature([
      AuthEffects
    ])
  ]
})
export class AuthStateModule {}
