<button mat-stroked-button
        [class.completed]="completed"
        [disabled]="disabled"
        (click)="onClicked($event)">
  <div fxLayoutAlign="start center">
    <mat-icon [fontIcon]="fontIcon"></mat-icon>
      <div fxFlex fxLayoutAlign="center">
        <mat-icon *ngIf="completed; else content"
                fontIcon="icon-check"
                class="check s24 ml-8"></mat-icon>
        <ng-template #content>
          <ng-content></ng-content>
        </ng-template>
      </div>
  </div>
</button>
