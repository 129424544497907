import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'

import {
  DocumentTypeId,
  Feature,
  IPlanData,
  IRegionData,
  Jurisdiction,
  Plan,
  PlanId,
  Plans,
  Product,
  ProductId,
  Region
} from '@libs/models'

import { ConfigurationLoaded } from '../+state/core.actions'

// ------------------------------------------------------------

export interface IConfigurationData {
  regions: IRegionData[]
  plans: IPlanData[]
  products: Product[]
  documentTypes: IDocumentTypeData[]
}

// ------------------------------------------------------------

export interface IDocumentTypeData {
  id: DocumentTypeId
  features: Feature[]
}

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class Configuration {
  regions: IRegionData[]
  plans: Plan[] = []
  products: Product[] = []
  documentTypeInfo = new Map<DocumentTypeId, IDocumentTypeData>()

  // ------------------------------------------------------

  getRegionConfigById(region: Region): IRegionData | undefined {
    return this.regions.find(regionData => regionData.id === region)
  }

  // ------------------------------------------------------

  getRegionByJurisdiction(jurisdiction: Jurisdiction): IRegionData | undefined {
    return this.regions.find(regionData => regionData.jurisdictions.includes(jurisdiction))
  }

  // ------------------------------------------------------

  getPlanById(id: PlanId): Plan | undefined {
    return this.plans.find(i => i.id === id)
  }

  // ------------------------------------------------------

  getPlanByFeatureAndJurisdiction(feature: Feature, jurisdiction: Jurisdiction): Plan | undefined {
    const planIds = this.getRegionByJurisdiction(jurisdiction).plans
    const potentialPlans: Plan[] = this.plans.filter(plan =>
      plan.features.has(feature) && planIds.includes(plan.id)
    )
    if (potentialPlans.length > 0) {
      return potentialPlans.find(p => p.id === Plans.Scale) || potentialPlans[ 0 ]
    }
    return undefined
  }

  // ------------------------------------------------------

  getProductById(id: ProductId): Product | undefined {
    return this.products.find(i => i.id === id)
  }

  // ------------------------------------------------------

  getDocumentTypes(): DocumentTypeId[] {
    return [ ...this.documentTypeInfo.keys() ]
  }

  // ------------------------------------------------------

  getRegions(): IRegionData[] {
    return this.regions
  }

  // ------------------------------------------------------

  initialise(
    data: IConfigurationData
  ): void {
    this.regions = data.regions
    this.products = data.products
    this.plans = data.plans.map(d => new Plan(d))

    for (const typeInfo of data.documentTypes) {
      this.documentTypeInfo.set(typeInfo.id, typeInfo)
    }

    this.store.dispatch(ConfigurationLoaded({ data }))
  }

  // ------------------------------------------------------

  constructor(
    private store: Store
  ) {}

}
