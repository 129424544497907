import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core'

import { MatExpansionPanel } from '@angular/material/expansion'

import { ProductNames } from '@libs/models'
import type { IBundleWithProductsData } from '../../models/bundles'

// ----------------------------------------------------------

@Component({
  selector: 'sl-bundle-price-info-panel',
  templateUrl: './bundle-price-info-panel.component.html',
  styleUrls: [ './bundle-price-info-panel.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundlePriceInfoPanelComponent implements AfterViewInit {

  @Input() bundle: IBundleWithProductsData
  @Input() withVat = false

  @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel

  readonly ProductNames = ProductNames

  // ----------------------------------------------------

  ngAfterViewInit() {
    setTimeout(() => {
      this.expansionPanel.close()
    }, 3000)
  }

}
