import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AccessRole, AppointmentRole, Company, User } from '@libs/models'

// ------------------------------------------------

@Directive({
  selector: '[slHasPermission]'
})
export class HasPermissionDirective {

  company: Company
  role: AppointmentRole

  // -----------------------------------------------

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private route: ActivatedRoute
  ) {
  }

  // ------------------------------------------------

  @Input()
  set slHasPermission(company: Company | null) {
    if (company) {
      this.company = company
    } else {
      this.company = this.route.snapshot.data.company
    }
    this.update()
  }

  // -------------------------------------------------

  @Input()
  set slHasPermissionRole(role: AppointmentRole) {
    this.role = role
    this.update()
  }

  // -------------------------------------------------

  update() {
    if (this.hasRole()) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef)
      }
    } else {
      this.viewContainer.clear()
    }
  }

  // --------------------------------------------------

  private hasRole(): boolean {
    if (!this.company) {
      return true
    }
    const user: User = this.route.snapshot.data.currentUser
    const access = user.accesses.findAccessForCompany(this.company)

    const isAdmin = user.isPlatformAdmin
      || access?.hasAccessRole(AccessRole.StaffWrite)
      || this.company.hasRole(user, AppointmentRole.Admin)

    return isAdmin || !this.role || this.company.hasRole(user, this.role)
  }

}
