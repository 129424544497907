import { Injectable } from '@angular/core'

import type { Company, Discount, Event, Feature, IPlanCheckoutData, IPlanHowMuchData, IProductHowMuchData, ProductId, TransactionRequestData, User } from '@libs/models'
import type { CardWithExpiry } from '../models/cards'
import type { IPaymentData, IPaymentSidenavData } from '../models/sidenav'

import { ModalService } from '@libs/modals'
import { SidenavService } from '@app/layout/services/sidenav.service'
import { ToastService } from '@libs/services'

import { pluralise } from '@libs/utils'

import { CardSelectionDialogComponent } from '../components/payment-dialog/card-selection-dialog.component'
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component'
import { LoadingDialogComponent } from '../components/loading-dialog/loading-dialog.component'
import { NewCardDialogComponent } from '../components/new-card-dialog/new-card-dialog.component'
import type { PaywallDialogDataResults, PaywallDialogData } from '../components/paywall-dialog/paywall-dialog.component'
import { PaywallDialogComponent } from '../components/paywall-dialog/paywall-dialog.component'
import { ProductBundlesSidenavComponent } from '../components/product-bundles-sidenav/product-bundles-sidenav.component'
import { ProductPriceDialogComponent } from '../components/product-price-dialog/product-price-dialog.component'
import { SubscriptionDialogComponent } from '../components/subscription-dialog/subscription-dialog.component'
import { SuccessDialogComponent } from '../components/success-dialog/success-dialog.component'
import type { IUnsubscribeDialogResult } from '../components/unsubscribe-dialog/unsubscribe-dialog.component'
import { UnsubscribeDialogComponent } from '../components/unsubscribe-dialog/unsubscribe-dialog.component'

import { CompanySubscriptionDialogComponent } from '@app/companies/modules/settings/components/company-subscription-dialog/company-subscription-dialog.component'

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class PlanDialogService {

  showPaywallDialog(
    company: Company,
    user: User,
    missingFeature?: Feature,
  ): Promise<PaywallDialogDataResults | undefined> {
    return this.modalService.show<PaywallDialogComponent, PaywallDialogData, PaywallDialogDataResults>(
      PaywallDialogComponent,
      {
        company,
        user,
        missingFeature
      }
    )
  }

  // ----------------------------------------------------

  showPriceInfoSidenav(
    howMuch: IProductHowMuchData,
    event: Event,
  ): Promise<TransactionRequestData> {
    return this.sidenavService.openSync<ProductBundlesSidenavComponent, { howMuch: IProductHowMuchData, event: Event }, TransactionRequestData>(ProductBundlesSidenavComponent, { howMuch, event })
  }

  // ----------------------------------------------------

  showPriceInfoDialog(
    data: IProductHowMuchData,
  ): Promise<boolean> {
    return this.modalService.show<ProductPriceDialogComponent, IProductHowMuchData, boolean>(
      ProductPriceDialogComponent,
      data
    )
  }

  // ----------------------------------------------------

  showNewCardDialog(
    company: Company,
  ): Promise<boolean> {
    return this.modalService.show(NewCardDialogComponent, { company }, { panelClass: 'sl-fullscreen-panel' })
  }

  // ----------------------------------------------------

  showRemoveCardConfirmDialog(
    card: CardWithExpiry,
  ): Promise<boolean> {
    const text = card.default
      ? $localize`This card is currently used for memberships`
      : ''

    return this.modalService.confirm({
      title: $localize`Remove card?`,
      ok: $localize`Remove`,
      text
    })
  }

  // ----------------------------------------------------

  showCardSelectionDialog(
    company: Company,
    paymentData: IPaymentData,
    price: number,
    productId? : ProductId
  ): Promise<string> {
    return this.modalService.show<CardSelectionDialogComponent, IPaymentSidenavData, string>(
      CardSelectionDialogComponent,
      {
        company,
        paymentData,
        price,
        productId
      },
      { panelClass: 'sl-fullscreen-panel' }
    )
  }

  // ----------------------------------------------------

  showCompanySubscriptionDialog(
    company: Company,
    user: User
  ): Promise<boolean> {
    return this.modalService.show(
      CompanySubscriptionDialogComponent,
      {
        company,
        user
      },
      { panelClass: 'sl-fullscreen-panel' }
    )
  }

  // ----------------------------------------------------

  showSubscriptionDialog(
    company: Company,
    item: IPlanHowMuchData,
  ): Promise<boolean> {
    return this.modalService.show<SubscriptionDialogComponent, IPlanCheckoutData, boolean>(
      SubscriptionDialogComponent,
      {
        company,
        item
      },
      { panelClass: 'sl-fullscreen-panel' }
    )
  }

  // ----------------------------------------------------

  showUnsubscribeDialog(): Promise<IUnsubscribeDialogResult> {
    return this.modalService.show<UnsubscribeDialogComponent, void, IUnsubscribeDialogResult>(
      UnsubscribeDialogComponent
    )
  }

  // ----------------------------------------------------

  showWaitDialog(): void {
    this.modalService.show(LoadingDialogComponent, {
      title: $localize`:Text while waiting a purchase to complete:Please wait... Processing... Thinking...`
    }, {
      panelClass: '',
      disableClose: true
    })
  }

  // ----------------------------------------------------

  hideWaitDialog(): void {
    this.modalService.closeAllDialogs()
  }

  // ----------------------------------------------------

  showSuccessDialog(
    freeTrialReward?: number
  ): void {
    const data: any = {
      title: $localize`Thank you for your purchase!`
    }

    if (freeTrialReward) {
      data.text = $localize`As a thank you from SeedLegals, your free trial has been extended by ${freeTrialReward} days.`
    }

    this.modalService.show(SuccessDialogComponent, data, { panelClass: '' })

    setTimeout(() => {
      this.modalService.closeAllDialogs()
    }, 4000)
  }

  // ----------------------------------------------------

  showErrorDialog(): void {
    this.modalService.show(ErrorDialogComponent, {
      title: $localize`Sorry, your payment has been declined.`,
      text: $localize`The most common reason is your card being declined. You may try with another one, or contact us.`
    }, {
      panelClass: ''
    })
  }

  // ----------------------------------------------------

  showPaymentMethodAuthenticationFailedDialog(): void {
    this.modalService.show(ErrorDialogComponent, {
      title: $localize`Sorry, your card has been declined.`,
      text: $localize`Your authentication has failed. You may try again, or contact us.`
    }, {
      panelClass: ''
    })
  }

  // ----------------------------------------------------

  showSuccessOrErrorDialog(
    success: boolean,
    freeTrialReward: number | null = null
  ): void {
    if (success) {
      this.showSuccessDialog(freeTrialReward)
    } else {
      this.showErrorDialog()
    }
  }

  // ----------------------------------------------------

  showSuccessOrErrorToast(
    success: boolean,
  ): void {
    if (success) {
      this.toastService.success($localize`Thank you for your purchase!`)
    } else {
      this.toastService.error($localize`Sorry, your payment has been declined.`)
    }
  }

  // ----------------------------------------------------

  showCouponsAppliedDialog(
    discounts: Discount[],
  ): Promise<boolean> {
    let text = discounts.length > 1
      ? $localize`These discounts will be applied at your next billing date:`
      : $localize`This discount will be available on your next `

    const items = discounts.map(discount => {
      const frequency = discount.billingPeriod.toLocaleLowerCase()
      let period = discount.billingPeriod === 'MONTHLY' ? 'month' : 'year'
      period = discount.numPeriods + ' ' + pluralise(discount.numPeriods, period)

      if (discounts.length === 1) {

        return $localize`${frequency} membership. ${discount.numPeriods > 0 ? 'It will be valid for ' + period + '.' : ''}`
      } else {

        return $localize`If ${frequency}, it will be valid for ${discount.numPeriods} ${frequency.substr(0, frequency.length - 2)}${discount.numPeriods === 1 ? '' : 's'}`
      }
    })

    let html = ''

    if (items.length === 1) {
      text += items[ 0 ]
    } else if (items.length > 1) {
      html = '<ul><li>' + items.join('</li><li>') + '</li></ul>'
    }

    return this.modalService.confirm({
      title: $localize`Coupon applied!`,
      text,
      html,
      ok: $localize`Go to memberships`,
      cancel: null
    })
  }

  // ----------------------------------------------------

  constructor(
    private readonly modalService: ModalService,
    private readonly sidenavService: SidenavService,
    private readonly toastService: ToastService
  ) {}

}
