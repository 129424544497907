import { EventCategories, EventCategory } from '../../models/category.model'
import { Jurisdiction } from '../../models/jurisdiction.model'
import { Event } from './event'
import { ProductId, Products } from '../money/product'
import { InvestmentScheme } from '../../models'
import type { ShareClass } from '../stock/share-class'
import type { ApiFieldSpec } from '../model'
import type { LoanSourceEvent } from './loan-event'
import { BackendService } from '@libs/backend'
import { InterestType } from '../loan/interest-type'
import { CohortTeam } from '../cohort-manager/cohort'
import { LoanCollection } from '../loan/loan'

// ------------------------------------------------------------

export class ConvertibleNoteEvent extends Event implements LoanSourceEvent {
  readonly domain = 'convertibleNotes'

  loans = new LoanCollection()

  readonly interestType = InterestType.None
  readonly interestRate = 0
  shareClass: ShareClass | null
  investmentScheme: InvestmentScheme
  discount: number
  historical: boolean
  cap: number
  lowValuation: number
  floorValuation: number
  maturityDate: string
  longstopDate: string
  cohortTeam: CohortTeam

  // ----------------------------------------------------

  constructor({
    historical = false,
    shareClass = null,
    investmentScheme = InvestmentScheme.Standard,
    discount = 0,
    cap = 0,
    lowValuation = 0,
    floorValuation = 0,
    longstopDate = null,
    cohortTeam = null,
    ...data
  }) {
    super({
      historical,
      investmentScheme,
      shareClass,
      discount,
      cap,
      lowValuation,
      floorValuation,
      longstopDate,
      cohortTeam,
      ...data
    })
  }

  // ----------------------------------------------------

  get loan() {
    return this.loans.item(0)
  }

  // ----------------------------------------------------

  get investor() {
    return this.loan?.investor ?? null
  }

  // ----------------------------------------------------

  get isFounderLoan(): boolean {
    return this.isFrance && this.investor?.isFounder
  }

  // ----------------------------------------------------

  get scheme() {
    return this.investmentScheme
  }

  // ----------------------------------------------------

  override get safeName() {
    const eventName = EventCategories[ this.category ]
    if (this.investor) {
      return `${eventName} - ${this.investor.name}`
    }

    return eventName
  }

  get safeNameWithoutInvestor() {
    return EventCategories[ this.category ]
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.loan?.amount ?? 0
  }

  // ----------------------------------------------------

  get category(): EventCategory {

    if (this.isFounderLoan) {
      return this.historical ? EventCategory.HistoricalFounderLoan : EventCategory.FounderLoan
    }

    if (this.isBSAAir) {
      return this.historical ? EventCategory.HistoricalBSAAIR : EventCategory.BSAAIR
    }

    if (this.isAPAC) {
      return this.historical ? EventCategory.HistoricalASA : EventCategory.SeedSAFE
    }

    return this.historical ? EventCategory.HistoricalASA : EventCategory.SeedFAST
  }

  // ----------------------------------------------------

  get product(): ProductId {
    //TODO: USE REGION!
    switch (this.company.jurisdiction) {
      case Jurisdiction.France:
        return Products.BSAAir
      default:
        return Products.ConvertibleNote
    }
  }

  // ----------------------------------------------------

  get isBSAAir(): boolean {
    return this.isFrance && !this.isFounderLoan
  }

  // ----------------------------------------------------

  get isFrance(): boolean {
    // TODO: This should be region based instead
    return this.company.jurisdiction === Jurisdiction.France
  }

  get isAPAC(): boolean {
    // TODO: This should be region based instead
    return this.company.jurisdiction === Jurisdiction.HongKong || this.company.jurisdiction === Jurisdiction.Singapore
  }

  // ----------------------------------------------------

  get isSAFE(): boolean {
    return this.answers[ 'note' ]?.type == "YCpost"
  }

  // ----------------------------------------------------


  get isValuationFixed(): boolean {
    return this.answers[ 'capAndDiscount' ]?.fixedValuation === true
  }

  // ----------------------------------------------------

  // is true when not set to false and not fixed valuation
  get isValuationCapped(): boolean {
    return !this.isValuationFixed && this.answers[ 'capAndDiscount' ]?.capEnabled !== false
  }

  // ----------------------------------------------------

  get fixedValuation(): number | null {
    return this.isValuationFixed
      ? this.answers[ 'capAndDiscount' ].valuation
      : null
  }

  // ----------------------------------------------------

  get valuationFloor(): number | null {
    return this.isValuationFixed
      ? 0
      : this.lowValuation
  }

  // ----------------------------------------------------

  get valuationCap(): number | null {
    return this.isValuationCapped && this.cap > 0
      ? this.cap
      : null
  }

  // ----------------------------------------------------

  get downRoundAllowed(): boolean {
    return !!this.answers[ 'investorRights' ]?.allowDownRound
  }

  // ----------------------------------------------------

  get isCohortFunding(): boolean {
    return !!this.cohortTeam
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'cohortTeam', include: 'create' },
      { key: 'historical', include: 'create' },
      'shareClass',
      'investmentScheme',
      'discount',
      'cap',
      'lowValuation',
      'floorValuation',
      'longstopDate'
    ]
  }

  // ----------------------------------------------------

  override async afterUpdate(api, responseData) {
    await super.afterUpdate(api, responseData)

    if ('maturityDate' in responseData) {
      this.maturityDate = responseData.maturityDate
    }
  }

  // ----------------------------------------------------

  override async beforeRemoval(api: BackendService) {
    if (this.loan) {
      await this.loan.remove(api)
    }
  }

  // ----------------------------------------------------

  getViewState() {
    const viewState = this.historical
      ? [ 'captable', 'debt-table' ]
      : [ 'raise', 'seed-fasts', this.id ]

    return {
      state: [ '/companies', this.company.id, ...viewState ]
    }
  }
}
