import { Collection } from '../collection'
import { Model, type ApiFieldSpec } from '../model'
import { type Company } from '../company'
import { type Event } from '../events/event'
import { type ShareTransferEvent } from '../events/share-transfer-event'
import { type Document, DocumentCollection } from '../documents/document'
import { InvestmentScheme } from '../../models/investment-event.model'
import { type Investment } from './investment'
import { type Investor } from './investor'
import { type ShareClass } from './share-class'

import { type BackendService } from '@libs/backend'

import { percentage } from '@libs/utils'

// ------------------------------------------------------------

export class Share extends Model {
  count: number
  issued: string | null
  certno: string | null
  annulled: boolean

  investmentScheme?: InvestmentScheme
  _investment: Investment

  event: Event
  shareTransfer?: ShareTransferEvent

  company: Company
  investor: Investor
  shareClass: ShareClass

  document?: Document
  documents = new DocumentCollection()

  // ----------------------------------------------------

  constructor({
    count = 0,
    issued = null,
    annulled = false,
    investment = null,
    event = null,
    investmentScheme = null,
    ...data
  }) {
    super({ count, issued, annulled, ...data })

    this._investment = investment
    this.setEvent(event)

    this.investmentScheme = investmentScheme as InvestmentScheme
  }

  // ----------------------------------------------------

  setEvent(event: Event | null = null) {
    if (event) {
      this.event = this[ event.fieldSingular ] = event
    } else if (this.event) {
      const oldEvent = this.event
      this.event = this[ oldEvent.fieldSingular ] = null
    } else {
      this.event = null
    }
  }

  // ----------------------------------------------------

  get domain() {
    return `shares`
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'shareClass', include: 'create' },
      { key: 'investor', include: 'create' },
      'count',
      'certno',
      'issued'
    ]
  }

  // ----------------------------------------------------

  override customisePayload(payload, mode) {
    payload = super.customisePayload(payload, mode)

    if (mode === 'create' && this._investment) {
      payload.investment = this._investment
    }

    // _out(`Share.customisePayload(mode = "${mode}"): fields, payload, share`, this.getApiFields(), payload, this)

    return payload
  }

  // ----------------------------------------------------

  override async afterCreate(api: BackendService, responseData: any) {
    await super.afterCreate(api, responseData)

    if (this._investment) {
      this._investment._share = this
    }
  }

  // ----------------------------------------------------

  /**
   * Removing a share removes its linked investment (and vice versa),
   * so we remove the investment and the DB will cascade the deletion
   * automatically.
   */
  override async remove(api: BackendService): Promise<this> {
    if (this._investment) {
      await this._investment.remove(api)
    } else {
      await this.event.remove(api)
    }

    return this
  }

  // ----------------------------------------------------

  get investment(): number {
    if (this._investment) {
      return this._investment.investment
    }

    // if (this.event instanceof ShareTransferEvent) {
    //   return this.event.sourceShare.investment * this.count / this.event.sourceShare.count
    // }

    return 0
  }

  // ----------------------------------------------------

  get scheme(): InvestmentScheme {
    return this._investment?.scheme ??
      this.investmentScheme ??
      InvestmentScheme.Standard
  }

  // ----------------------------------------------------

  get shareCertificate(): Document | null {
    return this.documents.getByType('SCER')
  }

  // ----------------------------------------------------

  get originalShare(): Share {
    if (this.shareTransfer) {
      return this.shareTransfer.sourceShare.originalShare
    }

    return this
  }

  // ----------------------------------------------------

  get eisClaim(): Document | null {
    return this.documents.getByType('ISCE')
  }

  // ----------------------------------------------------

  get estimatedCount(): number {
    return this.count
  }

  // ----------------------------------------------------

  get acquired() {
    return this.issued
      ? this.issued
      : this.event.effectiveDate
  }

  // ----------------------------------------------------

  get isIssued(): boolean {
    return true
  }

  // ----------------------------------------------------

  get shareCount(): number {
    return this.count
  }

  // ----------------------------------------------------

  get issuedShareCount(): number {
    return this.shareCount
  }

  // ----------------------------------------------------

  get votingShareCount(): number {
    return this.shareClass.voting * this.shareCount
  }

  // ----------------------------------------------------

  get votePercentage(): number {
    return percentage(this.votingShareCount, this.company.votingShareCount)
  }

  // ----------------------------------------------------

  get ownership(): number {
    return percentage(this.shareCount, this.company.outstandingShareCount)
  }

  // ----------------------------------------------------

  get dilutedOwnership(): number {
    return percentage(this.shareCount, this.company.dilutedShareCount)
  }

  // ----------------------------------------------------

  get active(): boolean {
    return !this.annulled
  }

  // ----------------------------------------------------

  override toString() {
    const ss = `${this.shareClass.name}, ${this.scheme}, ${this.investor.name}`
    return `${super.toString()}: ${this.count} shares (~£${this.investment}, ${ss})`
  }
}

// ------------------------------------------------------------

export class ShareCollection extends Collection<Share> {
  constructor() {
    super('investor.name')
  }

  // ----------------------------------------------------

  getForInvestment({ id }): Share | undefined {
    return this.find(s => s._investment && s._investment.id === id)
  }

  // ----------------------------------------------------

  getByInvestor({ id }): Share[] {
    return this.filter(s => s.investor.id === id)
  }

  // ----------------------------------------------------

  getByShareClass({ id }): Share[] {
    return this.filter(s => s.shareClass.id === id)
  }

  // ----------------------------------------------------

  getByInvestorAndShareClass(investor: Investor, shareClass: ShareClass): Share[] {
    return this.filter(s => s.investor.id === investor.id && s.shareClass.id === shareClass.id)
  }
}
