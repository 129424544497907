import { pick } from 'ramda'

import { Model, type INamed } from './model'
import { Address } from './address'
import type { EntityType } from '../models/model.model'

import { getInitials } from '@libs/utils'

import { environment } from '@env/environment'

// ------------------------------------------------------------


const getPath = (...elems) => environment.assetsLocation + [ ...elems ].join('/')

// ------------------------------------------------------------

const addressSafeDataPicker = pick([
  'line1',
  'line2',
  'city',
  'postcode',
  'country'
])

// ------------------------------------------------------------

export abstract class Entity extends Model implements INamed {
  abstract readonly entityType: EntityType

  abstract name: string
  initials: string

  twitter: string
  linkedin: string
  facebook: string

  address: Address | null
  description: string | null

  abstract readonly defaultAvatar: string
  picture: string | null
  hasAvatar: boolean
  avatarUrl: string

  isFullModel = false

  // ----------------------------------------------------

  constructor({
    address = {},
    description = '',
    picture = null,
    ...data
  }) {
    super({
      // address,
      description,
      picture,
      ...data
    })

    this.address = new Address()
    this.updateAddress(address)

    this.nameChanged()
    this.pictureChanged()
  }

  // ----------------------------------------------------

  nameChanged() {
    this.initials = getInitials(this.name)
  }

  // ----------------------------------------------------

  pictureChanged() {
    this.hasAvatar = !!this.picture && this.picture !== this.defaultAvatar
    this.avatarUrl = getPath(this.picture || this.defaultAvatar)
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'address',
      'description',
      'picture'
    ]
  }

  // ----------------------------------------------------

  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    if (responseData.address) {
      this.address.setID(responseData.address.id)
    }

    this.nameChanged()
    this.pictureChanged()
  }

  // ----------------------------------------------------

  override async afterUpdate(api, responseData) {
    await super.afterUpdate(api, responseData)

    if (responseData.address) {
      if (!this.address.saved) {
        this.address.setID(responseData.address.id)
      }

      this.updateAddress(responseData.address)
    }

    this.nameChanged()
    this.pictureChanged()
  }

  // ----------------------------------------------------

  updateAddress(addressData): Address {
    if (addressData) {
      const safeData = addressSafeDataPicker(addressData)

      if (!this.address) {
        this.address = new Address()
      }

      if (!this.address.saved && typeof addressData.id === 'string') {
        this.address.setID(addressData.id)
      }

      Object.assign(this.address, safeData)
    }

    return this.address
  }

  // ----------------------------------------------------

  get isMissingEmail(): boolean { return false }

}

// ------------------------------------------------------------

export interface IUploadEntityPictureResponse {
  picture: string
}
