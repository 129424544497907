import { on, createReducer } from '@ngrx/store'

import {
  AuthAction,
  AuthCallbackError,
  ClearAuthErrorState,
  GetWhoAmI,
  LoginError,
  LoginFailure,
  LoginFound,
  LoginSuccess,
  Logout,
  ReadWhoAmIFromStorage,
  SessionExpired,
  SetAuthErrorState,
  UpdateWhoAmI,
  WhoAmIFailure,
  WhoAmINoUserExists,
  WhoAmIUserFound
} from './auth.actions'

import type { WhoAmIData } from '../models/whoami-data.model'

// ----------------------------------------------------------

export const AUTH_FEATURE_KEY = 'auth'

// ----------------------------------------------------------

export interface AuthState {
  loggedIn: boolean
  idToken?: string
  emailToken: string | null

  emailSentTo?: string
  authError?: any

  whoAmI?: WhoAmIData
  whoAmIPending: boolean
  whoAmIRedirectUrl?: string
  whoAmIError?: any
}

// ----------------------------------------------------------

export interface AuthPartialState {
  readonly [ AUTH_FEATURE_KEY ]: AuthState
}

// ----------------------------------------------------------

export const initialState: AuthState = {
  loggedIn: false,
  emailToken: null,
  whoAmIPending: false
}

// ----------------------------------------------------------

const reducer = createReducer(
  initialState,

  on(Logout, (state: AuthState) => ({
    ...initialState
  })),

  on(SetAuthErrorState, (state: AuthState, { authError }) => ({
    ...state,
    authError
  })),

  on(ClearAuthErrorState, (state: AuthState) => ({
    ...state,
    authError: undefined
  })),

  on(AuthCallbackError, (state: AuthState, { error }) => ({
    ...state,
    authError: { error }
  })),

  on(LoginFound, LoginSuccess, (state: AuthState, { idToken }) => ({
    ...state,
    loggedIn: true,
    idToken,
  })),

  on(LoginFailure, LoginError, (state: AuthState, { authError }) => ({
    ...state,
    loggedIn: false,
    authError
  })),

  on(SessionExpired, (state: AuthState, { redirectUri }) => ({
    ...state,
    loggedIn: false,
    authError: { error: 'session_expired' },
    whoAmI: undefined,
    whoAmIError: undefined,
    whoAmIRedirectUrl: redirectUri
  })),

  on(ReadWhoAmIFromStorage, (state: AuthState) => ({
    ...state,
    whoAmI: undefined,
    whoAmIPending: false,
    whoAmIError: undefined
  })),

  on(GetWhoAmI, (state: AuthState, { redirectUrl, emailToken }) => ({
    ...state,
    emailToken,
    whoAmI: undefined,
    whoAmIPending: true,
    whoAmIRedirectUrl: redirectUrl || '/',
    whoAmIError: undefined
  })),

  on(WhoAmIUserFound, (state: AuthState, { whoAmI }) => ({
    ...state,
    whoAmI: {
      ...whoAmI
    },
    whoAmIPending: false
  })),

  on(WhoAmINoUserExists, (state: AuthState) => ({
    ...state,
    whoAmIPending: false
  })),

  on(WhoAmIFailure, (state: AuthState, { error }) => ({
    ...state,
    whoAmIPending: false,
    whoAmIError: error
  })),

  on(UpdateWhoAmI, (state: AuthState, { whoAmI }) => ({
    ...state,
    whoAmI: {
      ...whoAmI
    }
  }))
)

export function authReducer(
  state: AuthState,
  action: AuthAction
): AuthState {
  return reducer(state, action)
}
