/* eslint-disable @typescript-eslint/indent */

import { camelCase, type HasId } from '@libs/utils'

// ----------------------------------------------------------

export { HasId }

// ----------------------------------------------------------

export const EntityClassPrefixes = {
  a: 'Address',
  aai: 'AdvanceAssuranceIntent',
  acc: 'Access',
  adv: 'AdvanceAssurance',
  ap: 'Appointment',
  bdm: 'BoardMeeting',
  ben: 'Beneficiary',
  bk: 'BankAccount',
  c: 'Company',
  cf: 'CohortFunding',
  cfs: 'ConfirmationStatement',
  ch: 'Cohort',
  chm: 'CohortMember',
  cht: 'CohortTeam',
  cpd: 'EventSupportingDocument',
  cpl: 'Compliance',
  cpr: 'CompanyProduct',
  cv: 'Conversation',
  cvi: 'ConversationItem',
  d: 'Document',
  ddf: 'DocumentDiff',
  dpt: 'Department',
  drs: 'Directorship',
  drt: 'DirectorshipTermination',
  drv: 'DirectorshipVariation',
  ebt: 'EmploymentBonusTarget',
  eg: 'EntityGroup',
  egr: 'EntityGroupRequest',
  emv: 'EmiValuation',
  epb: 'EmploymentBonusPayment',
  eps: 'Employment',
  ept: 'EmploymentTermination',
  epv: 'EmploymentVariation',
  f: 'Financier',
  ff: 'FinancierFollower',
  fsh: 'FoundersShareholdersAgreement',
  i: 'Investor',
  icv: 'InstantConversion',
  iic: 'InstantInvestmentConsent',
  iv: 'Investment',
  ln: 'Loan',
  lnc: 'LoanCertificate',
  lnr: 'Repayment',
  op: 'Option',
  opg: 'OptionGrant',
  opo: 'OptionPool',
  opr: 'OptionReturn',
  ops: 'OptionScheme',
  opx: 'OptionExercise',
  pf: 'Portfolio',
  pfp: 'PortfolioPitch',
  prc: 'PromoCode',
  prs: 'Proposal',
  pt: 'Pitch',
  pta: 'PitchAppointment',
  pti: 'PitchInterest',
  ptv: 'PitchView',
  rda: 'ResearchAssurance',
  rdc: 'ResearchClaim',
  rgr: 'RegularReport',
  rnd: 'Round',
  s: 'Snapshot',
  sar: 'ShareAllotmentReturn',
  sc: 'ShareClass',
  scg: 'ShareClassRegistration',
  scr: 'ShareClassRenaming',
  sdf: 'ConvertibleNote',
  sdn: 'SeedNote',
  sft: 'SeedSaft',
  sg: 'Signature',
  sh: 'Share',
  sht: 'ShareTransfer',
  stk: 'StockSplit',
  stm: 'Statement',
  stv: 'StopVesting',
  sub: 'Subscription',
  trx: 'Transaction',
  u: 'User',
  upl: 'UploadedFile',
  wt: 'Witness',
  xpo: 'ExitPayout',
  xt: 'Exit'
} as const

// ----------------------------------------------------------

export type EntityPrefix = keyof typeof EntityClassPrefixes
export type EntityClass = typeof EntityClassPrefixes[ EntityPrefix ]

export type EntityPrefixToClass<EP extends EntityPrefix> = typeof EntityClassPrefixes[ EP ]

// ----------------------------------------------------------

export type EntityId<EP extends EntityPrefix = EntityPrefix> = `${EP}_${string}`

export const isValidId = (id: string): id is EntityId => {
  const m = id.match(/^[a-z]{1,3}(?=_[a-zA-Z0-9]{10})/)

  return m && EntityClassPrefixes[ m[ 0 ] ]
}

// ----------------------------------------------------------

type C = 'a'|'b'|'c'|'d'|'e'|'f'|'g'|'h'|'i'|'j'|'k'|'l'|'m'|'n'|'o'|'p'|'q'|'r'|'s'|'t'|'u'|'v'|'w'|'x'|'y'|'z'
type Char = C | Uppercase<C>

type TrimEnd<S extends string> = S extends `${infer R}${Char}` ?
  R :
  S extends Char | '' ?
  '' :
  never

type EntityClassToDomain<EC extends EntityClass> =
  EC extends 'Beneficiary' | 'Company' ?
  `${Uncapitalize<TrimEnd<EC>>}ies` :
  EC extends 'Address' | 'Access' | 'Pitch' | 'ShareClass' | 'Witness' ?
  `${Uncapitalize<EC>}es` :
  `${Uncapitalize<EC>}s`

export type EntityDomain = EntityClassToDomain<EntityClass>

// ----------------------------------------------------------

export function idToEntityClass(
  id: string
): EntityClass | undefined {
  const m = id.match(/^[a-z]{1,3}(?=_)/)

  if (m) {
    return EntityClassPrefixes[ m[ 0 ] ]
  }
}

// ----------------------------------------------------------

export function idToField(
  id: string
): string | undefined {
  const entityClass = idToEntityClass(id)

  if (entityClass) {
    return camelCase(entityClass)
  }
}

// ----------------------------------------------------------

export function idToDomain(
  id: string
): string | undefined {
  const entityClass = idToEntityClass(id)

  if (!entityClass) {
    return
  }

  switch (entityClass) {
    case 'Address':
    case 'Access':
    case 'Pitch':
    case 'ShareClass':
    case 'Witness':
      return camelCase(entityClass) + 'es'

    case 'Beneficiary':
    case 'Company':
      return camelCase(entityClass.slice(0, -1)) + 'ies'

    default:
      return camelCase(entityClass) + 's'
  }
}

// ----------------------------------------------------------

export function idToLink(
  id: string
): string | undefined {
  const domain = idToDomain(id)

  if (domain) {
    return `/${domain}/${id}/`
  }
}
