import { addIndex, curry, identical, map, mapObjIndexed, pipe, type } from 'ramda'
import { omitBy } from 'ramda-adjunct'

// ----------------------------------------------------------

export function setPath<T>(path: string[], value: unknown, target: T): T {
  const bits = [ ...path ]
  const prop = bits.pop()

  for (const cur of bits) {
    if (!target[ cur ]) {
      target[ cur ] = {}
    }

    target = target[ cur ]
  }

  if (value !== undefined) {
    target[ prop ] = value
  } else {
    delete target[ prop ]
  }

  return target
}

// ----------------------------------------------------------

export const mapIndexed = addIndex(map)

// ----------------------------------------------------------

const isObj = pipe(type, identical('Object'))
const isArray = pipe(type, identical('Array'))

const omitUndefined = obj => omitBy(v => typeof v === 'undefined', obj)

// ----------------------------------------------------------

export const mapDeep = curry(function _mapDeep(fn, src) {
  const mapper = (value, key, obj) => {
    if (isObj(value) || isArray(value)) {
      const newValue = fn(value, key, obj)

      return newValue !== value
        ? newValue
        : mapDeep(fn, value)
    } else {
      return fn(value, key, obj)
    }
  }

  if (isArray(src)) {
    return mapIndexed(mapper, src)
  } else if (isObj(src)) {
    return omitUndefined(mapObjIndexed(mapper, src))
  } else {
    return src
  }
})
