import { InjectionToken } from '@angular/core'

import { IDebugOptions, IEnvironmentConfig } from '@env/models'

// ----------------------------------------------------------

export const GlobalObject = new InjectionToken<Window>('GlobalObject')

// ----------------------------------------------------------

export const EnvironmentConfig = new InjectionToken<IEnvironmentConfig>('EnvironmentConfig')
export const DebugConfig = new InjectionToken<IDebugOptions>('DebugConfig')

export type { IDebugOptions, IEnvironmentConfig }
