import {
  Document,
  DocumentTypeNames,
  ResearchClaimEvent
} from '@libs/models'

import { labelProvider } from '@libs/services'

import { formatDateRange } from '@libs/utils'

// ----------------------------------------------------------

export const LABELLERS = [

  labelProvider(
    Document,
    (document: Document) => DocumentTypeNames[ document.documentType ]
  ),

  labelProvider(
    ResearchClaimEvent,
    (event: ResearchClaimEvent, locale: string) =>
      $localize`R&D Claim ${formatDateRange(event.startDate, event.effectiveDate, locale)}`
  ),

  labelProvider(
    ResearchClaimEvent,
    'timeline',
    (event: ResearchClaimEvent, locale: string) =>
      formatDateRange(event.startDate, event.effectiveDate, locale)
  ),

  labelProvider(
    'RDLA',
    ({ event }: Document<ResearchClaimEvent>, locale: string) => {
      return `${DocumentTypeNames.RDLA} (${formatDateRange(event.startDate, event.effectiveDate, locale)})`
    }
  ),

  labelProvider(
    'RDTN',
    ({ event }: Document<ResearchClaimEvent>, locale: string) => {
      return `${DocumentTypeNames.RDTN} (${formatDateRange(event.startDate, event.effectiveDate, locale)})`
    }
  ),

]
