
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'

import { partition } from 'ramda'
import type { ICaptableEvent } from '@libs/captable'

// ----------------------------------------------------------

@Component({
  selector: 'sl-captable-event-selector',
  templateUrl: './captable-event-selector.component.html',
  styleUrls: [ './captable-event-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptableEventSelectorComponent {
  @Input() ready: boolean
  @Input() set events(data: ICaptableEvent[]) {
    const [ closedEvents, openEvents ] = partition(event => event.closed, data)
    this.openEvents = openEvents
    this.closedEvents = closedEvents

    this.selectedEvent = data[ 0 ]
  }
  @Input() selectedEventId: string

  @Output() selectionChanged = new EventEmitter<string>()

  openEvents: ICaptableEvent[] = []
  closedEvents: ICaptableEvent[] = []
  selectedEvent: ICaptableEvent

  // -------------------------------------------------------------------------

  onSelectedEventChanged(event: MatSelectChange) {
    this.selectionChanged.emit(event.value)
  }
}
