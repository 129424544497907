<sl-avatar [entity]="entity"
           [size]="60"
           [slEntityInfoPopup]="entity"
           [disabled]="!showPopup"></sl-avatar>

<div class="ml-16">
  <span class="mat-title">{{ entity.name }}</span>

  <div *ngIf="label"
       class="mat-body-1">{{ label }}</div>
</div>
