import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject, forwardRef, Optional, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'

import { STEPPER_GLOBAL_OPTIONS, StepperOptions } from '@angular/cdk/stepper'

import { CountryCode, Entity, User } from '@libs/models'
import { IEntity } from '../../models/entity-sidenav'

import { SidenavStepComponent, SidenavStepWithSubStepperComponent, SidenavStepperComponent } from '../sidenav-stepper/sidenav-stepper.component'

// ----------------------------------------------------------

export interface IUserSelected {
  user: User
  isNew: boolean
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-user-select-steps',
  templateUrl: './user-select-steps.component.html',
  providers: [
    { provide: SidenavStepComponent, useExisting: UserSelectStepsComponent }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectStepsComponent extends SidenavStepWithSubStepperComponent implements OnInit {
  @Input() entities: IEntity<User>[] = []
  @Input() title = $localize`Add user`
  @Input() searchLabel = $localize`Search existing`
  @Input() addNewLabel = $localize`Add new user`
  @Input() canAddNew = false
  @Input() autoAddNew = false
  @Input() showAddress = true
  @Input() requireAddress = false
  @Input() requestLegalName = true
  @Input() defaultCountry?: CountryCode

  @Output() userSelected = new EventEmitter<IUserSelected>()

  userCtrl = this.fb.control(null, Validators.required)
  showSelectStep = true
  detailsLabel = $localize`Enter details`

  // ----------------------------------------------------

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SidenavStepperComponent)) override readonly parentStepper: SidenavStepperComponent,
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(parentStepper, stepperOptions)
  }

  // ----------------------------------------------------

  ngOnInit() {
    // _log(`UserSelectStepsComponent.ngOnInit(): userCtrl, this`, this.userCtrl, this)

    if (this.canAddNew && this.autoAddNew && this.entities.length === 0) {
      this.showSelectStep = false
      this.detailsLabel = this.title
    }
  }

  // ----------------------------------------------------

  async onUserSelected({ entity }: IEntity<Entity>, isNew: boolean) {
    if (!(entity instanceof User)) {
      return
    }

    this.completed = true

    // _log(`UserSelectStepsComponent.onUserSelected(entity, isNew = ${isNew})`, { ...entity }, this.userCtrl.status, this.userCtrl, this)

    this.userSelected.emit({ user: entity, isNew })

    this.next()
  }

  // ----------------------------------------------------

  onUserChanged($event) {
    // _log(`UserSelectStepsComponent.onUserChanged($event)`, $event, this.userCtrl.status, this.userCtrl, this)
  }

  // ----------------------------------------------------

  onUserSaved(entity: User) {
    // _log(`UserSelectStepsComponent.onUserSaved(entity)`, entity, this.userCtrl, this)

    this.onUserSelected({ entity }, true)
  }
}
