import { inject, Injectable } from '@angular/core'

import { type Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Apollo, gql } from 'apollo-angular'

// ----------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class GenericQueryService {

  private readonly apollo = inject(Apollo)

  // ----------------------------------------------------

  runQuery<
    ReturnData extends object = object,
    V extends object = object
  >(
    { query, ...variables }: { query: string } & V,
  ): Observable<ReturnData> {
    return this.apollo.query<ReturnData>({
      fetchPolicy: 'no-cache',
      query: gql(query),
      variables,
    }).pipe(
      map(res => res.data),
    )
  }

}
