import { parse as parseExpr, eval as evalExpr } from 'expression-eval'
import { trimEnd } from 'ramda-adjunct'

// ------------------------------------------------------------

const KEBAB_REGEX = /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g
const PASCAL_REGEX = /(?:^|-)(\w)/g

export function kebabCase(str: string): string {
  return str[ 0 ].toLocaleLowerCase() + str.substring(1).replace(KEBAB_REGEX, match => '-' + match.toLocaleLowerCase())
}

export function pascalCase(str: string): string {
  return str.replace(PASCAL_REGEX, (_, m) => m.toUpperCase())
}

export function camelCase(str: string): string {
  return str[ 0 ].toLocaleLowerCase() + str.substring(1)
}

// ------------------------------------------------------------

export function pluralise(count: number, singular: string, plural?: string): string {
  plural = plural || singular + 's'

  return count === 1
    ? singular
    : plural
}

// ------------------------------------------------------------

export function evaluate(source: string, context = {}, returnSourceOnError = false): string | null {
  let result = null

  try {
    const ast = parseExpr(source)

    result = evalExpr(ast, context)
  } catch (ex) {}

  // The expression-eval library parses invalid JS expression quite happily, but
  // evaluates them to undefined, so check here to see if we got a result
  return result == null && returnSourceOnError
    ? source
    : result
}

// ------------------------------------------------------------

export function evaluateAll<T extends object>(source: T, context = {}): T {
  const processValue = v => typeof v === 'string'
    ? evaluate(v, context, true)
    : v

  return Object.keys(source)
    .reduce((out, key) => ({
      ...out,
      [ key ]: processValue(source[ key ])
    }), {} as T)
}

// ------------------------------------------------------------

export function listJoin(items: unknown[]): string {
  items = items.filter(v => v != null)

  if (items.length === 0) {
    return ''
  }

  const last = items.pop()

  return items.length > 0
    ? [ items.join(', '), last ].join(' ' + $localize`and` + ' ')
    : String(last)
}

// ------------------------------------------------------------

export function getInitials(name = ''): string {
  return name
    .split(' ')
    .map(w => w[ 0 ])
    .join('')
    .replace(/\W/g, '')
    .toUpperCase()
    .slice(0, 3)
}

// ------------------------------------------------------------

/**
 * Get a value from the sequence A ... Z, AA ... ZZ, AAA ... ZZZ, etc.
 *
 * @param {number} index
 * @param {boolean} skipA Set to true to never return 'A', defaults to false
 * @returns {string}
 */
export function getAlphabeticalSequenceIndex(index: number, skipA = false): string {
  return skipA && index === 0
    ? ''
    : String.fromCharCode(65 + index % 26).repeat(1 + Math.floor(index / 26))
}

// ------------------------------------------------------------

/**
 * Copy text to the system clipboard.
 *
 * @param {string} text Text to be copied
 * @returns {boolean}   True if copy was successful
 */
export function copyTextToClipboard(
  text: string
): boolean {
  const doc = window.document

  const elem = Object.assign(doc.createElement('input'), {
    type: 'text',
    style: 'position: fixed; bottom -80px;',
    value: text
  })

  doc.body.appendChild(elem)
  elem.select()

  try {
    doc.execCommand('copy')
    return true
  } catch (ex) {
    return false
  } finally {
    doc.body.removeChild(elem)
  }
}

// ------------------------------------------------------------

export function dedentText(
  text: string,
  trimWhitespace = true
): string {

  const lines = text.split('\n').map(trimEnd)

  const minIndent = Math.min(
    ...lines.map(line => line.match(/\S/)?.index).filter(v => v !== undefined)
  )

  const result = lines
    .map(line => line.length ? line.slice(minIndent) : line)
    .join('\n')

  return trimWhitespace
    ? result.trim()
    : result
}
