import { Injectable, Inject } from '@angular/core'

import { firstValueFrom } from 'rxjs'

import { GlobalsService } from '@app/core/services/globals/globals.service'

import type {
  Appointment,
  Company,
  IAppointmentPatchData,
  User
} from '@libs/models'

import { BackendService, RestApi } from '@libs/backend'

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  /**
   * Creates a new appointment for the given user and company,
   * adds it to both entities, and then returns it.
   */
  async createAppointment(
    company: Company,
    user: User,
    appointmentData: IAppointmentPatchData
  ): Promise<Appointment> {
    const payload = {
      user: user.link,
      company: company.link,
      position: appointmentData.position || '',
      roles: appointmentData.roles || []
    }

    // Invoke POST /appointments
    const appointmentResponse = await firstValueFrom(this.restApi
      .all('appointments')
      .post(payload))

    return this.globalsService.buildAppointment(company, user, appointmentResponse)
  }

  // ----------------------------------------------------

  /**
   * Patch an appointment with a set of data and return the result.
   */
  async patchAppointment(
    appointment: Appointment,
    data: IAppointmentPatchData
  ): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.restApi
        .one('appointments', appointment.id)
        .patch<IAppointmentPatchData>(data))

      appointment.position = response.position
      appointment.roles = response.roles

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  /**
   * Remove an appointment, also removing it from both the user
   * and company objects it was attached to.
   */
  removeAppointment(appointment: Appointment): Promise<Appointment> {
    return appointment.remove(this.restApi)
  }

  // ----------------------------------------------------

  constructor(
    @Inject(RestApi) private restApi: BackendService,
    private globalsService: GlobalsService
  ) {}
}
