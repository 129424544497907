<sl-simple-dialog-wrapper headerTitle="Special Completion Conditions"
                          okLabel="Confirm"
                          [form]="checkboxCtrl"
                          cancelLabel="Dismiss"
                          (okClicked)="okClicked()">
  <div fxLayout="column"
       class="gap-16">
    <span>{{ data.description }}</span>
    <mat-checkbox [formControl]="checkboxCtrl"
                  required>I confirm these conditions have been met.</mat-checkbox>
  </div>
</sl-simple-dialog-wrapper>
