import { HttpErrorResponse } from '@angular/common/http'

import { _logt } from './logging'

// ----------------------------------------------------------

const LINK = 'color: #07a; text-decoration: underline'
const WARN = 'color: #a00'

// ----------------------------------------------------------

export function logHttpError(error: HttpErrorResponse): void {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.

    _logt(
      [ `%c${error.name}%c : ${error.error.message} (%c${error.url}%c): error, httpError`, WARN, '', LINK, '' ],
      error.error,
      error
    )
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    _logt(
      [ `%cHttpError%c : ${error.status} ${error.statusText} (%c${error.url}%c): body, httpError`, WARN, '', LINK, '' ],
      error.error,
      error
    )
  }
}
