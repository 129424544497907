import type { Document } from './document'

// ------------------------------------------------------------

export type PermissionLevel = 'none' | 'read' | 'admin'

// ------------------------------------------------------------

export interface IDocumentPermissionInfo {
  level: PermissionLevel
  admin: boolean
  read: boolean
}

// ------------------------------------------------------------

export class PermissionSet {

  public readonly entities = new Set<string>()

  // ----------------------------------------------------

  constructor(
    private readonly document: Document,
  ) {}

  // ----------------------------------------------------

  private getAdminUserIds(): Set<string> {
    if (this.document.company) {
      const ids = this.document.company.getAdmins()
        .map(a => a.user.id)

      return new Set(ids)
    } else {
      return this.document.user? new Set([ this.document.user.id ]): new Set()
    }
  }

  // ----------------------------------------------------

  clear(): void {
    this.entities.clear()
  }

  // ----------------------------------------------------

  hasPermission(
    entityId: string,
  ): boolean {
    return this.entities.has(entityId)
  }

  // ----------------------------------------------------

  getPermission(
    entityId: string,
  ): IDocumentPermissionInfo {
    if (this.getAdminUserIds().has(entityId)) {
      return {
        level: 'admin',
        admin: true,
        read: true
      }
    } else {
      const read = this.entities.has(entityId)

      return {
        level: read ? 'read' : 'none',
        admin: false,
        read
      }
    }
  }

  // ----------------------------------------------------

  addAll(
    entityIds: string[],
  ): void {
    for (const id of entityIds) {
      this.entities.add(id)
    }
  }

}
