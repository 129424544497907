import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { type Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { type CompanyType, type IAddressDataWithoutId, Jurisdiction, Region } from '@libs/models'
import type { ICommonwealthRegisteredCompanyData } from '../../models/company-data'

import { environment } from '@env/environment'

// ----------------------------------------------------------

export const CompaniesHouseApiSettings = {
  ApiRoot: environment.companiesHouseApi.url,
  AuthToken: `Basic ${btoa(environment.companiesHouseApi.key + ':')}`
}

// ----------------------------------------------------------

interface ICompaniesHouseAddressData {
  premises: string
  address_line_1: string
  address_line_2?: string
  locality: string
  region?: string
  postal_code: string
}

// ----------------------------------------------------------

interface ICompaniesHouseGetCompanyResponse {
  company_number: string
  company_name: string
  date_of_creation: string
  registered_office_address?: ICompaniesHouseAddressData
}

// ----------------------------------------------------------

export interface ICompaniesHouseCompanySearchResponse {
  items: ICompaniesHouseCompanySearchCompanyData[]
}

export interface ICompaniesHouseCompanySearchCompanyData {
  company_number: string
  title: string
  date_of_creation: string
  address?: ICompaniesHouseAddressData
  address_snippet: string
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CompaniesHouseService {
  readonly isEnabled = environment.FLAGS.DISABLE_COMPANIES_HOUSE_API !== true

  // ----------------------------------------------------

  constructor(
    private httpClient: HttpClient
  ) {}

  // ----------------------------------------------------

  convertAddress(address?: ICompaniesHouseAddressData): IAddressDataWithoutId | null {
    if (!address) {
      return null
    }

    let line1 = (address.address_line_1 || '').trim()

    // Line 2 is not usually present
    let line2 = address.address_line_2 && address.address_line_2.trim()

    // Can be missing, a string or a number...
    if (address.premises) {
      const premises = address.premises.trim()

      if (Number.isInteger(parseInt(premises, 10))) {
        line1 = `${premises} ${line1}`
      } else if (!line2) {
        line2 = line1
        line1 = premises
      } else {
        line1 = `${premises}, ${line1}`
      }
    }

    // Ignore addresses with both locality and region fields
    const city = (address.locality || address.region || '').trim()
    const postcode = address.postal_code && address.postal_code.trim()
    // Ignore country field, could be 'England' or 'United Kingdom' or missing
    const country = 'GB'

    return { line1, line2, city, postcode, country }
  }

  // ----------------------------------------------------

  getCompany(
    data: ICommonwealthRegisteredCompanyData
  ): Observable<ICommonwealthRegisteredCompanyData> {
    if (!this.isEnabled) {
      throw new Error('Cannot access Companies House API')
    }

    const url = `${CompaniesHouseApiSettings.ApiRoot}/company/${data.registrarNumber}`

    return this.httpClient
      .get<ICompaniesHouseGetCompanyResponse>(url, {
        headers: {
          Authorization: CompaniesHouseApiSettings.AuthToken
        },
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        map(response => {
          return {
            ...data,
            address: this.convertAddress(response.registered_office_address)
          }
        })
      )
  }

  // ----------------------------------------------------

  searchCompanies(
    q: string,
    limit = 5
  ): Observable<ICommonwealthRegisteredCompanyData[]> {
    if (!this.isEnabled) {
      return of([])
    }

    try {
      const url = `${CompaniesHouseApiSettings.ApiRoot}/search/companies` // ?items_per_page=5&q=${encodeURIComponent(name)}`

      return this.httpClient
        .get<ICompaniesHouseCompanySearchResponse>(url, {
          params: {
            items_per_page: String(limit),
            q
          },
          headers: {
            Authorization: CompaniesHouseApiSettings.AuthToken
          },
          observe: 'body',
          responseType: 'json'
        })
        .pipe(
          map(({ items }) => items.map(ci => {
            return {
              region: Region.Commonwealth,
              jurisdiction: Jurisdiction.EnglandWales,
              field: 'companiesHouseNumber',
              currency: 'GBP',
              registrarNumber: ci.company_number,
              // companiesHouseNumber: ci.company_number,
              name: this.prettifyCompanyName(ci.title),
              rawName: ci.title,
              type: this.getCompanyType(ci.title),
              incorporated: ci.date_of_creation,
              addressSnippet: ci.address_snippet,
              postCode: ci.address ? ci.address.postal_code : ''
            }
          }))
        )
    } catch (e) {
      return of([])
    }
  }

  // ----------------------------------------------------

  getCompanyType(name: string): CompanyType | null {
    const m = name.match(/\b(?:(LTD)\.?|(LIMITED|LBG|LLP|LP|PLC|PUC|CIC))$/i)

    if (!m) {
      return 'OTHER'
    }

    return (m[ 1 ] || m[ 2 ]) as CompanyType
  }

  // ----------------------------------------------------

  /**
   * Capitalize the company stored in Companies House and removes 'LTD' or 'LIMITED'
   * @param {string} name
   * @returns {string}
   */
  prettifyCompanyName(name: string): string {
    return name
      // Removes the last word if a known company type (e.g. LTD or LIMITED) and trims the trailing spaces
      .replace(/\b(?:LTD\.?|LIMITED|LLP|LP|PLC|LBG|PUC|CIC)$/i, '')
      .trim()
    // Capitalize the first letter of each word while lower-casing the others
      .replace(/\w\S*/g, txt => txt[ 0 ].toUpperCase() + txt.substring(1).toLowerCase())
  }
}
