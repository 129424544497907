import { Inject, LOCALE_ID, Optional, Pipe, type PipeTransform } from '@angular/core'
import { formatCurrency, formatNumber } from '@angular/common'

import { type CurrencyCode } from '@libs/models'

import { LocaleService } from '@libs/services'

import { getCurrency } from './currency-utils'

// ----------------------------------------------------------

const ZEROES_REGEX = /\.0+$/

// ----------------------------------------------------------

@Pipe({
  name: 'slCurrency'
})
export class SlCurrencyPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Optional() private localeService: LocaleService,
  ) {}

  // ----------------------------------------------------

  transform(
    value: number,
    allDecimals: boolean | number,
    currencyCode?: CurrencyCode
  ): string

  transform(
    value: number,
    currencyCode?: CurrencyCode
  ): string

  // ----------------------------------------------------

  transform(
    value: number,
    allDecimals: boolean | number | CurrencyCode = false,
    currencyCode?: CurrencyCode
  ): string {
    if (!Number.isFinite(value)) {
      return ''
    }

    if (typeof allDecimals === 'string') {
      currencyCode = allDecimals
      allDecimals = false
    }

    const { symbol } = getCurrency(this.localeService, currencyCode)

    let digitsInfo = '1.2'

    if (allDecimals === true) {
      digitsInfo = '1.2-10'
    } else if (typeof allDecimals === 'number') {
      digitsInfo = `1.${Math.min(2, allDecimals)}-${allDecimals}`
    }

    // Check if value ends in all zero digits and change
    // digitsInfo to prevent any decimal digits being shown
    const simpleResult = formatNumber(value, 'en', digitsInfo)

    if (simpleResult.match(ZEROES_REGEX)) {
      digitsInfo = '1.0-0'
    }

    // Use formatCurrency from @angular/common
    const result = formatCurrency(value, this.locale, symbol, currencyCode, digitsInfo)

    // _logc(
    //   `CurrencyPipe.transform(value, allDecimals = ${allDecimals}, currencyCode = "${currencyCode}"): result, simpleResult, currency, localeNumberFormat: Decimal, localeNumberFormat: Currency, getNumberOfCurrencyDigits()`,
    //   value,
    //   result,
    //   simpleResult,
    //   getCurrencyByCode(currencyCode),
    //   getLocaleNumberFormat(locale, NumberFormatStyle.Decimal),
    //   getLocaleNumberFormat(locale, NumberFormatStyle.Currency),
    //   getNumberOfCurrencyDigits(currencyCode)
    // )

    return result
  }
}
