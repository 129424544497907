import { Component } from '@angular/core'
import { environment } from '@env/environment'
import { Profile } from '@env/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-static-toolbar',
  templateUrl: './static-toolbar.component.html',
  styleUrls: [ './static-toolbar.component.scss' ]
})
export class StaticToolBarComponent {
  isNotProduction = environment.profile !== Profile.Production && environment.profile !== Profile.Int
  environment = environment.profile.toUpperCase()
}
