import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  type OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatSelect } from '@angular/material/select'
import { FocusMonitor } from '@angular/cdk/a11y'

import { Subject } from 'rxjs'

import { getCountryForJurisdiction, Jurisdiction, type SelectableJurisdiction } from '@libs/models'
import { JurisdictionService } from '@libs/services'
import { SlJurisdictionPipe } from '@libs/shared/pipes/sl-jurisdiction.pipe'

// ----------------------------------------------------------

@Component({
  selector: 'sl-jurisdiction-select',
  templateUrl: './jurisdiction-select.component.html',
  styleUrls: [ './jurisdiction-select.component.scss' ],
  providers: [
    { provide: MatFormFieldControl, useExisting: JurisdictionSelectComponent },
    SlJurisdictionPipe
  ]
})
export class JurisdictionSelectComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Jurisdiction | undefined> {
  @Input() allowOther: boolean = false

  @Input()
  get selectableJurisdictions(): SelectableJurisdiction[] {
    return this._selectableJurisdictions
  }
  set selectableJurisdictions(value: SelectableJurisdiction[]) {
    this._selectableJurisdictions = value
    const [ completeJurisdictions, globalJurisdictions ] = this.jurisdictionService.partitionCompleteAndGlobal(this._selectableJurisdictions)
    this.completeJurisdictions = completeJurisdictions
    this.globalJurisdictions = globalJurisdictions

    this.stateChanges.next()
  }
  _selectableJurisdictions: SelectableJurisdiction[]

  // ----------------------------------------------------

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next()
  }
  _placeholder: string

  // ----------------------------------------------------

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: boolean) {
    this._required = value
    this.stateChanges.next()
  }
  _required = false

  // ----------------------------------------------------

  @Output() readonly changed = new EventEmitter<Jurisdiction | undefined>()

  // ----------------------------------------------------

  @HostBinding() id = `sl-jurisdiction-select-${JurisdictionSelectComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''
  @ViewChild(MatSelect, { static: true }) input: MatSelect

  // ----------------------------------------------------

  static nextId = 0
  selectOpened = false

  completeJurisdictions: SelectableJurisdiction[]
  globalJurisdictions: SelectableJurisdiction[]

  value: Jurisdiction | undefined = Jurisdiction.EnglandWales
  disabled = false
  countryCode = getCountryForJurisdiction

  // eslint-disable-next-line rxjs/finnish
  stateChanges = new Subject<void>()

  controlType = 'sl-jurisdiction-select'

  focused = false

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: Jurisdiction | undefined) => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {}

  // ----------------------------------------------------

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    private readonly jurisdictionService: JurisdictionService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin
      this.stateChanges.next()
    })
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement)
  }

  // ----------------------------------------------------

  get selectedJurisdiction(): Jurisdiction | undefined {
    return this.value
  }

  // ----------------------------------------------------

  onChanged(value: Jurisdiction | undefined): void {
    this.onChange(value)
    this.stateChanges.next()
    this.changed.emit(value)
  }

  // ----------------------------------------------------

  writeValue(modelValue: Jurisdiction | undefined): void {
    if (modelValue) {
      this.value = modelValue
    }
  }

  // ----------------------------------------------------

  registerOnChange(fn: (newValue: Jurisdiction | undefined) => void): void {
    this.onChange = fn
  }

  // ----------------------------------------------------

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn
  }

  // ----------------------------------------------------

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  // ----------------------------------------------------

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ')
  }

  // ----------------------------------------------------

  onContainerClick(): void {
    this.input.open()
  }

  get empty(): boolean {
    return false
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  get errorState(): boolean {
    return this.input.errorState
  }

  get autofilled(): boolean {
    return false
  }
}
