import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { ShareClass } from '@libs/models'

// -----------------------------------------------------------------------

@Component({
  selector: 'sl-share-class-bullet',
  templateUrl: './share-class-bullet.component.html',
  styles: [ `mat-icon { margin-right: 7px; }` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareClassBulletComponent {
  @Input() shareClass: Pick<ShareClass, 'name' | 'colour'>
}
