import { createAction, union, props } from '@ngrx/store'

import { IUserCompany, TabLink } from '@libs/models'
import { IBreadcrumbItem } from '../models/breadcrumbs'

// ----------------------------------------------------------

export const BackdropClicked = createAction(
  '[Layout] Backdrop Clicked'
)

// ----------------------------------------------------------

export const SetNavigationData = createAction(
  '[Layout] Set Navigation Data',
  props<{ breadcrumbs: IBreadcrumbItem[], tabLinks: TabLink[], collapsibleMainNav: boolean }>()
)

export const SetBreadcrumbs = createAction(
  '[Layout] Set Breadcrumbs',
  props<{ breadcrumbs: IBreadcrumbItem[] }>()
)

// ----------------------------------------------------------

export const MainNavToggle = createAction(
  '[Layout] Main Navbar Toggle'
)

export const MainNavOpen = createAction(
  '[Layout] Main Navbar Open'
)

export const MainNavClose = createAction(
  '[Layout] Main Navbar Close'
)

// ----------------------------------------------------------

export const SideNavToggle = createAction(
  '[Layout] Side Navbar Toggle'
)

export const SideNavOpen = createAction(
  '[Layout] Side Navbar Open'
)

export const SideNavClose = createAction(
  '[Layout] Side Navbar Close'
)

export const CloseLeftNavPanel = createAction(
  '[Layout] Close LeftNav Panel'
)

// ----------------------------------------------------------

export const NavigationStarted = createAction(
  '[Layout] Navigation Started'
)

export const NavigationFinished = createAction(
  '[Layout] Navigation Finished'
)

// ----------------------------------------------------------

export const InitialiseCurrentCompanyId = createAction(
  '[Layout] Initialise Current Company ID',
  props<{ companyId: string }>()
)

export const SetCurrentCompanyId = createAction(
  '[Layout] Set Current Company ID',
  props<{ companyId: string }>()
)

export const StoreCurrentCompanyId = createAction(
  '[Layout] Store Current Company ID',
  props<{ companyId: string }>()
)

export const ChangeCurrentCompany = createAction(
  '[Layout] Change Current Company',
  props<{ companyId: string, subRoute: Array<string | number> }>()
)

export const UpdateCurrentUserCompanies = createAction(
  '[Layout] Update Current User Companies',
  props<{ companies: Array<IUserCompany> }>()
)

// ----------------------------------------------------------

const actions = union({
  BackdropClicked,
  SetNavigationData,
  SetBreadcrumbs,
  MainNavToggle,
  MainNavOpen,
  MainNavClose,
  SideNavToggle,
  SideNavOpen,
  SideNavClose,
  NavigationStarted,
  NavigationFinished,
  InitialiseCurrentCompanyId,
  SetCurrentCompanyId,
  StoreCurrentCompanyId,
  ChangeCurrentCompany,
  UpdateCurrentUserCompanies
})

export type LayoutAction = typeof actions
