export const RegistrarConfig = {
  DEV: {
    companiesHouseApi: {
      url: 'https://api-sandbox.company-information.service.gov.uk',
      key: '723b66f3-140d-40a3-87df-29ae3309966a',
    }
  },
  INT_GROW: {
    companiesHouseApi: {
      url: 'https://api.company-information.service.gov.uk',
      key: '611434ca-0add-498d-b43c-6a2021825d89',
    }
  },
  INT_INVEST: {
    companiesHouseApi: {
      url: 'https://api.company-information.service.gov.uk',
      key: '05b6d053-3f5b-47d0-a01c-7823ab595dab',
    }
  },
  PROD_GROW: {
    companiesHouseApi: {
      url: 'https://api.company-information.service.gov.uk',
      key: 'Ylr5tPQKryelK_5ETVyt2O50AR24EtZoitdc8qsq',
    }
  },
  PROD_INVEST: {
    companiesHouseApi: {
      url: 'https://api.company-information.service.gov.uk',
      key: 'e9edca15-6d2a-4689-8a84-d773af6c0737',
    }
  }
}
