import { Injectable } from '@angular/core'

import { ICaptablePageSettings, ICohortManagerSettings, IExitScenarioSettings } from './settings.model'

import { LocalStorageService } from '@libs/storage'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private readonly CAPTABLE_KEY = 'captable'
  private readonly EXIT_SCENARIO_KEY = 'exitScenario'

  private readonly COHORT_MANAGER_KEY = 'cohortManager'

  // ----------------------------------------------------

  constructor(private storage: LocalStorageService) {}

  // ----------------------------------------------------

  getSettings<T>(
    key: string,
    defaults: T
  ): T {
    return {
      ...defaults,
      ...this.storage.getObject<T>(key)
    }
  }

  // ----------------------------------------------------

  saveSettings<T>(
    key: string,
    settings: Partial<T>
  ): void {
    this.storage.setObject(key, {
      ...this.storage.getObject<T>(key),
      ...settings,
    })
  }

  // ----------------------------------------------------

  getCaptableSettings(): ICaptablePageSettings {
    const defaultSettings: ICaptablePageSettings = {
      view: 'byShareholding',
      limit: 25
    }

    const storedSettings = this.storage.getObject<ICaptablePageSettings>(this.CAPTABLE_KEY, defaultSettings)

    const settings = {
      view: storedSettings.view || defaultSettings.view,
      limit: storedSettings.limit || defaultSettings.limit
    }

    // TODO: Hack for users still on view = 'byRound'. We need a proper settings sanitizer.
    if (![ 'byShareholding', 'byShareClass', 'byEvent' ].includes(settings.view)) {
      settings.view = 'byShareholding'
    }

    return settings
  }

  saveCaptableSettings(newSettings: Partial<ICaptablePageSettings>): void {
    const settings = {
      ...this.getCaptableSettings(),
      ...newSettings
    }
    this.storage.setObject(this.CAPTABLE_KEY, settings)
  }

  // ----------------------------------------------------

  getExitScenarioSettings(): IExitScenarioSettings {
    return {
      graphView: 'PerShareClass',
      tableView: 'byShareholding',
      exitPrice: 20e6,
      maxExitPrice: 20e6,
      ...this.storage.getObject<IExitScenarioSettings>(this.EXIT_SCENARIO_KEY, )
    }
  }

  saveExitScenarioSettings(newSettings: Partial<IExitScenarioSettings>): void {
    const settings = {
      ...this.getExitScenarioSettings(),
      ...newSettings
    }
    this.storage.setObject(this.EXIT_SCENARIO_KEY, settings)
  }

  // ----------------------------------------------------

  getCohortManagerSettings(): ICohortManagerSettings {
    return { ...this.storage.getObject<ICohortManagerSettings>(this.COHORT_MANAGER_KEY) }
  }

  saveCohortManagerSettings(newSettings: Partial<ICohortManagerSettings>): void {
    const settings = {
      ...this.getCohortManagerSettings(),
      ...newSettings
    }
    this.storage.setObject(this.COHORT_MANAGER_KEY, settings)
  }
}
