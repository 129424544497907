import { Injectable } from '@angular/core'

import { ComponentType } from '@angular/cdk/overlay'

import { Event, InvestorSummary } from '@libs/models'
import { EventInvestorSidenav } from '../models/event-investor-sidenav'

import { SidenavService } from '@app/layout/services/sidenav.service'

// ----------------------------------------------------------

@Injectable()
export class EventInvestorService {

  openSidenav<
    E extends Event,
    D extends InvestorSummary,
    C extends EventInvestorSidenav<E, D>
  >(
    sidenavComponent: ComponentType<C>,
    event: E,
    data?: D
  ): Promise<boolean> {
    return this.sidenavService.openSync(sidenavComponent, { event, ...data })
  }

  // ----------------------------------------------------

  constructor(
    private sidenavService: SidenavService
  ) {}
}
