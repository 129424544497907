import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core'

import { splitAt } from 'ramda'

import { IBreadcrumbItem } from '../../models/breadcrumbs'
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../route-animation'

// ----------------------------------------------------------

@Component({
  selector: 'sl-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: [ './breadcrumbs.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnChanges {

  @Input() breadcrumbs: IBreadcrumbItem[] = []
  @Input() isAdmin = true
  @Input() showAllCrumbs = true

  crumbs: Array<IBreadcrumbItem & { isLink: boolean }> = []
  currentPage: IBreadcrumbItem

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS

  items: Array<IBreadcrumbItem & { isLast: boolean, isLink: boolean }> = []

  // ----------------------------------------------------

  ngOnChanges() {
    const [ previous, current ] = splitAt(-1, this.breadcrumbs)
    this.currentPage = current[ 0 ]
    this.crumbs = this.showAllCrumbs
      ? previous.map(breadcrumb => ({ ...breadcrumb, isLink: breadcrumb.route && (!breadcrumb.isAdminRoute || this.isAdmin) }))
      : []
  }
}
