import { Component, Input, Optional, Self, ElementRef, type OnInit, Inject } from '@angular/core'
import { NgControl, UntypedFormBuilder } from '@angular/forms'
import { FocusMonitor } from '@angular/cdk/a11y'
import { MatFormFieldControl } from '@angular/material/form-field'

import { type Observable, of } from 'rxjs'

import { Api, BackendService } from '@libs/backend'
import { Event } from '@libs/models'
import { getEarliest, getLatest } from '@libs/utils'

import { DatepickerComponent } from '../datepicker/datepicker.component'

// ----------------------------------------------------------

@Component({
  selector: 'sl-event-datepicker',
  templateUrl: '../datepicker/datepicker.component.html',
  styleUrls: [ '../datepicker/datepicker.component.scss' ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: EventDatepickerComponent
    }
  ]
})
export class EventDatepickerComponent extends DatepickerComponent implements OnInit {
  @Input() event: Event

  // ----------------------------------------------------

  constructor(
    override readonly fb: UntypedFormBuilder,
    override readonly fm: FocusMonitor,
    override readonly elRef: ElementRef<HTMLElement>,
    @Optional() @Self() override readonly ngControl: NgControl,
    @Inject(Api) private api: BackendService
  ) {
    super(fb, ngControl, fm, elRef)
  }

  // ----------------------------------------------------

  override ngOnInit() {
    super.ngOnInit()

    this.subscriptions.push(
      this.getValidEffectiveDate(this.event).subscribe(
        dates => {
          this.minDate = getLatest([ dates.minDate, this.minDate ])
          this.maxDate = getEarliest([ dates.maxDate, this.maxDate ])
        }
      )
    )
  }

  // ----------------------------------------------------

  getValidEffectiveDate(event: Event | null): Observable<{ minDate: Date | null, maxDate: Date | null }> {
    if (!event?.closed) {
      return of({ minDate: null, maxDate: null })
    }

    return this.api
      .one(event.company.field, event.company.id)
      .one(event.field, event.id)
      .get('validEffectiveDates')
  }
}
