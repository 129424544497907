import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { Subject } from 'rxjs'

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>()

  firstPageLabel = $localize`First page`
  itemsPerPageLabel = $localize`Items per page:`
  lastPageLabel = $localize`Last page`

  nextPageLabel = $localize`Next page`
  previousPageLabel = $localize`Previous page`

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length <= 0 || pageSize <= 0) {
      return `–`
    }

    const startIndex = page * pageSize

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize

    return $localize`${startIndex + 1} – ${endIndex} of ${length}`
  }
}
