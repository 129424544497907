import { formatCurrency as _formatCurrency, formatNumber } from '@angular/common'

import { differenceInDays } from 'date-fns'

import { type Currency, InterestType } from '@libs/models'

// ------------------------------------------------------------

/**
 * Calculates the Return On Investment (ROI) based on the investment and payout
 * @param investment {number}: The amount of money invested by a shareClass or shareholder
 * @param payout {number}: The amount of money paid to that shareClass or shareholder after liquidation
 * @returns {?number}: Return on investment
 */
export function calculateROI(investment: number, payout: number): number | undefined {
  // Validate params
  if (!Number.isFinite(investment) || !Number.isFinite(payout)) {
    return undefined
  }

  return investment > 0
    ? Math.round(100 * (payout - investment) / investment) / 100
    : 0
}

// ------------------------------------------------------------

export function abbrCurrency(amount: number, symbol = '£', symbolAfter = false): string {
  // Validate 'amount' param
  if (!Number.isFinite(amount)) {
    return undefined
  }

  // Validate 'symbol' param
  if (typeof symbol !== 'string' || symbol.length < 1) {
    return undefined
  }

  // Validate 'symbolAfter' param
  if (typeof symbolAfter !== 'boolean') {
    return undefined
  }

  let res = ''

  const abbrev = (p, m, u, d) => Math.round(p / m) / d + u

  if (amount >= 999995000) {
    res += abbrev(amount, 1e7, 'bn', 100)
  } else if (amount >= 999950) {
    res += abbrev(amount, 1e4, 'm', 100)
  } else if (amount >= 1000) {
    res += abbrev(amount, 1e2, 'k', 10)
  } else if (amount >= 10) {
    res += Math.round(amount)
  } else if (amount === 0) {
    res += 0
  } else {
    res += amount.toFixed(2)
  }

  res = symbolAfter ? res + symbol : symbol + res

  return res
}

// ------------------------------------------------------------

const ZEROES_REGEX = /\.0+$/

export function formatCurrency(
  value: number,
  locale: string,
  currency: Currency,
  digitsInfo = '1.2'
): string {
  // Check if value ends in all zero digits and change
  // digitsInfo to prevent any decimal digits being shown.
  //
  // Use formatNumber so we aren't checking a localised
  // number that might have a different decimal separator.
  const simpleResult = formatNumber(value, 'en', digitsInfo)

  if (simpleResult.match(ZEROES_REGEX)) {
    digitsInfo = '1.0-0'
  }

  // Use formatCurrency from @angular/common
  return _formatCurrency(value, locale, currency.symbol, currency.code, digitsInfo)
}

// ------------------------------------------------------------

export function getPortion(overallValue: number, portionSize: number, overallSize: number): number {
  return overallValue > 0 && overallSize > 0
    ? overallValue * portionSize / overallSize
    : 0
}

// ------------------------------------------------------------

export function calculateInterest({
  principal,
  interestType = InterestType.Simple,
  interestRate,
  startDate,
  endDate
}: {
  principal: number
  interestType?: InterestType
  interestRate: number
  startDate: Date
  endDate: Date
}): number {
  if (principal === 0) {
    return 0
  }

  const roi = calculateInterestRate({
    interestType,
    interestRate,
    startDate,
    endDate
  })

  return principal * (roi - 1)
}

// ------------------------------------------------------------

export function calculateInterestRate({
  interestType = InterestType.Simple,
  interestRate,
  startDate,
  endDate
}: {
  interestType?: InterestType
  interestRate: number
  startDate: Date
  endDate: Date
}): number {
  if (interestType === InterestType.None || interestRate === 0) {
    return 1
  }

  const days = differenceInDays(endDate, startDate)

  if (days === 0) {
    return 1
  }

  const rate = interestRate / 100

  if (interestType === InterestType.Simple) {
    return 1 + rate * days / 365
  }

  const fullYears = Math.floor(days / 365)
  const remainingDays = days % 365

  return Math.pow(1 + rate, fullYears) * (1 + rate * remainingDays / 365)
}
