import { Component, Input, Output, EventEmitter } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'

// ----------------------------------------------------------

@Component({
  selector: 'sl-simple-dialog-wrapper',
  templateUrl: './simple-dialog-wrapper.component.html',
  styles: [ `
    :host {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  ` ],
  exportAs: 'slSimpleDialogWrapper'
})
export class SimpleDialogWrapperComponent {
  @Input() form: AbstractControl = new UntypedFormGroup({})

  @Input() headerTitle?: string
  @Input() contentClass?: string

  @Input() showDefaultButtons = true
  @Input() buttonAlign = 'end'

  @Input() okButton = true
  @Input() okLabel = $localize`Done`
  @Input() okAriaLabel?: string

  @Input() cancelButton = true
  @Input() cancelLabel = $localize`Cancel`
  @Input() cancelAriaLabel?: string

  @Input() closeOnClick = true
  @Input() loading = false

  @Output() okClicked = new EventEmitter<void>()
  @Output() cancelClicked = new EventEmitter<void>()

  errorMessage: string | null = null

  // ----------------------------------------------------

  public setErrorMessage(message: string) {
    this.errorMessage = message
  }

  public clearErrorMessage() {
    this.errorMessage = null
  }

  // ----------------------------------------------------

  onOkClicked($event) {
    this.okClicked.emit($event)
  }
}
