import { PurchaseStage, ProductId } from '../things/money/product'
import { Event } from '../things/events/event'
import { PlanId } from './plan.model'

// ----------------------------------------------------------

export type EventFieldSingular =
  | 'advanceAssurance'
  | 'compliance'
  | 'convertibleNote'
  | 'emiValuation'
  | 'optionExercise'
  | 'optionGrant'
  | 'optionScheme'
  | 'round'
  | 'shareTransfer'
  | 'stockSplit'

// ----------------------------------------------------------

export type EventFieldPlural =
  | 'advanceAssurances'
  | 'compliances'
  | 'convertibleNotes'
  | 'emiValuations'
  | 'optionExercises'
  | 'optionGrants'
  | 'optionSchemes'
  | 'rounds'
  | 'shareTransfers'
  | 'stockSplits'

// ----------------------------------------------------------

export enum SingularEventFields {
  AdvanceAssurance = 'advanceAssurance',
  Compliance = 'compliance',
  ConvertibleNote = 'convertibleNote',
  EmiValuation = 'emiValuation',
  OptionExercise = 'optionExercise',
  OptionGrant = 'optionGrant',
  OptionScheme = 'optionScheme',
  Round = 'round',
  ShareTransfer = 'shareTransfer',
  StockSplit = 'stockSplit'
}

// ----------------------------------------------------------

export enum PluralEventFields {
  AdvanceAssurance = 'advanceAssurances',
  Compliance = 'compliances',
  ConvertibleNote = 'convertibleNotes',
  EmiValuation = 'emiValuations',
  OptionExercise = 'optionExercises',
  OptionGrant = 'optionGrants',
  OptionScheme = 'optionSchemes',
  Round = 'rounds',
  ShareTransfer = 'shareTransfers',
  StockSplit = 'stockSplits'
}

// ----------------------------------------------------------

export type OptionalEventFields = {
  [ field in EventFieldSingular ]?: string
}

// ----------------------------------------------------------

export interface IEventApprovalPurchase {
  product?: ProductId
  stage?: PurchaseStage

  subscription?: PlanId
}

// ----------------------------------------------------------

export interface IEventApprovalBroadcast {
  message: string
  param?: any
}

// ----------------------------------------------------------

export interface IEventApprovalOptions {
  approveButtonText?: string
  approveButtonIcon?: string
  beforeApproval?: (event: Event, approvalOptions: IEventApprovalOptions) => Promise<boolean>
  title?: string
  text?: string
  ok?: string
  purchase?: IEventApprovalPurchase
  targetState?: string | Array<string | number>
  targetStateParams?: object
  targetStateOptions?: object
  broadcast?: IEventApprovalBroadcast
  tooltip?: string
}
