import { omit } from 'ramda'

import { RequestOptions, StringToStringOrStrings, ParameterMap } from '../models/request-options.model'

// ----------------------------------------------------------

export const stripSpecialFieldsFromResponse = omit([ '_embedded', '_links' ])

// ----------------------------------------------------------

export function updateOptions(
  dest: RequestOptions,
  src?: RequestOptions,
  append = false
): void {
  if (!src) {
    return
  }

  for (const [ k,  v ] of Object.entries(src)) {
    if (k !== 'headers' && k !== 'params') {
      dest[ k ] = v
    }
  }

  if (src.headers) {
    dest.headers = updateStringMap(dest.headers || {}, src.headers, append)
  }

  if (src.params) {
    dest.params = updateStringMap(dest.params || {}, src.params, append)
  }
}

// ----------------------------------------------------------

export function processParamMap(
  paramMap: ParameterMap
): StringToStringOrStrings {
  const dest: StringToStringOrStrings = {}

  for (const key of Object.keys(paramMap)) {
    const value = paramMap[ key ]

    if (value != null) {
      dest[ key ] = typeof value === 'number'
        ? String(value)
        : value
    }
  }

  return dest
}

// ----------------------------------------------------------

export function updateStringMap(
  dest: StringToStringOrStrings,
  src: StringToStringOrStrings,
  append = false
): StringToStringOrStrings {
  for (const key of Object.keys(src)) {
    const value = src[ key ]

    if (append) {
      if (typeof dest[ key ] === 'string') {
        dest[ key ] = [ dest[ key ] as string ]
      }

      if (Array.isArray(value)) {
        (dest[ key ] as string[]).push(...value)
      } else {
        (dest[ key ] as string[]).push(value)
      }
    } else {
      dest[ key ] = value
    }
  }

  return dest
}

// ----------------------------------------------------------

export class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`)
  }
}
