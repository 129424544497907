import type { ProductId } from '../things/money/product'
import { Products } from '../things/money/product'

import { Region } from './region.model'
import type { DocumentTypeId } from './document-types.model'
import { getAlphabeticalSequenceIndex } from '@libs/utils'

// ------------------------------------------------------------

export enum OptionSchemeType {
  ADVISOR = 'ADVISOR',
  BSPCE = 'BSPCE',
  EMI = 'EMI',
  Unapproved = 'UNAPPROVED',
  APAC = 'APAC'
}

export const OptionSchemeTypes = {
  [ Region.Commonwealth ] : [ OptionSchemeType.EMI, OptionSchemeType.Unapproved ],
  [ Region.Europe ]: [],
  [ Region.France ]: [ OptionSchemeType.BSPCE, OptionSchemeType.ADVISOR ],
  [ Region.Germany ]: [],
  [ Region.HongKong ]: [ OptionSchemeType.APAC ],
  [ Region.Ireland ]: [ OptionSchemeType.EMI, OptionSchemeType.Unapproved ],
  [ Region.Singapore ]: [ OptionSchemeType.APAC ]
} as const

// ------------------------------------------------------------

export interface IOptionSchemeMetadata {
  product: ProductId
  name: string
  emi: boolean
  image: string
  description: string
  blurb: string
}

export interface IOptionSchemeMarketingData {
  marketingFeatures: string[]
  grantMarketingFeatures: string[]
}

export interface IOptionSchemeDocumentData {
  agreementType: DocumentTypeId
  subscriptionLetterType?: DocumentTypeId
  needsUserWitness: boolean
  needsCompanyWitness: boolean
}

// ------------------------------------------------------------

type OptionSchemeDataByRegionAndType<T> = {
  [region in keyof typeof OptionSchemeTypes]: {
    [type in typeof OptionSchemeTypes[region][number]]: T
  }
}

export const OptionSchemeMetadata: OptionSchemeDataByRegionAndType<IOptionSchemeMetadata> = {
  [ Region.Commonwealth ]: {
    [ OptionSchemeType.EMI ]: {
      product: Products.OptionScheme,
      emi: true,
      name: $localize`EMI Scheme`,
      image: 'assets/images/app/options/tax-scheme.png',
      description: $localize`Use this tax-efficient scheme to give options to employees. Contractors and advisors cannot use this scheme.`,
      blurb: $localize`
        An EMI Option Scheme is an HMRC-approved tax-efficient way to give share options to employees. For advisors and consultants an Unapproved
         Option Scheme is the way to go.
    `
    },
    [ OptionSchemeType.Unapproved ]: {
      product: Products.OptionSchemeUnapproved,
      emi: false,
      name: $localize`Unapproved Scheme`,
      image: 'assets/images/app/options/unapproved-scheme.png',
      description: $localize`Use this scheme to give options to advisors and consultants, or if your company falls outside HMRC’s EMI option scheme limits.`,
      blurb: $localize`Use this scheme to give options to advisors and consultants, or if your company falls outside HMRC’s EMI option scheme limits.`
    }
  },
  [ Region.Europe ]: {},
  [ Region.France ]: {
    [ OptionSchemeType.BSPCE ]: {
      product: Products.BSPCE,
      emi: true,
      name: $localize`BSPCE`,
      image: 'assets/images/app/options/tax-scheme.png',
      description: $localize`Use this tax-optimized scheme to grant options to your employees and management team. Contractors and advisors are not eligible under this scheme.`,
      blurb: $localize`
      A BSPCE option plan (bons de souscription de parts de créateur d’entreprise) is a tax efficient way for
       eligible companies to give for free share options to their employees or directors. Both the company and the
       beneficiaries will benefit from tax incentives. Beneficiaries have to pay taxes only upon sale of the shares
       and employees with three or more years of seniority will be eligible to a flat tax rate of 30%.

      If the beneficiary is not eligible for BSPCE, for instance a consultant or an advisor, then BSA Advisor is
       the way to go.
    `
    },
    [ OptionSchemeType.ADVISOR ]: {
      product: Products.BSAAdvisor,
      emi: false,
      name: $localize`BSA Advisor`,
      image: 'assets/images/app/options/unapproved-scheme.png',
      description: $localize`Grant BSA Advisor to consultants and advisors to incentivize them on the long-term performance of your company.`,
      blurb: $localize`
      Use this scheme if you cannot meet the criteria for a tax advantaged scheme or are
       seeking to reward other people outside of the limits of your tax advantaged scheme.
    `
    }
  },
  [ Region.Germany ]: {},
  [ Region.HongKong ]: {
    [ OptionSchemeType.APAC ]: {
      product: Products.OptionSchemeUnapproved,
      emi: false,
      name: $localize`Option Scheme`,
      image: 'assets/images/app/options/unapproved-scheme.png',
      description: $localize`Use this scheme to give options to employees, advisors and consultants.`,
      blurb: $localize`Use this scheme to give options to employees, advisors and consultants.`
    }
  },
  [ Region.Ireland ]: {
    [ OptionSchemeType.EMI ]: {
      product: Products.OptionScheme,
      emi: true,
      name: $localize`EMI Scheme`,
      image: 'assets/images/app/options/tax-scheme.png',
      description: $localize`Use this UK HMRC tax-efficient scheme to give options to your UK employees. Non-UK employees, as well as any contractors and advisors cannot use this scheme.`,
      blurb: $localize`
        An EMI Option Scheme is an HMRC-approved tax-efficient way to give share options to UK employees. For advisors, consultants and Irish employees an Unapproved Option Scheme is the way to go.`
    },
    [ OptionSchemeType.Unapproved ]: {
      product: Products.OptionSchemeUnapproved,
      emi: false,
      name: $localize`Unapproved Scheme`,
      image: 'assets/images/app/options/unapproved-scheme.png',
      description: $localize`Use this scheme to give options to your Irish employees as well as advisors and consultants.`,
      blurb: $localize`Use this scheme to give options to employees,advisors and consultants.`
    }
  },
  [ Region.Singapore ]: {
    [ OptionSchemeType.APAC ]: {
      product: Products.OptionSchemeUnapproved,
      emi: false,
      name: $localize`Option Scheme`,
      image: 'assets/images/app/options/unapproved-scheme.png',
      description: $localize`Use this scheme to give options to employees, advisors and consultants.`,
      blurb: $localize`Use this scheme to give options to employees, advisors and consultants.`
    }
  }
}

export const OptionSchemeMarketingData: OptionSchemeDataByRegionAndType<IOptionSchemeMarketingData> = {
  [ Region.Commonwealth ]: {
    [ OptionSchemeType.EMI ]: {
      marketingFeatures:  [
        $localize`EMI Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`,
        $localize`Joint Election under S431 ITEPA`,
        $localize`Joint NIC Election`
      ]
    },
    [ OptionSchemeType.Unapproved ]: {
      marketingFeatures: [
        $localize`Unapproved Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`,
        $localize`Joint Election under S431 ITEPA`,
        $localize`Joint NIC Election`
      ]
    },
  },
  [ Region.Europe ]: {},
  [ Region.France ]: {
    [ OptionSchemeType.BSPCE ]: {
      marketingFeatures: [
        $localize`CEO Resolution`,
        $localize`BSPCE Scheme`,
        $localize`Simplified Shareholders Agreement`,
      ],
      grantMarketingFeatures: [
        $localize`CEO resolutions to grant options`,
        $localize`CEO additional report`,
        $localize`Grant letter for the Beneficiary`
      ]
    },
    [ OptionSchemeType.ADVISOR ]: {
      marketingFeatures: [
        $localize`CEO Resolution`,
        $localize`BSA Grant Agreement`,
        $localize`Subscription Form`,
        $localize`CEO Additional Report`
      ],
      grantMarketingFeatures: [
        $localize`CEO Resolution`,
        $localize`BSA grant agreement`,
        $localize`Subscription Form`,
        $localize`Additional CEO Report`
      ]
    }
  },
  [ Region.Germany ]: {},
  [ Region.HongKong ]: {
    [ OptionSchemeType.APAC ]: {
      marketingFeatures: [
        $localize`Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`
      ]
    }
  },
  [ Region.Ireland ]: {
    [ OptionSchemeType.EMI ]: {
      marketingFeatures:  [
        $localize`EMI Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`,
        $localize`Joint Election under S431 ITEPA`,
        $localize`Joint NIC Election`
      ]
    },
    [ OptionSchemeType.Unapproved ]: {
      marketingFeatures: [
        $localize`Unapproved Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`,
      ]
    },
  },
  [ Region.Singapore ]: {
    [ OptionSchemeType.APAC ]: {
      marketingFeatures: [
        $localize`Share Option Scheme`,
        $localize`Board Resolution to adopt it`,
        $localize`Option Grant for each person`,
        $localize`Option Certificate for each person`
      ],
      grantMarketingFeatures: [
        $localize`Option Agreement`
      ]
    }
  }
}

export const OptionSchemeDocumentData: OptionSchemeDataByRegionAndType<IOptionSchemeDocumentData> = {
  [ Region.Commonwealth ]: {
    [ OptionSchemeType.EMI ]: { agreementType: 'OPAG', needsUserWitness: true, needsCompanyWitness: true },
    [ OptionSchemeType.Unapproved ]: { agreementType: 'OPAG', needsUserWitness: true, needsCompanyWitness: true }
  },
  [ Region.Europe ]: {},
  [ Region.France ]: {
    [ OptionSchemeType.BSPCE ]: { agreementType: 'BOGAG', needsUserWitness: false, needsCompanyWitness: false },
    [ OptionSchemeType.ADVISOR ]: { agreementType: 'ADVGAG', subscriptionLetterType: 'ADVGSL', needsUserWitness: false, needsCompanyWitness: false }
  },
  [ Region.Germany ]: {},
  [ Region.HongKong ]: {
    [ OptionSchemeType.APAC ]: { agreementType: 'OPAG', needsUserWitness: true, needsCompanyWitness: true }
  },
  [ Region.Ireland ]: {
    [ OptionSchemeType.EMI ]: { agreementType: 'IEOPAG', needsUserWitness: true, needsCompanyWitness: false },
    [ OptionSchemeType.Unapproved ]: { agreementType: 'IEOPAG', needsUserWitness: true, needsCompanyWitness: false }
  },
  [ Region.Singapore ]: {
    [ OptionSchemeType.APAC ]: { agreementType: 'OPAG', needsUserWitness: true, needsCompanyWitness: true }
  }
}

// ------------------------------------------------------------

export const OptionSchemeDisclaimers = new Map<OptionSchemeType, { title: string, blurb: string, requirements: string[] }>([
  [ OptionSchemeType.EMI,
    {
      title: $localize`Are you EMI qualified?`,
      blurb: $localize`
        Before you start on your EMI Option Scheme, check that your company qualifies.
        To qualify, a company needs to meet all requirements below.
        If in doubt, <a href="https://seedlegals.com/blog/company-qualifies-emi-share-option-scheme" target="_blank">see our detailed requirements</a> list.
      `,
      requirements: [
        $localize`It must be a trading company.`,
        $localize`It can't be an investment company or do a disqualifying activity.`,
        $localize`The total value of its gross assets must be less that £30M`,
        $localize`It must have a permanent establishment in the UK.`,
        $localize`It must be independent.`,
        $localize`If it has subsidiaries, they must be qualifying subsidiaries.`,
        $localize`It must have fewer than 250 employees.`,
        $localize`No employee getting EMI options can own more than 30% of its shares.`,
        $localize`No employee can own more than £250,000 in EMI shares.`,
        $localize`It must have allocated less than £3M in EMI shares.`,
        $localize`And of course you should have at least one employee, or plan to have one within 90 days..`
      ]
    }
  ],

  [ OptionSchemeType.BSPCE,
    {
      title: $localize`Are you BSPCE qualified?`,
      blurb: $localize`
        Before you start on your BSPCE Option Scheme, check that your company qualifies.
        To qualify, a company needs to meet all requirements below.

        For more detail, <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000038836589/2019-12-30" target="_blank">Article 163 bis of the CGI</a> (Code Général des Impôts) describes the criteria.
        In case of doubt, please seek legal advice with a lawyer.
      `,
      requirements: [
        $localize`The company is incorporated in France as a Société par Actions Simplifiée and subject to corporate tax, or incorporate in another eligible jurisdiction (EU or other) subject to the equivalent of France corporate tax;`,
        $localize`It must have been incorporated less than 15 years ago;`,
        $localize`Your company is private, or floated on a stock exchange with a valuation of less than 150 million euros (Companies floated on a stock exchange can remain eligible to BSPCE, but only for 3 more years once their valuation reaches 150 million euros);`,
        $localize`At least 25% of it's shares must be held either by individuals or by companies of which 75% of their share capital is held by individuals at all times;`,
        $localize`It must have been incorporated as a whole new business (excluding the carry-over of a pre existing activity);`,
        $localize`BSPCE options may only be granted to employees of the Company, management of the Company, and members of the board, supervisory board, or similar board members when the company is a Société par Actions Simplifiée.`
      ]
    }
  ]
])

// ------------------------------------------------------------

/**
 * Get a name for a new scheme being created.
 *
 * @param {number} schemeCount how many schemes of the same type already exists
 * @param {OptionSchemeType} type the type of scheme
 * @param {Region} region the company region
 * @returns {string}
 */
export function getOptionSchemeDescription(schemeCount: number, type: OptionSchemeType, region: Region): string {
  let name = OptionSchemeMetadata[ region ][ type ].name

  if (type === OptionSchemeType.BSPCE) {
    name += $localize` of Category`
  }

  // BSCPE does not skip A, or maybe it does, and the others don't or do, for some reason
  const skipA = type !== OptionSchemeType.BSPCE
  const seqCode = getAlphabeticalSequenceIndex(schemeCount, skipA).trim()

  if (seqCode) {
    name += ' ' + seqCode
  }

  return name
}
