import { Component, Input } from '@angular/core'

// ----------------------------------------------

const ICON_BY_LEVEL = {
  info: 'information',
  done: 'check-circle',
  warn: 'information',
  error: 'alert-circle',
  plain: 'information',
  document: 'file-document'
}

@Component({
  selector: 'sl-alert',
  templateUrl: './alert.component.html',
  styleUrls: [ './alert.component.scss' ]
})
export class AlertComponent {
  @Input() headerTitle?: string
  @Input() level?: 'info' | 'warn' | 'error' | 'done' | 'plain' | 'document' | 'none' = 'info'
  @Input() iconSize = 's16'

  // ----------------------------------------------------

  get icon() {
    return ICON_BY_LEVEL[ this.level ]
  }
}
