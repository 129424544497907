import type { ICohortConfig } from './cohort-config.model'
import { CohortManager } from './cohort-config.model'
import { GBCohortMemberImmigrationTypes } from './cohort-immigration'
import { getMemberQuestions } from './cohort-member-questions'
import { getMemberUploadQuestions } from './cohort-member-uploads'

// ------------------------------------------------------

export const C13Config: ICohortConfig = {
  name: 'Carbon13',
  description: 'Carbon 13',
  cohortLogo: '/assets/images/app/logos/c13-logo-93x52.png',
  toolbarBackground: '#217eb8',
  offerLetterDocType: 'C13OL',
  FSRVDocType: 'C13FSA',
  fundingDocTypes: [ 'C13AG', 'C13OL3', 'C13OAG' ],
  cohortManager: CohortManager.Carbon13,
  cohortMemberUploads: getMemberUploadQuestions([
    'passport',
    'utility',
  ]),
  cohortCountry: { code: 'GB', name: $localize`United Kingdom` },
  immigrationQuestionText: $localize`Do you have the right to work in the UK?`,
  immigrationTypes: GBCohortMemberImmigrationTypes,
  cohortMembersQuestions: getMemberQuestions([
    'passportNumber',
    'currentCompany',
    'companyInfo',
    'phd',
    'phdIpClaim',
  ]),
  cities: [],
}
