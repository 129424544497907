import type { DocumentTypeId } from '@libs/models'

// ------------------------------------------------------------

export type DocumentStatusChecker = {
  checker: DocumentStatusCheckerCallback
  tooltipText: string
}

// Answers should be Record<string, unknown>
type DocumentStatusCheckerCallback = (answers?: object) => boolean

// ------------------------------------------------------------

const displayOptionsChecker: DocumentStatusChecker = {
  checker: answers => {
    const answer = answers?.['displayOptions']?.['investment']
    return !!answer && answer === 'false' || answer === 'mask' || answer === 'none'
  },
  tooltipText: $localize`To enable signing, first enable display of the cap table and investors, in Terms > Display Options`,
}

const DocumentTypesStatusCheckers = {
  // Seed Round
  TESH: displayOptionsChecker,
  SHAG: displayOptionsChecker,
  // FR Seed Round
  GRTESH: displayOptionsChecker,
  // Seed Note
  SNTS: displayOptionsChecker,
  SNAG: displayOptionsChecker,
}

export const getDocumentStatusChecker = (
  type: DocumentTypeId
): DocumentStatusChecker | undefined => DocumentTypesStatusCheckers[ type ]
