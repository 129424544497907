import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'

import { EntityComponent, MoreEntitiesComponent } from './components/entity/entity.component'
import { EntityListComponent } from './components/entity-list/entity-list.component'
import { NamedEntityComponent } from './components/named-entity/named-entity.component'

// ----------------------------------------------------------

const EXPORT_COMPONENTS = [
  EntityComponent,
  EntityListComponent,
  MoreEntitiesComponent,
  NamedEntityComponent,
]

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: EXPORT_COMPONENTS,
  exports: EXPORT_COMPONENTS,
})
export class EntityComponentsModule {}
