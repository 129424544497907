<div class="entity-info-wrapper no-overflow p-16 gap-16"
     fxLayout="row"
     fxLayoutAlign="start start">

  <sl-avatar [entity]="entity"
             [size]="40"></sl-avatar>

  <div fxFlex
       fxLayout="column">

    <span *ngIf="!pitchInterest else pitch" class="entity-name">{{ entity.name }}</span>
    <ng-template #pitch>
          <span *ngIf="pitchInterest?.companyName; else pitchNoCompany"
                class="entity-name">{{ pitchInterest.companyName }}</span>
      <ng-template #pitchNoCompany>
        <span class="entity-name">{{ pitchInterest?.firstName }} {{ pitchInterest?.lastName }}</span>
      </ng-template>
    </ng-template>

    <span class="reference">
      {{ entityReference | slDash }}
      <mat-icon *ngIf="isConnected"
                fontIcon="icon-check-circle"
                matTooltip="Email verified"
                i18n-matTooltip
                class="s14"></mat-icon>
    </span>
    <small *ngIf="showAddress" class="address">{{ entity.address | address }}</small>
  </div>

  <div fxFlex></div>

  <button (click)="actionClicked.emit(entity)"
          [disabled]="actionDisabled"
          mat-icon-button>
    <mat-icon *ngIf="actionIcon" fontIcon="icon-{{ actionIcon }}"></mat-icon>
  </button>
</div>
