import { NgModule } from '@angular/core'

import { SharedModule } from '@libs/shared'
import { LayoutComponentsModule } from '@app/layout/layout-components.module'
import { EntitiesComponentsModule } from '@app/entities/entities-components.module'

import { COMPONENTS, EXPORT_COMPONENTS } from './components'

// ----------------------------------------------------------

@NgModule({
  imports: [
    SharedModule,
    LayoutComponentsModule,
    EntitiesComponentsModule
  ],
  declarations: [
    ...COMPONENTS,
    ...EXPORT_COMPONENTS
  ],
  exports: EXPORT_COMPONENTS
})
export class PaymentsComponentsModule {}
