import { differenceInMonths, differenceInYears, isBefore } from 'date-fns'

import { DocumentEventAdapter } from './document-event-adapters'
import type { ApiFieldSpec } from '../model'
import { StatementCollection } from './statement'
import type { User } from '../user'
import type { IEventViewState } from './event'
import type { Share } from '../stock'

import type { EventSupportingDocument } from '../../models'
import { EventCategory, InvestmentScheme, SupportingDocumentType } from '../../models'
import { EVENT_LEGACY_DATES, isBeforeLegacyDate } from '../../models/event-legacy-dates'

import { asDate, isSameOrAfter, isSameOrBefore } from '@libs/utils'

// ------------------------------------------------------------

export class ComplianceEvent extends DocumentEventAdapter {
  readonly domain = 'compliances'
  readonly category = EventCategory.SEISCompliance

  statements = new StatementCollection()

  startDate: string
  endDate: string
  signingDirector: User

  supportingDocuments: EventSupportingDocument[] = []

  // ----------------------------------------------------

  constructor(data) {
    super(data)
  }

  // ----------------------------------------------------

  override get safeName() {
    return $localize`S/EIS Compliance`
  }

  // ----------------------------------------------------

  get hasPurchased() {
    return this.company.hasPurchase(this)
  }

  // ----------------------------------------------------

  get hasUirs() {
    return this.statements.length > 0 &&
      this.statements.items().every(statement => statement.uir)
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'raise', 'compliances', this.id ]
    }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    const viewState = this.getViewState()
    return {
      state: [ ...viewState.state, 'questions', 'details' ],
      params: viewState.params
    }
  }

  // ----------------------------------------------------

  override getQuestionState(
    sectionKey: string,
    questionKey?: string
  ) {
    const { state, params } = super.getQuestionState(sectionKey, questionKey)

    return {
      state,
      params: {
        ...params,
        view: 'questions'
      }
    }
  }

  // ----------------------------------------------------

  override get hasIndividualQuestionsForDocuments(): boolean {
    return false
  }

  // ----------------------------------------------------

  get coveredShareCount(): number {
    return this.company.getSeisEisShares()
      .filter(s => isSameOrAfter(asDate(s.acquired), asDate(this.startDate)) && isSameOrBefore(asDate(s.acquired), asDate(this.endDate)))
      .length
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'startDate',
      'endDate',
      { key: 'signingDirector', include: 'update' }
    ]
  }

  // ----------------------------------------------------

  hasDocument(type: SupportingDocumentType): boolean {
    return this.supportingDocuments.some(doc => doc.type === type)
  }

  // ----------------------------------------------------

  get hasSEIS(): boolean {
    return this.statements.some(statement => statement.scheme === InvestmentScheme.SEIS)
  }

  get hasEIS(): boolean {
    return this.statements.some(statement => statement.scheme === InvestmentScheme.EIS)
  }

  // ----------------------------------------------------

  /**
   * @returns True if the tradingStartDate is at least 4 months before the application date of the compliance event.
   * Used in the JSON workflow.
   */
  hasTradedUnder4Months(tradingStartDate: string): boolean {
    return differenceInMonths(asDate(this.effectiveDate), asDate(tradingStartDate)) < 4
  }

  /**
   * @returns True if the application has SEIS and has traded under 4 months (included not started trading).
   */
  needsExpenditures(started: boolean, tradingStartDate: string): boolean {
    return this.hasSEIS && (!started || this.hasTradedUnder4Months(tradingStartDate))
  }

  get isKIC(): boolean {
    return this.answers['knowledgeIntensive']?.eligibility ?? false
  }

  /**
   * @returns True if within 10 years for KICs, or 7 for non-KICs, from the first commercial sale of a product or service.
   */
  withinInitialInvestingPeriod(tradingStartDate: string): boolean {
    const maxYears = this.isKIC ? 10 : 7
    return differenceInYears(asDate(this.effectiveDate), asDate(tradingStartDate)) < maxYears
  }


  // ----------------------------------------------------

  /**
   * @returns Company shares that are SEIS or EIS only
   * AND on or after the compliance event's start date
   * AND before the compliance event's end date.
   */
  get includedShares(): Share[] {
    const relevantSchemes = [ InvestmentScheme.SEIS, InvestmentScheme.EIS ]
    return this.company
      .getAllShares().filter(share => this.isWithinDateRange(share.issued) && relevantSchemes.includes(share.scheme))
  }

  private isWithinDateRange(date: string): boolean {
    return isSameOrAfter(asDate(date), asDate(this.startDate)) && isBefore(asDate(date), asDate(this.endDate))
  }

  // ----------------------------------------------------

  /**
   * @returns ComplianceEvent immediately before current compliance event.
   */
  get precedingCompliance(): ComplianceEvent | undefined {
    return this.company.compliances.find(compliance => compliance.effectiveDate < this.effectiveDate)
  }

  // ----------------------------------------------------

  /**
   * @returns ComplianceEvent immediately after current compliance event.
   */
  get subsequentCompliance(): ComplianceEvent | undefined {
    const compliancesAfter = this.company.compliances
      .filter(compliance => compliance.effectiveDate > this.effectiveDate)

    return compliancesAfter.length > 0
      ? compliancesAfter[ compliancesAfter.length - 1 ]
      : undefined
  }

  // ----------------------------------------------------

  // Old Compliance applications don't support UIRs
  get legacy(): boolean {
    return isBeforeLegacyDate(this, EVENT_LEGACY_DATES.ComplianceAddedUirs)
  }

}
