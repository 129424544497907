import { Component, Input, HostBinding, ViewChild, Optional, Self, ElementRef, Output, EventEmitter } from '@angular/core'
import { NgControl, UntypedFormBuilder } from '@angular/forms'

import { FocusMonitor } from '@angular/cdk/a11y'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatDatepicker } from '@angular/material/datepicker'

import { Subject } from 'rxjs'

import { asIsoString, FormControlComponent, isoFormat } from '@libs/utils'

type StartView = 'month' | 'year' | 'multi-year'

// ----------------------------------------------------------

@Component({
  selector: 'sl-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: [ './datepicker.component.scss' ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DatepickerComponent
    }
  ]
})
export class DatepickerComponent extends FormControlComponent<string>
  implements MatFormFieldControl<string> {
  static nextId = 0

  @ViewChild(MatInput, { static: true }) input: MatInput
  @ViewChild(MatDatepicker, { static: true }) datepicker: MatDatepicker<string>

  @HostBinding() id = `sl-datepicker-${DatepickerComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''

  // eslint-disable-next-line rxjs/finnish
  stateChanges = new Subject<void>()

  controlType = 'sl-datepicker'

  focused = false

  @Output() dateChange = new EventEmitter<string>()

  // ----------------------------------------------------

  @Input()
  get startView(): StartView {
    return this._startView
  }
  set startView(startView: StartView ) {
    this._startView = startView
  }
  _startView: StartView = 'month'

  // ----------------------------------------------------

  @Input()
  get value(): string {
    return isoFormat(new Date(this.controls.value.date))
  }
  set value(value: string) {
    this.controls.patchValue({ date: value }, { emitEvent: false })
    this.stateChanges.next()
  }

  // ----------------------------------------------------

  @Input()
  get minDate(): string | Date | null {
    return this._minDate
  }
  set minDate(value: string | Date | null) {
    this._minDate = asIsoString(value)
  }
  _minDate: string | null = null

  // ----------------------------------------------------

  @Input()
  get maxDate(): string | Date | null {
    return this._maxDate
  }
  set maxDate(value: string | Date | null) {
    this._maxDate = asIsoString(value)
  }
  _maxDate: string | null = null

  // ----------------------------------------------------

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value
    this.stateChanges.next()
  }
  _placeholder: string

  // ----------------------------------------------------

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: boolean | '') {
    this._required = value === '' || value

    this.stateChanges.next()
  }
  _required = false

  // ----------------------------------------------------

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: boolean | '') {
    this._disabled = value === '' || value

    if (value) {
      this.controls.disable({ emitEvent: false })
    } else {
      this.controls.enable({ emitEvent: false })
    }

    this.stateChanges.next()
  }
  _disabled = false

  // ----------------------------------------------------

  constructor(
    override readonly fb: UntypedFormBuilder,
    @Optional() @Self() public ngControl: NgControl,
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>
  ) {
    super(fb)

    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin
      this.stateChanges.next()
    })
  }

  // ----------------------------------------------------

  getControlGroup() {
    return this.fb.group({
      date: null
    })
  }

  // ----------------------------------------------------

  override writeValue(modelValue: string) {
    super.writeValue(modelValue)

    this.stateChanges.next()
  }

  // ----------------------------------------------------

  override changeValue(modelValue: string | undefined) {
    super.changeValue(modelValue)

    if (modelValue) {
      this.dateChange.emit(modelValue)
    }
  }

  // ----------------------------------------------------

  override convertModelDataToFormData(date: string) {
    return { date }
  }

  // ----------------------------------------------------

  override convertFormDataToModelData(formValue: { date: string }): string {
    return formValue.date
  }

  // ----------------------------------------------------

  get empty(): boolean {
    return !this.controls.value.date
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  get errorState(): boolean {
    return this.input.errorState
  }

  get autofilled(): boolean {
    return this.input.autofilled
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ')
  }

  onContainerClick(): void {
    this.input.onContainerClick()
    this.datepicker.open()
  }
}
