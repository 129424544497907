import { Component, Input, type OnInit, type OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'

import { map } from 'rxjs/operators'

import { Actions, ofType } from '@ngrx/effects'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { type Company, type User, type Entity, getAvatarColour } from '@libs/models'
import type { PitchInterest } from '@app/pitch/models/pitch.model'
import { UserUpdated } from '@app/users/+state/users.actions'
import { CompanyUpdated } from '@app/companies/+state/company.actions'

import { getInitials } from '@libs/utils'

import { environment } from '@env/environment'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() entity: Entity | PitchInterest
  @Input() size = 40
  @Input() sizeXs?: number

  isUser = false
  user: User | null = null
  company: Company | null = null
  pitchInterest: PitchInterest | null = null

  hasAvatar: boolean
  avatarUrl: string
  initials: string

  sizeStyle: object
  sizeStyleXs: object
  initialsStyle: object
  initialsStyleXs: object

  // ----------------------------------------------------

  constructor(
    private actions$: Actions,
    private changeDetector: ChangeDetectorRef
  ) {}

  // ------------------------------------------------------

  ngOnInit() {
    this.actions$
      .pipe(
        ofType(UserUpdated, CompanyUpdated),
        map(action => {
          return action.type === UserUpdated.type
            ? action.id
            : action.companyId
        }),
        untilDestroyed(this)
      )
      .subscribe(id => {
        if (this.entity.id === id) {
          this.ngOnChanges()
          this.changeDetector.detectChanges()
        }
      })
  }

  // ------------------------------------------------------

  ngOnChanges() {
    if (this.entity.id?.startsWith('pti_')) {
      this.pitchInterest = this.entity as PitchInterest
      if (this.pitchInterest.companyName) {
        this.initials = getInitials(this.pitchInterest.companyName)
      } else {
        this.isUser = true
        this.initials = getInitials(`${this.pitchInterest.firstName} ${this.pitchInterest.lastName}`)
      }
    } else if (this.entity.id?.startsWith('c_')) {
      this.company = this.entity as Company
      this.hasAvatar = !!this.company.picture
      this.avatarUrl = environment.assetsLocation + this.company.picture
      this.initials = getInitials(this.company.name)
    } else {
      this.isUser = true
      this.user = this.entity as User
      this.hasAvatar = !!this.user.picture

      // check for user that have their pictures set from auth0 on signup
      this.avatarUrl = this.user.picture?.startsWith('users/')
        ? environment.assetsLocation + this.user.picture
        : this.user.picture

      this.initials = getInitials(this.user.name || `${this.user.firstName} ${this.user.lastName}`)
    }

    this.sizeStyle = {
      width: this.size + 'px',
      lineHeight: this.size + 'px'
    }

    this.sizeStyleXs = {
      width: (this.sizeXs || this.size) + 'px',
      lineHeight: (this.sizeXs || this.size) + 'px'
    }

    this.initialsStyle = {
      fontSize: Math.floor(this.size / 3) + 'px',
      backgroundColor: getAvatarColour(this.entity.id)
    }

    this.initialsStyleXs = {
      fontSize: Math.floor((this.sizeXs || this.size) / 3) + 'px',
      backgroundColor: getAvatarColour(this.entity.id)
    }
  }
}
