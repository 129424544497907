<sl-simple-dialog-wrapper okLabel="Continue"
                          (okClicked)="okClicked()"
                          buttonAlign="center">
  <div fxLayout="column"
       fxLayoutAlign="start center"
       class="plan-subscription-dialog">
    <div class="mat-title">{{ item.title }}</div>

    <img [src]="item.image"
        class="deco">

    <div class="description">{{ item.description }}</div>

    <ng-container *ngIf="price > 0">
      <div class="price-intro" i18n>We’ll bill you</div>

      <div class="price">{{ price | slCurrency:currency }}<span *ngIf="showVat" i18n> + VAT</span></div>
    </ng-container>

    <ng-container *ngIf="price === 0">

      <p *ngIf="item.payLaterDescription; else freeMessage" class="price-intro">{{ item.payLaterDescription }}</p>

      <ng-template #freeMessage>
        <div class="price">{{ 0 | slCurrency:currency }}</div>
      </ng-template>

    </ng-container>

    <ng-container *ngIf="item.additionalInformation">
      <div class="additionalInformation">{{ item.additionalInformation }}</div>
    </ng-container>
    
    <p class="disclaimer" i18n>
      By continuing, you agree to our
      <a href="https://seedlegals.com/terms-of-service/" target="_blank">Terms of Service</a>.
    </p>
  </div>
</sl-simple-dialog-wrapper>
