import { commonEnvironment, defaultDebugOptions, defaultDebugStyles } from './common'
import {
  IDebugOptions,
  IDebugStyles,
  IEnvironmentConfig,
  Profile,
  ApiConfig,
  AuthConfig,
  LocationConfig,
  RegistrarConfig,
  SentryConfig,
  StripeConfig
} from './models'

// ------------------------------------------------------------

export const environment: IEnvironmentConfig = {
  ...commonEnvironment,
  ...ApiConfig.DEV,
  ...AuthConfig.DEV,
  //...HubspotConfig.DEV, // uncomment to run hubpost widget locally
  ...LocationConfig.DEV,
  ...RegistrarConfig.DEV,
  ...StripeConfig.DEV,

  profile: Profile.Dev,

  sentryOptions: {
    ...SentryConfig.GROW,
    enabled: false,
    environment: Profile.Dev,
    logErrorsInConsole: true
  },

  FLAGS: {
    DISABLE_COMPANIES_HOUSE_API: false,
    DISABLE_FRANCE: false,
    DISABLE_NOTIFICATIONS: false,
    TRACK_PAGE_VIEWS: false,
    DISABLE_LANGUAGE_SELECTION: false,
    DISABLE_DRAFTABLE: false,
    ENABLE_COMMENTS: true
  }
}

// ----------------------------------------------------------

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
import 'zone.js/plugins/zone-error'  // Included with Angular CLI.

// ----------------------------------------------------------

export const DebugOptions: IDebugOptions = {
  ...defaultDebugOptions,
  addGlobals: true,
  logActions: true,
  logStripeEvents: true,
  logConsoleOutput: true,
}

export const debugStyles: IDebugStyles = defaultDebugStyles
