
export const Fragments = {

  AddressFields: `
    fragment addressFields on Address {
      id
      line1
      line2
      city
      postcode
      country
    }
  `,

  PublicUserFields: `
    fragment publicUserFields on PublicUser {
      id
      name
      picture
      connected
      invited
      email
    }
  `,

  PublicCompanyFields: `
    fragment publicCompanyFields on PublicCompany {
      id
      name
      picture
    }
  `,

  PublicCompanyWithAppontmentsFields: `
    fragment publicCompanyWithAppontmentsFields on CompanyExcerpt {
      id
      name
      picture
      appointments {
        id
        roles
        user {
          ...publicUserFields
        }
      }
    }
  `,

  AppointmentFields: `
    fragment appointmentFields on Appointment {
      id
      roles
      user {
        ...publicUserFields
      }
      company {
        id
      }
    }
  `,

  EventFields: `
    fragment eventFields on Event {
      id
      effectiveDate
      company {
        id
      }
      documents {
        ...documentExcerptFields
      }

      ...on HasUploadedFiles {
        uploadedFiles {
          ...uploadedFileFields
        }
      }

      ...on HasEntityGroups {
        entityGroups {
          ...entityGroupFields
        }
      }
    }
  `,

  EventFileFields: `
    fragment eventFileFields on Event {
      id
      effectiveDate
      company {
        id
      }
      documents {
        ...documentFileFields
      }

      ...on HasUploadedFiles {
        uploadedFiles {
          ...uploadedFileExcerptFields
        }
      }
    }
  `,

  EventUploadedFileFields: `
    fragment eventUploadedFileFields on Event {
      id
      effectiveDate
      company {
        id
      }
      ...on HasUploadedFiles {
        uploadedFiles {
          ...uploadedFileExcerptFields
        }
      }
    }
  `,

  EventSupportingDocumentFields: `
    fragment eventSupportingDocumentFields on Event {
      id
      effectiveDate
      company {
        id
      }
      ...on HasUploadedFiles {
        uploadedFiles {
          ...uploadedFileSummaryFields
        }
      }
      ...on HasSupportingDocuments {
        supportingDocuments2 {
          name
          uploaded
          required
          metadata
        }
      }
    }
  `,

  RoundFields: `
    fragment roundFields on Round {
      type
      dilution
      newSharesAndOptions
      postRoundDilutedShareCount
    }
  `,

  EntityGroupFields: `
    fragment entityGroupFields on EntityGroup {
      id
      name
      dynamicType
      users {
        ...publicUserFields
      }
      companies {
        ...publicCompanyWithAppontmentsFields
      }
      round {
        id
      }
      boardMeeting {
        id
      }
    }
  `,

  EntityGroupRequestFields: `
    fragment entityGroupRequestFields on EntityGroupRequest {
      id
      approved
      rejected
      user {
        ...publicUserFields
      }
    }
  `,

  PermissionedFields: `
    fragment permissionedFields on Permissioned {
      usersWithAccess {
        ...publicUserFields
      }
      companiesWithAccess {
        ...publicCompanyWithAppontmentsFields
      }
      entityGroupsWithAccess {
        id
      }
    }
  `,

  MetadataFields: `
    fragment metadataFields on HasMetadata {
      ...metadataCountsFields
      ...signatoriesFields
      sharees {
        users {
          ...publicUserFields
        }
        companies {
          ...publicCompanyWithAppontmentsFields
        }
      }
      publishedUploadedFile {
        id
        containsSignatures
      }
    }
  `,

  MetadataCountsFields: `
    fragment metadataCountsFields on HasMetadata {
      sharedShareesCount
      shareesCount
      signaturesCount
      signatoriesCount
    }
  `,

  SignatoriesFields: `
    fragment signatoriesFields on HasMetadata {
      signatories {
        requiresWitness
        companyRole
        signatory {
          ...on PublicUser {
            ...publicUserFields
          }
          ...on PublicCompany {
            ...publicCompanyWithAppontmentsFields
            address {
              ...addressFields
            }
          }
        }
        signature {
          id
          name
          signed
          signedAs
          signature
          user {
            ...publicUserFields
          }
          company {
            id
          }
          witness {
            # id
            name
            signed
          }
        }
      }
    }
  `,

  DocumentFields: `
    fragment documentFields on Document {
      id
      type
      status
      inserted
      modified
      ...permissionedFields
      ...metadataFields
      event {
        id
      }
      company {
        id
      }
    }
  `,

  DocumentExcerptFields: `
    fragment documentExcerptFields on DocumentExcerpt {
      id
      type
      status
      inserted
      modified
      ...permissionedFields
      ...metadataFields
      event {
        id
      }
      company {
        id
      }
    }
  `,

  DocumentFileFields: `
    fragment documentFileFields on DocumentExcerpt {
      id
      type
      status
      inserted
      modified
      ...metadataCountsFields
      event {
        id
      }
      company {
        id
      }
    }
  `,

  UploadedFileFields: `
    fragment uploadedFileFields on UploadedFileExcerpt {
      id
      inserted
      name
      format
      mimeType
      sizeInBytes
      metadata
      uploader {
        ...publicUserFields
      }
      ...permissionedFields
      event {
        id
      }
      company {
        id
      }
    }
  `,

  UploadedFileExcerptFields: `
    fragment uploadedFileExcerptFields on UploadedFileExcerpt {
      id
      inserted
      name
      format
      mimeType
      sizeInBytes
      metadata
      uploader {
        ...publicUserFields
      }
      event {
        id
      }
      company {
        id
      }
    }
  `,

  UploadedFileSummaryFields: `
    fragment uploadedFileSummaryFields on UploadedFileExcerpt {
      id
      inserted
      name
      format
      mimeType
      sizeInBytes
      metadata
      uploader {
        ...publicUserFields
      }
      advanceAssurance {
        id
      }
      boardMeeting {
        id
      }
      compliance {
        id
      }
      emiValuation {
        id
      }
      exit {
        id
      }
      researchClaim {
        id
      }
      round {
        id
      }
    }
  `,

  ProposalFields: `
    fragment proposalFields on Proposal {
      id
      name
      answers
      investmentAmount
      investmentScheme
      target
      valuation
      roundCompletionDate
      approved
      effectiveDate
      optionType
      optionOwnership
      company {
        id
        name
        picture
        jurisdiction
        currency
        claimed
      }
      founderCompany {
        id
        name
        picture
        jurisdiction
        currency
        claimed
        appointments {
          id
          roles
          user {
            id
            name
            picture
            connected
            invited
            email
            firstName
            lastName
          }
          company {
            id
          }
        }
      }
      documents {
        id
        type
      }
      user {
        id
        email
        firstName
        lastName
        picture
      }
      round {
        id
      }
    }
  `

} as const

// ----------------------------------------------------------

export type Fragment = keyof typeof Fragments

// ----------------------------------------------------------

export const isFragment = (f: string): f is Fragment => f in Fragments
