<div fxLayout="column" class="dialog-container">
  <div fxLayoutAlign="space-between center">
    <span class="hide-xs" style="width: 40px"></span>
    <h1 fxFlex i18n>We’re sorry to see you go!</h1>
    <button mat-icon-button
            (click)="close()">
      <mat-icon fontIcon="icon-close"></mat-icon>
    </button>
  </div>

  <div fxFlex
       fxLayout="column"
       class="content gap-8">
    <span class="description" i18n>
      Before you cancel, please let us know the reason why you are leaving. Every bit of feedback helps.
    </span>

    <mat-radio-group (change)="onSelectOption($event)"
                     fxLayout="column"
                     class="gap-16">

      <mat-radio-button *ngFor="let option of options"
                    [value]="option.key">
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>

    <div class="comment">
      <mat-form-field *ngIf="!!selected">
        <textarea matInput
                  [formControl]="commentForm"
                  [cdkTextareaAutosize]="true"
                  [cdkAutosizeMinRows]="2">
        </textarea>
        <mat-hint i18n>Please tell us more, so we can make it happen!</mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div fxLayoutAlign="space-between center"
       class="actions" >
    <button mat-button
            (click)="close()"
            i18n>
      Dismiss
    </button>
    <button mat-button
            color="warn"
            [disabled]="!selected"
            (click)="onUnsubscribeClicked()"
            i18n>
      Unsubscribe
    </button>
  </div>
</div>
