import { inject, Injectable } from '@angular/core'

import { type Observable, throwError } from 'rxjs'

import { type EntityDomain, idToDomain } from '@libs/models'
import { type HasId, omitId, type PartialWithoutId, type PartialWithId } from '@libs/utils'
import { type ParameterMap } from '../models/request-options.model'

import { RestApi } from '@libs/backend'

// ----------------------------------------------------------

/**
 * A service for making simple requests to the REST API
 */
@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  private readonly backendService = inject(RestApi)

  // ----------------------------------------------------

  /**
   * Fetch a single object from the REST API using its ID.
   *
   * @typeParam ResponseType - Type of the object being fetched
   * @param id - ID of object to fetch from the API
   * @param params - Optional query params to add to the request
   * @returns Observable that emits the response
   *
   * @example this.restApiService
   *            .get<Company>('c_abcdefghij')
   *            .subscribe(company => console.dir(company))
   *
   * @example this.restApiService
   *            .get<Company>('c_abcdefghij', { projection: 'inlineAppointmentsAndShareholders' })
   *            .subscribe(company => console.dir(company))
   */
  get<
    ResponseType extends HasId = HasId,
  >(
    id: string,
    params?: ParameterMap,
  ): Observable<ResponseType> {
    const domain = idToDomain(id)

    return domain
      ? this.backendService.one(domain, id).get(params)
      : throwError(() => new Error(`No domain found for id "${id}"`))
  }

  // ----------------------------------------------------

  /**
   * Fetch a list of objects from the REST API for a given domain,
   * optionally passing a collection path and/or query parameters,
   * e.g. for
   *
   * @typeParam ResponseType - Type of the objects being fetched
   * @param domain - Domain of the objects to fetch e.g. `companies`
   * @param collectionOrParams - Optional sub-collection or query params
   * @param params - Optional query params
   * @returns Observable that emits an array of fetched objects
   *
   * @example this.restApiService
   *            .getList<Company>('companies')
   *            .subscribe(companies => console.dir(companies))
   *
   * @example this.restApiService
   *            .getList<ICompanyAdminExcerptData>('companies', 'search/findMyAccessCompanies', {
   *              projection: 'adminCompanyExcerpt'
   *            })
   *            .subscribe(companies => console.dir(companies))
   */
  getList<
    ResponseType extends HasId = HasId,
  >(
    domain: EntityDomain,
  ): Observable<ResponseType[]>

  getList<
    ResponseType extends HasId = HasId,
  >(
    domain: EntityDomain,
    collection: string,
    params?: ParameterMap,
  ): Observable<ResponseType[]>

  getList<
    ResponseType extends HasId = HasId,
  >(
    domain: EntityDomain,
    params: ParameterMap,
  ): Observable<ResponseType[]>

  getList<
    ResponseType extends HasId = HasId,
  >(
    domain: EntityDomain,
    collectionOrParams?: string | ParameterMap,
    params?: ParameterMap,
  ): Observable<ResponseType[]> {
    return typeof collectionOrParams === 'string'
      ? this.backendService.all(domain).getList(collectionOrParams, params)
      : this.backendService.all(domain).getList(collectionOrParams)
  }

  // ----------------------------------------------------

  /**
   * Make a POST request to the REST API for the given domain.
   *
   * @typeParam ResponseType - Type of the object being created
   * @typeParam PostData - Type of data to be sent as the POST body
   * @param domain - Domain of the object we want to create e.g. `companies`
   * @param data - Data to send as the POST body
   * @returns Observable that emits the created object
   *
   * @example this.restApiService
   *            .post<Company>('companies', {
   *              name: 'Test',
   *              jurisdiction: 'EAW',
   *            })
   *            .subscribe(company => console.dir(company))
   */
  post<
    ResponseType extends HasId = HasId,
    PostData extends object = PartialWithoutId<ResponseType>,
  >(
    domain: EntityDomain,
    data: PostData,
  ): Observable<ResponseType> {
    return this.backendService.all(domain).post(data)
  }

  // ----------------------------------------------------

  /**
   * Make a PATCH request to the REST API by specifying an object with
   * both the ID of the object to patch and the data to patch.
   *
   * @typeParam ResponseType - Type of the object being patched
   * @typeParam PatchDataWithId - Type of data to be sent as the PATCH body and an ID field
   * @param dataWithId - Data to send as the PATCH body and the `id` field
   * @returns Observable that emits the patched response
   *
   * @example this.restApiService
   *            .patch<Company>({
   *              id: 'c_abcdefghij',
   *              name: 'Test',
   *              jurisdiction: 'EAW',
   *            })
   *            .subscribe(company => console.dir(company))
   *
   * Make a PATCH request to the REST API by specifying an ID and
   * the data to patch.
   *
   * @typeParam ResponseType - Type of the object being patched
   * @typeParam PatchData - Type of data to be sent as the PATCH body
   * @param data - Data to send as the PATCH body
   * @returns Observable that emits the patched response
   *
   * @example this.restApiService
   *            .patch<Company>('c_abcdefghij', {
   *              name: 'Test',
   *              jurisdiction: 'EAW',
   *            })
   *            .subscribe(company => console.dir(company))
   */
  patch<
    ResponseType extends HasId = HasId,
    PatchDataWithId extends HasId = PartialWithId<ResponseType>,
  >(
    dataWithId: PatchDataWithId,
  ): Observable<ResponseType>

  patch<
    ResponseType extends HasId = HasId,
    PatchData extends object = Partial<ResponseType>,
  >(
    id: string,
    data: PatchData,
  ): Observable<ResponseType>

  patch<
    ResponseType extends HasId = HasId,
    PatchData extends object = Partial<ResponseType>,
  >(
    idOrDataWithId: string | HasId,
    data?: PatchData,
  ): Observable<ResponseType> {
    const id = typeof idOrDataWithId === 'string'
      ? idOrDataWithId
      : idOrDataWithId.id

    const patchData = omitId(typeof idOrDataWithId === 'string'
      ? data
      : idOrDataWithId)

    const domain = idToDomain(id)

    return domain
      ? this.backendService.one(domain, id).patch(patchData)
      : throwError(() => new Error(`No domain found for id "${id}"`))
  }

  // ----------------------------------------------------

  /**
   * Delete a single object via the REST API using its ID.
   *
   * @param id - ID of object to delete
   * @returns Observable that emits the response
   *
   * @example this.restApiService
   *            .delete('i_1234567890')
   *            .subscribe(() => this.toastService.show(`Investment deleted`))
   */
  delete(
    id: string,
  ): Observable<unknown> {
    const domain = idToDomain(id)

    return domain
      ? this.backendService.one(domain, id).delete()
      : throwError(() => new Error(`No domain found for id "${id}"`))
  }

  // ----------------------------------------------------

  /*
   * Utility methods for accessing the full BackendService when required.
   */

  all(
    entity: EntityDomain,
  ) {
    return this.backendService.all(entity)
  }

  // ----------------------------------------------------

  one(
    entity: EntityDomain,
    id?: string,
  ) {
    return this.backendService.one(entity, id)
  }

  // ----------------------------------------------------

  httpClient() {
    return this.backendService.httpClient
  }

  // ----------------------------------------------------

  requestOptions() {
    return this.backendService.requestOptions
  }

}
