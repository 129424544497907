import { IModelData } from './model.model'
import { IUserData } from './user.model'

// ----------------------------------------------------------

export enum AccessRole {
  StaffRead = 'STAFF_READ',
  StaffWrite = 'STAFF_WRITE'
}

// ----------------------------------------------------------

export interface IAccessData extends IModelData {
  accessRoles: AccessRole[]
}

// ----------------------------------------------------------

// For the `adminCompanyExcerpt` projection returned by the
// API when searching for companies from the admin page.
export interface IAccessWithUserData extends IAccessData {
  inserted: string
  user: IUserData
}
