import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Store } from '@ngrx/store'

import { Card, CardWithExpiry } from '../models/cards'

import { LoadCards, AddNewCard, SetDefaultCard, DeleteCard } from './payments.actions'
import { CardService } from '../services/card.service'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class PaymentsFacade {

  cardsLoading$: Observable<boolean> = this.cardService.loading$
  cardsLoaded$: Observable<boolean> = this.cardService.loaded$

  cards$: Observable<Card[]> = this.cardService.entities$

  cardsWithExpiry$: Observable<CardWithExpiry[]> = this.cards$.pipe(
    map(cards => cards.map(card => ({ ...card, expiry: new Date(card.expYear, card.expMonth - 1) })))
  )

  defaultCard$: Observable<Card[]> = this.cardsWithExpiry$.pipe(
    map(cards => cards.filter(card => card.default))
  )

  // ----------------------------------------------------

  loadCards(companyId: string) {
    this.store.dispatch(LoadCards({ companyId }))
  }

  // ----------------------------------------------------

  addNewCard(companyId: string, label: string, cardElement: stripe.elements.Element) {
    this.store.dispatch(AddNewCard({ companyId, label, cardElement }))
  }

  // ----------------------------------------------------

  setDefaultCard(companyId: string, cardId: string) {
    this.store.dispatch(SetDefaultCard({ companyId, cardId }))
  }

  // ----------------------------------------------------

  deleteCard(companyId: string, card: CardWithExpiry) {
    this.store.dispatch(DeleteCard({ companyId, card }))
  }

  // ----------------------------------------------------

  constructor(
    private store: Store<any>,
    private cardService: CardService
  ) {}
}
