import type { DocumentTypeId } from './document-types.model'
import type { IEventData } from './investment-event.model'
import type { IUserExcerptData } from './user.model'

// ------------------------------------------------------------

export enum EmploymentType {
  Employee = 'EMPLOYEE',
  Founder = 'FOUNDER',
  Consultant = 'CONSULTANT',
  Intern = 'INTERN'
}

// ----------------------------------------------------------

export const EMPLOYMENT_TYPE_LABELS: Record<EmploymentType, string> = {
  [ EmploymentType.Consultant ]: $localize`Contractor`,
  [ EmploymentType.Employee ]: $localize`Employee`,
  [ EmploymentType.Founder ]: $localize`Founder`,
  [ EmploymentType.Intern ]: $localize`Inter`,
}

export const EMPLOYMENT_TYPE_THEME: Record<EmploymentType, string> = {
  [ EmploymentType.Consultant ]: 'green',
  [ EmploymentType.Employee ]: 'purple',
  [ EmploymentType.Founder ]: 'yellow',
  [ EmploymentType.Intern ]: 'violet',
}

// ----------------------------------------------------------

const employmentTypeDocuments: Record<EmploymentType, DocumentTypeId[]> = {
  [ EmploymentType.Employee ]: [ 'EMPA' ],
  [ EmploymentType.Founder ]: [ 'FSRV' ],
  [ EmploymentType.Consultant ]: [ 'COAG' ],
  [ EmploymentType.Intern ]: [],
}

export function getEmploymentTypeForDocument(
  docType: DocumentTypeId,
): EmploymentType {
  const keys = Object.keys(employmentTypeDocuments) as (keyof typeof employmentTypeDocuments)[]

  return keys.find(key => employmentTypeDocuments[ key ].includes(docType))
}

// ----------------------------------------------------------

export interface IEmploymentStartEventData extends IEventData {
  employee: IUserExcerptData
  jobTitle: string
  salary: number
}

// ----------------------------------------------------------

export interface IEmploymentEventData extends IEventData {
  employment: IEmploymentStartEventData
}

// ----------------------------------------------------------

export interface IEmploymentVariationEventData extends IEmploymentEventData {
  jobTitle: string | null
  salary: number | null
  comment: string | null
}

// ----------------------------------------------------------

export interface IEmploymentBonusPaymentEventData extends IEmploymentEventData {
  amount: number
}
