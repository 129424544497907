import { EntityType } from '../../models/model.model'
import { Document } from './document'
import { Signature } from './signature'
import { Company } from '../company'
import { User } from '../user'
import { AppointmentRole } from '../appointment-roles'
import { SignedAsRole } from '../../models'

// ------------------------------------------------------------

export class Signatory {
  type: EntityType

  company?: Company
  companyRole?: SignedAsRole

  user?: User

  witnessSignature: Signature | null = null

  // ----------------------------------------------------

  public static getCompanySignatory(
    document: Document,
    company: Company,
    companyRole: SignedAsRole = SignedAsRole.Signatory,
    requiresWitness = false
  ): Signatory {
    const signatory = new Signatory(document, company, requiresWitness)

    signatory.company = company
    signatory.companyRole = companyRole

    return signatory
  }

  // ----------------------------------------------------

  public static getUserSignatory(
    document: Document,
    user: User,
    requiresWitness = false
  ): Signatory {
    const signatory = new Signatory(document, user, requiresWitness)

    signatory.user = user

    return signatory
  }

  // ----------------------------------------------------

  constructor(
    public readonly document: Document,
    public readonly signatory: Company | User,
    public requiresWitness = false,
    public signature?: Signature
  ) {
    this.type = signatory.entityType
  }

  // ----------------------------------------------------

  get name() {
    return this.signatory.name
  }

  // ----------------------------------------------------

  get isSigned(): boolean {
    return !!this.signature
  }

  // ----------------------------------------------------

  get isWitnessed(): boolean {
    return this.isSigned && this.signature.isWitnessed
  }

  // ----------------------------------------------------

  // True if we require this signature to have a witness and we
  // have a valid signature but no witness signature yet
  get needsWitness(): boolean {
    return this.requiresWitness && this.isSigned && !this.signature.isWitnessed
  }

  // ----------------------------------------------------

  // True if signed, and if required, witnessed as well
  get isCompleted(): boolean {
    return this.requiresWitness
      ? this.isWitnessed
      : this.isSigned
  }

  // ----------------------------------------------------

  get signedDate() {
    return this.signature && this.signature.signed
  }

  // ----------------------------------------------------

  hasSigned(user: User): boolean {
    return this.isSigned && this.signature.user.id === user.id
  }

  // ----------------------------------------------------

  hasPermissionToSign(user: User): boolean {
    if (this.type === 'user') {
      return this.signatory.id === user.id
    }

    const appointment = user.getAppointmentForCompany(this.signatory.id)
    const role = this.companyRole
      ? (this.companyRole as unknown) as AppointmentRole
      : AppointmentRole.Signatory

    return !!appointment && appointment.hasRole(role)
  }

  // ----------------------------------------------------

  canSign(user: User): boolean {
    if (this.isSigned) {
      return false
    }
    return this.hasPermissionToSign(user)
  }

}
