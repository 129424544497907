import { NgModule } from '@angular/core'

import {
  BreakPointRegistry,
  FlexAlignStyleBuilder,
  FlexFillStyleBuilder,
  FlexStyleBuilder,
  LayoutAlignStyleBuilder,
  LayoutGapStyleBuilder,
  LayoutStyleBuilder,
  MediaMarshaller,
  GridRowsStyleBuilder,
  ɵMatchMedia as MatchMedia,
  GridColumnsStyleBuilder,
  GridGapStyleBuilder,
  PrintHook,
  StylesheetMap,
  StyleUtils
} from '@ngbracket/ngx-layout'

import { AngularMaterialModule } from './angular-material.module'

// ----------------------------------------------------------

@NgModule({
  imports: [
    AngularMaterialModule
  ],
  exports: [
    AngularMaterialModule
  ],
  providers: [
    BreakPointRegistry,
    FlexAlignStyleBuilder,
    FlexFillStyleBuilder,
    FlexStyleBuilder,
    GridColumnsStyleBuilder,
    GridGapStyleBuilder,
    GridRowsStyleBuilder,
    LayoutAlignStyleBuilder,
    LayoutGapStyleBuilder,
    LayoutStyleBuilder,
    MatchMedia,
    MediaMarshaller,
    PrintHook,
    StylesheetMap,
    StyleUtils
  ]
})
export class AngularMaterialTestModule {}
