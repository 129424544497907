import { Pipe, PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'slNone'
})
export class NonePipe implements PipeTransform {

  transform(
    value: unknown
  ): unknown {
    return value || $localize`None`
  }

}
