import { uniq, uniqBy, without } from 'ramda'

import { Collection } from './collection'
import { ApiFieldSpec, INamed, OnCompanyModel } from './model'

import { AppointmentRole } from './appointment-roles'
import { User } from './user'
import { Company } from './company'

// ------------------------------------------------------------

export class Appointment extends OnCompanyModel implements INamed {
  readonly domain = 'appointments'

  user: User
  position: string
  roles: AppointmentRole[]

  // ----------------------------------------------------

  constructor({
    user = null,
    position = '',
    roles = [],
    ...data
  }) {
    super({ user, position, roles, ...data })
  }

  // ----------------------------------------------------

  get name() {
    return this.user.name
  }

  // ----------------------------------------------------

  hasRole(role: AppointmentRole): boolean {
    // TODO: Fix this hack for admin appointments not including read.
    return this.roles.includes(role)
      || role === AppointmentRole.Read && this.roles.includes(AppointmentRole.Admin)
  }

  // ----------------------------------------------------

  setRole(role: AppointmentRole, value: boolean) {
    if (value) {
      this.roles = uniq([ ...this.roles, role ])
    } else {
      this.roles = without([ role ], this.roles)
    }
  }

  // ----------------------------------------------------

  get read(): boolean {
    // Hack to ensure that company admins always have full access to the company.
    return this.hasRole(AppointmentRole.Read) || this.hasRole(AppointmentRole.Admin)
  }

  set read(value: boolean) {
    this.setRole(AppointmentRole.Read, value)
  }

  // ----------------------------------------------------

  get admin(): boolean {
    return this.hasRole(AppointmentRole.Admin)
  }

  set admin(value: boolean) {
    this.setRole(AppointmentRole.Admin, value)
  }

  // ----------------------------------------------------

  get signatory(): boolean {
    return this.hasRole(AppointmentRole.Signatory)
  }

  set signatory(value: boolean) {
    this.setRole(AppointmentRole.Signatory, value)
  }

  // ----------------------------------------------------

  get director(): boolean {
    return this.hasRole(AppointmentRole.Director)
  }

  set director(value: boolean) {
    this.setRole(AppointmentRole.Director, value)
  }

  // ----------------------------------------------------

  get founder(): boolean {
    return this.hasRole(AppointmentRole.Founder)
  }

  set founder(value: boolean) {
    this.setRole(AppointmentRole.Founder, value)
  }

  // ----------------------------------------------------

  get foundingEmployee(): boolean {
    return this.hasRole(AppointmentRole.FoundingEmployee)
  }

  set foundingEmployee(value: boolean) {
    this.setRole(AppointmentRole.FoundingEmployee, value)
  }

  // ----------------------------------------------------

  get familyFounder(): boolean {
    return this.hasRole(AppointmentRole.FoundingFamily)
  }

  set familyFounder(value: boolean) {
    this.setRole(AppointmentRole.FoundingFamily, value)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'user', include: 'create' },
      'position',
      'roles'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()
    this.user.appointments.add(this)
  }

  override detach() {
    super.detach()
    this.user.appointments.remove(this)
  }

  // ----------------------------------------------------

  get tier(): number {
    if (this.admin) {
      return 0
    } else if (this.director) {
      return 1
    } else {
      return 2
    }
  }

  // ----------------------------------------------------

  isUser(user: User): boolean {
    return this.user.id === user.id
  }

  // ----------------------------------------------------

  override toString() {
    return `Appointment(${this.id}, company="${this.company.name}", user="${this.user.name}")`
  }
}

// ------------------------------------------------------------

export class AppointmentCollection extends Collection<Appointment> {
  // Get a collection of appointments in company name order
  static getAppointmentCollectionForUser(): AppointmentCollection {
    return new AppointmentCollection('company.name user.name')
  }

  // ----------------------------------------------------

  // Get a collection of appointments in user name order
  static getAppointmentCollectionForCompany() {
    return new AppointmentCollection('user.name company.name')
  }

  // ----------------------------------------------------

  constructor(sortBy = 'user.name') {
    super(sortBy)
  }

  // ----------------------------------------------------

  getUsers(): User[] {
    return uniqBy(u => u.id, this.map(a => a.user))
  }

  // ----------------------------------------------------

  getCompanies(): Company[] {
    return uniqBy(u => u.id, this.map(a => a.company))
  }

  // ----------------------------------------------------

  getAppointmentsForCompany({ id }): Appointment[] {
    return this.filter(appointment => appointment.company.id === id)
  }

  // ----------------------------------------------------

  getAppointmentsByRole(role: AppointmentRole): Appointment[] {
    return this.filter(appointment => appointment.hasRole(role))
  }

  // ----------------------------------------------------

  findAppointmentForUser({ id }): Appointment | undefined {
    return this.find(appointment => appointment.user.id === id)
  }

  // ----------------------------------------------------

  findAppointmentForCompany({ id }): Appointment | undefined {
    return this.find(appointment => appointment.company.id === id)
  }
}
