import { Model } from '../model'
import type { ProductId, PurchaseStage } from './product'

import type { IUserExcerptData } from '../../models/user.model'
import type { IModelData, ITimestamped } from '../../models/model.model'

// ----------------------------------------------------------

const LEGACY_TIMESTAMP = new Date('2018-03-08T15:42:22')

// ----------------------------------------------------------

export interface ITransactionData extends IModelData, ITimestamped {
  product: ProductId
  stage: PurchaseStage
  amount: number
  description: string
  entity: string
  paymentProviderId: string
  user: IUserExcerptData
  system: boolean
}

// ------------------------------------------------------------

export interface IBaseEntityTransactionRequestData {
  kind: string
  price?: number
  paymentMethodId?: string
}

export interface IEntityTransactionRequestData extends IBaseEntityTransactionRequestData {
  kind: 'entity'
  entity: string
  stage: PurchaseStage
}

export interface IStandaloneTransactionRequestData extends IBaseEntityTransactionRequestData {
  kind: 'product'
  product: ProductId
  stage: PurchaseStage
}

export interface IBundleTransactionRequestData extends IBaseEntityTransactionRequestData {
  kind: 'bundle'
  product?: ProductId
  entity?: string
  bundle?: string
  stage?: PurchaseStage
  description: string
}

export type TransactionRequestData = IEntityTransactionRequestData | IStandaloneTransactionRequestData | IBundleTransactionRequestData

// ------------------------------------------------------------

export type TransactionStage =
  | 'NONE'
  | PurchaseStage

// ------------------------------------------------------------

export enum TransactionStages {
  None = 'NONE',
  Deposit = 'DEPOSIT',
  Agreement = 'AGREEMENT',
  Balance = 'BALANCE'
}

// ------------------------------------------------------------

export class Transaction extends Model {
  product: ProductId
  stage: PurchaseStage
  amount: number
  description: string
  entity: string
  paymentProviderId: string
  user: IUserExcerptData
  system: boolean

  readonly domain: string = 'transactions'

  // ----------------------------------------------------

  constructor({
    amount = 0,
    description = '',
    system = true,
    ...data
  }) {
    super({ amount, description, system, ...data })
  }

  // ----------------------------------------------------

  get legacy() {
    return this.inserted <= LEGACY_TIMESTAMP
  }
}
