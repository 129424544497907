import { ChangeDetectionStrategy, Component, Input, type OnChanges, TemplateRef } from '@angular/core'

import { Region, type TabLink } from '@libs/models'
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../route-animation'

// ----------------------------------------------------------

@Component({
  selector: 'sl-subnav-bar',
  templateUrl: './subnav-bar.component.html',
  styleUrls: [ './subnav-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubnavBarComponent implements OnChanges {
  @Input() links: TabLink[] = []
  @Input() isAdmin = false
  @Input() region: Region
  @Input() commentsToggleContainerTemplate?: TemplateRef<unknown>

  accessibleLinks: TabLink[] = []

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS

  // ----------------------------------------------------

  ngOnChanges(changes) {
    if (changes.links || changes.isAdmin || changes.region) {
      this.accessibleLinks = this.links.filter(({ adminOnly, allowedRegions }) =>
        (this.isAdmin || !adminOnly) && (!Array.isArray(allowedRegions) || allowedRegions.includes(this.region))
      )
    }
  }

}
