import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import type { ParamMap } from '@angular/router'

import { type Observable, throwError, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import { pick } from 'ramda'

import { parse, stringify } from 'qs'
import jwt_decode from 'jwt-decode'

import { AuthStorageKeys } from '../auth.constants'
import type { AuthResult, UserInfo } from '../models/auth.model'
import type { WhoAmIData } from '../models/whoami-data.model'

import { EnvironmentConfig, GlobalObject, IEnvironmentConfig } from '@libs/shared/tokens'
import { LocalStorageService } from '@libs/storage'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private buildAuthUrl(
    path: string,
    paramNames: string[] = [],
    params = {},
  ): string {
    params = {
      ...pick(paramNames, this.environment.fusionAuth),
      ...params
    }

    const query = stringify(params, { addQueryPrefix: true })

    return `${this.environment.fusionAuth.domain}/${path}${query}`
  }

  // ----------------------------------------------------

  private buildRedirectUrl(
    target: string,
  ): string {
    const { origin, pathname } = this.global.location

    const path = pathname.startsWith('/' + this.locale)
      ? `/${this.locale}/${target}`
      : `/${target}`

    return origin + path
  }

  // ----------------------------------------------------

  login() {
    this.clearAllAuthData()

    const redirect_uri = this.buildRedirectUrl('callback')

    const url = this.buildAuthUrl(
      'oauth2/authorize',
      [ 'client_id', 'tenantId', 'scope', 'response_type' ],
      { redirect_uri }
    )

    this.global.location.href = url
  }

  // ----------------------------------------------------

  signUp() {
    this.clearAllAuthData()

    const redirect_uri = this.buildRedirectUrl('callback')

    const url = this.buildAuthUrl(
      'oauth2/register',
      [ 'client_id', 'tenantId', 'scope', 'response_type' ],
      { redirect_uri }
    )

    this.global.location.href = url
  }

  // ----------------------------------------------------

  logout() {
    this.clearAllAuthData()

    const post_logout_redirect_uri = this.buildRedirectUrl('login')

    const url = this.buildAuthUrl(
      'oauth2/logout',
      [ 'client_id', 'tenantId' ],
      { post_logout_redirect_uri }
    )

    this.global.location.href = url
  }

  // ----------------------------------------------------

  parseHash(): Observable<AuthResult> {
    const parsed = parse(this.global.location.hash.substring(1)) as Record<string, string>

    if (!parsed[ 'access_token' ] && !parsed[ 'id_token' ]) {
      return throwError(() => ({ error: 'access_denied' }))
    }

    return of({
      accessToken: parsed.access_token,
      idToken: parsed.id_token,
    })
  }

  // ----------------------------------------------------

  getUserInfo(): Observable<UserInfo> {
    const accessToken = this.getAccessToken()

    const url = this.buildAuthUrl(
      'oauth2/userinfo'
    )

    return this.httpClient.get<UserInfo>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      observe: 'response',
      responseType: 'json'
    }).pipe(
      mergeMap(response => {
        return response.status === 200
          ? of(response.body)
          : throwError(() => response.status)
      })
    )
  }

  // ----------------------------------------------------

  setSession(
    authResult: AuthResult
  ) {
    const { exp } = jwt_decode<{ exp: number }>(authResult.idToken)

    this.storage.setItem(AuthStorageKeys.ACCESS_TOKEN, authResult.accessToken)
    this.storage.setItem(AuthStorageKeys.ID_TOKEN, authResult.idToken)
    this.storage.setObject(AuthStorageKeys.EXPIRES_AT, exp * 1000)
  }

  // ----------------------------------------------------

  storeUrlParams(
    params: ParamMap,
    ...keys: string[]
  ) {
    // console.info(`AuthService.storeUrlParams(): params = %o`, params)
    keys.forEach(key => {
      if (params.has(key)) {
        this.storage.setItem(key, params.get(key))
      }
    })
  }

  // ----------------------------------------------------

  storeRedirectUrl(
    redirectUrl: string
  ) {
    if (!this.storage.hasItem(AuthStorageKeys.REDIRECT_URL)) {
      if (!redirectUrl.match(/\/(login|signup|callback|logout)$/i)) {
        this.storage.setItem(AuthStorageKeys.REDIRECT_URL, redirectUrl)
      }
    }
  }

  // ----------------------------------------------------

  storeWhoAmI(
    whoAmI?: WhoAmIData
  ) {
    if (whoAmI) {
      this.storage.setObject(AuthStorageKeys.WHO_AM_I, whoAmI)
      this.storeUserId(whoAmI.id)
    } else {
      this.storage.removeItem(AuthStorageKeys.WHO_AM_I)
      this.storeUserId()
    }
  }

  // ----------------------------------------------------

  storeUserId(
    userId?: string
  ) {
    if (userId) {
      this.storage.setItem(AuthStorageKeys.USER_ID, userId)
    } else {
      this.storage.removeItem(AuthStorageKeys.USER_ID)
    }
  }

  // ----------------------------------------------------

  getAuthToken(): string | undefined {
    return this.storage.getItem(AuthStorageKeys.ID_TOKEN)
  }

  getAccessToken(): string | undefined {
    return this.storage.getItem(AuthStorageKeys.ACCESS_TOKEN)
  }

  // ----------------------------------------------------

  clearAllAuthData() {
    // console.info('AuthService.clearStoredData')
    this.storage.removeItems(
      AuthStorageKeys.ACCESS_TOKEN,
      AuthStorageKeys.ID_TOKEN,
      AuthStorageKeys.EXPIRES_AT,
      AuthStorageKeys.PROFILE,
      AuthStorageKeys.WHO_AM_I,
      AuthStorageKeys.USER_ID,
    )
  }

  // ----------------------------------------------------

  isLoggedIn(): boolean {
    if (!this.storage.hasItem(AuthStorageKeys.ID_TOKEN)) {
      return false
    }

    // Check if current date is before token expiration and user is signed in locally
    const expiresAt = this.storage.getObject<number>(
      AuthStorageKeys.EXPIRES_AT,
      0
    )

    return expiresAt > new Date().getTime()
  }

  // ----------------------------------------------------

  getWhoAmI(): WhoAmIData {
    return this.storage.getObject<WhoAmIData>(AuthStorageKeys.WHO_AM_I)
  }

  // ----------------------------------------------------

  getUserId(): string {
    return this.storage.getItem(AuthStorageKeys.USER_ID)
  }

  // ----------------------------------------------------

  constructor(
    private httpClient: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(GlobalObject) private global: Window,
    @Inject(EnvironmentConfig) private environment: IEnvironmentConfig,
    private storage: LocalStorageService,
  ) {}

}
