import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core'

@Component({
  selector: 'sl-arrow-selector',
  templateUrl: './arrow-selector.component.html',
  styleUrls: [ './arrow-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowSelectorComponent implements OnChanges {
  @Input() currentStep
  @Input() steps
  @Output() stepChanged = new EventEmitter<number>()

  selectedIndex = 0

  ngOnChanges(): void {
    this.selectedIndex = this.steps.findIndex(
      step => step.value === this.currentStep
    )
  }

  stepClicked(step, index: number): void {
    if (step.disabled || this.selectedIndex === index) {
      return
    }

    this.selectedIndex = index
    this.stepChanged.emit(this.steps[ index ].value)
  }
}
