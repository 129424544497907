import { isoToday } from '@libs/utils'

import type { EntityType } from '../../models'
import { SignedAsRole } from '../../models'

import type { ApiFieldSpec } from '../model'
import { Model } from '../model'
import type { Company } from '../company'
import type { Document } from './document'
import type { Signatory } from './signatory'
import type { User } from '../user'
import { Witness } from './witness'
import type { Address } from '../address'

// ------------------------------------------------------------

export interface ISignatureFields {
  id?: string
  name?: string
  signature?: string
  signed?: string
  signedAs?: SignedAsRole
}

// ------------------------------------------------------------

export interface IWitnessFields {
  id?: string
  name?: string
  occupation?: string
  address?: Address
  signature?: string
  signed?: string
  signedAs?: SignedAsRole
}

// ------------------------------------------------------------

export interface ISigningStatus {
  strategy: SigningStrategy
  votingShareCount?: number
  signedVotingShareCount?: number
}

export enum SigningStrategy {
  All = 'ALL',
  None = 'NONE',
  Voting = 'VOTING'
}

// ------------------------------------------------------------

export class Signature extends Model {
  readonly domain = 'signatures'

  name: string
  signature: string
  signed: string

  company: Company | null
  signedAs: SignedAsRole | null

  witness?: Witness

  // ----------------------------------------------------

  constructor(
    public readonly signatory: Signatory,
    public readonly user: User,
    {
      id,
      name,
      signature,
      signed,
      // company,
      signedAs
    }: ISignatureFields
  ) {
    super({
      id,
      name: name || user.name,
      signature,
      signed: signed || isoToday(),
      signedAs: signedAs || SignedAsRole.Signatory
    })

    if (signatory.type === 'company') {
      this.company = signatory.company
      this.signedAs = signedAs || signatory.companyRole
    } else {
      this.company = null
      this.signedAs = null
    }

    if (this.signatory.requiresWitness) {
      this.witness = new Witness(this, {})
    }
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'document', include: 'create' },
      { key: 'user', include: 'create' },
      { key: 'name', include: 'create' },
      { key: 'signature', include: 'create' },
      { key: 'signed', include: 'create' }
    ]
  }

  // ----------------------------------------------------

  override customisePayload(payload, mode) {
    payload = super.customisePayload(payload, mode)

    if (mode === 'create') {
      if (this.type === 'company') {
        payload.company = this.signatory.company
        payload.signedAs = this.signedAs
      } else {
        payload.company = null
      }
    }

    return payload
  }

  // ----------------------------------------------------

  override async afterCreate(api, responseData) {
    if (this.signatory.requiresWitness) {
      await this.witness.save(api)
    }
  }

  // ----------------------------------------------------

  override attach() {
    this.signatory.signature = this
  }

  override detach() {
    this.signatory.signature = null
  }

  // ----------------------------------------------------

  get document(): Document {
    return this.signatory.document
  }

  // ----------------------------------------------------

  get type(): EntityType {
    return this.signatory.type
  }

  // ----------------------------------------------------

  get isWitnessed(): boolean {
    return this.witness
      ? this.witness.isSigned
      : false
  }
}
