import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnChanges } from '@angular/core'

import { CardWithExpiry, CardBrands } from '../../models/cards'

import { PaymentsFacade } from '../../+state/payments.facade'

// ----------------------------------------------------------

@Component({
  selector: 'sl-stripe-cards',
  templateUrl: './stripe-cards.component.html',
  styleUrls: [ './stripe-cards.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StripeCardsComponent implements OnChanges {
  @Input() cards: CardWithExpiry[] = []
  @Input() selectable = false

  @Output() cardSelected = new EventEmitter<CardWithExpiry>()

  CardBrands = CardBrands

  sortedCards: CardWithExpiry[]

  selected: CardWithExpiry | null = null

  // ----------------------------------------------------

  constructor(
    private paymentsFacade: PaymentsFacade
  ) {}

  // ----------------------------------------------------

  ngOnChanges(changes) {
    if (changes.cards) {
      this.sortedCards = this.cards.sort((a, b) => a.default ? -1 : 0)
    }

    if (this.selectable && changes.cards) {
      const defaultCard = this.sortedCards.find(card => card.default)
      this.cardClicked(defaultCard)
    }
  }

  // ----------------------------------------------------

  getCardImage(card: CardWithExpiry) {
    const brand = CardBrands[ card.brand ]
    if (brand) {
      return brand.image
    }
    return null
  }

  // ----------------------------------------------------

  getCardBrand(card: CardWithExpiry) {
    const brand = CardBrands[ card.brand ]
    if (brand) {
      return brand.brand
    }
    return card.brand
  }

  // ----------------------------------------------------

  cardClicked(card: CardWithExpiry) {
    this.selected = card

    this.cardSelected.emit(card)
  }

  // ----------------------------------------------------

  removeCard(card: CardWithExpiry) {
    this.paymentsFacade.deleteCard(card.companyId, card)
  }

  // ----------------------------------------------------

  setDefaultCard(card: CardWithExpiry) {
    this.paymentsFacade.setDefaultCard(card.companyId, card.id)
  }
}
