import { Injectable, Inject } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'

import { omit } from 'ramda'

import { firstValueFrom, ReplaySubject } from 'rxjs'
import { take } from 'rxjs/operators'

import {
  AccountRole,
  type Company,
  type IUploadEntityPictureResponse,
  type IUserData,
  type User,
  type UtmParams,
  type IUserPatchData,
  Sex
} from '@libs/models'

import { AuthStorageKeys } from '@app/auth/auth.constants'
import type { WhoAmIData } from '@app/auth/models/whoami-data.model'

import { Api, BackendService, FileTransferApi, RestApiService } from '@libs/backend'
import { LocalStorageService } from '@libs/storage'

import { GlobalsService } from '@app/core/services/globals/globals.service'
import { UsersFacade } from '../+state/users.facade'
import { UsersQuery } from './graphql/users-query'

// ------------------------------------------------------------

const UTM_PARAMS = {
  MEDIUM: 'utm_medium',
  SOURCE: 'utm_source',
  CAMPAIGN: 'utm_campaign'
}

// ----------------------------------------------------------

const omitIdUidAndAddress = omit([
  'id',
  'address'
])

// ----------------------------------------------------------

export interface UpdateUserSignatureResponse {
  signature: string
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _currentUser: User

  public get currentUser(): User {
    return this._currentUser
  }

  public set currentUser(value: User) {
    if (value !== this._currentUser) {
      this._currentUser = value
      this.currentUserSubject$.next(value)
    }
  }

  // ----------------------------------------------------

  private currentUserSubject$ = new ReplaySubject<User>(1)
  currentUser$ = this.currentUserSubject$.asObservable()

  // ----------------------------------------------------

  private getSavedToken(key: string, defaultValue: string | null = null): string {
    const value = this.storage.getItem(key)

    if (value) {
      this.storage.removeItem(key)
      return value
    }

    return defaultValue
  }

  // ----------------------------------------------------

  async updateUserUtmParams(user: User) {
    const { utmMedium, utmSource, utmCampaign } = this.getUtmParams()
    if (utmMedium || utmSource || utmCampaign) {
      const additionalInfo: User['additionalInfo'] = {
        ...user.additionalInfo,
        utmMedium,
        utmSource,
        utmCampaign,
      }
      await this.patchUser(user, { additionalInfo })
    }
  }

  // ----------------------------------------------------

  getUtmParams(): UtmParams {
    const utmMedium = this.getSavedToken(UTM_PARAMS.MEDIUM)
    const utmSource = this.getSavedToken(UTM_PARAMS.SOURCE)
    const utmCampaign = this.getSavedToken(UTM_PARAMS.CAMPAIGN)

    return {
      utmMedium,
      utmSource,
      utmCampaign
    }
  }

  // ----------------------------------------------------

  async toggleUserPlatformAdmin() {
    if (!this.currentUser) {
      return
    }

    const whoAmI = this.storage.getObject<WhoAmIData>(AuthStorageKeys.WHO_AM_I, {} as WhoAmIData)

    if (this.currentUser.isPlatformAdmin) {
      await firstValueFrom(this.api.all('godProperties').delete())
      whoAmI.role = AccountRole.User
      this.currentUser.role = AccountRole.User
    } else {
      await firstValueFrom(this.api.all('godProperties').post())
      whoAmI.role = AccountRole.Admin
      this.currentUser.role = AccountRole.Admin
    }

    this.storage.setObject(AuthStorageKeys.WHO_AM_I, whoAmI)
    this.storage.setItem(AuthStorageKeys.USER_ID, whoAmI?.id || '')

    // Make sure we reevaluate company access
    this.currentUserSubject$.next(this.currentUser)
  }

  // ----------------------------------------------------

  getCurrentUser(): Promise<User> {
    return firstValueFrom(this.currentUser$
      .pipe(take(1)))
  }

  // ----------------------------------------------------

  async getUser(id: string): Promise<User> {
    let user = this.globalsService.users.get(id)

    if (!(user && user.isFullModel)) {
      const usersData = await firstValueFrom(this.usersQuery.getUsers([ id ]).pipe(take(1)))

      if (usersData[ 0 ]) {
        user = this.globalsService.buildUser(usersData[ 0 ])
      } else {
        throw new Error('User not found')
      }
    }

    return user
  }

  // ----------------------------------------------------

  async getUsers(ids: string[]): Promise<User[]> {
    // remove duplicates
    const _ids = new Set(ids)

    const users: User[] = []
    const idsToQuery: string[] = []

    _ids.forEach(id => {
      const user = this.globalsService.users.get(id)
      if (!(user && user.isFullModel)) {
        idsToQuery.push(id)
      } else {
        users.push(user)
      }
    })

    if (idsToQuery.length > 0) {
      const usersData = await firstValueFrom(this.usersQuery.getUsers(idsToQuery).pipe(take(1)))
      usersData.forEach(u => {
        users.push(this.globalsService.buildUser(u))
      })
    }

    return users
  }

  // ----------------------------------------------------

  async findUserByEmail(email: string): Promise<User | null> {
    if (!email) {
      return null
    }

    const allUsers = Array.from(this.globalsService.users.values())

    const user = allUsers.find(u => u.email === email)

    if (user) {
      return user
    }

    try {
      const userData = await firstValueFrom(this.restApi
        .all('users')
        .get<IUserData>('search/findByEmailIgnoreCase', { email })
      )

      return this.globalsService.buildUser(userData)
    } catch (ex) {
      return null
    }
  }

  // ----------------------------------------------------

  async createUser(
    data: Partial<Omit<IUserData, 'id'>>
  ): Promise<User> {
    try {
      const userData = await firstValueFrom(this.restApi.post<IUserData>('users', {
        sex: Sex.NotKnown,
        ...data
      }))

      return this.globalsService.buildUser(userData)
    } catch (ex) {
      console.warn(ex)
      return null
    }
  }

  // ----------------------------------------------------

  async patchUser(
    user: User,
    data: IUserPatchData
  ): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.restApi.patch<IUserData, IUserPatchData>(user.id, data))

      Object.assign(user, omitIdUidAndAddress(response))

      if (response.firstName || response.lastName) {
        user.nameChanged()
      }

      if (data.address) {
        user.updateAddress(response.address)
      }

      this.usersFacade.userUpdated(user.id, omitIdUidAndAddress(data))

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  async sendInvitationEmail(company: Company, user: User): Promise<boolean> {
    try {
      await firstValueFrom(this.api
        .one('companies', company.id)
        .post({}, 'emailInvite', { userId: user.id }))

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  async updateUserPicture(user: User, dataUri: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.fileTransferApi
        .one('users', user.id)
        .upload<IUploadEntityPictureResponse>('picture.png', dataUri, 'uploadPicture'))

      user.picture = response.picture
      user.pictureChanged()

      this.usersFacade.userUpdated(user.id, { picture: response.picture })

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  async updateUserSignature(user: User, dataUri: string): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.fileTransferApi
        .one('users', user.id)
        .upload<UpdateUserSignatureResponse>('signature.png', dataUri, 'uploadSignature'))

      user.signature = response.signature

      this.usersFacade.userUpdated(user.id, { signature: response.signature })

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  async mergeUsers(sourceUser: User, destUser: User): Promise<HttpErrorResponse | Error | undefined> {
    if (sourceUser.id === destUser.id) {
      return
    }

    try {
      await firstValueFrom(this.api
        .one('users', sourceUser.id)
        .post({}, 'mergeInto', { userId: destUser.id }))

      location.reload()
    } catch (ex) {
      if (ex instanceof HttpErrorResponse || ex instanceof Error) {
        return ex
      }
    }
  }

  // ----------------------------------------------------

  async markVideoAsSeen(
    user: User,
    videoId: string
  ): Promise<boolean> {
    try {
      if (user.hasSeenVideo(videoId)) {
        return true
      }

      const { additionalInfo } = user
      additionalInfo.seenVideos = [ ...additionalInfo.seenVideos || [], videoId ]

      const response = await firstValueFrom(this.restApi.patch<IUserData>(user.id, { additionalInfo }))

      Object.assign(user, omitIdUidAndAddress(response))

      this.usersFacade.userUpdated(user.id, { additionalInfo })

      return true
    } catch (ex) {
      return false
    }
  }

  // ----------------------------------------------------

  calculateProfileCompletion(user: User): number {
    let completion = 0

    if (user.firstName && user.lastName) {
      completion += 20
    }

    if (user.address && user.address.postcode) {
      completion += 20
    }

    if (user.sex > 0) {
      completion += 20
    }

    if (user.picture) {
      completion += 20
    }

    if (user.signature) {
      completion += 20
    }

    return completion
  }

  // ----------------------------------------------------

  constructor(
    private readonly restApi: RestApiService,
    @Inject(Api) private api: BackendService,
    @Inject(FileTransferApi) private fileTransferApi: BackendService,
    private globalsService: GlobalsService,
    private storage: LocalStorageService,
    private usersFacade: UsersFacade,
    private usersQuery: UsersQuery
  ) {}
}
