<sl-sidenav-stepper confirmLabel="Confirm"
                    i18n-confirmLabel
                    [showBundlePricing]="true"
                    (confirmed)="confirm()">
  <sl-sidenav-step [label]="label"
                   [stepControl]="bundle">
    <div class="content-wrapper p-16">
      <div class="mb-8">
        <span class="supporting-text">
          {{ description }}
        </span>
      </div>

      <div class="bb mb-20 pv-16">
        <mat-radio-group [formControl]="bundle"
                         fxLayout="column"
                         class="gap-16">
          <ng-container *ngFor="let bundle of bundles">
            <mat-radio-button [value]="bundle">
              <div fxLayoutAlign="space-between start"
                   class="gap-16">
                <h3 class="mb-0" fxFlex>{{ bundle.name }}</h3>
                <span *ngIf="bundle.isBundle && bundle.savings.amount > 0"
                      class="chip save-chip"
                      i18n>Save {{ bundle.savings | slPrice }}</span>
              </div>

              <div class="text-semibold">{{ bundle.price | slPrice }}</div>
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>

      <!-- <span *ngIf="selectedBundle.savings.amount > 0"
            class="saving-bubble"
            i18n>Save {{ selectedBundle.savings | slPrice }} by buying this bundle</span> -->

      <ng-container *ngIf="selectedBundle.isBundle; else productOnly">
        <div class="mt-16">
          <span class="text-bold">{{ selectedBundle.benefitDetails.name }}</span>

          <ul *ngFor="let benefit of selectedBundle.benefitDetails.benefits">
            <li>{{ benefit }}</li>
          </ul>
        </div>
      </ng-container>
    </div>
  </sl-sidenav-step>

  <sl-bundle-price-info-panel [bundle]="selectedBundle" [withVat]="howMuch.billedWithVat"></sl-bundle-price-info-panel>

  <div class="value-list mb-16">
    <ng-container *ngIf="selectedBundle.isBundle">
      <span i18n>Savings</span>
      <span class="savings">-{{ selectedBundle.savings | slPrice | slDash }}</span>
    </ng-container>

    <span i18n>Total</span>
    <sl-fee [fee]="selectedBundle.price"></sl-fee>
  </div>

</sl-sidenav-stepper>

<ng-template #productOnly>
  <sl-marketing-features [features]="marketingFeatures"> </sl-marketing-features>
</ng-template>
