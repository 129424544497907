import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

let youtubeApiLoaded = false

// --------------------------------------------------------

@Component({
  selector: 'sl-youtube-player',
  template: `
    <youtube-player [videoId]="videoId"
                    [height]="height"
                    [width]="width"
                    [playerVars]="defaultPlayerVars"
                    (ready)="onReady($event)"
                    (stateChange)="handleStateChange($event)"
    ></youtube-player>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubePlayerComponent implements OnInit {
  @Input() videoId: string
  @Input() width: number
  @Input() height: number

  @Output() loaded = new EventEmitter<{ duration: number }>()
  // emits an event whenever the videos starts playing for the first time
  @Output() played = new EventEmitter<void>()

  hasStarted = false

  defaultPlayerVars = { modestbranding: 1, rel: 0 }

  // --------------------------------------------------------

  ngOnInit() {
    if (!youtubeApiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      document.body.appendChild(tag)
      youtubeApiLoaded = true
    }
  }

  // --------------------------------------------------------

  onReady({ target }: YT.PlayerEvent) {
    this.loaded.emit({ duration: target.getDuration() })
  }

  // --------------------------------------------------------

  handleStateChange(event: YT.OnStateChangeEvent) {
    if (!this.hasStarted && event.data === YT.PlayerState.PLAYING) {
      this.hasStarted = true
      this.played.emit()
    }
  }
}
