import { Component, OnInit } from '@angular/core'

import { User } from '@libs/models'
import { UserService } from '@app/users/services/user.service'

// ---------------------------------------------------------

@Component({
  selector: 'sl-platform-admin-toggle',
  templateUrl: './platform-admin-toggle.component.html'
})
export class PlatformAdminToggleComponent implements OnInit {
  user: User

  // --------------------------------------------------------

  constructor(
    private userService: UserService
  ) {}

  // --------------------------------------------------------

  ngOnInit() {
    this.user = this.userService.currentUser
  }

  // --------------------------------------------------------

  onUserPlatformAdminToggled() {
    this.userService.toggleUserPlatformAdmin()
  }

}
