import { Component } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-company-links',
  templateUrl: './company-links.component.html'
})
export class CompanyLinksComponent {
  copyrightYear = new Date()
}
