import { sum } from 'ramda'

import { parseISO, addDays } from 'date-fns'

import type { ApiFieldSpec } from '../model'
import type { IOptionGrantVesting } from '../../models/investment-event.model'
import { EventCategory } from '../../models/category.model'

import type { Investor } from '../stock/investor'
import type { ShareClass } from '../stock/share-class'
import type { IEventViewState } from '../events/event'
import type { OptionExerciseEvent } from './option-exercise-event'
import type { OptionSchemeEvent } from './option-scheme-event'
import type { StopVestingEvent } from './stop-vesting-event'
import { EventCollectionBase } from '../events/event'
import { DocumentEventAdapter } from '../events/document-event-adapters'
import type { Option } from './option'
import { OptionCollection } from './option'

import { today, isSameOrAfter } from '@libs/utils'
import { OptionReturnEvent } from './option-return-event'

// ------------------------------------------------------------

export interface IOptionGrantAnswers {
  nino?: string
  role?: string
  issuePrice?: number
}

// ------------------------------------------------------------

export class OptionGrantEvent extends DocumentEventAdapter<IOptionGrantAnswers> {
  readonly domain = 'optionGrants'
  readonly category = EventCategory.OptionGrant

  count: number
  strikePrice: number
  vesting: IOptionGrantVesting

  investor: Investor
  optionScheme: OptionSchemeEvent
  stopVesting: StopVestingEvent | null

  options = new OptionCollection()

  // ----------------------------------------------------

  constructor({
    count = 1,
    ...data
  }) {
    super({ count, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'investor', include: 'create' },
      'optionScheme',
      'strikePrice',
      'count',
      'vesting'
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'options', 'grants', this.id ]
    }
  }

  // ----------------------------------------------------

  override getEditState(): IEventViewState {
    return {
      state: [
        ...this.getViewState().state,
        'basics'
      ]
    }
  }

  // ----------------------------------------------------

  get emi(): boolean {
    return this.optionScheme.emi
  }

  // ----------------------------------------------------

  get lapsingDays(): number | null {
    return this.optionScheme.lapsingDays
  }

  // ----------------------------------------------------

  get shareClass(): ShareClass {
    return this.optionScheme.shareClass
  }

  // ----------------------------------------------------

  override get safeName(): string {
    return this.investor && typeof this.investor.name === 'string'
      ? $localize`Option Grant` + ` - ${this.investor.name}`
      : $localize`Option Grant`
  }

  // ----------------------------------------------------

  get activeOption(): Option | undefined {
    return this.options.activeOption
  }

  // ----------------------------------------------------

  /** We consider a grant completed once all options have been either exercised or returned to the pool */
  get hasCompleted(): boolean {
    return this.closed && !this.activeOption
  }

  // ----------------------------------------------------

  get currentOptionCount(): number {
    return this.options.optionCount
  }

  // ----------------------------------------------------

  get optionExercises(): OptionExerciseEvent[] {
    return this.options.optionExercises
  }

  // ----------------------------------------------------

  get optionReturns(): OptionReturnEvent[] {
    return this.options.optionReturns
  }

  // ----------------------------------------------------

  get optionsExercised(): number {
    return this.optionExercises.reduce(
      (out, exercise) => out + exercise.count,
      0
    )
  }

  // ----------------------------------------------------

  get pendingOptionCount(): number {
    return this.currentOptionCount - (this.activeOption?.optionExercise?.count ?? 0)
  }

  // ----------------------------------------------------

  get currentExercisedOptionCount(): number {
    const inactiveOptions = this.options.getInactiveOptions()

    return sum(inactiveOptions.map(o => o.optionExercise?.count ?? 0))
  }

  // ----------------------------------------------------

  get pendingExercisedOptionCount(): number {
    const pendingExerciseCount = this.activeOption?.optionExercise?.count ?? 0

    return this.currentExercisedOptionCount + pendingExerciseCount
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.count * this.strikePrice
  }

  // ----------------------------------------------------

  get canBeResumed(): boolean {
    return !!this.stopVesting
  }

  // ----------------------------------------------------

  get isLapsed(): boolean {
    if (this.lastDateToExerciseOption == null) return false

    return isSameOrAfter(today(), this.lastDateToExerciseOption)
  }

  // ----------------------------------------------------

  get lastDateToExerciseOption(): Date | null {
    // Holder has not left yet, so no deadline yet
    if (this.stopVesting == null) return null

    // Scheme is Exit, no deadline to exercise after leaving
    if (this.lapsingDays == null) return null

    // Scheme specifies a deadline after leaving
    return addDays(parseISO(this.stopVesting.effectiveDate), this.lapsingDays)
  }

  // ----------------------------------------------------

  override get hasIndividualQuestionsForDocuments(): boolean {
    return false
  }
}

// ------------------------------------------------------

export class OptionGrantCollection extends EventCollectionBase<OptionGrantEvent> {
  constructor() {
    super('investor.name')
  }

  /** Unapproved option grants */
  getUnapprovedGrants() {
    return this.filter(op => !op.approved)
  }

  // ----------------------------------------------------

  /** Approved option grants */
  getApprovedGrants() {
    return this.filter(op => op.closed)
  }

  // ----------------------------------------------------

  /** Option Grants that are in progress or require actions from the user */
  getActiveOptionGrants() {
    return this.filter(op => !!op.activeOption)
  }

  // ----------------------------------------------------

  /** Lapsed option grants excluding active option grants. */
  getLapsedOptionGrants() {
    return this.filter(op => op.isLapsed)
  }

  // ----------------------------------------------------

  getActiveOptions() {
    return this.getActiveOptionGrants().map(optionGrantEvent => optionGrantEvent.activeOption)
  }
}
