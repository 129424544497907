<mat-form-field appearance="standard">
  <mat-label *ngIf="!ready" i18n>Loading events...</mat-label>
  <mat-label *ngIf="ready" i18n>View from incorporation to...</mat-label>

  <mat-select [value]="selectedEventId"
              (selectionChange)="onSelectedEventChanged($event)">

    <mat-option *ngFor="let event of openEvents; let last = last;"
                [value]="event.id"
                [ngClass]="{'bb': last}"
                i18n>
      Due {{ event.effectiveDate | slDate }} - {{ event.name }}
    </mat-option>

    <mat-option *ngFor="let event of closedEvents"
                [value]="event.id">
      {{ event.effectiveDate | slDate }} - {{ event.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
