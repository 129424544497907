import type { Document } from './document'
import type { Signatory } from './signatory'
import type { User } from '../user'
import type { Company } from '../company'
import type { Entity } from '../entity'
import type { IDocumentPermissionInfo } from './permission-set'
import type { Appointment } from '../appointment'
import { AppointmentRole } from '../appointment-roles'
import type { ICompanyDocumentRelationship, IUserDocumentRelationship, SignedAsRole } from '../../models/document.model'

import { uniqById } from '@libs/utils'

// ------------------------------------------------------------

export abstract class DocumentRelationshipBase {
  abstract readonly type: 'company' | 'user'

  readonly witnessInfo?: UserWitness

  abstract readonly hasInvite: boolean
  abstract readonly canView: boolean

  abstract readonly relatedUsers: UserRelationship[]
  abstract readonly userSignatories: UserRelationship[]

  // ------------------------------------------------------------

  constructor(
    public readonly document: Document,
    public readonly entity: Entity,
    public readonly signatory?: Signatory,
  ) {
    if (this.signatory?.witnessSignature) {
      this.witnessInfo = new UserWitness(this)
    }
  }

  // ------------------------------------------------------------

  get id() { return this.entity.link }
  get name() { return this.entity.name }

  // ------------------------------------------------------------

  get canSign(): boolean { return !!this.signatory }
  get hasSigned(): boolean { return this.canSign && this.signatory.isSigned }
  get signedOn() { return this.signatory && this.signatory.signedDate }

  get requiresWitness(): boolean { return this.canSign && this.signatory.requiresWitness }
  get needsWitness(): boolean { return this.canSign && this.signatory.needsWitness }
}

// ------------------------------------------------------------

export class CompanyRelationship extends DocumentRelationshipBase
  implements ICompanyDocumentRelationship {
  readonly type = 'company'

  employeeAppointments: Appointment[] = []
  employees: UserRelationship[] = []
  employeeSignatories: EmployeeSignatory[] = []

  relevantEmployees: UserRelationship[] = []

  permission: IDocumentPermissionInfo

  // ------------------------------------------------------------

  constructor(
    document: Document,
    public readonly company: Company,
    signatory?: Signatory,
  ) {
    super(document, company, signatory)

    this.loadPermission()

    // add admin in employee set

    const admins = this.company.getAdmins()

    this.employees = uniqById([ ...this.company
      .getRelationsByRole(AppointmentRole.Signatory), ...admins ])
      .map(appointment => new UserRelationship(this.document, appointment.user))

    if (this.signatory) {
      this.employeeSignatories = this.company
        .getRelationsByRole((this.signatory.companyRole as unknown) as AppointmentRole)
        .map(appointment => new EmployeeSignatory(this, appointment.user))

      this.relevantEmployees = this.employeeSignatories
    } else {
      this.relevantEmployees = this.employees
    }
  }

  // ----------------------------------------------------

  loadPermission() {
    this.permission = this.document.permissions.getPermission(this.company.id)
  }
  // ------------------------------------------------------------

  get relatedUsers(): UserRelationship[] { return this.employees }

  get userSignatories(): UserRelationship[] { return this.employeeSignatories }

  // ------------------------------------------------------------

  get companyRole(): SignedAsRole | null {
    return this.signatory
      ? this.signatory.companyRole
      : null
  }

  override get canSign(): boolean {
    return !!this.signatory && this.employeeSignatories.length > 0
  }

  override get hasSigned(): boolean { return this.signatory && this.signatory.isSigned }

  get hasEmail(): boolean { return this.relevantEmployees.some(employee => employee.hasEmail) }

  get hasInvite(): boolean { return this.permission.read }
  get canInvite(): boolean { return !this.hasInvite && this.hasEmail }

  get canView(): boolean { return this.permission.read }

  get isDocumentCompany(): boolean { return this.company.id === this.document.company.id }
}

// ------------------------------------------------------------

export class UserRelationship extends DocumentRelationshipBase
  implements IUserDocumentRelationship {
  readonly type = 'user'

  permission: IDocumentPermissionInfo
  invited: Date | null = null

  // ----------------------------------------------------

  constructor(
    document: Document,
    public readonly user: User,
    signatory?: Signatory,
  ) {
    super(document, user, signatory)

    this.loadPermission()
  }

  // ----------------------------------------------------

  loadPermission() {
    this.permission = this.document.permissions.getPermission(this.user.id)
  }

  // ----------------------------------------------------

  get relatedUsers(): UserRelationship[] {
    return [ this ]
  }

  get userSignatories(): UserRelationship[] {
    return this.signatory
      ? [ this ]
      : []
  }

  // ----------------------------------------------------

  get canView(): boolean { return this.document.permissions.hasPermission(this.user.id) }
  get isAdmin(): boolean { return this.permission.admin }

  get hasEmail(): boolean { return !!this.user.email }
  get emailAddress(): string { return this.user.email }

  get needsInvite() { return this.canInvite && !this.canView }
  get canInvite(): boolean {
    return this.hasEmail && !this.permission.admin && !this.permission.read
  }
  get hasInvite(): boolean { return !!this.invited }

  get hasJoined(): boolean { return this.user.connected }
}

// ------------------------------------------------------------

export class EmployeeSignatory extends UserRelationship {

  constructor(
    public readonly parent: CompanyRelationship,
    user: User,
  ) {
    super(parent.document, user, parent.signatory)
  }

  // ----------------------------------------------------

  override get hasSigned() {
    return this.parent.hasSigned
  }

  override get signedOn() {
    return this.hasSigned
      ? this.signatory.signedDate
      : null
  }

  // ----------------------------------------------------

  override get needsInvite() {
    return this.canInvite
  }
}

// ------------------------------------------------------------

export class UserWitness extends UserRelationship {

  constructor(
    public readonly parent: DocumentRelationshipBase,
  ) {
    super(parent.document, parent.signatory.witnessSignature.user, parent.signatory)
  }

  // ----------------------------------------------------

  override get canSign() { return false }
  override get hasSigned() { return true }
  override get signedOn() { return this.signatory.witnessSignature.signed }
}
