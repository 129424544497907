import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-header-value',
  template: `
    <div class="pv-8" fxLayoutAlign="space-between center">
      <span class="text-semibold font-size-16 text-light-grey">{{ label }}</span>
      <span class="text-semibold font-size-16 mat-primary-fg text-right">
        <ng-content></ng-content>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderValueComponent {
  @Input() label: string
}
