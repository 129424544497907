<div fxLayout="row"
     fxLayoutAlign="start center"
     class="gap-8"
     [slEntityInfoPopup]="entity"
     [disabled]="!popup">

  <sl-avatar [entity]="entity"
             [size]="size"
             [sizeXs]="sizeXs"></sl-avatar>

  <span class="avatar-name">{{ entity.name }}</span>

  <mat-icon *ngIf="showEmailVerified"
            fontIcon="icon-check-circle"
            class="email-verified-icon"
            matTooltip="Email verified"></mat-icon>

</div>
