import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'

import { Company, Jurisdiction, Jurisdictions } from '@libs/models'
import type { CompanyMatch } from '../../models/company-match'

import { CompanyService } from '@app/companies/services/company/company.service'

// ----------------------------------------------------------

@Component({
  selector: 'sl-company-selector',
  templateUrl: './company-selector.component.html'
})
export class CompanySelectorComponent {
  @Input() currentCompany: Company
  @Input() allowNewCompany = true
  @Input() extendRoles = true
  @Input() jurisdiction: Jurisdiction = Jurisdiction.EnglandWales
  @Input() defaultName?: string

  @Output() companySelected = new EventEmitter<CompanyMatch>()
  @Output() companySaved = new EventEmitter<Company>()

  form = this.fb.group({ jurisdiction: [ this.jurisdiction ] })

  readonly selectableJurisdictions = Jurisdictions.map(j => ({ code: j, disabled: false }))

  matchingCompanies: CompanyMatch[] = []
  selectedCompany: CompanyMatch | null = null
  canCreateCompany = false
  searchLoading = false

  // ----------------------------------------------------

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly companyService: CompanyService
  ) {}

  // ----------------------------------------------------

  onCompanySelected(match: CompanyMatch) {
    this.selectedCompany = match
    this.companySelected.emit(match)
  }

  // ----------------------------------------------------

  get selectedJurisdiction(): Jurisdiction | null {
    return this.form.get('jurisdiction').value
  }

  // ----------------------------------------------------

  async getCompany() {
    const jurisdiction = this.selectedJurisdiction || this.currentCompany.jurisdiction
    const company = await this.companyService.createCompanyFromCompanyMatch(this.selectedCompany, jurisdiction, this.extendRoles)
    this.companySaved.emit(company)
  }
}
