import { Injectable } from '@angular/core'
import {
  type Jurisdiction,
  JurisdictionToVatValidator,
  SeedLegalsCompleteJurisdictions,
  type SelectableJurisdiction
} from '@libs/models'
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

import { checkVAT } from 'jsvat'
import { partition } from 'ramda'

// ----------------------------------------------------------

//TODO: Should handle more behaviour currently in company-details.component.ts
@Injectable({
  providedIn: 'root'
})
export class JurisdictionService {

  partitionCompleteAndGlobal(
    allJurisdictions: SelectableJurisdiction[]
  ): [ SelectableJurisdiction[], SelectableJurisdiction[] ] {
    const [ complete, global ] = partition(j => SeedLegalsCompleteJurisdictions.includes(j.code), allJurisdictions)
    return [ complete, global ]
  }
}

export function VatNumberValidator(jurisdiction: Jurisdiction): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const validator = JurisdictionToVatValidator[ jurisdiction ]

    if (!control.value || !validator) {
      return null
    }

    const isInvalidRegExp = validator instanceof RegExp
      && !control.value.match(validator)
    const isInvalidJsVat = !(validator instanceof RegExp)
      && !checkVAT(control.value, [ validator ]).isValid

    if (isInvalidRegExp || isInvalidJsVat) {
      return { pattern: { valid: false } }
    } else {
      return null
    }
  }
}
