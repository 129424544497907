import { groupBy, prop, sortBy, unnest } from 'ramda'

import { addMonths, isBefore, isEqual, parseISO, subMonths } from 'date-fns'
import { asDate, ColourGenerator, isSameOrAfter, isSameOrBefore } from '@libs/utils'

import { VestingScheduleItem } from './vesting-schedule-item'
import { Colours, IOptionGrantVesting, VestingType } from '../../models'

// ------------------------------------------------------------

interface IGrantLike {
  effectiveDate: string
  count: number
  vesting: IOptionGrantVesting
  optionReturns: { count: number, effectiveDate: string, approved: string }[]
  stopVesting?: { effectiveDate: string }
}

interface IGrantWithIvestor extends IGrantLike {
  investor: { id: string, entity: { name: string } }
}

// ------------------------------------------------------------

interface IInvestorVestingSchedule {
  investorId: string
  items: VestingScheduleItem[]
}

// ------------------------------------------------------------

export interface IChartDataset {
  label: string
  data: number[]
}

export interface IVestingChartData {
  colours: string[]
  datasets: IChartDataset[]
  labels: Date[]
}

// ------------------------------------------------------------

export function getVestingSchedule(
  optionGrant: IGrantLike,
  // ignore all returns and stop vesting events in some cases
  originalVestingOnly: boolean = false
): VestingScheduleItem[] {
  // TODO: stop using the event counts and instead use the counts attached to the options as these are the source of truth
  const schedule: VestingScheduleItem[] = []

  function addScheduleItem(d: Date, c = 0, t = 0): VestingScheduleItem {
    const item = new VestingScheduleItem(d, c, t)
    schedule.push(item)
    return item
  }

  calculateTimeBasedVesting(optionGrant, addScheduleItem, originalVestingOnly)

  // if milestones mark options as vested
  calculateMilestoneBasedVesting(optionGrant, addScheduleItem)

  return schedule
}

// ------------------------------------------------------------

function calculateTimeBasedVesting(
  optionGrant: IGrantLike,
  addScheduleItem: (d: Date, c?: number, t?: number) => VestingScheduleItem,
  originalVestingOnly: boolean
) {
  let insertOptionReturnAtCorrectDate

  if (optionGrant.vesting.type === VestingType.Time) {

    const count = optionGrant.count
    const startDate = parseISO(optionGrant.vesting.startDate)
    const period = optionGrant.vesting.period
    const frequency = optionGrant.vesting.frequency
    const initialVested = Math.floor(count * (optionGrant.vesting.vestedAtStart ?? 0) / 100)
    const available = count - initialVested

    const optionReturns = sortBy(prop('effectiveDate'), (optionGrant.optionReturns || []).filter(or => !!or.approved))
    let cumulated = initialVested
    let cumulativeReturnCount = 0

    if (period <= 0 || frequency <= 0) {
      cumulated = count

      // No vesting, all options on vesting commencement date
      // A starting value so that the chart looks nicer
      addScheduleItem(subMonths(startDate, 3), 0, 0)
      addScheduleItem(startDate, cumulated, cumulated)

      // insert option returns
      if (!originalVestingOnly && optionReturns.length > 0) {
        optionReturns.forEach((or, idx) => {
          cumulativeReturnCount += or.count
          // Returns vest everything they don't return:
          // Therefore the incremental value vested for a return is equal to
          // the initial count of the grant - the amount vested so far - however much has been returned to the pool so far
          const bonusVesting = optionGrant.count - cumulativeReturnCount - cumulated
          // The cumulated amount vested is therefore simply the previous value + the incremental amount vested
          cumulated += bonusVesting
          addScheduleItem(asDate(or.effectiveDate), bonusVesting, cumulated)
          // value at the end to make chart look nice
          if (idx === optionReturns.length - 1) {
            addScheduleItem(addMonths(asDate(or.effectiveDate), 3), 0, cumulated)
          }

        })
      } else {
        // value at the end to make chart look nice
        addScheduleItem(addMonths(startDate, 3), 0, count)
      }

    } else {
      const vestingStopDate = optionGrant.stopVesting
        ? parseISO(optionGrant.stopVesting.effectiveDate)
        : null

      const steps = period / frequency
      // Skips the first steps for cliff
      const skippedSteps = Math.max(0, optionGrant.vesting.cliff / frequency - 1)
      let previousCumulated = initialVested
      let stepDate = new Date(startDate)
      let vestingStopped = false

      // One value before so that the chart looks nicer
      addScheduleItem(stepDate, initialVested, initialVested)

      insertOptionReturnAtCorrectDate = function() {
        const optionReturn = optionReturns[ 0 ]
        if (optionReturn) {
          if (isSameOrBefore(asDate(optionReturn.effectiveDate), stepDate)) {
            cumulativeReturnCount += optionReturn.count
            // Returns vest everything they don't return:
            // Therefore the incremental value vested for a return is equal to
            // the initial count of the grant - the amount vested so far - however much has been returned to the pool so far
            const bonusVesting = optionGrant.count - cumulativeReturnCount - cumulated
            // The cumulated amount vested is therefore simply the previous value + the incremental amount vested
            cumulated += bonusVesting
            addScheduleItem(asDate(optionReturn.effectiveDate), bonusVesting, cumulated)
            // Remove optionReturn from array
            optionReturns.shift()
            // stop the standard vesting items being added if there are any returns
            vestingStopped = true
            // recursion in case there are multiple returns within one 'step'
            insertOptionReturnAtCorrectDate()
          }
        }
      }


      for (let i = 0; i < steps; i++) {
        stepDate = addMonths(stepDate, frequency)

        if (!originalVestingOnly) {

          insertOptionReturnAtCorrectDate()

          // If vesting is stopped on a particular date, add a value for that
          // date and the next step date (if different), and exit the loop once there are no more returns
          if (vestingStopDate && isBefore(vestingStopDate, stepDate)) {
            addScheduleItem(stepDate, 0, cumulated)
            // Continue the loop while there are still returns, but stop the standard vesting items being added
            vestingStopped = true

            // Exit the loop once vesting is stopped and there are no more returns to insert
            if (optionReturns.length === 0) break
          }
        }

        if (!vestingStopped) {
          // Vesting starts after the cliff, we add some values before so that the chart looks nicer
          if (i < skippedSteps) {
            addScheduleItem(stepDate, 0, cumulated)
          } else {
            cumulated = initialVested + Math.floor(available * (i + 1) / steps)
            const vested = cumulated - previousCumulated
            previousCumulated = cumulated

            addScheduleItem(stepDate, vested, cumulated)
          }
        }
      }

      // One more value after so that the chart looks nicer
      stepDate = addMonths(stepDate, frequency)
      addScheduleItem(stepDate, 0, cumulated)
    }

  }
}

// ------------------------------------------------------------

function calculateMilestoneBasedVesting(
  optionGrant: IGrantLike,
  addScheduleItem: (d: Date, c?: number, t?: number) => VestingScheduleItem
) {
  if (optionGrant.vesting.type === VestingType.Milestones) {
    const count = optionGrant.count
    const startDate = parseISO(optionGrant.effectiveDate)
    addScheduleItem(subMonths(startDate, 3), 0, 0)
    addScheduleItem(startDate, count, count)
    addScheduleItem(addMonths(startDate, 3), 0, count)
  }
}

// ------------------------------------------------------------

export function getOptionsVestedOnDate(
  vestingSchedule: VestingScheduleItem[],
  date = new Date()
): number {
  const schedule = vestingSchedule

  const scheduleLength = schedule.length

  if (isBefore(date, schedule[ 0 ].date)) {
    return 0
  } else if (isSameOrAfter(date, schedule[ scheduleLength - 1 ].date)) {
    return schedule[ scheduleLength - 1 ].total
  }

  let start = 0
  let end = scheduleLength - 1
  let middle = Math.floor((start + end) / 2)

  while (start < end && (isBefore(date, schedule[ middle ].date) || isSameOrAfter(date, schedule[ middle + 1 ].date))) {
    if (isBefore(date, schedule[ middle ].date)) {
      end = middle - 1
    } else {
      start = middle + 1
    }

    middle = Math.floor((start + end) / 2)
  }

  return schedule[ middle ].total
}

// ------------------------------------------------------------

export function getDateOfFirstVesting(optionGrant: { vesting: IOptionGrantVesting }): Date {
  const { startDate, type } = optionGrant.vesting

  if (type === VestingType.Time) {
    const { period, cliff } = optionGrant.vesting
    return addMonths(parseISO(startDate), period > 0 ? cliff : 0)
  }

  return parseISO(startDate)
}

// ------------------------------------------------------------

function getInvestorVestingSchedules(optionGrants: IGrantWithIvestor[]): IInvestorVestingSchedule[] {
  const grantsByInvestor = groupBy(g => g.investor.id, optionGrants.filter(grant => grant.vesting.type === VestingType.Time))

  const results = Object.entries(grantsByInvestor)
    .map(([ investor, grants ]) => {
      const schedules = grants.map(g => getVestingSchedule(g))
      return { investorId: investor, items: mergeScheduleItems(combineSchedules(schedules)) }
    })

  return results
}

// ----------------------------------------------------

export function getCombinedVestingSchedules(optionGrants: IGrantWithIvestor[]): IVestingChartData {
  const investorsMap = groupBy(prop('id'), optionGrants.map(prop('investor')))
  const sortedInvestorSchedules = getInvestorVestingSchedules(optionGrants)
  const numDatasets = sortedInvestorSchedules.length

  const colGen = new ColourGenerator(Colours)

  const colours: string[] = []
  const datasets: IChartDataset[] = []
  const labels: Date[] = []

  const previous: number[] = []
  const investorToDatasetMap = new Map<string, { idx: number, dataset: IChartDataset }>()
  const schedules: { investorId: string, date: Date, total: number }[][] = []

  sortedInvestorSchedules.forEach(({ investorId, items }, idx) => {
    colours.push(colGen.next())
    previous.push(0)

    const dataset = { label: investorsMap[ investorId ][ 0 ].entity.name, data: [] }
    datasets.push(dataset)
    schedules.push(items.map(({ date, total }) => ({ investorId, date, total })))

    investorToDatasetMap.set(investorId, { idx, dataset })
  })

  let previousTime = null

  for (const item of combineSchedules(schedules)) {
    const { idx: datasetIndex, dataset } = investorToDatasetMap.get(item.investorId)

    if (previousTime && isEqual(item.date, previousTime)) {
      // Item has same time as previous item, update last entry
      const series = dataset.data
      series[ series.length - 1 ] = item.total
      previous[ datasetIndex ] = item.total
    } else {
      labels.push(item.date)

      // Item has a different time, update all series with new this.entries
      for (let j = 0; j < numDatasets; j++) {
        if (j === datasetIndex) {
          datasets[ j ].data.push(item.total)
          previous[ j ] = item.total
        } else {
          datasets[ j ].data.push(previous[ j ])
        }
      }

      previousTime = item.date
    }
  }

  return { colours, datasets, labels }
}

// ----------------------------------------------------

function combineSchedules<T extends { date: Date }>(
  schedules: T[][]
): T[] {
  return sortBy(prop('date'), unnest(schedules))
}

// ----------------------------------------------------

function mergeScheduleItems(
  items: VestingScheduleItem[]
): VestingScheduleItem[] {
  return items.reduce((out, cur) => {
    const prev = out[ out.length - 1 ]

    if (prev && isEqual(prev.date, cur.date)) {
      prev.combineWith(cur)
    } else {
      out.push(cur.clone().initialiseTotal(prev))
    }

    return out
  }, [])
}
