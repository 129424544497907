import { Injectable } from '@angular/core'

import { compose, flatten, has, not, path, pipe, sortBy, uniqBy } from 'ramda'

import { Entity, User, Company, Investor } from '@libs/models'

import { CreateEntityDialogOptions, EntityDialogResult, EditEntityDialogOptions, EntityDialogData } from '../models/entity-edit-dialog'
import { IEntity, IEntitySidenavOptions } from '../models/entity-sidenav'
import { IInvestorSidenavData, IInvestorEntity } from '../models/investor-sidenav'

import { ModalService } from '@libs/modals'
import { SidenavService } from '@app/layout/services/sidenav.service'

import { EntityEditDialogComponent } from '../components/entity-edit-dialog/entity-edit-dialog.component'
import { InvestorSidenavComponent } from '../components/investor-sidenav/investor-sidenav.component'

import { idSet } from '@libs/utils'

// ----------------------------------------------------------

const entityMerger = pipe(
  sortBy(compose(not, has('i'))),
  uniqBy(path([ 'entity', 'id' ]))
)

// ----------------------------------------------------------

export interface InfoPopupData {
  user: User
  company: Company
}

// ----------------------------------------------------------

const _E = <E extends Entity = Entity>(entity: E): IEntity<E> => ({ entity })
const _IE = (investor: Investor): IInvestorEntity => ({ investor, entity: investor.entity })

// ----------------------------------------------------------

@Injectable()
export class EntityService {

  mergeAllInvestorEntities(
    ...investorEntities: IInvestorEntity[][]
  ): IInvestorEntity[] {
    return entityMerger(flatten(investorEntities))
  }

  // ----------------------------------------------------

  buildInvestorEntities(
    investors: Investor[]
  ): IInvestorEntity[] {
    return investors.map(_IE)
  }

  // ----------------------------------------------------

  buildEntities<E extends Entity = Entity>(
    entities: E[]
  ): IEntity<E>[] {
    return entities.map(_E)
  }

  // ----------------------------------------------------

  getAllCompanyEntities(
    company: Company
  ): IInvestorEntity[] {
    return this.mergeAllInvestorEntities(
      company.investors.map(_IE),
      company.appointments.getUsers().map(_E)
    )
  }

  // ----------------------------------------------------

  getCompanyAppointmentEntities(
    company: Company
  ): IEntity<User>[] {
    return company.appointments.getUsers().map(_E)
  }

  // ----------------------------------------------------

  getCompanyAdminEntities(
    company: Company
  ): IEntity<User>[] {
    return company.getAdmins().map(a => _E(a.user))
  }

  // ----------------------------------------------------

  getCompanyInvestorEntities(
    company: Company
  ): IInvestorEntity[] {
    return company.investors.map(_IE)
  }

  // ----------------------------------------------------

  excludeByEntityId<E extends Entity = Entity>(
    entities: IEntity<E>[],
    excluded: string[]
  ): IEntity<E>[] {
    const excludedIds = new Set(excluded)

    return entities.filter(({ entity }) => !excludedIds.has(entity.id))
  }

  // ----------------------------------------------------

  excludeByEntity<E extends Entity = Entity>(
    entities: IEntity<E>[],
    excluded: Entity[]
  ): IEntity<E>[] {
    const excludedIds = idSet(excluded)

    return entities.filter(({ entity }) => !excludedIds.has(entity.id))
  }

  // ----------------------------------------------------

  excludeByInvestor(
    entities: IInvestorEntity[],
    excluded: Investor[]
  ): IInvestorEntity[] {
    return this.excludeByEntity(
      entities,
      excluded.map(({ entity }) => entity)
    )
  }

  // ----------------------------------------------------

  showInvestorSidenav(
    company: Company,
    options?: Partial<IEntitySidenavOptions>
  ): Promise<Investor | undefined>

  showInvestorSidenav(
    company: Company,
    entities: IInvestorEntity[],
    options?: Partial<IEntitySidenavOptions>
  ): Promise<Investor | undefined>

  showInvestorSidenav(
    company: Company,
    entities: IInvestorEntity[] | Partial<IEntitySidenavOptions>,
    options?: Partial<IEntitySidenavOptions>
  ): Promise<Investor | undefined> {
    if (!Array.isArray(entities)) {
      options = entities

      entities = this.getCompanyInvestorEntities(company)
    }

    return this.sidenavService.openSync<InvestorSidenavComponent, IInvestorSidenavData, Investor>(
      InvestorSidenavComponent,
      {
        title: $localize`Add investor`,
        searchLabel: $localize`Search existing`,
        addNewLabel: $localize`Add new investor`,
        canAddNew: true,
        ...options,
        company,
        entities
      }
    )
  }

  // ----------------------------------------------------

  showCreateEntityDialog<Ent = Entity | Company | User>(
    options: CreateEntityDialogOptions = {}
  ): Promise<EntityDialogResult<Ent>> {
    return this.openEntityDialog<Ent>({
      mode: 'create',
      autoCreateAppointment: false,
      ...options
    })
  }

  // ----------------------------------------------------

  showEntityEditDialog<Ent = Company | User>(
    entity: Company | User,
    options: EditEntityDialogOptions = {}
  ): Promise<EntityDialogResult<Ent>> {
    return this.openEntityDialog<Ent>({
      mode: 'edit',
      entity,
      entityType: entity.entityType,
      autoCreateAppointment: false,
      ...options
    })
  }

  // ----------------------------------------------------

  private async openEntityDialog<Ent>(
    dialogData: EntityDialogData
  ): Promise<EntityDialogResult<Ent>> {
    this.modalService.closeAllDialogs()

    return this.modalService.show<EntityEditDialogComponent, EntityDialogData, EntityDialogResult<Ent>>(EntityEditDialogComponent, dialogData, {
      panelClass: [ 'sl-dialog-panel', 'sl-entity-dialog-panel' ],
      width: '960px',
      height: '90vh'
    })
  }

  // ----------------------------------------------------

  constructor(
    private modalService: ModalService,
    private sidenavService: SidenavService
  ) {}
}
