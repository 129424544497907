import { Jurisdiction } from './jurisdiction.model'

export interface Country {
  code: CountryCode
  name: string
}

// ----------------------------------------------------------

export type CountryCode =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GB-SCT'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'

// ----------------------------------------------------------

export const Countries: Country[] = [
  { code: 'GB', name: $localize`United Kingdom` },
  { code: 'AF', name: $localize`Afghanistan` },
  { code: 'AX', name: $localize`Åland Islands` },
  { code: 'AL', name: $localize`Albania` },
  { code: 'DZ', name: $localize`Algeria` },
  { code: 'AS', name: $localize`American Samoa` },
  { code: 'AD', name: $localize`Andorra` },
  { code: 'AO', name: $localize`Angola` },
  { code: 'AI', name: $localize`Anguilla` },
  { code: 'AQ', name: $localize`Antarctica` },
  { code: 'AG', name: $localize`Antigua and Barbuda` },
  { code: 'AR', name: $localize`Argentina` },
  { code: 'AM', name: $localize`Armenia` },
  { code: 'AW', name: $localize`Aruba` },
  { code: 'AU', name: $localize`Australia` },
  { code: 'AT', name: $localize`Austria` },
  { code: 'AZ', name: $localize`Azerbaijan` },
  { code: 'BS', name: $localize`Bahamas` },
  { code: 'BH', name: $localize`Bahrain` },
  { code: 'BD', name: $localize`Bangladesh` },
  { code: 'BB', name: $localize`Barbados` },
  { code: 'BY', name: $localize`Belarus` },
  { code: 'BE', name: $localize`Belgium` },
  { code: 'BZ', name: $localize`Belize` },
  { code: 'BJ', name: $localize`Benin` },
  { code: 'BM', name: $localize`Bermuda` },
  { code: 'BT', name: $localize`Bhutan` },
  { code: 'BO', name: $localize`Bolivia` },
  { code: 'BQ', name: $localize`Bonaire, Sint Eustatius and Saba` },
  { code: 'BA', name: $localize`Bosnia and Herzegovina` },
  { code: 'BW', name: $localize`Botswana` },
  { code: 'BV', name: $localize`Bouvet Island` },
  { code: 'BR', name: $localize`Brazil` },
  { code: 'IO', name: $localize`British Indian Ocean Territory` },
  { code: 'VG', name: $localize`British Virgin Islands` },
  { code: 'BN', name: $localize`Brunei Darussalam` },
  { code: 'BG', name: $localize`Bulgaria` },
  { code: 'BF', name: $localize`Burkina Faso` },
  { code: 'BI', name: $localize`Burundi` },
  { code: 'KH', name: $localize`Cambodia` },
  { code: 'CM', name: $localize`Cameroon` },
  { code: 'CA', name: $localize`Canada` },
  { code: 'CV', name: $localize`Cabo Verde` },
  { code: 'KY', name: $localize`Cayman Islands` },
  { code: 'CF', name: $localize`Central African Republic` },
  { code: 'TD', name: $localize`Chad` },
  { code: 'CL', name: $localize`Chile` },
  { code: 'CN', name: $localize`China` },
  { code: 'CX', name: $localize`Christmas Island` },
  { code: 'CC', name: $localize`Cocos (Keeling) Islands` },
  { code: 'CO', name: $localize`Colombia` },
  { code: 'KM', name: $localize`Comoros` },
  { code: 'CG', name: $localize`Congo` },
  { code: 'CD', name: $localize`Congo (Dem. Rep.)` },
  { code: 'CK', name: $localize`Cook Islands` },
  { code: 'CR', name: $localize`Costa Rica` },
  { code: 'CI', name: $localize`Côte D'Ivoire` },
  { code: 'HR', name: $localize`Croatia` },
  { code: 'CU', name: $localize`Cuba` },
  { code: 'CW', name: $localize`Curaçao` },
  { code: 'CY', name: $localize`Cyprus` },
  { code: 'CZ', name: $localize`Czechia` },
  { code: 'DK', name: $localize`Denmark` },
  { code: 'DJ', name: $localize`Djibouti` },
  { code: 'DM', name: $localize`Dominica` },
  { code: 'DO', name: $localize`Dominican Republic` },
  { code: 'TL', name: $localize`East Timor` },
  { code: 'EC', name: $localize`Ecuador` },
  { code: 'EG', name: $localize`Egypt` },
  { code: 'SV', name: $localize`El Salvador` },
  { code: 'GQ', name: $localize`Equatorial Guinea` },
  { code: 'ER', name: $localize`Eritrea` },
  { code: 'EE', name: $localize`Estonia` },
  { code: 'ET', name: $localize`Ethiopia` },
  { code: 'FK', name: $localize`Falkland Islands` },
  { code: 'FO', name: $localize`Faroe Islands` },
  { code: 'FJ', name: $localize`Fiji` },
  { code: 'FI', name: $localize`Finland` },
  { code: 'FR', name: $localize`France` },
  { code: 'GF', name: $localize`French Guiana` },
  { code: 'PF', name: $localize`French Polynesia` },
  { code: 'TF', name: $localize`French Southern Territories` },
  { code: 'GA', name: $localize`Gabon` },
  { code: 'GM', name: $localize`Gambia` },
  { code: 'GE', name: $localize`Georgia` },
  { code: 'DE', name: $localize`Germany` },
  { code: 'GH', name: $localize`Ghana` },
  { code: 'GI', name: $localize`Gibraltar` },
  { code: 'GR', name: $localize`Greece` },
  { code: 'GL', name: $localize`Greenland` },
  { code: 'GD', name: $localize`Grenada` },
  { code: 'GP', name: $localize`Guadeloupe` },
  { code: 'GU', name: $localize`Guam` },
  { code: 'GT', name: $localize`Guatemala` },
  { code: 'GG', name: $localize`Guernsey and Alderney` },
  { code: 'GN', name: $localize`Guinea` },
  { code: 'GW', name: $localize`Guinea-Bissau` },
  { code: 'GY', name: $localize`Guyana` },
  { code: 'HT', name: $localize`Haiti` },
  { code: 'HM', name: $localize`Heard Island and McDonald Islands` },
  { code: 'HN', name: $localize`Honduras` },
  { code: 'HK', name: $localize`Hong Kong` },
  { code: 'HU', name: $localize`Hungary` },
  { code: 'IS', name: $localize`Iceland` },
  { code: 'IN', name: $localize`India` },
  { code: 'ID', name: $localize`Indonesia` },
  { code: 'IR', name: $localize`Iran` },
  { code: 'IQ', name: $localize`Iraq` },
  { code: 'IE', name: $localize`Ireland` },
  { code: 'IM', name: $localize`Isle of Man` },
  { code: 'IL', name: $localize`Israel` },
  { code: 'IT', name: $localize`Italy` },
  { code: 'JM', name: $localize`Jamaica` },
  { code: 'JP', name: $localize`Japan` },
  { code: 'JE', name: $localize`Jersey` },
  { code: 'JO', name: $localize`Jordan` },
  { code: 'KZ', name: $localize`Kazakhstan` },
  { code: 'KE', name: $localize`Kenya` },
  { code: 'KI', name: $localize`Kiribati` },
  { code: 'KP', name: $localize`Korea (North)` },
  { code: 'KR', name: $localize`Korea (South)` },
  { code: 'XK', name: $localize`Kosovo` },
  { code: 'KW', name: $localize`Kuwait` },
  { code: 'KG', name: $localize`Kyrgyzstan` },
  { code: 'LA', name: $localize`Laos` },
  { code: 'LV', name: $localize`Latvia` },
  { code: 'LB', name: $localize`Lebanon` },
  { code: 'LS', name: $localize`Lesotho` },
  { code: 'LR', name: $localize`Liberia` },
  { code: 'LY', name: $localize`Libya` },
  { code: 'LI', name: $localize`Liechtenstein` },
  { code: 'LT', name: $localize`Lithuania` },
  { code: 'LU', name: $localize`Luxembourg` },
  { code: 'MO', name: $localize`Macao` },
  { code: 'MK', name: $localize`Macedonia` },
  { code: 'MG', name: $localize`Madagascar` },
  { code: 'MW', name: $localize`Malawi` },
  { code: 'MY', name: $localize`Malaysia` },
  { code: 'MV', name: $localize`Maldives` },
  { code: 'ML', name: $localize`Mali` },
  { code: 'MT', name: $localize`Malta` },
  { code: 'MH', name: $localize`Marshall Islands` },
  { code: 'MQ', name: $localize`Martinique` },
  { code: 'MR', name: $localize`Mauritania` },
  { code: 'MU', name: $localize`Mauritius` },
  { code: 'YT', name: $localize`Mayotte` },
  { code: 'MX', name: $localize`Mexico` },
  { code: 'FM', name: $localize`Micronesia` },
  { code: 'MD', name: $localize`Moldova` },
  { code: 'MC', name: $localize`Monaco` },
  { code: 'MN', name: $localize`Mongolia` },
  { code: 'ME', name: $localize`Montenegro` },
  { code: 'MS', name: $localize`Montserrat` },
  { code: 'MA', name: $localize`Morocco` },
  { code: 'MZ', name: $localize`Mozambique` },
  { code: 'MM', name: $localize`Myanmar` },
  { code: 'NA', name: $localize`Namibia` },
  { code: 'NR', name: $localize`Nauru` },
  { code: 'NP', name: $localize`Nepal` },
  { code: 'NL', name: $localize`Netherlands` },
  { code: 'NC', name: $localize`New Caledonia` },
  { code: 'NZ', name: $localize`New Zealand` },
  { code: 'NI', name: $localize`Nicaragua` },
  { code: 'NE', name: $localize`Niger` },
  { code: 'NG', name: $localize`Nigeria` },
  { code: 'NU', name: $localize`Niue` },
  { code: 'NF', name: $localize`Norfolk Island` },
  { code: 'MP', name: $localize`Northern Mariana Islands` },
  { code: 'NO', name: $localize`Norway` },
  { code: 'OM', name: $localize`Oman` },
  { code: 'PK', name: $localize`Pakistan` },
  { code: 'PW', name: $localize`Palau` },
  { code: 'PS', name: $localize`Palestine` },
  { code: 'PA', name: $localize`Panama` },
  { code: 'PG', name: $localize`Papua New Guinea` },
  { code: 'PY', name: $localize`Paraguay` },
  { code: 'PE', name: $localize`Peru` },
  { code: 'PH', name: $localize`Philippines` },
  { code: 'PN', name: $localize`Pitcairn` },
  { code: 'PL', name: $localize`Poland` },
  { code: 'PT', name: $localize`Portugal` },
  { code: 'PR', name: $localize`Puerto Rico` },
  { code: 'QA', name: $localize`Qatar` },
  { code: 'RE', name: $localize`Réunion` },
  { code: 'RO', name: $localize`Romania` },
  { code: 'RU', name: $localize`Russia` },
  { code: 'RW', name: $localize`Rwanda` },
  { code: 'BL', name: $localize`Saint Barthélemy` },
  { code: 'SH', name: $localize`Saint Helena, Ascension and Tristan da Cunha` },
  { code: 'KN', name: $localize`Saint Kitts and Nevis` },
  { code: 'LC', name: $localize`Saint Lucia` },
  { code: 'MF', name: $localize`Saint Martin` },
  { code: 'PM', name: $localize`Saint Pierre and Miquelon` },
  { code: 'VC', name: $localize`Saint Vincent and the Grenadines` },
  { code: 'WS', name: $localize`Samoa` },
  { code: 'SM', name: $localize`San Marino` },
  { code: 'ST', name: $localize`Sao Tome and Principe` },
  { code: 'SA', name: $localize`Saudi Arabia` },
  { code: 'SN', name: $localize`Senegal` },
  { code: 'RS', name: $localize`Serbia` },
  { code: 'SC', name: $localize`Seychelles` },
  { code: 'SL', name: $localize`Sierra Leone` },
  { code: 'SG', name: $localize`Singapore` },
  { code: 'SX', name: $localize`Sint Maarten` },
  { code: 'SK', name: $localize`Slovakia` },
  { code: 'SI', name: $localize`Slovenia` },
  { code: 'SB', name: $localize`Solomon Islands` },
  { code: 'SO', name: $localize`Somalia` },
  { code: 'ZA', name: $localize`South Africa` },
  { code: 'GS', name: $localize`South Georgia and the South Sandwich Islands` },
  { code: 'SS', name: $localize`South Sudan` },
  { code: 'ES', name: $localize`Spain` },
  { code: 'LK', name: $localize`Sri Lanka` },
  { code: 'SD', name: $localize`Sudan` },
  { code: 'SR', name: $localize`Suriname` },
  { code: 'SJ', name: $localize`Svalbard and Jan Mayen` },
  { code: 'SZ', name: $localize`Swaziland` },
  { code: 'SE', name: $localize`Sweden` },
  { code: 'CH', name: $localize`Switzerland` },
  { code: 'SY', name: $localize`Syria` },
  { code: 'TW', name: $localize`Taiwan` },
  { code: 'TJ', name: $localize`Tajikistan` },
  { code: 'TZ', name: $localize`Tanzania` },
  { code: 'TH', name: $localize`Thailand` },
  { code: 'TG', name: $localize`Togo` },
  { code: 'TK', name: $localize`Tokelau` },
  { code: 'TO', name: $localize`Tonga` },
  { code: 'TT', name: $localize`Trinidad and Tobago` },
  { code: 'TN', name: $localize`Tunisia` },
  { code: 'TR', name: $localize`Turkey` },
  { code: 'TM', name: $localize`Turkmenistan` },
  { code: 'TC', name: $localize`Turks and Caicos Islands` },
  { code: 'TV', name: $localize`Tuvalu` },
  { code: 'UG', name: $localize`Uganda` },
  { code: 'UA', name: $localize`Ukraine` },
  { code: 'AE', name: $localize`United Arab Emirates` },
  { code: 'UM', name: $localize`United States Minor Outlying Islands` },
  { code: 'US', name: $localize`United States of America` },
  { code: 'UY', name: $localize`Uruguay` },
  { code: 'UZ', name: $localize`Uzbekistan` },
  { code: 'VU', name: $localize`Vanuatu` },
  { code: 'VA', name: $localize`Vatican City` },
  { code: 'VE', name: $localize`Venezuela` },
  { code: 'VN', name: $localize`Vietnam` },
  { code: 'VI', name: $localize`Virgin Islands of the United States` },
  { code: 'WF', name: $localize`Wallis and Futuna` },
  { code: 'EH', name: $localize`Western Sahara` },
  { code: 'YE', name: $localize`Yemen` },
  { code: 'ZM', name: $localize`Zambia` },
  { code: 'ZW', name: $localize`Zimbabwe` }
]

// ----------------------------------------------------------

export type CountryMap = {
  [ code in CountryCode ]: Country
}

export const CountriesByCode: CountryMap = Countries.reduce((o, c) => {
  o[ c.code ] = c
  return o
}, {} as CountryMap)

// ----------------------------------------------------------

export function getCountryNameByCode (code: CountryCode): string {
  return CountriesByCode[ code ].name
}

// ----------------------------------------------------------

/**
 * @deprecated All UK jurisdictions are selectable when creating a new company.
 * So we no longer need to infer this via a Postcode.
 */
export const RegionalPostcodePrefixes: Map<string, Jurisdiction> = new Map([
  [ 'AB', Jurisdiction.Scotland ],
  [ 'DD', Jurisdiction.Scotland ],
  [ 'DG', Jurisdiction.Scotland ],
  [ 'EH', Jurisdiction.Scotland ],
  [ 'FK', Jurisdiction.Scotland ],
  [ 'G', Jurisdiction.Scotland ],
  [ 'HS', Jurisdiction.Scotland ],
  [ 'IV', Jurisdiction.Scotland ],
  [ 'KA', Jurisdiction.Scotland ],
  [ 'KW', Jurisdiction.Scotland ],
  [ 'KY', Jurisdiction.Scotland ],
  [ 'ML', Jurisdiction.Scotland ],
  [ 'PA', Jurisdiction.Scotland ],
  [ 'PH', Jurisdiction.Scotland ],
  [ 'TD', Jurisdiction.Scotland ],
  [ 'ZE', Jurisdiction.Scotland ],
  [ 'BT', Jurisdiction.NorthernIreland ]
])
