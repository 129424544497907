import { Component, Input, OnInit } from '@angular/core';
import { ComplianceShareEntity } from '@app/funding/modules/compliance/models/compliance.model';
import { Entity } from '@libs/models';

@Component({
  selector: 'sl-share-avatars',
  templateUrl: './share-avatars.component.html',
  styleUrls: ['./share-avatars.component.scss']
})
export class ShareAvatarsComponent implements OnInit {

  @Input() shares: ComplianceShareEntity[]

  entities: Entity[]

  ngOnInit(): void {
    this.entities = (this.shares ?? []).map(share => share.investor.entity)
  }

}
