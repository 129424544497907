import type { OnChanges, OnInit } from '@angular/core'
import { Component, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'

import { debounceTime, filter, switchMap, tap, distinctUntilChanged, map } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import type { Region } from '@libs/models'
import { Jurisdiction, CompanyTypeNames } from '@libs/models'
import { ModalService } from '@libs/modals'

import { type CompanyMatch } from '@app/entities/models/company-match'
import { Configuration } from '@app/core/services/configuration.service'
import { CompanySearchService } from '@app/companies/services/company-search/company-search.service'
import type { ILocalCompanyMatch } from '@app/entities/models/company-match'
import { RequestCompanyAccessDialogComponent } from '@app/entities/components/request-company-access-dialog/request-company-access-dialog.component'

// ------------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: [ './company-form.component.scss' ]
})
export class CompanyFormComponent implements OnInit, OnChanges {

  @Input() jurisdiction: Jurisdiction
  @Input() defaultName?: string
  @Input() preventExistingCompanies = false

  @Output() readonly companySelected = new EventEmitter<CompanyMatch>()

  readonly companiesName = new UntypedFormControl('', Validators.minLength(3))

  readonly companies$ = new BehaviorSubject<CompanyMatch[]>([])
  readonly loading$ = new BehaviorSubject<boolean>(false)
  readonly allowNewCompany$ = new BehaviorSubject<boolean>(true)

  region: Region
  hint?: string

  // ------------------------------------------------------

  constructor(
    private readonly companySearchService: CompanySearchService,
    private readonly configuration: Configuration,
    private readonly modalService: ModalService
  ) {
  }

  // ------------------------------------------------------

  ngOnInit() {
    this.companiesName.valueChanges
      .pipe(
        debounceTime(400),
        tap(() => this.companySelected.emit(null))
      )
      .pipe(
        debounceTime(400),
        filter(name => name && this.companiesName.valid),
        distinctUntilChanged(),
        tap(() => { this.loading$.next(true) }),
        switchMap(name => {
          return this.companySearchService.searchCompanies(name as string, { jurisdiction: this.jurisdiction }).pipe(
            map(matches => [ name, matches ])
          )
        }),
        untilDestroyed(this)
      )
      .subscribe(([ name, companies ]: [ string, CompanyMatch[] ]) => {
        this.allowNewCompany$.next(companies.every(c => c.name.toLowerCase() !== name.toLowerCase()))
        this.loading$.next(false)
        this.companies$.next(companies)
      })

    if (this.defaultName) {
      this.companiesName.patchValue(this.defaultName)
    }
  }

  // ------------------------------------------------------

  ngOnChanges(changes) {
    if (changes.jurisdiction?.currentValue) {
      this.region = this.configuration.getRegionByJurisdiction(changes.jurisdiction.currentValue).id
      this.assignSuffixHint()
      this.companiesName.setErrors({}, { emitEvent: false })
      this.companiesName.setValue('')
      this.companies$.next([])
    }
  }

  // ------------------------------------------------------

  private assignSuffixHint(): void {
    const regionTypes = this.configuration.getRegionConfigById(this.region).companyTypes

    if (regionTypes.length >= 2) {
      const exampleSuffixes = regionTypes.slice(0, 1).map(type => CompanyTypeNames[ type ].suffix).join(', ')
      this.hint = $localize`Enter your company name without the ${exampleSuffixes} or other suffix, e.g. NewCo`
    } else {
      this.hint = undefined
    }
  }

  // ------------------------------------------------------

  onCompanySelected(match: CompanyMatch) {
    this.companySelected.emit(match)

    this.companiesName.setErrors({}, { emitEvent: false })
    this.companiesName.updateValueAndValidity({ emitEvent: false })
  }

  // ------------------------------------------------------

  async notifyAdminCompanyDuplicate(match: ILocalCompanyMatch) {
    const res = await this.modalService.show<RequestCompanyAccessDialogComponent, CompanyMatch>(
      RequestCompanyAccessDialogComponent, match, { panelClass: 'sl-narrow-panel' }
    )
    if (res) {
      await this.companySearchService.notifyAdmin(match.company.id)
      this.companySearchService.addNotifiedAdmin(match.company.id)

      const companies = this.companies$.value.map(cm => 'company' in cm && cm.company.id ? { ...cm, canNotify: false } : cm)
      this.companies$.next([ ...companies ])
    }
  }
}
