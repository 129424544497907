import { Event } from './event'
import { EventCategory } from '../../models/category.model'

import type { IEventViewState } from './event'
import type { LoanEvent } from './loan-event'
import { LoanCollection } from '../loan/loan'

// ------------------------------------------------------------

export class RepaymentEvent extends Event implements LoanEvent {
  readonly category = EventCategory.Repayment
  readonly domain = 'repayments'
  readonly domainSingular = 'repayment'

  loans = new LoanCollection()

  // ----------------------------------------------------

  override get safeName() {
    return $localize`Loan Repayment`
  }

  // ----------------------------------------------------

  // Event is approved on creation.
  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    this.approved = responseData.approved
  }

  // ----------------------------------------------------

  override detach() {
    super.detach()

    this.loans.forEach(loan => loan.repayment = null)
    this.loans.clear()
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return undefined
  }

  // ----------------------------------------------------

  override get timelineAmount(): number {
    return this.loans.reduce((total, loan) => total + loan.amount, 0)
  }
}
