import { Injectable } from '@angular/core'

import { of, from } from 'rxjs'
import { map, switchMap, catchError, tap, exhaustMap, withLatestFrom, filter, delay } from 'rxjs/operators'

import { createEffect, Actions, ofType } from '@ngrx/effects'

import { CardService } from '../services/card.service'
import { PaymentsService } from '../services/payments.service'

import {
  LoadCards,
  LoadCardsSuccess,
  LoadCardsError,
  AddNewCard,
  AddNewCardSuccess,
  AddNewCardError,
  SetDefaultCard,
  SetDefaultCardSuccess,
  SetDefaultCardError,
  DeleteCard,
  DeleteCardSuccess,
  DeleteCardError
} from './payments.actions'
import { PlanService } from '@app/payments/services/plan.service'

// ----------------------------------------------------------

@Injectable()
export class PaymentsEffects {

  loadCards$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCards),
    tap(() => {
      this.cardService.clearCache()
      this.cardService.setLoading(true)
    }),
    switchMap(({ companyId }) => {
      return from(this.paymentsService.getCards(companyId))
        .pipe(
          map(cards => LoadCardsSuccess({ cards })),
          catchError(error => of(LoadCardsError({ error })))
        )
    })
  ))

  // ----------------------------------------------------

  loadCardsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCardsSuccess),
    tap(({ cards }) => {
      this.cardService.setLoading(false)
      this.cardService.setLoaded(true)
      this.cardService.addManyToCache(cards)
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  loadCardsError$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCardsError),
    tap(() => {
      this.cardService.setLoading(false)
      this.cardService.setLoaded(false)
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  addNewCard$ = createEffect(() => this.actions$.pipe(
    ofType(AddNewCard),
    exhaustMap(({ companyId, label, cardElement }) => {
      return from(this.planService.addNewCard(companyId, label, cardElement)).pipe(
        map(() => AddNewCardSuccess({ companyId })),
        catchError(error => of(AddNewCardError({ error })))
      )
    })
  ))

  // ----------------------------------------------------

  addNewCardsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AddNewCardSuccess),
    tap(() => this.cardService.setLoading(true)),
    delay(4000),
    map(({ companyId }) => LoadCards({ companyId }))
  ))

  // ----------------------------------------------------

  setDefaultCard$ = createEffect(() => this.actions$.pipe(
    ofType(SetDefaultCard),
    exhaustMap(({ companyId, cardId }) =>
      from(this.paymentsService.setDefaultCard(companyId, cardId))
        .pipe(
          map(() => SetDefaultCardSuccess({ companyId, cardId })),
          catchError(error => of(SetDefaultCardError({ error })))
        )
    )
  ))

  // ----------------------------------------------------

  setDefaultCardSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SetDefaultCardSuccess),
    withLatestFrom(this.cardService.entities$.pipe(
      map(cards => cards.find(card => card.default))
    )),
    tap(([ { cardId }, defaultCard ]) => {
      if (defaultCard) {
        this.cardService.updateManyInCache([
          { id: defaultCard.id, default: false },
          { id: cardId, default: true }
        ])
      } else {
        this.cardService.updateOneInCache({ id: cardId, default: true })
      }
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  deleteCard$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteCard),
    exhaustMap(({ companyId, card }) =>
      from(this.planService.deleteCard(companyId, card))
        .pipe(
          filter(success => success),
          map(() => DeleteCardSuccess({ companyId, cardId: card.id })),
          catchError(error => of(DeleteCardError({ error })))
        )
    )
  ))

  // ----------------------------------------------------

  deleteCardSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteCardSuccess),
    tap(({ cardId }) => {
      this.cardService.removeOneFromCache(cardId)
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  constructor(
    private actions$: Actions,
    private paymentsService: PaymentsService,
    private cardService: CardService,
    private planService: PlanService
  ) {}
}
