import { createAction, union, props } from '@ngrx/store'

import type { Company, ICompanyData, User, BankAccount } from '@libs/models'
import type { IInvoice } from '../models/invoice'

// ----------------------------------------------------

export const LoadCompany = createAction(
  '[Company] Load Company',
  props<{ companyId: string }>()
)

export const LoadCompanySuccess = createAction(
  '[Company] Load Company Success',
  props<{ companyId: string, response: ICompanyData }>()
)

export const LoadCompanyError = createAction(
  '[Company] Load Company Error',
  props<{ companyId: string, error: unknown }>()
)

// ----------------------------------------------------------

export const CompanyCreated = createAction(
  '[Company] Company Created',
  props<{ company: Company, user: User }>()
)

export const CompanyUpdated = createAction(
  '[Company] Company Updated',
  props<{ companyId: string, data: Partial<Company> }>()
)

// ----------------------------------------------------------

export const LoadCompanyInvoices = createAction(
  '[Company] Load Company Invoices',
  props<{ companyId: string }>()
)

export const LoadCompanyInvoicesSuccess = createAction(
  '[Company] Load Company Invoices Success',
  props<{ invoices: IInvoice[] }>()
)

export const LoadCompanyInvoicesError = createAction(
  '[Company] Load Company Invoices Error',
  props<{ error: unknown }>()
)

// ----------------------------------------------------------

export const UpdateCompanyBankAccount = createAction(
  '[Company] Update Company Bank Account',
  props<{ bankAccount: BankAccount, data: Partial<BankAccount> }>()
)

export const UpdateCompanyBankAccountSuccess = createAction(
  '[Company] Update Company Bank Account Success',
  props<{ bankAccount: BankAccount }>()
)

export const UpdateCompanyBankAccountError = createAction(
  '[Company] Update Company Bank Account Error',
  props<{ error: unknown }>()
)

// ----------------------------------------------------------

const actions = union({
  CompanyCreated,
  CompanyUpdated,
  LoadCompany,
  LoadCompanySuccess,
  LoadCompanyError,
  LoadCompanyInvoices,
  LoadCompanyInvoicesSuccess,
  LoadCompanyInvoicesError,
  UpdateCompanyBankAccount,
  UpdateCompanyBankAccountSuccess,
  UpdateCompanyBankAccountError
})

export type CompanyActions = typeof actions
