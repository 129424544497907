<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content
     style="max-width: 500px;">

  <p>{{data.text}}</p>

  <div *ngIf="data.html"
       [innerHTML]="data.html"></div>

</div>

<div mat-dialog-actions
     align="end">

  <ng-container *ngIf="data.cancel !== null">
    <button mat-button [mat-dialog-close]="false">
      {{data.cancel}}
    </button>
  </ng-container>

  <button mat-button [mat-dialog-close]="true"
          color="primary"
          cdkFocusInitial>
    {{data.ok}}
  </button>
</div>
