import { Component, ChangeDetectionStrategy, Input } from '@angular/core'

import type { HasId, INamedEntity } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-named-entity',
  template: `
    <sl-entity [entity]="entity"
               [size]="size"
               [noTooltip]="noTooltip"></sl-entity>
    <span>{{ entity.name }}</span>
  `,
  styles: [ `
    :host {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }
  ` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamedEntityComponent {
  @Input() entity: HasId & INamedEntity
  @Input() size = 40
  @Input() noTooltip = false
}
