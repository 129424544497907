import { Injectable } from '@angular/core'

import type { Observable } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'

import { Query, gql } from 'apollo-angular'

import type {
  BillingPeriod,
  IAccessData,
  IPlanData,
  IUserCompany,
  IUserCompanyAppointmentData,
  IUserData,
  SubscriptionStatus,
} from '@libs/models'

// ----------------------------------------------------------

export interface ICurrentUserQueryCompanyData {
  company: IUserCompany[ 'company' ]
  access: IAccessData | null
  appointment: IUserCompanyAppointmentData | null
  hasOptionAgreement: boolean
  hasConsented: boolean
  pendingDeadlinesCount: number
  subscriptions: {
    plan: IPlanData
    status: SubscriptionStatus
    expires: string
    billingPeriod: BillingPeriod
  }[]
}

export interface ICurrentUserQueryUserData extends IUserData {
  companies: ICurrentUserQueryCompanyData[]
}

export interface ICurrentUserQueryData {
  me: ICurrentUserQueryUserData
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CurrentUserQuery extends Query<ICurrentUserQueryData> {
  override readonly document = gql`
    query me {
      me {
        id
        locale
        firstName
        lastName
        connected
        email
        phone
        sex
        description
        facebook
        twitter
        linkedin
        additionalInfo
        signature
        invited
        picture
        address {
          id
          line1
          line2
          city
          postcode
          country
        }
        companies {
          hasOptionAgreement
          hasConsented
          pendingDeadlinesCount
          company {
            id
            name
            picture
            twitter
            linkedin
            facebook
            metadata
            jurisdiction
            currency
            hasCaptable
            hasCohortPortal
          }
          access {
            id
            accessRoles
          }
          appointment {
            id
            position
            roles
          }
          subscriptions {
            plan {
              id
              features
            }
            status
            expires
            billingPeriod
          }
        }
      }
    }
  `

  // ----------------------------------------------------

  getCurrentUser(): Observable<ICurrentUserQueryUserData> {
    return this.watch({}, {
      fetchPolicy: 'no-cache'
    })
      .valueChanges
      .pipe(
        // logger(`CurrentUserQuery.getCurrentUser$: result`),
        filter(result => !!result.data),
        map(result => result.data.me)
      )
  }
}
