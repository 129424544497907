import { Injectable } from '@angular/core'

import { select, Store } from '@ngrx/store'

import { companiesQuery } from './company.selector'
import { CompanyUpdated, LoadCompanyInvoices, UpdateCompanyBankAccount } from './company.actions'

import type { Company, BankAccount } from '@libs/models'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CompanyFacade {
  companiesLoading$ = this.store.pipe(select(companiesQuery.getCompaniesLoading))
  companiesLoaded$ = this.store.pipe(select(companiesQuery.getCompaniesLoaded))

  companyInvoicesLoading$ = this.store.pipe(select(companiesQuery.getCompanyInvoicesLoading))
  companyInvoices$ = this.store.pipe(select(companiesQuery.getCompanyInvoices))

  // ----------------------------------------------------

  companyUpdated(
    company: Company,
    data: Partial<Company> = {}
  ) {
    this.store.dispatch(CompanyUpdated({
      companyId: company.id,
      data
    }))
  }

  // ----------------------------------------------------

  getCompanyInvoices(companyId: string) {
    this.store.dispatch(LoadCompanyInvoices({
      companyId
    }))
  }

  // ----------------------------------------------------

  updateCompanyBankAccount(bankAccount: BankAccount, data: Partial<BankAccount>) {
    this.store.dispatch(UpdateCompanyBankAccount({
      bankAccount,
      data
    }))
  }

  // ----------------------------------------------------

  constructor(
    private store: Store
  ) {}
}
