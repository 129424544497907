import { Optional, Pipe, PipeTransform } from '@angular/core'
import { formatPercent } from '@angular/common'

import { LocaleService } from '@libs/services'

// ------------------------------------------------------

@Pipe({
  name: 'slPercent'
})
export class SlPercentPipe implements PipeTransform {

  constructor(@Optional() private localeService?: LocaleService) {}

  // ----------------------------------------------------

  transform(value: number, digitsInfo = '1.2-2'): string {
    const locale: string = this.localeService
      ? this.localeService.locale
      : 'en'

    return formatPercent(value, locale, digitsInfo)
  }

}
