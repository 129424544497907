import { Injectable } from '@angular/core'

import type { Observable } from 'rxjs'
import { from, of, combineLatest } from 'rxjs'
import { map, switchMap, skipWhile } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

import { layoutQuery } from './layout.selectors'
import {
  BackdropClicked,
  MainNavToggle,
  SideNavOpen,
  SideNavClose,
  ChangeCurrentCompany,
  SetBreadcrumbs,
} from './layout.actions'

import type { IUserCompany } from '@libs/models'
import { Company, UserCompanyAccess } from '@libs/models'
import type { IBreadcrumbItem } from '../models/breadcrumbs'

import { GlobalsService } from '@app/core/services/globals/globals.service'
import { CompanyService } from '@app/companies/services/company/company.service'
import { UsersFacade } from '@app/users/+state/users.facade'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class LayoutFacade {

  isXSmall$: Observable<boolean> = this.breakpointObserver
    .observe([ Breakpoints.XSmall ])
    .pipe(map(s => s.matches))

  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([ Breakpoints.Small ])
    .pipe(map(s => s.matches))

  isMedium$: Observable<boolean> = this.breakpointObserver
    .observe([ Breakpoints.Medium ])
    .pipe(map(s => s.matches))

  isLarge$: Observable<boolean> = this.breakpointObserver
    .observe([ Breakpoints.Large ])
    .pipe(map(s => s.matches))

  isXLarge$: Observable<boolean> = this.breakpointObserver
    .observe([ Breakpoints.XLarge ])
    .pipe(map(s => s.matches))

  // ----------------------------------------------------

  isSmallOrLess$: Observable<boolean> = combineLatest([ this.isXSmall$, this.isSmall$ ])
    .pipe(map(([ a, b ]) => a || b))

  isMediumOrLess$: Observable<boolean> = combineLatest([ this.isSmallOrLess$, this.isMedium$ ])
    .pipe(map(([ a, b ]) => a || b))

  isLargeOrLess$: Observable<boolean> = combineLatest([ this.isMediumOrLess$, this.isLarge$ ])
    .pipe(map(([ a, b ]) => a || b))

  // ----------------------------------------------------

  isLargeOrMore$: Observable<boolean> = combineLatest([ this.isLarge$, this.isXLarge$ ])
    .pipe(map(([ a, b ]) => a || b))

  isMediumOrMore$: Observable<boolean> = combineLatest([ this.isMedium$, this.isLargeOrMore$ ])
    .pipe(map(([ a, b ]) => a || b))

  isSmallOrMore$: Observable<boolean> = combineLatest([ this.isSmall$, this.isMediumOrMore$ ])
    .pipe(map(([ a, b ]) => a || b))

  // ----------------------------------------------------

  isMainNavCollapsible$ = combineLatest([
    this.isMediumOrLess$,
    this.store.pipe(select(layoutQuery.getMainNavCollapsible))
  ]).pipe(map(([ a, b ]) => a || b))

  // ----------------------------------------------------

  breadcrumbs$ = this.store.pipe(select(layoutQuery.getBreadcrumbs))

  tabLinks$ = this.store.pipe(select(layoutQuery.getTabLinks))

  // ----------------------------------------------------

  mainNavOpen$ = combineLatest([
    this.store.pipe(
      select(layoutQuery.getMainNavOpen)
    ),
    this.isMainNavCollapsible$
  ]).pipe(
    // logger(`LayoutFacade.mainNavOpen$`),
    map(([ mainNavOpen, isMainNavCollapsible ]) => isMainNavCollapsible ? mainNavOpen : true)
  )

  mainNavMode$ = this.isMainNavCollapsible$.pipe(
    map(isMainNavCollapsible => isMainNavCollapsible ? 'over' : 'side')
  )

  sideNavOpen$ = this.store.pipe(select(layoutQuery.getSideNavOpen))

  getLeftNavPanelOpen$ = this.store.pipe(select(layoutQuery.getLeftNavPanelOpen))

  isNavigating$ = this.store.pipe(select(layoutQuery.getIsNavigating))

  currentCompanyId$ = this.store.pipe(select(layoutQuery.getCurrentCompanyId))

  // ----------------------------------------------------

  currentUserCompany$ = combineLatest([
    this.currentCompanyId$,
    this.userFacade.userCompanies$
  ]).pipe(
    // logger(`LayoutFacade.currentUserCompany$`),
    skipWhile(([ companyId, _ ]) => !companyId),
    map(([ companyId, userCompanies ]) => {
      let userCompany: IUserCompany

      if (typeof companyId === 'string') {
        userCompany = userCompanies.find(({ company }) => company.id === companyId)
      }

      if (!userCompany) {
        userCompany = userCompanies.find(({ access }) => access !== UserCompanyAccess.Documents)
      }

      if (!userCompany) {
        userCompany = userCompanies[ 0 ]
      }

      return userCompany
    }),
    // logger(`LayoutFacade.currentUserCompany$: userCompany`)
  )

  // ----------------------------------------------------

  currentCompany$: Observable<Company | undefined> = combineLatest([
    this.currentCompanyId$,
    this.currentUserCompany$,
  ]).pipe(
    // logger(`LayoutFacade.currentCompany$`),
    skipWhile(([ currentCompanyId ]) => !currentCompanyId),
    switchMap(([ _, userCompany ]) => {
      if (!userCompany) {
        return of(undefined)
      }

      // this.store.dispatch(StoreCurrentCompanyId({ companyId: userCompany.company.id }))

      if (userCompany.access !== UserCompanyAccess.Documents) {
        return from(this.companyService.getCompany(userCompany.company.id))
      }

      const company = userCompany.company instanceof Company
        ? userCompany.company
        : this.globalsService.buildUserCompany({
          ...userCompany
        })

      return of(company)
    }),
    // logger(`LayoutFacade.currentCompany$: company`)
  )

  // ----------------------------------------------------

  currentCompanyAccess$: Observable<UserCompanyAccess> = this.currentUserCompany$.pipe(
    skipWhile(userCompany => !userCompany),
    map(data => data?.access)
  )

  // ----------------------------------------------------

  currentCompanyFullAccess$: Observable<boolean> = this.currentCompanyAccess$.pipe(
    map(access => access === UserCompanyAccess.Admin
      || access === UserCompanyAccess.StaffWrite
      || access === UserCompanyAccess.StaffRead)
  )

  // ----------------------------------------------------

  backdropClicked() {
    this.store.dispatch(BackdropClicked())
  }

  // ----------------------------------------------------

  mainNavToggle() {
    this.store.dispatch(MainNavToggle())
  }

  // ----------------------------------------------------

  sideNavOpen() {
    this.store.dispatch(SideNavOpen())
  }

  sideNavClose() {
    this.store.dispatch(SideNavClose())
  }

  // ----------------------------------------------------

  setBreadcrumbs(
    breadcrumbs: IBreadcrumbItem[]
  ) {
    this.store.dispatch(SetBreadcrumbs({ breadcrumbs }))
  }

  // ----------------------------------------------------

  changeCurrentCompany(companyId: string, subRoute: Array<string | number> = []) {
    this.store.dispatch(
      ChangeCurrentCompany({ companyId, subRoute })
    )
  }

  // ----------------------------------------------------

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private companyService: CompanyService,
    private userFacade: UsersFacade,
    private globalsService: GlobalsService
  ) {}

}
