import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import type { Company, User } from '@libs/models'

@Component({
  selector: 'sl-company-subscription-dialog',
  templateUrl: './company-subscription-dialog.component.html',
  styleUrls: [ './company-subscription-dialog.component.scss' ]
})
export class CompanySubscriptionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CompanySubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company, user: User }
  ) { }

}
