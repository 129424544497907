import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-left-nav-external-link',
  templateUrl: './left-nav-external-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftNavExternalLinkComponent {
  @Input() href: string
  @Input() openInNewTab = true

  @Input() label: string
  @Input() image: string
}
