export const AuthConfig = {
  DEV: {
    fusionAuth: {
      domain: 'https://auth.seedlegals.com',
      client_id: 'ecea69a1-37d8-4d23-afd5-ef63c674c067',
      tenantId: '2b109ed3-8220-4532-b37b-fd6428d5a1ae',
      scope: 'openid',
      response_type: 'token id_token',
    }
  },
  DEMO: {
    fusionAuth: {
      domain: 'https://auth.seedlegals.com',
      client_id: 'aa5fa2e2-bbca-4d28-aa39-06a6d9bdc8f4',
      tenantId: '2b109ed3-8220-4532-b37b-fd6428d5a1ae',
      scope: 'openid',
      response_type: 'token id_token',
    }
  },
  INT: {
    fusionAuth: {
      domain: 'https://auth.seedlegals.com',
      client_id: 'de423180-cff7-498d-9b26-e9035355bd0d',
      tenantId: '6586e0bc-1d4b-ee88-2f82-7cbaa0189308',
      scope: 'openid',
      response_type: 'token id_token',
    }
  },
  PROD: {
    fusionAuth: {
      domain: 'https://auth.seedlegals.com',
      client_id: 'b637d0cd-ed28-4c89-b25d-ab7bac0c1a9d',
      tenantId: '6586e0bc-1d4b-ee88-2f82-7cbaa0189308',
      scope: 'openid',
      response_type: 'token id_token',
    }
  }
}
