import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

import { User } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: [ './user-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent {
  @Input() user: User
}
