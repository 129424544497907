import { Injectable } from '@angular/core'

import { sum } from 'ramda'

import type { PricingModel } from '@libs/models'
import { isFixed } from '@libs/models'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class PriceCalculatorService {

  getPrice(
    pricingModel: PricingModel | undefined,
    value: number,
  ): number | null {
    if (!pricingModel) {
      return null
    } else if (isFixed(pricingModel)) {
      return pricingModel.price
    } else {
      const tierPrices = pricingModel.tiers.map(tier => {
        if (value <= tier.lowerThreshold) {
          return 0
        }

        const tierValue = Math.min(value, tier.upTo ?? Infinity) - tier.lowerThreshold

        return tierValue * tier.amount
      })

      return Math.max(pricingModel.minPrice, sum(tierPrices))
    }
  }

}
