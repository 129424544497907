import { Injectable } from '@angular/core'
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router'

import { BehaviorSubject } from 'rxjs'
import { filter, map, mapTo } from 'rxjs/operators'

// ----------------------------------------------------------

@Injectable()
export class RouterHelperService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  // ----------------------------------------------------

  getResolverSubjectForMatcher<T>(
    matcher: (data: Data) => T
  ): BehaviorSubject<T> {
    const matchedSubject$ = new BehaviorSubject(this.getStartValue(matcher))

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        mapTo(this.getFirstMatchingRoute(matcher)),
        filter(route => !!matcher(route.snapshot.data)),
        map(route => matcher(route.snapshot.data))
      )
      .subscribe(matchedSubject$)

    return matchedSubject$
  }

  // ----------------------------------------------------

  getFirstMatchingRoute<T>(matcher: (data: Data) => T): ActivatedRoute {
    let route = this.activatedRoute

    while (!matcher(route.snapshot.data) && route.firstChild != null) {
      route = route.firstChild
    }

    return route
  }

  // ----------------------------------------------------

  getStartValue<T>(matcher: (data: Data) => T): T {
    return matcher(this.getFirstMatchingRoute(matcher).snapshot.data)
  }
}
