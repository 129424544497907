import { BehaviorSubject } from 'rxjs'

import type { Model } from './model'

import { getComparator, Comparator, Mapper, Conditional, Reducer, Transformer } from '@libs/utils'

import { DebugOptions } from '@env/environment'

// ------------------------------------------------------------

export class Collection<T extends Model> {
  static objid = 1

  protected comparatorFunc: Comparator<T>

  protected _data: Map<string, T> = new Map()

  private _items$ = new BehaviorSubject<T[]>([])
  public readonly items$ = this._items$.asObservable()

  readonly _objid = Collection.objid++

  // ----------------------------------------------------

  constructor(
    public comparator: Comparator<T> | string | string[] = 'id',
  ) {
    this.comparatorFunc = getComparator(comparator)

    this.log('init', {
      comparator,
      comparatorFunc: this.comparatorFunc,
    })
  }

  // ----------------------------------------------------

  get _prefix(): string {
    return `${this.constructor.name}<#${this._objid}>`
  }

  // ----------------------------------------------------

  log(heading: string, body?: unknown, ...bodyArgs: unknown[]) {
    if (!DebugOptions.logCollectionChanges) {
      return
    }

    console.group(`${this._prefix} (length: ${this.length}): ${heading}`)

    if (body) {
      try {
        if (typeof body === 'function') {
          body.apply(this, bodyArgs)
        } else if (typeof body === 'string') {
          console.log(body, ...bodyArgs)
        } else {
          console.dir(body)
        }
      } catch (ex) {
        console.error(ex)
      }
    }

    console.groupEnd()
  }

  // ----------------------------------------------------

  * [ Symbol.iterator ](): Iterable<T> {
    for (const v of this.items()) {
      yield v
    }
  }

  // ----------------------------------------------------

  items(): T[] {
    return [ ...this._data.values() ].sort(this.comparatorFunc)
  }

  // ----------------------------------------------------

  item(idx: number): T | undefined {
    return idx >= 0 && idx < this._data.size
      ? this.items()[ idx ]
      : undefined
  }

  // ----------------------------------------------------

  get length(): number {
    return this._data.size
  }

  // ----------------------------------------------------

  next() {
    this._items$.next(this.items())
  }
  // ----------------------------------------------------

  clear(): this {
    this.log('clear')

    this._data.clear()
    this._items$.next([])

    return this
  }

  // ----------------------------------------------------

  // Does an object with a key matching that of obj exist in this collection?
  has(obj: T): boolean {
    return this._data.has(obj.id)
  }

  // ----------------------------------------------------

  get(key: string): T | undefined {
    return this._data.get(key)
  }

  // ----------------------------------------------------

  getmany(keys: string[]): T[] {
    const ks = new Set(keys)

    return this.filter(entity => ks.has(entity.id))
  }

  // ----------------------------------------------------

  add(obj: T, notify = true): this {
    const key = obj.id

    if (key === null) {
      throw new Error(`Cannot add object with null key!`)
    }

    this.log(`add - key = ${key}, has_key = ${this._data.has(key)}`, { obj, key })

    if (this._data.has(key)) {
      return this
    }

    this._data.set(key, obj)

    if (notify) {
      this.next()
    }

    return this
  }

  // ----------------------------------------------------

  addAll(objs: Collection<T> | T[]): this {
    objs.forEach(obj => this.add(obj, false))

    this.next()

    return this
  }

  // ----------------------------------------------------

  remove(obj: T): this {
    const key = obj.id

    if (this._data.has(key)) {
      this.log('remove', { obj, key })

      this._data.delete(key)
      this.next()
    }

    return this
  }

  // ----------------------------------------------------

  updateFrom(other: Collection<T> | T[]): this {
    this._data.clear()
    return this.addAll(other)
  }

  // ----------------------------------------------------

  difference(other: Collection<T>): T[] {
    return this.items().filter(v => !other.has(v))
  }

  // ----------------------------------------------------

  forEach(func: (v: T) => void): void {
    this.items().forEach(func)
  }

  // ----------------------------------------------------

  map<U>(func: Mapper<T, U>): U[] {
    return this.items().map(func)
  }

  // ----------------------------------------------------

  flatMap<U>(func: Mapper<T, U[]>): U[] {
    return this.map(func).reduce((out, cur) => [ ...out, ...cur ], [])
  }

  // ----------------------------------------------------

  filter(func: Conditional<T>): T[] {
    return this.items().filter(func)
  }

  // ----------------------------------------------------

  find(func: Conditional<T>): T | undefined {
    return this.items().find(func)
  }

  // ----------------------------------------------------

  findIndex(func: Conditional<T>): number {
    return this.items().findIndex(func)
  }

  // ----------------------------------------------------

  some(func: Conditional<T>): boolean {
    return this.items().some(func)
  }

  // ----------------------------------------------------

  every(func: Conditional<T>): boolean {
    return this.items().every(func)
  }

  // ----------------------------------------------------

  reduce<A>(func: Reducer<T, A>, initial?: A): A {
    return this.items().reduce(func, initial)
  }

  // ----------------------------------------------------

  transform<A>(func: Transformer<T, A>, initial?: A): A {
    return this.items().reduce((out, cur) => {
      func(out, cur)
      return out
    }, initial)
  }

}
