<form [formGroup]="controls">
  <ng-container *ngIf="user?.connected">
    <sl-user-info [user]="user"></sl-user-info>
  </ng-container>

  <div *ngIf="!user?.connected"
       fxLayout="column">

    <mat-form-field>
      <mat-label i18n>Email</mat-label>

      <input (focusout)="searchForUser()"
             matInput
             type="email"
             formControlName="email">
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label i18n>First name</mat-label>

      <input matInput
             formControlName="firstName"
             [required]="required">
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label i18n>Last name</mat-label>

      <input matInput
             formControlName="lastName"
             [required]="required">

      <mat-hint *ngIf="requestLegalName"
                i18n>Enter their full legal name, no nicknames or shortened versions</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="showAddress">
      <h2 class="text-bold mt-0" i18n>Address</h2>

      <sl-address-editor formControlName="address"
                         [inSidenav]="true"
                         [defaultCountry]="user?.address?.country || defaultCountry"
                         [required]="requireAddress"></sl-address-editor>
    </ng-container>

  </div>
</form>
