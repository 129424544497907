import { Injectable, Inject } from '@angular/core'

import { of } from 'rxjs'
import { filter, map, tap, withLatestFrom, switchMap, catchError, mergeMap, exhaustMap } from 'rxjs/operators'

import { select, Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { omit } from 'ramda'

import { IUserData, IUserCreateData, getFirstSupportedLanguageFromBrowser, Sex } from '@libs/models'
import type { UserInfo } from '@app/auth/models/auth.model'
import type { WhoAmIData } from '@app/auth/models/whoami-data.model'

import { LocaleService } from '@libs/services'
import { RestApi, BackendService, Api } from '@libs/backend'
import { AuthService } from '@app/auth/services/auth.service'
import { GlobalsService } from '@app/core/services/globals/globals.service'
import { UserService } from '../services/user.service'
import { CurrentUserQuery, ICurrentUserQueryUserData } from '../services/graphql/current-user-query'

import {
  LoadCurrentUser,
  LoadCurrentUserSuccess,
  LoadCurrentUserComplete,
  CreateNewUser,
  CreateNewUserSuccess,
  CreateNewUserError,
  GetWhoAmIForNewUser,
  UserUpdated,
  LoadCurrentUserError
} from './users.actions'

import { selectConfigurationLoaded } from '@app/core/+state/core.selectors'
import { Logout, UpdateWhoAmI } from '@app/auth/+state/auth.actions'
import { selectWhoAmI } from '@app/auth/+state/auth.selectors'

import { delayUntilTruthy } from '@libs/utils'

// ----------------------------------------------------------

const companiesOmitter = omit([
  'companies'
])

// ----------------------------------------------------------

@Injectable()
export class UsersEffects {

  updateLocale$ = createEffect(() => this.actions$.pipe(
    ofType(UserUpdated),
    withLatestFrom(this.userService.currentUser$),
    filter(([ user, currentUser ]) => user.id === currentUser.id),
    map(([ _, currentUser ]) => currentUser),
    withLatestFrom(this.store.pipe(select(selectWhoAmI))),
    filter(([ user, whoAmI ]) => user.locale !== whoAmI.locale),
    map(([ user, whoAmI ]) => {
      whoAmI.locale = user.locale
      return UpdateWhoAmI({ whoAmI })
    })
  ))

  // ----------------------------------------------------

  createNewUser$ = createEffect(() => this.actions$.pipe(
    ofType(CreateNewUser),
    // eslint-disable-next-line rxjs/no-unsafe-switchmap
    switchMap(() => this.authService.getUserInfo()),
    exhaustMap((profile: UserInfo) => {
      const payload: IUserCreateData = {
        email: profile.email,
        firstName: profile.given_name || '',
        lastName: profile.family_name || '',
        phone: profile.phone_number ?? null,
        address: {
          line1: '',
          line2: '',
          city: '',
          postcode: '',
          country: 'GB'
        },
        sex: Sex.NotKnown,
        connected: false,
      }

      if (profile.picture && profile.picture.length < 100) {
        payload.picture = profile.picture
      }

      return this.restApi
        .all('users')
        .post<IUserData>(payload)
        .pipe(
          mergeMap(user => [ GetWhoAmIForNewUser(), CreateNewUserSuccess({ user }) ]),
          catchError(error => of(CreateNewUserError({ error })))
        )
    })
  ))

  // ----------------------------------------------------

  getWhoAmIForNewUser$ = createEffect(() => this.actions$.pipe(
    ofType(GetWhoAmIForNewUser),
    switchMap(() => {
      return this.api
        .one('whoAmI')
        .get<WhoAmIData>()
        .pipe(
          map((whoAmI: WhoAmIData) => ({
            locale: getFirstSupportedLanguageFromBrowser(),
            ...whoAmI
          }))
        )
    }),
    map(whoAmI => UpdateWhoAmI({ whoAmI }))
  ))

  // ----------------------------------------------------

  createNewUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CreateNewUserSuccess),
    delayUntilTruthy(this.store.pipe(select(selectWhoAmI))),
    map(({ user }) => LoadCurrentUserSuccess({ user, companies: [] }))
  ))

  // ----------------------------------------------------

  loadCurrentUser$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCurrentUser),
    switchMap(() => this.currentUserQuery.getCurrentUser().pipe(
      map((currentUser: ICurrentUserQueryUserData) => {
        return LoadCurrentUserSuccess({
          user: companiesOmitter(currentUser) as IUserData,
          companies: currentUser.companies
        })
      }),
      catchError(error => of(LoadCurrentUserError({ error }))),
    )),
  ))

  // ----------------------------------------------------

  loadCurrentUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCurrentUserSuccess),
    // logger(`UsersEffects.loadCurrentUserSuccess$`),
    delayUntilTruthy(this.store.pipe(select(selectConfigurationLoaded))),
    map(({ user: userData, companies }) => {
      const user = this.globalsService.buildUser(userData)

      // TODO: Sort out the ridiculoius duplication of code relating to
      // initialising and storing user companies and their access rules,
      // along with how they update and how they interact with the UX.
      for (const { access, appointment, company: companyData, subscriptions } of companies) {
        // Ignore companies that we can view at the "document" level only.
        if (!access && !appointment) {
          continue
        }

        const company = this.globalsService.buildCompany({
          ...companyData,
          subscriptions: subscriptions.reduce((out, sub) => {
            out[ sub.plan.id ] = {
              plan: sub.plan,
              expires: sub.expires,
              status: sub.status,
              billingPeriod: sub.billingPeriod
            }

            return out
          }, {})
        })

        if (access) {
          this.globalsService.buildAccess(company, user, access)
        }

        if (appointment) {
          this.globalsService.buildAppointment(company, user, appointment)
        }
      }

      return user
    }),
    withLatestFrom(this.store.pipe(select(selectWhoAmI))),
    tap(([ user, whoAmI ]) => {
      user.role = whoAmI?.role

      user.utmParams = this.userService.getUtmParams()

      this.userService.currentUser = user
    }),
    tap(([ user, whoAmI ]) => {
      if (user.locale) {
        this.localeService.setLocale(user.locale)
      } else {
        this.localeService.setLocale(whoAmI.locale)
      }
    }),
    // logger(`UsersEffects.loadCurrentUserSuccess$`, 'user'),
    map(([ currentUser, _ ]) => LoadCurrentUserComplete({ currentUser }))
  ))

  // ---------------------------------------------------

  loadCurrentUserError$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCurrentUserError),
    map(() => Logout())
  ))

  // ---------------------------------------------------

  constructor(
    private actions$: Actions,
    private store: Store,
    private authService: AuthService,
    private currentUserQuery: CurrentUserQuery,
    private userService: UserService,
    private localeService: LocaleService,
    private globalsService: GlobalsService,
    @Inject(RestApi) private restApi: BackendService,
    @Inject(Api) private api: BackendService
  ) {}

}
