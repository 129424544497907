export type CardBrand =
  | 'american express'
  | 'mastercard'
  | 'visa'

// ----------------------------------------------------------

export interface CardSetupIntent {
  clientSecret: string
}

// ----------------------------------------------------------

export interface CardDetails {
  id: string
  default: boolean
  brand: CardBrand
  expMonth: number
  expYear: number
  last4: string
}

// ----------------------------------------------------------

export const CardBrands: Record<CardBrand, { image: string }> = {
  visa: {
    image: '/assets/images/app/cards/visa.svg'
  },
  mastercard: {
    image: '/assets/images/app/cards/mastercard.svg'
  },
  'american express': {
    image: '/assets/images/app/cards/amex.svg'
  }
}
