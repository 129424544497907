import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject, forwardRef, Optional } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'

import { STEPPER_GLOBAL_OPTIONS, StepperOptions } from '@angular/cdk/stepper'

import { Company, type EntityType, type User } from '@libs/models'
import type { CompanyMatch } from '../../models/company-match'
import type { IInvestorEntity } from '../../models/investor-sidenav'

import { CompanyService } from '@app/companies/services/company/company.service'

import { SidenavStepComponent, SidenavStepWithSubStepperComponent, SidenavStepperComponent } from '../sidenav-stepper/sidenav-stepper.component'

// ----------------------------------------------------------

interface IDefaultInvestorDetails {
  email: string
  firstName: string
  lastName: string
  companyName?: string
}

@Component({
  selector: 'sl-investor-select-steps',
  templateUrl: './investor-select-steps.component.html',
  providers: [
    { provide: SidenavStepComponent, useExisting: InvestorSelectStepsComponent }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorSelectStepsComponent extends SidenavStepWithSubStepperComponent {
  @Input() company: Company
  @Input() entities: IInvestorEntity[] = []
  @Input() title = $localize`Add investor`
  @Input() searchLabel = $localize`Search existing`
  @Input() addNewLabel = $localize`Add new investor`
  @Input() canAddNew = false

  /** Default values that are used to populate form fields. */
  @Input() defaultDetails?: IDefaultInvestorDetails

  /** Enable to skip selecting an existing entity and show the form steps. */
  @Input() skipEntitySelector = false

  @Output() entitySelected = new EventEmitter<IInvestorEntity>()

  entityType?: EntityType
  newCompany: Company

  userCtrl = this.fb.control(null, Validators.required)
  companyCtrl = this.fb.control(null, Validators.required)
  contactsCtrl = this.fb.control(0, Validators.min(1))

  // ----------------------------------------------------

  constructor(
    private companyService: CompanyService,
    private fb: UntypedFormBuilder,
    @Inject(forwardRef(() => SidenavStepperComponent)) override readonly parentStepper: SidenavStepperComponent,
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(parentStepper, stepperOptions)
  }

  // ----------------------------------------------------

  async onEntitySelected(entity: IInvestorEntity) {
    this.completed = true

    // _log(`InvestorSelectStepsComponent.onEntitySelected(entity)`, { ...entity }, this)

    if (!entity.investor) {
      entity.investor = await this.companyService.addInvestorForEntity(this.company, entity.entity)
    }

    this.entitySelected.emit(entity)

    this.next()
  }

  // ----------------------------------------------------

  onEntityTypeClicked(subStepper: SidenavStepperComponent, entityType: EntityType) {
    this.entityType = entityType

    // _log(`InvestorSelectStepsComponent.onEntityTypeClicked(entityType = "${entityType}")`, subStepper, [ ...subStepper.steps ], this)

    // Wait for template to have updated after entityType is set.
    setTimeout(() => {
      subStepper.next()
    }, 0)
  }

  // ----------------------------------------------------

  onEntitySaved(entity: User | Company) {
    // _log(`InvestorSelectStepsComponent.onEntitySaved(entity)`, entity, this)

    this.onEntitySelected({ entity })
  }

  // ----------------------------------------------------

  onCompanySelected(match: CompanyMatch) {
    // _log(`InvestorSelectStepsComponent.onCompanySelected(match)`, match, this)

    this.companyCtrl.setValue(match)
  }

  // ----------------------------------------------------

  onContactAdded() {
    this.contactsCtrl.setValue(this.contactsCtrl.value + 1)
  }

  // ----------------------------------------------------

  onCompanySaved(subStepper: SidenavStepperComponent, newCompany: Company) {
    const companyMatch: CompanyMatch = this.companyCtrl.value

    if (companyMatch.source === 'local') {
      this.onEntitySaved(newCompany)
    } else {
      this.newCompany = newCompany
      subStepper.next()
    }
  }

}
