import type { Company } from '../company'
import type { Round } from './round'
import type { AntiDilution, Currency, DocumentTypeId, OptionType } from '../../models'
import { EventCategory, InvestmentScheme, OptionTypes } from '../../models'
import { Jurisdiction } from '../../models/jurisdiction.model'
import { Event, type IEventViewState } from './event'
import { type Document } from '../documents'
import { type ApiFieldSpec } from '../model'
import {
  AOrdinaryShareClassSpec,
  APreferredShareClassSpec,
  OrdinaryShareClassSpec,
  ShareClassSpecification
} from '../stock/share-class'
import { type Entity } from '..'

// --------------------------------------------------------

export class ProposalEvent extends Event<ProposalAnswers> {
  readonly domain = 'proposals'
  readonly category = EventCategory.Proposal

  founderCompany: Company
  round: Round
  investmentAmount: number
  target: number
  valuation: number
  roundCompletionDate: string
  investmentScheme: InvestmentScheme
  optionType: OptionType
  optionOwnership: number | null

  // ------------------------------------------------------

  constructor({
    founderCompany = null,
    round = null,
    investmentAmount = 0,
    target = 0,
    valuation = 0,
    roundCompletionDate = null,
    investmentScheme = InvestmentScheme.Standard,
    optionType = OptionTypes.None,
    optionOwnership = null,
    ...data
  }) {
    super({
      founderCompany,
      round,
      investmentAmount,
      target,
      valuation,
      roundCompletionDate,
      investmentScheme,
      optionType,
      optionOwnership,
      ...data
    })
  }

  // ----------------------------------------------------

  override get safeName(): string {
    return $localize`Proposal - ${this.founderCompany.name}`
  }

  // ------------------------------------------------------

  override get scopeField() {
    return 'proposal'
  }

  // ------------------------------------------------------

  get entity(): Entity {
    return this.company ?? this.user
  }

  // ------------------------------------------------------

  get document(): Document {
    return this.documents.getByType(this.documentTypeId)
  }

  // ------------------------------------------------------

  get documentTypeId(): DocumentTypeId {
    switch (this.founderCompany.jurisdiction) {
      case Jurisdiction.Singapore:
        return 'SGPOTS'
      case Jurisdiction.HongKong:
        return 'HKPOTS'
      case Jurisdiction.France:
        return 'GRPOTS'
      default:
        return 'POTS'
    }
  }

  // ------------------------------------------------------

  override get locale(): Currency {
    return this.founderCompany.locale
  }


  // ----------------------------------------------------

  override attach() {
    this.founderCompany.investorProposals.add(this)
    this.founderCompany.events.add(this)

    if (this.company) {
      this.company[ this.field ].add(this)
      this.company.events.add(this)
    }

    if (this.round) {
      this.round[ this.field ].add(this)
    }
  }

  // ----------------------------------------------------

  override detach() {
    this.founderCompany.investorProposals.remove(this)
    this.founderCompany.events.remove(this)

    if (this.company) {
      this.company[ this.field ].remove(this)
      this.company.events.remove(this)
    }

    if (this.round) {
      this.round[ this.field ].remove(this)
    }
  }

  // ----------------------------------------------------

  public getShareClassSpec(): ShareClassSpecification | null {
    if (!this.answers.shareClass?.preset) {
      return null
    }

    switch (this.answers.shareClass.preset) {
      case 'ordinary':
        return OrdinaryShareClassSpec

      case 'investorOrdinary':
        return AOrdinaryShareClassSpec

      case 'investorPreferred':
        return APreferredShareClassSpec

      case 'custom':
        return new ShareClassSpecification({
          preferred: this.answers.shareClass.preferred,
          liquidation: this.answers.shareClass.liquidation,
          multiplier: this.answers.shareClass.liquidation
            ? this.answers.shareClass.multiplier
            : 1,
          participating: this.answers.shareClass.liquidation
            ? this.answers.shareClass.participating
            : false,
          antiDilution: this.answers.shareClass.antiDilution
        })
    }
  }

  // ------------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'founderCompany', include: 'create' },
      'round',
      'name',
      'investmentAmount',
      'target',
      'valuation',
      'roundCompletionDate',
      'investmentScheme',
      'optionType',
      'optionOwnership',
      'user'
    ]
  }

  // ------------------------------------------------------

  getViewState(): IEventViewState {
    const state = this.company ?
      [ '/companies', this.company.id, 'deal-manager', 'proposals', this.id ]:
      [ '/individual', 'deal-manager', 'proposals', this.id ]
    return { state }
  }

}

// --------------------------------------------------------

export interface ProposalAnswers {
  shareClass: {
    preset: 'ordinary' | 'investorOrdinary' | 'investorPreferred' | 'custom'
    preferred?: boolean
    liquidation?: boolean
    multiplier?: number
    participating?: boolean
    antiDilution?: AntiDilution
  }
}
