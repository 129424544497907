import { concat, ifElse, mergeWith, nthArg, omit, pipe, uniq } from 'ramda'

// ------------------------------------------------------------

// mergeFn(current, newItem) - return newItem if current doesn't exist, else
// merge newItem into current, merging array properties through concatenation
// and discarding non-unique array items.
const mergeFn = mergeWith(
  ifElse(Array.isArray, pipe<unknown[], unknown, unknown>(concat, uniq), nthArg(1))
)

// ------------------------------------------------------------

type QueryType = SimpleType | QueryType[] | QueryObject | PlainObject

type SimpleType = string | number | null | boolean

interface QueryObject {
  __typename: string
  id: string
  [ prop: string ]: QueryType
}

interface PlainObject {
  [ prop: string ]: QueryType
}

interface TypeAndId {
  typeName: string
  outputTypeName: string
  id: string
}

class ExcludeObjectError extends Error {}

// ------------------------------------------------------------

export interface TypeNormalisationOptions {

  /** The `__typename` to match instances against */
  typeName: string

  /** If false instances of this type are normalised as plain objects */
  normalise?: boolean

  /** Override the type name instances are stored under */
  outputTypeName?: string

  /** Exclude instances of this type from being normalised or saved */
  exclude?: (src: object, parent?: TypeAndId) => boolean

  /** Transform instances of this type before normalisation */
  mapper?: (src: object, parent?: TypeAndId) => object

  /** Fields to skip normalisation for */
  plainFields?: string[]

}

// ------------------------------------------------------------

export interface NormaliserOptions {
  /** Key containing object IDs (default: 'id') */
  idKey: string

  /** Key containing type name (default: '__typename') */
  typeKey: string

  /** Whether the output returns each normalised type as an array or as an object with item ID: item key-value pairs (default: 'array') */
  itemOutput: 'array' | 'object'

  /** Whether the type key field is omitted on normalised objects (default: true) */
  omitTypes: boolean

  /** Whether by default objects are normalised by type (default: true) */
  normalise: boolean

  /** Normalisation options for specific types (default: []) */
  types: TypeNormalisationOptions[]
}

// ------------------------------------------------------------

const defaultOptions: NormaliserOptions = {
  idKey: 'id',
  typeKey: '__typename',
  itemOutput: 'array',
  omitTypes: true,
  normalise: true,
  types: []
}

// ------------------------------------------------------------

export class GraphQLNormaliser {

  options: NormaliserOptions

  types: Map<string, TypeNormalisationOptions>

  items = new Map<string, Map<string, object>>()

  // ----------------------------------------------------

  constructor(
    options?: Partial<NormaliserOptions>
  ) {
    this.options = { ...defaultOptions, ...options }

    this.types = this.options.types.reduce((out, item) => out.set(item.typeName, item), new Map())
  }

  // ----------------------------------------------------

  normalise(
    source: object | QueryType[] | SimpleType
  ): { data: QueryType, types: Record<string, object[]> | Record<string, object> } {
    this.items.clear()

    for (const { typeName, outputTypeName } of this.types.values()) {
      if (this.shouldNormaliseType(typeName)) {
        this.items.set(outputTypeName ?? typeName, new Map())
      }
    }

    const data = this.normaliseValue(source as QueryType)

    return {
      data,
      types: this.getObjects()
    }
  }

  // ----------------------------------------------------

  private isQueryObject(
    value: QueryType
  ): value is QueryObject {
    return value !== null &&
      typeof value === 'object' &&
      typeof value[ this.options.idKey ] === 'string' &&
      typeof value[ this.options.typeKey ] === 'string'
  }

  // ----------------------------------------------------

  private isSimpleValue(
    value: QueryType
  ): value is SimpleType {
    return value === null ||
      typeof value === 'boolean' ||
      typeof value === 'string' ||
      typeof value === 'number'
  }

  // ----------------------------------------------------

  private getOutputTypeName(
    typeName: string
  ): string {
    return this.types.get(typeName)?.outputTypeName ?? typeName
  }

  // ----------------------------------------------------

  private getTypeAndId(
    obj: QueryObject
  ): TypeAndId {
    const typeName = obj[ this.options.typeKey ] as string
    const outputTypeName = this.getOutputTypeName(typeName)
    const id = obj[ this.options.idKey ] as string

    return { typeName, outputTypeName, id }
  }

  // ----------------------------------------------------

  private storeQueryObject(
    { typeName, outputTypeName, id }: TypeAndId,
    obj: QueryObject,
    parent?: TypeAndId
  ) {
    // console.groupCollapsed(`storeQueryObject(typeName = "${typeName}", outputTypeName = "${outputTypeName}", id = "${id}")`)
    // console.dir(obj)

    let result = this.types.get(typeName)?.mapper
      ? this.types.get(typeName).mapper(obj, parent)
      : obj

    if (this.options.omitTypes) {
      result = omit([ this.options.typeKey ], result)
    }

    // console.dir(result)

    if (!this.items.has(outputTypeName)) {
      this.items.set(outputTypeName, new Map<string, object>())
    }

    const map = this.items.get(outputTypeName)
    const current = map.get(id)

    if (current) {
      result = mergeFn(current, result)

      // console.dir(map.get(id))
      // console.dir(result)
    }

    // console.groupEnd()

    this.items.set(outputTypeName, map.set(id, result))
  }

  // ----------------------------------------------------

  private normaliseValue(
    value: QueryType,
    parent?: TypeAndId,
    isPlainField = false,
  ): QueryType {
    // console.group('normaliseValue')
    // console.dir(value)
    // console.dir(parent)

    let result: QueryType

    if (this.isSimpleValue(value)) {
      return value
    }

    if (Array.isArray(value)) {
      return this.normaliseArray(value, parent)
    }

    if (!isPlainField && this.isQueryObject(value)) {
      result = this.normaliseQueryObject(value, parent)
    } else {
      result = this.normaliseObject(value, parent)
    }

    // console.dir(result)
    // console.groupEnd()

    return result
  }

  // ----------------------------------------------------

  private normaliseArray(
    items: QueryType[],
    parent?: TypeAndId
  ): QueryType[] {
    // console.group('normaliseArray')
    // console.dir(items)

    const result = items.reduce<QueryType[]>((out, item) => {
      try {
        return [ ...out, this.normaliseValue(item, parent) ]
      } catch (ex) {
        if (ex instanceof ExcludeObjectError) {
          return out
        }
        throw ex
      }
    }, [])

    // console.dir(result)
    // console.groupEnd()

    return result
  }

  // ----------------------------------------------------

  private normaliseObjectEntries<T>(
    obj: T,
    parent: TypeAndId,
    typeOptions?: TypeNormalisationOptions,
  ): T {
    return Object.entries(obj).reduce((out, [ field, value ]) => {
      try {
        const normalisedValue = this.normaliseValue(value, parent, typeOptions?.plainFields?.includes(field))

        return {
          ...out,
          [ field ]: normalisedValue
        }
      } catch (ex) {
        if (ex instanceof ExcludeObjectError) {
          return out
        }
        throw ex
      }
    }, {} as T)
  }

  // ----------------------------------------------------

  private normaliseObject(
    obj: PlainObject,
    parent?: TypeAndId
  ): PlainObject {
    // console.group('normaliseObject')
    // console.dir(obj)

    const typeOptions = this.types.get(obj[ '__typename' ] as string)

    let result = this.normaliseObjectEntries(obj, parent, typeOptions)

    if (this.options.omitTypes) {
      result = omit([ this.options.typeKey ], result)
    }

    // console.dir(result)
    // console.groupEnd()

    return result
  }

  // ----------------------------------------------------

  private shouldNormaliseType(
    typeName: string,
  ): boolean {
    if (this.types.has(typeName)) {
      const { normalise: normaliseType } = this.types.get(typeName)

      // If options.normalise is true (the default), normalise types unless they explicitly specify normalise: false.
      // If options.normalise is false, only normalise types if they explicitly specify normalise: true.
      return this.options.normalise
        ? normaliseType !== false
        : normaliseType === true
    } else {
      return this.options.normalise
    }
  }

  // ----------------------------------------------------

  private normaliseQueryObject(
    obj: QueryObject,
    parent?: TypeAndId
  ): string | PlainObject {
    const typeAndId = this.getTypeAndId(obj)

    if (!this.shouldNormaliseType(typeAndId.typeName)) {
      return this.normaliseObject(obj, parent)
    }

    const typeOptions = this.types.get(typeAndId.typeName)

    if (typeOptions?.exclude?.(obj, parent)) {
      throw new ExcludeObjectError()
    }

    // console.group(`normaliseQueryObject(typeName = "${typeName}", outputTypeName = "${outputTypeName}", id = "${id}")`)
    // console.dir(obj)

    const result = this.normaliseObjectEntries(obj, typeAndId, typeOptions)

    // console.dir(result)
    // console.groupEnd()

    this.storeQueryObject(typeAndId, result, parent)

    return typeAndId.id
  }

  // ----------------------------------------------------

  private getObjects() {
    return this.options.itemOutput === 'array'
      ? this.getObjectsAsArray()
      : this.getObjectsAsObject()
  }

  // ----------------------------------------------------

  private getObjectsAsObject(): Record<string, Record<string, object>> {
    return [ ...this.items.entries() ].reduce((out, [ typeName, map ]) => ({
      ...out,
      [ typeName ]: [ ...map.entries() ].reduce((out2, [ key, value ]) => ({
        ...out2,
        [ key ]: value
      }), {})
    }), {})
  }

  // ----------------------------------------------------

  private getObjectsAsArray(): Record<string, object[]> {
    return [ ...this.items.entries() ].reduce((out, [ typeName, map ]) => ({
      ...out,
      [ typeName ]: [ ...map.values() ].reduce((out2: object[], value) => [ ...out2, value ], [] as object[])
    }), {})
  }

}
