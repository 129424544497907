import { Injectable, Inject } from '@angular/core'

import { type Observable, forkJoin, of, firstValueFrom } from 'rxjs'
import { map, catchError, switchMap, toArray } from 'rxjs/operators'

import { flatten, uniqBy } from 'ramda'

import { Region, type ICompanyPublicExcerptData, type Jurisdiction, type Company } from '@libs/models'
import { LocalStorageService } from '@libs/storage'
import { Api, BackendService } from '@libs/backend'

import type { CompanyMatch, ILocalCompanyMatch, IRegisteredCompanyMatch } from '@app/entities/models/company-match'
import { Configuration } from '@app/core/services/configuration.service'
import { GlobalsService } from '@app/core/services/globals/globals.service'
import { RegisteredCompanySearchService } from '../registered-company-search/registered-company-search.service'
import { ToastService } from '@libs/services'

// ------------------------------------------------------------

const CompaniesAccessRequestedKey = 'CompanyCreationNotifiedAdmins'

// ------------------------------------------------------------

export interface ICompanySearchOptions {
  jurisdiction?: Jurisdiction | 'All'
  limit?: number
}

// ------------------------------------------------------------

function getRegisteredCompanyId(region: Region, company: Company): string | null {
  switch (region) {
    case Region.Commonwealth:
      return company.companiesHouseNumber
    case Region.France:
      return company.sirenCode
    default:
      return null
  }
}

// ------------------------------------------------------------

const uniqByRegionalCompanyId = uniqBy<ILocalCompanyMatch | IRegisteredCompanyMatch, string>(v => {
  return v.registrarNumber
    ? v.region + '_' + v.registrarNumber
    : (v as ILocalCompanyMatch).company.id
})

// ------------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {

  searchCompanies(
    q: string,
    options?: ICompanySearchOptions
  ): Observable<CompanyMatch[]> {
    options = {
      limit: 20,
      ...options
    }

    const sources: Observable<ILocalCompanyMatch[] | IRegisteredCompanyMatch[]>[] = [
      this.searchOnPlatform(q, options)
    ]

    if (options.jurisdiction) {
      sources.push(this.searchOffPlatform(q, options))
    }

    return forkJoin(sources).pipe(
      map(results => flatten(results)),
      map(uniqByRegionalCompanyId)
    )
  }

  // ----------------------------------------------------

  searchOnPlatform(
    q: string,
    { limit }: ICompanySearchOptions
  ): Observable<ILocalCompanyMatch[]> {
    q = q.trim()

    return this.api
      .all('companies')
      .one('search')
      .get<ICompanyPublicExcerptData[]>('findByNameIgnoreCaseContaining', { q, limit })
      .pipe(
        switchMap(data => data),
        map(companyData => this.globalsService.buildCompany(companyData)),
        map(company => {
          const regionData = this.configuration.getRegionByJurisdiction(company.jurisdiction)
          const canNotify = !this.hasAlreadyNotifiedAdmin(company.id)

          return {
            source: 'local',
            region: regionData.id,
            name: company.name,
            registrarNumber: getRegisteredCompanyId(regionData.id, company),
            incorporated: company.incorporated,
            address: company.address?.isComplete()
              ? company.address.toFormattedString()
              : null,
            company,
            canNotify
          } as ILocalCompanyMatch
        }),
        toArray(),
        catchError(() => of([]))
      )
  }

  // ----------------------------------------------------

  searchOffPlatform(
    q: string,
    { jurisdiction, limit }: ICompanySearchOptions
  ): Observable<IRegisteredCompanyMatch[]> {
    return this.registeredCompanySearchService.searchCompanies(jurisdiction, q, limit)
      .pipe(
        switchMap(data => data),
        map(companyData => this.registeredCompanySearchService.getCompanyMatch(companyData)),
        toArray(),
        catchError(() => of([]))
      )
  }

  // ----------------------------------------------------

  notifyAdmin(companyId: string) {
    return firstValueFrom(this.api.one('companies', companyId)
      .one('claim')
      .withHeaders({
        'Content-Type': 'application/json'
      })
      .post())
  }

  // ----------------------------------------------------

  addNotifiedAdmin(companyId: string) {
    const newNotifiedAdmins: string = [ ...new Set([ ...this.getNotifiedAdminsArray() ?? [], companyId ]) ].join(',')
    this.localStorageService.setItem(CompaniesAccessRequestedKey, newNotifiedAdmins)
    this.toastService.success($localize`Company Admin notified`)
  }

  private hasAlreadyNotifiedAdmin(companyId: string): boolean {
    return !!this.getNotifiedAdminsArray()?.includes(companyId)
  }

  private getNotifiedAdminsArray(): string[] | undefined {
    return this.localStorageService.getItem(CompaniesAccessRequestedKey)?.split(',')
  }

  // ----------------------------------------------------

  constructor(
    @Inject(Api) private api: BackendService,
    private readonly configuration: Configuration,
    private readonly globalsService: GlobalsService,
    private readonly localStorageService: LocalStorageService,
    private readonly registeredCompanySearchService: RegisteredCompanySearchService,
    private readonly toastService: ToastService
  ) {}
}
