import { compose, concat, ifElse, mergeDeepWith, nthArg, omit, path, prop, sortBy, toLower, uniq, uniqBy } from 'ramda'

// ----------------------------------------------------------

export type Comparator<T> = (a: T, b: T) => number

export type Mapper<T, U> = (_: T) => U

export type Conditional<T> = (a: T) => boolean

export type Reducer<T, A> = (accum: A, cur: T) => A

export type Transformer<T, A> = (accum: A, cur: T) => void

// ----------------------------------------------------------

function propertyComparator<T>(propName: string, reverse: boolean = false): Comparator<T> {
  if (propName[ 0 ] === '-') {
    propName = propName.slice(1)
    reverse = true
  }

  const factor = reverse ? -1 : 1

  return (a, b) => {
    const av = a[ propName ]
    const bv = b[ propName ]

    let result = 0

    if (av !== bv) {
      result = factor * (av < bv ? -1 : 1)
    }

    return result
  }
}

// ----------------------------------------------------------

function chainedComparator<T>(comparators: Comparator<T>[]): Comparator<T> {
  return (a, b) => {
    for (const c of comparators) {
      const result = c(a, b)

      if (result) {
        return result
      }
    }

    return 0
  }
}

// ----------------------------------------------------------

export function getComparator<T>(criteria: Comparator<T> | string | string[]): Comparator<T> {
  if (typeof criteria === 'function') {
    return criteria
  }

  if (typeof criteria === 'string') {
    criteria = criteria.split(' ')
  }

  const comparators = criteria.map(criterion => propertyComparator(criterion))

  return chainedComparator(comparators)
}

// ----------------------------------------------------------

export interface HasId {
  id: string
}

// ----------------------------------------------------------

export type IdRecord<T extends HasId = HasId> = {
  [ id: string ]: T
}

// ----------------------------------------------------------

export type WithoutId<T extends HasId> = Omit<T, 'id'>

export type PartialWithoutId<T extends HasId> = Partial<WithoutId<T>>

export type PartialWithId<T extends HasId> = { id: string } & PartialWithoutId<T>

// ----------------------------------------------------------

export const ids = (items: HasId[]): string[] => {
  return items.map(v => v.id)
}

export const idSet = (items: HasId[]): Set<string> => {
  return new Set(ids(items))
}

export const omitId = omit([ 'id' ])

// ----------------------------------------------------------

export const mapDefined = <T, K extends keyof T>(propName: K, list: T[]) => list.reduce<T[K][]>((acc, item) => {
  if (item[ propName ]) {
    acc.push(item[ propName ])
  }

  return acc
}, [])

// ----------------------------------------------------------

export function uniqById<T extends HasId>(arr: T[]): T[] {
  return uniqBy(prop('id'), arr.filter(v => v))
}

export function uniqByUser<T extends { user: HasId }>(arr: T[]): T[] {
  return uniqBy(path([ 'user', 'id' ]), arr.filter(v => v))
}

// ----------------------------------------------------------

export function setIntersection<T>(
  a: Set<T>,
  b: Set<T>,
): Set<T> {
  return new Set([ ...a ].filter(v => b.has(v)))
}

// ----------------------------------------------------------

export function differenceById<T extends HasId>(
  first: T[],
  second: T[]
): T[] {
  const excludedIds = idSet(second)

  return first.filter(v => !excludedIds.has(v.id))
}

// ----------------------------------------------------------

export function isEqualIdSet<T extends HasId>(
  first: T[],
  second: T[]
): boolean {
  const firstIds = idSet(first)
  const secondIds = idSet(second)

  if (firstIds.size !== secondIds.size) {
    return false
  }

  for (const id of firstIds) {
    if (!secondIds.has(id)) {
      return false
    }
  }

  return true
}

// ----------------------------------------------------------

export function isInvalidUid(id: string) {
  return id.match(/^(\d{1,}$)/)
}

// ----------------------------------------------------------

export function sortByName<T extends { name: string }>(arr: T[]): T[] {
  return sortBy<T>(compose(toLower, prop('name')), arr)
}

export function sortByFirstName<T extends { firstName: string }>(arr: T[]): T[] {
  return sortBy<T>(compose(toLower, prop('firstName')), arr)
}

// ------------------------------------------------------------

// mergeDeep(a, b) returns a new object by deep merging a and b with data
// from b overwriting data from a when they conflict, arrays are
// concatenated together in the output as [ ...a_array, ...b_array ].
export const mergeDeep = mergeDeepWith(
  ifElse(
    Array.isArray,
    // pipe(concat, uniq),
    concat,
    nthArg(1),
  )
)

export const mergeDeepUniq = mergeDeepWith(
  ifElse(
    Array.isArray,
    (a: unknown[], b: unknown[]) => uniq([ ...a, ...b ]),
    nthArg(1),
  )
)
