import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { type Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'

import { type CompanyType, Region, Jurisdiction, getFrCompanyTypeFromCode } from '@libs/models'
import type { IFranceRegisteredCompanyData } from '../../models/company-data'

import { environment } from '@env/environment'

// ----------------------------------------------------------

export const RNCSApiSettings = {
  SireneApiRoot: 'https://entreprise.data.gouv.fr/api/sirene/v1',
  RncsApiRoot: 'https://entreprise.data.gouv.fr/api/rncs/v1'
}

// ----------------------------------------------------------

export interface ISireneResponse {
  etablissement: ISireneCompanyResponseData[]
}

// ----------------------------------------------------------

export interface ISireneCompanyResponseData {
  siren: string
  nom_raison_sociale: string
  date_creation_entreprise: string
  nature_juridique_entreprise: string
  geo_adresse: string
}

// ----------------------------------------------------------

export interface IRncsResponse {
  dossier_entreprise_greffe_principal: IRncsCompanyResponseData
}

// ----------------------------------------------------------

export interface IRncsCompanyResponseData {
  code_greffe: string
  etablissement_principal: {
    date_debut_activite: string
    adresse_ligne_1: string
    adresse_ligne_2: string
    adresse_ligne_3: string
    adresse_code_postal: string
    adresse_ville: string
    adresse_pays: string
  }
  personne_morale: {
    forme_juridique: string
    devise: string
    capital: string
  }
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class RncsService {
  readonly isEnabled = environment.FLAGS.DISABLE_COMPANIES_HOUSE_API !== true

  // ----------------------------------------------------

  getCompany(
    data: IFranceRegisteredCompanyData
  ): Observable<IFranceRegisteredCompanyData> {
    if (!this.isEnabled) {
      throw new Error('Cannot access RNCS API')
    }

    const url = `${RNCSApiSettings.RncsApiRoot}/fiches_identite/${data.registrarNumber}`

    return this.httpClient
      .get<IRncsResponse>(url, {
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        map(({ dossier_entreprise_greffe_principal }) => this.updateCompanyWithFullDetails(data, dossier_entreprise_greffe_principal)),
        catchError(() => of({ ...data } as IFranceRegisteredCompanyData))
      )
  }

  // ----------------------------------------------------

  searchCompanies(
    q: string,
    limit = 5
  ): Observable<IFranceRegisteredCompanyData[]> {
    if (!this.isEnabled) {
      return of([])
    }

    try {
      const url = `${RNCSApiSettings.SireneApiRoot}/full_text/${encodeURIComponent(q)}`

      return this.httpClient
        .get<ISireneResponse>(url, {
          params: {
            per_page: String(limit)
          },
          observe: 'body',
          responseType: 'json'
        })
        .pipe(
          map(({ etablissement: items }) => items.map(item => this.getSearchDataFromResponse(item))),
          catchError(() => of([]))
        )
    } catch (e) {
      return of([])
    }
  }

  // ----------------------------------------------------

  private getSearchDataFromResponse(
    data: ISireneCompanyResponseData
  ): IFranceRegisteredCompanyData {
    const incorporated = `${data.date_creation_entreprise.slice(0, 4)}-${data.date_creation_entreprise.slice(-2)}-01`
    const type: CompanyType = getFrCompanyTypeFromCode(data.nature_juridique_entreprise)

    return {
      region: Region.France,
      jurisdiction: Jurisdiction.France,
      field: 'sirenCode',
      currency: 'EUR',
      registrarNumber: data.siren,
      // sirenCode: data.siren,
      name: this.prettifyText(data.nom_raison_sociale),
      rawName: data.nom_raison_sociale,
      type,
      incorporated,
      addressSnippet: data.geo_adresse
    }
  }

  // ----------------------------------------------------

  private updateCompanyWithFullDetails(
    data: IFranceRegisteredCompanyData,
    response: IRncsCompanyResponseData
  ): IFranceRegisteredCompanyData {
    const headquarters = response.etablissement_principal

    const line2 = headquarters.adresse_ligne_2 || headquarters.adresse_ligne_3
      ? headquarters.adresse_ligne_2 && headquarters.adresse_ligne_3
        ? `${headquarters.adresse_ligne_2}, ${headquarters.adresse_ligne_3}`
        : headquarters.adresse_ligne_2 || headquarters.adresse_ligne_3
      : null

    return {
      ...data,
      greffe: response.code_greffe,
      shareCapital: +response.personne_morale.capital,
      address: {
        line1: headquarters.adresse_ligne_1,
        line2,
        city: headquarters.adresse_ville,
        postcode: headquarters.adresse_code_postal,
        country: 'FR'
      }
    }
  }

  // ----------------------------------------------------

  /**
   * Capitalize the company stored in Companies House and removes 'LTD' or 'LIMITED'
   * @param {string} str
   * @returns {string}
   */
  private prettifyText(str: string | null): string | null {
    return str !== null
      ? str.trim().replace(/\w\S*/g, txt => txt[ 0 ].toUpperCase() + txt.substring(1).toLowerCase())
      : null
  }

  // ----------------------------------------------------

  constructor(
    private httpClient: HttpClient
  ) {}
}
