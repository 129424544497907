<sl-price-info-display *ngIf="priceInfoService.ready$ | async; else loading"
                       [companyId]="companyId"
                       [discountedFee]="priceInfoService.discountedFee$ | async"
                       [totalFee]="priceInfoService.totalFee$ | async"
                       [depositFee]="priceInfoService.depositFee$ | async"
                       [hideCalculations]="hideCalculations"
                       [agreementFee]="priceInfoService.agreementFee$ | async"
                       [pricingModel]="priceInfoService.pricingModel$ | async"
                       [currency]="priceInfoService.currency$ | async"
                       [feeTemplate]="feeTemplate"
                       [headerTemplate]="headerTemplate"
                       [footerTemplate]="footerTemplate"></sl-price-info-display>

<ng-template #loading>
  <div class="loading-container">
    <mat-spinner [diameter]="24"></mat-spinner>
  </div>
</ng-template>
