import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'

import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { AuthFacade } from '../+state/auth.facade'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class NotConnectedGuard implements CanActivate {

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.isUserConnected$.pipe(
      map(isConnected => isConnected ? this.router.createUrlTree([ '/' ]) : true),
      first(),
    )
  }

  // ----------------------------------------------------

  constructor(
    private authFacade: AuthFacade,
    private router: Router,
  ) {}

}
