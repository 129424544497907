import { NgModule } from '@angular/core'

import { ModalsModule } from '@libs/modals'
import { PaymentsComponentsModule } from './payments-components.module'
import { PaymentsStateModule } from './payments-state.module'

// ----------------------------------------------------------

@NgModule({
  imports: [
    ModalsModule,
    PaymentsComponentsModule,
    PaymentsStateModule
  ]
})
export class PaymentsModule {}
