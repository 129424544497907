<div gdColumns="repeat(auto-fill, minmax(352px, 400px))"
     gdRows="1fr"
     class="gap-16">

  <div *ngFor="let card of sortedCards"
       class="payment-method"
       [class.selectable]="selectable && card !== selected"
       [class.selected]="selected === card"
       (click)="selectable && card !== selected && cardClicked(card)">
    <div fxLayout="row"
         fxLayoutAlign="start center"
         class="card-details gap-8">

      <img [src]="getCardImage(card)">

      <div fxLayout="column"
           fxFlex>
        <span class="text-bold">{{ card.label ? card.label : 'No label specified' }} </span>
        <span>{{ getCardBrand(card) }} ••• {{ card.last4 }}</span>
        <div fxLayoutAlign="space-between center">
          <span>{{ card.expiry | date: 'MM/yy' }}</span>
          <span *ngIf="card.default" i18n class="text-bold">Default</span>
        </div>
      </div>

    </div>

    <mat-divider *ngIf="!selectable"></mat-divider>

    <div *ngIf="!selectable"
         fxLayoutAlign="end center"
         class="p-8">
      <a *ngIf="!card.default || cards.length === 1"
         (click)="removeCard(card)"
         mat-button
         color="primary"
         i18n>Remove</a>
      <a *ngIf="!card.default"
         (click)="setDefaultCard(card)"
         mat-button
         color="primary"
         i18n>Set default</a>
    </div>

  </div>

  <ng-content></ng-content>
</div>
