import { Directive, Input, type OnInit } from '@angular/core'

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

import { map } from 'rxjs/operators'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

// ----------------------------------------------------------

/** Tag content or template elements as grid-item for the ghost grid component
 *  Should only be used inside `sl-ghost-grid` as direct child component and  it
 *  will be replaced by a ghost component while `loading` is `true` in the parent.
 *  Can only be applied to direct descendants of the ghost component.
 *
 *  See usage example on `ghost-grid.component`.
 */
@UntilDestroy()
@Directive({
  selector: '[slGhostGridItem]',
})
export class GhostGridItemDirective implements OnInit {

  /** Which ghost component will show while loading. Please check all different styles. */
  @Input() slGhostType: 'document' | 'line' | 'text' | 'heading' | 'table' | 'list' | 'avatar' | 'card' | 'empty' = 'line'

  /** Styles passed to the ghost-component container. */
  @Input() slGhostStyles: { [ klass: string ]: string } = {}

  /** Amount of columns that the ghost component takes up.
   *  If nothing is given, it will take the whole row.
   */
  @Input() slGhostColSpan: number

  /** Amount of columns that the ghost component takes up on small screens.
   *  Will fall back to `colSpan` if none is given.
   */
  @Input() slGhostColSpanXs: number

  /** Number of columns for the `table` and `list` types. */
  @Input() slGhostCols = 1

  /** Number of columns for the `table` and `list` types on small screens.
   *  Will fall back to `cols` if none is given.
   */
  @Input() slGhostColsXs: number

  /** Number of rows for the `table` type. */
  @Input() slGhostRows = 1

  /** Number of rows for the `table` type on small screens.
   *  Will fall back to `rows` if none is given.
   */
  @Input() slGhostRowsXs: number

  /** Height in px for `table` and list items. */
  @Input() slRowHeight = 120

  _colSpan: number
  _cols: number
  _rows: number

  // ----------------------------------------------------

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {}

  // ----------------------------------------------------

  ngOnInit(): void {
    this.breakpointObserver
      .observe([ Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium ])
      .pipe(
        map(s => s.matches),
        untilDestroyed(this),
      )
      .subscribe(isMobile => {
        if (isMobile) {
          this._colSpan = this.slGhostColSpanXs || this.slGhostColSpan
          this._cols = this.slGhostColsXs || this.slGhostCols
          this._rows = this.slGhostRowsXs || this.slGhostRows
        } else {
          this._colSpan = this.slGhostColSpan
          this._cols = this.slGhostCols
          this._rows = this.slGhostRows
        }
      })
  }
}
