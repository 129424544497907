import { Component, Input } from '@angular/core'
import { Company, User } from '@libs/models'

// -----------------------------------------------------------

@Component({
  selector: 'sl-entity-chip',
  templateUrl: './entity-chip.component.html',
  styleUrls: [ './entity-chip.component.scss' ]
})
export class EntityChipComponent {
  @Input() entity?: User | Company
  @Input() grow?: boolean = false
}
