import { sortBy, sum } from 'ramda'
import { isBefore } from 'date-fns'

import type { EntityType } from '../models/model.model'
import { EntityTypes } from '../models/model.model'
import type { CompanyProduct, CompanyType, ICompanyMetadata } from '../models/company.model'
import type { Currency, CurrencyCode } from '../models/currency.model'
import { getCurrencyByCode } from '../models/currency.model'
import type { BillingPeriod, PlanId } from '../models/plan.model'
import type { Feature } from '../models/feature.model'
import { Jurisdiction } from '../models/jurisdiction.model'

import type { Model } from './model'
import { Collection } from './collection'
import { Entity } from './entity'
import type { Appointment } from './appointment'
import { AppointmentCollection } from './appointment'
import { AppointmentRole } from './appointment-roles'
import type { User } from './user'

import type { CohortTeam } from './cohort-manager/cohort'

import { DocumentCollection } from './documents/document'

import type { Event } from './events/event'
import { EventCollectionBase } from './events/event'
import type {
  EmploymentBonusPaymentEvent,
  EmploymentBonusTargetEvent,
  EmploymentStartEvent,
  EmploymentTerminationEvent,
  EmploymentVariationEvent
} from './events/employment-events'
import { EmploymentEventCollection } from './events/employment-events'
import type { Round } from './events/round'
import { RoundCollection } from './events/round'
import { PlatformRoundTypes } from './events/round-type'

import type { AdvanceAssuranceDocumentEvent } from './events/advance-assurance-event'
import type { BoardMeetingEvent } from './events/board-meeting-event'
import type { CohortFundingEvent } from './events/cohort-funding-event'
import type { ComplianceEvent } from './events/compliance-event'
import type { ConfirmationStatementEvent } from './events/confirmation-statement-event'
import type { ConvertibleNoteEvent } from './events/convertible-note-event'
import type {
  DirectorshipEvent,
  DirectorshipTerminationEvent,
  DirectorshipVariationEvent
} from './events/director-events'
import type { ExitEvent } from './events/exit-event'
import type { FounderShareholderEvent } from './events/founder-shareholder-event'
import type { InstantInvestmentConsentEvent } from './events/instant-investment-consent-event'
import type { InstantConversionEvent } from './events/instant-conversion-event'
import type { OptionPoolEvent } from './events/option-pool-event'
import type { ProposalEvent } from './events/proposal-event'
import type { RegularReportEvent } from './events/regular-report-event'
import type { RepaymentEvent } from './events/repayment-event'
import type { ResearchAssuranceEvent } from './events/research-assurance-event'
import type { ResearchClaimEvent } from './events/research-claim-event'
import type { SeedNoteEvent } from './events/seed-note-event'
import type { SeedSaftEvent } from './events/seed-saft-event'
import type { ShareTransferEvent } from './events/share-transfer-event'
import type { StockSplitEvent } from './events/stock-split-event'

import { ShareAllotmentReturnCollection } from './events/share-allotment-return-event'
import { ShareClassRegistrationCollection } from './events/share-class-registration-event'

import type { BankAccount } from './money/bank-account'
import type { ProductId, PurchaseStage } from './money/product'
import { PurchaseStages } from './money/product'
import type { Subscription } from './money/subscription'

import { OptionCollection } from './options/option'
import type { EmiValuationEvent } from './options/emi-valuation-event'
import type { OptionExerciseEvent } from './options/option-exercise-event'
import { OptionGrantCollection } from './options/option-grant-event'
import type { OptionSchemeEvent } from './options/option-scheme-event'
import type { StopVestingEvent } from './options/stop-vesting-event'
import type { OptionReturnEvent } from './options/option-return-event'

import type { Investor } from './stock/investor'
import { InvestorCollection } from './stock/investor'
import type { Share } from './stock/share'
import type { ShareClass } from './stock/share-class'
import { ShareClassCollection } from './stock/share-class'

import type { AccessRole } from '../models/access.model'
import { AccessCollection } from './access'

import type { Mapper } from '@libs/utils'
import { asDate, isSameOrBefore, percentage, sortByName, uniqById } from '@libs/utils'

// ------------------------------------------------------------

const CompanyTypeSuffixes = {
  LTD: 'Ltd',
  LIMITED: 'Limited',
  LLP: 'LLP',
  LP: 'LP',
  LBG: 'LBG',
  PLC: 'PLC',
  PUC: 'PUC',
  CIC: 'CIC'
}

// ------------------------------------------------------------

export class Company extends Entity {
  readonly entityType: EntityType = EntityTypes.Company

  name: string
  fullName: string

  companiesHouseNumber: string | null
  sirenCode: string | null
  hmrcReferenceNumber: string | null
  taxReferenceNumber: string | null
  payeReferenceNumber: string | null
  vatNumber: string | null
  currency: CurrencyCode
  type: CompanyType
  jurisdiction: Jurisdiction
  regionalName: string | null
  greffe: string | null
  amtsgericht: string | null
  override description: string | null
  override twitter: string | null
  override linkedin: string | null
  override facebook: string | null
  crunchbase: string | null
  website: string | null
  signatureText: string | null
  shareCapital: number | null
  shareNominalValue: number | null
  showHeaderLogo = true
  enableStaffWriteAccess = false
  hasCohortPortal = false
  hasConsented = false
  incorporated: string | null
  claimed = true
  metadata: ICompanyMetadata

  accesses = new AccessCollection()
  appointments = new AppointmentCollection()
  bankAccounts = new Collection<BankAccount>()
  companyProducts: CompanyProduct[] = []
  documents = new DocumentCollection()
  shareClasses = new ShareClassCollection()
  investors = new InvestorCollection()
  options = new OptionCollection()

  subscriptions = new Map<PlanId, Subscription>()

  events = new EventCollectionBase()

  advanceAssurances = new EventCollectionBase<AdvanceAssuranceDocumentEvent>()
  boardMeetings = new EventCollectionBase<BoardMeetingEvent>()
  cohortFundings = new Collection<CohortFundingEvent>()
  cohortTeams = new Collection<CohortTeam>()
  compliances = new EventCollectionBase<ComplianceEvent>()
  convertibleNotes = new EventCollectionBase<ConvertibleNoteEvent>()
  confirmationStatements = new EventCollectionBase<ConfirmationStatementEvent>()
  directorships = new EventCollectionBase<DirectorshipEvent>()
  directorshipVariations = new EventCollectionBase<DirectorshipVariationEvent>()
  directorshipTerminations = new EventCollectionBase<DirectorshipTerminationEvent>()
  emiValuations = new EventCollectionBase<EmiValuationEvent>()
  exits = new EventCollectionBase<ExitEvent>()
  foundersShareholdersAgreements = new EventCollectionBase<FounderShareholderEvent>()
  optionExercises = new EventCollectionBase<OptionExerciseEvent>()
  optionGrants = new OptionGrantCollection()
  optionPools = new EventCollectionBase<OptionPoolEvent>()
  optionReturns = new EventCollectionBase<OptionReturnEvent>()
  optionSchemes = new EventCollectionBase<OptionSchemeEvent>()
  proposals = new EventCollectionBase<ProposalEvent>()
  investorProposals = new EventCollectionBase<ProposalEvent>()
  regularReports = new EventCollectionBase<RegularReportEvent>()
  repayments = new EventCollectionBase<RepaymentEvent>()
  researchAssurances = new EventCollectionBase<ResearchAssuranceEvent>()
  researchClaims = new EventCollectionBase<ResearchClaimEvent>()
  rounds = new RoundCollection()
  seedNotes = new EventCollectionBase<SeedNoteEvent>()
  seedSafts = new EventCollectionBase<SeedSaftEvent>()
  shareTransfers = new EventCollectionBase<ShareTransferEvent>()
  shareClassRegistrations = new ShareClassRegistrationCollection()
  stockSplits = new EventCollectionBase<StockSplitEvent>()
  stopVestings = new EventCollectionBase<StopVestingEvent>()
  instantConversions = new EventCollectionBase<InstantConversionEvent>()

  instantInvestmentConsents = new EventCollectionBase<InstantInvestmentConsentEvent>()
  shareAllotmentReturns = new ShareAllotmentReturnCollection()

  employments = new EmploymentEventCollection<EmploymentStartEvent>()
  employmentVariations = new EmploymentEventCollection<EmploymentVariationEvent>()
  employmentBonusPayments = new EmploymentEventCollection<EmploymentBonusPaymentEvent>()
  employmentBonusTargets = new EmploymentEventCollection<EmploymentBonusTargetEvent>()
  employmentTerminations = new EmploymentEventCollection<EmploymentTerminationEvent>()

  // ----------------------------------------------------

  constructor({
    incorporated = null,
    jurisdiction = Jurisdiction.EnglandWales,
    type = 'LTD',
    currency = 'GBP',
    website = null,
    twitter = null,
    linkedin = null,
    facebook = null,
    crunchbase = null,
    companiesHouseNumber = null,
    sirenCode = null,
    taxReferenceNumber = null,
    hmrcReferenceNumber = null,
    payeReferenceNumber = null,
    vatNumber = null,
    regionalName = null,
    greffe = null,
    amtsgericht = null,
    shareCapital = null,
    shareNominalValue = null,
    signatureText = null,
    metadata = {},
    isFullModel = false,
    ...data
  }) {
    super({
      incorporated,
      jurisdiction,
      type,
      currency,
      website,
      twitter,
      linkedin,
      facebook,
      crunchbase,
      companiesHouseNumber,
      sirenCode,
      taxReferenceNumber,
      hmrcReferenceNumber,
      payeReferenceNumber,
      vatNumber,
      regionalName,
      greffe,
      amtsgericht,
      shareCapital,
      shareNominalValue,
      signatureText,
      metadata,
      isFullModel,
      ...data
    })
  }

  // ----------------------------------------------------

  override nameChanged() {
    if (typeof this.name === 'string') {
      this.fullName = this.name

      if (CompanyTypeSuffixes[ this.type ]) {
        this.fullName += ' ' + CompanyTypeSuffixes[ this.type ]
      }
    } else {
      this.fullName = ''
    }

    super.nameChanged()
  }

  // ----------------------------------------------------

  get domain() {
    return `companies`
  }

  // ----------------------------------------------------

  get defaultAvatar() {
    return 'company-nopic-168.png'
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'name',
      'website',
      'twitter',
      'linkedin',
      'facebook',
      'crunchbase',
      'companiesHouseNumber',
      'sirenCode',
      'taxReferenceNumber',
      'hmrcReferenceNumber',
      'payeReferenceNumber',
      'vatNumber',
      'regionalName',
      'greffe',
      'amtsgericht',
      'shareCapital',
      'incorporated',
      'shareNominalValue',
      'jurisdiction',
      'type',
      'currency',
      'signatureText',
      'showHeaderLogo',
      'hasCohortPortal',
      'metadata'
    ]
  }

  // ----------------------------------------------------

  get bankAccount(): BankAccount {
    return this.bankAccounts.items()[ 0 ]
  }

  // ----------------------------------------------------

  get hasSetupFoundersAndDirectors(): boolean {
    return this.metadata.foundersAndDirectorsSetup || false
  }

  // ----------------------------------------------------

  getActiveSubscription(): Subscription {
    return [ ...this.subscriptions.values() ].find(sub => sub.isActive)
  }

  // ----------------------------------------------------

  getActiveNonTrialSubscription(): Subscription | undefined {
    return [ ...this.subscriptions.values() ].find(sub => sub.isActive && !sub.isFreeTrial)
  }

  // ----------------------------------------------------

  canUseFreeTrial(): boolean {
    return this.subscriptions.size === 0
  }

  // ----------------------------------------------------

  /**
   * Check whether company has a subscription for a given plan.
   *
   * @param {string} planId  Plan ID
   * @returns {boolean}
   */
  hasSubscription(planId: PlanId): boolean {
    return this.subscriptions.has(planId) && this.subscriptions.get(planId).isActive
  }

  // ----------------------------------------------------

  /**
   * Check whether company has a subscription for a given plan, but excludes Free Trial Subscriptions or cancelled.
   *
   * @param {string} planId  Plan ID
   * @param {BillingPeriod} billingPeriod  Billing Period
   * @returns {boolean}
   */
  hasPurchasedSubscription(planId: PlanId, billingPeriod: BillingPeriod): boolean {
    const subscription = this.subscriptions.get(planId)
    return subscription && subscription.billingPeriod === billingPeriod && subscription.isPaid
  }

  // ----------------------------------------------------

  hasFeature(feature: Feature): boolean {
    return this.getAllFeatures().has(feature)
  }

  // ----------------------------------------------------

  getAllFeatures(): Set<Feature> {
    const features = new Set<Feature>()

    this.subscriptions.forEach(subscription => {
      if (subscription.isActive) {
        for (const f of subscription.plan.features) {
          features.add(f)
        }
      }
    })

    return features
  }

  // ----------------------------------------------------

  // Get the subscription object for the given plan ID if one exists.
  getSubscription(planId: PlanId): Subscription | undefined {
    return this.subscriptions.get(planId)
  }

  // ----------------------------------------------------

  hasPurchase(
    entityOrProduct: Model | ProductId,
    stage: PurchaseStage = PurchaseStages.Balance,
  ): boolean {
    return !!this.getPurchase(entityOrProduct, stage)
  }

  // ----------------------------------------------------

  getPurchase(
    entityOrProduct: Model | ProductId,
    stage: PurchaseStage = PurchaseStages.Balance,
  ): CompanyProduct {
    if (typeof entityOrProduct === 'string') {
      return this.companyProducts.find(cp => cp.product === entityOrProduct && cp.stage === stage)
    } else {
      return this.companyProducts.find(cp => cp.entity === entityOrProduct.link && cp.stage === stage)
    }
  }

  // ----------------------------------------------------

  getPurchasesForEntity(
    entity: Model,
  ): CompanyProduct[] {
    return this.companyProducts.filter(cp => cp.entity === entity.link)
  }

  // ----------------------------------------------------

  /**
   * Retrieves all the users related to the given company, merging
   * its appointments, shareholders, option holders and investors.
   */
  getAllUsers(): User[] {
    const employees = this.appointments.map(a => a.user)

    const investorsAndOptionHolders = [
      ...this.investors.filter(i => i.type === 'user'),
      ...this.options.map(option => option.investor)
    ]
      .map(investor => investor.entity as User)

    return sortByName(uniqById([
      ...employees,
      ...investorsAndOptionHolders
    ]))
  }

  // ----------------------------------------------------

  /**
   * Gets items that are stored on the company's events.
   *
   * @param {Function | String} collectorFunc A function used to get an array
   *                                          or Collection from an event
   * @returns {Object[]} An array of items collected from all events
   */
  _collectFromEvents<U>(collectorFunc: Mapper<Event, U[]>) {
    return this.events.reduce((out: U[], event: Event) => {
      try {
        return [ ...out, ...collectorFunc(event) ]
      } catch (ex) {
        return out
      }
    }, [])
  }

  // ----------------------------------------------------

  getAllShares(): Share[] {
    return this._collectFromEvents(event => event.getSharesIssuedFromEvent())
  }

  // ----------------------------------------------------

  getActiveShares(): Share[] {
    return this.getAllShares().filter(s => s.active)
  }

  // ----------------------------------------------------

  getSeisEisShares(): Share[] {
    return this.getActiveShares().filter(share => share.scheme === 'SEIS' || share.scheme === 'EIS').reverse()
  }

  // ----------------------------------------------------

  getEisSharesBeforeOrOn(date: string): Share[] {
    return this.getActiveShares().filter(share => share.scheme === 'EIS' && isSameOrBefore(asDate(share.issued), asDate(date))).reverse()
  }

  // ----------------------------------------------------

  getCurrentInvestors(): Investor[] {
    return this.currentRound
      ? uniqById(this.currentRound.investments.map(investment => investment.investor))
      : []
  }

  // ----------------------------------------------------

  getShareholders(): Investor[] {
    return uniqById(this.getActiveShares().map(share => share.investor))
  }

  // ----------------------------------------------------

  get hasClosedOptionScheme(): boolean {
    return this.optionSchemes.some(scheme => scheme.closed)
  }

  // ----------------------------------------------------

  get shareCount(): number {
    return sum(this.getActiveShares().map(s => s.shareCount))
  }

  // ----------------------------------------------------

  get votingShareCount() {
    return sum(this.getActiveShares().map(s => s.votingShareCount))
  }

  // ----------------------------------------------------

  /**
   * The number of options in the company's option pool from
   * combining option pools that were defined to be a specific number
   * of options.
   *
   * @type {Number}
   */
  get optionCount() {
    return sum([
      ...this.rounds.items().map(s => s.optionCount ?? 0),
      ...this.cohortFundings.items().map(s => s.optionCount ?? 0),
      ...this.optionPools.items().map(p => p.closed ? p.count : 0),
    ]) - this.exercisedOptionCount
  }

  // ----------------------------------------------------

  /**
   * The total number of options in the company's option pool,
   * combines options from both fixed- and percentage-based
   * option pools in each round, as well as pool events.
   *
   * @type {Number}
   */
  get estimatedOptionCount() {
    return sum([
      ...this.rounds.items().map(s => s.estimatedOptionCount),
      ...this.cohortFundings.items().map(s => s.estimatedOptionCount),
      ...this.optionPools.items().map(p => p.count),
    ])
  }

  // ----------------------------------------------------

  /**
   * The number of options in the company's option pool from
   * combining OptionExercises
   *
   * @type {Number}
   */
  get exercisedOptionCount() {
    return sum(this.optionExercises.items().map(oe => oe.count))
  }

  // ----------------------------------------------------

  /**
   * Used to calculate the percentage of the fully diluted share total the option pool will comprise, after
   * [count] options are added to the pool.
   *
   *  @type {Number}
   */
  estimatedPostPoolEventPercentage(count: number) {
    if (count + this.dilutedShareCount > 0) { // Protect against divide by 0
      return (count + this.optionCount) / (count + this.dilutedShareCount)
    }
    return 0
  }

  // ----------------------------------------------------

  get outstandingShareCount() {
    return this.shareCount
  }

  // ----------------------------------------------------

  get dilutedShareCount() {
    return this.optionCount + this.outstandingShareCount
  }

  // ----------------------------------------------------

  get optionOwnership() {
    return percentage(this.optionCount, this.dilutedShareCount)
  }

  // ----------------------------------------------------

  get closedRounds() {
    return this.rounds.getCompletedEvents()
  }

  // ----------------------------------------------------

  get incorporationRound(): Round {
    return sortBy(r => r.effectiveDate, this.rounds.getCompletedEvents())[ 0 ]
  }

  // ----------------------------------------------------

  get shareNominalValueConsistent(): boolean {
    return !!this.incorporationRound && this.incorporationRound.pricePerShare === this.shareNominalValue
  }

  // ----------------------------------------------------

  get currentRound(): Round | undefined {
    return this.rounds.getCurrentEvents().find(r => !r.isInstant)
  }

  // ----------------------------------------------------

  get ordinaryShareClass(): ShareClass {
    return sortBy(sc => sc.id, this.shareClasses.filter(sc => !sc.preferred))[ 0 ]
  }

  // ----------------------------------------------------

  get issuedShareClasses(): ShareClass[] {
    return this.shareClasses.filter(sc => sc.hasIssuedShares)
  }

  // ----------------------------------------------------

  get locale(): Currency {
    return getCurrencyByCode(this.currency)
  }

  // ----------------------------------------------------

  getRelation(user: User): Appointment | undefined {
    return user && this.appointments.find(a => a.user.id === user.id)
  }

  // ----------------------------------------------------

  getRelationsByRole(role: AppointmentRole): Appointment[] {
    return this.appointments.filter(a => a.hasRole(role))
  }

  // ----------------------------------------------------

  getAdmins(): Appointment[] {
    return this.appointments.getAppointmentsByRole(AppointmentRole.Admin)
  }

  // ----------------------------------------------------

  hasRole(user: User, role: AppointmentRole): boolean {
    return this.getRelation(user)?.hasRole(role) ?? false
  }

  // ----------------------------------------------------

  getMostRecentClosedRound(date: string): Round | undefined {
    const rounds = this.closedRounds.filter(round => PlatformRoundTypes.has(round.type) && isBefore(asDate(round.effectiveDate), asDate(date)))

    return rounds.length
      ? rounds[ 0 ]
      : null
  }

  // ----------------------------------------------------

  hasAccess(user: User, accessRole: AccessRole): boolean {
    return !!this.accesses.find(a => a.user.id === user.id)?.hasAccessRole(accessRole)
  }

  // ----------------------------------------------------

  get hasCaptable(): boolean {
    return this.shareNominalValue > 0 && this.shareClasses.length > 0
  }

  // ----------------------------------------------------

  // This should technically use this.getAdmins(), as that is who receives emails from us, however that information is not accessible
  // in the CompanyPublicExcerpt, so we use this.appointments to handle the majority of use cases instead
  override get isMissingEmail(): boolean {
    return this.appointments.items().every(appointment => !appointment.user.email)
  }

  // ----------------------------------------------------

  get previousOptionPoolEvents(): Array<OptionPoolEvent | Round | CohortFundingEvent> {
    const roundsWithPools = this.rounds.filter(r => r.optionCount > 0)
    const poolEvents = this.optionPools.items()
    const cohortFundingEvents = this.cohortFundings.filter(e => e.optionCount > 0)
    return [ ...roundsWithPools, ...poolEvents, ...cohortFundingEvents ].sort((e1, e2) =>
      +new Date(e1.effectiveDate) - +new Date(e2.effectiveDate)
    )
  }

  // ----------------------------------------------------

  get currentOptionPool(): OptionPoolEvent {
    return this.optionPools.find(p => !p.approved)
  }

  // ----------------------------------------------------

  get registrarNumber(): string {
    if (this.jurisdiction === Jurisdiction.France) {
      return this.greffe
    } else if (this.jurisdiction === Jurisdiction.Germany) {
      return this.amtsgericht
    } else {
      return this.companiesHouseNumber
    }
  }

}
