import { Component, ChangeDetectionStrategy } from '@angular/core'

// -----------------------------------------------------------

@Component({
  selector: 'sl-actions-menu',
  templateUrl: './actions-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsMenuComponent {}
