import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { formatDate, FormStyle, getLocaleDayNames, getLocaleFirstDayOfWeek, getLocaleMonthNames, TranslationWidth } from '@angular/common'

import { DateAdapter } from '@angular/material/core'

import { isoFormat, isoParse, isoToday } from '@libs/utils'

import { map, range } from 'ramda'
import {
  addDays,
  addMonths,
  addYears,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getTime,
  getYear,
  isValid,
  parse
} from 'date-fns'

// ------------------------------------------------------------

/**
 * Matches strings that have the form of a valid RFC 3339 string
 * (https://tools.ietf.org/html/rfc3339). Note that the string may not actually be a valid date
 * because the regex will match strings an with out of bounds month, date, etc.
 */
const ISO_8601_REGEX =
    /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/


/** Creates an array and fills it with values. */
const rangeMap = <T>(length: number, func: (index: number) => T) => map(func, range(0, length))

/** Creates a string representation of a number in a given length. Defaults to two digits. */
const padNum = (value: number, size = 2) => String(value).padStart(size, '0')

// ------------------------------------------------------------

@Injectable()
export class LocalDateStringAdapter extends DateAdapter<string, string> {

  constructor(
    @Inject(LOCALE_ID) locale: string,
  ) {
    super()
    super.setLocale(locale)
  }

  // ------------------------------------------------------------

  getYear(date: string): number {
    return getYear(isoParse(date))
  }

  getMonth(date: string): number {
    return getMonth(isoParse(date))
  }

  getDate(date: string): number {
    return getDate(isoParse(date))
  }

  getDayOfWeek(date: string): number {
    return getDay(isoParse(date))
  }

  // ------------------------------------------------------------

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const translationWidth =
      style === 'long' ? TranslationWidth.Wide
      : style === 'short' ? TranslationWidth.Abbreviated
      : TranslationWidth.Narrow

    return getLocaleMonthNames(this.locale, FormStyle.Format, translationWidth) as string[]
  }

  getDateNames(): string[] {
    return rangeMap(31, i => this._format(`2017-01-${padNum(i + 1)}`, 'd'))
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const translationWidth =
      style === 'long' ? TranslationWidth.Wide
      : style === 'short' ? TranslationWidth.Abbreviated
      : TranslationWidth.Narrow

    return getLocaleDayNames(this.locale, FormStyle.Format, translationWidth) as string[]
  }

  getYearName(date: string): string {
    return this._format(date, 'y')
  }

  // ------------------------------------------------------------

  getFirstDayOfWeek(): number {
    return getLocaleFirstDayOfWeek(this.locale)
  }

  getNumDaysInMonth(date: string): number {
    return getDaysInMonth(isoParse(date))
  }

  // ------------------------------------------------------------

  clone(date: string): string {
    return date.slice()
  }

  // ------------------------------------------------------------

  createDate(year: number, month: number, date: number): string {
    const result = `${padNum(year, 4)}-${padNum(month + 1)}-${padNum(date)}`
    if (!this.isValid(result)) {
      throw Error()
    }
    return result
  }

  today(): string {
    return isoToday()
  }

  // ------------------------------------------------------------

  parse(value: any, format?: string): string | null {
    let d: Date
    if (typeof value === 'number') {
      d = new Date(value)

    } else if (value instanceof Date) {
      d = value

    } else if (typeof value === 'string') {
      d = parse(value, format ?? 'yyyy-MM-dd', new Date())
    }

    return isValid(d) ? isoFormat(d) : null
  }

  // ------------------------------------------------------------

  format(date: string, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('LocalDateStringAdapter: Cannot format invalid date.')
    }
    return this._format(date, displayFormat)
  }

  // ------------------------------------------------------------

  addCalendarYears(date: string, years: number): string {
    return isoFormat(addYears(isoParse(date), years))
  }

  addCalendarMonths(date: string, months: number): string {
    return isoFormat(addMonths(isoParse(date), months))
  }

  addCalendarDays(date: string, days: number): string {
    return isoFormat(addDays(isoParse(date), days))
  }

  // ------------------------------------------------------------

  toIso8601(date: string): string {
    return date.split('T')[ 0 ]
  }

  // ------------------------------------------------------------

  override deserialize(value: any): string | null {
    if (typeof value === 'string') {
      if (!value) {
        return this.invalid()
      }
      // The `Date` constructor accepts formats other than ISO 8601, so we need to make sure the
      // string is the right format first.
      if (this.isDateInstance(value)) {
        return value
      }
    }
    return this.invalid()
  }

  // ------------------------------------------------------------

  isDateInstance(value: any) {
    return typeof value === 'string' && ISO_8601_REGEX.test(value)
  }

  isValid(date: string) {
    if (!this.isDateInstance(date)) {
      return false
    }
    // we only allow years between 1 and 9999
    // because on IE and Edge the i18n API will throw a hard error that can crash the entire app
    const d = isoParse(date)
    return !isNaN(getTime(d)) && getYear(d) > 0 && getYear(d) < 10000
  }

  invalid(): string {
    return null
  }

  // ------------------------------------------------------------

  /**
   * Formats a date according to locale rules.
   * Strip out unicode LTR and RTL characters. Edge and IE insert these into formatted dates while
   * other browsers do not.
   * @param date The date string to format.
   * @param format 	The date-time components to include. See https://angular.io/api/common/DatePipe for details.
   * @returns The formated date string.
   */
  private _format(date: string, format: string) {
    return formatDate(date, format, this.locale).replace(/[\u200e\u200f]/g, '')
  }
}
