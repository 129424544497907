<button (click)="onSearch()"
        mat-stroked-button
        aria-label="Search"
        i18n-aria-label
        ngClass.gt-xs="search-button"
        ngClass.lt-sm="mat-icon-button search-button-small">
  <div fxLayoutAlign.gt-xs="start center" fxLayoutAlign.lt-sm="center center">
    <mat-icon fontIcon="icon-magnify" ngClass.xs="mt-4" ngClass.gt-xs="mr-8"></mat-icon>
    <span class="hide-le-sm" i18n>
      <span class="button-chip">{{ isMac() ? '⌘' : 'Ctrl' }} + K</span> to search
    </span>
    <span class="hide-xs hide-gt-sm" i18n>Search</span>
  </div>
</button>
