import { Collection } from '../collection'
import { OnCompanyEventModel, type ApiFieldSpec } from '../model'
import { InvestmentType, InvestmentScheme } from '../../models/investment-event.model'

import type { Round } from '../events/round'
import type { Share } from './share'
import type { ShareClass } from './share-class'
import type { Investor } from './investor'
import type { Loan } from '../loan/loan'
import type { INamedEntity } from '../../models'

import { roundPps, roundTo } from '@libs/utils'
import { RoundingMode } from '../events/rounding-mode'

// ------------------------------------------------------------

export class Investment extends OnCompanyEventModel<Round> {
  readonly domain = 'investments'

  intendedOwnership: number | null
  intendedCount: number | null
  amount: number
  discount: number
  type: InvestmentType
  scheme: InvestmentScheme
  received: string

  _share: Share | null = null
  shareClass: ShareClass
  investor: Investor
  loan: Loan | null
  intent?: number

  isNomineeInvestment: boolean
  nomineeCompany: INamedEntity

  // ----------------------------------------------------

  constructor({
    company,
    event,
    shareClass,
    investor,
    scheme = InvestmentScheme.Standard,
    type = InvestmentType.Subscription,
    amount = 0,
    intendedOwnership = null,
    intendedCount = null,
    discount = 0,
    share = null,
    loan = null,
    received = null,
    isNomineeInvestment = false,
    nomineeCompany = null,
    ...data
  }) {
    super({
      company,
      event,
      shareClass,
      investor,
      loan,
      scheme,
      type,
      received,
      amount,
      intendedOwnership,
      intendedCount,
      discount,
      isNomineeInvestment,
      nomineeCompany,
      ...data
    })

    this._share = share
  }

  // ----------------------------------------------------

  get share(): Share | null {
    return this._share
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'investor', include: 'create' },
      'shareClass',
      'scheme',
      'type',
      'amount',
      'intendedOwnership',
      'intendedCount',
      'discount',
      'received',
      'loan'
    ]
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    this.event.investors.add(this.investor)
    this.event.shareClasses.add(this.shareClass)
  }

  override detach() {
    super.detach()

    this.event.investors.remove(this.investor)
    if (this.loan) {
      this.loan.investment = null
    }
    // this.event.shareClasses.remove(this.shareClass)
  }

  // ----------------------------------------------------

  override async afterRemoval() {
    if (this._share) {
      await this._share.removeWithoutApi()
      this._share = null
    }
  }

  // ----------------------------------------------------

  get investment(): number {
    return typeof this.amount === 'number'
      ? this.amount
      : this.estimatedInvestment
  }

  set investment(value: number) {
    this.amount = value
  }

  get estimatedInvestment(): number {
    return this.count * this.pricePerShare
  }

  // ----------------------------------------------------

  get shareCount(): number {
    return this.count
  }

  get count(): number {
    return this._share
      ? this._share.count
      : this.estimatedCount
  }

  // TODO: Deprecated, remove this function; its users should look up somewhere else
  // Calculations are duplicated BE-side
  get estimatedCount(): number {
    if (this.intendedCount) {
      return this.intendedCount
    }

    if (this.intendedOwnership) {
      const totalPremiumlessShares = this.event.investments.getTotalPremiumlessCount()
      const totalShares = this.company.shareCount + totalPremiumlessShares

      const totalPremiumlessEquity = this.event.investments.getTotalPremiumlessEquity()
      const totalPremiumlessSharesWithEquity = totalShares * 100 / (100 - totalPremiumlessEquity)

      return Math.ceil(totalPremiumlessSharesWithEquity * this.intendedOwnership / 100)
    }

    const amount = this.amount
    const price = this.estimatedPricePerShare

    if (typeof amount === 'number' && price > 0) {
      const count = roundTo(amount / price, 5)
      const isFloorRounding = this.isConversion || this.event.shareCountRounding === RoundingMode.FLOOR

      return isFloorRounding ? Math.floor(count) : Math.ceil(count)
    }

    return 0
  }

  // ----------------------------------------------------

  // TODO: Deprecated, remove this function; its users should look up somewhere else
  // Calculations are duplicated BE-side
  get estimatedValuation(): number {
    if (this.loan?.event.fixedValuation != null) {
      return this.loan.event.fixedValuation
    }

    const discountedRoundValuation = this.event.valuation * (1 - this.discount / 100)

    if (this.loan?.event.valuationCap != null) {
      return Math.min(discountedRoundValuation, this.loan.event.valuationCap)
    }

    return discountedRoundValuation
  }

  // ----------------------------------------------------

  get pricePerShare(): number {
    return this.estimatedPricePerShare
  }

  // TODO: Deprecated, remove this function; its users should look up somewhere else
  // Calculations are duplicated BE-side
  get estimatedPricePerShare(): number {
    if (this.event.pricePerShare !== null) {
      return roundPps(this.event.pricePerShare * (1 - this.discount / 100))
    }

    let pricePerShare = roundPps(this.estimatedValuation / this.event.estimatedPreMoneyDilutedShareCount)

    if (this.loan && !this.loan.event.downRoundAllowed) {
      pricePerShare = Math.max(pricePerShare, this.event.getPreviousRoundPricePerShare())
    }

    return pricePerShare
  }

  // ----------------------------------------------------

  get isIssued(): boolean {
    return false
  }

  // ----------------------------------------------------

  get hasDiscount(): boolean {
    return !!this.discount
  }

  // ----------------------------------------------------

  get issuedShareCount(): number {
    return this.isIssued ? this.count : 0
  }

  // ----------------------------------------------------

  get issuedInvestment(): number {
    return this.isIssued ? this.investment : 0
  }

  // ----------------------------------------------------

  get isConversion(): boolean {
    return this.type === 'CONVERSION'
  }

  // ----------------------------------------------------

  get votingShareCount(): number {
    return this.shareClass.voting * this.count
  }

  // ----------------------------------------------------

  override toString() {
    const ss = `${this.shareClass.name}, ${this.scheme}, ${this.investor.name}`
    return `${super.toString()}: £${this.amount} (~${this.count} shares, ${ss})`
  }
}

// ------------------------------------------------------------

export class InvestmentCollection extends Collection<Investment> {
  constructor() {
    super('investor.name')
  }

  // ----------------------------------------------------

  getByInvestor(investor: Investor): Investment[] {
    return this.filter(i => i.investor.id === investor.id)
  }

  // ----------------------------------------------------

  getByInvestorAndShareClass(investor: Investor, shareClass: ShareClass): Investment[] {
    return this.filter(i => i.investor.id === investor.id && i.shareClass.id === shareClass.id)
  }

  // ----------------------------------------------------

  getByShareClass(shareClass: ShareClass): Investment[] {
    return this.filter(i => i.shareClass.id === shareClass.id)
  }

  // ----------------------------------------------------

  getByType(type: InvestmentType): InvestmentCollection {
    const investments = new InvestmentCollection()
    investments.addAll(this.filter(i => i.type === type))
    return investments
  }

  // ----------------------------------------------------

  getByScheme(scheme: InvestmentScheme): InvestmentCollection {
    const investments = new InvestmentCollection()
    investments.addAll(this.filter(i => i.scheme === scheme))
    return investments
  }

  // ----------------------------------------------------

  getByReceived(received: boolean): InvestmentCollection {
    const investments = new InvestmentCollection()
    investments.addAll(this.filter(i => !!i.received === received))
    return investments
  }

  // ----------------------------------------------------

  getExceptScheme(scheme: InvestmentScheme): InvestmentCollection {
    const investments = new InvestmentCollection()
    investments.addAll(this.filter(i => i.scheme !== scheme))
    return investments
  }

  // ----------------------------------------------------

  get firstReceived(): Investment | null {
    const investments: Investment[] = this.items().sort((a, b) => a.received.localeCompare(b.received))
    return investments.length > 0 ? investments[ 0 ] : null
  }

  // ----------------------------------------------------

  get lastReceived(): Investment | null {
    const investments: Investment[] = this.items().sort((a, b) => a.received.localeCompare(b.received))
    return investments.length > 0 ? investments[ investments.length - 1 ] : null
  }

  // ----------------------------------------------------

  get estimatedInvestment(): number {
    return this.reduce((total, i) => total + i.estimatedInvestment, 0)
  }

  // ----------------------------------------------------

  getByShareClassAndScheme(shareClass: ShareClass, scheme: InvestmentScheme): Investment | undefined {
    return this.find(i => i.shareClass.id === shareClass.id && i.scheme === scheme)
  }

  // ----------------------------------------------------

  getTotalPremiumlessCount(): number {
    return this.reduce((total, i) => total + i.intendedCount, 0)
  }

  // ----------------------------------------------------

  getTotalPremiumlessEquity(): number {
    return this.reduce((total, i) => total + i.intendedOwnership, 0)
  }

  // ----------------------------------------------------

  getInvestmentsFromLoans(): Investment[] {
    return this.filter(i => !!i.loan)
  }
}
