import { animate, style, transition, trigger } from '@angular/animations'

// ----------------------------------------------------

const hiddenStyle = style({
  opacity: 0,
  transform: 'scale(0)'
})

const visibleStyle = style({
  opacity: 1,
  transform: 'scale(1)'
})

// ----------------------------------------------------

export type PopupState = 'enter' | 'leave'

export const popupFadeAndScaleAnimations = trigger(
  'popupState',
  [
    transition(':enter', [
      hiddenStyle,
      animate('.2s ease-out', visibleStyle)
    ]),
    transition(':leave', [
      visibleStyle,
      animate('.2s ease-out', hiddenStyle)
    ])
  ]
)
