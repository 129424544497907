<div fxLayout="row"
     fxLayoutAlign="space-between center"
     class="header">
  <div style="width: 40px"></div>
  <span i18n>Membership payment</span>
  <button mat-icon-button
          (click)="dialogRef.close(false)">
    <mat-icon fontIcon="icon-close"></mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<div fxLayout="column"
     class="content gap-24">

  <div>
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="billing-row">
      <span class="text-light-grey" i18n>{{ planMetaData[ data.item.plan ].name }} plan</span>
      <span class="text-semibold">{{ data.item.amountInclVAT | slCurrency:data.item.currency }}</span>
    </div>
    <mat-divider></mat-divider>
  </div>

  <sl-loading-wrapper [loading]="cardsLoading$ | async">
    <div fxLayout="column"
         fxLayoutAlign="start center"
         class="p-16 gap-32">

      <sl-stripe-cards [cards]="defaultCard$ | async"
                       [selectable]="true">
        <div *ngIf="(cards$ | async).length === 0; else manageCards"
             class="text-center p-16">

          <button (click)="addNewCardClicked()"
                  mat-button
                  color="primary"
                  i18n>
            + Add new card
          </button>
        </div>

        <ng-template #manageCards>
          <div class="text-center p-16">
            <button (click)="onManageCardsClicked()"
                    mat-button
                    color="primary"
                    i18n>
              Manage your cards
            </button>
          </div>
        </ng-template>
      </sl-stripe-cards>

      <div fxLayout="row" fxLayoutAlign="center center">
        <button (click)="onSubscribeClicked()"
                [disabled]="(defaultCard$ | async).length === 0"
                mat-raised-button
                class="pay-button"
                color="primary"
                i18n>
          Subscribe
        </button>
      </div>
    </div>
  </sl-loading-wrapper>
</div>
