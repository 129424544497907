import { Currency, CurrencyCode, getCurrencyByCode } from '@libs/models'

import { LocaleService } from '@libs/services'

// ----------------------------------------------------------

export function getCurrency(
  localeService: LocaleService | undefined,
  currencyCode?: CurrencyCode
): Currency {
  if (currencyCode) {
    return getCurrencyByCode(currencyCode)
  }

  if (localeService) {
    return localeService.currency
  }

  return getCurrencyByCode('GBP')
}
