import { sum } from 'ramda'

import { Collection } from '../collection'
import { Entity } from '../entity'
import { OnCompanyModel, INamed } from '../model'

import { DocumentCollection } from '../documents/document'
import { InvestmentCollection, Investment } from './investment'
import { ShareCollection } from './share'
import { Option } from '../options/option'
import { ShareClass } from './share-class'
import { InvestmentScheme } from '../../models/investment-event.model'
import { User } from '../user'
import { AppointmentRole } from '../appointment-roles'

// ------------------------------------------------------------

export class Investor extends OnCompanyModel implements INamed {
  entity: Entity

  documents = new DocumentCollection()
  investments = new InvestmentCollection()
  shares = new ShareCollection()

  // Contains only active options
  options: Collection<Option> = new Collection('optionGrant.effectiveDate')

  // ----------------------------------------------------

  constructor({
    company,
    entity,
    ...data
  }) {
    super({ company, entity, ...data })
  }

  // ----------------------------------------------------

  get domain() {
    return `investors`
  }

  // ----------------------------------------------------

  get type() {
    return this.entity instanceof User
      ? 'user'
      : 'company'
  }

  // ----------------------------------------------------

  get name() {
    return this.entity.name
  }

  // ----------------------------------------------------

  get avatarUrl() {
    return this.entity.avatarUrl
  }

  // ----------------------------------------------------

  get hasAvatar() {
    return this.entity.hasAvatar
  }

  // ----------------------------------------------------

  get initials() {
    return this.entity.initials
  }

  // ----------------------------------------------------

  override customisePayload(payload, mode) {
    payload = super.customisePayload(payload, mode)

    if (mode === 'create') {
      if (this.type === 'user') {
        payload.investingUser = this.entity // `/users/${this.entity.id}`
      } else {
        // TODO: Hack to prevent 400 when setting a company investor
        // : { [ `${verb}Company` ]: `/companies/${this.entity.id}` }
        payload.investingCompany = this.entity
      }
    }

    return payload
  }

  // ----------------------------------------------------

  /**
   * Call this to remove this investment.
   */
   override async beforeRemoval(api) {
    await Promise.all(this.investments.map(i => i.remove(api)))
  }

  // ----------------------------------------------------

  hasInvestmentInShareClass(shareClass: ShareClass): boolean {
    return this.investments.getByShareClass(shareClass).length > 0
  }

  // ----------------------------------------------------

  getInvestmentsForShareClass(shareClass: ShareClass): Investment[] {
    return this.investments.getByShareClass(shareClass)
  }

  // ----------------------------------------------------

  getInvestmentForShareClassAndScheme(shareClass: ShareClass, scheme: InvestmentScheme): Investment | undefined {
    return this.investments.getByShareClassAndScheme(shareClass, scheme)
  }

  // ----------------------------------------------------

  get totalOptionCount(): number {
    return sum(this.options.map(o => o.count))
  }

  // ----------------------------------------------------

  get isFounder(): boolean {
    return this.entity instanceof User && this.company.hasRole(this.entity, AppointmentRole.Founder)
  }

  // ----------------------------------------------------

  // get estimatedPrice() {
  //   return this.round.estimatedPricePerInvestment * (1 - this.discount / 100)
  // }

  // ----------------------------------------------------

  // get estimatedCount() {
  //   return this.investment > 0 && this.estimatedPrice > 0
  //     ? Math.ceil((this.investment / this.estimatedPrice).toFixed(5))
  //     : 0
  // }
}

// ------------------------------------------------------------

export class InvestorCollection extends Collection<Investor> {
  constructor() {
    super('name')
  }

  // ----------------------------------------------------

  getInvestorForEntity(entity: Entity): Investor | undefined {
    return this.find(investor => investor.entity.link === entity.link)
  }

  // ----------------------------------------------------

  getInvestorsForShareClass(shareClass: ShareClass): Investor[] {
    return this.filter(investor => investor.hasInvestmentInShareClass(shareClass))
  }

  // ----------------------------------------------------

  getInvestorsByEntityType(type: 'user' | 'company'): Investor[] {
    return this.filter(investor => investor.type === type)
  }
}
