export const enum InterestType {
  None = 'NONE',
  Simple = 'SIMPLE',
  Compound = 'COMPOUND'
}

export const InterestTypeLabels: Array<{ key: string, label: string }> = [
  { key: InterestType.None, label: 'None' },
  { key: InterestType.Simple, label: 'Simple' },
  { key: InterestType.Compound, label: 'Compound' },
]
