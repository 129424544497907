import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { UntypedFormBuilder } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { Round, Company, SeedNoteEvent } from '@libs/models'
import { ModalComponent } from '@libs/utils'

// --------------------------------------------------------

export interface FundTrackerDialogData {
  event: Round | SeedNoteEvent
  company: Company
  description: string
}

// --------------------------------------------------------

@Component({
  selector: 'sl-fund-tracker-incomplete-dialog',
  templateUrl: './fund-tracker-incomplete-dialog.component.html',
  styles: [ 'sl-simple-dialog-wrapper { max-width: 800px }' ]
})
export class FundTrackerIncompleteDialogComponent extends ModalComponent<FundTrackerIncompleteDialogComponent, FundTrackerDialogData> {

  constructor(
    fb: UntypedFormBuilder,
    override readonly dialogRef: MatDialogRef<FundTrackerIncompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) override readonly data: FundTrackerDialogData,
    private router: Router
  ){
    super(fb, dialogRef, data)
  }

  // ----------------------------------------------------

  okClicked() {
    const event = this.data.event

    this.router.navigate([ ...event.getViewState().state, 'funds-tracker' ])
    this.close()
  }
}
