import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

import { Entity } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-entity-summary',
  templateUrl: './entity-summary.component.html',
  styleUrls: [ './entity-summary.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySummaryComponent {
  @Input() entity?: Entity
  @Input() label?: string
  @Input() showPopup = false
}
