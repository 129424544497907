<div class="nav-container"
     fxLayout="column"
     fxLayoutAlign="start stretch">
  <div fxFlex
       class="scrollable">
    <ng-container *ngIf="currentCompany?.id; else noCompany">
      <div class="company-select">
        <mat-expansion-panel #expansionPanel>
          <mat-expansion-panel-header collapsedHeight="80px"
                                      expandedHeight="80px">
            <div class="company-selected-company gap-16"
                 fxLayout="row"
                 fxLayoutAlign="start center">
              <div class="avatar-wrapper">
                <sl-avatar [entity]="currentCompany.company"></sl-avatar>

                <div *ngIf="isLoading"
                     class="spinner-wrapper">
                  <mat-spinner color="primary" [diameter]="24"></mat-spinner>
                </div>
              </div>

              <div>
                <div class="company-name text-truncate">{{ currentCompany.company.name }}</div>
                <div class="company-roles">{{ currentCompany.roleInfo }}</div>
              </div>
            </div>

            <div *ngIf="moreDocumentsToSign > 0 && !expansionPanel.expanded"
                 class="more-documents-to-sign fade-in"
                 i18n>More documents to sign</div>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div *ngFor="let userCompany of otherCompanies"
                 (click)="onCompanyClicked(userCompany)"
                 class="user-company-row"
                 fxLayout="row"
                 fxLayoutAlign="space-between center">
              <div fxLayout="row"
                   fxLayoutAlign="start center"
                   class="gap-16">
                <sl-avatar [entity]="userCompany.company"></sl-avatar>

                <div>
                  <div class="company-name text-truncate">{{ userCompany.company.name }}</div>
                  <div class="company-roles">{{ userCompany.roleInfo }}</div>
                </div>
              </div>

              <ng-container *ngIf="userCompany.company.jurisdiction === 'FRA'; else commonWealthNotifications">
                <span *ngIf="userCompany.unsignedDocuments > 0"
                      class="badge fade-in"
                      fxLayoutAlign="center center">{{ userCompany.unsignedDocuments }}</span>
                </ng-container>
              <ng-template #commonWealthNotifications>
                <span *ngIf="userCompany.unsignedDocuments > 0 || userCompany.pendingDeadlinesCount > 0"
                      class="badge fade-in"
                      fxLayoutAlign="center center">{{ userCompany.unsignedDocuments + userCompany.pendingDeadlinesCount }}</span>
              </ng-template>
            </div>

            <div routerLink="/new-company"
                 class="user-company-row gap-16"
                 fxLayout="row"
                 fxLayoutAlign="start center">
              <div class="icon-wrapper">
                <mat-icon fontIcon="icon-plus"
                          color="primary"></mat-icon>
              </div>
              <div class="company-name"
                   i18n>Add new company</div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </div>

      <mat-divider></mat-divider>

      <mat-nav-list>

        <div *ngIf="showCohortPortal">
          <sl-left-nav-page-link [company]="company"
                                 target="cohorts"
                                 label="Cohort Portal"
                                 i18n-label
                                 image="cohort-portal">
            <span *ngIf="hasPendingCohort"
                  class="badge fade-in"
                  fxLayoutAlign="center center">!</span>
          </sl-left-nav-page-link>

          <mat-divider class="ml-16 mv-8"></mat-divider>

        </div>

        <sl-left-nav-page-link [company]="company"
                               target="documents"
                               label="My Documents"
                               i18n-label
                               image="documents">
          <span *ngIf="currentCompany.unsignedDocuments"
                class="badge fade-in"
                fxLayoutAlign="center center">{{ currentCompany.unsignedDocuments }}</span>
        </sl-left-nav-page-link>

        <mat-divider class="ml-16 mv-8"></mat-divider>

        <ng-container *ngFor="let group of leftNavLinks; first as isFirst; last as isLast">
          <ng-container *ngFor="let link of group">
            <sl-left-nav-page-link *ngIf="link.target !== 'calendar'"
                                   [company]="company"
                                   [target]="link.target"
                                   [label]="link.label"
                                   [image]="link.image"></sl-left-nav-page-link>

            <sl-left-nav-page-link *ngIf="link.target === 'calendar'"
                                   [company]="company"
                                   [target]="link.target"
                                   label="Calendar"
                                   i18n-label
                                   [image]="link.image">
                <span *ngIf="currentCompany.pendingDeadlinesCount"
                      class="badge fade-in"
                      fxLayoutAlign="center center">{{ currentCompany.pendingDeadlinesCount }}</span>
              </sl-left-nav-page-link>
          </ng-container>

          <mat-divider *ngIf="!isLast || isFirst"
                       class="ml-16 mv-8"></mat-divider>
        </ng-container>

        <sl-left-nav-external-link [href]="helpLink"
                                   label="Help"
                                   i18n-label
                                   image="help"></sl-left-nav-external-link>
      </mat-nav-list>
    </ng-container>

    <ng-template #noCompany>
      <div class="company-select">
        <div routerLink="/new-company"
             class="user-company-row gap-16"
             fxLayout="row"
             fxLayoutAlign="start center">
          <div class="icon-wrapper">
            <mat-icon fontIcon="icon-plus"
                      color="primary"></mat-icon>
          </div>
          <div class="company-name"
               i18n>Add new company</div>
        </div>
      </div>
    </ng-template>
  </div>

  <mat-divider></mat-divider>

  <mat-expansion-panel>
    <mat-expansion-panel-header collapsedHeight="56px"
                                expandedHeight="56px">

      <div class="user-display gap-16"
           fxLayout="row"
           fxLayoutAlign="start center">
        <sl-avatar [entity]="user"></sl-avatar>

        <div fxFlex>
          <div class="user-name">{{ user.name }}</div>
          <div *ngIf="user.email"
               class="user-email">{{ user.email }}</div>
        </div>
      </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <mat-nav-list>
        <sl-left-nav-page-link target="/account"
                               label="Profile"
                               i18n-label
                               [exact]="true"
                               image="account"></sl-left-nav-page-link>

        <sl-left-nav-page-link target="/logout"
                               label="Logout"
                               i18n-label
                               image="logout"></sl-left-nav-page-link>
      </mat-nav-list>
    </ng-template>

  </mat-expansion-panel>

</div>
