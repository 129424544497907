<div fxLayout="column" fxLayoutAlign="start stretch" class="gap-16">

  <mat-form-field>
    <mat-label i18n>Company name</mat-label>
    <input [formControl]="companiesName" matInput autofocus>
    <mat-error *ngIf="companiesName.hasError('minlength')" i18n>Must be at least 3 characters</mat-error>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>

  <sl-registrar-company-selector *ngIf="(loading$ | async) === false; else loadingBlock"
                                 [companies]="companies$ | async"
                                 [companyName]="companiesName.value"
                                 [jurisdiction]="jurisdiction"
                                 [preventExistingCompanies]="preventExistingCompanies"
                                 [allowNewCompany]="allowNewCompany$ | async"
                                 (companySelected)="onCompanySelected($event)"
                                 (notifyAdminCompanyDuplicate)="notifyAdminCompanyDuplicate($event)">
  </sl-registrar-company-selector>

  <ng-template #loadingBlock>
    <div fxLayout="row" fxLayoutAlign="center center" class="p-8">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </ng-template>
</div>
