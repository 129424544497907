<div fxLayout="row"
     fxLayoutAlign="start center"
     class="gap-4">
  <mat-icon fontIcon="icon-account-circle"
            [color]="user.isPlatformAdmin ? '' : 'accent'"></mat-icon>

  <mat-slide-toggle (toggleChange)="onUserPlatformAdminToggled()"
                    [checked]="user.isPlatformAdmin"
                    color="accent"></mat-slide-toggle>

  <mat-icon fontIcon="icon-shield-account"
            [color]="user.isPlatformAdmin ? 'accent' : ''"></mat-icon>
</div>
