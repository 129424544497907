import type { Country, DocumentTypeId } from '@libs/models'

import { ImmigrationKeyLabel } from './cohort-immigration'
import { CohortQuestion } from './cohort-member-questions'
import { CohortMemberUploadQuestion } from './cohort-member-uploads'

// -------------------------------------------------

export enum CohortCityNames {
  LONDON = 'LONDON',
  PARIS = 'PARIS',
  BERLIN = 'BERLIN',
  TORONTO = 'TORONTO',
  SINGAPORE = 'SINGAPORE'
}

// -------------------------------------------------

export type CohortCities = Record<CohortCityNames, string>

// -------------------------------------------------

export enum CohortManager {
  EntrepreneurFirst = 'EF',
  Carbon13 = 'C13',
  ClimateKIC = 'CK'
}

// ------------------------------------------------------

export const CityNames: Readonly<CohortCities>= {
  LONDON: $localize`London`,
  PARIS: $localize`Paris`,
  BERLIN: $localize`Berlin`,
  TORONTO: $localize`Toronto`,
  SINGAPORE: $localize`Singapore`,
}

// ------------------------------------------------------

export const DisabledCities = [
  CohortCityNames.SINGAPORE
]

// ------------------------------------------------------

export const EnabledCityNames: Partial<CohortCities> = Object.keys(CohortCityNames)
  .filter((cohortCity: keyof CohortCities) => !DisabledCities.includes(cohortCity))
  .reduce((filtered, key) => {
    filtered[key] = CityNames[key]
    return filtered
  }, {} )

// ------------------------------------------------------

export type CohortCity = keyof typeof EnabledCityNames

// ------------------------------------------------------


export interface ICohortConfig {
  name: string
  description: string
  toolbarBackground: string
  cohortLogo: string
  offerLetterDocType: DocumentTypeId
  FSRVDocType: DocumentTypeId
  fundingDocTypes: DocumentTypeId[]
  cohortManager: CohortManager
  cohortCountry: Country
  immigrationQuestionText: string
  immigrationTypes: ImmigrationKeyLabel[]
  cities: { code: CohortCity, name: string }[]
  cohortMemberUploads: CohortMemberUploadQuestion[]
  cohortMembersQuestions: CohortQuestion[] // better name
}
