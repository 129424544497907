<mat-form-field class="no-bottom-padding status-chip"
                [matTooltip]="tooltipText"
                i18n-matTooltip
                [matTooltipDisabled]="!toggleDisabled">
  <mat-select [formControl]="statusCtrl" panelClass="sl-test" [disabled]="toggleDisabled">
    <mat-select-trigger>
      <span class="circle mr-8" [style.backgroundColor]="selectedStatus.color"></span>
      <span>{{ selectedStatus.title }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let status of statuses" [value]="status.value">
      <span class="circle mr-8" [style.backgroundColor]="status.color"></span>
      <span>{{ status.title }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
