import type { OnInit } from '@angular/core'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef
} from '@angular/core'

import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { PriceInfoStoreService } from '../../services/price-info-store.service'

import { ProductId } from '@libs/models'
import type { PriceInfoTemplateContext } from '../../models/sidenav'

// ------------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-price-info-panel',
  templateUrl: './price-info-panel.component.html',
  styleUrls: [ './price-info-panel.component.scss' ],
  providers: [
    PriceInfoStoreService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceInfoPanelComponent implements OnInit {
  @Input() companyId: string
  @Input() hideCalculations = false
  @Input() set productId(id: ProductId) {
    this.productId$.next(id)
  }
  @Input() set value(val: number) {
    this.value$.next(val)
    this.priceInfoService.setValue(this.value$.getValue())
  }

  @ContentChild('feeTemplate') feeTemplate: TemplateRef<PriceInfoTemplateContext>
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<PriceInfoTemplateContext>
  @ContentChild('footerTemplate') footerTemplate: TemplateRef<PriceInfoTemplateContext>

  value$ = new BehaviorSubject<number>(null)
  productId$ = new BehaviorSubject<ProductId>(undefined)

  // ----------------------------------------------------

  constructor(
    readonly priceInfoService: PriceInfoStoreService
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    combineLatest([
      this.productId$,
      this.value$.pipe(
        debounceTime(800),
        startWith(this.value$.getValue()),
        distinctUntilChanged()
      )
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([ productId, value ]) => {
        this.priceInfoService.updateFromQuery({
          companyId: this.companyId,
          productId,
          value
        })
      })
  }

}
