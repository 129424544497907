<div fxLayout="column"
     class="breadcrumbs gap-8">

  <div *ngIf="crumbs.length > 0"
       fxLayoutAlign="start center"
       class="gap-16">

    <ng-container *ngFor="let crumb of crumbs; let last = last;">
      <ng-container [ngTemplateOutlet]="crumb.isLink ? linkCrumb : plainCrumb"
                    [ngTemplateOutletContext]="{ breadcrumb: crumb }"></ng-container>

      <span *ngIf="!last">></span>
    </ng-container>
  </div>

  <span [ngClass]="routeAnimationsElements"
        [title]="currentPage.label"
        class="text-truncate page-title">{{ currentPage.label }}</span>
</div>


<ng-template #linkCrumb let-breadcrumb="breadcrumb">
  <a [routerLink]="breadcrumb.route"
     [queryParams]="breadcrumb.queryParams"
     [ngClass]="routeAnimationsElements"
     class="text-truncate isLink">
     {{ breadcrumb.label }}
  </a>
</ng-template>

<ng-template #plainCrumb let-breadcrumb="breadcrumb">
  <a [ngClass]="routeAnimationsElements"
     class="text-truncate">{{ breadcrumb.label }}</a>
</ng-template>
