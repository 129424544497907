import { Component, Input, EventEmitter, Output } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-fancy-value',
  templateUrl: './fancy-value.component.html',
  styleUrls: [ './fancy-value.component.scss' ]
})
export class FancyValueComponent {
  @Input() label?: string
  @Input() boldLabel = false
  @Input() size: 'small' | 'large' = 'large'
  @Input() align: 'start' | 'end' = 'start'

  @Input() colourKey?: string

  @Output() clicked = new EventEmitter<MouseEvent>()

  // ----------------------------------------------------

  onValueClicked($event: MouseEvent) {
    this.clicked.emit($event)
  }
}
