import { Injectable } from '@angular/core'
import { FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common'

import { BehaviorSubject } from 'rxjs'

import { Currency, CurrencyCode, getCurrencyByCode } from '@libs/models'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _localeCode = 'en'
  private _currency: Currency = getCurrencyByCode('GBP')

  private _locale$ = new BehaviorSubject<string>(this._localeCode)
  locale$ = this._locale$.asObservable()

  private _currency$ = new BehaviorSubject<Currency>(this._currency)
  currency$ = this._currency$.asObservable()

  // ----------------------------------------------------

  get locale(): string {
    return this._localeCode
  }

  // ----------------------------------------------------

  get currency(): Currency {
    return this._currency
  }

  // ----------------------------------------------------

  get dayNames(): readonly string[] {
    return getLocaleDayNames(this.locale, FormStyle.Standalone, TranslationWidth.Wide)
  }

  // ----------------------------------------------------

  setLocale(localeCode: string) {
    if (localeCode) {
      this._localeCode = localeCode
      this._locale$.next(localeCode)
    }
  }

  // ----------------------------------------------------

  setCurrency(currencyCode: CurrencyCode) {
    this._currency = getCurrencyByCode(currencyCode)
    this._currency$.next(this._currency)
  }
}
