import type { HttpErrorResponse, HttpResponse } from '@angular/common/http'

import { type Observable } from 'rxjs'
import { map, tap, catchError } from 'rxjs/operators'

import type {
  RequestMethod,
  RequestOptions,
  StringToStringOrStrings,
  ParameterMap
} from '../models/request-options.model'
import type { IDownload } from '../models/download.model'

import type { Builder } from './builder'

import {
  stripSpecialFieldsFromResponse,
  updateOptions,
  processParamMap
} from './utils'

import { dataURItoBlob } from '@libs/utils'

import { DebugOptions, debugStyles as _ds } from '@env/environment'

// ----------------------------------------------------------

export interface IListResponseData {
  _embedded: {
    what: unknown
  }
}

// ----------------------------------------------------------

export class RequestBuilder implements Builder {

  readonly segments: string[] = []

  readonly requestOptions: RequestOptions = {}

  isCollection = false

  // ----------------------------------------------------

  all(entity: string): RequestBuilder {
    const rb = new RequestBuilder(this)

    rb.segments.push(entity)
    rb.isCollection = true

    return rb
  }

  // ----------------------------------------------------

  one(id: number): RequestBuilder
  one(entity: string, id?: number | string): RequestBuilder

  one(first: number | string, second?: number | string): RequestBuilder {
    const rb = new RequestBuilder(this)

    if (typeof first === 'string') {
      if (typeof second === 'number') {
        rb.segments.push(first, String(second))
      } else if (typeof second === 'string') {
        rb.segments.push(first, second)
      } else {
        rb.segments.push(first)
      }
    } else {
      if (!rb.isCollection) {
        throw new Error('Cannot get an item from a non-collection')
      }

      rb.segments.push(String(first))
    }

    rb.isCollection = false

    return rb
  }

  // ----------------------------------------------------

  withHeaders(
    headers: StringToStringOrStrings,
    append = false
  ): RequestBuilder {
    const rb = new RequestBuilder(this)
    updateOptions(rb.requestOptions, { headers }, append)
    return rb
  }

  // ----------------------------------------------------

  withoutAuthorization(): RequestBuilder {
    const rb = new RequestBuilder(this)

    if (rb.requestOptions.headers?.[ 'Authorization' ]) {
      delete rb.requestOptions.headers[ 'Authorization' ]
    }

    return rb
  }

  // ----------------------------------------------------

  withParams(
    params: StringToStringOrStrings,
    append = false
  ): RequestBuilder {
    const rb = new RequestBuilder(this)
    updateOptions(rb.requestOptions, { params }, append)
    return rb
  }

  // ----------------------------------------------------

  withOptions(
    options: RequestOptions
  ): RequestBuilder {
    const rb = new RequestBuilder(this)
    updateOptions(rb.requestOptions, options)
    return rb
  }

  // ----------------------------------------------------

  request(method: RequestMethod, segments: string[], options: RequestOptions & { responseType: 'arraybuffer' }): Observable<ArrayBuffer>
  request(method: RequestMethod, segments: string[], options: RequestOptions & { responseType: 'blob' }): Observable<Blob>
  request(method: RequestMethod, segments: string[], options: RequestOptions & { responseType: 'text' }): Observable<string>
  request(method: RequestMethod, segments: string[], options: RequestOptions & { responseType: 'json' }): Observable<object>
  request<R>(method: RequestMethod, segments: string[], options: RequestOptions & { responseType: 'json' }): Observable<R>
  request(method: RequestMethod, segments: string[], options: RequestOptions): Observable<any>

  request(
    method: RequestMethod,
    extraSegments: string[],
    extraOptions: RequestOptions
  ): Observable<any> {
    const requestUrl = [ this.baseUrl, ...this.segments, ...extraSegments ].join('/')

    const requestOptions = {}

    updateOptions(requestOptions, this.requestOptions)
    updateOptions(requestOptions, extraOptions)

    const loggingUrl = this.getUrl(requestUrl, requestOptions)

    if (DebugOptions.logConsoleOutput) {
      console.groupCollapsed(`%cREQUEST%c  ${method} %c${loggingUrl}`, _ds.requestRequest, '', _ds.requestUrl)
      console.dir(requestOptions)
      // eslint-disable-next-line no-restricted-syntax
      console.trace()
      console.groupEnd()
    }

    const start = Date.now()

    return this.httpClient.request(method, requestUrl, {
      observe: 'body',
      ...requestOptions
    })
      .pipe(
        tap(response => {
          const taken = (Date.now() - start) / 1000

          if (DebugOptions.logConsoleOutput) {
            console.groupCollapsed(`%cRESPONSE%c ${method} %c${loggingUrl}%c (%fs)`, _ds.requestResponse, '', _ds.requestUrl, '', taken)
            console.dir(response)
            console.groupEnd()
          }
        }),
        catchError((error: HttpErrorResponse) => {
          const taken = (Date.now() - start) / 1000

          if (DebugOptions.logConsoleOutput) {
            console.groupCollapsed(`%cERROR%c    ${method} %c${loggingUrl}%c (%fs)`, _ds.requestError, '', _ds.requestUrl, '', taken)
            console.warn(error)
            console.groupEnd()
          }

          throw error
        })
      )
  }

  // ----------------------------------------------------

  private processArguments(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): { segments: string[], options: RequestOptions } {
    const localSegments: string[] = []
    const localOptions: RequestOptions = {
      headers: {},
      params: {}
    }

    if (typeof first === 'string') {
      localSegments.push(first)

      if (typeof second !== 'undefined') {
        updateOptions(localOptions, {
          params: processParamMap(second as ParameterMap),
          ...third
        })
      }
    } else if (first) {
      updateOptions(localOptions, {
        params: processParamMap(first as ParameterMap),
        ...second
      })
    }

    return {
      segments: localSegments,
      options: localOptions
    }
  }

  // ----------------------------------------------------

  get<R = any>(params?: ParameterMap, options?: RequestOptions): Observable<R>
  get<R = any>(collection?: string, params?: ParameterMap, options?: RequestOptions): Observable<R>

  get<R = any>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    return this.request<R>('GET', segments, {
      ...options,
      responseType: 'json'
    })
  }

  // ----------------------------------------------------

  getList<R>(params?: ParameterMap, options?: RequestOptions): Observable<R[]>
  getList<R>(collection?: string, params?: ParameterMap, options?: RequestOptions): Observable<R[]>

  getList<R>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R[]> {
    const { segments, options } = this.processArguments(first, second, third)

    // TODO: check
    const what = this.segments[ this.segments.length - 1 ]

    return this.request<IListResponseData>('GET', segments, {
      ...options,
      responseType: 'json'
    })
      .pipe(
        map(responseData => responseData._embedded[ what ] as R[])
      )
  }

  // ----------------------------------------------------

  head<R = unknown>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<HttpResponse<R>> {
    const { segments, options } = this.processArguments(first, second, third)

    return this.request<HttpResponse<R>>('HEAD', segments, {
      ...options,
      observe: 'response',
      responseType: 'json'
    })
  }

  // ----------------------------------------------------

  options<R = unknown>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    return this.request<R>('OPTIONS', segments, {
      ...options,
      responseType: 'json'
    })
  }

  // ----------------------------------------------------

  post<R = any>(
    body?: string | object,
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    options.body = body

    return this.request('POST', segments, {
      ...options,
      responseType: 'json'
    })
      .pipe(
        map(data => stripSpecialFieldsFromResponse(data) as R)
      )
  }

  // ----------------------------------------------------

  put<R = unknown>(
    body?: object,
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    options.body = body

    return this.request('PUT', segments, {
      ...options,
      responseType: 'json'
    })
      .pipe(
        map(data => stripSpecialFieldsFromResponse(data) as R)
      )
  }

  // ----------------------------------------------------

  patch<R = any>(
    body: object,
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    options.body = body

    return this.request<R>('PATCH', segments, {
      ...options,
      responseType: 'json'
    })
      .pipe(
        map(data => stripSpecialFieldsFromResponse(data) as R)
      )
  }

  // ----------------------------------------------------

  delete<R>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    const { segments, options } = this.processArguments(first, second, third)

    return this.request<R>('DELETE', segments, {
      ...options,
      responseType: 'json'
    })
  }

  // ----------------------------------------------------

  remove<R>(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<R> {
    return this.delete(first, second, third)
  }

  // ----------------------------------------------------

  getText(params?: ParameterMap, options?: RequestOptions): Observable<string>
  getText(collection?: string, params?: ParameterMap, options?: RequestOptions): Observable<string>

  getText(
    first?: string | ParameterMap,
    second?: ParameterMap | RequestOptions,
    third?: RequestOptions
  ): Observable<string> {
    const { segments, options } = this.processArguments(first, second, third)

    return this.request('GET', segments, {
      ...options,
      responseType: 'text'
    })
  }

  // ----------------------------------------------------

  upload<R = unknown>(
    fileName: string,
    content: Blob | string,
    first?: string | ParameterMap,
    requestBody: ParameterMap = {}
  ): Observable<R> {
    if (typeof content === 'string') {
      content = dataURItoBlob(content)
    }

    const formData = new FormData()
    formData.append('file', content, fileName)

    for (const [ k, v ] of Object.entries(requestBody)) {
      formData.append(k, String(v))
    }

    return this.post<R>(formData, first)
  }

  // ----------------------------------------------------

  uploadMultiple<R = unknown>(
    files: Array<{ key: string, fileName: string, content: Blob | string }>,
    requestBody: ParameterMap = {}
  ): Observable<R> {
    const formData = new FormData()

    for (const file of files) {
      let content = file.content
      if (typeof content === 'string') {
        content = dataURItoBlob(content)
      }

      formData.append(file.key, content, file.fileName)
    }

    for (const [ k, v ] of Object.entries(requestBody)) {
      formData.append(k, String(v))
    }

    return this.post<R>(formData)
  }

  // ----------------------------------------------------

  download(
    mimeType: string,
    requestType?: 'blob',
  ): Observable<IDownload<Blob>>
  download(
    mimeType: string,
    requestType: 'arraybuffer',
  ): Observable<IDownload<ArrayBuffer>>

  download<T extends 'blob' | 'arraybuffer'>(
    mimeType: string,
    requestType: T = 'blob' as T
  ): Observable<IDownload<Blob> | IDownload<ArrayBuffer>> {
    const url = [ this.baseUrl, ...this.segments ].join('/')

    return this.httpClient.request('GET', url, {
      headers: {
        ...this.requestOptions.headers,
        Accept: mimeType
      },
      params: {
        ...this.requestOptions.params
      },
      observe: 'response',
      responseType: requestType
    }).pipe(
      map(({ headers, body: content }) => {
        const fileName = headers.get('Content-Disposition')
          ?.match(/attachment; filename="(.+)"$/)
          ?.[ 1 ]

        return { fileName, content, mimeType }
      })
    )
  }

  // ----------------------------------------------------

  get httpClient() {
    return this.parent.httpClient
  }

  // ----------------------------------------------------

  get baseUrl() {
    return this.parent.baseUrl
  }

  // ----------------------------------------------------

  private getUrl(requestUrl: string, requestOptions: { params?: object }): string {
    let url = requestUrl

    if (Object.keys(requestOptions.params).length > 0) {
      url += '?'
      url += Object.entries(requestOptions.params)
        .map(([ k, v ]) => k + '=' + encodeURIComponent(v))
        .join('&')
    }

    return url
  }

  // ----------------------------------------------------

  constructor(
    private parent: Builder
  ) {
    this.isCollection = parent.isCollection
    this.segments.push(...parent.segments)
    updateOptions(this.requestOptions, parent.requestOptions)

    // _log(`new RequestBuilder(): parent.requestOptions, requestOptions`, parent.requestOptions, this.requestOptions)
  }
}
