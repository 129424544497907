import { Component, Inject } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { map, take } from 'rxjs/operators'
import { Actions, ofType } from '@ngrx/effects'

import { INewCardDialogData } from '../../models/sidenav'

import { AnalyticsKeys, AnalyticsService } from '@libs/analytics'
import { PaymentsFacade } from '../../+state/payments.facade'
import { AddNewCardError, AddNewCardSuccess } from '../../+state/payments.actions'

import type { StripeCardComponent } from '../stripe-card/stripe-card.component'

// ------------------------------------------------------------

@Component({
  selector: 'sl-new-card-dialog',
  templateUrl: './new-card-dialog.component.html',
  styleUrls: [ './new-card-dialog.component.scss' ]
})
export class NewCardDialogComponent {
  processing = false
  cardLabelCtrl = new UntypedFormControl('', Validators.required)

  // ----------------------------------------------------

  constructor(
    private actions$: Actions,
    @Inject(MAT_DIALOG_DATA) private data: INewCardDialogData,
    public dialogRef: MatDialogRef<NewCardDialogComponent>,
    private paymentsFacade: PaymentsFacade,
    private analyticsService: AnalyticsService
  ) {}

  // ----------------------------------------------------

  onAddClicked(stripeCard: StripeCardComponent) {
    if (!this.cardLabelCtrl.valid) {
      return
    }

    this.processing = true
    this.paymentsFacade.addNewCard(this.data.company.id, this.cardLabelCtrl.value, stripeCard.cardElement)

    this.actions$.pipe(
      ofType(AddNewCardSuccess, AddNewCardError),
      map(action => action.type === '[Payments] Add New Card Success'),
      // eslint-disable-next-line rxjs/no-unsafe-first
      take(1)
    ).subscribe(success => {
      this.analyticsService.trackEvent(AnalyticsKeys.ADD_CARD, this.data.productId)
      this.processing = false
      this.dialogRef.close(success)
    })
  }

}
