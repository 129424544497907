import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { AdminRoleGuard } from '@app/auth/services/admin-role.guard'
import { AuthGuard } from '@app/auth/services/auth.guard'
import { NotConnectedGuard } from '@app/auth/services/not-connected.guard'

// ----------------------------------------------------------

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'onboarding',
          canActivate: [ AuthGuard, NotConnectedGuard ],
          children: [
            {
              path: '',
              loadChildren: () => import('@app/onboarding/onboarding.module').then(m => m.OnboardingModule)
            }
          ]
        },

        {
          path: 'witness',
          loadChildren: () => import('@app/witnessing/witnessing.module').then(m => m.WitnessingModule)
        },

        {
          path: 'admin',
          canActivate: [ AuthGuard, AdminRoleGuard ],
          loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule)
        },

        {
          path: 'pitch',
          loadChildren: () => import('@app/pitch/pitch-public/pitch-public.module').then(m => m.PitchPublicModule)
        },

        {
          path: 'portfolio',
          loadChildren: () => import('@libs/portfolio').then(m => m.PortfolioPublicModule)
        },

        {
          path: 'compare',
          loadChildren: () => import('@app/comparisons/comparison.module').then(m => m.ComparisonViewModule)
        },

        // Empty route to allow us to use a canActivate guard around our lazily loaded module.
        {
          path: '',
          canActivate: [ AuthGuard ],
          children: [
            {
              path: '',
              loadChildren: () => import('@app/alpha/alpha.module').then(m => m.AlphaModule)
            }
          ]
        }
      ],
      {
        // anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',

        // TODO: Preload modules, but only after the app is up an running, in the background
        // preloadingStrategy: PreloadAllModules,

        // Allow route parameters to be inherited by child routes
        paramsInheritanceStrategy: 'always'
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    AdminRoleGuard
  ]
})
export class AppRoutingModule { }
