import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { AuthService } from '../../services/auth.service'
import { AuthFacade } from '../../+state/auth.facade'
import { SignUp } from '../../+state/auth.actions'

import { EnvironmentConfig, IEnvironmentConfig } from '@libs/shared/tokens'
import { AuthStorageKeys } from '@app/auth/auth.constants'

// ----------------------------------------------------------

@Component({
  template: ``
})
export class SignUpPageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    @Inject(EnvironmentConfig) private environment: IEnvironmentConfig,
    private authService: AuthService,
    private facade: AuthFacade
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.authService.storeUrlParams(
      this.route.snapshot.queryParamMap,
      this.environment.tokens.email,
      this.environment.tokens.utm.medium,
      this.environment.tokens.utm.source,
      this.environment.tokens.utm.campaign,
      AuthStorageKeys.REDIRECT_URL
    )

    this.facade.dispatch(SignUp())
  }

}
