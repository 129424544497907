import { Component } from '@angular/core'

import { LayoutFacade } from '@app/layout/+state/layout.facade'

// ------------------------------------------------------------

@Component({
  template: `
    <ng-container *ngrxLet="layout.breadcrumbs$ as crumbs">
      <sl-page-layout-with-subnav [crumbs]="crumbs"></sl-page-layout-with-subnav>
    </ng-container>
  `
})
export class EventLayoutComponent {

  constructor(
    public readonly layout: LayoutFacade
  ) {}

}
