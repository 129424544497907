import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

import { type HasId, type INamedEntity } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-entity-list',
  template: `
    <div *ngFor="let entity of displayEntities"
         [style.width.px]="overlapSize">
      <sl-entity [entity]="entity"
                 [size]="size"
                 [isList]="true"></sl-entity>
    </div>
    <div [style.width.px]="overlapSize">
      <sl-more-entities [amount]="entities.length - limit"
                        [size]="size"></sl-more-entities>
    </div>
  `,
  styles: [ `
    :host {
      display: inline-flex;
      align-items: center;
    }

    div {
      position: relative;
    }
  ` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListComponent {
  @Input() entities: Array<HasId & INamedEntity>
  @Input() size = 40
  @Input() limit = 8
  @Input() overlap = 50

  get displayEntities() {
    return this.entities.slice(0, this.limit)
  }

  get overlapSize() {
    return this.size * (1 - this.overlap / 100)
  }
}
