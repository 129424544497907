import { Component, Input, Output, EventEmitter } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-button-selector',
  templateUrl: './button-selector.component.html'
})
export class ButtonSelectorComponent<T> {
  @Input() label?: string
  @Input() value?: T

  @Output() changed = new EventEmitter<T>()

  // ----------------------------------------------------

  valueChanged(newValue: T) {
    if (newValue !== this.value) {
      this.value = newValue

      this.changed.emit(newValue)
    }
  }
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-button-selector-option',
  templateUrl: './button-selector-option.component.html',
  styleUrls: [ './button-selector-option.component.scss' ]
})
export class ButtonSelectorOptionComponent<T> {
  @Input() value: T

  // ----------------------------------------------------

  constructor(
    public selector: ButtonSelectorComponent<T>
  ) {}

  // ----------------------------------------------------

  get selected() {
    return this.selector.value === this.value
  }

  // ----------------------------------------------------

  onClicked() {
    if (!this.selected) {
      this.selector.valueChanged(this.value)
    }
  }
}
