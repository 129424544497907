import { Pipe, PipeTransform } from '@angular/core'

import { clone } from 'ramda'

// ----------------------------------------------------------

@Pipe({
  name: 'clone'
})
export class ClonePipe implements PipeTransform {

  transform<T>(
    src: T
  ): T {
    return clone(src)
  }

}
