import { CountryCode } from './country.model'

// ----------------------------------------------------------

export enum Locale {
  English = 'en',
  French = 'fr'
}

// ----------------------------------------------------------

export interface Language {
  locale: Locale
  name: string
  code: CountryCode
}

// ----------------------------------------------------------

export const Languages: Language[] = [
  { locale: Locale.English, name: 'English', code: 'GB' },
  { locale: Locale.French, name: 'Français', code: 'FR' }
]

// ----------------------------------------------------------

export function getFirstSupportedLanguageFromBrowser(): Locale {
  return navigator.language.startsWith('fr') ? Locale.French : Locale.English
}
