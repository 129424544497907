<form [formGroup]="form" class="main-form">
  <sl-simple-dialog-wrapper [form]="form"
                            [headerTitle]="data.title"
                            [loading]="loading"
                            (okClicked)="onSaveClicked()">

    <span i18n>Please enter { entity.entityType, select,
          company {<b>{{entity.name}}</b>'s}
          user {<b>your</b>}
          } address. We need it for you to properly enter into this contract.
    </span>

    <div class="address-container">
      <sl-address-editor formControlName="address"
                         [required]="true"></sl-address-editor>
    </div>

  </sl-simple-dialog-wrapper>
</form>
