import { Component, EventEmitter, Input, Output } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-favourite-action-button',
  templateUrl: './favourite-action-button.component.html',
  styleUrls: [ './favourite-action-button.component.scss' ]
})
export class FavouriteActionButtonComponent {
  @Input() label: string
  @Input() icon?: string
  @Input() disabled = false
  @Output() action = new EventEmitter()
}
