import { DecimalPipe } from '@angular/common'
import { Pipe, type PipeTransform } from '@angular/core'

/**
 * Converts a Return On Investment into a human-readable version (e.g. "x7, =, −18%")
 * @param roi {Number}: Return On Investment in percentage (e.g. "7.58, 0, −0.18)
 * @returns {?string}: Human-readable version
 */
@Pipe({
  name: 'hrRoi'
})
export class HrRoiPipe implements PipeTransform {

  transform (roi: number): string {

    const locale = 'en'

    // Validate param, ROI cannot be less than -100%
    if (!Number.isFinite(roi) || roi < -1) {
      return undefined
    }

    if (roi === 0) {
      return '='
    }

    if (roi >= 1) {
      const pipe = new DecimalPipe(locale)
      return 'x' + pipe.transform(Math.round(roi + 1))
    }

    return (roi > 0 ? '+' : '−') + Math.abs(Math.round(roi * 100)) + '%'
  }
}
