import { Pipe, PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'slFindById'
})
export class FindByIdPipe implements PipeTransform {

  transform<T extends { id: string }>(
    items: T[],
    id: string
  ): T | undefined {
    return items.find(i => i.id === id)
  }

}
