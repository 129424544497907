import { Injectable } from '@angular/core'

import { MatSnackBar } from '@angular/material/snack-bar'

// ----------------------------------------------------------

export type ToastDisplayMode = 'success' | 'error'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private snackBar: MatSnackBar
  ) {}

  // ----------------------------------------------------

  show(
    message: string,
    mode: ToastDisplayMode,
    duration = 2500
  ) {
    const panelClass = mode
      ? [ 'snack-bar', `snack-bar-${mode}` ]
      : 'snack-bar'

    this.snackBar.open(message, undefined, {
      duration,
      panelClass
    })
  }

  // ----------------------------------------------------

  success(message: string, duration?: number) {
    this.show(message, 'success', duration)
  }

  // ----------------------------------------------------

  error(message: string, duration?: number) {
    this.show(message, 'error', duration)
  }
}
