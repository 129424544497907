import type { Company } from '../company'
import type { CurrencyCode } from '../../models/currency.model'
import { type BillingPeriod, BillingPeriods, type PlanId, Plans } from '../../models/plan.model'
import type { Feature } from '../../models/feature.model'

// ----------------------------------------------------------

export interface IPlanCheckoutData {
  company: Company
  item: IPlanHowMuchData
}

// ----------------------------------------------------------

export interface IPlanHowMuchData {
  amount: number
  amountInclVAT: number
  currency: CurrencyCode
  plan: PlanId
  description: string
  billingPeriod: BillingPeriod
}

// ----------------------------------------------------------

export interface IPlanData {
  id: PlanId
  features: Feature[]
  includes: PlanId[]
}

// ----------------------------------------------------------

export type PlanPrice = {
  [ key in BillingPeriod ]?: number
}

// ----------------------------------------------------------

export class Plan {
  id: PlanId
  price: PlanPrice | null = null
  features: Set<Feature> = new Set()
  includes: Set<PlanId> = new Set()
  currency?: CurrencyCode

  // ----------------------------------------------------

  constructor(planData: IPlanData) {
    this.id = planData.id

    for (const f of planData.features) {
      this.features.add(f)
    }

    for (const i of planData.includes) {
      this.includes.add(i)
    }
  }

  // ----------------------------------------------------

  setPrice(howMuch: IPlanHowMuchData): void {
    this.price = { ...this.price || {}, [ howMuch.billingPeriod ]: howMuch.amount }
    this.currency = howMuch.currency
  }

  // -----------------------------------------------------

  covers (feature: Feature): boolean {
    return this.features.has(feature)
  }

  // -----------------------------------------------------

  equals (plan: Plan): boolean {
    return this.id === plan.id
  }

  // -----------------------------------------------------

  get adminFreebieBillingPeriod(): BillingPeriod {
    return this.id === Plans.Plus ? BillingPeriods.Yearly: BillingPeriods.Monthly
  }

}
