import { createReducer, on } from '@ngrx/store'

import { clone } from 'ramda'

import type { IConfigurationData } from '../services/configuration.service'

import { ConfigurationLoaded } from './core.actions'

// ----------------------------------------------------------

export const CORE_FEATURE_KEY = 'core'

// ----------------------------------------------------------

export interface CoreState {
  configuration?: IConfigurationData
  configurationLoaded: boolean
}

// ----------------------------------------------------------

export const initialState: CoreState = {
  configurationLoaded: false
}

// ----------------------------------------------------------

export const coreReducer = createReducer(
  initialState,

  on(ConfigurationLoaded, (state, { data }) => ({
    ...state,
    configuration: clone(data),
    configurationLoaded: true
  }))
)
