import { isoFormat, today } from '@libs/utils'

import { Event } from './event'
import { EventCategory } from '../../models/category.model'

// ------------------------------------------------------------

export class StockSplitEvent extends Event {
  readonly domain = 'stockSplits'
  readonly category = EventCategory.StockSplit

  before: number
  after: number
  historical: boolean

  // ----------------------------------------------------

  constructor({
    before = 1,
    after = 100,
    historical = true,
    ...data
  }) {
    super({ before, after, historical, ...data })

    this.name = $localize`Share split`

    this.effectiveDate = this.effectiveDate || isoFormat(today())
  }

  // ----------------------------------------------------

  override get scopeField() {
    return 'stockSplit'
  }

  // ----------------------------------------------------

  override get safeName() {
    return $localize`Share split ${this.after}-for-${this.before}`
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'before',
      'after',
      'historical'
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'captable', 'stock-splits', this.id ]
    }
  }

  // ----------------------------------------------------

  get factor() {
    return this.before / this.after
  }
}
