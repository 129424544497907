<form [formGroup]="form"
      class="main-form">
  <sl-simple-dialog-wrapper [form]="form"
                            [headerTitle]="title"
                            [loading]="loading"
                            (okClicked)="onSaveClicked()">
    <div>
      <div fxLayout="column"
          class="gap-20">

        <div *ngIf="view === 'company'">
          <sl-company-form (companySelected)="companyMatch=$event"></sl-company-form>
        </div>

        <div *ngIf="view === 'user'"
            fxLayout="column"
            class="gap-10 gap-gt-md-40"
            fxLayout.gt-md="row">

          <div [formGroup]="userForm"
              fxLayout="column">
            <ng-container *ngIf="user?.connected">
              <sl-user-info [user]="user"></sl-user-info>
            </ng-container>

            <ng-container *ngIf="!user?.connected">
              <mat-form-field>
                <mat-label i18n>Email</mat-label>
                <input (focusout)="searchUser()"
                      matInput
                      type="email"
                      formControlName="email">
              </mat-form-field>

              <div fxLayout.xs="column"
                  fxLayout.gt-xs="row"
                  class="gap-gt-xs-16">
                <mat-form-field ngClass.gt-xs="no-bottom-padding"
                                fxFlex>
                  <mat-label i18n>First name</mat-label>

                  <input matInput
                        formControlName="firstName"
                        required>
                </mat-form-field>

                <mat-form-field class="no-bottom-padding"
                                fxFlex>
                  <mat-label i18n>Last name</mat-label>

                  <input matInput
                        formControlName="lastName"
                        required>
                </mat-form-field>
              </div>
              <div class="mat-hint"
                   i18n>Enter their full legal name, no nicknames or shortened versions</div>

              <h2 class="text-bold mt-0" i18n>Address</h2>

              <sl-address-editor formControlName="address"></sl-address-editor>
            </ng-container>
          </div>

          <div [formGroup]="appointmentForm">
            <h2 class="text-bold mt-0" i18n>Roles</h2>

            <div fxLayout="column"
                class="gap-10">

              <mat-checkbox formControlName="admin">
                <sl-role-bullet role="ADMIN"></sl-role-bullet>
                <div i18n>
                  Has access to everything, just like you, including adding/removing other users
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="read">
                <sl-role-bullet role="READ"></sl-role-bullet>
                <div i18n>
                  Can view your company cap table - you might want to grant this to investors
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="director">
                <sl-role-bullet role="DIRECTOR"></sl-role-bullet>
                <div *ngIf="currentCompanyRegion !== 'FRANCE'" i18n>
                  Is a Director of the company
                </div>
                <div *ngIf="currentCompanyRegion === 'FRANCE'" i18n>
                  Is the legal representative of the company (President)
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="signatory">
                <sl-role-bullet role="SIGNATORY"></sl-role-bullet>
                <div i18n>
                  Can sign documents on behalf of the company
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="founder">
                <sl-role-bullet role="FOUNDER"></sl-role-bullet>
                <div i18n>
                  Designate as a Founder in the Term Sheet, Shareholders Agreement and Warranties
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="foundingEmployee">
                <sl-role-bullet role="FND_EMPLOYEE"></sl-role-bullet>
                <div i18n>
                  One of the founding team other than a Founder, e.g. a CTO who holds shares
                </div>
              </mat-checkbox>

              <mat-checkbox formControlName="familyFounder">
                <sl-role-bullet role="FND_FAMILY"></sl-role-bullet>
                <div i18n>
                  A family member who holds shares on behalf of a Founder, but is not a Founder
                </div>
              </mat-checkbox>

              <mat-form-field class="mt-10">
                <mat-label i18n>Job title</mat-label>

                <input formControlName="position"
                      matInput>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </sl-simple-dialog-wrapper>
</form>
