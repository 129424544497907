import { Injectable } from '@angular/core'

import { Apollo, gql } from 'apollo-angular'

import type { Observable } from 'rxjs'
import { catchError, EMPTY, filter, map, switchMap, tap, withLatestFrom } from 'rxjs'

import { ComponentStore } from '@ngrx/component-store'

import type { DocumentExcerpt } from '@libs/file-upload'
import type { EmployeeExcerpt, ISearch } from '../models/search'

// ------------------------------------------------------

export interface SearchState {
  loading: boolean
  loaded: boolean
  data: ISearch
  error: any
}

const initialState: SearchState = {
  loading: false,
  loaded: false,
  data: {
    documents: [],
    employees: []
  },
  error: null
}

// ----------------------------------------------------

interface SearchResponseData {
  company: {
    employees: EmployeeExcerpt[]
    documentPage: { content: DocumentExcerpt[] }
  }
}

export const searchQuery = gql`
  query search($companyId: ID!, $q: String) {
    company(id: $companyId) {
      employees(filter: { q: $q }) {
        user {
          id
          name
          picture
        }
        keyTerms {
          employmentType
          jobTitle
          department {
            name
          }
        }
      }
      documentPage(filter: { q: $q }) {
        content {
          id
          modified
          type
          otherPartyCompany {
            name
          }
          otherPartyUser {
            name
          }
        }
      }
    }
  }
`

// ------------------------------------------------------

@Injectable()
export class SearchStore extends ComponentStore<SearchState> {
  constructor(
    private readonly apollo: Apollo
  ) {
    super(initialState)
  }

  // ------------------------------------------------------

  readonly searches$: Observable<ISearch> = this.select(state => state.data)
  readonly loading$: Observable<boolean> = this.select(state => state.loading)
  readonly loaded$: Observable<boolean> = this.select(state => state.loaded)

  // ------------------------------------------------------

  readonly reset = this.effect(
    payload$ => payload$.pipe(
      withLatestFrom(this.loaded$),
      filter(([ _, loaded ]) => loaded),
      tap(() => this.resetData())
    )
  )

  readonly search = this.effect(
    (payload$: Observable<{ companyId: string, q?: string }>) => {
      return payload$.pipe(
        tap(() => this.setLoading(true)),
        switchMap(({ companyId, q }) => {
          return this.apollo.query<SearchResponseData>({
            fetchPolicy: 'no-cache',
            query: searchQuery,
            variables: {
              companyId: companyId,
              q: q ?? ''
            }
          }).pipe(
            map(({ data: { company: { employees, documentPage: { content } } } }) => ({
              employees,
              documents: content
            })),
            tap(data => this.setSearchResults(data)),
            catchError(err => {
              this.setError(err)
              return EMPTY
            }),
          )
        })
      )
    })

  // ------------------------------------------------------

  readonly resetData = this.updater(() => ({
    ...initialState
  }))


  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading
  }))

  readonly setSearchResults = this.updater((state, data: ISearch) => ({
    ...state,
    loaded: true,
    loading: false,
    data
  }))

  readonly setError = this.updater((state, error: any) => ({
    ...state,
    loaded: true,
    loading: false,
    error
  }))
}
