import { Pipe, PipeTransform } from '@angular/core'

import { fromNow } from '@libs/utils'

// ----------------------------------------------------------

@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {

  transform(value: string | Date): string {
    try {
      return fromNow(value)
    } catch {
      return ''
    }
  }

}
