import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap, tap, mergeMap, filter } from 'rxjs/operators'

import { Store, select } from '@ngrx/store'
import { Actions, ofType, createEffect, type OnInitEffects } from '@ngrx/effects'

import { type AuthState } from './auth.reducer'
import { AuthService } from '../services/auth.service'
import { selectWhoAmIRedirectUrl } from './auth.selectors'
import {
  type AuthAction,
  AuthCallbackError,
  CheckLogin,
  GetWhoAmI,
  HandleAuthCallback,
  LoginFailure,
  LoginFound,
  LoginSuccess,
  Login,
  Logout,
  OnboardingRedirect,
  PostAuthRedirect,
  ReadWhoAmIFromStorage,
  SessionExpired,
  SignUp,
  UpdateWhoAmI,
  WhoAmIFailure,
  WhoAmINoUserExists,
  WhoAmIUserFound
} from './auth.actions'
import { CreateNewUser, LoadCurrentUser } from '@app/users/+state/users.actions'

import { AuthStorageKeys } from '../auth.constants'
import { type WhoAmIData } from '../models/whoami-data.model'

import { Api } from '@libs/backend/config/backend.config'
import { BackendService } from '@libs/backend'
import { LocaleService } from '@libs/services'
import { LocalStorageService } from '@libs/storage'

import { getFirstSupportedLanguageFromBrowser } from '@libs/models'

// ----------------------------------------------------------

@Injectable()
export class AuthEffects implements OnInitEffects {

  login$ = createEffect(() => this.actions$.pipe(
    ofType(Login),
    // logger(`AuthEffects.login$`),
    tap(() => {
      this.authService.login()
    }),
  ), { dispatch: false })

  // ----------------------------------------------------

  signUp$ = createEffect(() => this.actions$.pipe(
    ofType(SignUp),
    // logger(`AuthEffects.signUp$`),
    tap(() => {
      this.authService.signUp()
    }),
  ), { dispatch: false })

  // ----------------------------------------------------

  handleAuthCallback$ = createEffect(() => this.actions$.pipe(
    ofType(HandleAuthCallback),
    // logger(`AuthEffects.handleAuthCallback$`),
    exhaustMap(() => this.authService.parseHash().pipe(
      // logger(`AuthEffects.handleAuthCallback$`, 'authResult'),
      tap(authResult => {
        this.authService.setSession(authResult)
      }),
      map(authResult => LoginSuccess(authResult)),
      catchError((error: any) => {
        if (error.error === 'unauthorized' && error.errorDescription.startsWith('Verification email sent.')) {
          return of(AuthCallbackError({ error: 'email_not_verified' }))
        } else if (error.error === 'access_denied' && error.errorDescription === 'Unknown or invalid login ticket.') {
          return of(AuthCallbackError({ error: 'unknown_or_invalid_login' }))
        } else if (error.error === 'access_denied' && error.errorDescription === 'The user cancelled LinkedIn login') {
          return of(AuthCallbackError({ error: 'user_cancelled_login' }))
        } else if (error.error === 'user_cancelled_login') {
          return of(AuthCallbackError({ error: 'user_cancelled_login' }))
        } else if (error.error === 'invalid_request' && error.errorDescription === 'No verifier returned from client.') {
          return of(AuthCallbackError({ error: 'no_verifier' }))
        } else {
          return of(AuthCallbackError({ error: 'unknown_error' }))
        }
      })
    ))
  ))

  // ----------------------------------------------------

  authCallbackError$ = createEffect(() => this.actions$.pipe(
    ofType(AuthCallbackError),
    // logger(`AuthEffects.authCallbackError$`),
    tap(() => {
      this.router.navigate([ '/login' ])
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(LoginSuccess),
    // logger(`AuthEffects.loginSuccess$`),
    map(() => {
      const redirectUrl = this.storageService.getItem(AuthStorageKeys.REDIRECT_URL, '/')
      const emailToken = this.storageService.getItem(AuthStorageKeys.EMAIL_TOKEN)

      this.storageService.removeItems(AuthStorageKeys.REDIRECT_URL, AuthStorageKeys.EMAIL_TOKEN)

      // console.info('AuthEffects.loginSuccess$: redirectUrl = %c%s%c, emailToken = %s', 'color:#57f', redirectUrl, '', emailToken)

      return GetWhoAmI({ redirectUrl, emailToken })
    })
  ))

  // ----------------------------------------------------

  loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(LoginFailure),
    // logger(`AuthEffects.loginFailure$`),
    tap(() => {
      this.router.navigate([ '/login' ])
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  sessionExpired$ = createEffect(() => this.actions$.pipe(
    ofType(SessionExpired),
    // logger(`AuthEffects.sessionExpired$`),
    tap(() => {
      this.authService.clearAllAuthData()

      this.router.navigate([ '/login' ])
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(Logout),
    // logger('AuthEffects.logout$'),
    tap(() => {
      this.authService.logout()
    }),
  ), { dispatch: false })

  // ----------------------------------------------------

  checkLogin$ = createEffect(() => this.actions$.pipe(
    ofType(CheckLogin),
    // logger('AuthEffects.checkLogin$'),
    mergeMap(() => {
      if (this.authService.isLoggedIn()) {
        const idToken = this.authService.getAuthToken()

        // _log(`AuthEffects.checkLogin$: accessToken, idToken`, jwt_decode(accessToken), jwt_decode(idToken))

        return [ LoginFound({ idToken }) ]
      } else {
        return []
      }
    })
  ))

  // ----------------------------------------------------

  loginFound$ = createEffect(() => this.actions$.pipe(
    ofType(LoginFound),
    // logger('AuthEffects.loginFound$'),
    map(() => ReadWhoAmIFromStorage())
  ))

  // ----------------------------------------------------

  readWhoAmIFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(ReadWhoAmIFromStorage),
    map(() => this.authService.getWhoAmI()),
    map(whoAmI => whoAmI
      ? WhoAmIUserFound({ whoAmI })
      : GetWhoAmI({})
    )
  ))

  // ----------------------------------------------------

  getWhoAmI$ = createEffect(() => this.actions$.pipe(
    ofType(GetWhoAmI),
    switchMap(({ emailToken }) => {
      const params = emailToken
        ? { token: emailToken }
        : {}

      return this.api
        .one('whoAmI')
        .get<WhoAmIData>(params)
        .pipe(
          map((whoAmI: WhoAmIData) => ({
            locale: getFirstSupportedLanguageFromBrowser(),
            ...whoAmI
          })),
          map((whoAmI: WhoAmIData) => WhoAmIUserFound({ whoAmI })),
          catchError(error => {
            return of(error.status === 403
              ? WhoAmINoUserExists()
              : WhoAmIFailure({ error }))
          })
        )
    })
  ))

  // ----------------------------------------------------

  whoAmIUserFound$ = createEffect(() => this.actions$.pipe(
    ofType(WhoAmIUserFound),
    // logger(`AuthEffects.whoAmIUserFound$`),
    tap(({ whoAmI }) => {
      this.authService.storeWhoAmI(whoAmI)
      this.localeService.setLocale(whoAmI.locale)
    }),
    mergeMap(({ whoAmI }) => {
      return whoAmI.connected
        ? [ LoadCurrentUser(), PostAuthRedirect() ]
        : [ LoadCurrentUser(), OnboardingRedirect() ]
    })
  ))

  // ----------------------------------------------------

  whoAmINoUserExists$ = createEffect(() => this.actions$.pipe(
    ofType(WhoAmINoUserExists),
    // logger(`AuthEffects.whoAmIUserFound$`),
    tap(() => {
      this.authService.storeWhoAmI()
    }),
    mergeMap(() => [ CreateNewUser(), OnboardingRedirect() ])
  ))

  // ----------------------------------------------------

  whoAmIFailure$ = createEffect(() => this.actions$.pipe(
    ofType(WhoAmIFailure),
    tap(() => {
      this.router.navigate([ '/login' ])
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  updateWhoAmI$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateWhoAmI),
    tap(({ whoAmI }) => {
      this.authService.storeWhoAmI(whoAmI)
      this.localeService.setLocale(whoAmI.locale)
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  onboardingRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(OnboardingRedirect),
    tap(() => {
      // Prevent using previous user's setting.
      this.storageService.removeItem('currentCompanyId')

      this.router.navigate([ '/onboarding' ])
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  postAuthRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(PostAuthRedirect),
    mergeMap(() => this.store.pipe(select(selectWhoAmIRedirectUrl))),
    filter(redirectUrl => !!redirectUrl),
    tap(redirectUrl => {
      // _log(`postAuthRedirect$: redirectUrl, router.url`, redirectUrl, this.router.url)

      const urlTree = this.router.parseUrl(redirectUrl)

      this.router.navigateByUrl(urlTree, {
        state: {
          mustComplete: true
        },
      })
    })
  ), { dispatch: false })

  // ----------------------------------------------------

  ngrxOnInitEffects(): AuthAction {
    return CheckLogin()
  }

  // ----------------------------------------------------

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AuthState>,
    private authService: AuthService,
    @Inject(Api) private api: BackendService,
    private localeService: LocaleService,
    private storageService: LocalStorageService
  ) {}

}
