import { EventCategory } from '../../models/category.model'
import type { LeaverStatus } from '../../models/investment-event.model'
import { LeaverStatuses } from '../../models/investment-event.model'
import type { ApiFieldSpec } from '../model'
import type { OptionGrantEvent } from './option-grant-event'
import type { IEventViewState } from '../events/event'
import { Event } from '../events/event'

// ------------------------------------------------------------

export class StopVestingEvent extends Event {

  readonly category: EventCategory = EventCategory.StopVesting
  readonly domain: string = 'stopVestings'

  leaverStatus: LeaverStatus
  optionGrant: OptionGrantEvent

  // ----------------------------------------------------

  constructor({
    leaverStatus = LeaverStatuses.Bad,
    ...data
  }) {
    super({ leaverStatus, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'optionGrant', include: 'create' },
      'leaverStatus',
    ]
  }

  // ----------------------------------------------------

  get isGoodLeaver(): boolean {
    return this.leaverStatus === LeaverStatuses.Good
  }

  // ----------------------------------------------------

  get isBadLeaver(): boolean {
    return this.leaverStatus === LeaverStatuses.Bad
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.optionGrant.investor && typeof this.optionGrant.investor.name === 'string'
      ? `Vesting Stopped - ${this.optionGrant.investor.name}`
      : 'Vesting Stopped'
  }

  // ----------------------------------------------------

  getViewState(): undefined {
    return undefined
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    if (this.optionGrant) {
      this.optionGrant.stopVesting = this
    }
  }

  // -----------------------------------------------------

  override detach() {
    super.detach()

    this.optionGrant.stopVesting = null
  }
}
