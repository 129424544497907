import { Component, OnInit } from '@angular/core'

import { AuthFacade } from '../../+state/auth.facade'
import { HandleAuthCallback } from '../../+state/auth.actions'

// ----------------------------------------------------------

@Component({
  template: `
   <div class="splash-screen">
    <div class="wrapper">
      <div class="logo">
        <img src="/assets/images/app/logo-180.png">
      </div>
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>
  </div>
  `
})
export class CallbackPageComponent implements OnInit {

  constructor(
    private facade: AuthFacade
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.facade.dispatch(HandleAuthCallback())
  }

}
