import { Component, ElementRef, Injector, Input } from '@angular/core'

import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'

import { SwappPanelOverlayRef } from '../swapp-panel/swapp-panel-overlay-ref'
import { SwappPanelComponent } from '../swapp-panel/swapp-panel.component'
import { AppType } from '@libs/shared/models/swapp-data.model'

// ----------------------------------------------------------

@Component({
  selector: 'sl-swapp-icon',
  templateUrl: './swapp-icon.component.html'
})
export class SwappIconComponent {
  @Input() appType: AppType

  // ----------------------------------------------------

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private injector: Injector
  ) {}

  // ----------------------------------------------------

  getInjector(panelRef: SwappPanelOverlayRef) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: SwappPanelOverlayRef,
          useValue: panelRef
        },
        {
          provide: 'appType',
          useValue: this.appType
        }
      ]
    })
  }

  // ----------------------------------------------------

  onClicked() {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.elementRef.nativeElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ])
    })

    const panelRef = new SwappPanelOverlayRef(overlayRef)

    const portal = new ComponentPortal(SwappPanelComponent, null, this.getInjector(panelRef))

    overlayRef.attach(portal)

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose()
    })
  }
}
