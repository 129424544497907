import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { CompanyMatch } from '@app/entities/models/company-match'
import { Address, CompanyTypeNames, Entity, Jurisdiction } from '@libs/models'

// ----------------------------------------------------------

interface ICompanyRegistrationEntityDisplayData {
  name: string
  type?: string
  incorporated?: string
  registrarNumber?: string
  address?: Address
  jurisdiction?: Jurisdiction
  isNew: boolean
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-avatar-with-company-details',
  templateUrl: './avatar-with-company-details.component.html',
  styleUrls: [ './avatar-with-company-details.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarWithCompanyDetailsComponent {
  @Input() set companyMatch(value: CompanyMatch) {
    this.entity = this.assignCompanyMatchEntity(value)
    this.displayData = this.assignCompanyDisplayData(value)
  }
  entity: Entity
  displayData: ICompanyRegistrationEntityDisplayData

  @Input() size = 40
  @Input() sizeXs?: number
  @Input() highlighted = false

  // ----------------------------------------------------

  /**
   * Generate a placeholder Entity for use in the sl-avatar component.
   */
  assignCompanyMatchEntity(match: CompanyMatch): Entity {
    switch (match.source) {
      case 'local':
        return match.company
      case 'remote':
        if ('companyData' in match) {
          return { id: 'c_registered', name: match.companyData.name } as Entity
        }
      case 'new':
      default:
        return { id: 'c_new', name: match.name } as Entity
    }
  }

  // ----------------------------------------------------

  /**
   * Mapping display data from different CompanyMatch types.
   */
  assignCompanyDisplayData(match: CompanyMatch): ICompanyRegistrationEntityDisplayData {
    switch (match.source) {
      case 'local':
        return {
          name: match.company.name,
          type: CompanyTypeNames[ match.company.type ].suffix,
          incorporated: match.company.incorporated,
          address: match.company.address,
          registrarNumber: match.company.registrarNumber,
          isNew: false
        }
      case 'remote':
        if ('companyData' in match) {
          return {
            name: match.companyData.name,
            type: CompanyTypeNames[ match.companyData.type ].suffix,
            incorporated: match.companyData.incorporated,
            address: match.companyData.address as Address,
            registrarNumber: match.companyData.registrarNumber,
            isNew: false
          }
        }
      case 'new':
      default:
        return {
          name: match.name,
          jurisdiction: match.jurisdiction,
          isNew: true
        }
    }
  }
}
