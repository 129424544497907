import { Component, Input } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: [ './fullscreen-dialog.component.scss' ]
})
export class FullscreenDialogComponent {

  @Input() label: string

}
