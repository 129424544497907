import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { LocalStorageService } from './services'

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    LocalStorageService
  ]
})
export class StorageModule {}

// ----------------------------------------------------------

export { LocalStorageService }
