import type { Company, Document, Entity, ISigningStatus, Signatory, User } from '../things'
import { AppointmentRole } from '../things'

import type {
  IAdvanceAssuranceExcerptData,
  IBoardMeetingEventData,
  ICohortFundingData,
  IComplianceData,
  IConvertibleNoteExcerptData,
  IDirectorshipEventData,
  IDirectorshipTerminationEventData,
  IDirectorshipVariationEventData,
  IEmiValuationExcerptData,
  IEventData,
  IInstantConversionData,
  IInstantInvestmentConsentData,
  IExitData,
  IInvestorData,
  IOptionExerciseExcerptData,
  IOptionGrantExcerptData,
  IOptionPoolData,
  IOptionReturnData,
  IOptionSchemeExcerptData,
  IProposalData,
  IResearchAssuranceData,
  IResearchClaimData,
  IRoundExcerptData,
  ISeedNoteData,
  ISeedSaftData,
  IShareAllotmentReturnData,
  IShareClassRegistrationData,
  IShareTransferData,
  IStatementData,
  IStockSplitData,
  IStopVestingData
} from './investment-event.model'
import type {
  IEmploymentBonusPaymentEventData,
  IEmploymentEventData,
  IEmploymentStartEventData,
  IEmploymentVariationEventData
} from './employment.model'
import type { IAddressData } from './address.model'
import type { IModelData, INamedEntity, ITimestamped } from './model.model'
import type { OptionalEventFields } from './event.model'
import type { ICompanyExcerptData } from './company.model'
import type { DocumentTypeId } from './document-types.model'
import type { IUserExcerptData } from './user.model'

// ----------------------------------------------------------

export enum DocumentStatus {
  Ready = 'READY',
  Draft = 'DRAFT'
}

// ----------------------------------------------------------

export type DocumentAccess =
  | 'GRANTED'
  | 'MISSING_PLAN'
  | 'MISSING_PRODUCT'
  | 'MISSING_BOTH'

export enum DocumentAccessibility {
  Granted = 'GRANTED',
  MissingFeature = 'MISSING_PLAN',
  MissingProduct = 'MISSING_PRODUCT',
  MissingBoth = 'MISSING_BOTH'
}

// ----------------------------------------------------------

export enum SignedAsRole {
  Signatory = AppointmentRole.Signatory,
  Director = AppointmentRole.Director
}

// ----------------------------------------------------------

export interface IDocumentRelationshipBase {
  readonly document: Document
  readonly signatory?: Signatory
  readonly witnessInfo?: IUserDocumentRelationship
  readonly entity: Entity
  readonly id: string
  readonly name: string
  readonly hasInvite: boolean
  readonly canInvite: boolean
  readonly canView: boolean
  readonly canSign: boolean
  readonly hasSigned: boolean
  readonly signedOn: string | undefined
  readonly requiresWitness: boolean
  readonly needsWitness: boolean
  readonly relatedUsers: IUserDocumentRelationship[]
  readonly userSignatories: IUserDocumentRelationship[]
}

export interface ICompanyDocumentRelationship extends IDocumentRelationshipBase {
  readonly type: 'company'
  readonly company: Company

  readonly companyRole: SignedAsRole | null

  readonly employees: IUserDocumentRelationship[]
  readonly relevantEmployees: IUserDocumentRelationship[]
  readonly employeeSignatories: Array<IUserDocumentRelationship & { parent: ICompanyDocumentRelationship }>
}

export interface IUserDocumentRelationship extends IDocumentRelationshipBase {
  readonly type: 'user'
  readonly user: User

  readonly isAdmin: boolean
  readonly needsInvite: boolean
  readonly hasJoined: boolean
  readonly hasEmail: boolean
  readonly emailAddress: string | null

  loadPermission(): void
}

export type DocumentRelationship = ICompanyDocumentRelationship | IUserDocumentRelationship

// ----------------------------------------------------------

export interface ISnapshotData {
  id: string
  inserted: string
  document: string
  version: number
}

// ----------------------------------------------------------

export interface IPublishedUploadedFile {
  inserted: string
  uploader: {
    email: string
    firstName: string
    lastName: string
  }
  containsSignatures: boolean
}

// ----------------------------------------------------------

export interface IPatchDocumentResponse {
  id: string
  inserted: string
  updated: string
  status: DocumentStatus
  publishedSnapshot: ISnapshotData | null
  accessibility: DocumentAccess
  answers: object
}

// ----------------------------------------------------------

export interface IDocumentData extends IModelData, ITimestamped {
  type: DocumentTypeId
  status: DocumentStatus
  publishedSnapshot: ISnapshotData | null
  publishedUploadedFile: IPublishedUploadedFile | null
  accessibility: DocumentAccess
  company: ICompanyExcerptData
  user: IUserExcerptData | null
  employee: IUserExcerptData | null
  investor: IInvestorData | null
  answers: object

  advanceAssurance: IAdvanceAssuranceExcerptData | null
  boardMeeting: IBoardMeetingEventData | null
  cohortFunding: ICohortFundingData | null
  compliance: IComplianceData | null
  confirmationStatement: IEventData | null
  convertibleNote: IConvertibleNoteExcerptData | null
  directorship: IDirectorshipEventData | null
  directorshipVariation: IDirectorshipVariationEventData | null
  directorshipTermination: IDirectorshipTerminationEventData | null
  emiValuation: IEmiValuationExcerptData | null
  exit: IExitData | null
  foundersShareholdersAgreement: IEventData | null
  instantConversion: IInstantConversionData | null
  instantInvestmentConsent: IInstantInvestmentConsentData | null
  optionExercise: IOptionExerciseExcerptData | null
  optionGrant: IOptionGrantExcerptData | null
  optionScheme: IOptionSchemeExcerptData | null
  optionPool: IOptionPoolData | null
  optionReturn: IOptionReturnData | null
  proposal: IProposalData | null
  regularReport: IEventData | null
  researchAssurance: IResearchAssuranceData | null
  researchClaim: IResearchClaimData | null
  round: IRoundExcerptData | null
  shareClassRegistration: IShareClassRegistrationData | null
  seedNote: ISeedNoteData | null
  seedSaft: ISeedSaftData | null
  shareTransfer: IShareTransferData | null
  stockSplit: IStockSplitData | null
  statement: IStatementData | null
  stopVesting: IStopVestingData | null

  employment: IEmploymentStartEventData | null
  employmentTermination: IEmploymentEventData | null
  employmentVariation: IEmploymentVariationEventData | null
  employmentBonusPayment: IEmploymentBonusPaymentEventData | null
  shareAllotmentReturn: IShareAllotmentReturnData | null
}

// ----------------------------------------------------------

// Used when getting share/option certificates for a company
export interface IDocumentWithSignaturesData extends IDocumentData {
  signatures: ISignatureData[]
}

// ----------------------------------------------------------

export interface IDocumentWithTypeAndCompanyData extends IModelData {
  company: string
  type: DocumentTypeId
}

// ----------------------------------------------------------

export interface IDocumentExcerptData extends IModelData {
  type: DocumentTypeId
  company: ICompanyExcerptData
  user: IUserExcerptData | null
  employee: IUserExcerptData | null
  investor: IInvestorData | null
  inserted: string
  modified: string
  archived: string | null
}

// ----------------------------------------------------------

export interface ICreateDocumentPayload extends OptionalEventFields {
  company?: string
  type: DocumentTypeId
  status: DocumentStatus.Ready
  answers: object
  submitter: string
  employee?: string
  cohortMember?: string
  investor?: string
  statement?: string
}

// ----------------------------------------------------------

export interface ISignatureData extends IModelData {
  name: string
  signature: string
  signed: string
  signedAs?: SignedAsRole
  user: IUserExcerptData
  witness: IWitnessData | null
}

// ----------------------------------------------------------

export interface IWitnessData extends IModelData {
  name: string | null
  occupation: string | null
  address: IAddressData | null
  signature: string | null
  signed: string | null
}

// ----------------------------------------------------------

export interface ISignatureWithUserAddressAndDocumentData extends IModelData, INamedEntity {
  signature: string
  signed: string
  address: IAddressData
  document: IDocumentWithTypeAndCompanyData
}

// ----------------------------------------------------------

export interface ICompanySignatoryData {
  company: ICompanyExcerptData
  companyRole: SignedAsRole
  requiresWitness: boolean
  signature: ISignatureData | null
}

export interface IUserSignatoryData {
  user: IUserExcerptData
  requiresWitness: boolean
  signature: ISignatureData | null
}

export type SignatoryData = ICompanySignatoryData | IUserSignatoryData

// ----------------------------------------------------------

export interface IDocumentMetadataResponse extends IModelData {
  permissions: IDocumentEntitiesData
  sharees: IDocumentEntitiesData
  signatories: SignatoryData[]
  signingStatus: ISigningStatus
}

// ----------------------------------------------------------

export interface IEntityGroupData extends IModelData {
  name: string
  users: IUserExcerptData[]
}

// ----------------------------------------------------------

export interface IDocumentEntitiesData {
  users: IUserExcerptData[]
  companies: ICompanyExcerptData[]
  entityGroups: IEntityGroupData[]
}
