import { Component, Input, HostBinding, ViewChild, Optional, Self, ElementRef, Output, EventEmitter, Injectable, ChangeDetectionStrategy, ViewEncapsulation, Injector } from '@angular/core'
import { formatDate } from '@angular/common'
import { NgControl, UntypedFormBuilder, FormGroupDirective } from '@angular/forms'

import { FocusMonitor } from '@angular/cdk/a11y'
import { NativeDateAdapter, DateAdapter } from '@angular/material/core'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatInput } from '@angular/material/input'
import { MatDatepicker, MatCalendarHeader } from '@angular/material/datepicker'

import { Subject } from 'rxjs'

import { isValid, startOfMonth } from 'date-fns'

import { FormControlComponent, asDate, parseYearMonth, formatYearMonth, updateFormControlRequired } from '@libs/utils'

// ----------------------------------------------------------

interface IFormData {
  date: Date
}

// ----------------------------------------------------------

@Injectable()
export class YearMonthNativeDateAdapter extends NativeDateAdapter {

  override format(date: Date): string {
    if (!this.isValid(date)) {
      throw Error('YearMonthNativeDateAdapter: Cannot format invalid date.')
    }

    return formatDate(date, 'MMM y', this.locale)
  }

}

// ----------------------------------------------------------

@Component({
  templateUrl: './year-month-calendar-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearMonthCalendarHeaderComponent extends MatCalendarHeader<Date> {

  override currentPeriodClicked() {
    this.calendar.currentView = this.calendar.currentView === 'multi-year'
      ? 'year'
      : 'multi-year'
  }

}


// ----------------------------------------------------------

@Component({
  selector: 'sl-year-month-picker',
  templateUrl: './year-month-picker.component.html',
  styleUrls: [ './year-month-picker.component.scss' ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: YearMonthPickerComponent
    },
    {
      provide: DateAdapter,
      useClass: YearMonthNativeDateAdapter
    }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearMonthPickerComponent extends FormControlComponent<string>
  implements MatFormFieldControl<string> {
  static nextId = 0

  @ViewChild(MatInput, { static: true }) input: MatInput
  @ViewChild(MatDatepicker, { static: true }) datepicker: MatDatepicker<Date>

  @HostBinding() id = `sl-year-month-picker-${YearMonthPickerComponent.nextId++}`
  @HostBinding('attr.aria-describedby') describedBy = ''

  // eslint-disable-next-line rxjs/finnish
  stateChanges = new Subject<void>()

  controlType = 'sl-year-month-picker'
  calendarHeader = YearMonthCalendarHeaderComponent

  focused = false

  @Output() dateChange = new EventEmitter<string>()

  private readonly form = this.injector.get(FormGroupDirective, null)

  // ----------------------------------------------------

  @Input()
  get value(): string {
    return formatYearMonth(this.date)
  }
  set value(value: string) {
    const date = parseYearMonth(value)

    this.controls.patchValue({ date }, { emitEvent: false })

    this.stateChanges.next()
  }

  // ----------------------------------------------------

  @Input()
  get minDate(): Date | null {
    return this._minDate
  }
  set minDate(value: string | Date | null) {
    const date = new Date(value)
    this._minDate = value && this.alignDateToStandardTime(startOfMonth(date))
  }
  _minDate: Date | null = null

  // ----------------------------------------------------

  @Input()
  get maxDate(): string | Date | null {
    return this._maxDate
  }
  set maxDate(value: string | Date | null) {
    this._maxDate = value && asDate(value)
  }
  _maxDate: Date | null = null

  // ----------------------------------------------------

  @Input()
  get placeholder(): string {
    return this._placeholder
  }
  set placeholder(value: string) {
    this._placeholder = value

    this.stateChanges.next()
  }
  _placeholder: string

  // ----------------------------------------------------

  @Input()
  get required(): boolean {
    return this._required
  }
  set required(value: boolean) {
    if (this._required !== value) {
      this._required = value

      updateFormControlRequired(this.controls, 'date', this._required)

      this.controls.updateValueAndValidity()

      this.stateChanges.next()
    }
  }
  _required = false

  // ----------------------------------------------------

  @Input()
  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: boolean) {
    this._disabled = value

    if (value) {
      this.controls.disable({ emitEvent: false })
    } else {
      this.controls.enable({ emitEvent: false })
    }

    this.stateChanges.next()
  }
  _disabled = false

  @Input()
  get startView(): 'year' | 'multi-year' {
    return isValid(this.date) ? 'year' : this._startView
  }

  set startView(value: 'year' | 'multi-year') {
    this._startView = value

    this.stateChanges.next()
  }

  _startView: 'year' | 'multi-year' = 'year'

  // ----------------------------------------------------

  constructor(
    @Optional() @Self() readonly ngControl: NgControl,
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly fm: FocusMonitor,
    private readonly injector: Injector,
    override readonly fb: UntypedFormBuilder,
  ) {
    super(fb)

    if (this.ngControl) {
      this.ngControl.valueAccessor = this
      // this.ngControl.control.setValidators(control => this.validate(control))
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin
      this.stateChanges.next()
    })
  }

  // ----------------------------------------------------

  getControlGroup() {
    return this.fb.group({
      date: null
    })
  }

  // ----------------------------------------------------

  override writeValue(modelValue: string) {
    super.writeValue(modelValue)

    this.stateChanges.next()
  }

  // ----------------------------------------------------

  override changeValue(modelValue: string | undefined) {
    // _log(`YearMonthPickerComponent.changeValue(modelValue): this.date`, `#${this.id}`, modelValue, this.date)

    if (modelValue) {
      super.changeValue(modelValue)

      this.dateChange.emit(modelValue)
    }
  }

  // ----------------------------------------------------

  override convertModelDataToFormData(yearMonth: string): IFormData {
    return {
      date: parseYearMonth(yearMonth)
    }
  }

  // ----------------------------------------------------

  override convertFormDataToModelData({ date }: IFormData): string {
    // _log(`YearMonthPickerComponent.convertFormDataToModelData(date): modelData`, date, formatYearMonth(date))
    return formatYearMonth(date)
  }

  // ----------------------------------------------------

  get errorState() {
    return (this.controls.dirty || this.form?.submitted)
        && this.required && this.controls.invalid
  }

  // ----------------------------------------------------

  get date(): Date {
    return (this.controls.value as IFormData).date
  }

  // ----------------------------------------------------

  chosenMonthHandler(normalisedMonth: Date) {
    // _log(`YearMonthPickerComponent.chosenMonthHandler(normalisedMonth): this.date`, `#${this.id}`, normalisedMonth, this.date, this)

    this.datepicker.close()

    this.controls.patchValue({ date: normalisedMonth })
  }

  // ----------------------------------------------------

  get empty(): boolean {
    return !this.controls.value.date
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty
  }

  get autofilled(): boolean {
    return this.input.autofilled
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ')
  }

  onContainerClick(): void {
    this.input.onContainerClick()
    this.datepicker.open()
  }

  // ----------------------------------------------------

  alignDateToStandardTime(value: Date) {
    const offset = new Date().getTimezoneOffset() - value.getTimezoneOffset()
    return offset ? new Date(value.getTime() + offset * 60000) : value
  }

}
