<h1 mat-dialog-title>{{data.title}}</h1>

<div mat-dialog-content>

  <p style="max-width: 600px;">{{data.text}}</p>

  <mat-form-field style="width: 100%;">
    <mat-label>{{data.placeholder}}</mat-label>
    <input matInput [value]="data.initialValue" #input>
  </mat-form-field>

</div>

<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>
    {{data.cancel}}
  </button>

  <button mat-button [mat-dialog-close]="input.value" cdkFocusInitial>
    {{data.ok}}
  </button>
</div>
