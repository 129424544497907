import { Event, IEventViewState } from './event'
import { EventCategory } from '../../models/category.model'
import { EventSupportingDocument, SupportingDocumentType } from '../../models/supporting-documents.model'
import { ApiFieldSpec } from '../model'

import { taxYearsForInterval } from '@libs/utils'
import { add, differenceInYears, parseISO } from 'date-fns'

// --------------------------------------------------------

export class ResearchClaimEvent extends Event<ResearchClaimAnswers> {

  readonly category: EventCategory = EventCategory.ResearchClaim
  readonly domain: string = 'researchClaims'

  supportingDocuments: EventSupportingDocument[] = []
  taxableBalanceYear1: number | null = null
  taxableBalanceYear2: number | null = null
  startDate: string
  isIPManaged: boolean

  // ---------------------------------------------------

  constructor(data) {

    super(data)

    this.name = $localize`R&D Claim`
  }

  // ----------------------------------------------------

  get financialYearDates(): { endOfYear1: Date, startOfYear2: Date | null } {
    const hasYear2 = differenceInYears(parseISO(this.effectiveDate), parseISO(this.startDate)) > 0

    if (hasYear2) {
      const endOfYear1 = add(parseISO(this.startDate), { years: 1, days: -1 })
      return {
        endOfYear1,
        startOfYear2: add(endOfYear1, { days: 1 }) }
    } else {
      return {
        endOfYear1: parseISO(this.effectiveDate),
        startOfYear2: null
      }
    }
  }

  // ----------------------------------------------------

  get taxYears(): string[] {
    return taxYearsForInterval(this.startDate, this.effectiveDate)
  }

  // ---------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'research', 'claims', this.id ]
    }
  }

  // ---------------------------------------------------

  hasDocument(type: SupportingDocumentType, typeSpecific?: string): boolean {
    return !!this.supportingDocuments.find(d => d.type === type && d.typeSpecific === typeSpecific)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'startDate',
      'taxableBalanceYear1',
      'taxableBalanceYear2'
    ]
  }

  // ----------------------------------------------------

  // Employee costs are pre-populated on creation.
  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    this.answers = responseData.answers
  }

}

// --------------------------------------------------------

export interface FinancialYearCalculation {
  isIPManaged: boolean
  isAbusePrevented: boolean
  isAbusePreventionCapped: boolean
  isConnectedSubcontractorCapExceeded: boolean
}

// --------------------------------------------------------

export interface ResearchClaimProject {
  projectOverview: {
    title: string
    start: string
    ongoing: string
    end?: string
  }
  projectAims: {
    aims: string
    projectAchievement: string
  }
  projectReview: {
    review: string
    methodsUnique: string
  }
  projectUncertainties: {
    projectTimeline: string
    techUncertainties: string
    failure: string
  }
  projectResolution: {
    professional: string
    techResolution: string
  }
}

// --------------------------------------------------------

export interface ResearchClaimExpenses {
  employees: EmployeeCost[]
  subContractors: SubContractorCost[]
  externalWorkers: ExternalWorkerCost[]
  software: SoftwareCost[]
  consumables: ConsumableCost[]
  payments: PaymentCost[]
  dataLicenses: DataLicenseCost[]
  cloudComputing: CloudComputingCost[]
}

export interface EmployeeCostPeriod {
  salary: number
  bonus: number
  ni: number
  pension: number
}

export interface EmployeeCost {
  id: string
  title: string
  rdPercentage: number
  periods: EmployeeCostPeriod[] // [ EmployeeCostPeriod ] | [ EmployeeCostPeriod, EmployeeCostPeriod ]
}

interface WorkerCost {
  supplier: string
  workPeriodStart: string
  workPeriodEnd: string
  workDone: string
  connected: boolean
}

interface BaseCost {
  invoiceDate: string
  amountSpent: number
  rdAllowable: boolean
  rdPercentage: number
}

export interface SubContractorCost extends BaseCost, WorkerCost { }

export interface ExternalWorkerCost extends BaseCost, WorkerCost { }

export interface SoftwareCost extends BaseCost {
  supplier: string
  serviceOffered: string
}

export interface ConsumableCost extends BaseCost {
  supplier: string
  description: string
}

export interface PaymentCost extends BaseCost {
  description: string
}

export interface DataLicenseCost extends BaseCost {
  supplier: string
  description: string
}

export interface CloudComputingCost extends BaseCost {
  supplier: string
  description: string
}

export type ResearchClaimCostType =
  | 'employees'
  | 'subContractors'
  | 'externalWorkers'
  | 'software'
  | 'consumables'
  | 'payments'
  | 'dataLicenses'
  | 'cloudComputing'

export type ResearchClaimCost =
  | EmployeeCost
  | SubContractorCost
  | ExternalWorkerCost
  | SoftwareCost
  | ConsumableCost
  | PaymentCost
  | DataLicenseCost
  | CloudComputingCost

export type ResearchClaimCosts =
  | { key: 'employees', costs: EmployeeCost[] }
  | { key: 'subContractors', costs: SubContractorCost[] }
  | { key: 'externalWorkers', costs: ExternalWorkerCost[] }
  | { key: 'software', costs: SoftwareCost[] }
  | { key: 'consumables', costs: ConsumableCost[] }
  | { key: 'payments', costs: PaymentCost[] }
  | { key: 'dataLicenses', costs: DataLicenseCost[] }
  | { key: 'cloudComputing', costs: CloudComputingCost[] }

// --------------------------------------------------------

export interface ResearchClaimAnswers {
  company: {
    specialisation: string
    otherTeam?: string
    investor?: string
  }
  achievements: {
    achievements?: string
    press?: string
  }
  industry: {
    industryName: string
    revenue: number
    expectedRevenue: number
    geography: string
    expansion: boolean
    expansionCountries: string
    expansionYear: string
    customers: number
    customersExpected: number
    segment: string
  }
  opportunities: {
    uniqueProposition: string
    industryLacks: string
  }
  financialYear1: {
    isIPManaged: boolean
    totalPAYE: number
    totalEmployerNIC: number
    totalEmployeesNIC: number
  }
  financialYear2: {
    isIPManaged: boolean
    totalPAYE: number
    totalEmployerNIC: number
    totalEmployeesNIC: number
  }
  projects: ResearchClaimProject[]
  costs: ResearchClaimExpenses
}
