import { Component, Input } from '@angular/core'

// ------------------------------------------------------------

@Component({
  selector: 'sl-info-panel',
  template: `
    <div class="p-16">{{ text }}</div>
  `,
  styles: [
    `div {
      background-color: #f6f6f6;
      color: #7f7f7f;
      font-size: 14px;
    }`
  ]
})
export class InfoPanelComponent {
  @Input() text = $localize`Free to get started`
}
