import { Pipe, PipeTransform } from '@angular/core'

// ----------------------------------------------------------

@Pipe({
  name: 'slFindBy'
})
export class FindByPipe implements PipeTransform {

  transform<T, K extends keyof T>(
    items: T[],
    key: K,
    value: T[ K ]
  ): T | undefined {
    return items.find(i => i[ key ] === value)
  }

}
