<mat-toolbar>
  <mat-toolbar-row>
    <img src="/assets/images/app/logo-176x25.png">

    <div *ngIf="isNotProduction"
         class="env-bullet">{{ environment }}</div>

    <span fxFlex></span>

    <ng-content></ng-content>
  </mat-toolbar-row>
</mat-toolbar>
