import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { CallbackPageComponent } from './pages/callback-page/callback-page.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { LogoutPageComponent } from './pages/logout-page/logout-page.component'
import { SignUpPageComponent } from './pages/signup-page/signup-page.component'

// ----------------------------------------------------------

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'callback', component: CallbackPageComponent },
      { path: 'login', component: LoginPageComponent },
      { path: 'signup', component: SignUpPageComponent },
      { path: 'signUp', component: SignUpPageComponent },
      { path: 'logout', component: LogoutPageComponent },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule {}
