<ng-template #currencySelectItem let-currency>
  <div fxLayout="row" fxLayoutAlign="start center" class="gap-8">
    <div *ngIf="currency" class="flag" [ngClass]="currency.flag"></div>
    <span>{{ currency.code }} - {{ currency.label }}</span>
  </div>
</ng-template>

<mat-form-field>
  <mat-label i18n>Currency</mat-label>
  <mat-select [required]="required" [(ngModel)]="selectedValue" (ngModelChange)="changeValue($event)">
    <mat-select-trigger *ngIf="selectedCurrency">
      <ng-container *ngTemplateOutlet="currencySelectItem; context: { $implicit: selectedCurrency }"></ng-container>
    </mat-select-trigger>
    <mat-option *ngFor="let currency of currencies" [value]="currency.code">
      <ng-container *ngTemplateOutlet="currencySelectItem; context: { $implicit: currency }"></ng-container>
    </mat-option>
  </mat-select>
</mat-form-field>
