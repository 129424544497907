<mat-expansion-panel fxFlex class="fee-panel mat-elevation-z0" [expanded]="true" [hideToggle]="true">

  <mat-expansion-panel-header [collapsedHeight]="expansionPanelHeight" [expandedHeight]="expansionPanelHeight">
    <div fxLayout="column"
         fxLayoutAlign="center space-between"
         class="gap-16"
         fxFlex>
      <div fxLayout="row"
           fxLayoutAlign=" center"
           class="gap-16"
           fxFlex
           [ngClass]="{ 'text-dark-grey': isExpandable }">
        <mat-icon fontIcon="icon-information"></mat-icon>

        <span fxFlex i18n>Fee</span>

        <ng-container *ngIf="!feeTemplate">
          <span *ngIf="discountedFee.amount < totalFee.amount" class="text-strike" i18n>
            {{ totalFee | slPrice }} { totalFee.billedWithVat, select, true {&ngsp;+ VAT}}
          </span>
          <span i18n>
            { discountedFee.amount === 0, select, true {Free} false {{{ discountedFee | slPrice }}{ discountedFee.billedWithVat, select, true {&ngsp;+ VAT}}} }
          </span>
        </ng-container>

        <ng-container *ngIf="feeTemplate"
                      class="fee"
                      [ngTemplateOutlet]="feeTemplate"
                      [ngTemplateOutletContext]="templateContext"></ng-container>
      </div>

      <div *ngIf="!isExpandable"
           class="fee-marketing-block"
           i18n>You’ll also need a Standard
        <ng-container *slRegion="['COMMONWEALTH', 'FRANCE']">or Plus </ng-container>
        <a [routerLink]="[ '/companies', companyId, 'settings', 'membership' ]">membership</a>
      </div>
    </div>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <div *ngIf="isExpandable" class="breakdown-panel">

      <ng-container *ngIf="headerTemplate"
                    [ngTemplateOutlet]="headerTemplate"
                    [ngTemplateOutletContext]="templateContext"></ng-container>

      <sl-price-info-tier-list *ngIf="tiered(pricingModel)"
                               [currency]="currency"
                               [tiers]="pricingModel.tiers"
                               [minimumPrice]="pricingModel.minPrice"
                               [hideCalculations]="hideCalculations"
                               [depositFee]="depositFee"></sl-price-info-tier-list>

      <ng-container *ngIf="footerTemplate"
                    [ngTemplateOutlet]="footerTemplate"
                    [ngTemplateOutletContext]="templateContext"></ng-container>

      <div class="fee-marketing-block mt-8" i18n>You’ll also need a Standard
        <ng-container *slRegion="['COMMONWEALTH', 'FRANCE']"> or Plus </ng-container>
        <a [routerLink]="[ '/companies', companyId, 'settings', 'membership' ]">membership</a>
      </div>

    </div>
  </ng-template>

</mat-expansion-panel>

<ng-content></ng-content>
