import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class ResolveService {

  collectAll<RouteData = any>(
    route: ActivatedRouteSnapshot
  ): RouteData {
    let r = route

    const data = {} as RouteData

    for (const [ k, v ] of Object.entries(route.params)) {
      data[ k ] = v
    }

    while (r && r.routeConfig !== null) {
      Object.assign(data, r.data)

      r = r.parent
    }

    // _logtc(`ResolveService.collectAll(route, data)`, route, data)

    return data
  }

  // ----------------------------------------------------

  collectId(
    route: ActivatedRouteSnapshot,
    key: string
  ): string | undefined {
    const id = route.params[ key ]

    return id
  }
}
