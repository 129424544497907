import type { AfterViewInit } from '@angular/core'
import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core'
import { MatExpansionPanel } from '@angular/material/expansion'

import type { TieredPricingModel } from '@libs/models'
import { isTiered, CurrencyCode, Fee, PricingModel } from '@libs/models'

import type { PriceInfoTemplateContext } from '../../models/sidenav'

// ------------------------------------------------------------

@Component({
  selector: 'sl-price-info-display',
  templateUrl: './price-info-display.component.html',
  styleUrls: [ './price-info-display.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceInfoDisplayComponent implements AfterViewInit {

  @Input() companyId: string
  @Input() currency: CurrencyCode
  @Input() pricingModel: PricingModel
  @Input() discountedFee: Fee
  @Input() totalFee: Fee
  @Input() depositFee: Fee | null
  @Input() hideCalculations = false
  @Input() agreementFee: Fee | null

  @Input() feeTemplate: TemplateRef<PriceInfoTemplateContext>
  @Input() headerTemplate: TemplateRef<PriceInfoTemplateContext>
  @Input() footerTemplate: TemplateRef<PriceInfoTemplateContext>

  @ViewChild(MatExpansionPanel, { static: true }) expansionPanel: MatExpansionPanel

  // ----------------------------------------------------

  ngAfterViewInit() {
    setTimeout(() => {
      this.expansionPanel.close()
    }, 3000)
  }

  // ----------------------------------------------------

  readonly tiered = (pricingModel: PricingModel): pricingModel is TieredPricingModel => isTiered(pricingModel)

  // ----------------------------------------------------

  get templateContext(): PriceInfoTemplateContext {
    return {
      $implicit: this.discountedFee,
      currency: this.currency,
      pricingModel: this.pricingModel,
      fee: this.discountedFee,
      depositFee: this.depositFee,
      agreementFee: this.agreementFee,
      totalFee: this.totalFee
    }
  }

  // ----------------------------------------------------

  get isExpandable() {
    return !!this.headerTemplate || !!this.tiered(this.pricingModel) || !!this.footerTemplate
  }

  // ----------------------------------------------------

  get expansionPanelHeight(): string {
    let height = 56
    if (!this.isExpandable) height += 40
    return `${height}px`
  }
}
