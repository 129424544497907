import { Component, type OnInit } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'

import { Angulartics2GoogleTagManager } from 'angulartics2'

// ----------------------------------------------------------

@Component({
  selector: 'sl-app',
  template: `
    <router-outlet></router-outlet>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mat-typography'
  }
})
export class AppComponent implements OnInit {

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([
            event.url.replace(/^\/#/, '')
          ])
        }
      }
    })
  }

  // ----------------------------------------------------

  constructor(
    private router: Router,
    angularticsGTM: Angulartics2GoogleTagManager
  ) {
    angularticsGTM.startTracking()
  }
}
