import { Inject, Injectable } from '@angular/core'

import { firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'
import { omit } from 'ramda'

import {
  type BillingPeriod,
  type CardSetupIntent,
  type Company,
  type CompanyProduct,
  type ITransactionData,
  type Plan,
  type PlanId,
  Transaction,
  type TransactionRequestData
} from '@libs/models'
import type { Card, CardBrand } from '../models/cards'

import { Api, BackendService, RestApi } from '@libs/backend'

// ----------------------------------------------------------

export interface CardRequest {
  label: string
}

// ----------------------------------------------------------

export interface CardResponse {
  providerId: string
  label: string
  default: boolean
  brand: CardBrand
  expMonth: number
  expYear: number
  last4: string
}

// ----------------------------------------------------------

export interface FreeTrialReward {
  plan: PlanId
  days: number
}

// ----------------------------------------------------------

export interface PurchaseResponse {
  status: 'success' | 'requires_action'
  id?: string
  stripeClientSecret?: string
  invoiceId?: string
  transactionId?: string
  companyProductId?: string
  rewards: FreeTrialReward[]
}

// ----------------------------------------------------------

export interface SubscriptionResponse {
  status: 'success' | 'requires_action'
  stripeClientSecret?: string
}

// ----------------------------------------------------------

const omitKind = omit([ 'kind' ])

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  // ----------------------------------------------------

  async getCards(
    companyId: string
  ): Promise<Card[]> {
    try {
      return firstValueFrom(this.api.one('companies', companyId)
        .all('cards')
        .get<CardResponse[]>()
        .pipe(
          map(response => response.map(v => ({ companyId, ...v, id: v.providerId })))
        ))
    } catch (ex) {
      console.log(ex)
      throw ex
      return []
    }
  }

  // ----------------------------------------------------

  async addNewCard(
    companyId: string,
    label: string
  ): Promise<CardSetupIntent> {
    try {
      const cardRequest: CardRequest = { label }
      const cardSetupIntent = await firstValueFrom(this.api.one('companies', companyId)
        .all('cards')
        .post<CardSetupIntent>(cardRequest))

      return cardSetupIntent
    } catch (ex) {
      console.log(ex)

      throw ex
    }
  }

  // ----------------------------------------------------

  async setDefaultCard(
    companyId: string,
    cardId: string
  ): Promise<boolean> {
    try {
      await firstValueFrom(this.api.one('companies', companyId)
        .all('cards')
        .all('default')
        .post(cardId))

      return true
    } catch (ex) {
      console.log(ex)
      return false
    }
  }

  // ----------------------------------------------------

  async removeCard(
    companyId: string,
    cardId: string
  ): Promise<boolean> {
    try {
      await firstValueFrom(this.api.one('companies', companyId)
        .one('cards', cardId)
        .remove())

      return true
    } catch (ex) {
      console.log(ex)
      return false
    }
  }

  // ----------------------------------------------------

  async purchase(
    company: Company,
    payload: TransactionRequestData
  ): Promise<PurchaseResponse> {
    try {
      return await firstValueFrom(this.api
        .one('companies', company.id)
        .all('transactions')
        .post<PurchaseResponse>(omitKind(payload)))
    } catch (ex) {
      return null
    }
  }

  // ----------------------------------------------------

  async getTransactionByInvoiceId(
    invoiceId: string
  ): Promise<Transaction | null> {
    try {
      const transactionResponse = await firstValueFrom(this.restApi
        .all('transactions')
        .one('search')
        .get<ITransactionData>('findByPaymentProviderId', { p: invoiceId, projection: 'inlineTransaction' }))

      if (transactionResponse) {
        return new Transaction(transactionResponse)
      }

      return null
    } catch (e) {
      return null
    }
  }

  // ----------------------------------------------------

  async getCompanyProductByTransactionId(
    company: Company,
    transactionId: string,
  ): Promise<CompanyProduct[] | null> {
    try {

      const companyProductsResponse = await firstValueFrom(this.restApi
        .all('companyProducts')
        .getList<CompanyProduct>('search/findAllByTransactionId', { transactionId, projection: 'inlineCompanyProduct' }))

      if (companyProductsResponse) {
        for (const companyProduct of companyProductsResponse) {
          company.companyProducts.unshift({ ...companyProduct })
        }
        return company.companyProducts
      }

      return null
    } catch (e) {
      return null
    }
  }

  // ----------------------------------------------------

  async doPlanSubscription(
    company: Company,
    plan: Plan,
    billingPeriod: BillingPeriod
  ): Promise<SubscriptionResponse> {
    try {
      const response = await firstValueFrom(this.api
        .one('companies', company.id)
        .one('plans')
        .post<SubscriptionResponse>({
          plan: plan.id,
          billingPeriod
        }))

      if (response.status !== 'requires_action') {
        return {
          status: 'success'
        }
      }

      return response
    } catch (ex) {
      return null
    }
  }

  // ----------------------------------------------------

  constructor(
    @Inject(Api) private api: BackendService,
    @Inject(RestApi) private restApi: BackendService
  ) {}
}
