<ng-container *ngIf="!isSubstep">

  <sl-sticky-bar class="top bb">
    <mat-progress-bar *ngIf="selected?.processing"
                      mode="indeterminate"></mat-progress-bar>

    <div class="header-content p-8 gap-8"
         fxLayout="column"
         fxLayoutAlign="end stretch">

      <div fxLayout="row">
        <button *ngIf="canGoBack"
                class="back-button"
                mat-icon-button
                [disabled]="completed"
                type="button"
                (click)="previous()">
          <mat-icon fontIcon="icon-arrow-left"></mat-icon>
        </button>

        <ng-container *ngIf="!canGoBack">
          <span fxFlex></span>

          <button class="close-button"
                  mat-icon-button
                  [disabled]="completed"
                  type="button"
                  (click)="layoutFacade.sideNavClose()">
            <mat-icon fontIcon="icon-close"></mat-icon>
          </button>
        </ng-container>
      </div>

      <span class="mat-title mat-primary-fg mh-8">
        {{ selected.label }}
      </span>

    </div>
  </sl-sticky-bar>

  <div class="content-wrapper"
       fxLayout="column"
       fxLayoutAlign="start stretch">

    <!-- Content from the CdkStep is projected here -->
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>

  </div>

  <ng-content select="[footer]"></ng-content>

  <sl-sticky-bar class="bottom" *ngIf="buttonsVisible">
    <div class="footer-content">

      <ng-container *ngIf="isInitialStep">
        <ng-container *ngIf="showBundlePricing; else infoPanel">
          <ng-content select="sl-bundle-price-info-panel"></ng-content>
        </ng-container>

        <mat-divider></mat-divider>

      </ng-container>

      <ng-content *ngIf="!prerequisite && !isWarningStep"
                  select="sl-sidenav-stepper-prequisite-failure"></ng-content>

      <ng-container *ngIf="footerAlert">
        <ng-content select="sl-alert"></ng-content>
      </ng-container>

      <div *ngIf="prerequisite || isWarningStep"
           class="p-16">

        <button *ngIf="canGoForward"
                (click)="goForward()"
                [disabled]="!hasFinishedStep || selected?.processing"
                type="button"
                mat-flat-button
                color="primary"
                type="submit"
                i18n>{ isInitialStep, select, true {{{initialLabel}}} other {Continue} }</button>

        <button *ngIf="!canGoForward"
                (click)="goForward()"
                [disabled]="!hasFinishedStep || selected?.processing || completed"
                mat-flat-button
                type="submit"
                color="primary">{{ confirmLabel }}</button>
      </div>
    </div>
  </sl-sticky-bar>

</ng-container>

<ng-container *ngIf="isSubstep">
  <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
</ng-container>

<ng-template #infoPanel>
  <ng-content select="sl-info-panel"></ng-content>
  <ng-content select="sl-price-info-panel"></ng-content>
</ng-template>
