import { Component, EventEmitter, Input, Output, Optional, Inject, forwardRef, ContentChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core'

import { Directionality } from '@angular/cdk/bidi'
import { CdkStepper, CdkStep, STEPPER_GLOBAL_OPTIONS, StepperOptions, type StepperSelectionEvent } from '@angular/cdk/stepper'

import { LayoutFacade } from '@app/layout/+state/layout.facade'

// ----------------------------------------------------------

@Component({
  selector: 'sl-sidenav-step',
  template: `<ng-template><ng-content></ng-content></ng-template>`,
  providers: [
    { provide: CdkStep, useExisting: SidenavStepComponent }
  ]
})
export class SidenavStepComponent extends CdkStep {
  @Input() showButtons = true
  @Input() async = false
  @Input() key?: string
  @Input() backToKey?: string

  @Output() beforeStepDone = new EventEmitter<void>()
  @Output() stepDone = new EventEmitter<void>()

  processing = false

  // ------------------------------------------------

  constructor(
    @Inject(forwardRef(() => SidenavStepperComponent)) protected parentStepper: SidenavStepperComponent,
    @Optional() @Inject(STEPPER_GLOBAL_OPTIONS) stepperOptions?: StepperOptions
  ) {
    super(parentStepper, stepperOptions)
  }

  // ------------------------------------------------

  previous() {
    this.parentStepper.previous()
  }

  // ----------------------------------------------------

  next() {
    this.parentStepper.next()
  }
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-sidenav-step-with-sub-stepper',
  template: `<ng-template><ng-content></ng-content></ng-template>`,
  providers: [
    { provide: SidenavStepComponent, useExisting: SidenavStepWithSubStepperComponent }
  ]
})
export class SidenavStepWithSubStepperComponent extends SidenavStepComponent {
  onSelectionChanged(event: StepperSelectionEvent) {
    // _log(`SidenavStepWithSubStepperComponent.onSelectionChanged(event): this`, event, this)

    const step = event.selectedStep as SidenavStepComponent

    this.label = step.label
    this.showButtons = step.showButtons
  }
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-sidenav-stepper',
  templateUrl: './sidenav-stepper.component.html',
  styleUrls: [ './sidenav-stepper.component.scss' ],
  providers: [
    { provide: CdkStepper, useExisting: SidenavStepperComponent }
  ]
})
export class SidenavStepperComponent extends CdkStepper {
  @Input() parentStepper?: SidenavStepperComponent

  @Input() initialLabel = $localize`Continue`
  @Input() confirmLabel = $localize`Confirm`
  @Input() prerequisite = true
  @Input() showButtons = true
  @Input() showWarning = false
  @Input() footerAlert = false
  @Input() showBundlePricing = false

  @Output() confirmed = new EventEmitter()

  @ContentChildren(SidenavStepComponent, { descendants: true })
  override _steps: QueryList<SidenavStepComponent>

  completed = false

  // ----------------------------------------------------

  override get selected(): SidenavStepComponent | undefined {
    return this._steps
      ? this._steps.toArray()[ this.selectedIndex ]
      : undefined
  }

  // ----------------------------------------------------

  constructor(
    public layoutFacade: LayoutFacade,
    @Optional() _dir: Directionality,
    _changeDetectorRef: ChangeDetectorRef,
    _elementRef?: ElementRef<HTMLElement>,
  ) {
    super(_dir, _changeDetectorRef, _elementRef)
  }

  // ----------------------------------------------------

  get hasFinishedStep(): boolean {
    return this.selected.stepControl
      ? this.selected.stepControl.valid
      : this.selected.optional
  }

  // ----------------------------------------------------

  get buttonsVisible(): boolean {
    return !!(this.showButtons && this.selected.showButtons)
  }

  // ----------------------------------------------------

  get isSubstep(): boolean {
    return this.selected instanceof SidenavStepWithSubStepperComponent
  }

  // ----------------------------------------------------

  get isFirstStep(): boolean {
    return this.selectedIndex === 0
  }

  // ----------------------------------------------------

  get isLastStep(): boolean {
    return this.selectedIndex === this.steps.length - 1
  }

  // ----------------------------------------------------

  get canGoBack(): boolean {
    if (!this.isFirstStep) {
      return true
    }

    return this.parentStepper
      ? this.parentStepper.canGoBack
      : false
  }

  // ----------------------------------------------------

  get canGoForward(): boolean {
    if (!this.isLastStep) {
      return true
    }

    return this.parentStepper
      ? this.parentStepper.canGoForward
      : false
  }

  // ----------------------------------------------------

  /**
   * Meaning first step excluding warning step
   */
  get isInitialStep(): boolean {
    if (this.showWarning) {
      if (this.isFirstStep) {
        return false
      }

      if (this.selectedIndex === 1) {
        return true
      }
    }

    return !this.canGoBack
  }

  // ----------------------------------------------------

  get isWarningStep(): boolean {
    return this.showWarning && this.isFirstStep
  }

  // ----------------------------------------------------

  override previous() {
    if (this.selected.backToKey) {
      const step = this._steps.toArray().findIndex(s => s.key === this.selected.backToKey)

      if (step) {
        this.selectedIndex = step
        return
      }
    }

    if (!this.isFirstStep) {
      super.previous()
    } else {
      this.parentStepper.previous()
    }
  }

  // ----------------------------------------------------

  goForward() {
    this.selected.stepDone.emit()

    if (this.selected.async) {
      this.selected.processing = true
    } else {
      this._goForward()
    }
  }

  // ----------------------------------------------------

  _goForward() {
    if (!this.isLastStep) {
      this.next()
    } else if (this.parentStepper) {
      this.parentStepper._goForward()
    } else {
      this.confirmed.emit()
      this.completed = true
    }
  }

  // ----------------------------------------------------

  selectByKey(key: string) {
    const step = this._steps.toArray().findIndex(s => s.key === key)

    if (step) {
      this.selectedIndex = step
    }
  }
}
