import type { OnInit, OnDestroy } from '@angular/core'
import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core'

import { partition } from 'ramda'
import type { Subscription } from 'rxjs'

import type { Region } from '@libs/models'

import { LayoutFacade } from '@app/layout/+state/layout.facade'
import { Configuration } from '@app/core/services/configuration.service'

// ----------------------------------------------------------

type RegionParam = `${Region}` | `!${Region}`

// ----------------------------------------------------------

/**
 * A structural directive to conditionally show content based on the company's
 * region.
 *
 * An array cannot contain both inclusive or exclusive region values. If both
 * are provided, inclusive values will always take preference. Thats means that
 * if an inclusive Region is supplied all other regions will be denied unless
 * explicitly added as an inclusive region.
 *
 * @deprecated
 * The negative syntax using a `!` is deprecated. e.g. `!FRANCE`. An array of
 * positive values should be used instead.
 *
 * @example
 * ```html
 * <!-- Renders for French regions only. -->
 * <element *slRegion="'FRANCE'">...<element>
 * <!-- Renders for all regions but Commonwealth. -->
 * <element *slRegion="'!COMMONWEALTH'">...<element>
 * <!-- Renders only FRANCE and GERMANY. -->
 * <element *slRegion="['FRANCE', 'COMMONWEALTH']">...<element>
 * ```
 */
@Directive({
  selector: '[slRegion]'
})
export class RegionDirective implements OnInit, OnDestroy {
  private sub: Subscription

  private hasView = false

  private companyRegion: Region

  // ----------------------------------------------------

  private allowedRegions: Region[]
  private deniedRegions: Region[]

  @Input()
  set slRegion(regions: RegionParam | RegionParam[]) {
    regions = Array.isArray(regions) ? regions : [ regions ]

    const [ denied, allowed ] = partition(r => r.startsWith('!'), regions)
    this.allowedRegions = allowed as Region[]
    this.deniedRegions = denied.map(r => r.substring(1)) as Region[]

    this.checkMatch()
  }

  // ----------------------------------------------------

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private configuration: Configuration,
    private layout: LayoutFacade
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.sub = this.layout.currentCompany$.subscribe(company => {
      this.companyRegion = this.configuration.getRegionByJurisdiction(company.jurisdiction).id
      this.checkMatch()
    })
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.sub?.unsubscribe()
  }

  // ----------------------------------------------------

  checkMatch() {
    if (!this.companyRegion) {
      return
    }

    let matches = false

    if (this.allowedRegions.length) {
      matches = this.allowedRegions.includes(this.companyRegion)
    } else if (this.deniedRegions.length) {
      matches = !this.deniedRegions.includes(this.companyRegion)
    }

    if (matches && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      this.hasView = true
    } else if (!matches && this.hasView) {
      this.viewContainer.clear()
      this.hasView = false
    }
  }
}
