import { Directive, ElementRef, forwardRef, HostListener, Inject, Input, LOCALE_ID } from '@angular/core'
import { formatNumber } from '@angular/common'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input'

import { getDecimalSeparator, parseLocaleNumber } from '@libs/utils'

// ------------------------------------------------------------

@Directive({
  selector: 'input[slFormatNumber]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormatNumberDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormatNumberDirective),
      multi: true,
    }
  ]
})
export class FormatNumberDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('slFormatNumber')
  set slFormatNumber(type: 'float' | 'integer' | '') {
    this.numberType = type || 'float'
  }

  @Input() maxDigits = 16

  private numberType: 'float' | 'integer' = 'float'
  private _value: number | null

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange = (_: number) => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouch = () => {}

  // ----------------------------------------------------

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  // ----------------------------------------------------

  get value(): number | null {
    return this._value
  }

  // ----------------------------------------------------

  @Input()
  set value(value: number | null) {
    this._value = value
    this.formatValue(String(this._value))
  }

  // ----------------------------------------------------

  @HostListener('input', [ '$event.target' ])
  onInput(target) {
    const isInteger = this.numberType === 'integer'
    const parsedNumber = parseLocaleNumber(target.value, this.locale, isInteger, this.maxDigits)

    if (!isNaN(parsedNumber)) {
      this._value = parsedNumber
    } else {
      this._value = null
    }

    this._onChange(this._value) // here to notify Angular Validators

    // get cursorPosition and length of formatted string before it changes
    const currentLength = this.elementRef.nativeElement.value.length
    const currentCursorPosition = target.selectionEnd

    this.formatValue(target.value)

    // set the new updated cursorPosition based on how much the string's length has changed
    const newLength = this.elementRef.nativeElement.value.length
    const newCursorPosition = Math.max(0, currentCursorPosition + newLength - currentLength)

    target.setSelectionRange(newCursorPosition, newCursorPosition)
  }

  // ----------------------------------------------------

  @HostListener('blur')
  onBlur() {
    this._onTouch()
  }

  // ----------------------------------------------------

  writeValue(value: number) {
    this._value = value
    this.formatValue(String(this._value))
  }

  // ----------------------------------------------------

  registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn
  }

  // ----------------------------------------------------

  registerOnTouched(fn: () => void): void {
    this._onTouch = fn
  }

  // ----------------------------------------------------

  private formatValue(value: string | null) {
    if (this._value !== null) {
      const decimalSeparator = getDecimalSeparator(this.locale)
      const digitsInfo = this.getDecimalPlaces(value, decimalSeparator)

      this.elementRef.nativeElement.value = formatNumber(this._value, this.locale, digitsInfo)

      if (this.hasTrailingDecimalSeparator(value, decimalSeparator)) {
        this.elementRef.nativeElement.value += decimalSeparator
      }
    } else {
      this.elementRef.nativeElement.value = ''
    }
    if (this.hasInitialMinusSymbol(value)) {
      this.elementRef.nativeElement.value = '-' + this.elementRef.nativeElement.value
    }
  }

  // ----------------------------------------------------

  private getDecimalPlaces(value: string, decimalSeparator: string): string {
    const length = String(this._value).length

    if (length >= this.maxDigits) {
      return '1.0-10'
    }

    const index = value.indexOf(decimalSeparator)

    if (this.numberType === 'integer') {
      return '1.0-0'
    } else if (index > -1 ) {
      return '1.' + String(Math.min(9, value.substring(index).replace(/[^0-9]/g, '').length)) + '-10'
    } else {
      return '1.0-10'
    }
  }

  // ----------------------------------------------------

  private hasTrailingDecimalSeparator(value: string, decimalSeparator: string): boolean {
    return this.numberType === 'float' && value.includes(decimalSeparator)
      && !this.elementRef.nativeElement.value.includes(decimalSeparator)
  }

  // ----------------------------------------------------

  private hasInitialMinusSymbol(value: string): boolean {
    return value[ 0 ] === '-' && !this.elementRef.nativeElement.value.includes('-')
  }


}
