import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IEntity } from '@app/entities/models/entity-sidenav'
import { Entity, User } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-entity-selector-entity-list',
  templateUrl: './entity-selector-entity-list.component.html',
  styleUrls: [ './entity-selector-entity-list.component.scss' ]
})
export class EntitySelectorEntityListComponent<E extends IEntity = IEntity> {

  @Input() entities: E[]
  @Output() entitySelected = new EventEmitter<E>()

  // ----------------------------------------------------

  isUserEntity = (entity: Entity): entity is User => entity instanceof User

  // ----------------------------------------------------

  onEntitySelected(entity: E) {
    this.entitySelected.emit(entity)
  }

}
