export enum GBImmigrationType {
  Citizen = 0,
  Resident = 1,
  Settled = 2,
  Standard = 3,
  T1 = 4,
  StartUp = 5,
  Innovator = 6,
  T4 = 7,
  T2 = 8,
  T5 = 9
}

// ------------------------------------------------------

export enum FRImmigrationType {
  Talent = 11,
  Resident = 12,
  RECE = 13,
  None = 14
}

// ------------------------------------------------------

export enum DEImmigrationType {
  Citizen = 14,
  EUPassport = 15,
  EEAPassport = 16,
  Resident = 17
}

// -------------------------------------------------------

export enum SGImmigrationType {
  Entrepass = 18,
  StudentPass = 19,
  EmployementPass = 20,
  DependantPass = 21,
  ShortTermVisit = 22,
  Citizen = 23,
  Resident = 24
}

// -------------------------------------------------------

export enum CAImmigrationType {
  Citizen = 25,
  NAFTA = 26,
  IEC = 27,
  Resident = 28
}

// -------------------------------------------------------

export enum OtherVisaType {
  OtherVisa = 10
}

// ----------------------------------------------------

export const GBCohortMemberImmigrationTypes = [
  { key: GBImmigrationType.Citizen, label: $localize`British citizen`, },
  { key: GBImmigrationType.Resident, label: $localize`Permanent residence (e.i. ILR/Settled Status)`, },
  { key: GBImmigrationType.Settled, label: $localize`Pre-settled status (EEA, Swiss, non-EEA family)`, },
  { key: GBImmigrationType.Standard, label: $localize`Standard Visitor Visa (Prospective Entrepreneur)`, },
  { key: GBImmigrationType.T1, label: $localize`Tier 1 Visa (Global Talent)`, },
  { key: GBImmigrationType.StartUp, label: $localize`Start-up Visa`, },
  { key: GBImmigrationType.Innovator, label: $localize`Innovator Visa`, },
  { key: GBImmigrationType.T4, label: $localize`Tier 4 Visa (Doctorate Extension Scheme)`, },
  { key: GBImmigrationType.T2, label: $localize`Tier 2 Dependant Visa`, },
  { key: GBImmigrationType.T5, label: $localize`Tier 5 Visa (Youth Mobility Scheme)` },
  { key: OtherVisaType.OtherVisa, label: $localize`Other Visa` },
]

// ----------------------------------------------------

export const FRCohortMemberImmigrationTypes = [
  { key: FRImmigrationType.None, label: $localize`No visa needed`, },
  { key: FRImmigrationType.Talent, label: $localize`Talent Passport Business Creation Visa`, },
  { key: FRImmigrationType.Resident, label: $localize`Entrepreneur/Profession Libérale visa or residency permit`, },
  { key: FRImmigrationType.RECE, label: $localize`Residency Permit Recherche d’emploi et Création d’emploi (RECE)`, },
  { key: OtherVisaType.OtherVisa, label: $localize`Other Visa` },
]

// ----------------------------------------------------

export const DECohortMemberImmigrationTypes = [
  { key: DEImmigrationType.Citizen, label: $localize`Current German Passport`, },
  { key: DEImmigrationType.EUPassport, label: $localize`Current European Union Passport`, },
  { key: DEImmigrationType.EEAPassport, label: $localize`Current EEA Passport`, },
  { key: DEImmigrationType.Resident, label: $localize`German Permanent Residency`, },
  { key: OtherVisaType.OtherVisa, label: $localize`Other Visa` },
]

// ----------------------------------------------------

export const SGCohortMemberImmigrationTypes = [
  { key: SGImmigrationType.Entrepass, label: $localize`Entrepass`, },
  { key: SGImmigrationType.StudentPass, label: $localize`Student Pass`, },
  { key: SGImmigrationType.EmployementPass, label: $localize`Employment Pass`, },
  { key: SGImmigrationType.DependantPass, label: $localize`Dependant's Pass`, },
  { key: SGImmigrationType.ShortTermVisit, label: $localize`Short Term Visit Pass`, },
  { key: SGImmigrationType.Citizen, label: $localize`Singapore Citizen`, },
  { key: SGImmigrationType.Resident, label: $localize`Singapore Resident` },
  { key: OtherVisaType.OtherVisa, label: $localize`Other Visa` },
]

// ----------------------------------------------------

export const CACohortMemberImmigrationTypes = [
  { key: CAImmigrationType.Citizen, label: $localize`Current Canadian Passport`, },
  { key: CAImmigrationType.NAFTA, label: $localize`Current NAFTA (USA, Mexico) Passport`, },
  { key: CAImmigrationType.IEC, label: $localize`Current IEC/ Working Holiday Passport`, },
  { key: CAImmigrationType.Resident, label: $localize`Canadian Permanent Residency`, },
  { key: OtherVisaType.OtherVisa, label: $localize`Other Visa` },
]

// ---------------------------------------------------------

export type ImmigrationType =
  | GBImmigrationType
  | FRImmigrationType
  | DEImmigrationType
  | CAImmigrationType
  | SGImmigrationType
  | OtherVisaType

// -------------------------------------------------

export interface ImmigrationKeyLabel {
  key: ImmigrationType
  label: string
}

// -------------------------------------------------

// export const AllCohortImmigrationQuesionsMap = [
//   ...CACohortImmigrationQuestionsMap,
//   ...FRCohortImmigrationQuestionsMap,
//   ...DECohortImmigrationQuestionsMap,
//   ...FRCohortImmigrationQuestionsMap,
//   ...GBCohortImmigrationQuestionsMap
// ]
