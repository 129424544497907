<mat-card class="mat-elevation-z0">

  <mat-card-header fxLayout="row"
                   fxLayoutAlign="start center">

    <!-- User or Company : Avatar, Name -->
    <sl-avatar [entity]="user || company"
               mat-card-avatar></sl-avatar>

    <mat-card-title>{{ investor.name }}</mat-card-title>

    <!-- User : Email -->
    <mat-card-subtitle *ngIf="user && user.email"
                       fxLayout="row"
                       class="gap-2">
      <span>{{ user.email }}</span>

      <mat-icon *ngIf="user.connected"
                fontIcon="icon-check-circle"
                matTooltip="Email verified"
                inline></mat-icon>
    </mat-card-subtitle>

    <!-- Company: Website -->
    <mat-card-subtitle *ngIf="company && company.website">
      <a [href]="company.website | addUrlScheme"
         target="_blank">
        {{ company.website | stripUrlScheme }}
      </a>
    </mat-card-subtitle>

    <div fxFlex></div>

    <button (click)="close()"
            mat-icon-button>
      <mat-icon fontIcon="icon-close"></mat-icon>
    </button>
  </mat-card-header>

  <mat-card-content fxLayout="column"
                    fxLayoutAlign="start stretch"
                    class="gap-16">

    <ng-container *ngIf="data.templateRef"
                  [ngTemplateOutlet]="data.templateRef"
                  [ngTemplateOutletContext]="{ $implicit: data.summary }"></ng-container>

  </mat-card-content>

  <mat-card-actions *ngIf="canEdit || data.showDelete">
    <button *ngIf="canEdit"
            mat-stroked-button
            color="primary"
            i18n
            (click)="onEditClicked()">
      Edit
    </button>

    <button *ngIf="data.showDelete"
            mat-stroked-button
            color="warn"
            i18n
            (click)="onDeleteClicked()">
      Delete
    </button>

  </mat-card-actions>

</mat-card>
