<button (click)="actionClick.emit($event)"
        [disabled]="disabled"
        mat-menu-item
        aria-labelledby="ariaId"
        type="button">

  <mat-icon fontIcon="icon-{{ icon }}"></mat-icon>

  <span id="{{ ariaId }}"
        [ngClass]="{ 'mat-warn-fg' : warn }">
    <ng-content></ng-content>
  </span>

</button>
