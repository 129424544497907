import { ChangeDetectionStrategy, Component, Injectable, ViewEncapsulation } from '@angular/core'
import { DateAdapter, NativeDateAdapter } from '@angular/material/core'
import { formatDate } from '@angular/common'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatCalendarHeader } from '@angular/material/datepicker'

import {
  YearMonthPickerComponent
} from '../year-month-picker/year-month-picker.component'

// ----------------------------------------------------------

@Injectable()
export class YearNativeDateAdapter extends NativeDateAdapter {

  override format(date: Date): string {
    if (!this.isValid(date)) {
      throw Error('YearNativeDateAdapter: Cannot format invalid date.')
    }

    return formatDate(date, 'y', this.locale)
  }
}

// ----------------------------------------------------------

@Component({
  templateUrl: './year-calendar-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearCalendarHeaderComponent extends MatCalendarHeader<Date> { }

// ----------------------------------------------------------

@Component({
  selector: 'sl-year-picker',
  templateUrl: './year-picker.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: YearPickerComponent
    },
    {
      provide: DateAdapter,
      useClass: YearNativeDateAdapter
    }
  ]
})
export class YearPickerComponent extends YearMonthPickerComponent {

  override calendarHeader = YearCalendarHeaderComponent

  // ----------------------------------------------------

  chosenYearHandler(normalisedYear: Date) {
    // _log(`YearMonthPickerComponent.chosenMonthHandler(normalisedMonth): this.date`, `#${this.id}`, normalisedMonth, this.date, this)

    this.datepicker.close()
    this.controls.patchValue({ date: normalisedYear })
  }
}
