import { LOCALE_ID, NgModule } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { CdkStepperModule, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { OverlayModule } from '@angular/cdk/overlay'
import { PortalModule } from '@angular/cdk/portal'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { FlexLayoutModule } from '@ngbracket/ngx-layout'

import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { DateAdapter, MatOptionModule, MAT_DATE_FORMATS } from '@angular/material/core'
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'

import { LocalDateStringAdapter } from '../services/date-adapter'
import { DATE_FORMATS } from '../services/date-formats'
import { PaginatorIntl } from '../services/paginator'

// ----------------------------------------------------------

export const MATERIAL_MODULES = [
  CdkStepperModule,
  FlexLayoutModule,
  OverlayModule,
  PortalModule,
  ScrollingModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
]

// ----------------------------------------------------------

@NgModule({
  imports: MATERIAL_MODULES,
  exports: MATERIAL_MODULES,
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },

    {
      provide: DateAdapter,
      useClass: LocalDateStringAdapter,
      deps: [ LOCALE_ID ]
    },

    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    },

    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntl
    },

    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {
        displayDefaultIndicatorType: false
      }
    }
  ]
})
export class AngularMaterialModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    matIconRegistry.registerFontClassAlias('icon', 'icon')
    matIconRegistry.setDefaultFontSetClass('icon')
    matIconRegistry.addSvgIcon('icon-crunchbase', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/app/logos/cb-logo.svg'))
    matIconRegistry.addSvgIcon('icon-favourite', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/app/favourite.svg'))
    matIconRegistry.addSvgIcon('icon-favourite-outline', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/app/favourite-outline.svg'))
  }
}
