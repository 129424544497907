import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core'

import { getCountryNameByCode, Address } from '@libs/models'

// ------------------------------------------------------------

@Component({
  selector: 'sl-address-snippet',
  templateUrl: './address-snippet.component.html',
  styleUrls: [ './address-snippet.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSnippetComponent implements OnInit {
  @Input() address: Address

  // ----------------------------------------------------

  ngOnInit() {
    if (this.address && !(this.address instanceof Address)) {
      this.address = new Address(this.address)
    }
  }

  // -----------------------------------------------------

  get country(): string {
    return getCountryNameByCode(this.address.country)
  }
}
