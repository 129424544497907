<sl-simple-dialog-wrapper *ngIf="!isCompanyAdmin"
                          headerTitle="Hi, the company needs to pick a membership plan first."
                          i18n-headerTitle
                          [showDefaultButtons]="false">

  <div i18n>
    For access, contact your company admin
  </div>

</sl-simple-dialog-wrapper>

<sl-simple-dialog-wrapper *ngIf="isCompanyAdmin"
                          headerTitle="Hi, you'll need to pick a membership plan first."
                          i18n-headerTitle
                          [showDefaultButtons]="false">

  <div *ngIf="data.missingFeature" i18n>
    This feature is part of one of our membership plans, so let's do that first.
  </div>

  <div *ngIf="canUseFreeTrial" i18n>
    Good news, your first {{ region.freeTrialDuration }} days is on us, you'll have unlimited access to all our products, no card needed
  </div>

  <div class="buttons">
    <button *ngIf="canUseFreeTrial"
            (click)="activateFreeTrial()"
            mat-flat-button
            cdkFocusInitial
            [slLoading]="loading"
            color="primary"
            i18n>Start my 30-day free trial</button>

    <button *ngIf="!canUseFreeTrial"
            (click)="goToSubscriptionPage()"
            mat-flat-button
            cdkFocusInitial
            color="primary"
            i18n>Show me my membership options</button>
  </div>

</sl-simple-dialog-wrapper>
