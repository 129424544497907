import { Optional, Pipe, PipeTransform } from '@angular/core'
import { formatPercent } from '@angular/common'

import { InterestType } from '@libs/models'

import { LocaleService } from '@libs/services'

// ----------------------------------------------------------

export interface IInterestValue {
  interestType: InterestType
  interestRate?: number
}

// ----------------------------------------------------------

@Pipe({
  name: 'slInterest'
})
export class InterestPipe implements PipeTransform {

  constructor(
    @Optional() private localeService?: LocaleService
  ) {}

  // ----------------------------------------------------

  transform(
    value: IInterestValue,
    showNoneFOrZero = false,
    decimalFormat = '1.0-0'
  ): string {
    if (value.interestType === InterestType.None) {
      return '—'
    }

    const pc = (value.interestRate ?? 0) / 100

    if (pc === 0 && showNoneFOrZero) {
      return $localize`None`
    }

    const pv = formatPercent(pc, this.localeService?.locale || 'en', decimalFormat)

    if (pc > 0) {
      const pt = value.interestType === InterestType.Simple
        ? $localize`simple`
        : $localize`compound`

      return `${pv} (${pt})`
    } else {
      return pv
    }
  }
}
