import { Component, Inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { ModalService } from '@libs/modals'
import { BillingPeriods, IPlanCheckoutData, PlanMetadata } from '@libs/models'

import { PaymentsFacade } from '../../+state/payments.facade'

import { NewCardDialogComponent } from '../new-card-dialog/new-card-dialog.component'

// ----------------------------------------------------------

@Component({
  selector: 'sl-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: [ './subscription-dialog.component.scss' ]
})
export class SubscriptionDialogComponent implements OnInit {

  cards$ = this.paymentsFacade.cardsWithExpiry$
  defaultCard$ = this.paymentsFacade.defaultCard$
  cardsLoading$ = this.paymentsFacade.cardsLoading$

  planMetaData = PlanMetadata

  // ----------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPlanCheckoutData,
    public dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private paymentsFacade: PaymentsFacade,
    private router: Router,
    private modalService: ModalService
  ) { }

  // ----------------------------------------------------

  ngOnInit(): void {
    this.paymentsFacade.loadCards(this.data.company.id)
  }

  // ----------------------------------------------------

  get period(): string {
    return this.data.item.billingPeriod === BillingPeriods.Monthly ? 'month' : 'year'
  }

  // ----------------------------------------------------

  onManageCardsClicked() {
    this.dialogRef.close(false)
    this.router.navigate([ './companies', this.data.company.id, 'settings', 'billing' ])
  }

  // ----------------------------------------------------

  async addNewCardClicked() {
    await this.modalService.show(NewCardDialogComponent, { company: this.data.company }, { panelClass: 'sl-fullscreen-panel' })
  }

  // ----------------------------------------------------

  async onSubscribeClicked() {
    this.dialogRef.close(true)
  }
}
