import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

// -----------------------------------------------------------

@Component({
  selector: 'sl-chip',
  templateUrl: './chip.component.html',
  styleUrls: [ './chip.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  @Input() customStyle = {}
  @Input() colour: string
}
