<ng-container *ngIf="features?.length > 0">
  <div class="mb-16"
       [ngClass]="headerType === 'heading' ? 'heading-text text-semibold' : 'supporting-text'">
    {{ header }}</div>

  <div fxLayout="column"
      fxLayoutAlign="space-between stretch"
      class="gap-8">

    <div *ngFor="let feature of features"
         fxLayoutAlign="start center"
         class="gap-16">
      <mat-icon fontIcon="icon-check"
                color="accent"></mat-icon>
      <span class="label-text">{{ feature }}</span>
    </div>

  </div>
</ng-container>
