import { Component, ContentChild, Directive, inject, Input, TemplateRef } from '@angular/core'

import { combineLatest, map } from 'rxjs'
import { reverse } from 'ramda'

import { TabLink } from '@libs/models'
import { FeatureFlagsService } from '@libs/services'

import { LayoutFacade } from '@app/layout/+state/layout.facade'
import { UsersFacade } from '@app/users/+state/users.facade'
import { AppType } from '@libs/shared/models/swapp-data.model'

import { IBreadcrumbItem } from '../../models/breadcrumbs'
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../route-animation'

// ----------------------------------------------------------

/**
 * Tags a template to be used as the 'content' area of
 * the site instead of just `<router-outlet></router-outlet>`,
 * allowing e.g. wrapping multiple routes within a ghost
 * container or other decoration.
 *
 * Must contain `<router-outlet></router-outlet>` in order
 * for sub-routes to be displayed!
 *
 * ```html
 * <sl-page-layout-with-subnav>
 *   <ng-template slContentContainer>
 *     <mat-card>
 *       <mat-card-title>Module!</mat-card-title>
 *       <mat-card-content>
 *         <router-outlet></router-outlet>
 *       </mat-card-content>
 *     </mat-card>
 *   </ng-template>
 * </sl-page-layout-with-subnav>
 * ```
 *
 * ```html
 * <sl-page-layout-with-subnav>
 *   <ng-template slContentContainer>
 *     <sl-ghost-grid [ready]="dataStore.ready$ | async">
 *       <ng-template slGhostGridContent>
 *         <router-outlet></router-outlet>
 *       </ng-template>
 *
 *       <ng-template slGhostGridItem slGhostType="heading"></ng-template>
 *     </sl-ghost-grid>
 *   </ng-template>
 * </sl-page-layout-with-subnav>
 * ```
 */
@Directive({
  selector: 'ng-template[slContentContainer]',
})
export class ContentContainerDirective {

  constructor(
    public template: TemplateRef<any>
  ) {}
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-page-layout-with-subnav',
  templateUrl: './page-layout-with-subnav.component.html',
  styleUrls: [ './page-layout-with-subnav.component.scss' ]
})
export class PageLayoutWithSubnavComponent {

  @Input() label?: string
  @Input() crumbs?: IBreadcrumbItem[]

  @Input() links: TabLink[] = []

  @Input() background?: string

  @ContentChild(ContentContainerDirective, { read: TemplateRef, static: true })
    contentContainerTemplate: TemplateRef<any>

  @ContentChild('commentsToggleContainer')
  commentsToggleContainerTemplate: TemplateRef<any>

  readonly layout = inject(LayoutFacade)
  readonly flagsService = inject(FeatureFlagsService)
  private userFacade = inject(UsersFacade)

  appType = AppType.GROW
  hasUnsignedDocuments$ = this.userFacade.hasUnsignedDocuments$
  currentRegion$ = this.userFacade.currentRegion$

  shouldShowBackButton$ = combineLatest([
    this.layout.isMediumOrLess$,
    this.layout.currentCompanyFullAccess$
  ]).pipe(map(([ a, b ]) => a && b))

  readonly isAppWideSearchEnabled$ = combineLatest([
    this.flagsService.getFeatureFlag('APP_WIDE_SEARCH'),
    this.layout.currentCompanyFullAccess$
  ]).pipe(map(([ a, b ]) => a && b))

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS

  // ----------------------------------------------------

  get breadcrumbs(): IBreadcrumbItem[] {
    return this.label
      ? [ { label: this.label } ]
      : this.crumbs
  }

  // ----------------------------------------------------

  // used for the back button on mobile screens only
  get lastClickableCrumb(): IBreadcrumbItem | undefined {
    return reverse(this.breadcrumbs).find((c, i) => i > 0 && !!c.route)
  }

  // ----------------------------------------------------

  toggleSidenav() {
    this.layout.mainNavToggle()
  }

}
