<ng-container *ngIf="messages.enabled">
  <button (click)="onClicked()"
          mat-icon-button>
    <mat-icon fontIcon="icon-bell-{{ messages.unread ? 'ring' : 'outline' }}"
              [class.active]="messages.unread"
              aria-label="Notifications"
              i18n-aria-label></mat-icon>
  </button>

  <span *ngIf="messages.unread">{{ messages.unread }}</span>
</ng-container>
