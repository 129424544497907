import type { ComplianceEvent, EmiValuationEvent, ResearchAssuranceEvent, ResearchClaimEvent } from '@libs/models'

export interface EventSupportingDocument {
  type: SupportingDocumentType
  typeSpecific?: string
}

export type SupportingDocumentsEventType = ComplianceEvent | ResearchAssuranceEvent | ResearchClaimEvent | EmiValuationEvent

export const enum SupportingDocumentType {
  AaEmail = 'AAEMAIL',
  Accounts = 'ACCOUNTS',
  Articles = 'ARTICLES',
  BalanceSheet = 'BALANCE_SHEET',
  CT600 = 'CT600',
  Custom = 'CUSTOM',
  DeMinimis = 'DEMINIMIS',
  Financials = 'FINANCIALS',
  Incorporation = 'INCORPORATION',
  InstantConversionAgreement = 'CONVERSION',
  InstantInvestmentAgreement = 'INSTANT',
  InvestmentAgreement = 'INVESTMENT',
  KnowledgeIntensive = 'KNOWLEDGE',
  PitchDeck = 'DECK',
  ResearchPlan = 'RESEARCH_PLAN',
  ShareRegister = 'REGISTER',
  TaxReturn = 'TAX_RETURN'
}

export const enum OptionalDocumentType {
  CashBurn = 'CASH_BURN',
  MarketForces = 'MARKET_FORCES'
}

// ------------------------------------------------------------

export const SupportingDocumentNames: Record<SupportingDocumentType, string> = {
  [ SupportingDocumentType.AaEmail ]: $localize`Advance Assurance approval email`,
  [ SupportingDocumentType.Accounts ]: $localize`Company account information`,
  [ SupportingDocumentType.Articles ]: $localize`Articles of Association`,
  [ SupportingDocumentType.BalanceSheet ]: $localize`Latest management balance sheet`,
  [ SupportingDocumentType.CT600 ]: $localize`CT600 Form`,
  [ SupportingDocumentType.Custom ]: $localize`Custom`,
  [ SupportingDocumentType.DeMinimis ]: $localize`De minimis aid information`,
  [ SupportingDocumentType.Financials ]: $localize`3 year financial projection`,
  [ SupportingDocumentType.Incorporation ]: $localize`Company registration documents`,
  [ SupportingDocumentType.InstantConversionAgreement ]: $localize`Any relevant Instant Conversion Agreements`,
  [ SupportingDocumentType.InstantInvestmentAgreement ]: $localize`Any relevant Instant Investment Agreements`,
  [ SupportingDocumentType.InvestmentAgreement ]: $localize`Investment Agreement`,
  [ SupportingDocumentType.KnowledgeIntensive ]: $localize`Knowledge Intensive Company information`,
  [ SupportingDocumentType.PitchDeck ]: $localize`Pitch Deck`,
  [ SupportingDocumentType.ResearchPlan ]: $localize`Detailed R&D plans (optional)`,
  [ SupportingDocumentType.ShareRegister ]: $localize`Share Register`,
  [ SupportingDocumentType.TaxReturn ]: $localize`Latest tax return`
}

// ------------------------------------------------------------

export const NonUploadableSupportingDocumentTypes = [
  SupportingDocumentType.InstantConversionAgreement,
  SupportingDocumentType.InstantInvestmentAgreement,
  SupportingDocumentType.ShareRegister
]
