import { createAction, union, props } from '@ngrx/store'

import type { WhoAmIData } from '../models/whoami-data.model'

// ----------------------------------------------------------

export const SetAuthErrorState = createAction(
  '[Auth] Set Auth Error State',
  props<{ authError: any }>()
)

export const ClearAuthErrorState = createAction(
  '[Auth] Clear Auth Error State'
)

// ----------------------------------------------------------

export const Login = createAction(
  '[Auth] Login'
)

export const SignUp = createAction(
  '[Auth] Sign Up'
)

export const Logout = createAction(
  '[Auth] Logout'
)

// ----------------------------------------------------------

export const HandleAuthCallback = createAction(
  '[Auth] Handle Auth Callback'
)

export const AuthCallbackError = createAction(
  '[Auth] Auth Callback Error',
  props<{ error: string }>()
)

export const CheckLogin = createAction(
  '[Auth] Check Login'
)

export const LoginFound = createAction(
  '[Auth] Login Found',
  props<{ idToken: string }>()
)

export const LoginSuccess = createAction(
  '[Auth API] Login Success',
  props<{ idToken: string }>()
)

export const LoginFailure = createAction(
  '[Auth API] Login Failure',
  props<{ authError: any }>()
)

export const LoginError = createAction(
  '[Auth API] Login Error',
  props<{ authError: any }>()
)

export const SessionExpired = createAction(
  '[Auth API] Session Expired',
  props<{ redirectUri: string }>()
)

// ----------------------------------------------------------

export const ReadWhoAmIFromStorage = createAction(
  '[WhoAmI] Read WhoAmI From Storage'
)

export const GetWhoAmI = createAction(
  '[WhoAmI] Get WhoAmI',
  props<{ redirectUrl?: string, emailToken?: string }>()
)

export const WhoAmIUserFound = createAction(
  '[WhoAmI] Get WhoAmI User Found',
  props<{ whoAmI: WhoAmIData }>()
)

export const WhoAmINoUserExists = createAction(
  '[WhoAmI] Get WhoAmI No User Exists'
)

export const WhoAmIFailure = createAction(
  '[WhoAmI] Get WhoAmI Failure',
  props<{ error: any }>()
)

export const UpdateWhoAmI = createAction(
  '[Auth] Update WhoAmI',
  props<{ whoAmI: WhoAmIData }>()
)

export const OnboardingRedirect = createAction(
  '[Auth] Redirect To Onboarding'
)

export const PostAuthRedirect = createAction(
  '[Auth] Post-Auth Redirect'
)

// ----------------------------------------------------------

const actions = union({
  AuthCallbackError,
  CheckLogin,
  ClearAuthErrorState,
  GetWhoAmI,
  HandleAuthCallback,
  LoginError,
  LoginFailure,
  LoginFound,
  LoginSuccess,
  Login,
  Logout,
  OnboardingRedirect,
  PostAuthRedirect,
  ReadWhoAmIFromStorage,
  SessionExpired,
  SetAuthErrorState,
  SignUp,
  UpdateWhoAmI,
  WhoAmIFailure,
  WhoAmINoUserExists,
  WhoAmIUserFound,
})

export type AuthAction = typeof actions
