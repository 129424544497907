import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { environment } from '@env/environment'
import { EnvironmentConfig } from './tokens'

import { DIRECTIVES } from './directives'

// ----------------------------------------------------------

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [ ...DIRECTIVES ],
  providers: [
    {
      provide: EnvironmentConfig,
      useValue: environment
    }
  ],
  exports: [ ...DIRECTIVES ]
})
export class SharedDirectivesModule {}
