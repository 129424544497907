<mat-dialog-content class="gap-16">
  <h1 i18n>Company already exists on SeedLegals</h1>
  <p class="text-dark-grey fnt-14" i18n>
    This company is already on SeedLegals. If you'd like to be added to it you can request access, we'll notify the company admins of your request.
  </p>
  <sl-avatar-with-company-details [companyMatch]="data" [highlighted]="true"></sl-avatar-with-company-details>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="close(null)" mat-button color="primary" i18n>Cancel</button>
  <button (click)="close(data)" mat-button type="submit" color="primary" i18n>Request access</button>
</mat-dialog-actions>
