import { uniqBy } from 'ramda'

import { Collection } from '../collection'
import { OnCompanyModel, type INamed } from '../model'
import type { Company } from '../company'

import { Colours } from '../../models/colours'
import {
  AntiDilution,
  AntiDilutionLabels
} from '../../models/investment-event.model'
import { type Share } from './share'
import { type Investor } from './investor'

import { Jurisdiction } from '../../models'
import { EventCollectionBase } from '../events/event'
import { type EmiValuationEvent } from '../options/emi-valuation-event'

import { ColourGenerator, percentage, type Comparator } from '@libs/utils'

// ------------------------------------------------------------

const colgen = new ColourGenerator(Colours)

// ------------------------------------------------------------

export class ShareClass extends OnCompanyModel implements INamed {
  name: string
  seniority: number
  preferred: boolean
  voting: number
  participating: boolean
  dividends: boolean
  transferable: boolean
  antiDilution: AntiDilution
  _liquidation: boolean
  multiplier: number

  colour: string

  emiValuations = new EventCollectionBase<EmiValuationEvent>()

  // ----------------------------------------------------

  constructor({
    name = '',
    seniority = 0,
    preferred = false,
    voting = 0,
    dividends = false,
    transferable = true,
    distribution = true,
    redemption = false,
    liquidation = false,
    multiplier = 0,
    participating = false,
    antiDilution = AntiDilution.None,
    ...data
  }) {
    super({
      name,
      preferred,
      voting,
      dividends,
      transferable,
      distribution,
      redemption,
      liquidation,
      multiplier,
      participating,
      antiDilution,
      seniority,
      ...data
    })

    this.colour = this.saved
      ? colgen.fromId(this.id)
      : colgen.next()

    // this.rounds = []
  }

  // ----------------------------------------------------

  static fromSpec(company: Company, spec: ShareClassSpecification): ShareClass {
    const sc: ShareClass = new ShareClass({
      company,
      preferred: spec.preferred,
      voting: spec.voting,
      dividends: spec.dividends,
      transferable: spec.transferable,
      liquidation: spec.liquidation,
      multiplier: spec.multiplier,
      participating: spec.participating,
      antiDilution: spec.antiDilution
    })
    sc.name = ShareClassNameGenerator.generateName(sc, company.shareClasses.items())
    return sc
  }

  // ----------------------------------------------------

  get domain() {
    return `shareClasses`
  }

  // ----------------------------------------------------

  getAllShares(): Share[] {
    return this.company.getActiveShares().filter(s => s.shareClass.id === this.id)
  }

  // ----------------------------------------------------

  getAllInvestors(): Investor[] {
    return uniqBy(v => v.id, this.getAllShares().map(s => s.investor))
  }

  // ----------------------------------------------------

  override getApiFields() {
    return [
      ...super.getApiFields(),
      'name',
      'seniority',
      'preferred',
      'voting',
      'dividends',
      'transferable',
      'distribution',
      'redemption',
      'liquidation',
      'multiplier',
      'participating',
      'antiDilution'
    ]
  }

  // ----------------------------------------------------

  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    this.colour = colgen.fromId(this.id)
  }

  // ----------------------------------------------------

  get liquidation() {
    return this._liquidation
  }

  set liquidation(value) {
    if (value === this._liquidation) {
      return
    }

    if (!value) {
      this.multiplier = 0
      this.participating = false
    }

    this._liquidation = value
  }

  // ----------------------------------------------------

  get count(): number {
    return this.getAllShares()
      .reduce((total, s) => total + s.count, 0)
  }

  // ----------------------------------------------------

  get shareCount(): number {
    return this.count
  }

  // ----------------------------------------------------

  get investment(): number {
    return this.getAllShares()
      .reduce((total, s) => total + s.investment, 0)
  }

  // ----------------------------------------------------

  get isVoting(): boolean {
    return this.voting > 0
  }

  // ----------------------------------------------------

  get hasAntiDilution(): boolean {
    return this.antiDilution !== AntiDilution.None
  }

  // ----------------------------------------------------

  get antiDilutionLabel(): string {
    return AntiDilutionLabels[ this.antiDilution ]
  }

  // ----------------------------------------------------

  get estimatedCount(): number {
    return this.getAllShares()
      .reduce((total, s) => total + s.estimatedCount, 0)
  }

  // ----------------------------------------------------

  // get estimatedInvestment(): number {
  //   return this.getAllShares()
  //     .reduce((total, s) => total + s.estimatedInvestment, 0)
  // }

  // ----------------------------------------------------

  get issuedShares(): Share[] {
    return this.getAllShares()
      .filter(s => s.isIssued)
  }

  // ----------------------------------------------------

  // get issuedShareCount(): number
  get issuedShareCount() {
    return this.issuedShares
      .reduce((total, s) => total + s.count, 0)
  }

  // ----------------------------------------------------

  // get issuedInvestment(): number {
  //   return this.issuedShares
  //     .reduce((total, s) => total + s.count * s.round.pricePerShare, 0)
  // }

  // ----------------------------------------------------

  get hasIssuedShares(): boolean {
    return this.issuedShareCount > 0
  }

  // ----------------------------------------------------

  get ownership(): number {
    return percentage(this.count, this.company.outstandingShareCount)
  }

  // ----------------------------------------------------

  get dilutedOwnership(): number {
    return percentage(this.count, this.company.dilutedShareCount)
  }

  // ----------------------------------------------------

  // toString() {
  //   const propstr = (obj, def) => def.split(' ')
  //     .map(s => s.includes(':') ? s.split(':') : [ s, s ])
  //     .map(([ l, k ]) => `${l}: ${obj[ k ] ? JSON.stringify(obj[ k ]) : String(obj[ k ])}`)
  //     .join(', ')

  //   return `${this.name}(${propstr(this, 'id sen:seniority price')})`
  // }
}

// ------------------------------------------------------------

const shareClassComparator: Comparator<ShareClass> = (a, b) => b.seniority - a.seniority || a.multiplier - b.multiplier || a.name.localeCompare(b.name)

// ------------------------------------------------------------

export class ShareClassSpecification {

  preferred: boolean

  dividends: boolean
  voting: number
  transferable: boolean

  liquidation: boolean
  multiplier: number
  participating: boolean

  antiDilution: AntiDilution

  constructor({
    preferred = false,
    dividends = true,
    voting = 1,
    transferable = true,
    liquidation = false,
    multiplier = 0,
    participating = false,
    antiDilution = AntiDilution.None
  }) {
    this.preferred = preferred
    this.dividends = dividends
    this.voting = voting
    this.transferable = transferable
    this.liquidation = liquidation
    this.multiplier = multiplier
    this.participating = participating
    this.antiDilution = antiDilution
  }

  matches(shareClass: ShareClass): boolean {
    return shareClass.preferred === this.preferred &&
           shareClass.dividends === this.dividends &&
           shareClass.voting === this.voting &&
           shareClass.transferable === this.transferable &&
           shareClass._liquidation === this.liquidation &&
           shareClass.multiplier === this.multiplier &&
           shareClass.participating === this.participating &&
           shareClass.antiDilution === this.antiDilution
  }

}

// ------------------------------------------------------------

export const OrdinaryShareClassSpec = new ShareClassSpecification({})

export const AOrdinaryShareClassSpec = new ShareClassSpecification({
  liquidation: true,
  multiplier: 1
})

export const APreferredShareClassSpec = new ShareClassSpecification({
  preferred: true,
  liquidation: true,
  multiplier: 1,
  antiDilution: AntiDilution.Narrow
})

// ------------------------------------------------------------

export class ShareClassCollection extends Collection<ShareClass> {
  constructor() {
    super(shareClassComparator)
  }

  findBySpecification(spec: ShareClassSpecification): ShareClass | undefined {
    return this.find(shareClass => spec.matches(shareClass))
  }
}

// ------------------------------------------------------------

export class ShareClassNameGenerator {

  public static generateName(shareClass: ShareClass, existingShareClasses: ShareClass[] = [], startWith?: { name: string, letter: string }): string {
    const isFr = shareClass.company.jurisdiction === Jurisdiction.France
    const existingNames = new Set(existingShareClasses.map(sc => sc.name))

    let name = ''
    let letter = ''

    if (isFr) {
      if (existingNames.has('Ordinaires')) {
        name = 'Ordinaires de catégorie'
        letter = 'Seed' // not a letter but it starts with Seed and then A, B, C ...
      } else {
        name = 'Ordinaires'
      }

    } else {
      if (startWith) {
        name = startWith.name
        letter = startWith.letter

      } else {
        name = shareClass.preferred ? 'Preferred' : 'Ordinary'
      }
    }

    return this.generateLetter({ name, letter }, existingNames, isFr)
  }

  public static generateLetter({ name, letter }, existingNames: Set<string>, reverse = false) {

    const getName = (lettr: string) => lettr
      ? reverse
        ? name + ' ' + lettr
        : lettr + ' ' + name

      : name

    let className = getName(letter)

    while (existingNames.has(className)) {
      if (letter === 'Seed') {
        letter = 'A'

      } else {
        letter = letter
          ? String.fromCharCode(letter.charCodeAt(0) + 1)
          : 'A'
      }

      className = getName(letter)
    }

    return className
  }
}
