import { type OnInit, Type, Provider, forwardRef, Directive, type OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, type ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, Validators, ValidatorFn } from '@angular/forms'

import type { Subscription } from 'rxjs'

// ----------------------------------------------------------

export function getValueProvider(componentClass: Type<unknown>): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => componentClass),
    multi: true
  }
}

// ----------------------------------------------------------

export function getValidatorProvider(componentClass: Type<unknown>): Provider {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => componentClass),
    multi: true
  }
}

// ----------------------------------------------------------

export function getFormControls(
  formGroup: UntypedFormGroup
): [ string, AbstractControl ][] {
  return Object.entries(formGroup.controls)
}

// ----------------------------------------------------------

export function updateFormControlRequired(
  formGroup: UntypedFormGroup,
  name: string,
  required: boolean,
  ...additionalValidators: ValidatorFn[]
) {
  const validators = required
    ? [ Validators.required, ...additionalValidators ]
    : additionalValidators

  formGroup.get(name)?.setValidators(validators)
}

// ----------------------------------------------------------

@Directive()
export abstract class FormControlComponent<M> implements OnInit, OnDestroy, ControlValueAccessor {

  controls = this.getControlGroup()

  // ----------------------------------------------------

  isDisabled = false

  subscriptions: Subscription[] = []

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: M) => {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {}

  // ----------------------------------------------------

  constructor(
    public fb: UntypedFormBuilder
  ) {}

  // ----------------------------------------------------

  abstract getControlGroup(): UntypedFormGroup

  // ----------------------------------------------------

  ngOnInit() {
    this.subscriptions.push(
      this.controls.valueChanges
        .subscribe(formValue => {
          // _log(`${this.constructor.name}.controls.valueChanges(formValue, status = ${this.controls.status}): controls, this`, formValue, this.controls, this)

          if (this.controls.status === 'VALID') {
            const modelValue = this.convertFormDataToModelData(formValue)

            // _log(`${this.constructor.name}.controls.valueChanges(formValue) -> modelValue`, formValue, modelValue)

            this.changeValue(modelValue)
          } else {
            this.changeValue(undefined)
          }
        })
    )
  }

  // ----------------------------------------------------

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe()
      }
    }
  }

  // ----------------------------------------------------

  /**
   * @whatItDoes Takes the data from this control's form
   * and preprocesses it to get a new value to emit
   */
  convertFormDataToModelData(formValue: unknown): M {
    return formValue as M
  }

  // ----------------------------------------------------

  /**
   * @whatItDoes Takes a new value from the parent form and
   * preprocceses it to get a value we use to patch our form
   */
  convertModelDataToFormData(value: M): unknown {
    return value
  }

  // ----------------------------------------------------

  writeValue(modelValue: M): void {
    const formValue = this.convertModelDataToFormData(modelValue)

    // _log(`${this.constructor.name}.writeValue(modelValue): formValue`, modelValue, formValue)

    if (formValue) {
      this.controls.patchValue(formValue)
    }
  }

  // ----------------------------------------------------

  changeValue(modelValue: M | undefined): void {
    this.onChange(modelValue)
  }

  // ----------------------------------------------------

  registerOnChange(fn: (newValue: M) => void): void {
    this.onChange = fn
  }

  // ----------------------------------------------------

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn
  }

  // ----------------------------------------------------

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled !== this.isDisabled) {
      if (isDisabled) {
        this.controls.disable({ emitEvent: false })
      } else {
        this.controls.enable({ emitEvent: false })
      }

      this.isDisabled = isDisabled
    }
  }
}
