<mat-menu #menu
          xPosition="before">
  <ng-content></ng-content>
</mat-menu>

<button [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
        mat-icon-button
        aria-label="Actions"
        i18n-aria-label
        type="button">
  <mat-icon fontIcon="icon-dots-vertical"></mat-icon>
</button>
