import { Component, Input, type OnChanges, ChangeDetectionStrategy, Inject } from '@angular/core'

import { type DynamicGroupType, type EntityClass, getAvatarColour, type HasId, idToEntityClass, type INamedEntity } from '@libs/models'
import { IEnvironmentConfig } from '@env/models/environment-config'

import { EnvironmentConfig } from '@libs/shared'
import { getInitials } from '@libs/utils'

// ----------------------------------------------------------

function hasPictureField(
  obj: { id: string },
): obj is { id: string, picture: string } {
  const ec = idToEntityClass(obj.id)
  return ec === 'Company' || ec === 'User'
}

function isGroup(
  obj: { id: string },
): obj is { id: string, dynamicType: DynamicGroupType | null } {
  return idToEntityClass(obj.id) === 'EntityGroup'
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-entity',
  template: `
    <div [ngClass]="entityClass | lowercase"
         [style.width.px]="size"
         [style.lineHeight.px]="size"
         [matTooltip]="entity.name"
         [matTooltipDisabled]="noTooltip"
         [matTooltipShowDelay]="200"
         [matTooltipHideDelay]="100">
      <img *ngIf="avatarUrl" [src]="avatarUrl">

      <span *ngIf="!avatarUrl"
            [style.fontSize.px]="size / 3"
            [style.outlineWidth.px]="size / 10"
            [style.outlineOffset.px]="-size / 10"
            [style.outlineColor]="foregroundColour"
            [style.color]="foregroundColour"
            [style.backgroundColor]="backgroundColour">{{ initials }}</span>

      <mat-icon *ngIf="showIcon"
                fontIcon="icon-lightning-bolt-circle"
                color="primary"
                class="corner-icon text-center"
                [style.width.px]="size / 2"
                [style.height.px]="size / 2"
                [style.lineHeight.px]="size / 2"
                [style.fontSize.px]="size / 3"
                [style.top.px]="size / -6"
                [style.right.px]="size / -6"></mat-icon>
    </div>
  `,
  styles: [ `
    :host {
      display: inline-block;
    }

    span {
      display: grid;
      place-content: center;

      font-weight: bold;

      cursor: default;
    }

    img {
      background-color: white;
      border: solid 1px #ebebeb;
      width: 100%;
      height: auto;
    }

    div {
      position: relative;
    }

    div.user * {
      border-radius: 50%;
    }

    div.company * {
      border-radius: 6%;
    }

    div.entitygroup span {
      outline-style: dotted;
    }

    .corner-icon {
      position: absolute;
      border-radius: 50%;
      background-color: white;
    }
  ` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityComponent implements OnChanges {
  @Input() entity: HasId & INamedEntity
  @Input() size = 40
  @Input() isList = false
  @Input() noTooltip = false

  entityClass: EntityClass
  initials?: string
  foregroundColour?: string
  backgroundColour?: string
  avatarUrl?: string
  showIcon = false

  // ----------------------------------------------------

  constructor(
    @Inject(EnvironmentConfig) private environment: IEnvironmentConfig
  ) {}

  // ----------------------------------------------------

  ngOnChanges() {
    this.entityClass = idToEntityClass(this.entity.id)

    if (hasPictureField(this.entity) && this.entity.picture) {
      this.avatarUrl = this.entity.picture.startsWith('http')
        ? this.entity.picture
        : this.environment.assetsLocation + this.entity.picture
    } else {
      this.avatarUrl = undefined
      this.initials = getInitials(this.entity.name)
      this.foregroundColour = isGroup(this.entity)
        ? getAvatarColour(this.entity.id)
        : 'white'
      this.backgroundColour = isGroup(this.entity)
        ? 'white'
        : getAvatarColour(this.entity.id)
    }

    if (!this.isList && isGroup(this.entity) && !!this.entity.dynamicType) {
      this.showIcon = true
    }
  }
}

// ----------------------------------------------------------

@Component({
  selector: 'sl-more-entities',
  template: `
    <div *ngIf="amount>0"
         [style.width.px]="size"
         [style.lineHeight.px]="size">

      <span [style.fontSize.px]="size / 3">+{{amount}}</span>
    </div>
  `,
  styles: [ `
    :host {
      display: inline-block;
    }
    span {
      display: grid;
      place-content: center;
      color: white;
      background-color: #4a89dc;
      font-weight: bold;
      border-radius: 50%;
      cursor: default;
    }
  ` ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreEntitiesComponent {
  @Input() amount: number
  @Input() size = 40
}
