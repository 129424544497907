import { HttpErrorResponse } from '@angular/common/http'
import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { Company, Entity, User, Address } from '@libs/models'

import { UserService } from '@app/users/services/user.service'
import { CompanyService } from '@app/companies/services/company/company.service'
import { ToastService } from '@libs/services'

import { SimpleDialogWrapperComponent } from '@libs/modals'

// ----------------------------------------------------

@Component({
  selector: 'sl-address-edit-dialog',
  templateUrl: './address-edit-dialog.component.html',
  styleUrls: [ './address-edit-dialog.component.scss' ]
})
export class AddressEditDialogComponent implements OnInit {

  form: UntypedFormGroup = this.fb.group({ address: [ null ] })

  entity: Entity
  loading: boolean

  @ViewChild(SimpleDialogWrapperComponent) dialogWrapper: SimpleDialogWrapperComponent

  // ----------------------------------------------------

  constructor(
    public dialogRef: MatDialogRef<AddressEditDialogComponent>,
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: AddressEditDialogData
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.entity = this.data.entity
    this.form.patchValue({ address: this.entity.address })
  }

  // ----------------------------------------------------

  async onSaveClicked() {
    if (this.form.value.address) {
      const address = new Address(this.form.value.address)

      if (address.isComplete()) {

        const entityToUpdate = this.data.entity
        this.loading = true
        let patched = false

        if (entityToUpdate.entityType === 'user') {
          patched = await this.userService.patchUser(entityToUpdate as User, this.form.value)

        } else if (entityToUpdate.entityType === 'company') {
          const ex = await this.companyService.patchCompany(entityToUpdate as Company, this.form.value)

          if (ex instanceof HttpErrorResponse && ex.status === 403) {
            this.dialogWrapper.setErrorMessage($localize`Only admins can update Company address.`)
          }

          patched = !ex
        }

        if (!patched) {
          this.toastService.error($localize`Could not update address.`)
        } else {
          this.dialogRef.close(true)
        }

        this.loading = false
        return
      }
    }

    this.dialogWrapper.setErrorMessage($localize`Please provide a valid address.`)
  }
}

// ----------------------------------------------------

export interface AddressEditDialogData {
  title: string
  entity: Entity
}
