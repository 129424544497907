import { Pipe, PipeTransform } from '@angular/core'

import { Jurisdiction, JurisdictionName } from '@libs/models'

// ------------------------------------------------------

@Pipe({
  name: 'slJurisdiction'
})
export class SlJurisdictionPipe implements PipeTransform {

  transform(jurisdiction: Jurisdiction | undefined): string {
    if (jurisdiction) {
      return JurisdictionName[ jurisdiction ] || jurisdiction
    } else {
      return $localize`Other`
    }
  }
}
