import { Component, OnInit } from '@angular/core'

import type { IProductHowMuchData } from '@libs/models'

import { ITEMS_BY_PRODUCT_AND_STAGE, ITEMS_BY_STAGE, } from './product-price-dialog.constants'

import { ModalComponent } from '@libs/utils'

// ------------------------------------------------------------

@Component({
  selector: 'sl-product-price-dialog',
  templateUrl: './product-price-dialog.component.html',
  styleUrls: [ './product-price-dialog.component.scss' ]
})
export class ProductPriceDialogComponent
  extends ModalComponent<ProductPriceDialogComponent, IProductHowMuchData, boolean>
  implements OnInit {

  item = ITEMS_BY_STAGE[ this.data.stage ]
  price = this.data.discountedAmount
  currency = this.data.currency
  showVat = this.data.billedWithVat && this.data.discountedAmount !== this.data.discountedAmountInclVAT

  // ----------------------------------------------------

  ngOnInit() {
    const docTypeItem = ITEMS_BY_PRODUCT_AND_STAGE[ this.data.product ]

    if (docTypeItem) {
      this.item = {
        ...this.item,
        ...docTypeItem.all,
        ...docTypeItem[ this.data.stage ]
      }
    }
  }

  // ----------------------------------------------------

  okClicked() {
    this.dialogRef.close(true)
  }
}
