import { Component } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-toolbar-layout',
  template: `
    <div class="main-layout">
      <mat-sidenav-container>
        <mat-sidenav-content>
          <sl-static-toolbar></sl-static-toolbar>

          <div class="main-container">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  `
})
export class ToolbarLayoutComponent {}
