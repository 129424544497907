import { firstValueFrom } from 'rxjs'

import { omitBy } from 'ramda-adjunct'
import { mapObjIndexed, pick } from 'ramda'

import { parseISO } from 'date-fns'

import type { BackendService } from '@libs/backend'

import type { Company } from './company'
import type { Event } from './events/event'

// ------------------------------------------------------------

const hasValidLink = obj => typeof obj.link === 'string' && obj.link.match(/^\/[\w-]+\/[\w-]+$/)

// ------------------------------------------------------------

export interface INamed {
  name: string
}

// ------------------------------------------------------------

let currentId = 1

// ------------------------------------------------------------

export type SaveMode = 'create' | 'update'

// ------------------------------------------------------------

export interface IApiFieldModeSpec {
  key: string
  include: SaveMode
}

export type ApiFieldSpec = string | IApiFieldModeSpec | (<M extends Model>(model: M, mode: SaveMode) => string | undefined)

// ------------------------------------------------------------

export interface IModel {
  readonly domain: string
  readonly id: string | null
  readonly saved: boolean
  readonly link: string | null
}

// ------------------------------------------------------------

export abstract class Model implements IModel {
  _id: string | null

  _inserted: Date | null
  _updated: Date | null

  readonly _objid = `${this.constructor.name}_${currentId++}`

  // ----------------------------------------------------

  /**
   * Name of the field on the Company object that has this event type's collection
   * e.g. for a Round instance r, r.company[r.field].get(r.id) === r. Should be the
   * same as returned from the API e.g. rounds or stockSplits
   */
  abstract readonly domain: string

  // ----------------------------------------------------

  constructor({
    id = null,
    inserted = null,
    updated = null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _links = null,
    ...data
  }) {
    Object.assign(this, omitBy(v => typeof v === 'undefined', data))

    this._id = id
    this.inserted = typeof inserted === 'string' ? parseISO(inserted) : inserted
    this.updated = typeof updated === 'string' ? parseISO(updated) : updated
  }

  // ----------------------------------------------------

  get id(): string | null {
    return this._id
  }

  get saved(): boolean {
    return this._id !== null
  }

  // ----------------------------------------------------

  setID(id: string | null): this {
    if (this._id !== null) {
      throw new Error(`ID already set!`)
    }

    if (id !== null) {
      this._id = id
      this.attach()
    }

    return this
  }

  // ----------------------------------------------------

  clearID(): this {
    if (this._id === null) {
      throw new Error(`Cannot clear an ID that has no value!`)
    }

    this.detach()
    this._id = null

    return this
  }

  // ----------------------------------------------------

  /**
   * Name of the field on the Company object that has this event type's collection
   * e.g. for a Round instance r, r.company[r.field].get(r.id) === r. Should be the
   * same as returned from the API e.g. rounds or stockSplits
   */
  get field(): string {
    return this.domain
  }

  // ----------------------------------------------------

  /**
   * Name of the field for single items returned from the API or POSTed to it e.g. round
   * or stockSplit on the payload for creating a new document.
   */
  get fieldSingular(): string {
    return this.field.replace(/s$/, '')
  }

  // ----------------------------------------------------

  get link(): string | null {
    return this.saved
      ? `/${this.domain}/${this.id}`
      : null
  }

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  attach(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  detach(): void {}

  // ----------------------------------------------------

  get inserted(): Date | null {
    return this._inserted || null
  }

  set inserted(value: Date | null) {
    this._inserted = value ? new Date(value) : null
  }

  // ----------------------------------------------------

  get updated(): Date | null {
    return this._updated || null
  }

  set updated(value: Date | null) {
    this._updated = value ? new Date(value) : null
  }

  // ----------------------------------------------------

  get modified(): Date | null {
    return this.updated || this.inserted
  }

  // ----------------------------------------------------

  protected getApiFields(): ApiFieldSpec[] {
    return []
  }

  // ----------------------------------------------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected customisePayload(payload: object, mode: SaveMode): object {
    return payload
  }

  // ----------------------------------------------------

  protected getActualFields(mode: SaveMode): string[] {
    return this.getApiFields().reduce((out, v) => {
      if (typeof v === 'string') {
        out.push(v)
      } else if (typeof v === 'function') {
        const r = v(this, mode)
        if (typeof r === 'string') {
          out.push(r)
        }
      } else if (typeof v === 'object') {
        const { key, include = 'both' } = v

        if (include === 'both' || include === mode) {
          out.push(key)
        }
      }

      return out
    }, [])
  }

  // ----------------------------------------------------

  protected convertModelObjects(payload: object, mode: SaveMode): object {
    const processValue = value => {
      if (value && typeof value === 'object') {
        if (typeof value.getValueForPayload === 'function') {
          return value.getValueForPayload(mode)
        } else if (hasValidLink(value)) {
          return value.link
        }
      }

      return value
    }

    return mapObjIndexed(processValue, payload)
  }

  // ----------------------------------------------------

  protected getPayload(mode: SaveMode = 'update'): object {
    const fields = this.getActualFields(mode)

    const initialPayload = this.customisePayload(pick(fields, this), mode)

    return this.convertModelObjects(initialPayload, mode)
  }

  // ----------------------------------------------------

  save(api: BackendService): Promise<this> {
    return this.saved
      ? this.doUpdate(api)
      : this.doCreate(api)
  }

  // ----------------------------------------------------

  private async doCreate(api: BackendService): Promise<this> {
    await this.beforeCreate(api)

    const payload = this.getPayload('create')

    const responseData = await firstValueFrom(api
      .all(this.domain)
      .post(payload))

    this.setID(responseData.id)

    await this.afterCreate(api, responseData)

    return this
  }

  // ----------------------------------------------------

  private async doUpdate(api: BackendService): Promise<this> {
    await this.beforeUpdate(api)

    const payload = this.getPayload()

    const responseData = await firstValueFrom(api
      .one(this.domain, this.id)
      .patch(payload))

    await this.afterUpdate(api, responseData)

    return this
  }

  // ----------------------------------------------------

  /**
   * If this object has been persisted (i.e. has an ID) call the
   * API to delete it, then remove it from any collections it is
   * a member of.
   *
   * @returns {this}
   */
  async remove(api: BackendService): Promise<this> {
    if (this.id !== null) {
      await this.beforeRemoval(api)

      await firstValueFrom(api
        .one(this.domain, this.id)
        .remove())

      this.clearID()

      await this.afterRemoval(api)
    }

    return this
  }

  // ----------------------------------------------------

  /**
   * If persisted, remove this object from any collections
   * it is a member of without touching the API. Use when
   * there is a cascading delete in the DB for instance.
   */
  async removeWithoutApi(): Promise<this> {
    if (this.id !== null) {
      await this.beforeRemovalWithoutApi()

      this.clearID()

      await this.afterRemovalWithoutApi()
    }

    return this
  }

  // ----------------------------------------------------
  /* eslint-disable @typescript-eslint/no-empty-function */
  /* eslint-disable @typescript-eslint/no-unused-vars */

  async beforeCreate(api: BackendService): Promise<void> {}

  async afterCreate(api: BackendService, responseData: unknown): Promise<void> {
    this.inserted = new Date()
    this.updated = null
  }

  // ----------------------------------------------------

  async beforeUpdate(api: BackendService): Promise<void> {}

  async afterUpdate(api: BackendService, responseData: unknown): Promise<void> {
    this.updated = new Date()
  }

  // ----------------------------------------------------

  async beforeRemoval(api: BackendService): Promise<void> {}
  async afterRemoval(api: BackendService): Promise<void> {}

  // ----------------------------------------------------

  async beforeRemovalWithoutApi() {}
  async afterRemovalWithoutApi() {}

  /* eslint-enable @typescript-eslint/no-empty-function */
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // ----------------------------------------------------

  toString() {
    return `${this.constructor.name}(id: ${this.id || '-'})`
  }
}

// ------------------------------------------------------------

export abstract class OnCompanyModel extends Model {
  company: Company

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'company', include: 'create' }
    ]
  }

  // ----------------------------------------------------

  override attach() {
    this.company[ this.field ].add(this)
  }

  override detach() {
    this.company[ this.field ].remove(this)
  }
}

// ------------------------------------------------------------

export abstract class OnCompanyEventModel<E extends Event = Event> extends OnCompanyModel {
  event: E

  eventField: string

  // ----------------------------------------------------

  constructor({ event, ...data }) {
    super(data)

    this.setEvent(event)
  }

  // ----------------------------------------------------

  setEvent(event: E | null = null) {
    if (this.eventField) {
      this[ this.eventField ] = null
    }

    if (event) {
      // Add alias for this.event based on the event's field
      this.eventField = event.fieldSingular
      this.event = this[ this.eventField ] = event
    } else {
      this.event = this.eventField = null
    }
  }

  // ----------------------------------------------------

  override customisePayload(payload: object, mode: SaveMode): object {
    payload = super.customisePayload(payload, mode)

    if (mode === 'create') {
      payload[ this.eventField ] = this.event.link
    }

    return payload
  }

  // ----------------------------------------------------

  override attach() {
    this.event[ this.field ].add(this)
  }

  override detach() {
    this.event[ this.field ].remove(this)
  }
}
