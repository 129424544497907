import { head, maxBy, reduce, sortBy } from 'ramda'

import { isBefore, parseISO } from 'date-fns'

import { Collection } from '../collection'
import { OnCompanyModel, type ApiFieldSpec } from '../model'
import { DocumentCollection } from '../documents/document'
import type { Share } from '../stock/share'
import type { Investor } from '../stock/investor'
import type { ShareClass } from '../stock/share-class'
import { PurchaseStages, type PurchaseStage } from '../money/product'
import type { Round } from './round'
import type { Investment } from '../stock/investment'
import type { Currency, EventCategory } from '../../models'
import type { User } from '../user'

import { type Comparator, isoFormat, today } from '@libs/utils'

// ------------------------------------------------------------

const eventNameComparator = (a: Event, b: Event) => a.name && b.name
  ? a.name.localeCompare(b.name)
  : 0

const eventClosedComparator = (a: Event, b: Event) => Number(a.closed) - Number(b.closed)

const eventDateFieldComparator = (field: string, a: Event, b: Event) => a[ field ] && b[ field ]
  ? new Date(b[ field ]).getTime() - new Date(a[ field ]).getTime()
  : 0

/**
 * Used to sort events by `effectiveDate` from newest to oldest and falls back
 * to prioritise open over closed events, inserted date, and then by name.
 */
export const eventComparator = (a: Event, b: Event) => {
  return (
    eventDateFieldComparator('effectiveDate', a, b) ||
    eventClosedComparator(a, b) ||
    eventDateFieldComparator('inserted', a, b) ||
    eventNameComparator(a, b)
  )
}

// ------------------------------------------------------------

const EventPurchaseStageOrdering = new Map<PurchaseStage, number>([
  [ PurchaseStages.Deposit, 1 ],
  [ PurchaseStages.Agreement, 2 ],
  [ PurchaseStages.Balance, 3 ],
  [ null, 0 ]
])

const EventNextPurchaseStage = {
  [ PurchaseStages.Deposit ]: PurchaseStages.Agreement,
  [ PurchaseStages.Agreement ]: PurchaseStages.Balance,
  [ PurchaseStages.Balance ]: null
}

const EventPreviousPurchaseStage = {
  [ PurchaseStages.Deposit ]: null,
  [ PurchaseStages.Agreement ]: PurchaseStages.Deposit,
  [ PurchaseStages.Balance ]: PurchaseStages.Agreement
}

// ------------------------------------------------------------

export interface IEventViewState {
  state: string[]
  params?: object
}

// ------------------------------------------------------------

export interface IInvestorsByShareClass {
  isLeadInvestor: boolean
  investor: Investor
  investments: Investment[]
}

// ------------------------------------------------------------

export abstract class Event<A extends object = object> extends OnCompanyModel {
  abstract readonly category: EventCategory

  name: string | null
  effectiveDate: string
  approved: string | null
  answers: A

  documents = new DocumentCollection()

  user: User | null

  // ----------------------------------------------------

  constructor({
    name = null,
    effectiveDate = isoFormat(today()),
    approved = null,
    answers = {},
    ...data
  }) {
    super({
      name,
      effectiveDate,
      approved,
      answers,
      ...data
    })
  }

  // ----------------------------------------------------

  /**
   * Name of the field set on the scope object used by the question.visible getter
   * to evaluate if it should be shown to the user e.g. scope[e.scopeField] === e
   * for an instance of an Event or any subclass.
   */
  get scopeField() {
    return 'event'
  }

  // ----------------------------------------------------

  override attach() {
    super.attach()

    this.company.events.add(this)
  }

  override detach() {
    super.detach()

    this.company.events.remove(this)
  }

  // ----------------------------------------------------

  override async afterRemoval() {
    await Promise.all(this.documents.map(document => document.removeWithoutApi()))

    if (this[ 'investments' ]) {
      await Promise.all(this[ 'investments' ].map(investment => investment.removeWithoutApi()))
    }

    if (this[ 'statements' ]) {
      await Promise.all(this[ 'statements' ].map(statement => statement.removeWithoutApi()))
    }


    if (this[ 'options' ]) {
      await Promise.all(this[ 'options' ].map(option => option.removeWithoutApi()))
    }

    if (this[ 'option' ]) {
      await this[ 'option' ].removeWithoutApi()
    }

    if (this[ 'investors' ]) {
      this[ 'investors' ].clear()
    }
  }

  // ----------------------------------------------------

  override async beforeRemoval(api) {
    await super.beforeRemoval(api)
  }

  // ----------------------------------------------------

  get safeName(): string {
    return this.name || this.constructor.name
  }

  // ----------------------------------------------------

  get closed(): boolean {
    return !!this.approved
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'effectiveDate',
      'approved',
      'answers'
    ]
  }

  // ----------------------------------------------------

  abstract getViewState(): IEventViewState

  // ----------------------------------------------------

  getEditState(): IEventViewState {
    return this.getViewState()
  }

  // ----------------------------------------------------

  getQuestionState(
    sectionKey: string,
    questionKey?: string
  ): IEventViewState {
    const { state, params = {} } = this.getViewState()

    state.push('questions')

    if (sectionKey) {
      state.push(sectionKey)

      if (questionKey) {
        params[ 'scroll' ] = questionKey
      }
    }

    return { state, params }
  }

  // ----------------------------------------------------

  getPurchasedStages(): PurchaseStage[] {
    return this.company.getPurchasesForEntity(this).map(t => t.stage)
  }

  // ----------------------------------------------------

  getCurrentPurchaseStage(): PurchaseStage | null {
    const stages = this.getPurchasedStages()

    if (stages.length > 0) {
      // TODO: check
      return reduce(maxBy<PurchaseStage>(s => EventPurchaseStageOrdering.get(s)), null, stages)
    } else {
      return null
    }
  }

  // ----------------------------------------------------

  hasPurchasedStage(stage: PurchaseStage = PurchaseStages.Balance): boolean {
    return EventPurchaseStageOrdering.get(this.getCurrentPurchaseStage()) >= EventPurchaseStageOrdering.get(stage)
  }

  // ----------------------------------------------------

  getNextPurchaseStage(): PurchaseStage | null {
    const currentPurchaseStage = this.getCurrentPurchaseStage()
    return currentPurchaseStage
      ? EventNextPurchaseStage[ currentPurchaseStage ]
      : PurchaseStages.Agreement
  }

  // ----------------------------------------------------

  canPurchaseStage(stage: PurchaseStage = PurchaseStages.Balance): boolean {
    const currentPurchaseStage = this.getCurrentPurchaseStage()
    return currentPurchaseStage === EventPreviousPurchaseStage[ stage ]
  }

  // ----------------------------------------------------

  get upcomingRound(): Round | undefined {
    return head(sortBy(r => r.effectiveDate, this.company.rounds
      .filter(r => r.isFundingRound && r.effectiveDate > this.effectiveDate)))
  }

  // ----------------------------------------------------

  getSharesIssuedFromEvent(): Share[] {
    return []
  }

  // ----------------------------------------------------

  getInvestorsForShareClass(_: ShareClass): IInvestorsByShareClass[] {
    return []
  }

  // ----------------------------------------------------

  /**
   * Returns true if documents generated by this event each have
   * their own separate sets of questions and sub-workflows.
   */
  get hasIndividualQuestionsForDocuments(): boolean {
    return true
  }

  // ----------------------------------------------------

  /**
   * Returns true if documents generated by this event can be edited.
   */
  get canEditDocuments(): boolean {
    return !this.closed
  }

  // ----------------------------------------------------

  get timelineAmount(): number {
    return 0
  }

  // ----------------------------------------------------

  get locale(): Currency {
    return this.company.locale
  }
}

// ----------------------------------------------------------

/** Base class for all event collections */
export class EventCollectionBase<E extends Event = Event> extends Collection<E> {

  constructor(
    comparator: Comparator<E> | string | string[] = eventComparator,
  ) {
    super(comparator)
  }

  // ----------------------------------------------------

  findCurrentEvent(): E | undefined {
    return this.find(event => !event.closed)
  }

  // ----------------------------------------------------

  getCurrentEvents(): E[] {
    return this.filter(event => !event.closed)
  }

  // ----------------------------------------------------

  getCompletedEvents(): E[] {
    return this.filter(event => event.closed)
  }

  // ----------------------------------------------------

  findMostRecentEventBeforeEvent(event: Event): E | undefined {
    const d = parseISO(event.effectiveDate)

    for (const e of this.items()) {
      if (isBefore(parseISO(e.effectiveDate), d)) {
        return e
      }
    }

    return undefined
  }

}
