import type { DocumentTypeId } from '@libs/models'

import { EnabledCityNames, CohortCity, CohortManager, ICohortConfig } from './cohort-config.model'
import { CACohortMemberImmigrationTypes, DECohortMemberImmigrationTypes, FRCohortMemberImmigrationTypes, GBCohortMemberImmigrationTypes, SGCohortMemberImmigrationTypes } from './cohort-immigration'
import { getMemberQuestions } from './cohort-member-questions'
import { getMemberUploadQuestions } from './cohort-member-uploads'

// --------------------------------------------------------------

const EF_BASE_COHORT_CONFIG = {
  name: 'Entrepreneur First',
  description: 'Entrepreneur First',
  cohortLogo: '/assets/images/app/logos/ef-logo.svg',
  toolbarBackground: '#f67a62',
  FSRVDocType: 'EFFSRV' as DocumentTypeId,
  fundingDocTypes: [ 'CFAR', 'CFSA', 'CFBR', 'CFCLN', 'CFSR' ] as DocumentTypeId[],
  cohortManager: CohortManager.EntrepreneurFirst,
  cohortMemberUploads: getMemberUploadQuestions([
    'passport',
    'utility',
  ]),
  cities: Object.entries(EnabledCityNames).map(([ code, name ]: [ code: CohortCity, name: string ]) => ({ code, name })),
}

// --------------------------------------------------------------

export const EFConfigByCity: Record<CohortCity, ICohortConfig> = {

  LONDON: {
    ...EF_BASE_COHORT_CONFIG,
    offerLetterDocType: 'EFOLUK' as DocumentTypeId,
    cohortCountry: { code: 'GB', name: $localize`United Kingdom` },
    immigrationQuestionText: $localize`Do you have the right to work in the UK?`,
    immigrationTypes: GBCohortMemberImmigrationTypes,
    cohortMembersQuestions: getMemberQuestions([
      'passportNumber',
      'currentCompany',
      'companyInfo',
      'phd',
      'phdIpClaim',
      'employmentStatus',
      'employmentDetails',
      'cohortMemberHistory',
      'immigrationStatus',
      'immigrationReason',
      'immigrationVisaTypeOther',
    ]),
  },

  PARIS: {
    ...EF_BASE_COHORT_CONFIG,
    offerLetterDocType: 'EFOLFR' as DocumentTypeId,
    cohortCountry: { code: 'FR', name: $localize`France` },
    fundingDocTypes: [ 'CFBSA', 'EFFRBR', 'EFFRSR', 'EFFRSL' ] as DocumentTypeId[],
    immigrationQuestionText: $localize`Do you have the right to work in France?`,
    immigrationTypes: FRCohortMemberImmigrationTypes,
    FSRVDocType: 'FREFFA' as DocumentTypeId,
    cohortMembersQuestions: getMemberQuestions([
      'passportNumber',
      'dateOfBirth',
      'placeOfBirth',
      'nationality',
      'currentCompany',
      'companyInfo',
      'phd',
      'phdIpClaim',
      'employmentStatus',
      'employmentDetails',
      'cohortMemberHistory',
      'immigrationStatus',
      'immigrationReason',
      'immigrationVisaTypeOther',
    ]),
  },

  BERLIN: {
    ...EF_BASE_COHORT_CONFIG,
    offerLetterDocType: 'EFOLDE' as DocumentTypeId,
    FSRVDocType: 'EFFSDE' as DocumentTypeId,
    fundingDocTypes: [ 'CFCLDE' ] as DocumentTypeId[],
    cohortCountry: { code: 'DE', name: $localize`Germany` },
    immigrationQuestionText: $localize`Do you have the right to work in Germany?`,
    immigrationTypes: DECohortMemberImmigrationTypes,
    cohortMembersQuestions: getMemberQuestions([
      'passportNumber',
      'dateOfBirth',
      'placeOfBirth',
      'nationality',
      'currentCompany',
      'companyInfo',
      'phd',
      'phdIpClaim',
      'employmentStatus',
      'employmentDetails',
      'cohortMemberHistory',
      'immigrationStatus',
      'immigrationReason',
      'immigrationVisaTypeOther',
    ]),
  },

  TORONTO: {
    ...EF_BASE_COHORT_CONFIG,
    offerLetterDocType: 'EFOLCA' as DocumentTypeId,
    cohortCountry: { code: 'CA', name: $localize`Canada` },
    immigrationQuestionText: $localize`Do you have the right to work in Canada?`,
    immigrationTypes: CACohortMemberImmigrationTypes,
    cohortMembersQuestions: getMemberQuestions([
      'passportNumber',
      'currentCompany',
      'companyInfo',
      'phd',
      'phdIpClaim',
      'employmentStatus',
      'employmentDetails',
      'cohortMemberHistory',
      'immigrationStatus',
      'immigrationReason',
      'immigrationVisaTypeOther',
      'eligibleForWorkPermit',
      'graduatedInCountry',
    ]),
  },

  SINGAPORE: {
    ...EF_BASE_COHORT_CONFIG,
    offerLetterDocType: 'EFOLSP' as DocumentTypeId,
    cohortCountry: { code: 'SG', name: $localize`Singapore` },
    immigrationQuestionText: $localize`Do you have the right to work in Singapore?`,
    cohortMemberUploads: getMemberUploadQuestions([
      'passport',
      'proofOfAddress',
    ]),
    immigrationTypes: SGCohortMemberImmigrationTypes,
    cohortMembersQuestions: getMemberQuestions([
      'passportNumber',
      'nationality',
      'currentCompany',
      'companyInfo',
      'phd',
      'phdIpClaim',
      'employmentStatus',
      'employmentDetails',
      'cohortMemberHistory',
      'immigrationReason',
      'immigrationVisaTypeOther',
    ]),
  },

}
