<div id="trustbox"
     class="trustpilot-widget"
     data-locale="en-GB"
     [attr.data-template-id]="trustPilotData.templateId"
     [attr.data-businessunit-id]="trustPilotData.businessUnitId"
     data-style-height="142px"
     data-style-width="255px"
     data-theme="light">
  <a [href]="trustPilotData.url" target="_blank" rel="noopener">Trustpilot</a>
</div>
