import { IDebugOptions, IDebugStyles } from './models'

// ----------------------------------------------------------

export const commonEnvironment = {
  seedLegalsName: 'SeedLegals Ltd',
  region: 'COMMONWEALTH',
  tokens: {
    email: 'token',
    utm: {
      medium: 'utm_medium',
      source: 'utm_source',
      campaign: 'utm_campaign'
    }
  },
  CohortManager: {
    EntrepreneurFirstOperationsId: 'c_j4jayVVuC7',
    Carbon13Id: 'c_zrM5Zdfzyr',
    ClimateKicId: 'c_TKyEErZv6l'
  },
  pitchDeckTemplateLocation: 'https://assets.seedlegals.com/pitch/Pitch-Deck-Template-SeedLegals.pptx'
}

export const demo5Environment = {
  seedLegalsName: 'SeedLegals Ltd',
  region: 'COMMONWEALTH',
  tokens: {
    email: 'token',
    utm: {
      medium: 'utm_medium',
      source: 'utm_source',
      campaign: 'utm_campaign'
    }
  },
  CohortManager: {
    EntrepreneurFirstOperationsId: 'c_wvceukXXKd',
    Carbon13Id: 'c_AshBe49Q9p',
    ClimateKicId: 'c_TKyEErZv6l'
  },
  pitchDeckTemplateLocation: 'https://assets.seedlegals.com/pitch/Pitch-Deck-Template-SeedLegals.pptx'
}

// ----------------------------------------------------------

export const defaultDebugOptions: IDebugOptions = {
  addGlobals: false,
  logSocketEvents: false,
  logActions: false,
  logCollectionChanges: false,
  logWorkflows: false,
  logStripeEvents: false,
  logGlobalsService: false,
  logConsoleOutput: false
}

// ----------------------------------------------------------

export const defaultDebugStyles: IDebugStyles = {
  actionSource: 'color: #f0f;',
  actionName: 'color: #970;',
  actionErrorName: 'color: #e33;',
  actionInfo: 'color: #aaa;',

  requestUrl: 'color: #05f; text-decoration: underline;',
  requestRequest: 'color: #0a0; font-weight: bold;',
  requestResponse: 'color: #4af; font-weight: bold;',
  requestError: 'color: #e33; font-weight: bold;'
}

// Different styles for when using Dev Tools with a dark theme
// export const defaultDebugStyles: IDebugStyles = {
//   actionSource: 'color: #f8f;',
//   actionName: 'color: #ff8;',
//   actionInfo: 'color: #ccc;',

//   requestUrl: 'color: #05f; text-decoration: underline;',
//   requestRequest: 'color: #080;',
//   requestResponse: 'color: #0ac;',
//   requestError: 'color: #f00;'
// }
