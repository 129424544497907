
export function dataURItoBlob(dataUri: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  const [ head, data ] = dataUri.split(',')

  const byteString = head.includes('base64')
    ? atob(data)
    : decodeURI(data)

  // separate out the mime component
  const mimeType = head.match(/:(.*?)(?:;|$)/)[ 1 ]

  // write the bytes of the string to a typed array
  const uint8a = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    uint8a[ i ] = byteString.charCodeAt(i)
  }

  return new Blob([ uint8a ], { type: mimeType })
}

// ----------------------------------------------------------

const EXTERNAL_URL_REGEX = /^((https?|ftp):)?\/\//i

export function isExternalUrl(url: string): boolean {
  return EXTERNAL_URL_REGEX.test(url)
}
