import { Component, inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DocumentTypeCategories, DocumentTypeNames, EMPLOYMENT_TYPE_LABELS, EMPLOYMENT_TYPE_THEME } from '@libs/models'
import { SearchStore } from '../+state/search-store.service'

// ----------------------------------------------------------

@Component({
  selector: 'sl-search-dialog',
  templateUrl: 'search-dialog.component.html',
  styleUrls: [ './search-dialog.component.scss' ],
  providers: [
    SearchStore
  ]
})
export class SearchDialogComponent {

  private readonly data= inject<{ companyId: string }>(MAT_DIALOG_DATA)
  private readonly searchQuery = inject(SearchStore)

  readonly companyId = this.data.companyId

  readonly documentTypeNames= DocumentTypeNames
  readonly documentTypeCategories = DocumentTypeCategories

  readonly employmentTypeTheme = EMPLOYMENT_TYPE_THEME
  readonly employmentTypeLabels = EMPLOYMENT_TYPE_LABELS

  results$ = this.searchQuery.searches$
  loaded$ = this.searchQuery.loaded$
  loading$ = this.searchQuery.loading$

  // ------------------------------------------------------

  onSearch(searchText: string) {
    if (searchText.length < 3) {
      return this.searchQuery.reset()
    } else {
      this.searchQuery.search({ companyId: this.companyId, q: searchText })
    }
  }

}
