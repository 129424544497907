<div fxLayout="row"
     fxLayoutAlign="start center"
     class="mb-{{ marginBottom }} mt-{{ marginTop }} gap-{{ gap }}">
  <img *ngIf="emoji"
       src="/assets/images/emoji/{{ emoji }}.svg"
       width="24"
       height="24">
  <mat-icon *ngIf="icon"
            fontIcon="icon-{{icon}}"></mat-icon>
  <span>
    <ng-content></ng-content>
  </span>
</div>
