import { Inject, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core'
import { formatDate } from '@angular/common'

import { asIsoString } from '@libs/utils'

//-----------------------------------------------------------

@Pipe({
  name: 'slDateRange'
})
export class SlDateRangePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {}

  transform(
    startDate: Date | string,
    endDate: Date | string,
    format = 'mediumDate',
    separator = ' - '
  ): string | null {

    if (!startDate || !endDate) return null

    try {
      const isoStartDate = asIsoString(startDate)
      const isoEndDate = asIsoString(endDate)
      // Same date
      if (isoStartDate === isoEndDate) return formatDate(isoStartDate, format, this.locale)
      // Same month
      if (isoStartDate.substring(0, 7) === isoEndDate.substring(0, 7)) return formatDate(isoStartDate, 'MMM y', this.locale)
      // Same year
      if (isoStartDate.substring(0, 4) === isoEndDate.substring(0, 4)) return formatDate(isoStartDate, 'MMM', this.locale) + separator + formatDate(isoEndDate, 'MMM y', this.locale)
      // Further apart
      return formatDate(isoStartDate, 'MMM y', this.locale) + separator + formatDate(isoEndDate, 'MMM y', this.locale)
    } catch (error) {
      throw Error(`InvalidPipeArgument: '${startDate}' or '${endDate}' for pipe SlDateRangePipe`)
    }
  }
}
