import type { HasId } from './id.model'

// ----------------------------------------------------------

export interface PageQueryVars {
  pageIndex: number
  pageSize: number
  sortBy: string
  sortDir: 'ASC' | 'DESC'
  query?: string
  creatorCompanyIds?: string[]
}

// ----------------------------------------------------------

export interface PageResponse<T extends HasId = HasId> {
  totalElements: number
  totalPages: number
  number: number
  content: T[]
}

// ----------------------------------------------------------

export const pageResponseFragment = `
  fragment pageResponseFields on PageInfo {
    totalElements
    totalPages
    number
  }
`
