import { Component, OnInit } from '@angular/core'

import { AuthFacade } from '../../+state/auth.facade'
import { Login } from '../../+state/auth.actions'

// ----------------------------------------------------------

@Component({
  template: ``
})
export class LoginPageComponent implements OnInit {

  constructor(
    private facade: AuthFacade
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.facade.dispatch(Login())
  }

}
