import { Injectable } from '@angular/core'

import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data'

import { DocumentExcerpt } from '../models/entities'

// ----------------------------------------------------------

@Injectable({ providedIn: 'root' })
export class DocumentExcerptService extends EntityCollectionServiceBase<DocumentExcerpt> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('DocumentExcerpt', serviceElementsFactory)
  }
}
