import { EventCategory } from '../../models'
import type { IEventViewState } from './event'
import { DocumentEventAdapter } from './document-event-adapters'

// ----------------------------------------------------

export class FounderShareholderEvent extends DocumentEventAdapter {
  readonly domain = 'foundersShareholdersAgreements'
  readonly category = EventCategory.FundingRound

  // ----------------------------------------------------

  constructor({
    company,
    ...data
  }) {
    super({
      company,
      ...data
    })
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'documents', this.documents.items()[ 0 ].id, 'edit' ]
    }
  }

  // ----------------------------------------------------

  override getQuestionState(
    sectionKey: string
  ) {
    return {
      ...this.getViewState(),
      params: {
        panel: sectionKey
      }
    }
  }
}
