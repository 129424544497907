import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { Angulartics2Module } from 'angulartics2'

import { AnalyticsService } from './services/analytics.service'

import { environment } from '@env/environment'

// ----------------------------------------------------------

@NgModule({
  imports: [
    Angulartics2Module.forRoot({
      // Disables tracking
      developerMode: !environment.FLAGS.TRACK_PAGE_VIEWS,
      pageTracking: {
        // Removes IDs for URLs, to ease aggregation
        clearIds: true
      }
    }),
    EffectsModule.forFeature([
      AnalyticsService
    ])
  ]
})
export class AnalyticsModule {}
