import { Component, Inject, type OnDestroy, type OnInit } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { TrustPilotData } from '../../models/trust-pilot-data.model'

// ----------------------------------------------------

declare global {
  interface Window { Trustpilot: any }
}

// ----------------------------------------------------

/**
 * Mini widget from TrustPilot, manually adds and removes script from DOM onInit/Destroy.
 * https://support.trustpilot.com/hc/en-us/articles/360019826379#mini-8
 * https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#add-a-trustbox-widget-with-an-angular-application-2
 */
@Component({
  selector: 'sl-trustpilot-mini',
  templateUrl: './trustpilot-mini.component.html',
})
export class TrustpilotMiniComponent implements OnInit, OnDestroy {

  readonly trustPilotData = TrustPilotData
  trustPilotScript: HTMLScriptElement

  // ----------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private _document: Document
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    const script = this._document.createElement('script')
    script.type = 'text/javascript'
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true

    const head = this._document.getElementsByTagName('head')[0]
    this.trustPilotScript = head.insertBefore(script, head.firstChild)

    script.onload = () => window.Trustpilot.loadFromElement(this._document.getElementById('trustbox'))
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.trustPilotScript.remove()
  }
}
