import { Injectable } from '@angular/core'

import {
  EntityServicesBase,
  EntityServicesElements
} from '@ngrx/data'

import { UserExcerpt } from '@app/documents/models/entities'

import { DocumentExcerptService } from '@app/documents/services/document-excerpt.service'
import { CardService } from '@app/payments/services/card.service'

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class AppEntityService extends EntityServicesBase {

  get userExcerptService() {
    return this.getEntityCollectionService<UserExcerpt>('UserExcerpt')
  }

  // ----------------------------------------------------

  constructor(
    entityServicesElements: EntityServicesElements,
    public readonly documentExcerptService: DocumentExcerptService,
    public readonly cardService: CardService
  ) {
    super(entityServicesElements)

    this.registerEntityCollectionServices([
      documentExcerptService,
      cardService
    ])
  }
}
