
import { type Category, DocumentCategory, EventCategory } from './category.model'
import { Region } from './region.model'

// ------------------------------------------------------------

export const DocumentTypeNames = {
  ADVGAG: $localize`:@@docTypeADVGAG:BSA Grant Agreement`,
  ADVGBR: $localize`:@@docTypeADVGBR:CEO Resolution`,
  ADVGRC: $localize`:@@docTypeADVGRC:CEO Additional Report`,
  ADVGSL: $localize`:@@docTypeADVGSL:Subscription Form`,
  ADVSBR: $localize`:@@docTypeADVSBR:Board Report`,
  ADVSSR: $localize`:@@docTypeADVSSR:Shareholders Resolution`,
  AFIL: $localize`:@@docTypeAFIL:Affiliate Agreement`,
  AIRAG: $localize`:@@docTypeAIRAG:Investment Agreement for BSA Air`,
  AIRBR: $localize`:@@docTypeAIRBR:President's Report`,
  AIROC: $localize`:@@docTypeAIROC:Determination of claims`,
  AIRSL: $localize`:@@docTypeAIRSL:Subscription Form`,
  AIRSR: $localize`:@@docTypeAIRSR:Shareholder Unanimous Resolutions`,
  AP01: $localize`:@@docTypeAP01:Appointment of Director (AP01 form)`,
  APSFAG: $localize`:@@docTypeAPSFAG:SeedSAFE Agreement`,
  ARAS: $localize`:@@docTypeARAS:Articles of Association`,
  ASOS: $localize`:@@docTypeASOS:Advisor Agreement`,
  B5: $localize`:@@docTypeB5:Return of allotment of shares (B5 form)`,
  BMAG: $localize`:@@docTypeBMAG:Agenda`,
  BMBR: $localize`:@@docTypeBMBR:Board Minutes`,
  BOGAG: $localize`:@@docTypeBOGAG:Grant Letter`,
  BOGBR: $localize`:@@docTypeBOGBR:CEO Report`,
  BOGRC: $localize`:@@docTypeBOGRC:CEO Additional Report`,
  BORE: $localize`:@@docTypeBORE:Board Resolution`,
  BOSBR: $localize`:@@docTypeBOSBR:Board Resolution`,
  BOSPV: $localize`:@@docTypeBOSPV:CEO Resolution`,
  BOSRU: $localize`:@@docTypeBOSRU:Option Scheme Rules`,
  BOSSR: $localize`:@@docTypeBOSSR:Shareholders Resolution`,
  BSAR: $localize`:@@docTypeBSAR:Articles of Association`,
  BSBR: $localize`:@@docTypeBSBR:Board Resolution`,
  BSSA: $localize`:@@docTypeBSSA:Investment Agreement`,
  BSSR: $localize`:@@docTypeBSSR:Shareholders Resolution`,
  BSTS: $localize`:@@docTypeBSTS:Investment Proposal`,
  C13AG: $localize`:@@docTypeC13AG:Carbon13 SeedFAST Agreement`,
  C13FSA: $localize`:@@docTypeC13FSA:Carbon13 Founder Service Agreement`,
  C13OAG: $localize`:@@docTypeC13OAG:Carbon13 Options Agreement`,
  C13OL: $localize`:@@docTypeC13OL:Carbon13 Offer Letter`,
  C13OL3: $localize`:@@docTypeC13OL3:Carbon13 Phase 3 Offer Letter`,
  CFAR: $localize`:@@docTypeCFAR:Articles of Association`,
  CFBR: $localize`:@@docTypeCFBR:Board Resolution`,
  CFBSA: $localize`:@@docTypeCFBSA:Entrepreneur First BSA Air`,
  CFCLDE: $localize`:@@docTypeCFCLDE:Entrepreneur First Convertible Loan Note`,
  CFCLN: $localize`:@@docTypeCFCLN:Entrepreneur First Convertible Note`,
  CFSA: $localize`:@@docTypeCFSA:Entrepreneur First Service Agreement`,
  CFSL: $localize`:@@docTypeCFSL:Entrepreneur First Subscription Letter`,
  CFSR: $localize`:@@docTypeCFSR:Shareholders Resolution`,
  CH01: $localize`:@@docTypeCH01:Change of Director's Details (CH01 form)`,
  CKCAG: $localize`:@@docTypeCKCAG:Climate-KIC Community Agreement`,
  CKSAFE: $localize`:@@docTypeCKSAFE:Climate-KIC SeedFAST Agreement`,
  CLNFAG: $localize`:@@docTypeCLNFAG:Future Fund Application`,
  CLNFBR: $localize`:@@docTypeCLNFBR:Board Resolution`,
  CLNFPC: $localize`:@@docTypeCLNFPC:Previous Investor Consent`,
  CLNFSR: $localize`:@@docTypeCLNFSR:Shareholders Resolution`,
  CLNFTS: $localize`:@@docTypeCLNFTS:Future Fund Term Sheet`,
  COAG: $localize`:@@docTypeCOAG:Consultancy Agreement`,
  CS01: $localize`:@@docTypeCS01:Confirmation Statement (CS01 form)`,
  CV19FL: $localize`:@@docTypeCV19FL:Furlough Notice`,
  CVECVA: $localize`:@@docTypeCVECVA:Contract Variation Agreement`,
  DEED: $localize`:@@docTypeDEED:Deed of Adherence`,
  DISC: $localize`:@@docTypeDISC:Disclosure Letter`,
  DUDI: $localize`:@@docTypeDUDI:Due Diligence Request`,
  EFOLCA: $localize`:@@docTypeEFOLCA:Entrepreneur First Offer Letter`,
  EFOLDE: $localize`:@@docTypeEFOLDE:Entrepreneur First Offer Letter`,
  EFOLFR: $localize`:@@docTypeEFOLFR:Entrepreneur First Offer Letter`,
  EFOLSP: $localize`:@@docTypeEFOLSP:Entrepreneur First Offer Letter`,
  EFOLUK: $localize`:@@docTypeEFOLUK:Entrepreneur First Offer Letter`,
  EFFRBR: $localize`:@@docTypeEFFRBR:CEO Report - Remuneration of founders and BSA Air`,
  EFFRSL: $localize`:@@docTypeEFFRSL:Subscription form - BSA Air`,
  EFFRSR: $localize`:@@docTypeEFFRSR:Shareholders' decision - Remuneration of founders and BSA Air`,
  EFFSDE: $localize`:@@docTypeEFFSDE:Entrepreneur First Founder Service Agreement`,
  EFFSRV: $localize`:@@docTypeEFFSRV:Entrepreneur First Founder Service Agreement`,
  EIAA: $localize`:@@docTypeEIAA:Agent Authorisation Form`,
  EIAS: $localize`:@@docTypeEIAS:HMRC Advance Assurance Application`,
  EICL: $localize`:@@docTypeEICL:HMRC Advance Assurance Checklist`,
  EIIL: $localize`:@@docTypeEIIL:Advance Assurance Proposed Investor List`,
  EIS1: $localize`:@@docTypeEIS1:EIS Compliance Statement (EIS1 form)`,
  EMICL: $localize`:@@docTypeEMICL:EMI Valuation Cover Letter`,
  EMIRP: $localize`:@@docTypeEMIRP:EMI Report`,
  EMPA: $localize`:@@docTypeEMPA:Employment Agreement`,
  EVIC: $localize`:@@docTypeEVIC:Everyday Investor Certificate`,
  EXCOAG: $localize`:@@docTypeEXCOAG:Consultancy Agreement`,
  FREFFA: $localize`:@@docTypeFREFFA:Entrepreneur First Corporate Officer Agreement`,
  FREMPA: $localize`:@@docTypeFREMPA:Employment Agreement`,
  FRICSL: $localize`:@@docTypeFRICSL:Subscripton Letter`,
  FRICD: $localize`:@@docTypeFRICD:Deed of Adherence`,
  FRICBR: $localize`:@@docTypeFRICBR:President's decisions`,
  FRICPO: $localize`:@@docTypeFRICPO:Power of Attorney`,
  FRIPAS: $localize`:@@docTypeFRIPAS:IP Assignment`,
  FROPBR: $localize`:@@docTypeFROPBR:CEO Report`,
  FROPSR: $localize`:@@docTypeFROPSR:Shareholders Resolution`,
  FROXBR: $localize`:@@docTypeFROXBR:CEO Report`,
  FROXDE: $localize`:@@docTypeFROXDE:Notice of Exercise and Deed of Adherence`,
  FRMASO: $localize`:@@docTypeFRMASO:Founder CEO Contract (Contrat de Mandat Social)`,
  FRNDA: $localize`:@@docTypeFRNDA:Non-Disclosure Agreement (NDA)`,
  FRASOS: $localize`:@@docTypoeFRASOS:Advisor Agreement (Contrat Advisor)`,
  FRFSHA: $localize`:@@docTypeFRFSHA:Founder Shareholders Agreement`,
  FRSSBR: $localize`:@@docTypeFRSSBR:CEO Report`,
  FRSSPF: $localize`:@@docTypeFRSSPF:Power for Formalities`,
  FRSSSR: $localize`:@@docTypeFRSSSR:Unanimous Decisions of the Shareholders`,
  FSTODM: $localize`:@@docTypeFSTODM:Security Movement Order`,
  CERFA: $localize`:@@docTypeCERFA:CERFA 2759-SD Share Transfer`,
  FSRV: $localize`:@@docTypeFSRV:Founder Agreement`,
  G1: $localize`:@@docTypeG1:Special Resolution (G1)`,
  G2: $localize`:@@docTypeG2:Special Resolution (G2)`,
  GRARAS: $localize`:@@docTypeGRARAS:Articles of Association`,
  GRBR: $localize`:@@docTypeGRBR:President's decisions`,
  GRDL: $localize`:@@docTypeGRDL:Representations and Warranties`,
  GRINVP: $localize`:@@docTypeGRINVP:Subscription Agreement`,
  GRPC: $localize`:@@docTypeGRPC:Previous Investor Consent`,
  GRPN: $localize`:@@docTypeGRPN:Preemption Notice`,
  GRPOFO: $localize`:@@docTypeGRPOFO:Power for Formalities`,
  GRPOTS: $localize`:@@docTypeGRPOTS:Investment Proposal`,
  HKPOTS: $localize`:@@docTypeHKPOTS:Investment Proposal`,
  SGPOTS: $localize`:@@docTypeSGPOTS:Investment Proposal`,
  GRRMB: $localize`:@@docTypeGRRMB:CEO Report`,
  GRSHAG: $localize`:@@docTypeGRSHAG:Shareholders Agreement`,
  GRSL: $localize`:@@docTypeGRSL:Subscription Form`,
  GRSR: $localize`:@@docTypeGRSR:Decisions of the Shareholders`,
  GRTESH: $localize`:@@docTypeGRTESH:Term Sheet`,
  GRWP: $localize`:@@docTypeGRWP:Individual Preemption Waivers`,
  HKARAS: $localize`:@@docTypeHKARAS:Articles of Association`,
  HKICAG: $localize`:@@docTypeHKICAG:Instant Conversion Agreement`,
  HKIIAG: $localize`:@@docTypeHKIIAG:Instant Investment Agreement`,
  HKSHAG: $localize`:@@docTypeHKSHAG:Shareholders Agreement`,
  HNWC: $localize`:@@docTypeHNWC:High Net Worth Investor Certificate`,
  ICAG: $localize`:@@docTypeICAG:Instant Conversion Agreement`,
  ICBR: $localize`:@@docTypeICBR:Board Resolution`,
  ICCN: $localize`:@@docTypeICCN:Instant Conversion Notice`,
  ICPN: $localize`:@@docTypeICPN:Preemption Notice`,
  ICSR: $localize`:@@docTypeICSR:Shareholders Resolution`,
  IEARAS: $localize`:@@docTypeIEARAS:Constitution`,
  IEICAG: $localize`:@@docTypeIEICAG:Instant Conversion Agreement`,
  IEIIAG: $localize`:@@docTypeIEIIAG:Instant Investment Agreement`,
  IEOPAG: $localize`:@@docTypeIEOPAG:Option Agreement`,
  IEOPTM: $localize`:@@docTypeIEOPTM:Deed of Termination`,
  IIAG: $localize`:@@docTypeIIAG:Instant Investment Agreement`,
  IIBR: $localize`:@@docTypeIIBR:Board Resolution`,
  IIPC: $localize`:@@docTypeIIPC:Previous Investor Consent`,
  IIPN: $localize`:@@docTypeIIPN:Preemption Notice`,
  IISR: $localize`:@@docTypeIISR:Shareholders Resolution`,
  IITS: $localize`:@@docTypeIITS:Investment Proposal`,
  INTN: $localize`:@@docTypeINTN:Intern Agreement`,
  IOPT: $localize`:@@docTypeIOPT:Warrant Agreement`,
  IPAS: $localize`:@@docTypeIPAS:IP Assignment`,
  IPEN: $localize`:@@docTypeIPEN:Instant Investment Notice`,
  IPIC: $localize`:@@docTypeIPIC:Instant Investment Consent`,
  ISCE: $localize`:@@docTypeISCE:S/EIS Certificate (S/EIS3 form)`,
  ISCL: $localize`:@@docTypeISCL:Compliance Statement Cover Letter`,
  ISHR: $localize`:@@docTypeISHR:Instant Investment Resolution`,
  LCER: $localize`:@@docTypeLCER:Loan certificate`,
  M2: $localize`:@@docTypeM2:Declaration of modification of a company (M2 form)`,
  MNDA: $localize`:@@docTypeMNDA:Non-Disclosure Agreement`,
  NEDA: $localize`:@@docTypeNEDA:NED Agreement`,
  NIC: $localize`:@@docTypeNIC:Joint NIC Election`,
  NOWI: $localize`:@@docTypeNOWI:Notice of Willingness`,
  NSC1: $localize`:@@docTypeNSC1:Return of allotment of shares (NSC1 form)`,
  OCER: $localize`:@@docTypeOCER:Option Certificate`,
  OPAG: $localize`:@@docTypeOPAG:Option Agreement`,
  OPDE: $localize`:@@docTypeOPDE:Deed of Adherence`,
  OPEX: $localize`:@@docTypeOPEX:Notice of Exercise`,
  OPBR: $localize`:@@docTypeOPBR:Board Resolution`,
  OPIC: $localize`:@@docTypeOPIC:Previous Investor Consent`,
  OPSR: $localize`:@@docTypeOPSR:Shareholders Resolution`,
  OPTM: $localize`:@@docTypeOPTM:Deed of Termination`,
  OSUBR: $localize`:@@docTypeOSUBR:Share Option Board Resolution`,
  OSURU: $localize`:@@docTypeOSURU:Share Option Scheme`,
  PICN: $localize`:@@docTypePICN:Previous Investor Consent`,
  PLBR: $localize`:@@docTypePLBR:Board Resolution`,
  PLPC: $localize`:@@docTypePLPC:Previous Investor Consent`,
  PLSR: $localize`:@@docTypePLSR:Shareholders Resolution`,
  POTS: $localize`:@@docTypePOTS:Investment Proposal`,
  PYCP: $localize`:@@docTypePYCP:Website Privacy Policy`,
  PYCV19: $localize`:@@docTypePYCV19:Coronavirus Workplace Policy`,
  PYEP: $localize`:@@docTypePYEP:Employee Privacy Policy`,
  PYSH: $localize`:@@docTypePYSH:Staff Handbook`,
  S431: $localize`:@@docTypeS431:Joint Election under S431 ITEPA`,
  RDAA: $localize`:@@docTypeRDAA:HMRC R&D Advance Assurance Form`,
  RDACL: $localize`:@@docTypeRDACL:HMRC R&D Advance Assurance Cover Letter`,
  RDLA: $localize`:@@docTypeRDLA:HMRC R&D Letter to Accountant`,
  RDPNA: $localize`:@@docTypeRDPNA:Pre-notification Answers`,
  RDTN: $localize`:@@docTypeRDTN:HMRC R&D Technical Narrative`,
  RRRR: $localize`:@@docTypeRRRR:Quarterly Report Q3 2020`,
  SCER: $localize`:@@docTypeSCER:Share Certificate`,
  SCGBR: $localize`:@@docTypeSCGBR:Board Resolution`,
  SCGSR: $localize`:@@docTypeSCGSR:Shareholders Resolution`,
  SDWR: $localize`:@@docTypeSDWR:Sub-division Resolutions`,
  SEI1: $localize`:@@docTypeSEI1:SEIS Compliance Statement (SEIS1 form)`,
  SERV: $localize`:@@docTypeSERV:Founder Pledge`,
  SFAG: $localize`:@@docTypeSFAG:SeedFAST Agreement`,
  SFBR: $localize`:@@docTypeSFBR:Board Resolution`,
  SFIC: $localize`:@@docTypeSFIC:Investor Consent Notice`,
  SFSR: $localize`:@@docTypeSFSR:Shareholders Resolution`,
  SFTAG: $localize`:@@docTypeSFTAG:SeedSAFT Agreement`,
  SFTBR: $localize`:@@docTypeSFTBR:Board Resolution`,
  SFTIC: $localize`:@@docTypeSFTIC:Investor Consent Notice`,
  SFTSR: $localize`:@@docTypeSFTSR:Shareholders Resolution`,
  SGARAS: $localize`:@@docTypeSGARAS:Constitution`,
  SGICAG: $localize`:@@docTypeSGICAG:Instant Conversion Agreement`,
  SGIIAG: $localize`:@@docTypeSGIIAG:Instant Investment Agreement`,
  SGSHAG: $localize`:@@docTypeSGSHAG:Shareholders Agreement`,
  SH01: $localize`:@@docTypeSH01:Return of allotment of shares (SH01 form)`,
  SH02: $localize`:@@docTypeSH02:Notice of Sub-division (SH02 form)`,
  SHAG: $localize`:@@docTypeSHAG:Shareholders Agreement`,
  SHRE: $localize`:@@docTypeSHRE:Shareholders Resolution`,
  SINC: $localize`:@@docTypeSINC:Sophisticated Investor Certificate`,
  SIND: $localize`:@@docTypeSIND:Investor Disclaimer`,
  SNAG: $localize`:@@docTypeSNAG:SeedNOTE Agreement`,
  SNBR: $localize`:@@docTypeSNBR:Board Resolution`,
  SNPC: $localize`:@@docTypeSNPC:Previous Investor Consent`,
  SNSR: $localize`:@@docTypeSNSR:Shareholders Resolution`,
  SNTS: $localize`:@@docTypeSNTS:SeedNOTE Term Sheet`,
  SOBR: $localize`:@@docTypeSOBR:Share Option Board Resolution`,
  SOSC: $localize`:@@docTypeSOSC:Share Option Scheme`,
  SDBR: $localize`:@@docTypeSDBR:Share Split Board Minutes`,
  SPEN: $localize`:@@docTypeSPEN:Preemption Notice`,
  STBR: $localize`:@@docTypeSTBR:Share Transfer Board Minutes`,
  STDE: $localize`:@@docTypeSTDE:Share Transfer Deed`,
  STJ30: $localize`:@@docTypeSTJ30:Share Transfer J30 Form`,
  STPN: $localize`:@@docTypeSTPN:Share Transfer Preemption Notice`,
  STSPA: $localize`:@@docTypeSTSPA:Share Purchase Agreement`,
  TESH: $localize`:@@docTypeTESH:Term Sheet`,
  TM01: $localize`:@@docTypeTM01:Termination of Director (TM01 form)`,
  VAL231: $localize`:@@docTypeVAL231:Application for EMI Valuation`,
  XTTESH:	$localize`:@@docTypeXTTESH:Heads of Terms`,
  XTSPA:	$localize`:@@docTypeXTSPA:Share Purchase Agreement`,
  XTDISC:	$localize`:@@docTypeXTDISC:Disclosure Letter`,
  XTBORE:	$localize`:@@docTypeXTBORE:Board Resolution`,
  XTJ30:	$localize`:@@docTypeXTJ30:Transfer of shares (J30 form)`,
  ZEHC: $localize`:@@docTypeZEHC:Zero Hour Contract`,
} as const

// ----------------------------------------------------------

export type DocumentTypeId = keyof typeof DocumentTypeNames

// ------------------------------------------------------------

const categoriesToTypes: [ Category, DocumentTypeId[] ][] = [
  [
    EventCategory.FundingRound,
    [
      'ARAS', 'B5', 'BORE', 'BSAR', 'BSBR', 'BSSA', 'BSSR',
      'BSTS', 'DISC', 'G1', 'G2', 'GRARAS', 'GRBR', 'GRDL',
      'GRINVP', 'GRPC', 'GRPN', 'GRPOFO', 'GRRMB', 'GRSHAG',
      'GRSL', 'GRSR', 'GRTESH', 'GRWP', 'IEARAS', 'M2',
      'NSC1', 'PICN', 'HKARAS', 'SGARAS', 'SH01',
      'HKSHAG', 'SGSHAG', 'SHAG', 'SHRE', 'SPEN', 'TESH'
    ]
  ],
  [
    EventCategory.RegularReport,
    [ 'RRRR' ]
  ],
  [
    EventCategory.InstantConversion,
    [ 'ICAG', 'ICBR', 'ICCN', 'ICPN', 'ICSR', 'HKICAG', 'SGICAG', 'IEICAG' ]
  ],
  [
    EventCategory.InstantInvestment,
    [ 'IEIIAG', 'IIAG', 'IIBR', 'IIPC', 'IIPN', 'IISR', 'IITS', 'IPEN', 'IPIC', 'ISHR', 'HKIIAG', 'SGIIAG' ]
  ],
  [
    EventCategory.InstantShares,
    [ 'PLBR', 'PLPC', 'PLSR' ]
  ],
  [
    EventCategory.CohortFunding,
    [
      'C13FSA', 'CFAR', 'CFBR', 'CFCLDE', 'CFCLN', 'CFSA', 'CFSL', 'CFSR',
      'EFFSDE', 'EFFSRV', 'CFBSA', 'EFFRBR', 'EFFRSL', 'EFFRSR', 'FREFFA'
    ]
  ],
  [
    EventCategory.SeedFAST,
    [ 'AIRAG', 'AIRBR', 'AIROC', 'AIRSL', 'AIRSR', 'APSFAG', 'CKSAFE', 'C13AG', 'C13OAG', 'C13OL3', 'SFAG', 'SFBR', 'SFIC', 'SFSR' ]
  ],
  [
    EventCategory.SeedNOTE,
    [ 'SNTS', 'SNPC', 'SNAG', 'SNBR', 'SNSR' ]
  ],
  [
    EventCategory.SeedSAFT,
    [ 'SFTAG', 'SFTBR', 'SFTIC', 'SFTSR' ]
  ],
  [
    EventCategory.CLNFutureFund,
    [ 'CLNFAG', 'CLNFBR', 'CLNFPC', 'CLNFSR', 'CLNFTS' ]
  ],
  [
    EventCategory.AdvanceAssurance,
    [ 'EIAA', 'EIAS', 'EICL', 'EIIL' ]
  ],
  [
    EventCategory.SEISCompliance,
    [ 'EIS1', 'ISCL', 'SEI1' ]
  ],
  [
    EventCategory.ShareTransfer,
    [ 'STBR', 'STDE', 'STJ30', 'STPN', 'STSPA', 'FSTODM', 'CERFA' ]
  ],
  [
    EventCategory.StockSplit,
    [ 'FRSSBR', 'FRSSPF', 'FRSSSR', 'SDWR', 'SH02', 'SDBR' ]
  ],
  [
    EventCategory.ShareClassRegistration,
    [ 'SCGBR', 'SCGSR' ]
  ],
  [
    EventCategory.OptionScheme,
    [ 'ADVSBR', 'ADVSSR', 'BOSBR', 'BOSPV', 'BOSRU', 'BOSSR', 'OSUBR', 'OSURU', 'SOBR', 'SOSC' ]
  ],
  [
    EventCategory.EmiValuation,
    [ 'EMICL', 'EMIRP', 'VAL231' ]
  ],
  [
    EventCategory.OptionGrant,
    [ 'ADVGAG', 'ADVGBR', 'ADVGRC', 'ADVGSL', 'BOGBR', 'BOGRC', 'BOGAG', 'OPAG', 'IEOPAG', 'NIC' ]
  ],
  [
    EventCategory.OptionExercise,
    [ 'OPDE', 'OPEX', 'FROXDE', 'FROXBR', 'S431', 'OPTM', 'IEOPTM' ]
  ],
  [
    EventCategory.OptionPool,
    [ 'OPBR', 'OPIC', 'OPSR', 'FROPBR', 'FROPSR' ]
  ],
  [
    EventCategory.Proposal,
    [ 'C13OL', 'CKCAG', 'EFOLCA', 'EFOLDE', 'EFOLFR', 'EFOLSP', 'EFOLUK', 'GRPOTS', 'POTS', 'HKPOTS', 'SGPOTS' ]
  ],
  [
    DocumentCategory.QuickAgreement,
    [ 'ASOS', 'COAG', 'EXCOAG', 'CV19FL', 'CVECVA', 'FRMASO', 'INTN', 'IPAS', 'MNDA', 'NEDA', 'ZEHC', 'FRNDA', 'FRFSHA', 'FRASOS', 'FRIPAS' ]
  ],
  [
    DocumentCategory.InvestorAgreement,
    [ 'DEED', 'DUDI', 'EVIC', 'HNWC', 'IOPT', 'SINC', 'SIND' ]
  ],
  [
    DocumentCategory.CommercialAgreement,
    [ 'AFIL' ]
  ],
  [
    DocumentCategory.Certificate,
    [ 'ISCE', 'LCER', 'OCER', 'SCER' ]
  ],
  [
    DocumentCategory.Policies,
    [ 'PYCP', 'PYCV19', 'PYEP', 'PYSH' ]
  ],
  [
    EventCategory.Team,
    [ 'EMPA', 'FREMPA', 'FSRV', 'COAG' ]
  ],
  [
    EventCategory.Board,
    [ 'AP01', 'BMAG', 'BMBR', 'CH01', 'NOWI', 'TM01' ]
  ],
  [
    EventCategory.ConfirmationStatement,
    [ 'CS01' ]
  ],
  [
    EventCategory.ResearchAssurance,
    [ 'RDAA', 'RDACL' ]
  ],
  [
    EventCategory.ResearchClaim,
    [ 'RDLA', 'RDTN', 'RDPNA' ]
  ],
  [
    EventCategory.Exit,
    [ 'XTTESH', 'XTSPA', 'XTDISC', 'XTBORE', 'XTJ30' ]
  ],

]

// ------------------------------------------------------------

export const CategoryDocumentTypes = new Map(categoriesToTypes)

export const DocumentTypeCategories: Map<DocumentTypeId, Category> = categoriesToTypes.reduce((out, [ category, documentTypeIds ]) => {
  for (const type of documentTypeIds) {
    out.set(type, category)
  }

  return out
}, new Map<DocumentTypeId, Category>())

// ------------------------------------------------------------

const POLICIES_TYPES: Set<DocumentTypeId> = new Set(CategoryDocumentTypes.get(DocumentCategory.Policies))

// ------------------------------------------------------------

export function isDocumentTypeAPolicy(
  type: DocumentTypeId
): boolean {
  return POLICIES_TYPES.has(type)
}

// ------------------------------------------------------------

const PDF_ONLY_DOCUMENT_TYPES: Set<DocumentTypeId> = new Set([
  'AP01',
  'B5',
  'CS01',
  'CH01',
  'G1',
  'G2',
  'ISCE',
  'LCER',
  'M2',
  'NSC1',
  'OCER',
  'RRRR',
  'SCER',
  'SH01',
  'SH02',
  'TM01',
  'CERFA'
])

const DOWNLOAD_ONLY_DOCUMENT_TYPES: Set<DocumentTypeId> = new Set([
  'ISCE',
  'LCER',
  'OCER',
  'SCER'
])

// this is no longer used - agreement types can only be created if they are in DocumentTypesForAgreementCategory in agreement-type.ts
const DEPRECATED_DOCUMENT_TYPES: Set<DocumentTypeId> = new Set([
  'DEED',
  'CV19FL',
  'CVECVA'
])

const EMPTY_QUESTIONS_DOCUMENT_TYPES: Set<DocumentTypeId> = new Set([
  'C13OL',
  'EFOLCA',
  'EFOLDE',
  'EFOLFR',
  'EFOLSP',
  'EFOLUK',
  'EVIC',
  'HNWC',
  'M2',
  'RRRR',
  'SH01',
  'SH02',
  'SINC',
  'SIND',
  'CERFA'
])

const SIGNATURE_NOT_FULLY_REQUIRED_DOC_TYPES: Set<DocumentTypeId> = new Set([
  'BSTS',
  'GRPN',
  'GRPC',
  'GRTESH',
  'PICN',
  'SPEN',
  'TESH',
  'IITS'
])

const RESOLUTION_DOC_TYPES: Set<DocumentTypeId> = new Set([
  'BOSSR',
  'BSSR',
  'GRSR',
  'IISR',
  'SHRE',
  'SFTSR'
])

const ROUND_FUNDS_TRACKER_PREREQUISITE_TYPES: Set<DocumentTypeId> = new Set([
  'SHAG',
  'HKSHAG',
  'SGSHAG',
  'BSSA',
  'GRSHAG',
  'HKIIAG',
  'SGIIAG',
  'IIAG',
  'IEIIAG'
])

const DRAFTABLE_DOC_TYPES: Set<DocumentTypeId> = new Set([
  'SNTS', 'SNAG', 'TESH', 'SHAG', 'DISC', 'GRTESH', 'GRSHAG', 'GRDL', 'BSTS', 'BSSA'
])

const REQUIRES_EMPLOYEE_DOCUMENT_TYPES: Set<DocumentTypeId> = new Set([
  'AFIL',
  'ASOS',
  'COAG',
  'CV19FL',
  'CVECVA',
  'DEED',
  'EVIC',
  'EXCOAG',
  'FRIPAS',
  'FRMASO',
  'FRNDA',
  'FRASOS',
  'HNWC',
  'IOPT',
  'IPAS',
  'INTN',
  'MNDA',
  'NEDA',
  'SINC',
  'ZEHC'
])

export const SHARE_ALLOTMENT_PDF_BY_REGION: Map<Region, DocumentTypeId> = new Map([
  [ Region.Commonwealth, 'SH01' ],
  [ Region.HongKong, 'NSC1' ],
  [ Region.Ireland, 'B5' ]
])

// ------------------------------------------------------------

// TODO: Remove
export function isDocumentTypePdfOnly(
  type: DocumentTypeId
): boolean {
  return PDF_ONLY_DOCUMENT_TYPES.has(type)
}

// TODO: Remove
export function isDocumentTypeDownloadOnly(
  type: DocumentTypeId
): boolean {
  return DOWNLOAD_ONLY_DOCUMENT_TYPES.has(type)
}

export function isDocumentTypeDeprecated(
  type: DocumentTypeId
): boolean {
  return DEPRECATED_DOCUMENT_TYPES.has(type)
}

export function isDocumentTypeWithoutQuestions(
  type: DocumentTypeId
): boolean {
  return EMPTY_QUESTIONS_DOCUMENT_TYPES.has(type)
}

export function isDocumentTypeNotFullyRequiringSignature(
  type: DocumentTypeId
): boolean {
  return SIGNATURE_NOT_FULLY_REQUIRED_DOC_TYPES.has(type)
}

export function isDocumentTypeResolution(
  type: DocumentTypeId
): boolean {
  return RESOLUTION_DOC_TYPES.has(type)
}

export function isDocumentFundsTrackerPreRequisite(
  type: DocumentTypeId
): boolean {
  return ROUND_FUNDS_TRACKER_PREREQUISITE_TYPES.has(type)
}

export function isDocumentDraftable(
  type: DocumentTypeId
): boolean {
  return DRAFTABLE_DOC_TYPES.has(type)
}

export function isEmployeeNeededForDocumentType(
  type: DocumentTypeId
): boolean {
  return REQUIRES_EMPLOYEE_DOCUMENT_TYPES.has(type)
}

export function isPurchaseableDocument(
  type: DocumentTypeId
): boolean {
  return type === 'FRFSHA'
}

// ------------------------------------------------------------

export const AgreementDocumentsCardDescriptionByType = new Map<DocumentTypeId, string>([
  [ 'AFIL', $localize`Want to incentivise partners to refer customers to you? Our Affiliate Agreement lets you specify which items earn a referral fee, for how long, when commission will be paid, and more.` ],
  [ 'ASOS', $localize`Add an advisor to your team. Choose from different advisor types. Define their deliverables. Set remuneration in cash and/or equity. Includes non-compete provisions.` ],
  [ 'COAG', $localize`Hire a consultant or contractor. Define the project and deliverables. Set payment in cash and/or equity. Includes NDA provisions. The consultant can be a person or a company.` ],
  [ 'CV19FL', $localize`Need to cut costs and furlough your employees? This document is all you need, create one for each person you want to furlough.<br><br>You can select whether they get the government payment only, or you can top them up to their normal salary. The document also includes the legally required variation to their original employment agreement that allows you to stop offering them work.` ],
  [ 'CVECVA', $localize`Temporarily vary your employee's existing Employment Agreement.<br><br>Use this contract to reduce salary or hours, extend probation, set flexible working or make other changes.` ],
  [ 'DEED', $localize`Use this agreement to transfer shares to a shareholder outside of a funding round. After this agreement is signed and witnessed, you should then update the cap table, add the shareholder and the shares transferred to them.` ],
  [ 'DUDI', $localize`Get investment ready by starting on your due diligence checklist now, so you're ready with the data room documents your investors will be asking for at your next funding round.` ],
  [ 'EVIC', $localize`Friends, family and angel investors are likely to be Everyday Investors (also referred to by the FCA as Restricted Investors), to be safe you can get them to sign this letter confirming that.` ],
  [ 'EXCOAG', $localize`Hire a consultant or contractor. Define the project and deliverables. Set payment in cash and/or equity. Includes NDA provisions. The consultant can be a person or a company.` ],
  [ 'FRMASO', $localize`This service agreement sets out the founder and CEO's obligations and duties, it governs the relationship between the company and its corporate officers.<br><br>This agreement is not an employment contract subject to labor law, so your corporate officers may have both this and an employment contract.` ],
  [ 'FRIPAS', $localize`The assignment of intellectual property rights contract formalizes the transfer of rights concerning creations made by a founder to the benefit of the company (for example, trademarks, logos, domain names, or software). For employees, the transfer of these rights is directly addressed in their employment contract. A regularization may be necessary when new creations are made by the employee throughout their assignment.<br><br>Investors will want to ensure that all the necessary intellectual property rights for the development of the business are properly held by the company.` ],
  [ 'FRASOS', $localize`Most startups get external advisors and consultants onboard to benefit from their  extensive knowledge and network.<br><br>Use an Advisor Agreement to define multiple conditions of such collaboration: remuneration in equity and cash, define deliverables, IP and confidentiality, etc.` ],
  [ 'FRNDA', $localize`Create a Non-Disclosure Agreement (NDA) before sharing confidential information with a third party.<br><br>If the other party is a company, create the NDA in the name of the person who'll be signing on their behalf, then add the company details in the NDA questions.` ],
  [ 'FRFSHA', $localize`The Founder Shareholders Agreement governs the relationship between the shareholders of your company prior to the first funding round.<br><br>Create your Founder Shareholders Agreement to specify the founder vesting and leaver provisions, shareholder rights, company governance, and more.` ],
  [ 'HNWC', $localize`For larger investments, you may want to have your investors sign either a High Net Worth or a Sophisticated Investor Certificate, ideally at the time you send them your pitch deck.` ],
  [ 'INTN', $localize`Hire an intern. Specify their duties. Add payment details (interns need to be paid the minimum wage). Includes mentoring, reporting & other intern hiring requirements.` ],
  [ 'IOPT', $localize`A warrant gives someone a right to buy shares at a future date, at a price that you agree now. A typical use would be to give someone an agreed % equity in your next round, in return for their providing services now.` ],
  [ 'IPAS', $localize`Everyone who's contributed to your product or idea should sign an IP Assignment. Investors will check this as part of their due diligence. IP assignment provisions are also included in our hiring agreements.` ],
  [ 'MNDA', $localize`Our mutual NDA allows you and another party to share confidential information. If the other party is a company, create the NDA in the name of the person who'll be signing on their behalf, then add the company details in the NDA questions.` ],
  [ 'NEDA', $localize`Hire a Non-Executive Director. Set their role, duties and duration of their engagement. Set remuneration in cash and/or equity. Includes non-compete provisions.` ],
  [ 'PYCP', $localize`If you have a web site that collects Personal Data (any data which can be used to personally identify an individual) you'll need a web site Privacy Policy that tells your users what data you are collecting, when you collect it, why you collect it, and why you are legally entitled to collect it.<br><br>Create your web site Privacy Policy here, then download it as a text file and add it to your web site.` ],
  [ 'PYCV19', $localize`Coronavirus is here, and it's affecting us all. As a company you have an obligation to explain to your team what the company is doing to protect their health & safety, whether they are expected to work from home, will they be paid when they are self-isolated, etc.<br><br>You'll also need to make temporary changes to your existing policies, such as allowing employees to their own computers as needed, notify them of changes to your normal sickness policy, give them time off to care for dependents, etc.<br><br>Create your Coronavirus Workplace Policy now (it's totally free) then share it with your team so everyone knows the drill. We'll keep this policy updated as events change and as new legislation is introduced.` ],
  [ 'PYEP', $localize`The GDPR legally requires every company that processes and collects Personal Data (any data which can be used to personally identify an individual) to explain to those individuals what data they are collecting, when they collect it, why they collect it, and why they are legally entitled to collect it.<br><br>Stay GDPR-compliant by sharing this document with your team, and with new team members as part of their onboarding.` ],
  [ 'PYSH', $localize`Our staff handbook helps you shape your company culture and create company policies for up to 40 workplace-related issues.<br><br>Create Mission and Value statements to define your culture and inspire your team. Define your company's maternity leave, dress code and diversity policies. And have all the rules in place so when situations arise you and your team know where they stand.<br><br>Share this handbook with your team, and with new team members as part of their onboarding.` ],
  [ 'SINC', $localize`For larger investments, you may want to have your investors sign either a Sophisticated Investor or a High Net Worth Certificate, ideally at the time you send them your pitch deck.` ],
  [ 'SIND', $localize`An investor disclaimer protects you by having the investor declare they're a sophisticated or high net worth investor. You can include it in the materials you send to your investors, as needed.` ],
  [ 'ZEHC', $localize`Sometimes you can't predict your staffing needs, which is where zero hour contracts are useful. The working relationship begins when the person starts their shift and ends when they end their shift.` ]
])

// ------------------------------------------------------------

export const TeamAgreementsDocumentDescription: { documentType: DocumentTypeId, description: string }[] = [
  { documentType: 'EMPA', description: $localize`Our perfectly customisable Employment Agreement makes it super-easy to set work hours, roles, reporting, probation, termination, assign bonus and stock options. And you can e-sign, store and update the contract right here.` },
  { documentType: 'FSRV', description: $localize`Our Founder Pledge is designed to protect the founders and the company if things go wrong between the founders, which happens more often than you think. If the founders don't have a signed Founder Pledge in place, now's the time to do it. When you do your first funding round you may want to switch to our Founder Service Agreement with salary and employment provisions. Both can be found here.` },
  { documentType: 'COAG', description: $localize`Need to hire a consultant? Our Consultancy Agreement makes that easy. You can even give the consultant shares or share options which vest over a period of time or on hitting specified milestones. If the other party is a company, create the Consultancy Agreement in the name of the person who’ll be signing on their behalf, then add the company details when prompted in the questions.` },
]

export const APACTeamAgreementsDocumentDescription: { documentType: DocumentTypeId, description: string }[] = [
  { documentType: 'EMPA', description: $localize`Our perfectly customisable Employment Agreement makes it super-easy to set work hours, roles, reporting, probation, termination, assign bonus and stock options. And you can e-sign, store and update the contract right here.` },
  { documentType: 'FSRV', description: $localize`Our Founder Pledge is designed to protect the founders and the company if things go wrong between the founders, which happens more often than you think. If the founders don't have a signed Founder Pledge in place, now's the time to do it. When you do your first funding round you may want to switch to our Founder Service Agreement with salary and employment provisions. Both can be found here.` }
]

export const FrenchTeamAgreementsDocumentDescription: { documentType: DocumentTypeId, description: string }[] = [
  { documentType: 'FREMPA', description: $localize`Our perfectly customisable Employment Agreement makes it super-easy to set work hours, roles, reporting, probation, termination, assign bonus and stock options. And you can e-sign, store and update the contract right here.` }
]

export const TeamAgreementsDocumentDescriptionByRegion = {
  [ Region.Commonwealth ]: TeamAgreementsDocumentDescription,
  [ Region.France ]: FrenchTeamAgreementsDocumentDescription,
  [ Region.Ireland ]: TeamAgreementsDocumentDescription,
  [ Region.Singapore ]: APACTeamAgreementsDocumentDescription,
  [ Region.HongKong ]: APACTeamAgreementsDocumentDescription
}
