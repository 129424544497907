import { Component } from '@angular/core'

import { LayoutFacade } from '@app/layout/+state/layout.facade'

// ------------------------------------------------------------

@Component({
  template: `
    <sl-page-layout-with-subnav [links]="tabLinks$ | async"
                                [crumbs]="breadcrumbs$ | async"></sl-page-layout-with-subnav>
  `
})
export class PageLayoutComponent {

  breadcrumbs$ = this.layoutFacade.breadcrumbs$
  tabLinks$ = this.layoutFacade.tabLinks$

  // ----------------------------------------------------

  constructor(
    protected layoutFacade: LayoutFacade
  ) {}

}
