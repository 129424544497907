import { Component, ElementRef, Inject, Injector } from '@angular/core'

import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'

import { NotificationStore, INotificationStore } from '../../services/notification/notification-store.service'

import { NotificationPanelOverlayRef } from '../notification-panel/notification-panel-overlay-ref'
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component'

// ----------------------------------------------------------

@Component({
  selector: 'sl-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: [ './notification-icon.component.scss' ]
})
export class NotificationIconComponent {

  // ----------------------------------------------------

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private injector: Injector,
    @Inject(NotificationStore) public messages: INotificationStore
  ) {}

  // ----------------------------------------------------

  getInjector(panelRef: NotificationPanelOverlayRef) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: NotificationPanelOverlayRef,
          useValue: panelRef
        }
      ]
    })
  }

  // ----------------------------------------------------

  onClicked() {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.elementRef.nativeElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          }
        ]),
      maxHeight: 400,
      width: 256,
      maxWidth: 256
    })

    const panelRef = new NotificationPanelOverlayRef(overlayRef)

    const portal = new ComponentPortal(NotificationPanelComponent, null, this.getInjector(panelRef))

    overlayRef.attach(portal)

    overlayRef.backdropClick().subscribe(() => {
      overlayRef.dispose()
    })
  }
}
