import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { StatusDialogData } from '../../models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-error-dialog',
  template: `
    <div class="p-32 gap-10" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <span>
          <mat-icon
            fontIcon="icon-credit-card-off"
            color="warn"
            class="s48"
          ></mat-icon>
        </span>
        <h3 fxFlex="60">{{ data.title }}</h3>
      </div>
      <div *ngIf="data.text" class="ph-32" fxLayout="row" fxLayoutAlign="space-around center">
        {{ data.text }}
      </div>
    </div>
  `
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: StatusDialogData) {}
}
