import type { Region } from './region.model'

export type PlanId =
  | 'START'
  | 'RUN'
  | 'SCALE'
  | 'LEGACY'
  | 'BASIC'
  | 'PLUS'

// ----------------------------------------------------------

export enum Plans {
  Start = 'START',
  Run = 'RUN',
  Scale = 'SCALE',
  Legacy = 'LEGACY',
  Basic = 'BASIC',
  Plus = 'PLUS',
}

// ----------------------------------------------------------

export type BillingPeriod = 'MONTHLY' | 'YEARLY'

export enum BillingPeriods {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

// ----------------------------------------------------------

export interface IPlanMetadata {
  name: string
  description: string
  features: string[]
  featuresByRegion?: Partial<Record<Region, string[]>>
  perks?: string[]
  perksByRegion?: Partial<Record<Region, string[]>>
  recommended?: boolean
  additionalFeatures?: string[]
  additionalFeaturesByRegion?: Partial<Record<Region, string[]>>
  additionalFeaturesTitle?: string
  supportFeatures?: string[]
  supportFeaturesByRegion?: Partial<Record<Region, string[]>>
  discountDescription?: string,
  value?: string,
  valueByRegion?: Partial<Record<Region, string>>
}

// ----------------------------------------------------------

export interface IPlanDiscount {
  plans: PlanId[]
  billingPeriod: BillingPeriod
  type: DiscountType
  amount?: number
  percentageDiscount?: number
  numPeriods?: number
}

export type DiscountType = 'percentage' | 'flat'

// ----------------------------------------------------------

// --------- IRELAND BASIC FEATURES ------------------
const basicIrelandFeatures = [
  $localize`Manage your Cap Table and Shareholders`,
  $localize`Build and manage your team all in one place`,
  $localize`Access to Quick Agreements`
]
// --------- APAC BASIC FEATURES ------------------
const basicAPACFeatures = [
  $localize`Manage your Cap Table and Shareholders`,
  $localize`Build and manage your team all in one place`,
  $localize`Access to Quick Agreements`
]

// --------- FRENCH BASIC FEATURES ------------------
const basicFranceFeatures = [
  $localize`Pitch, investor tracker and Data Room`,
  $localize`Cap Table management`,
  $localize`Mandatory company registry`,
  $localize`Team and employment agreement`,
  $localize`NDA, Advisor agreement & other popular contracts`
]
const basicFrancePerks = [
  $localize`Access our 100+ partner network`
]

// --------- FRENCH PLUS FEATURES ------------------
const plusFranceFeatures = [
  $localize`Everything in Standard`,
  $localize`400+ Investors list`,
  $localize`Set of tools to facilitate your roadshow`,
  $localize`Set of resources to help you raise`,
]
const plusFranceAdditionalFeatures = [
  $localize`500€ credit in BSA AIRs`,
  $localize`No engagement fee, start your funding round for free`,
  $localize`Free Founder Shareholders Agreement`,
  $localize`Free share transfers`
]
const plusFranceSupportFeatures = [
  $localize`A dedicated Account Manager`,
  $localize`Quarterly SeedLegals strategy review`
]
const plusFrancePerks = [
  $localize`Deals and discounts from Hubspot, AWS, Stripe, Miro and Intercom`
]

// ----------------------------------------------------------

export const PlanMetadata: Record<PlanId, IPlanMetadata> = {

  [ Plans.Start ]: {
    name: $localize`Start`,
    description: $localize`Perfect for startups planning their first funding round`,
    features: [
      $localize`Manage your equity and team`,
      $localize`Access to Quick Agreements`,
      $localize`Full access to Pitch`,
    ]
  },

  [ Plans.Run ]: {
    name: $localize`Run`,
    description: $localize`Perfect for companies of 5-25 people`,
    features: [
      $localize`Everything in our Start plan`,
      $localize`Create Company Policies`,
      $localize`Run your company Board`
    ]
  },

  [ Plans.Scale ]: {
    name: $localize`Scale`,
    description: $localize`Perfect for companies of 25+ people`,
    features: [
      $localize`Everything in our Run plan`,
      $localize`Unlimited number of employees`,
      $localize`Upload your round supporting documents`,
      $localize`Priority support`
    ]
  },

  [ Plans.Legacy ]: {
    name: $localize`Legacy Plan`,
    description: $localize`Legacy Plan`,
    features: []
  },

  [ Plans.Basic ]: {
    name: $localize`Standard`,
    description: $localize`Pay as you go flexibility`,
    discountDescription: $localize`Save 15% with annual`,
    features: [
      $localize`20+ popular contracts and policies`,
      $localize`Team and employment agreements`,
      $localize`Cap Table management`,
      $localize`Board management`,
      $localize`Pitch, investor tracker and Data Room`,
    ],
    featuresByRegion: {
      FRANCE: basicFranceFeatures,
      HONG_KONG: basicAPACFeatures,
      IRELAND: basicIrelandFeatures,
      SINGAPORE: basicAPACFeatures,
    },
    additionalFeatures: [
      $localize`Unlimited expert help`
    ],
    additionalFeaturesTitle: $localize`Expert support`,
    perks: [
      $localize`Access to exclusive partnerships`
    ],
    perksByRegion: {
      FRANCE: basicFrancePerks
    },
    value: $localize`Worth over £3,500`,
    valueByRegion: {
      FRANCE: $localize`Worth over €3,500`
    }
  },

  [ Plans.Plus ]: {
    name: $localize`Plus`,
    description: $localize`Save thousands in discounts and perks`,
    features: [
      $localize`Everything in Standard`
    ],
    featuresByRegion: {
      FRANCE: plusFranceFeatures
    },
    additionalFeatures: [
      $localize`Free SEIS/EIS Advance Assurance`,
      $localize`No engagement fee, start free Funding round`,
      $localize`Free Share transfers and Share issues`,
      $localize`£100 discount on your first SeedFAST`,
      $localize`£500 discount on subsequent EMI valuations`,
      $localize`No engagement fee, start free R&D Tax Credits`,
    ],
    additionalFeaturesByRegion: {
      FRANCE: plusFranceAdditionalFeatures
    },
    additionalFeaturesTitle: $localize`Discounts`,
    supportFeaturesByRegion: {
      FRANCE: plusFranceSupportFeatures
    },
    perks: [
      $localize`Exclusive partnerships with AWS, Hubspot, Stripe, Miro and Intercom`,
      $localize`Access to private founders chat group`
    ],
    perksByRegion: {
      FRANCE: plusFrancePerks
    },
    value: $localize`Worth over £8,000`,
    valueByRegion: {
      FRANCE: $localize`Worth over €8,000`
    },
    recommended: true
  },

}

// ----------------------------------------------------------

export interface IFeatureTableSection {
  label: string
  rows: IFeatureTableRow[]
}

interface IFeatureTableRow {
  label: string
  STANDARD: IFeatureTableValue
  PLUS: IFeatureTableValue
}

interface IFeatureTableValue {
  amount?: number
  prefix?: string
  suffix?: string
  text?: string
  hint?: string
  hintAmount?: number
}

// ----------------------------------------------------------

export const CommonwealthMembershipFeatures: IFeatureTableSection[] = [
  {
    label: $localize`Raise`,
    rows: [
      {
        label: $localize`SEIS/EIS Advance Assurance`,
        STANDARD: {
          amount: 390
        },
        PLUS: {
          text: $localize`Free`
        },
      },
      {
        label: $localize`SeedFAST`,
        STANDARD: {
          hint: $localize`% of amount raised`
        },
        PLUS: {
          amount: 100,
          text: $localize`off`,
          hint: $localize`your first SeedFAST`,
        },
      },
      { label: $localize`Instant Investment`,
        STANDARD: {
          hint: $localize`% of amount raised`,
        },
        PLUS: {
          hint: $localize`% of amount raised`
        },
      },
      {
        label: $localize`Issue shares`,
        STANDARD: {
          amount: 290,
          hint: $localize`per share issue`
        },
        PLUS: {
          text: $localize`Free`
        },
      },
      {
        label: $localize`Transfer shares`,
        STANDARD: {
          amount: 290,
          hint: $localize`per share transfer`
        },
        PLUS: {
          text: $localize`Free`
        },
      },
      {
        label: $localize`SEIS/EIS Compliance`,
        STANDARD: {
          amount: 490
        },
        PLUS: {
          amount: 490
        },
      },
      {
        label: $localize`Open a Seed Round`,
        STANDARD: {
          amount: 690,
          text: $localize`engagement fee`,
          hint: $localize`balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      {
        label: $localize`Open a Series A Round`,
        STANDARD: {
          amount: 690,
          text: $localize`engagement fee`,
          hint: $localize`balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      {
        label: $localize`Open a Bootstrap Round`,
        STANDARD: {
          amount: 390,
          text: $localize`engagement fee`,
          hint: $localize`balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      {
        label: $localize`SeedNOTE`,
        STANDARD: {
          amount: 690,
          text: $localize`engagement fee`,
          hint: $localize`balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      }
    ],
  },
  {
    label: $localize`Share options schemes`,
    rows: [
      {
        label: $localize`EMI Option Scheme`,
        STANDARD: {
          amount: 1490
        },
        PLUS: {
          amount: 1490
        },
      },
      {
        label: $localize`EMI Valuation`,
        STANDARD: {
          amount: 990
        },
        PLUS: {
          amount: 990,
          suffix: $localize`first valuation`,
          hintAmount: 490,
          hint: $localize`all further valuations`
        },
      },
      {
        label: $localize`Unapproved Option Scheme`,
        STANDARD: {
          amount: 1490
        },
        PLUS: {
          amount: 1490
        },
      }
    ],
  },
  {
    label: $localize`R&D Tax Credits`,
    rows: [
      {
        label: $localize`Get money back from HMRC`,
        STANDARD: {
          amount: 490,
          text: $localize`engagement fee`,
          hint: $localize`5% of total claim, balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`5% of total claim, pay on completion`
        },
      }
    ],
  }
]

export const FrenchMembershipFeatures: IFeatureTableSection[] = [
  {
    label: $localize`Raise`,
    rows: [
      {
        label: $localize`BSA AIR Round`,
        STANDARD: {
          text: $localize`% of amount raised`,
        },
        PLUS: {
          amount: 500,
          text: $localize`credit`,
          hint: $localize`in BSA AIRs`,
        },
      },
      {
        label: $localize`Open a Bootstrap Round`,
        STANDARD: {
          text: $localize`700€ engagement fee`,
          hint: $localize`Balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      {
        label: $localize`Open a Seed Round`,
        STANDARD: {
          text: $localize`700€ engagement fee`,
          hint: $localize`Balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      {
        label: $localize`Open a Series A Round`,
        STANDARD: {
          text: $localize`700€ engagement fee`,
          hint: $localize`Balance on completion`
        },
        PLUS: {
          text: $localize`No engagement fee`,
          hint: $localize`Start free, pay on completion`
        },
      },
      { label: $localize`Founder Shareholders Agreement`,
        STANDARD: {
          amount: 590
        },
        PLUS: {
          text: $localize`FREE`
        },
      },
      {
        label: $localize`Transfer shares`,
        STANDARD: {
          amount: 250,
          hint: $localize`per share transfer`
        },
        PLUS: {
          text: $localize`FREE`
        },
      },
      {
        label: $localize`Share split`,
        STANDARD: {
          amount: 250,
        },
        PLUS: {
          amount: 250,
        },
      },
      {
        label: $localize`Instant Conversion`,
        STANDARD: {
          prefix: $localize`from`,
          amount: 250,
        },
        PLUS: {
          prefix: $localize`from`,
          amount: 250,
        },
      },
      {
        label: $localize`UK investor SEIS/EIS Advance Assurance`,
        STANDARD: {
          amount: 500
        },
        PLUS: {
          amount: 500
        },
      },
      {
        label: $localize`UK investor SEIS/EIS Compliance`,
        STANDARD: {
          amount: 500
        },
        PLUS: {
          amount: 500
        },
      }
    ],
  },
  {
    label: $localize`Share options schemes`,
    rows: [
      {
        label: $localize`BSPCE Plan`,
        STANDARD: {
          amount: 1250
        },
        PLUS: {
          amount: 1250
        },
      },
      {
        label: $localize`BSA Advisor Plan`,
        STANDARD: {
          amount: 1250
        },
        PLUS: {
          amount: 1250
        },
      },
      {
        label: $localize`BSPCE & BSA Advisor pack`,
        STANDARD: {
          amount: 1990
        },
        PLUS: {
          amount: 1990
        },
      },
    ],
  }
]

// ----------------------------------------------------------

export interface ICoreFeaturesList {
  label: string
  features: string []
}

// ----------------------------------------------------------

export const CommonwealthCoreFeatures: ICoreFeaturesList[] = [
    {
      label: $localize`Manage your cap table and shareholders`,
      features: [
        $localize`Add or remove shareholders, allocate shares`,
        $localize`Create share classes`,
        $localize`Do a share split`,
        $localize`Create share certificates`,
      ]
    },
    {
      label: $localize`Engage investors`,
      features: [
        $localize`Create your pitch page, share with investors`,
        $localize`Track investor interest`,
        $localize`Upload and share documents with Data Room`,
      ]
    },
    {
      label: $localize`Run your business`,
      features: [
        $localize`Appoint directors`,
        $localize`Create board meetings`,
        $localize`Share, sign and store board documents`,
        $localize`Create Confirmation Statements`,
        $localize`Calendar of legal tasks and deadlines`,
      ]
    },
    {
      label: $localize`Create and manage your team`,
      features: [
        $localize`Employment Contracts`,
        $localize`Track salaries and bonus payments`,
        $localize`Terminate employment`,
        $localize`Staff Handbook`,
      ]
    },
    {
      label: $localize`Your most common contracts`,
      features: [
        $localize`Consultancy Agreement`,
        $localize`Director & Advisor Agreements`,
        $localize`NDAs & IP Assigment`,
        $localize`NED Agreement`,
        $localize`Zero Hour, Intern & Affiliate Contracts`,
        $localize`Affiliate Agreement`,
        $localize`Warrant Agreement`,
        $localize`Website Privacy Policy`,
        $localize`Employee Privacy Policy`,
      ]
    }
]

export const FrenchCoreFeatures: ICoreFeaturesList[] = [
    {
      label: $localize`Engage investors`,
      features: [
        $localize`Create your pitch page, share with investors`,
        $localize`Track investor interest`,
        $localize`Upload and share documents with Data Room`,
      ]
    },
    {
      label: $localize`Create and manage your team`,
      features: [
        $localize`Employment Contracts`,
        $localize`Track salaries and bonus payments`
      ]
    },
    {
      label: $localize`Calendar of legal tasks and deadlines`,
      features: [
        $localize`Follow your legal tasks`,
        $localize`Track your deadlines`,
        $localize`Compare documents`
      ]
    },
    {
      label: $localize`Manage your cap table and shareholders`,
      features: [
        $localize`Add or remove shareholders, allocate shares`,
        $localize`Create share classes`,
        $localize`Create share certificates (MonJuridique)`,
      ]
    },
    {
      label: $localize`Your most common contracts`,
      features: [
        $localize`NDAs & IP Assigment`,
        $localize`Advisor agreement`,
        $localize`Consultant Agreement`
      ]
    }
  ]

// ----------------------------------------------------------

// Exporting regional variables

export interface IRegionFeatures{
  core?: ICoreFeaturesList[],
  membership?: IFeatureTableSection[]
}

const defaultFeatures : IRegionFeatures = {
  core: CommonwealthCoreFeatures,
  membership: CommonwealthMembershipFeatures
}

export const RegionFeatures : Partial<Record<Region, IRegionFeatures>> = {
  COMMONWEALTH: {
    ...defaultFeatures
  },
  FRANCE: {
    core: FrenchCoreFeatures,
    membership: FrenchMembershipFeatures
  },
  IRELAND: {
    ...defaultFeatures
  },
  SINGAPORE: {
    ...defaultFeatures
  },
  HONG_KONG: {
    ...defaultFeatures
  }
}

// ----------------------------------------------------------

export interface IAdditionalMembershipBenefitItem {
  label: string
  description: string
}

export const AdditionalMembershipBenefitItems: IAdditionalMembershipBenefitItem[] = [
  {
    label: $localize`Talk to us your way`,
    description:$localize`Chat, phone, email, video call`
  },
  {
    label: $localize`Get unlimited help`,
    description:$localize`No billable hours, no extra cost`
  },
  {
    label: $localize`Ask us anything`,
    description:$localize`We’re here 9am to 6pm Mon - Fri`
  },
]




// -----------------------------------------------------------

//Export sorted list of svg assets for plans perks according to jurisdiction
export interface IPlanAssets{
  logos?: string
  flash?: string
  recommended?: string
}

export const DefaultAssets : Partial<Record<PlanId, IPlanAssets>> = {
  PLUS: {
    logos: 'assets/images/app/plans/logos_plus.png',
  },
  BASIC: {
    logos: 'assets/images/app/plans/logos_standard.png',
  }
}

export const AssetsByRegion : Partial<Record<Region, Partial<Record<PlanId, IPlanAssets>>>> = {
  COMMONWEALTH : {
    ...DefaultAssets
  },
  FRANCE : {
    PLUS: {
      logos: 'assets/images/app/plans/logos_plus_fr.png'
    },
    BASIC: {
      logos: 'assets/images/app/plans/logos_standard_fr.png'
    }
  },
  IRELAND : {
    ...DefaultAssets
  },
  SINGAPORE : {
    ...DefaultAssets
  },
  HONG_KONG : {
    ...DefaultAssets
  }
}
