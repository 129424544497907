<div mat-dialog-title
     class="bb">
  <div class="header-content"
       gdColumns="40px 1fr 40px"
       gdColumns.lt-sm="1fr"
       gdRows="72px"
       gdRows.lt-sm="80px 48px"
       gdAreas="blank title close"
       gdAreas.lt-sm="close | title">
    <h1 gdArea="title"
        gdGridAlign="center center"
        gdGridAlign.lt-sm="start center">{{label}}</h1>
    <button mat-icon-button
            mat-dialog-close
            gdArea="close"
            gdGridAlign="center center"
            gdGridAlign.lt-md="end center">
      <mat-icon fontIcon="icon-close"></mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content
     class="content">
  <ng-content></ng-content>
</div>

<div matDialogActions>
  <ng-content select="footer"></ng-content>
</div>
