<div class="arrow-selector">
  <div class="arrow fake-arrow"></div>
  <div *ngFor="let step of steps; let index = index"
       class="arrow"
       [ngClass]="{
         selected: selectedIndex === index,
         disabled: !!step.disabled
       }"
       (click)="stepClicked(step, index)">
    <span>{{ step.label }}</span>
  </div>
</div>
