import { CohortMember } from '@libs/models'
import { OtherVisaType } from './cohort-immigration'

export interface CohortQuestion {
  key: string
  label: string
  type: 'choice' | 'boolean' | 'freeText' | 'date' | 'nationality' // TODO: remove this from types
  required?: boolean
  enabled?: (additionalInfo: any) => boolean
  defaultValue?: string | ((cohortMember: CohortMember) => string)
}

// ------------------------------------------------------

const memberQuestions: CohortQuestion[] = [
  {
    key: 'passportNumber',
    label: $localize`Passport number`,
    type: 'choice',
    required: true
  },
  {
    key: 'currentCompany',
    label: $localize`Currently have an incorporated company`,
    type: 'boolean',
    required: true
  },
  {
    key: 'companyInfo',
    label: $localize`Company details`,
    type: 'choice',
    required: true,
    enabled: additionalInfo => additionalInfo.currentCompany
  },
  {
    key: 'phd',
    label: $localize`Currently enrolled in a PhD`,
    type: 'boolean',
    required: true
  },
  {
    key: 'phdIpClaim',
    label: $localize`PhD has claim to any IP`,
    type: 'boolean',
    required: true,
    enabled: additionalInfo => additionalInfo.phd
  },
  {
    key: 'immigrationStatus',
    label: $localize`Has right to work in cohort country`,
    type: 'choice',
    required: true
  },
  {
    key: 'immigrationReason',
    label: $localize`Immigration status`,
    type: 'choice',
    required: true,
    enabled: additionalInfo => additionalInfo.immigrationStatus === 'Yes'
  },
  {
    key: 'immigrationVisaTypeOther',
    label: $localize`Other visa type`,
    type: 'choice',
    required: true,
    enabled: additionalInfo => additionalInfo.immigrationStatus === 'Yes' && additionalInfo.immigrationReason === OtherVisaType.OtherVisa
  },
  {
    key: 'employmentStatus',
    label: $localize`Are you currently employed, on sabbatical, taking leave (unpaid or otherwise) or any equivalent break from any employer, academic institution or equivalent?`,
    type: 'boolean',
    required: true
  },
  {
    key: 'employmentDetails',
    label: $localize`Could you provide further details?`,
    type: 'freeText',
    required: true,
    enabled: additionalInfo => additionalInfo.employmentStatus === 'Yes'
  },
  {
    key: 'cohortMemberHistory',
    label: $localize`Have you previously raised money for a company you intend to work on on the Entrepreneur First programme?`,
    type: 'boolean'
  },
  {
    key: 'dateOfBirth',
    label: $localize`Date of Birth`,
    type: 'date',
    required: true
  },
  {
    key: 'placeOfBirth',
    label: $localize`Place of Birth`,
    type: 'freeText',
    required: true
  },
  {
    key: 'nationality',
    label: $localize`Nationality`,
    type: 'nationality',
    required: true,
    defaultValue: (cohortMember) => cohortMember.cohort.config.cohortCountry.code
  },
  {
    key: 'eligibleForWorkPermit',
    label: $localize`Have you graduated or will be graduating from a Canadian university (from a Bachelors, Masters, or PhD) with a course duration greater than 8 months?`,
    type: 'boolean',
    required: true
  },
  {
    key: 'graduatedInCountry',
    label: $localize`Have you received or will soon be eligible for an open work permit after your graduation from a Canadian university (from a Bachelors, Masters, or PhD) with a course duration greater than 8 months?`,
    type: 'boolean',
    required: true
  }
]

const questionMap = memberQuestions.reduce(
  (out, q) => out.set(q.key, q),
  new Map<string, CohortQuestion>()
)

// -----------------------------------------------------

export function getMemberQuestions(
  keys: string[]
): CohortQuestion[] {
  return keys.map(k => questionMap.get(k))
}
