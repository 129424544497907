import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core'

import { Company } from '@libs/models'

// ----------------------------------------------------------

@Component({
  selector: 'sl-left-nav-page-link',
  templateUrl: './left-nav-page-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftNavPageLinkComponent implements OnChanges {
  @Input() company?: Company

  @Input() target?: string | string[]

  @Input() exact = false

  @Input() label: string
  @Input() image: string

  // @Input() accessRole?: AppointmentRole

  route: Array<string | number>

  // ----------------------------------------------------

  ngOnChanges() {
    this.route = []

    if (this.company) {
      this.route.push('/companies', this.company.id)
    }

    if (Array.isArray(this.target)) {
      this.route.push(...this.target)
    } else if (this.target) {
      this.route.push(this.target)
    }
  }
}
