import { getInitials } from '@libs/utils'

import { Company } from '.'
import { ApiFieldSpec, INamed, Model } from './model'

// ----------------------------------------------------
export class Department extends Model implements INamed {
  readonly domain = 'departments'

  company: Company
  name: string
  initials: string

  // ----------------------------------------------------

  constructor({
    company,
    name = '',
    ...data
  }) {
    super({ company, name, ...data })

    this.nameChanged()
  }

  // ----------------------------------------------------

  nameChanged() {
    this.initials = getInitials(this.name)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'company', include: 'create' },
      'name'
    ]
  }
}
