import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

// --------------------------------------------------------

@Component({
  selector: 'sl-multi-progress-bar',
  template: `
    <div class="container">
      <div class="background"></div>
      <div *ngFor="let item of _items"
           class="item"
           [style]="item"></div>
    </div>
  `,
  //  [style.backgroundColor]="item[ 0 ]"
  //  [style.width.pc]="item[ 1 ]"
  //  [style.left.pc]="item[ 2 ]"></div>
  styleUrls: [ './multi-progress-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiProgressBarComponent {

  @Input()
  set items(
    values: Array<[ colour: string, value: number ]>
  ) {
    const newItems = []
    let offset = 0

    for (const [ backgroundColor, value ] of values) {
      newItems.push({
        backgroundColor,
        width: value * 100 + '%',
        left: offset + '%',
      })

      offset += value * 100
    }

    this._items = newItems
  }

  _items: Array<{ backgroundColor: string, width: number, left: number }> = []

  // @Input()
  // set percentage(pct: number) {
  //   this.barValue = 100 * Math.max(0, Math.min(1, pct))
  // }

  // _value = 0
  // _total = 0

  // @Input()
  // set value(value: number) {
  //   this._value = value
  //   this.setBarValue()
  // }

  // @Input()
  // set total(total: number) {
  //   this._total = total
  //   this.setBarValue()
  // }

  // barValue = 0

  // // ----------------------------------------------------

  // setBarValue() {
  //   const p = percentage(this._value, this._total)

  //   this.barValue = 100 * Math.max(0, Math.min(1, p))
  // }
}
