import { EventCategory } from '../../models/category.model'
import { Event, EventCollectionBase } from './event'

import type { IEventViewState } from './event'
import type { ShareClass } from '../stock/share-class'
import type { ApiFieldSpec } from '../model'

// ------------------------------------------------------------

export class ShareClassRegistration extends Event {
  readonly category = EventCategory.ShareClassRegistration
  readonly domain = 'shareClassRegistrations'
  readonly workflowKey = 'share-class-registration'

  shareClass: ShareClass

  // ----------------------------------------------------

  override name = $localize`Share Class Registration`

  constructor({
    shareClass = null,
    ...data
  }) {
    super({
      shareClass,
      ...data
    })
  }

  // ----------------------------------------------------

  override get safeName(): string {
    return $localize`${this.shareClass.name} share registration`
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'shareClass',
      { key: 'company', include: 'create' },
      { key: 'revision', include: 'create' },
    ]
  }

  // ----------------------------------------------------

  getViewState(): IEventViewState {
    return {
      state: [ '/companies', this.company.id, 'captable', 'share-classes', 'share-class-registration', this.id ]
    }
  }

}

// ------------------------------------------------------

export class ShareClassRegistrationCollection extends EventCollectionBase<ShareClassRegistration> {
  getByShareClassId(id: string): ShareClassRegistration | undefined {
    return this.find(i => i.shareClass.id === id)
  }
}
