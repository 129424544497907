import { Observable } from 'rxjs'
import { debounceTime, startWith } from 'rxjs/operators'

// ----------------------------------------------------

export interface FromResizeOptions {
  emitOnStart: boolean
  debounceTime: number
}

// ----------------------------------------------------

export function fromResize(
  element: Element,
  options?: Partial<FromResizeOptions>,
): Observable<DOMRectReadOnly> {
  const resolvedOptions: FromResizeOptions = {
    emitOnStart: true,
    debounceTime: 10,
    ...options,
  }

  const initialRect = element.getBoundingClientRect()

  let resize$ = buildResize(element).pipe(debounceTime(resolvedOptions.debounceTime))

  if (resolvedOptions.emitOnStart) {
    resize$ = resize$.pipe(startWith(initialRect))
  }

  return resize$
}

// ----------------------------------------------------

const buildResize = (element: Element): Observable<DOMRectReadOnly> => new Observable(subscriber => {
  const resizeObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      if (entry.target === element) {
        subscriber.next(entry.contentRect)
      }
    })
  })

  resizeObserver.observe(element, { box: 'border-box' })

  return () => {
    resizeObserver.unobserve(element)
    resizeObserver.disconnect()
  }
})
