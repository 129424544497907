import { Component, Input } from '@angular/core'

import { Category, CategoryLabels } from '@libs/models'

// -------------------------------------------------------------------------

@Component({
  selector: 'sl-category-bullet',
  template: `
      <div *ngIf="label"
           class="chip {{ size }}"
           [ngClass]="category | lowercase">{{ label }}</div>
  `,
  styleUrls: [ './category-bullet.component.scss' ]
})
export class CategoryBulletComponent {

  @Input() category: Category
  @Input() size: 'small' | 'large' = 'small'

  get label() {
    return CategoryLabels[ this.category ]
  }
}
