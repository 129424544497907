import { pick } from 'ramda'

import { Model, ApiFieldSpec } from './model'
import { CountryCode } from '../models/country.model'
import { IAddressDataWithoutId, IAddressData } from '../models/address.model'

// ------------------------------------------------------------

export const _addressSafeDataPicker = pick([
  'name',
  'line1',
  'line2',
  'city',
  'postcode',
  'country'
])

export const addressSafeDataPicker = (a: Address | IAddressData): IAddressDataWithoutId => _addressSafeDataPicker(a)

// ------------------------------------------------------------

export class Address extends Model {
  readonly domain = 'addresses'

  name: string | null
  line1: string
  line2: string | null
  city: string
  postcode: string
  country: CountryCode

  // ----------------------------------------------------

  constructor({
    name = null,
    line1 = '',
    line2 = null,
    city = '',
    postcode = '',
    country = 'GB'
  } = {}) {
    super({ name, line1, line2, city, postcode, country })
  }

  // ----------------------------------------------------

  isComplete(): boolean {
    return this.line1 !== '' &&
           this.city !== '' &&
           this.postcode !== ''
  }

  // ----------------------------------------------------

  getValueForPayload(): IAddressData | IAddressDataWithoutId {
    return this.saved
      ? {
        id: this.id,
        ...addressSafeDataPicker(this)
      }
      : addressSafeDataPicker(this)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'name',
      'line1',
      'line2',
      'city',
      'postcode',
      'country'
    ]
  }

  // -----------------------------------------------------

  toFormattedString(): string {
    const values = [ this.line1, this.line2, this.city, this.postcode, this.country ].filter(val => !!val)
    if (values.length === 0) {
      return ''
    }
    return values
      .reduce((agg, next) => agg + ', ' + next)
  }

  // -----------------------------------------------------

  regionalCopy(): Address {
    return new Address({ country: this.country })
  }
}
