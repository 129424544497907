import { Collection } from '../collection'
import { OnCompanyModel, ApiFieldSpec } from '../model'

import { Document, DocumentCollection } from '../documents/document'
import { OptionExerciseEvent } from './option-exercise-event'
import { OptionGrantEvent } from './option-grant-event'
import { Investor } from '../stock/investor'
import { OptionReturnEvent } from './option-return-event'

// ------------------------------------------------------------

/**
 * @class
 * @extends OnCompanyModel
 * @property {OptionGrantEvent} optionGrant
 * @property {OptionExerciseEvent} [optionExercise]
 * @property {Investor} investor
 * @property {number} count
 * @property {string} certno
 * @property {boolean} active
 * @property {DocumentCollection} documents
 */
export class Option extends OnCompanyModel {
  readonly domain = 'options'

  count: number
  active: boolean
  certno: string | null

  investor: Investor

  //TODO: OR OPTION RETURN ON REMAINDER OPTION
  /**
   * The event that generated this option certificate, which will be
   * either an option grant or an option exercise.
   */
  originatingEvent: OptionGrantEvent | OptionExerciseEvent

  /**
   * The original option grant that this certificate originates from.
   */
  optionGrant: OptionGrantEvent

  /**
   * An option exercise event that has or will exercise some of these
   * options. If that event is closed this option will have `active`
   * set to false to mark that this certificate has been annulled.
   */
  optionExercise: OptionExerciseEvent | null

  optionReturn: OptionReturnEvent | null

  documents = new DocumentCollection()

  // ----------------------------------------------------

  constructor({
    company,
    investor,
    optionGrant,
    optionExercise = null,
    optionReturn = null,
    ...data
  }) {
    super({
      company,
      investor,
      optionGrant,
      optionExercise,
      ...data
    })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'optionGrant', include: 'create' },
      { key: 'investor', include: 'create' },
      'count',
      'certno',
      'active'
    ]
  }

  // ----------------------------------------------------

  get numericCertNo(): string {
    return this.certno.replace(/(\d+)/g, (all, num) => {
      return num.padStart(12, '0')
    })
  }

  // ----------------------------------------------------

  get optionCertificate(): Document {
    return this.documents.getByType('OCER')
  }

  // ----------------------------------------------------

  get shareClass() {
    return this.optionGrant.optionScheme.shareClass
  }

  // ----------------------------------------------------

  get strikePrice() {
    return this.optionGrant.strikePrice
  }

  // ----------------------------------------------------

  get grantedDate() {
    return this.optionGrant.effectiveDate
  }

  // ----------------------------------------------------

  get vestedDate() {
    return this.optionExercise
      ? this.optionExercise.effectiveDate
      : null
  }

  // ----------------------------------------------------

  // get issuedDate() {
  //   return this.optionExercise
  //     ? this.previousOption.vestedDate
  //     : this.grantedDate
  // }
}

// ------------------------------------------------------------

export class OptionCollection extends Collection<Option> {
  getInactiveOptions(): Option[] {
    return this.filter(option => !option.active)
  }

  // ----------------------------------------------------

  get activeOption(): Option | undefined {
    return this.find(option => option.active)
  }

  // ----------------------------------------------------

  get optionCount(): number {
    return this.activeOption
      ? this.activeOption.count
      : 0
  }

  // ----------------------------------------------------

  get optionExercises(): OptionExerciseEvent[] {
    return this.map(val => val.optionExercise)
      .filter(v => !!v)
  }

  // ----------------------------------------------------

  get optionReturns(): OptionReturnEvent[] {
    return this.map(val => val.optionReturn)
      .filter(v => !!v)
  }
}
