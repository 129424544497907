<div fxLayout="column" class="gap-8">
  <span *ngFor="let tier of tiers; first as isFirst; last as isLast;" i18n>
    <ng-container *ngIf="isFirst && !isLast && !hideCalculations">
      {{ tier.amount | slPercent: '1.0-2' }} of the first {{ tier.upTo | abbrCurrency:currency }} raised
    </ng-container>
    <ng-container *ngIf="!isFirst && !isLast">
      + {{ tier.amount | slPercent: '1.0-2' }} of the amount from {{ tier.lowerThreshold | abbrCurrency:currency }} - {{ tier.upTo | abbrCurrency:currency }}
    </ng-container>

    <ng-container *ngIf="isLast && !hideCalculations">
      <ng-container *ngIf="tier.amount > 0">
        { tiers.length, plural, =1 {} other {+ } }{{ tier.amount | slPercent: '1.0-2' }}
        <ng-container *ngIf="tier.lowerThreshold > 0">
        of the amount over {{ tier.lowerThreshold | abbrCurrency:currency }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="tier.amount === 0">
        No additional cost for amounts over {{ tier.lowerThreshold | abbrCurrency:currency }}
      </ng-container>
      <ng-container *ngIf="tier.lowerThreshold === 0">of the amount raised</ng-container>
    </ng-container>
  </span>
  <span *ngIf="minimumPrice > 0" i18n>{{ minimumPrice | slCurrency:currency }} minimum</span>
</div>
