
export interface FixedPricingModel {
  purchasable: boolean
  price: number
}

export interface TieredPricingModel {
  purchasable: boolean
  minPrice: number
  tiers: Tier[]
}

export interface Tier {
  amount: number
  lowerThreshold: number
  upTo?: number
  maxTierValue?: number
}

// -----------------------------------------------------

export type PricingModel = FixedPricingModel | TieredPricingModel

// -----------------------------------------------------

export function isTiered(pricingModel: PricingModel): pricingModel is TieredPricingModel {
  return 'tiers' in pricingModel
}

export function isFixed(pricingModel: PricingModel): pricingModel is FixedPricingModel {
  return 'price' in pricingModel
}
