<div fxLayout="column"
     fxLayoutAlign="start {{align}}"
     [ngClass]="size"
     [class.gap-4]="size === 'small'">

  <div *ngIf="label"
       class="label secondary-text"
       [class.text-bold]="boldLabel"
       [style.color]="boldLabel && '#101010'">
    <span>{{ label }}</span>

    <span *ngIf="colourKey"
          class="colour-key"
          [style.backgroundColor]="colourKey"></span>
  </div>

  <div (click)="onValueClicked($event)"
       class="mat-primary-fg text-truncate pb-8"
       [class.clickable]="clicked.observed">
    <ng-content></ng-content>
  </div>

</div>
