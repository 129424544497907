import { IModelData } from './model.model'
import { CountryCode } from './country.model'

// ----------------------------------------------------------

export interface IAddressDataWithoutId {
  line1: string
  line2: string | null
  city: string
  postcode: string
  country: CountryCode
}

// ----------------------------------------------------------

export interface IAddressData extends IModelData, IAddressDataWithoutId {}

// ----------------------------------------------------------

export interface IGreffeData {
  code: string
  postcode: string
  city: string
}

// ----------------------------------------------------------

export const Greffes: IGreffeData[] = [
  {
    code: '0101',
    postcode: '01011',
    city: 'Bourg-en-Bresse',
  },
  {
    code: '0203',
    postcode: '02200',
    city: 'Soissons',
  },
  {
    code: '0202',
    postcode: '02322',
    city: 'Saint-Quentin',
  },
  {
    code: '0303',
    postcode: '03100',
    city: 'Montluçon',
  },
  {
    code: '0301',
    postcode: '03306',
    city: 'Cusset',
  },
  {
    code: '0401',
    postcode: '04100',
    city: 'Manosque',
  },
  {
    code: '0501',
    postcode: '05000',
    city: 'Gap',
  },
  {
    code: '0605',
    postcode: '06009',
    city: 'Nice',
  },
  {
    code: '0603',
    postcode: '06133',
    city: 'Grasse',
  },
  {
    code: '0602',
    postcode: '06400',
    city: 'Cannes',
  },
  {
    code: '0601',
    postcode: '06632',
    city: 'Antibes',
  },
  {
    code: '0702',
    postcode: '07200',
    city: 'Aubenas',
  },
  {
    code: '0802',
    postcode: '08202',
    city: 'Sedan',
  },
  {
    code: '0951',
    postcode: '09008',
    city: 'Foix',
  },
  {
    code: '1001',
    postcode: '10000',
    city: 'Troyes',
  },
  {
    code: '1104',
    postcode: '11108',
    city: 'Narbonne',
  },
  {
    code: '1101',
    postcode: '11890',
    city: 'Carcassonne',
  },
  {
    code: '1203',
    postcode: '12000',
    city: 'Rodez',
  },
  {
    code: '1305',
    postcode: '13150',
    city: 'Tarascon',
  },
  {
    code: '1303',
    postcode: '13291',
    city: 'Marseille',
  },
  {
    code: '1301',
    postcode: '13625',
    city: 'Aix-en-Provence',
  },
  {
    code: '1304',
    postcode: '13657',
    city: 'Salon-de-Provence',
  },
  {
    code: '1402',
    postcode: '14037',
    city: 'Caen',
  },
  {
    code: '1407',
    postcode: '14107',
    city: 'Lisieux',
  },
  {
    code: '1501',
    postcode: '15006',
    city: 'Aurillac',
  },
  {
    code: '1601',
    postcode: '16000',
    city: 'Angoulême',
  },
  {
    code: '1704',
    postcode: '17001',
    city: 'La Rochelle',
  },
  {
    code: '1708',
    postcode: '17100',
    city: 'Saintes',
  },
  {
    code: '1801',
    postcode: '18023',
    city: 'Bourges',
  },
  {
    code: '1901',
    postcode: '19100',
    city: 'Brive-la-Gaillarde',
  },
  {
    code: '2002',
    postcode: '20297',
    city: 'Bastia',
  },
  {
    code: '2001',
    postcode: '20700',
    city: 'Ajaccio',
  },
  {
    code: '2104',
    postcode: '21072',
    city: 'Dijon',
  },
  {
    code: '2202',
    postcode: '22021',
    city: 'Saint-Brieuc',
  },
  {
    code: '2301',
    postcode: '23000',
    city: 'Guéret',
  },
  {
    code: '2402',
    postcode: '24009',
    city: 'Périgueux',
  },
  {
    code: '2401',
    postcode: '24100',
    city: 'Bergerac',
  },
  {
    code: '2501',
    postcode: '25042',
    city: 'Besançon',
  },
  {
    code: '2602',
    postcode: '26105',
    city: 'Romans-sur-Isère',
  },
  {
    code: '2702',
    postcode: '27003',
    city: 'Évreux',
  },
  {
    code: '2701',
    postcode: '27305',
    city: 'Bernay',
  },
  {
    code: '2801',
    postcode: '28008',
    city: 'Chartres',
  },
  {
    code: '2903',
    postcode: '29196',
    city: 'Quimper',
  },
  {
    code: '2901',
    postcode: '29219',
    city: 'Brest',
  },
  {
    code: '3003',
    postcode: '30000',
    city: 'Nîmes',
  },
  {
    code: '3102',
    postcode: '31068',
    city: 'Toulouse',
  },
  {
    code: '3201',
    postcode: '32000',
    city: 'Auch',
  },
  {
    code: '3302',
    postcode: '33064',
    city: 'Bordeaux',
  },
  {
    code: '3303',
    postcode: '33504',
    city: 'Libourne',
  },
  {
    code: '3405',
    postcode: '34070',
    city: 'Montpellier',
  },
  {
    code: '3402',
    postcode: '34500',
    city: 'Béziers',
  },
  {
    code: '3501',
    postcode: '35031',
    city: 'Rennes',
  },
  {
    code: '3502',
    postcode: '35400',
    city: 'Saint-Malo',
  },
  {
    code: '3601',
    postcode: '36000',
    city: 'Châteauroux',
  },
  {
    code: '3701',
    postcode: '37041',
    city: 'Tours',
  },
  {
    code: '3801',
    postcode: '38019',
    city: 'Grenoble',
  },
  {
    code: '3802',
    postcode: '38217',
    city: 'Vienne',
  },
  {
    code: '3902',
    postcode: '39004',
    city: 'Lons-le-Saunier',
  },
  {
    code: '4002',
    postcode: '40000',
    city: 'Mont-de-Marsan',
  },
  {
    code: '4001',
    postcode: '40107',
    city: 'Dax',
  },
  {
    code: '4101',
    postcode: '41018',
    city: 'Blois',
  },
  {
    code: '4202',
    postcode: '42006',
    city: 'Saint-Étienne',
  },
  {
    code: '4201',
    postcode: '42333',
    city: 'Roanne',
  },
  {
    code: '4302',
    postcode: '43009',
    city: 'Le Puy',
  },
  {
    code: '4401',
    postcode: '44262',
    city: 'Nantes',
  },
  {
    code: '4402',
    postcode: '44606',
    city: 'Saint-Nazaire',
  },
  {
    code: '4502',
    postcode: '45000',
    city: 'Orléans',
  },
  {
    code: '4601',
    postcode: '46010',
    city: 'Cahors',
  },
  {
    code: '4701',
    postcode: '47000',
    city: 'Agen',
  },
  {
    code: '4801',
    postcode: '48000',
    city: 'Mende',
  },
  {
    code: '4901',
    postcode: '49100',
    city: 'Angers',
  },
  {
    code: '5001',
    postcode: '50100',
    city: 'Cherbourg-en-Cotentin',
  },
  {
    code: '5002',
    postcode: '50208',
    city: 'Coutances',
  },
  {
    code: '5101',
    postcode: '51008',
    city: 'Châlons-en-Champagne',
  },
  {
    code: '5103',
    postcode: '51095',
    city: 'Reims',
  },
  {
    code: '5201',
    postcode: '52000',
    city: 'Chaumont',
  },
  {
    code: '5301',
    postcode: '53004',
    city: 'Laval',
  },
  {
    code: '5402',
    postcode: '54003',
    city: 'Nancy',
  },
  {
    code: '5401',
    postcode: '54153',
    city: 'Briey',
  },
  {
    code: '5501',
    postcode: '55014',
    city: 'Bar-le-Duc',
  },
  {
    code: '5602',
    postcode: '56019',
    city: 'Vannes',
  },
  {
    code: '5601',
    postcode: '56104',
    city: 'Lorient',
  },
  {
    code: '5751',
    postcode: '57036',
    city: 'Metz',
  },
  {
    code: '5753',
    postcode: '57125',
    city: 'Thionville',
  },
  {
    code: '5752',
    postcode: '57216',
    city: 'Sarreguemines',
  },
  {
    code: '5802',
    postcode: '58020',
    city: 'Nevers',
  },
  {
    code: '5906',
    postcode: '59326',
    city: 'Valenciennes',
  },
  {
    code: '5910',
    postcode: '59338',
    city: 'Lille métropole',
  },
  {
    code: '5902',
    postcode: '59376',
    city: 'Dunkerque',
  },
  {
    code: '5952',
    postcode: '59508',
    city: 'Douai',
  },
  {
    code: '6001',
    postcode: '60004',
    city: 'Beauvais',
  },
  {
    code: '6002',
    postcode: '60200',
    city: 'Compiègne',
  },
  {
    code: '6101',
    postcode: '61000',
    city: 'Alençon',
  },
  {
    code: '6201',
    postcode: '62000',
    city: 'Arras',
  },
  {
    code: '6202',
    postcode: '62200',
    city: 'Boulogne-sur-Mer',
  },
  {
    code: '6303',
    postcode: '63000',
    city: 'Clermont-Ferrand',
  },
  {
    code: '6403',
    postcode: '64000',
    city: 'Pau',
  },
  {
    code: '6401',
    postcode: '64181',
    city: 'Bayonne',
  },
  {
    code: '6502',
    postcode: '65013',
    city: 'Tarbes',
  },
  {
    code: '6601',
    postcode: '66834',
    city: 'Perpignan',
  },
  {
    code: '6752',
    postcode: '67070',
    city: 'Strasbourg',
  },
  {
    code: '6751',
    postcode: '67703',
    city: 'Saverne',
  },
  {
    code: '6851',
    postcode: '68027',
    city: 'Colmar',
  },
  {
    code: '6852',
    postcode: '68061',
    city: 'Mulhouse',
  },
  {
    code: '6901',
    postcode: '69433',
    city: 'Lyon',
  },
  {
    code: '6903',
    postcode: '69665',
    city: 'Villefranche-sur-Saône',
  },
  {
    code: '7001',
    postcode: '70007',
    city: 'Vesoul-Gray',
  },
  {
    code: '7106',
    postcode: '71000',
    city: 'Mâcon',
  },
  {
    code: '7102',
    postcode: '71100',
    city: 'Chalon-sur-Saône',
  },
  {
    code: '7202',
    postcode: '72014',
    city: 'Le Mans',
  },
  {
    code: '7301',
    postcode: '73008',
    city: 'Chambéry',
  },
  {
    code: '7401',
    postcode: '74007',
    city: 'Annecy',
  },
  {
    code: '7402',
    postcode: '74200',
    city: 'Thonon-les-Bains',
  },
  {
    code: '7501',
    postcode: '75181',
    city: 'Paris',
  },
  {
    code: '7608',
    postcode: '76000',
    city: 'Rouen',
  },
  {
    code: '7601',
    postcode: '76204',
    city: 'Dieppe',
  },
  {
    code: '7606',
    postcode: '76600',
    city: 'Le Havre',
  },
  {
    code: '7702',
    postcode: '77000',
    city: 'Melun',
  },
  {
    code: '7701',
    postcode: '77100',
    city: 'Meaux',
  },
  {
    code: '7803',
    postcode: '78011',
    city: 'Versailles',
  },
  {
    code: '7901',
    postcode: '79028',
    city: 'Niort',
  },
  {
    code: '8002',
    postcode: '80002',
    city: 'Amiens',
  },
  {
    code: '8101',
    postcode: '81005',
    city: 'Albi',
  },
  {
    code: '8102',
    postcode: '81100',
    city: 'Castres',
  },
  {
    code: '8201',
    postcode: '82000',
    city: 'Montauban',
  },
  {
    code: '8302',
    postcode: '83007',
    city: 'Draguignan',
  },
  {
    code: '8305',
    postcode: '83041',
    city: 'Toulon',
  },
  {
    code: '8303',
    postcode: '83601',
    city: 'Fréjus',
  },
  {
    code: '8401',
    postcode: '84097',
    city: 'Avignon',
  },
  {
    code: '8501',
    postcode: '85017',
    city: 'La Roche-sur-Yon',
  },
  {
    code: '8602',
    postcode: '86000',
    city: 'Poitiers',
  },
  {
    code: '8701',
    postcode: '87000',
    city: 'Limoges',
  },
  {
    code: '8801',
    postcode: '88025',
    city: 'Épinal',
  },
  {
    code: '8901',
    postcode: '89011',
    city: 'Auxerre',
  },
  {
    code: '8903',
    postcode: '89108',
    city: 'Sens',
  },
  {
    code: '9001',
    postcode: '90020',
    city: 'Belfort',
  },
  {
    code: '7801',
    postcode: '91011',
    city: 'Évry',
  },
  {
    code: '9201',
    postcode: '92020',
    city: 'Nanterre',
  },
  {
    code: '9301',
    postcode: '93008',
    city: 'Bobigny',
  },
  {
    code: '9401',
    postcode: '94049',
    city: 'Créteil',
  },
  {
    code: '7802',
    postcode: '95300',
    city: 'Pontoise',
  },
  {
    code: '9711',
    postcode: '97100',
    city: 'Basse-Terre',
  },
  {
    code: '9712',
    postcode: '97110',
    city: 'Pointe-à-Pitre',
  },
  {
    code: '9721',
    postcode: '97200',
    city: 'Fort-de-France',
  },
  {
    code: '9731',
    postcode: '97300',
    city: 'Cayenne',
  },
  {
    code: '9741',
    postcode: '97494',
    city: 'Saint-Denis, Réunion',
  },
  {
    code: '9742',
    postcode: '97448',
    city: 'Saint-Pierre, Réunion',
  },
  {
    code: '9751',
    postcode: '97500',
    city: 'Saint-Pierre-et-Miquelon',
  },
  {
    code: '9761',
    postcode: '97600',
    city: 'Mamoudzou',
  },
  {
    code: '9861',
    postcode: '98600',
    city: 'Mata-Utu',
  },
  {
    code: '9871',
    postcode: '98714',
    city: 'Papeete',
  },
  {
    code: '9881',
    postcode: '98848',
    city: 'Nouméa',
  }
]
