import { Component, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-action-with-search-bar',
  templateUrl: './action-with-search-bar.component.html',
  styleUrls: [ './action-with-search-bar.component.scss' ]
})
export class ActionWithSearchBarComponent {
  @Input() searchLabel = $localize`Search...`
  @Input() debounceInterval = 400

  searchCtrl = new UntypedFormControl('')

  @Output() searchChanged$: Observable<string> = this.searchCtrl.valueChanges.pipe(
    debounceTime(this.debounceInterval),
    distinctUntilChanged(),
    untilDestroyed(this)
  )

  // ----------------------------------------------------

  public clearSearch() {
    this.searchCtrl.setValue('')
  }

}
