import { createFeatureSelector, createSelector } from '@ngrx/store'

import { LAYOUT_FEATURE_KEY, LayoutState } from './layout.reducer'

// ----------------------------------------------------------

export const getLayoutState = createFeatureSelector<LayoutState>(LAYOUT_FEATURE_KEY)

export const getMainNavOpen = createSelector(
  getLayoutState,
  state => state.mainNavOpen
)
export const getMainNavCollapsible = createSelector(
  getLayoutState,
  state => state.collapsibleMainNav
)
export const getSideNavOpen = createSelector(
  getLayoutState,
  state => state.sideNavOpen
)
export const getLeftNavPanelOpen = createSelector(
  getLayoutState,
  state => state.lefNavPanelOpen
)

export const getIsNavigating = createSelector(
  getLayoutState,
  state => state.navigating
)

export const getCurrentCompanyId = createSelector(
  getLayoutState,
  state => state.currentCompanyId
)

export const getBreadcrumbs = createSelector(
  getLayoutState,
  state => state.breadcrumbs
)

export const getTabLinks = createSelector(
  getLayoutState,
  state => state.tabLinks
)

// ----------------------------------------------------------

export const layoutQuery = {
  getLayoutState,

  getMainNavOpen,
  getMainNavCollapsible,
  getSideNavOpen,
  getLeftNavPanelOpen,

  getIsNavigating,

  getCurrentCompanyId,
  getBreadcrumbs,
  getTabLinks
}
