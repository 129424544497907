import { createFeatureSelector, createSelector } from '@ngrx/store'

import { COMPANIES_FEATURE_KEY, CompaniesState } from './company.reducer'

// ----------------------------------------------------

// Lookup the 'company' feature state managed by NgRx
const getCompaniesState = createFeatureSelector<CompaniesState>(COMPANIES_FEATURE_KEY)

export const getCompaniesLoading = createSelector(
  getCompaniesState,
  (state: CompaniesState) => state.loading
)

export const getCompaniesLoaded = createSelector(
  getCompaniesState,
  (state: CompaniesState) => state.loaded
)

// ----------------------------------------------------

export const getCompanyInvoicesLoading = createSelector(
  getCompaniesState,
  (state: CompaniesState) => state.loading
)

export const getCompanyInvoices = createSelector(
  getCompaniesState,
  (state: CompaniesState) => state.invoices
)

// ----------------------------------------------------

export const companiesQuery = {
  getCompaniesLoading,
  getCompaniesLoaded,
  getCompanyInvoicesLoading,
  getCompanyInvoices
}
