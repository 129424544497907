import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { mergeDeepRight } from 'ramda'

import { environment } from '@env/environment'

// ----------------------------------------------------------

const ELEMENTS_DEFAULT_OPTIONS: stripe.elements.ElementsOptions = {
  style: {
    base: {
      fontFamily: 'Roboto, "Open Sans", "Helvetica Neue", Arial, sans-serif'
    }
  }
}

// ----------------------------------------------------------

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripePromise: Promise<stripe.Stripe>

  private elementsPromise: Promise<stripe.elements.Elements>

  // ----------------------------------------------------

  /**
   * Get Stripe API object, will load it if required.
   */
  getStripe(): Promise<stripe.Stripe> {
    if (!this.stripePromise) {
      this.stripePromise = new Promise<stripe.Stripe>((resolve, reject) => {
        const script = Object.assign(this.document.createElement('script'), {
          src: 'https://js.stripe.com/v3/',
          onerror: err => reject(err),
          onload: () => {
            const stripe = Stripe(environment.STRIPE_API_KEY)

            resolve(stripe)
          }
        })

        this.document.body.appendChild(script)
      })
    }

    return this.stripePromise
  }

  // ----------------------------------------------------

  /**
   * Get Stripe Elements object, will wait for the Stripe library to load.
   */
  getElements(): Promise<stripe.elements.Elements> {
    if (!this.elementsPromise) {
      this.elementsPromise = this.getStripe().then(stripe => stripe.elements())
    }

    return this.elementsPromise
  }

  // ----------------------------------------------------

  async createElement(
    type: stripe.elements.elementsType,
    options: stripe.elements.ElementsOptions = {}
  ): Promise<stripe.elements.Element> {
    const elements = await this.getElements()
    // TODO
    // Types of property 'supportedCountries' are incompatible.
    // Type '___MergeUpDeep<{ [x: number]: string; }, { [x: number]: string; }, 1, number>' is missing the following properties from type 'string[]': length, pop, push, concat, and 26 more
    return elements.create(type, mergeDeepRight(ELEMENTS_DEFAULT_OPTIONS, options) as stripe.elements.ElementsOptions)
  }

  // ----------------------------------------------------

  async createAndMountElement(
    selector: string | HTMLElement,
    type: stripe.elements.elementsType,
    options: stripe.elements.ElementsOptions = {}
  ): Promise<stripe.elements.Element> {
    const element = await this.createElement(type, options)

    element.mount(selector)

    return element
  }

  // ----------------------------------------------------

  private handlePaymentIntentResponse(
    response: stripe.PaymentIntentResponse
  ): stripe.paymentIntents.PaymentIntent {
    if (response.error) {
      throw response.error
    } else {
      return response.paymentIntent
    }
  }

  // ----------------------------------------------------

  async retrievePaymentIntent(
    clientSecret: string
  ): Promise<stripe.paymentIntents.PaymentIntent> {
    const stripe = await this.getStripe()

    const response = await stripe.retrievePaymentIntent(clientSecret)

    return this.handlePaymentIntentResponse(response)
  }

  // ----------------------------------------------------

  private handleSetupIntentResponse(response: stripe.SetupIntentResponse): stripe.setupIntents.SetupIntent {
    if (response.error) {
      throw response.error
    } else {
      return response.setupIntent
    }
  }

  // ----------------------------------------------------

  async confirmCardSetup(
    clientSecret: string,
    data?: stripe.ConfirmCardSetupData,
    options?: stripe.ConfirmCardSetupOptions,
  ): Promise<stripe.setupIntents.SetupIntent> {
    const stripe = await this.getStripe()

    const response = await stripe.confirmCardSetup(clientSecret, data, options)

    return this.handleSetupIntentResponse(response)
  }

  // ----------------------------------------------------

  async confirmCardPayment(
    clientSecret: string,
    data?: stripe.ConfirmCardPaymentData,
    options?: stripe.ConfirmCardPaymentOptions,
  ): Promise<stripe.paymentIntents.PaymentIntent> {
    const stripe = await this.getStripe()

    const response = await stripe.confirmCardPayment(clientSecret, data, options)

    return this.handlePaymentIntentResponse(response)
  }

  // ----------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}
}
