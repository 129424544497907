import { Directive, Input, AfterViewChecked, Inject, OnDestroy } from '@angular/core'
import { DOCUMENT } from '@angular/common'

// ------------------------------------------------------------

const className = 'sl-offset-widget'

// ------------------------------------------------------------

@Directive({
  selector: '[slOffsetWidget]'
})
export class OffsetWidgetDirective implements AfterViewChecked, OnDestroy {
  private isEnabled = true

  @Input()
  set slOffsetWidget(value: boolean | undefined | '') {
    this.isEnabled = value !== false
  }

  // ----------------------------------------------------

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  // ----------------------------------------------------

  ngAfterViewChecked() {
    if (!this.isEnabled) {
      return
    }

    this.document.body.classList.add(className)
  }

  // ----------------------------------------------------

  ngOnDestroy() {
    this.document.body.classList.remove(className)
  }
}
