<div *ngFor="let item of entities"
     (click)="onEntitySelected(item)"
     class="entity-row gap-16"
     fxLayout="row"
     fxLayoutAlign="start stretch">

  <div fxLayout="column"
       fxLayoutAlign="center start">
    <sl-avatar [entity]="item.entity"></sl-avatar>
  </div>

  <div fxFlex
       class="bb"
       fxLayout="column"
       fxLayoutAlign="center start">
    <div class="entity-name">{{ item.entity.name }}</div>

    <div *ngIf="isUserEntity(item.entity)"
         class="entity-email">{{ $any(item.entity).email }}</div>
  </div>
</div>
