import { EventCategory } from '../../models/category.model'
import { ApiFieldSpec } from '../model'
import { Event } from './event'
import { Share } from '../stock/share'
import { Investor } from '../stock/investor'

// ------------------------------------------------------------

export class ShareTransferEvent extends Event {
  readonly domain = 'shareTransfers'
  readonly category = EventCategory.ShareTransfer

  count: number
  pricePerShare: number

  investor: Investor

  /**
   * Share certificate from which the source investor is transferring some
   * or all shares. Will be annulled once the transfer is closed.
   */
  sourceShare: Share

  /**
   * Once the transfer is closed, if not all shares were transferred this will
   * be a new certificate for the source investor's remaining shares.
   */
  remainderShare: Share | null = null

  /**
   * Once the transfer is closed, the new share certificate for the transferred
   * shares owned by the target investor.
   */
  share: Share | null = null

  // ----------------------------------------------------

  constructor({
    company,
    investor,
    sourceShare = null,
    count = 1,
    pricePerShare = 0,
    documents = null,
    ...data
  }) {
    super({
      company,
      investor,
      sourceShare,
      count,
      pricePerShare,
      ...data
    })

    this.name = $localize`Share transfer`
  }

  // ----------------------------------------------------

  override get scopeField() {
    return 'shareTransfer'
  }

  // ----------------------------------------------------

  override get safeName() {
    return this.sourceInvestor && this.targetInvestor
      ? `${this.sourceInvestor.name} ⇾ ${this.targetInvestor.name}`
      : $localize`Share transfer`
  }

  // ----------------------------------------------------

  get sourceInvestor() {
    return this.sourceShare && this.sourceShare.investor
  }

  // ----------------------------------------------------

  get targetInvestor() {
    return this.investor
  }

  // ----------------------------------------------------

  get hasTransferredAllShares() {
    return this.sourceShare
      ? this.count === this.sourceShare.count
      : false
  }

  // ----------------------------------------------------

  get amount() {
    return Math.ceil(this.count * this.pricePerShare * 100) / 100
  }

  // ----------------------------------------------------

  /**
   * According to https://www.gov.uk/tax-buy-shares/use-a-stock-transfer-form, stamp duty is needed above £1,000
   * @returns {boolean} Whether the transfer requires a stamp duty or not, ie. above £1,000
   */
  get needsStampDuty() {
    return this.amount > 1000
  }

  // ----------------------------------------------------

  /**
   * Calculates stamp duty of the Share transfer, based on https://www.gov.uk/tax-buy-shares/use-a-stock-transfer-form
   * @returns {number} Amount (in £), 0.5% of the transfer amont, rounded up to nearest £5
   */
  get stampDutyAmount() {
    return this.needsStampDuty ? 5 * Math.ceil(this.amount * 0.001) : 0
  }

  // ----------------------------------------------------

  /**
   * Used for when deleting share transfers, to warn users when transfers cannot be deleted.
   * @returns {boolean}, if the share or remainder share are used as the source share in another transfer.
   */
  get reliedOnBySubsequentTransfers() {
    return !!this.company.shareTransfers.find(s => s.sourceShare === this.share || s.sourceShare === this.remainderShare)
  }

  // ----------------------------------------------------

  get remainingShareCount() {
    return this.sourceShare
      ? this.sourceShare.count - this.count
      : 0
  }

  // ----------------------------------------------------

  get shareClass() {
    return this.sourceShare && this.sourceShare.shareClass
  }

  // ----------------------------------------------------

  getShareholderNamesAfterTransfer() {
    return this.hasTransferredAllShares
      ? this.targetInvestor.name
      : this.targetInvestor.name + ' and ' + this.sourceInvestor.name
  }

  // ----------------------------------------------------

  override getSharesIssuedFromEvent() {
    if (!this.closed || !this.share) {
      return []
    }

    return !this.hasTransferredAllShares && this.remainderShare
      ? [ this.share, this.remainderShare ]
      : [ this.share ]
  }

  // ----------------------------------------------------

  getSourceInvestment() {
    if (this.sourceShare._investment) {
      return this.sourceShare.investment
    }

    if (this.sourceShare.shareTransfer) {
      const transfer = this.sourceShare.shareTransfer

      return transfer.getSourceInvestment() * this.sourceShare.count / transfer.sourceShare.count
    }

    return 0
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'sourceShare', include: 'create' },
      { key: 'investor', include: 'create' },
      'count',
      'pricePerShare'
    ]
  }

  // ----------------------------------------------------

  getViewState() {
    return {
      state: [ '/companies', this.company.id, 'captable', 'share-transfers', this.id ]
    }
  }

  // ----------------------------------------------------

  override  get timelineAmount(): number {
    return this.amount
  }

  // ----------------------------------------------------

  get priorApprovalNeeded(): boolean {
    return this.answers[ 'restrictions' ]?.[ 'approval' ]
  }
}
