import { isBefore, parseISO } from 'date-fns'

// ------------------------------------------------------------

export const EVENT_LEGACY_DATES = {
  ComplianceAddedUirs: '2019-01-01',
  ComplianceCanCheckTradingRequirements: '2023-03-01',
  ProposalsOnlyGetLatestVersion: '2019-09-13',
} as const

// ------------------------------------------------------------

export const isBeforeLegacyDate = (
  event: { effectiveDate: string },
  legacyDate: string,
) => isBefore(parseISO(event.effectiveDate), parseISO(legacyDate))

export const isAfterLegacyDate = (
  event: { effectiveDate: string },
  legacyDate: string,
) => !isBeforeLegacyDate(event, legacyDate)
