<a [routerLink]="route"
   routerLinkActive="active"
   [routerLinkActiveOptions]="{ 'exact': exact }"
   mat-list-item>
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       style="width: 100%">
    <div fxLayout="row"
         fxLayoutAlign="start center"
         class="gap-16">
      <img src="/assets/images/app/left-nav/{{ image }}.svg" class="p-8" alt="Page link" i18n-alt>
      <div>{{ label }}</div>
    </div>

    <ng-content></ng-content>
  </div>
</a>
