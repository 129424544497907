import { Component, Input, ChangeDetectionStrategy } from '@angular/core'
import { trigger, transition, style, animate } from '@angular/animations'

// ----------------------------------------------------------

@Component({
  selector: 'sl-loading-wrapper',
  template: `
    <div *ngIf="loading; else contentBlock"
         @showHideGhost
         fxLayout="row"
         fxLayoutAlign="center center">
      <mat-spinner class="m-16"
                   [diameter]="30"></mat-spinner>
    </div>

    <ng-template #contentBlock>
      <div @showContent>
        <ng-content></ng-content>
      </div>
    </ng-template>
  `,
  animations: [
    trigger('showHideGhost', [
      transition(':leave', [
        animate('0.4s', style({ opacity: 0 }))
      ])
    ]),
    trigger('showContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s', style({ opacity: 0 })),
        animate('0.5s', style({ opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingWrapperComponent {
  @Input() loading = true
}
