import { Component, Inject, type OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, type UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { take } from 'rxjs/operators'

import type { Company, Entity, EntityType, Region, User } from '@libs/models'
import { Appointment, Sex } from '@libs/models'

import type { CompanyMatch } from '@app/entities/models/company-match'
import { EntityDialogData } from '@app/entities/models/entity-edit-dialog'

import { UserService } from '@app/users/services/user.service'
import { CompanyService } from '@app/companies/services/company/company.service'
import { LayoutFacade } from '@app/layout/+state/layout.facade'
import { GlobalsService } from '@app/core/services/globals/globals.service'
import { ToastService } from '@libs/services'

import { BackendService, RestApi } from '@libs/backend'
import { SimpleDialogWrapperComponent } from '@libs/modals'
import { Configuration } from '@app/core/services/configuration.service'

// ----------------------------------------------------

const APPOINTMENT_ROLE_PROPS: string[] = [ 'admin', 'director', 'foundingEmployee', 'familyFounder', 'founder', 'read', 'signatory' ]

// ----------------------------------------------------

@Component({
  selector: 'sl-entity-edit-dialog',
  templateUrl: './entity-edit-dialog.component.html',
  styleUrls: [ './entity-edit-dialog.component.scss' ]
})
export class EntityEditDialogComponent implements OnInit {
  entity: Entity | null = null
  originalEntity: Entity | null = null

  user: User | null = null
  originalUser: User | null = null

  appointment: Appointment | null = null

  company: Company | null = null
  originalCompany: Company | null = null

  currentUser: User
  currentCompany: Company
  currentCompanyRegion: Region

  entityType?: EntityType
  isEditing = false
  loading: boolean

  view: EntityType = 'user'

  companyMatch: CompanyMatch | null = null

  previousEmail: string
  matchedUser = false
  message = ''

  // ----------------------------------------------------

  @ViewChild(SimpleDialogWrapperComponent) dialogWrapper: SimpleDialogWrapperComponent

  form: UntypedFormGroup = this.fb.group({})

  userForm = this.fb.group({
    email: [ this.data.prefillEmail || null ],
    firstName: [ null, Validators.required ],
    lastName: [ null, Validators.required ],
    address: [ null ]
  })

  appointmentForm = this.fb.group({
    position: [ '' ],
    admin: [ false ],
    director: [ false ],
    founder: [ false ],
    foundingEmployee: [ false ],
    familyFounder: [ false ],
    read: [ false ],
    signatory: [ false ],
  })

  // ----------------------------------------------------

  constructor(
    public dialogRef: MatDialogRef<EntityEditDialogComponent>,
    private fb: UntypedFormBuilder,
    private globalsService: GlobalsService,
    private companyService: CompanyService,
    private userService: UserService,
    private layout: LayoutFacade,
    private toastService: ToastService,
    private configuration: Configuration,
    @Inject(RestApi) private restApi: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: EntityDialogData
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    // _log(`EntityEditDialogComponent.ngOnInit(): data, userService.currentUser`, this.data, this.userService.currentUser, this)

    this.currentUser = this.userService.currentUser

    this.originalEntity = this.data.entity
    this.entityType = this.data.entityType

    this.layout.currentCompanyId$.pipe(
      take(1)
    ).subscribe(currentCompanyId => {
      this.currentCompany = this.globalsService.companies.get(currentCompanyId)

      const { jurisdiction } = this.currentCompany
      this.currentCompanyRegion = this.configuration.getRegionByJurisdiction(
        jurisdiction
      ).id

      if (this.data.mode === 'edit' && this.data.entity) {
        this.isEditing = true

        this.view = this.data.entity.entityType || 'user'

        if (this.data.entity.entityType === 'company') {
          this.originalCompany = this.data.entity as Company

          this.updateCompanyEntity(this.originalCompany)
        }

        if (this.data.entity.entityType === 'user') {
          this.originalUser = this.data.entity as User

          this.updateUserEntity(this.originalUser)
        }
      } else {
        this.view = this.data.entityType || 'user'
      }

      this.setView(this.view)
    })
  }

  // ----------------------------------------------------

  updateAppointment(appointment: Appointment | null) {
    // _log(`EntityEditDialogComponent.updateAppointment(appointment): appointmentForm`, appointment, this.appointmentForm)

    this.appointment = appointment

    if (appointment) {
      this.appointmentForm.patchValue({
        position: appointment.position,
        admin: appointment.admin,
        director: appointment.director,
        founder: appointment.founder,
        foundingEmployee: appointment.foundingEmployee,
        familyFounder: appointment.familyFounder,
        read: appointment.read,
        signatory: appointment.signatory
      })
    } else {
      this.appointmentForm.patchValue({
        position: '',
        admin: false,
        director: false,
        founder: false,
        foundingEmployee: false,
        familyFounder: false,
        read: false,
        signatory: false
      })
    }
  }

  // ----------------------------------------------------

  async updateUserEntity(user: User | null) {
    // _log(`EntityEditDialogComponent.updateUserEntity(user): userForm`, user, this.userForm)

    this.user = user

    if (user) {
      this.userForm.patchValue({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        address: user.address
      })

      const appointment = this.currentCompany.appointments.findAppointmentForUser(user)

      this.updateAppointment(appointment)

      this.previousEmail = this.user.email
    } else {
      this.userForm.patchValue({
        email: '',
        firstName: '',
        lastName: '',
        address: null
      })

      this.updateAppointment(null)

      this.previousEmail = ''
    }
  }

  // ----------------------------------------------------

  async updateCompanyEntity(company: Company | null) {
    // _log(`EntityEditDialogComponent.updateCompanyEntity(company)`, company)

    this.company = company
  }

  // ----------------------------------------------------

  setView(value: EntityType) {
    this.view = value

    if (this.view === 'user') {
      this.form.addControl('user', this.userForm)
      this.form.addControl('appointment', this.appointmentForm)
    } else {
      this.form.removeControl('user')
      this.form.removeControl('appointment')
    }
  }

  // ----------------------------------------------------

  get title(): string {
    if (this.data.title) {
      return this.data.title
    }

    const action = this.isEditing ? $localize`Edit` : $localize`Add new`
    return action + ' ' + this.view
  }

  // ----------------------------------------------------

  async searchUser() {
    const currentEmail = this.userForm.value.email

    if (currentEmail === this.previousEmail) {
      return
    }

    const matchedUser = await this.userService
      .findUserByEmail(currentEmail)

    if (matchedUser) {
      this.updateUserEntity(matchedUser)

      this.matchedUser = true
    } else {
      this.matchedUser = false
      this.message = ''
    }
  }

  // ----------------------------------------------------

  async createUserAndAppointment() {
    const userData = this.userForm.value

    this.user = await this.userService.createUser({
      ...userData,
      sex: Sex.NotKnown
    })

    if (!this.user) {
      this.dialogWrapper.setErrorMessage($localize`Error creating user`)
      return
    }

    const appointmentData = this.appointmentForm.value

    const createAppointment = this.data.autoCreateAppointment ||
      [ 'position', ...APPOINTMENT_ROLE_PROPS ].some(f => !!appointmentData[ f ])

    if (createAppointment) {
      try {
        this.appointment = new Appointment({
          company: this.currentCompany,
          user: this.user,
          ...appointmentData
        })

        await this.appointment.save(this.restApi)

        this.globalsService.appointments.set(this.appointment.id, this.appointment)
        this.appointment.attach()
      } catch (ex) {
        this.dialogWrapper.setErrorMessage($localize`Error creating appointment`)
        return
      }
    }

    this.dialogRef.close(this.user)
  }

  // ----------------------------------------------------

  async patchUserAndAppointment() {
    const userData = this.userForm.value

    if (this.userForm.dirty && !this.user.connected) {
      const result = await this.userService.patchUser(this.user, userData)

      if (!result) {
        this.dialogWrapper.setErrorMessage($localize`Error updating user`)
        return
      }
    }

    const appointmentData = this.appointmentForm.value
    const createAppointment = this.data.autoCreateAppointment ||
      [ 'position', ...APPOINTMENT_ROLE_PROPS ].some(f => !!appointmentData[ f ])

    if (this.appointment) {
      if (this.appointmentForm.dirty) {
        try {
          Object.assign(this.appointment, appointmentData)

          await this.appointment.save(this.restApi)
        } catch (ex) {
          this.dialogWrapper.setErrorMessage($localize`Error updating appointment`)
          return
        }
      }
    } else {
      if (createAppointment) {
        try {
          this.appointment = new Appointment({
            company: this.currentCompany,
            user: this.user,
            ...appointmentData
          })

          await this.appointment.save(this.restApi)

          this.globalsService.appointments.set(this.appointment.id, this.appointment)
          this.appointment.attach()
        } catch (ex) {
          this.dialogWrapper.setErrorMessage($localize`Error creating appointment`)
          return
        }
      }
    }

    this.dialogRef.close(this.user)
  }

  // ----------------------------------------------------

  /**
   * Handle various cases...
   *
   *   1.  Creating a new user, either no email address was entered,
   *       or no matching address was found. initiallyEditing and
   *       matchedData all null, use modelData.user and appData to create a
   *       new user and appointment and return them.
   *
   *   2.  Creating a new user, email entered and matching an existing
   *       user's email address. initiallyEditing is empty, matchedData
   *       contain the matched user and appointment with the current
   *       company if found. Patch matchedData from modelData.user/appData, then
   *       return matchedData as user and app.
   *
   *   3.  Editing an existing user so initiallyEditing is set. Either
   *       the email address was unchanged or no match was found for
   *       the new value. Use modelData.user and appData to patch user and
   *       app from initiallyEditing, then return them to the user.
   *
   *   4.  Editing an existing user who has changed their email and it
   *       matches an existing user - initiallyEditing is set and matchedData
   *       is also set. Call the merge user endpoint to merge
   *       initiallyEditing.user into matchedData.user - this removes the
   *       user we started editing completely, replacing all references to
   *       it with the matched user.
   */
  private async processUserView() {
    this.dialogWrapper.clearErrorMessage()

    if (this.isEditing) {
      if (this.matchedUser) {
        // Case #4 -  this forces a full page reload rather than try and handle
        // replacing all references to originalUser with user.
        const err = await this.userService.mergeUsers(this.originalUser, this.user)

        if (err) {
          this.dialogWrapper.setErrorMessage($localize`Error updating user`)
          return
        }

        this.dialogRef.close()
      } else {
        // Case #3
        await this.patchUserAndAppointment()
      }
    } else {
      if (this.matchedUser) {
        // Case #2
        await this.patchUserAndAppointment()
      } else {
        // Case #1
        await this.createUserAndAppointment()
      }
    }
  }

  // ----------------------------------------------------

  /**
   * @depricated = not used anywhere, TODO: Remove?
   * Handle various cases...
   *
   *   1.  Creating a new company, where the name isn't already taken
   *       and no company is selected in the dropdown. Use companyData
   *       to do a POST to the API to create a new company and return
   *       it on closing the dialog.
   *
   *   2.  Creating a new company, the name matches an existing company, or
   *       the user selected a company in the dropdwon - just returm the
   *       existing company when the dialog closes.
   *
   *   3.  Editing an existing company is not at all planned or possible to
   *       do here, so just return the company in initialData, yay!
   */
  async processCompanyView() {
    if (!this.companyMatch) {
      return
    }

    let company: Company

    switch (this.companyMatch.source) {
      case 'local':
        company = this.companyMatch.company
        break

      case 'remote':
        try {
          company = await this.companyService.createRegisteredCompany(
            this.companyMatch.companyData,
            this.currentUser
          )
        } catch {
          this.toastService.error($localize`Cannot create company. Please contact us.`)
          return
        }
        break

      case 'new': {
        const creatorCompany: Company = this.data.company
        company = await this.companyService.createNewCompany(
          {
            name: this.companyMatch.name,
            // jurisdiction: creatorCompany.jurisdiction,
            address: creatorCompany.address ? creatorCompany.address.regionalCopy() : null,
            type: creatorCompany.type,
            currency: creatorCompany.currency
          },
          this.currentUser
        )
        break
      }
    }

    this.dialogRef.close(company)
  }

  // ----------------------------------------------------

  onSaveClicked() {
    this.loading = true
    if (this.view === 'user') {
      this.processUserView()
    } else {
      this.processCompanyView()
    }
    this.loading = false
  }

}
