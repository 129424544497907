import { OptionSchemeMarketingData, OptionSchemeType, Region } from '@libs/models'
import type { BundledProduct, CurrencyCode, Event, Fee, IProductHowMuchData, Price, ProductBundleId, ProductId } from '@libs/models'

// ------------------------------------------------------------

export interface IBundleWithProductsData {
  isBundle: true
  id: ProductBundleId
  name: string
  currency: CurrencyCode
  fullPrice: Price
  price: Fee
  savings: Price
  products: BundledProduct[]
  benefitDetails: IBundlesBenefitDetails
}

export interface IProductOnlyData {
  isBundle: false
  id: ProductId
  name: string
  currency: CurrencyCode
  price: Fee
  products: BundledProduct[]
}

export type IProductBundleData = IProductOnlyData | IBundleWithProductsData

// ------------------------------------------------------------

export interface IBundlesBenefitDetails {
  name: string
  benefits: string[]
}

// ------------------------------------------------------------

export interface IBundleSidenavData {
  howMuch: IProductHowMuchData
  event: Event
}

// ------------------------------------------------------------

export const BundleProductMarketingFeatures = {
  ADVANCE_ASSURANCE: [
    $localize`Step-by-step guide and expert help`,
    $localize`Upload your pitch deck & business plan`,
    $localize`Our experts will review everything`,
    $localize`We'll submit it to HMRC for approval`,
    $localize`We'll let you know when it's approved`,
  ],
  SEED: [
    $localize`Term Sheet`,
    $localize`Previous Investor Consent`,
    $localize`Preemption Notice`,
    $localize`Shareholders Agreement`,
    $localize`Articles of Association`,
    $localize`Disclosure Letter`,
    $localize`Board Resolution`,
    $localize`Shareholders Resolution`,
    $localize`SH01 Form`
  ],
  OPTION_SCHEME: OptionSchemeMarketingData[ Region.Commonwealth ][ OptionSchemeType.EMI ].marketingFeatures,
  OPTION_SCHEME_UNAPPROVED: OptionSchemeMarketingData[ Region.Commonwealth ][ OptionSchemeType.Unapproved ].marketingFeatures
} as const

// ------------------------------------------------------------

interface IProductBundlesMetadata {
  name: string
  benefitDetails: IBundlesBenefitDetails
}

export const productBundlesMetadata: Record<ProductBundleId, IProductBundlesMetadata> = {
  OPTION_SCHEME_AND_EMI_VALUATION_AND_UNAPPROVED_SCHEME: {
    name: $localize`EMI Scheme + EMI Valuation + Unapproved Scheme`,
    benefitDetails: {
      name: $localize`80% of companies buy our EMI Scheme, EMI Valuation and Unapproved Scheme together`,
      benefits: [
        $localize`Buying an EMI Scheme lets you grant EMI options to your employees`,
        $localize`Buying an Unapproved Scheme lets you issue options to contractors, advisors and overseas team members`,
        $localize`The EMI Valuation is needed to get the best possible EMI discount from HMRC, to maximise the value of the EMI options to your employees.`
      ]
    }
  },
  OPTION_SCHEME_AND_EMI_VALUATION: {
    name: $localize`EMI Scheme + EMI Valuation`,
    benefitDetails: {
      name: $localize`90% of companies buy our EMI Scheme and EMI Valuation together`,
      benefits: [
        $localize`Buying an EMI Scheme lets you grant EMI options to your employees`,
        $localize`The EMI Valuation is needed to get the best possible EMI discount from HMRC, to maximise the value of the EMI options to your employees.`
      ]
    }
  },
  OPTION_SCHEME_AND_UNAPPROVED_SCHEME: {
    name: $localize`EMI Scheme + Unapproved Scheme`,
    benefitDetails: {
      name: $localize`80% of companies buy our EMI Scheme and Unapproved Scheme together`,
      benefits: [
        $localize`Buying an EMI Scheme lets you grant EMI options to your employees`,
        $localize`Buying an Unapproved Scheme lets you issue options to contractors, advisors and overseas team members`,
      ]
    }
  },
  BSPCE_AND_BSA_ADVISOR: {
    name: $localize`BSPCE + BSA Advisor Schemes`,
    benefitDetails: {
      name: $localize`80% of companies put in place BSPCE and BSA Advisor together`,
      benefits: [
        $localize`Buying a BSPCE plan lets you give share options to your employees and directors`,
        $localize`Buying a BSA Advisor plan lets you give share options to third parties such as advisors or freelancers`,
      ]
    }
  }
}
