import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { Round, Company, SeedNoteEvent } from '@libs/models'
import { ModalComponent } from '@libs/utils'

// --------------------------------------------------------

export interface SpecialCompletionConditionsData {
  event: Round | SeedNoteEvent
  company: Company
  description: string
}

// --------------------------------------------------------

@Component({
  selector: 'sl-special-completion-conditions-dialog',
  templateUrl: './special-completion-conditions-dialog.component.html',
  styles: [ 'sl-simple-dialog-wrapper { max-width: 800px }' ]
})
export class SpecialCompletionConditionsDialogComponent
  extends ModalComponent<
    SpecialCompletionConditionsDialogComponent,
    SpecialCompletionConditionsData>
  implements OnInit {
  checkboxCtrl: UntypedFormControl

  // ----------------------------------------------------

  ngOnInit() {
    this.checkboxCtrl = new UntypedFormControl(null, Validators.required)
  }

  // ----------------------------------------------------

  constructor(
    fb: UntypedFormBuilder,
    override readonly dialogRef: MatDialogRef<SpecialCompletionConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) override readonly data: SpecialCompletionConditionsData,
  ){
    super(fb, dialogRef, data)
  }

  // ----------------------------------------------------

  okClicked() {
    this.close(true)
  }

}
