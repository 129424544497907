import { Component, Input, ChangeDetectionStrategy } from '@angular/core'

// ----------------------------------------------------------

@Component({
  selector: 'sl-marketing-features',
  templateUrl: './marketing-features.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingFeaturesComponent {
  @Input() header = $localize`Includes`
  @Input() headerType: 'heading' | 'subheading' = 'heading'
  @Input() features: string[] = []
}
