import { NgModule } from '@angular/core'

import { SharedModule } from '@libs/shared'
import { EntitiesComponentsModule } from '@app/entities/entities-components.module'

import { COMPONENTS, EXPORT_COMPONENTS } from './components'
import { DIRECTIVES } from './directives'
import { SearchModule } from '@libs/search'

// ----------------------------------------------------------

@NgModule({
  imports: [
    SharedModule,
    EntitiesComponentsModule,
    SearchModule
  ],
  declarations: [
    ...COMPONENTS,
    ...EXPORT_COMPONENTS,
    ...DIRECTIVES
  ],
  exports: [
    ...EXPORT_COMPONENTS,
    ...DIRECTIVES
  ]
})
export class LayoutComponentsModule {}
