import { ApiFieldSpec, Model } from '../model'
import { Loan } from './loan'

// ---------------------------------------------

export class LoanCertificate extends Model {
  readonly domain = 'loanCertificates'

  loan: Loan
  certno: string
  issued: string

  // ----------------------------------------------------

  override async afterCreate(api, responseData) {
    await super.afterCreate(api, responseData)

    this.certno = responseData.certno
  }

  // --------------------------------------------

  override attach(): void {
    super.attach()

    this.loan.loanCertificate = this
  }

  // --------------------------------------------

  override detach(): void {
    super.detach()

    this.loan.loanCertificate = null
    this.loan = null
    // this.investor = null
  }

  // --------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'loan', include: 'create' },
      { key: 'certno', include: 'update' },
      'issued'
    ]
  }
}
