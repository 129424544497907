import { Directive, Inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'

import { IEnvironmentConfig, Profile } from '@env/models'

import { EnvironmentConfig } from '../tokens'

// ----------------------------------------------------------

@Directive({
  selector: '[slDevOnly]'
})
export class DevOnlyDirective implements OnInit {

  constructor(
    @Inject(EnvironmentConfig) private environment: IEnvironmentConfig,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    if (this.environment.profile === Profile.Dev) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }

}
