import { pick } from 'ramda'

import type { IBankAccountData } from '../../models/bank-account.model'
import type { CountryCode } from '../../models/country.model'

import type { CohortMember } from '../cohort-manager/cohort'
import type { Company } from '../company'
import { ApiFieldSpec, Model } from '../model'
import { getBankAccountFields } from 'libs/bank-account/src/lib/models/account-type'

// ------------------------------------------------------------

export const bankAccountSafeDataPicker = pick([
  'id',
  'country',
  'name',
  'holder',
  'address',
  'sortCode',
  'accountNumber',
  'iban',
  'swiftCode',
  'bankCode',
])

// ------------------------------------------------------------

export abstract class BaseBankAccount extends Model implements IBankAccountData {
  abstract parent: 'company' | 'cohortMember'
  readonly domain = 'bankAccounts'

  country: CountryCode
  name: string
  holder: string
  address: string

  accountNumber: string | null
  sortCode: string | null
  iban: string | null
  swiftCode: string | null
  bankCode: string | null

  // ----------------------------------------------------

  constructor({
    country = 'GB',
    name = '',
    holder = '',
    address = '',
    sortCode = '',
    accountNumber = '',
    iban = '',
    swiftCode = '',
    bankCode = '',
    ...data
  }) {
    super({ country, name, holder, address, accountNumber, sortCode, iban, swiftCode, bankCode, ...data })
  }

  // ----------------------------------------------------

  get isComplete(): boolean {
    const filteredFields = getBankAccountFields(this.country).filter(field => field.required).map(filtered => filtered.field)
    return filteredFields.every(requiredField => !!this[ requiredField ])
  }

  // ----------------------------------------------------

  getValueForPayload() {
    return bankAccountSafeDataPicker(this)
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      'country',
      'name',
      'holder',
      'address',
      'sortCode',
      'accountNumber',
      'iban',
      'swiftCode',
      'bankCode',
    ]
  }

  // -----------------------------------------------------

  toFormattedString(): string {
    return [
      this.name,
      this.holder,
      this.address,
      this.sortCode,
      this.accountNumber,
      this.iban,
      this.swiftCode,
      this.bankCode,
    ].filter(val => !!val).join(', ')
  }

}

// ------------------------------------------------------------

export class CompanyBankAccount extends BaseBankAccount {
  readonly parent = 'company'

  company: Company

  // ----------------------------------------------------

  constructor({
    company,
    ...data
  }) {
    super({ company, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'company', include: 'create' },
    ]
  }

  // ----------------------------------------------------

  override attach() {
    this.company[ this.field ].add(this)
  }

  override detach() {
    this.company[ this.field ].remove(this)
  }
}

// ------------------------------------------------------------

export class CohortMemberBankAccount extends BaseBankAccount {
  readonly parent = 'cohortMember'

  cohortMember: CohortMember

  // ----------------------------------------------------

  constructor({
    cohortMember,
    ...data
  }) {
    super({ cohortMember, ...data })
  }

  // ----------------------------------------------------

  override getApiFields(): ApiFieldSpec[] {
    return [
      ...super.getApiFields(),
      { key: 'cohortMember', include: 'create' },
    ]
  }

}

// ------------------------------------------------------------

export type BankAccount =
  | CompanyBankAccount
  | CohortMemberBankAccount
