import { Component, Inject, OnInit } from '@angular/core'

import { CardWithExpiry } from '../../models/cards'
import { IPaymentSidenavData } from '../../models/sidenav'

import { PaymentsFacade } from '../../+state/payments.facade'

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ModalService } from '@libs/modals'
import { NewCardDialogComponent } from '@app/payments/components/new-card-dialog/new-card-dialog.component'

// ----------------------------------------------------------

@Component({
  selector: 'sl-card-selection-dialog',
  templateUrl: './card-selection-dialog.component.html',
  styleUrls: [ './card-selection-dialog.component.scss' ]
})
export class CardSelectionDialogComponent implements OnInit {

  selected: CardWithExpiry | null = null

  cards$ = this.paymentsFacade.cardsWithExpiry$
  cardsLoading$ = this.paymentsFacade.cardsLoading$

  // ----------------------------------------------------

  ngOnInit(): void {
    this.paymentsFacade.loadCards(this.data.company.id)
  }

  // ----------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPaymentSidenavData,
    public dialogRef: MatDialogRef<CardSelectionDialogComponent>,
    private paymentsFacade: PaymentsFacade,
    private modalService: ModalService
  ) {}

  // ----------------------------------------------------

  onCardSelected(card: CardWithExpiry) {
    this.selected = card
  }

  // ----------------------------------------------------

  addNewCardClicked() {
    this.modalService.show(NewCardDialogComponent,
      { company: this.data.company, productId: this.data.productId },
      { panelClass: 'sl-fullscreen-panel' })
  }

  // ----------------------------------------------------

  async onPayClicked() {
    this.dialogRef.close(this.selected.id)
  }
}
