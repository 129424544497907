import { Pipe, PipeTransform } from '@angular/core'

import { LabelService } from '@libs/services'

// ----------------------------------------------------------

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform {

  constructor(
    private labelService: LabelService,
  ) {}

  // ----------------------------------------------------

  transform<T>(
    src: T,
    kind = 'default'
  ): string {
    return this.labelService.getLabel(src, kind)
  }

}
