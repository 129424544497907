import { formatNumber } from '@angular/common'

// ------------------------------------------------------------

export const PPS_DECIMAL_PLACES = 10

// ------------------------------------------------------------

/**
 * Returns a concatenated string of amount plus abbreviation
 *
 * @param amount {number} the number to be abbreviated
 */
export function abbreviateNumber(
  amount: number
): string {
  let res = ''
  const abbrev = (p, m, u, d) => Math.round(p / m) / d + u

  if (amount >= 999995000) {
    res += abbrev(amount, 1e7, 'bn', 100)
  } else if (amount >= 999950) {
    res += abbrev(amount, 1e4, 'm', 100)
  } else if (amount >= 1000) {
    res += abbrev(amount, 1e2, 'k', 10)
  } else if (amount >= 10) {
    res += Math.round(amount)
  } else if (amount === 0) {
    res += 0
  }

  return res
}

// ------------------------------------------------------------
export function roundTo(value: number, dp = 0): number {
  const fac = Math.pow(10, dp)
  return Math.round(value * fac) / fac
}

// ------------------------------------------------------------

export function roundPps(value: number): number {
  return roundTo(value, PPS_DECIMAL_PLACES)
}

// ------------------------------------------------------------

/**
 * Returns the percentage represented by "value out of total"
 *
 * @param value {number} The portion
 * @param total {number} The total
 * @returns {number} value/total
 */
export function percentage(value: number, total: number): number {
  return typeof value === 'number' && typeof total === 'number' && total !== 0
    ? value / total
    : 0
}

// ------------------------------------------------------------

/**
 * Returns the number of digits after the decimal point.
 *
 * @param value {number} Value to check
 * @returns {number} Number of decimals, or 0 if Infinity or NaN
 */
export function getDecimalPlaces(value: number): number {
  if (!Number.isFinite(value)) {
    return 0
  }

  let e = 1
  let p = 0

  while (Math.round(value * e) / e !== value) {
    e *= 10
    p++
  }

  return p
}

// ----------------------------------------------------------

const FILE_SIZE_UNITS = [ 'B', 'kB', 'MB', 'GB' ]

export function formatFileSize(
  value: number,
  locale = 'en'
): string {
  let unitIdx = 0

  while (unitIdx < 3 && value >= 10 ** 3) {
    unitIdx++

    value /= 10 ** 3
  }

  return formatNumber(value, locale, '1.0-2') + ' ' + FILE_SIZE_UNITS[ unitIdx ]
}

// ----------------------------------------------------------

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - the locale that the number is represented in.
 * @return {number} number - returns a floating point number with no formatting
 */
export function parseLocaleNumber(stringNumber, locale, isInteger: boolean, maxDigits) {
  const parsedNumber = stringNumber
    .replace(new RegExp('\\' + getThousandSeparator(locale), 'g'), '')
    .replace(new RegExp('\\' + getDecimalSeparator(locale)), '.')
    //replaces all except the first instance of a . with an empty string
    .replace(/\./g, (c, i, text) => text.indexOf(c) === i ? c : '')
    //replaces everything except any remaining numbers, dots or minus symbols with an empty string (catch all edge cases)
    .replace(/[^0-9.-]+/g, '')
    //replaces all except the 0th index of a - with an empty string
    .replace(/-/g, (c, i) => i === 0 ? c : '')
    .slice(0, maxDigits)
  return isInteger ? parseInt(parsedNumber, 10) : parseFloat(parsedNumber)
}

// ----------------------------------------------------------

function getThousandSeparator(locale) {
  return formatNumber(11111, locale).replace(/\p{Number}/gu, '')
}

export function getDecimalSeparator(locale) {
  return formatNumber(1.1, locale).replace(/\p{Number}/gu, '')
}
