import {
  Component,
  OnInit,
  Input,
  ContentChild,
  TemplateRef,
  Injector,
  ElementRef,
  ViewChildren,
  QueryList,
  Inject,
  EventEmitter,
  Output
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, ComponentType } from '@angular/cdk/portal'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Observable } from 'rxjs'

import { Event, InstantConversionEvent, InvestmentSummary, LoanSummary, Model, Round, SeedNoteEvent } from '@libs/models'
import { BasicLoanEventDataSource, EventInvestorSummaryDataSource } from '../../models/event-investor-summary-data-source'

import { BackendService, RestApi } from '@libs/backend'
import { ToastService } from '@libs/services'
import { EventInvestorService } from '../../services/event-investor.service'

import { InvestorSummaryChipComponent } from '../investor-summary-chip/investor-summary-chip.component'
import { InvestorInfoPopupComponent } from '../investor-info-popup/investor-info-popup.component'

// ----------------------------------------------------------

@Component({
  selector: 'sl-event-investor-summary-list',
  templateUrl: './event-investor-summary-list.component.html'
})
export class EventInvestorSummaryListComponent implements OnInit {
  @Input() event: Round | InstantConversionEvent | SeedNoteEvent
  @Input() dataSource: EventInvestorSummaryDataSource<Event, InvestmentSummary> | BasicLoanEventDataSource
  @Input() sidenav?: ComponentType<any>
  @Input() addRouterLink?: string
  @Input() showAddNew = true
  @Input() showDelete = false

  @Output() delete = new EventEmitter<InvestmentSummary | LoanSummary>()
  @Output() add = new EventEmitter<void>()

  @ViewChildren(InvestorSummaryChipComponent, { read: ElementRef }) chipElements: QueryList<ElementRef>

  @ContentChild('popupTemplate') popupTemplateRef: TemplateRef<any>

  @ContentChild('valueTemplate') valueTemplateRef: TemplateRef<any>

  summaries$: Observable<InvestmentSummary[] | LoanSummary[]>

  // ----------------------------------------------------

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private eventInvestorService: EventInvestorService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(RestApi) private restApi: BackendService,
    private toastService: ToastService
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.summaries$ = this.dataSource.connect()
    // .pipe(
    //   logger(`EventInvestorSummaryListComponent.summaries$`)
    // )

    // _log(
    //   `EventInvestorSummaryListComponent.ngOnInit(event, dataSource): this`,
    //   this.event,
    //   this.dataSource,
    //   this
    // )
  }

  // ----------------------------------------------------

  onInvestorClicked(summary: InvestmentSummary | LoanSummary, index) {
    // _log(
    //   `EventInvestorSummaryListComponent.onInvestorClicked(summary): this`,
    //   summary,
    //   this
    // )

    if (!this.popupTemplateRef) {
      return
    }

    const overlayRef = this.overlay.create({
      minWidth: '320px',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies
        .close(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.chipElements.toArray()[ index ])
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          }
        ])
        .withTransformOriginOn('sl-investor-info-popup')
        .withViewportMargin(8)
    })

    const portal = new ComponentPortal(
      InvestorInfoPopupComponent,
      null,
      this.getInjector(overlayRef, summary)
    )

    overlayRef.attach(portal)
  }

  // ----------------------------------------------------

  onAddInvestorClicked() {
    // _log(
    //   `EventInvestorSummaryListComponent.onAddInvestorClicked(): this`,
    //   this
    // )
    if (this.addRouterLink) {
      this.router.navigate([ this.addRouterLink ], { relativeTo: this.route })
    } else if (this.sidenav) {
      this.onAddInvestorSidenav()
    } else {
      this.add.emit()
    }
  }

  // ----------------------------------------------------

  async onAddInvestorSidenav() {
    const result = await this.eventInvestorService.openSidenav(
      this.sidenav,
      this.event
    )

    if (result) {
      this.onEventChanged()
    }
  }

  // ----------------------------------------------------

  onEventChanged() {
    this.dataSource.getData()
    // _log(
    //   `EventInvestorSummaryListComponent.onEventChanged(): this`,
    //   this
    // )
  }

  // ----------------------------------------------------

  private getInjector(
    overlayRef: OverlayRef,
    summary: InvestmentSummary | LoanSummary
  ) {
    const data = {
      templateRef: this.popupTemplateRef,
      event: this.event,
      summary,
      showDelete: this.showDelete,
      sidenavComponent: this.sidenav,
      onEventChanged: () => {
        this.onEventChanged()
      },
      onDelete: async () => {
        if (this.delete.observed) {
          this.delete.emit(summary)
        } else {
          try {
            const investment: Model = 'investment' in summary
              ? summary.investment
              : summary.loan

            await investment.remove(this.restApi)
            this.onEventChanged()
          } catch (ex) {
            this.toastService.error($localize`Error could not remove investment`)
          }
        }
      }
    }

    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MAT_DIALOG_DATA,
          useValue: data
        },
        {
          provide: OverlayRef,
          useValue: overlayRef
        }
      ]
    })
  }

}
