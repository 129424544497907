import { Directive, TemplateRef } from '@angular/core'

// ----------------------------------------------------------

/** Tag content or template elements as grid-item for the ghost grid component
 *  Should only be used inside `sl-ghost-grid` as direct child component and  it
 *  will be replaced by a ghost component while `loading` is `true` in the parent.
 *  Can only be applied to direct descendants of the ghost component.
 *
 *  See usage example on `ghost-grid.component`.
 */
@Directive({
  selector: '[slGhostGridContent]',
})
export class GhostGridContentDirective {

  constructor(
    public template: TemplateRef<any>
  ) {}

}
