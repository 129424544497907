import type { OnChanges, OnInit } from '@angular/core'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core'

import { MatExpansionPanel } from '@angular/material/expansion'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { Actions, ofType } from '@ngrx/effects'

import type { IUserCompanyWithRoleInfo } from '@libs/models'
import {
  AppointmentRole,
  Company,
  ConvertibleNoteEvent,
  ExtendedRole,
  LeftNavInfo,
  Region,
  User,
  UserCompanyAccess
} from '@libs/models'

import { CohortCompanyIds } from '@libs/cohort'
import { Configuration } from '@app/core/services/configuration.service'
import type { LeftNavLinksData } from '../../models/left-nav-links-data'
import { LeftNavLinks } from '../../models/left-nav-links'

import { LayoutFacade } from '../../+state/layout.facade'
import { CompanyUpdated } from '@app/companies/+state/company.actions'
import { FeatureFlagsService } from '@libs/services'

// ----------------------------------------------------------

const DEFAULT_HELP_LINK = 'https://help.seedlegals.com'

// ----------------------------------------------------------

@UntilDestroy()
@Component({
  selector: 'sl-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: [ './left-nav.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftNavComponent implements OnInit, OnChanges {
  @Input() company: Company
  @Input() user: User
  @Input() leftNavInfo: LeftNavInfo
  @Input() isLoading: boolean

  isPoliciesEnabled = false

  readonly isPoliciesEnabled$ = this.flagsService.getFeatureFlag('NAV_POLICIES').pipe(
    untilDestroyed(this)
  )

  companySelectExpanded = false

  currentCompany: IUserCompanyWithRoleInfo
  otherCompanies: IUserCompanyWithRoleInfo[] = []
  moreDocumentsToSign: number
  hasPendingCohort = false
  showCohortPortal = false

  leftNavLinks: LeftNavLinksData[][] = []

  helpLink = DEFAULT_HELP_LINK

  leftNavPanelOpen$ = this.layout.getLeftNavPanelOpen$

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel

  // ----------------------------------------------------

  constructor(
    private cdr: ChangeDetectorRef,
    private config: Configuration,
    public layout: LayoutFacade,
    private flagsService: FeatureFlagsService,
    private actions$: Actions
  ) {}

  // ----------------------------------------------------

  ngOnInit() {
    this.leftNavPanelOpen$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(open => {
        if (this.user && this.company && this.panel) {
          if (!open) {
            this.panel.close()
          }
        }
      })

    this.actions$
      .pipe(
        ofType(CompanyUpdated),
        untilDestroyed(this)
      )
      .subscribe(({ companyId }) => {
        if (this.company?.id === companyId) {
          this.ngOnChanges()
        }
      })

    this.isPoliciesEnabled$.subscribe((isPoliciesEnabled: boolean) => {
      this.isPoliciesEnabled = isPoliciesEnabled

      this.ngOnChanges()

      this.cdr.detectChanges()
    })
  }

  // ----------------------------------------------------

  ngOnChanges() {
    if (this.leftNavInfo) {
      this.currentCompany = this.leftNavInfo.currentCompany
      this.otherCompanies = this.leftNavInfo.otherCompanies
      this.moreDocumentsToSign = this.leftNavInfo.moreDocumentsToSign
    }

    if (this.currentCompany && this.company) {
      this.initialiseLinks()
    }
  }

  // ----------------------------------------------------

  initialiseLinks() {
    this.helpLink = this.getHelpLink(this.company)

    const showFullNavigation = this.currentCompany.access !== UserCompanyAccess.Documents

    const cohortTeam = this.company.cohortTeams.item(0)
    this.hasPendingCohort = cohortTeam
      ? !cohortTeam.fundingEvent?.approved
      : false

    this.showCohortPortal = (this.currentCompany.company.hasCohortPortal
      && this.hasRole(ExtendedRole.Admin, ExtendedRole.StaffWrite, ExtendedRole.StaffRead))
      || (CohortCompanyIds.includes(this.currentCompany.id) && this.currentCompany.access === UserCompanyAccess.Documents)

    const region = this.config.getRegionByJurisdiction(this.currentCompany.company.jurisdiction)

    this.leftNavLinks = LeftNavLinks.reduce((out, group) => {
      const items = group.filter(item => {

        // This is a workaround for allowing some limited Europe Cohort companies to access their funding product.
        if (item.target === 'raise' && region.id === Region.Europe && showFullNavigation) {
          return this.company.events.some(e => e instanceof ConvertibleNoteEvent)
        }

        if (!item.allowedRegions.includes(region.id)) {
          return false
        }

        if (item.condition && !item.condition(this.currentCompany)) {
          return false
        }

        if (item.target === 'policies' && !this.isPoliciesEnabled) {
          return false
        }

        if (item.accessRole === AppointmentRole.Admin) {
          return this.hasRole(ExtendedRole.Admin, ExtendedRole.StaffWrite, ExtendedRole.StaffRead)
        } else if (item.accessRole === AppointmentRole.Read) {
          return this.hasRole(ExtendedRole.Admin, ExtendedRole.StaffWrite, ExtendedRole.StaffRead, ExtendedRole.Read)
        }

        return true
      })

      if (items.length) {
        out.push(items)
      }

      return out
    }, [] as LeftNavLinksData[][])

    // _log(`LeftNavComponent.initialiseLinks(): currentCompany, leftNavInfo, leftNavLinks`, this.currentCompany, this.leftNavInfo, this.leftNavLinks, this)
  }

  // ----------------------------------------------------

  onCompanyClicked(
    userCompany: IUserCompanyWithRoleInfo
  ) {
    this.panel.close()

    if (userCompany.unsignedDocuments) {
      this.layout.changeCurrentCompany(userCompany.company.id, [ 'documents' ])
    } else {
      this.layout.changeCurrentCompany(userCompany.company.id)
    }
  }

  // ----------------------------------------------------

  hasRole(
    ...roles: ExtendedRole[]
  ) {
    return roles.some(role => this.currentCompany.extendedRoles.has(role))
  }

  // ----------------------------------------------------

  private getHelpLink(company: Company): string {
    const region = this.config.getRegionByJurisdiction(company.jurisdiction).id
    switch (region) {
      case Region.France:
        return DEFAULT_HELP_LINK + '/fr/'
      default:
        return DEFAULT_HELP_LINK
    }
  }

}
